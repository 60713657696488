import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import ButtonWithSpinner from "../common/ButtonWithSpinner";
import PageHeader from "../common/PageHeader";
import ExpenseList from "../expense/ExpenseList";
import CreateButtons from "../common/CreateButtons";
import {
  useUserConfiguration,
  useExpenses,
  saveExpense,
  useOfficialRates,
  useCountries,
  useMajorCities,
  refreshExpenses,
  saveReport,
  useSeedExchangeRatesNB
} from "../../shared/queries/queries";
import "../../styles/report.css";
import { Expense } from "../../shared/types";
import { createReportFromExpenses } from "../../shared/utils/reportUtils";
import { getExpenseClone } from "../../shared/utils/expenseUtils";
import { showToast } from "../../utils/toastWrapper";
import Spinner from "../common/Spinner";
import { useStore } from "../../shared/store/store";
import moment from "moment";

// Main page for standalone expenses
const ExpensesPage = () => {
  const [t] = useTranslation();
  const history = useHistory();

  // Monitor this to trigger a rerender if we switch users
  useStore((state) => state.currentUserId);

  const userConfigurationQuery = useUserConfiguration();
  const expensesQuery = useExpenses();
  const officialRatesQuery = useOfficialRates();
  const countriesQuery = useCountries();
  const majorCitiesQuery = useMajorCities();
  const seedExchangeRatesNBQuery = useSeedExchangeRatesNB();

  const expenses = useMemo(() => (expensesQuery.data ? expensesQuery.data.expenses.filter((o) => !o.deleted) : []), [expensesQuery.data]);
  const allExpenseUuids = expenses.map((o) => o.uuid);
  const isLoading = !expensesQuery.data;
  const isFetching = expensesQuery.isFetching;

  const [selectedExpenseUuids, setSelectedExpenseUuids] = useState(allExpenseUuids);
  const [selectingMultiple, setSelectingMultiple] = useState(false);
  const [cloningUuid, setCloningUuid] = useState("");
  const [creatingReportFromExpenses, setCreatingReportFromExpenses] = useState(false);

  // Bail out here if we don't have a valid user configuration or all the static data yet
  if (!userConfigurationQuery.data || !officialRatesQuery.data || !countriesQuery.data || !majorCitiesQuery.data || !seedExchangeRatesNBQuery.data) {
    return <Spinner size="75px" margin="1em" />;
    // return (
    //   <div>
    //     <div>Loading...</div>
    //     <div>userConfigurationQuery.data: {(!!userConfigurationQuery.data).toString()}</div>
    //     <div>officialRatesQuery.data: {(!!officialRatesQuery.data).toString()}</div>
    //     <div>countriesQuery.data: {(!!countriesQuery.data).toString()}</div>
    //     <div>majorCitiesQuery.data: {(!!majorCitiesQuery.data).toString()}</div>
    //   </div>
    // );
  }

  // Show selection of items before creating an expense report
  const createExpenseReportFromExpenses = () => {
    setSelectingMultiple(true);
  };

  // Create a report and move all selected expenses onto it
  const confirmCreateExpenseReportFromExpenses = async () => {
    setCreatingReportFromExpenses(true);

    const userConfiguration = userConfigurationQuery.data.configuration;
    const officialRates = officialRatesQuery.data;
    const countries = countriesQuery.data;
    const majorCities = majorCitiesQuery.data;
    const exchangeRatesNBSeed = seedExchangeRatesNBQuery.data;

    try {
      const expensesToMove = expenses.filter((expense) => selectedExpenseUuids.includes(expense.uuid));
      // If no expenses are found, just return, this should not happen
      if (expensesToMove.length === 0) {
        setCreatingReportFromExpenses(false);
        return;
      }

      // Create the report
      const newReport = await createReportFromExpenses(
        2,
        expensesToMove,
        userConfiguration,
        officialRates,
        countries,
        majorCities,
        exchangeRatesNBSeed
      );

      // Save the report. This will also recursively save all the expenses
      saveReport(newReport)
        .then(() => {
          // Force a refresh of standalone expenses since some expenses will be gone from it now
          refreshExpenses();
          // Redirect to the new report
          history.push(`/report/${newReport.uuid}`);
        })
        .catch((err: any) => {
          showToast({ type: "error", title: t("notifications.reportSaveFailed"), text: err?.backendMessage || "" });
          setCreatingReportFromExpenses(false);
        });
    } catch {
      setCreatingReportFromExpenses(false);
      showToast({ type: "error", title: t("error"), text: t("genericError") });
    }
  };

  // Switch to the editor and create a new expense
  const addExpense = (expenseTypeId: number) => {
    history.push(`/expense/new?expenseType=${expenseTypeId}&from=expenses`);
  };

  // Switch to the editor and edit the expense
  const editExpense = (expense: Expense) => {
    history.push(`/expense/${expense.uuid}?from=expenses`);
  };

  const changeMultiSelection = (expenseUuidList: string[]) => {
    setSelectedExpenseUuids(expenseUuidList);
  };

  // Copy/clone an expense from the list
  const copyExpense = (expense: Expense) => {
    setCloningUuid(expense.uuid);
    const clone = getExpenseClone(expense);
    saveExpense(clone)
      .then(() => {
        setCloningUuid("");
        showToast({ type: "success", text: t("expenseCopied") });
      })
      .catch((err: any) => {
        setCloningUuid("");
        showToast({ type: "error", title: t("notifications.expenseCopyFailed"), text: err?.backendMessage || "" });
      });
  };

  const deleteExpense = async (expense: Expense) => {
    const now = moment().toISOString();
    const deletedExpense = { ...expense, deleted: now, dirty: now };
    await saveExpense(deletedExpense);
  };

  return (
    <div>
      <div>
        <PageHeader page="expenses" />
        {!selectingMultiple && (
          <CreateButtons
            showExpense
            showExpenseReportFromExpenses
            onCreateExpense={addExpense}
            onCreateReportFromExpenses={createExpenseReportFromExpenses}
            disabled={!expensesQuery.data}
          />
        )}

        {selectingMultiple && (
          <div>
            <ButtonWithSpinner
              bsStyle="success"
              bsSize="large"
              disabled={selectedExpenseUuids.length === 0 || creatingReportFromExpenses}
              onClick={async () => await confirmCreateExpenseReportFromExpenses()}
              showSpinner={creatingReportFromExpenses}
            >
              {t("expensesView.confirmCreateExpenseReport", {
                count: selectedExpenseUuids.length
              })}
            </ButtonWithSpinner>{" "}
            <Button bsStyle="warning" bsSize="large" disabled={creatingReportFromExpenses} onClick={() => setSelectingMultiple(false)}>
              {t("cancel")}
            </Button>
          </div>
        )}
      </div>

      <div className="report-component">
        <ExpenseList
          title={t("listHeaders.expensesAllTitle")}
          description={t("listHeaders.expensesAllInfo")}
          expenses={expenses}
          orderBy="changed"
          orderDesc
          readOnly={false}
          renderMode="table"
          showHeader
          onEditExpense={editExpense}
          onCopyExpense={copyExpense}
          onDeleteExpense={deleteExpense}
          selectingMultiple={selectingMultiple}
          onChangeMultiSelection={changeMultiSelection}
          showRefreshButton={!expensesQuery.isLoading}
          isLoading={isLoading}
          isFetching={isFetching}
          cloningUuid={cloningUuid}
          errorMessage={expensesQuery.isError || expensesQuery.data?.incrementalRefreshStatus === "error" ? t("queryErrors.loadExpensesError") : ""}
        />
      </div>
    </div>
  );
};

export default ExpensesPage;
