import moment from "moment";
import { AutoExpenseOverride } from "../types";
import { uuid } from "../utils/helpers";

interface AutoExpenseOverrideConstructorOptions {
  reportUuid: string;
  expenseDate: string;
}
export const autoExpenseOverrideConstructor = (opts: AutoExpenseOverrideConstructorOptions): AutoExpenseOverride => {
  const created = moment().toISOString();
  const thisUuid = uuid();
  const { reportUuid, expenseDate } = opts;
  return {
    uuid: thisUuid,
    created,
    changed: "",
    reportUuid,
    expenseDate,
    excludeLodging: false,
    excludeDiet: false,
    freeBreakfast: false,
    freeLunch: false,
    freeDinner: false,
    deleted: "",
    dirty: created
  };
};
