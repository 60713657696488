import { ExpenseType, ProductConfiguration, UserConfiguration } from "../types";

/**
 * @param {object} configuration - The user configuration object
 * @returns The currently enabled expense types for this user, hidden expense types will be removed
 */
export const currentExpenseTypes = (configuration?: UserConfiguration): ExpenseType[] => {
  if (!configuration || !configuration.product) return [];
  return currentExpenseTypesByProductConfig(configuration.product);
};

export const currentExpenseTypesByProductConfig = (productConfig?: ProductConfiguration): ExpenseType[] => {
  if (!productConfig || !productConfig.expenseTypes) return [];
  const allEnabledExpenseTypes = productConfig.expenseTypes.filter((expenseType) => expenseType.enabled);
  productConfig.expenseTypesHidden.forEach((expenseTypeHidden) => {
    for (let i = 0; i < allEnabledExpenseTypes.length; i++) {
      if (allEnabledExpenseTypes[i].id === expenseTypeHidden.expenseTypeId) {
        allEnabledExpenseTypes.splice(i, 1);
        break;
      }
    }
  });
  return allEnabledExpenseTypes;
};
