import { jsonFetch } from "./api";
import { AdvancedApprovalAttempt, AdvancedApprovalDeputization, AlternativeApprovalFlow, ClassicApprovalAttempt } from "../types";

/**
 * Gets all classic report approval attempts the user is or has been involved in
 * @returns {response} A list of all classic approvalAttempts
 */
export const listMine = async (): Promise<ClassicApprovalAttempt[]> => {
  const fetchOptions = {
    method: "GET"
  };
  return jsonFetch(`e/reportapproval/listmine`, fetchOptions);
};

/**
 * Counts the user's immediately pending report approvals (classic and advanced combined)
 * @returns {int} Number of pending approvals
 */
export const pending = async (): Promise<number> => {
  const fetchOptions = {
    method: "GET"
  };
  return jsonFetch(`e/reportapproval/pending`, fetchOptions);
};

/**
 * Decide on a classic approval attempt (approve or reject a report)
 * @param {object} decisionDetails approvalAttemptUuid, approved (bool), comment (string), language (string)
 * @returns {} 200 OK
 */
interface DecideOptions {
  approvalAttemptUuid: string;
  approved: boolean;
  comment: string;
  language: string;
}
export const decide = async (decisionDetails: DecideOptions): Promise<void> => {
  const fetchOptions = {
    method: "POST",
    body: JSON.stringify(decisionDetails)
  };
  return jsonFetch(`e/reportapproval/decide`, fetchOptions);
};

/**
 * Gets all advanced report approval attempts the user is or has been involved in
 * @param {object} parameters onlyPending (bool) limits list to currently pending decisions, skip (number) and take (number) is for paging
 * @returns {response} {attempts[], deputizations[{id, name}]}, A list of all approvalAttempts, and a list of the user's current deputizations (other people they can approve on behalf of)
 */
interface ListMineAdvancedOptions {
  onlyPending: boolean;
  skip: number;
  take: number;
}
export const listMineAdvanced = async (
  opts: ListMineAdvancedOptions
): Promise<{ attempts: AdvancedApprovalAttempt[]; deputizations: AdvancedApprovalDeputization[] }> => {
  const { onlyPending, skip, take } = opts;
  const fetchOptions = {
    method: "GET"
  };
  let url = `e/reportapproval/listmineadvanced?onlypending=${onlyPending ? "true" : "false"}&skip=${skip ? skip : 0}&take=${take ? take : 99999999}`;
  return jsonFetch(url, fetchOptions);
};

/**
 * Decide on an advanced approval attempt (approve or reject a report)
 * @param {object} decisionDetails advancedApprovalDecisionUuid, approved (bool), comment (string), language (string)
 * @returns {} 200 OK
 */
interface DecideAdvancedOptions {
  advancedApprovalDecisionUuid: string;
  approved: boolean;
  comment: string;
  language: string;
}
export const decideAdvanced = async (decisionDetails: DecideAdvancedOptions): Promise<void> => {
  const fetchOptions = {
    method: "POST",
    body: JSON.stringify(decisionDetails)
  };
  return jsonFetch(`e/reportapproval/decideadvanced`, fetchOptions);
};

/**
 * Get a list of available advanced approval flows for a user
 * Pass in an active decision UUID. The flow list will belong to the owner of the report that decision is for
 *
 * When an approver has a queued decision, he can redirect the report to another flow
 * This endpoint is intended to provide options for which flows the report can be redirected to
 * @param {string} decisionUuid UUID of an active advanced approval decision
 * @returns {} 200 OK
 */
export const alternativeFlows = async (decisionUuid: string): Promise<AlternativeApprovalFlow[]> => {
  const fetchOptions = {
    method: "GET"
  };
  return jsonFetch(`e/reportapproval/alternativeflows?decisionUuid=${decisionUuid}`, fetchOptions);
};

/**
 * Redirect a report to another advanced approval flow
 * Pass in an active decision UUID to identify which flow/report this is about, and a newFlowId based on the options from the alternativeFlows endpoint
 * @param {object} redirectFlowDetails currentDecisionUuid, newFlowId (number)
 * @returns {} 200 OK
 */
interface RedirectToNewFlowOptions {
  currentDecisionUuid: string;
  newFlowId: number;
}
export const redirectToNewFlow = async (redirectFlowDetails: RedirectToNewFlowOptions): Promise<void> => {
  const fetchOptions = {
    method: "POST",
    body: JSON.stringify(redirectFlowDetails)
  };
  return jsonFetch(`e/reportapproval/redirecttonewflow`, fetchOptions);
};
