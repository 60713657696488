import { useQuery, QueryClient, setLogger } from "react-query";
import * as userApi from "../api/user";
import * as geoApi from "../api/geography";
import * as reportApi from "../api/report";
import * as expenseApi from "../api/expense";
import * as mileageApi from "../api/mileage";
import * as reportSegmentApi from "../api/reportSegment";
import * as reportCustomValueApi from "../api/reportCustomValue";
import * as expenseCustomValueApi from "../api/expenseCustomValue";
import * as attachmentApi from "../api/attachment";
import * as autoExpenseOverrideApi from "../api/autoExpenseOverride";
import * as inboxApi from "../api/inbox";
import * as inboxAttachmentApi from "../api/inboxAttachment";
import * as exchangeRatesApi from "../api/exchangeRates";
import * as officialRatesApi from "../api/officialRates";
import * as reportApprovalApi from "../api/reportApproval";
import { Report, Expense, Inbox, Mileage, ApiReportsListResponse, ApiInboxListResponse, ApiMileageListResponse } from "../types";
import { isEmpty } from "lodash";
import moment from "moment";

// TODO-V2-OPTIONAL
// Optimistic updates of related queries after mutation:
// - add entity inserts to lists
// - update lists based on entity updates

// Time consts
const TIME_1_HOUR = 3600 * 1000;
const TIME_4_HOURS = 3600 * 4 * 1000;
// const TIME_12_HOURS = 3600 * 12 * 1000;
// const TIME_24_HOURS = 3600 * 24 * 1000;

// Query key consts
export const QK_USER_CONFIGURATION = "user_configuration";

export const QK_GEO_TOPLOCATIONS = "geo_toplocations";
export const QK_GEO_COUNTRIES = "geo_countries";
export const QK_GEO_MAJORCITIES = "geo_majorcities";
export const QK_GEO_LOCATION = "geo_location";

export const QK_EXCHANGERATES = "exchangerates";
export const QK_EXCHANGERATES_NB = "exchangerates_nb";
export const QK_SEEDEXCHANGERATES_NB = "seedexchangerates_nb"
export const QK_OFFICIALRATES = "officialrates";

export const QK_REPORT_LIST = "report_list";
export const QK_EXPENSE_LIST = "expense_list";
export const QK_INBOX_LIST = "inbox_list";
export const QK_MILEAGE_LIST = "mileage_list";

export const QK_MILEAGE = "mileage";

export const QK_TOLLSTATIONS = "tollstations";
export const QK_TOLLSTATIONS_BY_DIRECTIONS_ROUTE = "tollstations_by_directions_route";

export const QK_APPROVALS_PENDING_COUNT = "approvals_pending_count";
export const QK_APPROVALS_CLASSIC = "approvals_pending_classic";
export const QK_APPROVALS_ADVANCED = "approvals_pending_advanced";

// Internal current user ID for queries
let uid: null | number = null;

// Timestamps for serverTime of when we last pulled full or incremental updates to each query
let lastUpdate = {
  QK_REPORT_LIST: "",
  QK_EXPENSE_LIST: "",
  QK_INBOX_LIST: "",
  QK_MILEAGE_LIST: ""
};

// Always hit this when rehydrating app, logging in, or switching users
export const setUserId = (userId: null | number) => {
  uid = userId;
};

// Return the current userId used for queries
export const currentUserId = () => {
  return uid;
};

// This is the one QC instance to rule them all. Cast it into the fire!
// (Wrap the application in it to make sure everyone is using the same cache; <QueryClientProvider client={queryClient}> )
export const queryClient = new QueryClient();

// Mute console errors from queries globally. We're handling API errors ourselves.
setLogger({
  log: () => {},
  warn: () => {},
  error: () => {}
});

const defaultQueryOptions = () => ({ enabled: !!currentUserId(), staleTime: TIME_4_HOURS, cacheTime: TIME_4_HOURS, retry: 1, retryDelay: 15000 }); // Used for most userdata queries
const staticQueryOptions = () => ({ ...defaultQueryOptions(), staleTime: Infinity, cacheTime: Infinity }); // Used for queries against (mostly) static data

// Return configuration for the current user, or for a specific user if a userId and jwtToken is provided
export const useUserConfiguration = (userId?: number, jwtToken?: string) => {
  const uid = userId || currentUserId();
  const queryOptions = { ...defaultQueryOptions(), enabled: !!uid };
  return useQuery([QK_USER_CONFIGURATION, { userId: uid }], () => userApi.configuration(jwtToken), queryOptions);
};

// Return top locations for the current user
export const useTopLocations = () => {
  return useQuery([QK_GEO_TOPLOCATIONS, { userId: currentUserId() }], geoApi.topLocations, defaultQueryOptions());
};

// Return all reports for the current user
export const useReports = () => {
  return useQuery([QK_REPORT_LIST, { userId: currentUserId() }], () => reportApi.list(), {
    ...defaultQueryOptions(),
    onSuccess: (data) => {
      if (data?.serverTime) lastUpdate.QK_REPORT_LIST = data.serverTime;
    }
  });
};

// Return all standalone expenses for the current user
export const useExpenses = () => {
  return useQuery([QK_EXPENSE_LIST, { userId: currentUserId() }], () => expenseApi.list(undefined, true), {
    ...defaultQueryOptions(),
    onSuccess: (data) => {
      if (data?.serverTime) lastUpdate.QK_EXPENSE_LIST = data.serverTime;
    }
  });
};

// Return all inbox items for the current user
export const useInboxes = () => {
  return useQuery([QK_INBOX_LIST, { userId: currentUserId() }], () => inboxApi.list(), {
    ...defaultQueryOptions(),
    onSuccess: (data) => {
      if (data?.serverTime) lastUpdate.QK_INBOX_LIST = data.serverTime;
    }
  });
};

// Return all mileage tracks for the current user
// These will not include coord sets for performance reasons.
export const useMileages = () => {
  return useQuery([QK_MILEAGE_LIST, { userId: currentUserId() }], () => mileageApi.list(undefined, false), {
    ...defaultQueryOptions(),
    onSuccess: (data) => {
      if (data?.serverTime) lastUpdate.QK_MILEAGE_LIST = data.serverTime;
    }
  });
};

// Return a single expense for the current user
// Only executes if an expenseUuid is provided
// This *will* include coord sets which can be heavy
export const useMileage = (mileageUuid: string) => {
  const enabled = !!(mileageUuid && mileageUuid !== "" && mileageUuid !== "new");
  const queryOptions = { ...defaultQueryOptions(), enabled };
  return useQuery([QK_MILEAGE, { userId: currentUserId(), mileageUuid }], () => mileageApi.details(mileageUuid, true), queryOptions);
};

// Return tollstations passings in a mileage for the current user
// Only executes if a mileage object is provided
export const useTollstations = (mileage?: Mileage | null) => {
  const enabled = !!(mileage && !isEmpty(mileage));
  const mileageUuid = mileage ? mileage.uuid : "";
  const queryOptions = { ...defaultQueryOptions(), enabled };
  return useQuery([QK_TOLLSTATIONS, { userId: currentUserId(), mileageUuid }], () => mileage && mileageApi.tollstations(mileage), queryOptions);
};

// Return tollstations passings in a google-provided directionsRoute for the current user
// Only executes if a directionsRoute object is provided
// Assumes a few things about the directionsRoute object structure, but it is mostly pass-through and handled by the backend
export const useTollstationsByDirectionsRoute = (directionsRoute?: any | null) => {
  const enabled = !!(directionsRoute && !isEmpty(directionsRoute));
  const routeId =
    directionsRoute && directionsRoute.legs && directionsRoute.legs.length > 0
      ? `${directionsRoute.legs[0].start_address}${directionsRoute.legs[directionsRoute.legs.length - 1].end_address}`
      : "";
  const queryOptions = { ...defaultQueryOptions(), enabled };
  return useQuery(
    [QK_TOLLSTATIONS_BY_DIRECTIONS_ROUTE, { userId: currentUserId(), routeId }],
    () => (directionsRoute ? mileageApi.tollstationsByDirectionsRoute(directionsRoute) : null),
    queryOptions
  );
};

// Return all exchange rates from fixer.io (our primary exchange rate provider) for a given date
// If no date param is passed, today's rates will be loaded
export const useExchangeRates = (date?: string) => {
  const dateDay = date ? moment(date).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD");
  return useQuery([QK_EXCHANGERATES, { dateDay }], () => exchangeRatesApi.exchangeRates(dateDay), staticQueryOptions());
};

// Return all exchange rates from Norges Bank (secondary provider, used in the diet calculator as of 2024) for a given date
// If no date param is passed, today's rates will be loaded
export const useExchangeRatesNB = (date?: string) => {
  const dateDay = date ? moment(date).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD");
  return useQuery([QK_EXCHANGERATES_NB, { dateDay }], () => exchangeRatesApi.exchangeRatesNB(dateDay), staticQueryOptions());
};

// Return seed exchange rates from Norges Bank (secondary provider, used in the diet calculator as of 2024), typically the last 100 rate sets
// Intended for feeding the 2024 diet calculator
export const useSeedExchangeRatesNB = () => {
  return useQuery(QK_SEEDEXCHANGERATES_NB, () => exchangeRatesApi.seedExchangeRatesNB(), staticQueryOptions());
};

// Return all official diet rates with complete history
export const useOfficialRates = () => {
  return useQuery(QK_OFFICIALRATES, () => officialRatesApi.officialRates(), staticQueryOptions());
};

// Return all countries
export const useCountries = () => {
  return useQuery(QK_GEO_COUNTRIES, () => geoApi.countries(), staticQueryOptions());
};

// Return all major cities
export const useMajorCities = () => {
  return useQuery(QK_GEO_MAJORCITIES, () => geoApi.majorCities(), staticQueryOptions());
};

// Return an arbitrary location based on cityId/countryId, used for geocoding non-standard locations
export const useLocation = (cityId: number, countryId: number) => {
  const enabled = cityId > 0 || countryId > 0;
  const queryOptions = { ...staticQueryOptions(), enabled };
  return useQuery([QK_GEO_LOCATION, { cityId: countryId }], () => geoApi.location(cityId, countryId), queryOptions);
};

// Return the current number of pending approvals
export const usePendingApprovalCount = () => {
  const queryOptions = { ...defaultQueryOptions(), staleTime: TIME_1_HOUR, cacheTime: TIME_1_HOUR };
  return useQuery([QK_APPROVALS_PENDING_COUNT, { userId: currentUserId() }], () => reportApprovalApi.pending(), queryOptions);
};

// Return all classic approvals the user is involved in
export const useClassicApprovals = () => {
  const queryOptions = { ...defaultQueryOptions(), staleTime: TIME_1_HOUR, cacheTime: TIME_1_HOUR };
  return useQuery([QK_APPROVALS_CLASSIC, { userId: currentUserId() }], () => reportApprovalApi.listMine(), queryOptions);
};

// Return all advanced approvals the user is involved in
export const useAdvancedApprovals = (options: { onlyPending: boolean; skip: number; take: number }) => {
  const queryOptions = { ...defaultQueryOptions(), staleTime: TIME_1_HOUR, cacheTime: TIME_1_HOUR };
  return useQuery([QK_APPROVALS_ADVANCED, { userId: currentUserId() }], () => reportApprovalApi.listMineAdvanced(options), queryOptions);
};

// Save:
// - report
// - expenses
// - segments
// - attachments
// - reportcustomvalues
// - expensecustomvalues
// - autoexpenseoverrides
// Pass in an advancedApprovalAttemptDecisionUuid if this is an approver editing a report on behalf of an end user.
// The uuid will be added to all request headers and act as an authorization key for these backend operations
export const saveReport = async (report: Report, advancedApprovalAttemptDecisionUuid?: string) => {
  const opts = advancedApprovalAttemptDecisionUuid ? { headers: { advancedApprovalAttemptDecisionUuid } } : {};
  if (report.dirty) await reportApi.save(report, opts);

  const segments = report.reportSegments.filter((o) => o.dirty).map((o) => reportSegmentApi.save(o, opts));
  await Promise.all(segments.map((p) => p.catch((e) => e)));

  const autoExpenseOverrides = report.autoExpenseOverrides.filter((o) => o.dirty).map((o) => autoExpenseOverrideApi.save(o, opts));
  await Promise.all(autoExpenseOverrides.map((p) => p.catch((e) => e)));

  const reportCustomValues = report.reportCustomValues.filter((o) => o.dirty).map((o) => reportCustomValueApi.save(o, opts));
  await Promise.all(reportCustomValues.map((p) => p.catch((e) => e)));

  const reportAttachments = report.attachments.filter((o) => o.dirty).map((o) => attachmentApi.save(o, opts));
  await Promise.all(reportAttachments.map((p) => p.catch((e) => e)));

  const changedExpenses = report.expenses.filter((o) => o.dirty);
  const expenses = changedExpenses.map((o) => expenseApi.save(o, opts));
  await Promise.all(expenses.map((p) => p.catch((e) => e)));

  for (const exp of changedExpenses) {
    const expenseCustomValues = exp.expenseCustomValues.filter((o) => o.dirty).map((o) => expenseCustomValueApi.save(o, opts));
    await Promise.all(expenseCustomValues.map((p) => p.catch((e) => e)));

    const expenseAttachments = exp.attachments.filter((o) => o.dirty).map((o) => attachmentApi.save(o, opts));
    await Promise.all(expenseAttachments.map((p) => p.catch((e) => e)));
  }

  // Refresh report list and invalidate query for this report
  // If the save was done by an approver for another user's report, we can skip this
  if (!advancedApprovalAttemptDecisionUuid) refreshReports();
};

// Save:
// - expense
// - expenseCustomValues
// - attachments
export const saveExpense = async (expense: Expense) => {
  if (expense.dirty) await expenseApi.save(expense);

  const expenseCustomValues = expense.expenseCustomValues.filter((o) => o.dirty).map((o) => expenseCustomValueApi.save(o));
  await Promise.all(expenseCustomValues.map((p) => p.catch((e) => e)));

  const expenseAttachments = expense.attachments.filter((o) => o.dirty).map((o) => attachmentApi.save(o));
  await Promise.all(expenseAttachments.map((p) => p.catch((e) => e)));

  if (expense.reportUuid) {
    // If the expense is on a report, refresh the report list
    refreshReports();
  } else {
    // Otherwise, refresh expense list
    refreshExpenses();
  }
};

// Save:
// - inbox item
// - inboxAttachments
export const saveInbox = async (inbox: Inbox) => {
  if (inbox.dirty) await inboxApi.save(inbox);

  const inboxAttachments = inbox.inboxAttachments.filter((o) => o.dirty).map((o) => inboxAttachmentApi.save(o));
  await Promise.all(inboxAttachments.map((p) => p.catch((e) => e)));

  // Refresh inbox list
  refreshInboxes();
};

// Save:
// - mileage
// If keepExistingCoordinates=true, the backend will ignore the "track" property. Useful if we're only deleting a track or updating a description, but we haven't loaded the actual track yet
export const saveMileage = async (mileage: Mileage, keepExistingCoordinates: boolean = false) => {
  if (mileage.dirty) await mileageApi.save(mileage, keepExistingCoordinates);

  // Refresh mileage list and invalidate query for this mileage
  refreshMileages();
  queryClient.invalidateQueries([QK_MILEAGE, { userId: currentUserId(), mileageUuid: mileage.uuid }]);
};

export const mergeUpdatedEntities = (oldEntities: Report[], newEntities: Report[]) => {
  return [
    ...oldEntities.map((o) => newEntities.find((p) => p.uuid === o.uuid) || o),
    ...newEntities.filter((o) => !oldEntities.find((p) => p.uuid === o.uuid))
  ];
};

// The functions below refreshes entity lists
// By default, these functions will call the API with a changedSince param, and merge any changes into the existing query without invalidating it for performance
// If you need to re-download the complete list, pass hardRefresh:true
export const refreshReports = async (hardRefresh?: boolean) => {
  const queryKey = [QK_REPORT_LIST, { userId: currentUserId() }];
  if (hardRefresh) {
    queryClient.invalidateQueries(queryKey);
  } else {
    try {
      // Set incremental refresh status to "loading"
      queryClient.setQueryData(
        queryKey,
        (oldData) =>
          oldData && {
            ...(oldData as ApiReportsListResponse),
            incrementalRefreshStatus: "loading"
          }
      );
      // Fetch report changes
      const newData = await reportApi.list(lastUpdate.QK_REPORT_LIST || undefined);
      // Merge added/deleted reports and reset incremental refresh status
      queryClient.setQueryData(
        queryKey,
        (oldData) =>
          oldData && {
            serverTime: newData.serverTime,
            reports: [
              ...(oldData as ApiReportsListResponse).reports.map((o) => newData.reports.find((p) => p.uuid === o.uuid) || o),
              ...newData.reports.filter((o) => !(oldData as ApiReportsListResponse).reports.find((p) => p.uuid === o.uuid))
            ],
            incrementalRefreshStatus: "ok"
          }
      );
      lastUpdate.QK_REPORT_LIST = newData.serverTime;
    } catch {
      // Request failed, set incremental refresh status to "error"
      queryClient.setQueryData(
        queryKey,
        (oldData) =>
          oldData && {
            ...(oldData as ApiReportsListResponse),
            incrementalRefreshStatus: "error"
          }
      );
      console.log("refreshReports failed");
    }
  }
};

// When refreshing expenses, keep in mind that moving a standalone expense to a report will effectively remove it from this list
// While other entities are typically flagged as deleted, an incremental refresh (using changedSince) here will not reflect this change
// Thus: we don't provide a hardRefresh param, we always refresh the complete list
export const refreshExpenses = async () => {
  queryClient.invalidateQueries([QK_EXPENSE_LIST, { userId: currentUserId() }]);
};

export const refreshInboxes = async (hardRefresh?: boolean) => {
  const queryKey = [QK_INBOX_LIST, { userId: currentUserId() }];
  if (hardRefresh) {
    queryClient.invalidateQueries(queryKey);
  } else {
    try {
      // Set incremental refresh status to "loading"
      queryClient.setQueryData(
        queryKey,
        (oldData) =>
          oldData && {
            ...(oldData as ApiInboxListResponse),
            incrementalRefreshStatus: "loading"
          }
      );
      // Fetch inbox changes
      const newData = await inboxApi.list(lastUpdate.QK_INBOX_LIST || undefined);
      // Merge added/deleted inboxes and reset incremental refresh status
      queryClient.setQueryData(
        queryKey,
        (oldData) =>
          oldData && {
            serverTime: newData.serverTime,
            inboxes: [
              ...(oldData as ApiInboxListResponse).inboxes.map((o) => newData.inboxes.find((p) => p.uuid === o.uuid) || o),
              ...newData.inboxes.filter((o) => !(oldData as ApiInboxListResponse).inboxes.find((p) => p.uuid === o.uuid))
            ],
            incrementalRefreshStatus: "ok"
          }
      );
      lastUpdate.QK_INBOX_LIST = newData.serverTime;
    } catch {
      // Request failed, set incremental refresh status to "error"
      queryClient.setQueryData(
        queryKey,
        (oldData) =>
          oldData && {
            ...(oldData as ApiInboxListResponse),
            incrementalRefreshStatus: "error"
          }
      );
      console.log("refreshInboxes failed");
    }
  }
};

export const refreshMileages = async (hardRefresh?: boolean) => {
  const queryKey = [QK_MILEAGE_LIST, { userId: currentUserId() }];
  if (hardRefresh) {
    queryClient.invalidateQueries(queryKey);
  } else {
    try {
      // Set incremental refresh status to "loading"
      queryClient.setQueryData(
        queryKey,
        (oldData) =>
          oldData && {
            ...(oldData as ApiMileageListResponse),
            incrementalRefreshStatus: "loading"
          }
      );
      // Fetch mileage changes
      const newData = await mileageApi.list(lastUpdate.QK_MILEAGE_LIST || undefined);
      // Merge added/deleted mileages and reset incremental refresh status
      queryClient.setQueryData(
        queryKey,
        (oldData) =>
          oldData && {
            serverTime: newData.serverTime,
            mileages: [
              ...(oldData as ApiMileageListResponse).mileages.map((o) => newData.mileages.find((p) => p.uuid === o.uuid) || o),
              ...newData.mileages.filter((o) => !(oldData as ApiMileageListResponse).mileages.find((p) => p.uuid === o.uuid))
            ],
            incrementalRefreshStatus: "ok"
          }
      );
      lastUpdate.QK_MILEAGE_LIST = newData.serverTime;
    } catch {
      // Request failed, set incremental refresh status to "error"
      queryClient.setQueryData(
        queryKey,
        (oldData) =>
          oldData && {
            ...(oldData as ApiMileageListResponse),
            incrementalRefreshStatus: "error"
          }
      );
      console.log("refreshMileages failed");
    }
  }
};

// User config has no changedSince param and should always be fully downloaded
export const refreshUserConfiguration = async () => {
  queryClient.invalidateQueries([QK_USER_CONFIGURATION, { userId: currentUserId() }]);
};

export const refreshAllUserConfigurations = async () => {
  queryClient.invalidateQueries([QK_USER_CONFIGURATION]);
};

// Refresh all non-static queries
export const refreshAll = async (hardRefresh?: boolean) => {
  refreshReports(hardRefresh);
  refreshExpenses();
  refreshInboxes(hardRefresh);
  refreshMileages(hardRefresh);
  refreshUserConfiguration();
};

// Refresh all approval-related queries
// Hit this after doing report archival or approval operations
export const refreshAllApproval = async () => {
  queryClient.invalidateQueries(QK_APPROVALS_PENDING_COUNT);
  queryClient.invalidateQueries(QK_APPROVALS_CLASSIC);
  queryClient.invalidateQueries(QK_APPROVALS_ADVANCED);
};
