import moment from "moment";
import i18n from "../i18n/i18nConfig";
import { v4 as uuidv4 } from "uuid";
import { languageCodesWithCommaSeparator } from "./constants";

/**
 * Capitalize a string. (First letter to uppper)
 * @param {string} inputstring - The string to be capitalized
 * @returns {string} The capitalized string
 */
export const capitalize = (inputstring: string): string => {
  let retString = "";
  if (inputstring) {
    retString = inputstring.charAt(0).toUpperCase() + inputstring.slice(1);
  }
  return retString;
};

/**
 * Calculate the VAT part of a sum that already includes VAT
 * @param {number} vatPercent - VAT Percent in integer percentages
 * @param {number} totalValue - Total value in integer cents
 * @returns {number} The total VAT in integer cents rounded to the nearest whole cent
 */
export const calculateVat = (vatPercent: number, totalValue: number): number => {
  return Math.round(totalValue * (vatPercent / (100 + vatPercent)));
};

/**
 * Calculate VAT for a sum that does not already include VAT
 * @param {number} vatPercent - VAT Percent in integer percentages
 * @param {number} totalValue - Total value in integer cents
 * @returns {number} The total VAT in integer cents rounded to the nearest whole cent
 */
export const calculateVatFromNetSum = (vatPercent: number, totalValue: number): number => {
  return Math.round(totalValue * (vatPercent / 100));
};

/**
 * Returns a location string from a country and city name
 * @param {string} countryName - Country name
 * @param {string} cityName - city name
 */
export const getLocationString = (countryName: string = "", cityName: string = ""): string => {
  if (cityName) {
    return `${cityName}, ${countryName}`;
  }
  return countryName;
};

/**
 * Date range to formatted string
 * Uses a provided locale, or defaults the global one that should be set in language options through momentjs. (This means you should probably never actually provide a locale)
 * Renders only start date if dates are equal or no ends date is provided
 * @param {date} Date | string - the date to format
 * @param {date} Date | string - the date to format
 * @returns {string} The formatted string
 */
export const formatDateRange = (starts: Date | string, ends?: Date | string, locale?: string): string => {
  // Apparently faster than momentjs' rendering
  const languageTag = locale || moment.locale();
  const start = new Date(starts);
  const end = new Date(ends || starts);
  if (starts === ends || !ends) {
    // Only render first date
    const startsString = start.toLocaleDateString(languageTag, {
      day: "2-digit",
      month: "short",
      year: "numeric"
    });
    return startsString;
  }
  const startsString = start.toLocaleDateString(languageTag, {
    day: "2-digit",
    month: "short"
  });
  const stopsString = end.toLocaleDateString(languageTag, {
    day: "2-digit",
    month: "short",
    year: "numeric"
  });
  return `${startsString} - ${stopsString}`;
};

/**
 * Returns a formatted version of a monetary value
 * @param {number} hundredths - Monetary value in hundredths (as it is mostly stored in TT)
 */
export const formatMoney = (hundredths: number = 0): string => {
  if (isNaN(hundredths)) return "";
  const ret = (hundredths / 100)
    .toFixed(2)
    .replace(".", ",")
    .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return localizeDecimalSeparator(ret);
};

/**
 * Returns the decimal separator by locale (the "language" key in store).
 * If no locale is provided, we try to pull it from the current i18n config, then default to norwegian.
 */
export const getDecimalSeparator = (locale?: string): string => {
  const l = locale || i18n.language || "nb";
  if (languageCodesWithCommaSeparator.includes(l)) return ",";
  return ".";
};

/* Takes a string representing a number, and ensures decimal separator(s) are localized */
export const localizeDecimalSeparator = (inputString: string): string => {
  const separator = getDecimalSeparator();
  return inputString.replace(".", separator).replace(",", separator);
};

/**
 * Generates a new UUID in our v4 lowercase TravelText format
 * @returns a new UUID in lowercase
 */
export const uuid = (): string => {
  return uuidv4();
};

export const sleep = async (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const nullOrUndefined = (obj: any) => {
  return obj === undefined || obj === null;
};
