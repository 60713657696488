/*eslint camelcase: 0*/
import moment from "moment";
import i18n from "../../i18n/i18nConfig";
import {
  autoExpenseGroupConstructor,
  autoExpenseConstructor,
  segmentDayConstructor
} from "../../constructors/reportCalculationConstructors";
import { getRateBySegment, getRateBySegmentDay } from "./autoExpenses";

// V16

// Valid until ?
// Fixes two bugs
//1: You get a full extra day if you are more than 6 hours into a new day if it is not the first day
//2: If you have skip a day (less than 6 hours in a day on a journey), the next day will count like a new first day

// Static values for this version
const official_diet_norway = 733;

const official_diet_taxfree_part_private_or_withcooking = 205;
const official_diet_taxfree_part_nocooking = 315;

const official_diet_daytrip_6_12 = 289;
const official_diet_daytrip_12_plus = 537;

const official_overnight_extra = 430;
const official_nondomestic_extra = 491;

const meal_reduction_domestic_breakfast = 0.2;
const meal_reduction_domestic_lunch = 0.3;
const meal_reduction_domestic_dinner = 0.5;

const meal_reduction_nondomestic_breakfast = 0.1;
const meal_reduction_nondomestic_lunch = 0.4;
const meal_reduction_nondomestic_dinner = 0.5;

// Checks if a trip is considered "overnight" if either of the following is true:
// - Lasts for at least MinTotalHours hours
// - If MinNightHours > 0, at least this many hours were spent between 22:00 and 06:00
const isOvernight = (starts, stops, minTotalHours, minNightHours) => {
  const start = moment(starts);
  const stop = moment(stops);
  let ret = start.diff(stop, "hours", true) >= minTotalHours;

  if (minNightHours > 0) {
    if (start.hours() >= 22 || stop.hours() < 6) {
      // Segment starts inside the 22-06 timespan. If we're still within the 22-06 timespan MinNightHours hours later, and that time is before the segment stops date, it's an overnight trip
      const end = start.add(minNightHours, "h");
      if (end <= stop && (end.hours() < 6 || (end.hours() === 6 && end.minutes === 0))) ret = true;
    } else {
      // Segment starts outside the 22-06 timespan. If the segment lasts longer than 03:00 the following day, it's an overnight trip
      const nextDay = start.add(1, "d");
      const earliestEnd = nextDay.hours(3).minutes(0).seconds(0);
      if (earliestEnd <= stops) ret = true;
    }
  }
  return ret;
};

const generateAutoExpense = (segmentDay, dayrate, report, domestic, overnight, locationName, autoExpenseType) => {
  // Build an expense for this timespan
  const expense = autoExpenseConstructor();
  expense.autoExpenseType = autoExpenseType;
  expense.fromDate = segmentDay.from;
  expense.geoCityId = segmentDay.segment.cityId;
  expense.geoCountryId = segmentDay.segment.countryId;

  expense.location = locationName;
  expense.sum = dayrate;
  expense.toDate = segmentDay.to;

  // Has the user set any meal-based overrides? This will reduce the total compensation
  // Filter on overrides that actually does something
  const aeo = report.autoExpenseOverrides
    .filter((o) => o.excludeDiet || o.excludedLodging || o.freeBreakfast || o.freeLunch || o.freeDinner)
    .find((o) => moment(o.expenseDate).isSame(moment(segmentDay.from), "day"));

  if (aeo) {
    // Meal reductions
    const meal_reduction_breakfast = domestic
      ? meal_reduction_domestic_breakfast
      : meal_reduction_nondomestic_breakfast;
    const meal_reduction_lunch = domestic ? meal_reduction_domestic_lunch : meal_reduction_nondomestic_lunch;
    const meal_reduction_dinner = domestic ? meal_reduction_domestic_dinner : meal_reduction_nondomestic_dinner;

    if (aeo.freeBreakfast) {
      expense.sum -= Math.round(dayrate * meal_reduction_breakfast);
      expense.breakfastReductionSum = Math.round(dayrate * meal_reduction_breakfast);
    }
    if (aeo.freeLunch) {
      expense.sum -= Math.round(dayrate * meal_reduction_lunch);
      expense.lunchReductionSum = Math.round(dayrate * meal_reduction_lunch);
    }
    if (aeo.freeDinner) {
      expense.sum -= Math.round(dayrate * meal_reduction_dinner);
      expense.dinnerReductionSum = Math.round(dayrate * meal_reduction_dinner);
    }
    if (!aeo.freeBreakfast && !aeo.freeDinner && !aeo.freeLunch) expense.sum = 0; // ... in case of rounding issues
    if (aeo.excludeDiet) expense.sum = 0;

    expense.freeBreakfast = aeo.freeBreakfast ? 1 : 0;
    expense.freeLunch = aeo.freeLunch ? 1 : 0;
    expense.freeDinner = aeo.freeDinner ? 1 : 0;
    expense.excluded = aeo.excludeDiet ? 1 : 0;
  }

  if (expense.sum < 0) expense.sum = 0; // Sanity check

  // Extended fields for export usage
  expense.hours = moment(segmentDay.to).diff(moment(segmentDay.from), "hours", true);
  expense.units = 1;
  expense.unitSum = expense.sum;
  expense.domestic = domestic ? 1 : 0;
  expense.overnight = overnight ? 1 : 0;
  expense.lodgingType = segmentDay.segment.lodgingType;
  expense.farFromHome = segmentDay.segment.farFromHome ? 1 : 0;
  expense.originalSum = dayrate;

  // By default, autoexpenses are tax free.
  // For overnight trips where the night was *not* spent in a hotel, however, parts of the autoexpense might be taxable.
  if (expense.overnight && segmentDay.segment.lodgingType !== 1) {
    let taxfreebase = official_diet_taxfree_part_private_or_withcooking; // For lodging types 2 ("pensjonat med kok") and 4 ("privat/ulegitimert")
    if (segmentDay.segment.lodgingType === 3) taxfreebase = official_diet_taxfree_part_nocooking; // For lodging type 3 ("pensjonat uten kok")

    const taxfreefactor = dayrate === 0 ? 1 : (taxfreebase * 100) / dayrate; // Prevent DBZ
    expense.taxfreeSum = Math.round(expense.sum * taxfreefactor);
    expense.taxableSum = expense.sum - expense.taxfreeSum;

    // If the report uses Ratetype 4 (taxfree rates), override the main sum to reflect just the taxfree part. This is now the sum that will be used for payouts and everything else.
    if (report.rateType === 4) {
      expense.sum = expense.taxfreeSum;

      // The following lines were added on 2017-01-25
      // Both UnitSum and OriginalSum are only used by webexport, and must also be updated in case we're using taxfree rates
      // The *ReductionSum fields are used by webexport as well as a cosmetic field on the PDF export
      // These changes should probably have been done in a new AutoExpenses revision, but the complaint came in from customers who has trouble exporting existing reports since we introduced taxfree rates, so we'll chance a "live update" this time
      expense.unitSum = expense.taxfreeSum;
      expense.originalSum = Math.round(dayrate * taxfreefactor);
      expense.breakfastReductionSum = Math.round(expense.breakfastReductionSum * taxfreefactor);
      expense.lunchReductionSum = Math.round(expense.lunchReductionSum * taxfreefactor);
      expense.dinnerReductionSum = Math.round(expense.dinnerReductionSum * taxfreefactor);
    }
  }

  return expense;
};

export const getAutoExpensesV16 = (report) => {
  const ret = []; // autoexpensegroups
  if (report.rateType !== 2 && report.rateType !== 3 && report.rateType !== 4) return ret; // No rates at all? Return an empty list
  if (report.rateType === 2 && report.rateCustomDiet === 0) return ret; // Custom rates = 0, no rates will be returned. This should not happen
  if (report.reportSegments.length === 0) return ret; // No segments exists, this whould not happen

  // Clone segments to preserve original, and order by date
  let orderedSegments = report.reportSegments.filter((o) => !o.deleted).map((o) => Object.assign({}, o));
  orderedSegments.sort((a, b) => new Date(a.starts) - new Date(b.starts));

  // Create groups
  orderedSegments.forEach((segment) => {
    const orderedSegment = segment;
    const diet_expensetype_label = report.rateType === 3 || report.rateType === 4 ? "state_diet" : "custom_diet";
    // Create an autoexpense group for diet
    const agDiet = autoExpenseGroupConstructor();
    agDiet.autoExpenseType = diet_expensetype_label;
    // "state_diet" is the wrapper term exposed in API and elsewhere for both the classic rates and the new tax free rates
    // This might change in the future if people need to differentiate them
    agDiet.fromDate = orderedSegment.starts;
    agDiet.toDate = orderedSegment.stops;

    orderedSegment.diet = agDiet;
    if (orderedSegment.countryId === 164) {
      //Norway
      orderedSegment.diet.location = i18n.t("calculation.norway");
      let dietdesc = i18n.t("calculation.dietCalculation");
      if (report.rateType === 2) dietdesc = i18n.t("calculation.ownRates");
      if (report.rateType === 4) dietdesc = i18n.t("calculation.taxfreeRates");
      orderedSegment.diet.description = `${dietdesc} ${i18n.t("for")} ${i18n.t("calculation.norway")}`;

      // Create an autoexpesensgroup for "Nattillegget" (Domestic only)
      const agNightExtra = autoExpenseGroupConstructor();
      agNightExtra.autoExpenseType = "state_overnight_extra";
      agNightExtra.fromDate = orderedSegment.starts;
      agNightExtra.toDate = orderedSegment.stops;
      agNightExtra.location = "Norge";
      orderedSegment.nightExtra = agNightExtra;
    } else {
      //abroad
      const location = getRateBySegment(orderedSegment);
      orderedSegment.diet.location = location.officialPlaceName;
      let dietdesc = i18n.t("calculation.dietCalculation");
      if (report.rateType === 2) dietdesc = i18n.t("calculation.ownRates");
      if (report.rateType === 4) dietdesc = i18n.t("calculation.taxfreeRates");
      orderedSegment.diet.description = `${dietdesc} ${i18n.t("for")} ${agDiet.location}`;

      //Create an autoexpensegroup for "Kompensasjons-/Utenlandstillegget" (Non-domestic only)
      const agNondomesticExtra = autoExpenseGroupConstructor();
      agNondomesticExtra.autoExpenseType = "state_nondomestic_extra";
      agNondomesticExtra.fromDate = orderedSegment.starts;
      agNondomesticExtra.toDate = orderedSegment.stops;
      orderedSegment.nonDomesticExtra = agNondomesticExtra;

      orderedSegment.nonDomesticExtra.location = location.officialPlaceName;
    }

    return orderedSegment;
  });

  const start = moment(orderedSegments[0].starts);
  const stop = moment(orderedSegments[orderedSegments.length - 1].stops);
  const lastDayHours = stop.diff(start, "hours", true) % 24;

  let totalDays = Math.floor(stop.diff(start, "days", true));

  // Add a final day if it is more than 6 hours
  if (lastDayHours >= 6) totalDays++;

  // Has not been away longer than 6 hours, no diet for you my friend
  if (!totalDays || totalDays <= 0) return [];

  // Sanity check, probably a typo from the user
  if (totalDays > 1000) return ret;

  let currStart = start;
  let segmentDays = new Array(totalDays);
  let currDay = 0;
  let segmentPause = false;
  let firstDayInTravel = true;

  while (currDay < totalDays) {
    let longestDay = null;
    let totalMinutesInDay = 0;
    let currStop = moment(currStart).add(24, "hours");
    if (currStop.isAfter(stop)) {
      currStop = stop;
    }

    let longestOverlap = 0;
    orderedSegments.forEach((segment) => { // eslint-disable-line
      //first remove those that dont overlap
      const segmentStarts = moment(segment.starts);
      const segmentStops = moment(segment.stops);
      if (!segmentStops.isBefore(currStart) && !segmentStarts.isAfter(currStop)) {
        if (segmentPause) {
          currStart = segmentStarts;
          currStop = moment(currStart).add(24, "hours");
          if (currStop > stop) currStop = stop;
          segmentPause = false;
        }
        const overlapStart = currStart.isAfter(segmentStarts) ? currStart : segmentStarts;
        const overlapStop = currStop.isBefore(segmentStops) ? currStop : segmentStops;

        const totalMinutes = moment(overlapStop).diff(moment(overlapStart), "minutes");

        totalMinutesInDay += totalMinutes;
        if (totalMinutes >= longestOverlap) {
          longestDay = segmentDayConstructor({
            from: currStart,
            to: currStop,
            segment,
            firstDay: firstDayInTravel
          });
          longestOverlap = totalMinutes;
        }
      }
    });
    firstDayInTravel = false;

    if (totalMinutesInDay >= 360) {
      //6 hours
      segmentDays[currDay] = longestDay;
    } else {
      //adjust the stop of the previous day on segment pause
      if (currDay >= 1) {
        const prevday = segmentDays[currDay - 1];
        if (prevday && prevday.to > prevday.segment.stops) {
          prevday.to = prevday.segment.stops;
        }
      }
      segmentPause = true;
      firstDayInTravel = true;
    }
    currDay++;
    currStart = moment(currStart).add(24, "hours");
  }

  segmentDays = segmentDays
    .filter((day) => day)
    .map((segmentDay) => { // eslint-disable-line
      // Look for a location/diet rate per overnight stay, based on a matching geographic location. If this returns null, it's a domestic segment and we should use the norwegian official rate
      const location = getRateBySegmentDay(segmentDay);

      // Is this a domestic or non-domestic day? If location is null, it's domestic.
      const is_domestic = location == null;

      // Figure out which diet full dayrate to use (in hundredths). Start by assuming the standard Norwegian official rate for either hotel (LodgingType 1) or private (LodgingType 4) lodging
      let daily_diet = official_diet_norway * 100;

      // Override if this is a non-domestic segment, and we found a matching foreign rate
      if (!is_domestic) daily_diet = location.diet * 100;

      // Override if a custom dayrate is specified
      if (report.rateType === 2) daily_diet = report.rateCustomDiet;

      const diet_expensetype_label = report.rateType === 3 || report.rateType === 4 ? "state_diet" : "custom_diet";

      // Main calculation
      if (is_domestic) {
        if (
          segmentDay.isFullDay() ||
          isOvernight(segmentDay.from, segmentDay.to, 24, 5) ||
          (!segmentDay.firstDay && moment(segmentDay.to).diff(moment(segmentDay.from), "hours", true) >= 6)
        ) {
          // Generate the full expense
          const expense = generateAutoExpense(
            segmentDay,
            daily_diet,
            report,
            true,
            true,
            "Norge",
            diet_expensetype_label
          );
          segmentDay.segment.diet.autoExpenses.push(expense);
        } else if (segmentDay.segment.farFromHome) {
          // Partial day
          // Add 1 line official_diet_daytrip_* if FarFromHome (more than 15km from home/office) is checked
          const hours = Math.floor(moment(segmentDay.to).diff(moment(segmentDay.from), "hours", true));
          let rate = 0;
          if (hours >= 6) rate = official_diet_daytrip_6_12 * 100;
          if (hours >= 12) rate = official_diet_daytrip_12_plus * 100;

          if (rate > 0) {
            const expense = generateAutoExpense(segmentDay, rate, report, true, false, "Norge", diet_expensetype_label);
            segmentDay.segment.diet.autoExpenses.push(expense);
          }
        }

        // Check for "Nattillegget"; extra compensation per overnight stay. Triggered and calculated as a single-expense autoexpensegroup under the following conditions:
        // * Domestic segment
        // * Official rates
        // * IncludeNightExtra is checked by the user
        // * One or more overnight stays (checked within main wrapper)
        if ((report.rateType === 3 || report.rateType === 4) && segmentDay.segment.includeNightExtra === 1) {
          // One night = 6 hours between 22 and 06
          if (isOvernight(segmentDay.from, segmentDay.to, 21, 6)) {
            const nightexpense = autoExpenseConstructor();
            nightexpense.autoExpenseType = "state_overnight_extra";
            nightexpense.fromDate = segmentDay.from;
            nightexpense.geoCityId = segmentDay.segment.cityId;
            nightexpense.geoCountryId = segmentDay.segment.countryId;
            nightexpense.location = "Norge";
            nightexpense.sum = official_overnight_extra * 100;
            nightexpense.toDate = segmentDay.to;

            nightexpense.hours = moment(segmentDay.to).diff(moment(segmentDay.from), "hours", true);
            nightexpense.units = 1;
            nightexpense.unitSum = official_overnight_extra * 100;
            nightexpense.domestic = 1;
            nightexpense.overnight = 1;
            nightexpense.lodgingType = segmentDay.segment.lodgingType;
            nightexpense.farFromHome = segmentDay.segment.farFromHome ? 1 : 0;
            nightexpense.originalSum = nightexpense.sum;
            nightexpense.breakfastReductionSum = 0;
            nightexpense.lunchReductionSum = 0;
            nightexpense.dinnerReductionSum = 0;

            segmentDay.segment.nightExtra.description = `${i18n.t(
              "calculation.nightExtra"
            )} kr ${official_overnight_extra} ${i18n.t("calculation.perNight")}`;
            segmentDay.segment.nightExtra.autoExpenses.push(nightexpense);
          }
        }
      } else {
        //not domestic
        if (
          segmentDay.isFullDay() ||
          moment(segmentDay.to).diff(moment(segmentDay.from), "hours", true) >= 12 ||
          (!segmentDay.firstDay && moment(segmentDay.to).diff(moment(segmentDay.from), "hours", true) >= 6)
        ) {
          //add a full diet
          const expense = generateAutoExpense(
            segmentDay,
            daily_diet,
            report,
            false,
            true,
            location.officialPlaceName,
            diet_expensetype_label
          );
          segmentDay.segment.diet.autoExpenses.push(expense);
        } else if (moment(segmentDay.to).diff(moment(segmentDay.from), "hours", true) >= 6) {
          // Add 1 line daily_diet * (2/3)
          const rate = Math.floor(daily_diet * 0.6666667);
          const expense = generateAutoExpense(
            segmentDay,
            rate,
            report,
            false,
            false,
            location.officialPlaceName,
            diet_expensetype_label
          );
          segmentDay.segment.diet.autoExpenses.push(expense);
        }
        // Check for "Kompensasjons/Utenlandstillegget"; extra compensation for non-domestic trips. Triggered and calculated as a single-expense autoexpensegroup under the following conditions:
        // * Non-domestic segment
        // * Official rates
        // * IncludeOvernightAbroadExtra is checked by the user
        // * The total segment length is 12 hour or more
        if (
          (report.rateType === 3 || report.rateType === 4) &&
          segmentDay.segment.includeOvernightAbroadExtra === 1 &&
          moment(segmentDay.to).diff(moment(segmentDay.from), "hours", true) >= 12
        ) {
          // Add a official_nondomestic_extra per night
          const nondomesticexpense = generateAutoExpense();
          nondomesticexpense.autoExpenseType = "state_nondomestic_extra";
          nondomesticexpense.fromDate = segmentDay.from;
          nondomesticexpense.geoCityId = location.geoCityId;
          nondomesticexpense.geoCountryId = location.geoCountryId;
          nondomesticexpense.location = location.officialPlaceName;
          nondomesticexpense.sum = official_nondomestic_extra * 100;
          nondomesticexpense.toDate = segmentDay.to;

          nondomesticexpense.hours = moment(segmentDay.to).diff(moment(segmentDay.from), "hours", true);
          nondomesticexpense.units = 1;
          nondomesticexpense.unitSum = official_nondomestic_extra * 100;
          nondomesticexpense.domestic = 0;
          nondomesticexpense.overnight = 1;
          nondomesticexpense.lodgingType = segmentDay.segment.lodgingType;
          nondomesticexpense.farFromHome = segmentDay.segment.farFromHome ? 1 : 0;
          nondomesticexpense.originalSum = nondomesticexpense.sum;
          nondomesticexpense.breakfastReductionSum = 0;
          nondomesticexpense.lunchReductionSum = 0;
          nondomesticexpense.dinnerReductionSum = 0;

          const day = segmentDay;

          day.segment.nonDomesticExtra.description = `${i18n.t("calculation.nonDomesticExtra")} ${i18n.t("for")} ${
            location.officialPlaceName
          }, ${official_nondomestic_extra} ${i18n.t("calculation.perDayTaxable")}`;
          day.segment.nonDomesticExtra.autoExpenses.push(nondomesticexpense);

          return day;
        }
      }
    });

  orderedSegments = orderedSegments.map((segment) => {
    const orderedSegment = segment;

    //merge all nightextras if there are more than 1
    if (orderedSegment.nightExtra && orderedSegment.nightExtra.autoExpenses.length > 1) {
      const numnights = orderedSegment.nightExtra.autoExpenses.length;
      orderedSegment.nightExtra.autoExpenses[0].units = numnights;
      orderedSegment.nightExtra.autoExpenses[0].sum *= numnights;
      orderedSegment.nightExtra.autoExpenses.length = 1;
      orderedSegment.nightExtra.description = `${i18n.t("calculation.nightExtra")}, ${numnights} ${i18n.t(
        "calculation.nights"
      )} x kr ${official_overnight_extra}`;
    }
    //merge all nondomestic extras if there are more than 1
    if (orderedSegment.nonDomesticExtra && orderedSegment.nonDomesticExtra.autoExpenses.count > 1) {
      const numDays = orderedSegment.nonDomesticExtra.autoExpenses.count;
      orderedSegment.nonDomesticExtra.autoExpenses[0].units = numDays;
      orderedSegment.nonDomesticExtra.autoExpenses[0].sum *= numDays;
      orderedSegment.nonDomesticExtra.autoExpenses.length = 1;
      orderedSegment.nonDomesticExtra.description = `${i18n.t("calculation.Utenlandstillegg")} ${i18n.t("for")} ${
        orderedSegment.nonDomesticExtra.location
      }, ${numDays} ${i18n.t("calculation.days")} x kr ${official_nondomestic_extra} (${i18n.t(
        "calculation.taxable"
      )})`;
    }
    // Return any groups that contains lines
    if (orderedSegment.diet && orderedSegment.diet.autoExpenses.length > 0) ret.push(orderedSegment.diet);
    if (orderedSegment.nightExtra && orderedSegment.nightExtra.autoExpenses.length > 0)
      ret.push(orderedSegment.nightExtra);
    if (orderedSegment.nonDomesticExtra && orderedSegment.nonDomesticExtra.autoExpenses.length > 0)
      ret.push(orderedSegment.nonDomesticExtra);

    return orderedSegment;
  });

  return ret;
};
