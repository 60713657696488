import moment from "moment";
import i18n from "../i18n/i18nConfig";
import { CommandResult } from "../types";
import { getExpenseTypeDescription } from "./expenseUtils";

// Generates a human-friendly message based on the result from an executed command
export function generateFriendlyResponseMessage(cmdresult: CommandResult) {
  let statusType = "success";
  let message = "";
  const emptyUuid = "00000000-0000-0000-0000-000000000000";

  if (cmdresult.status === 0) {
    // enum CommandResultInterpretedAs { Unknown = 0, StartReport = 1, StopReport = 2, AddExpenseToReport = 10, AddExpenseStandalone = 11, AddDrivingStandalone = 12,
    // StartSegment = 20, InboxOnly = 30 }
    if (cmdresult.interpretedAs === 0) message = i18n.t("commandResponse.commandNotUnderstood");

    if (cmdresult.interpretedAs === 1) {
      const { description } = cmdresult.reportDetails;

      const from = moment(cmdresult.segmentDetails.starts).format("DD.MM");
      message = i18n.t("commandResponse.travelReportStartedFrom", {
        description,
        from
      });

      if (cmdresult.segmentDetails.starts !== cmdresult.segmentDetails.stops) {
        const to = moment(cmdresult.segmentDetails.stops).format("DD.MM");
        message = i18n.t("commandResponse.travelReportStartedFromTo", {
          description,
          from,
          to
        });
      }
      message = `${message}.`;
    }

    if (cmdresult.interpretedAs === 2) {
      const { description } = cmdresult.reportDetails;
      message = i18n.t("commandResponse.reportStoppedToday", { description });
    }

    if (cmdresult.interpretedAs === 10 || cmdresult.interpretedAs === 11 || cmdresult.interpretedAs === 12) {
      const { expenseTypeId, expenseTypeText, currency, unitPrice, unitPriceNOK, units, unitName, starts } = cmdresult.entryDetails;

      // USD 1 / kr 2 (3 km)
      let expenseValues = "";
      if (currency !== "NOK") expenseValues = `${currency} ${(unitPrice * units) / 100} / `;
      expenseValues = `${expenseValues}kr ${(unitPriceNOK * units) / 100}`;
      if (unitName === "Km") expenseValues = `${expenseValues} (${units} km)`;

      const date = moment(starts).format("DD.MM");

      let withReceiptTranslated = "";
      const { uuid, attachmentUuid } = cmdresult.inboxDetails;
      if ((uuid && uuid !== emptyUuid) || (attachmentUuid && attachmentUuid !== emptyUuid)) {
        withReceiptTranslated = ` ${i18n.t("commandResponse.withReceipt")}`;
      }

      let targetTranslated = "";
      if (cmdresult.interpretedAs === 10) {
        const { description } = cmdresult.reportDetails;
        targetTranslated = i18n.t("commandResponse.targetReport", {
          reportDescription: description
        });
      }
      if (cmdresult.interpretedAs === 11) {
        targetTranslated = i18n.t("commandResponse.targetExpenses");
      }
      if (cmdresult.interpretedAs === 12) {
        targetTranslated = i18n.t("commandResponse.targetMileage");
      }

      const translatedExpenseTypeText = getExpenseTypeDescription({
        expenseTypeText: expenseTypeText,
        expenseTypeId: expenseTypeId
      });

      message = i18n.t("commandResponse.expenseCreated", {
        expenseType: translatedExpenseTypeText,
        expenseValues,
        date,
        withReceiptTranslated,
        targetTranslated
      });

      if ((cmdresult.interpretedAs === 10 || cmdresult.interpretedAs === 11) && cmdresult.entryDetails.suggestions.length > 0) {
        const suggestions = cmdresult.entryDetails.suggestions
          .map((o) =>
            getExpenseTypeDescription({
              expenseTypeText: o
            })
          )
          .join(", ");
        message = `${message} (${i18n.t("commandResponse.expenseTypeSuggestions", { suggestions })})`;
      }
    }

    if (cmdresult.interpretedAs === 20) {
      const starts = moment(cmdresult.segmentDetails.starts).format("DD.MM");
      const stops = moment(cmdresult.segmentDetails.stops).format("DD.MM");
      const { description } = cmdresult.reportDetails;

      let location = i18n.t("commandResponse.unknownLocation");
      const { cityName, countryName } = cmdresult.segmentDetails;
      if (cityName !== "") {
        location = `${cityName}, ${countryName}`;
      } else if (countryName !== "") {
        location = countryName;
      }

      message = i18n.t("commandResponse.createdNewDestinationStarts", {
        starts,
        location,
        description
      });
      if (starts !== stops)
        message = i18n.t("commandResponse.createdNewDestinationStartsStops", {
          starts,
          stops,
          location,
          description
        });

      if (cmdresult.warning === 1) {
        message = `${message} ${i18n.t("commandResponse.segmentWarningTimeAdjusted")}`;
      }
      if (cmdresult.warning === 2) {
        message = `${message} ${i18n.t("commandResponse.segmentWarningExistingAdjusted")}`;
      }
    }

    if (cmdresult.interpretedAs === 30) message = i18n.t("commandResponse.receiptAddedToInbox");
  } else {
    statusType = "warning";
    if (cmdresult.error === 0) {
      message = i18n.t("commandResponse.errorUnknown");
    }
    if (cmdresult.error === 1) {
      message = i18n.t("commandResponse.errorNoActiveReports");
    }
    if (cmdresult.error === 2) {
      message = i18n.t("commandResponse.errorReportIsArchived");
    }
    if (cmdresult.error === 3) {
      message = i18n.t("commandResponse.errorReportIsInvalid");
    }
  }

  return { message, statusType };
}
