/* eslint no-eval: "off"*/
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactNode } from "react";
import i18n from "../shared/i18n/i18nConfig";
import { BusEvent } from "../utils/useBus";

export interface TourStep {
  selector?: string;
  globalEvent?: BusEvent;
  content: string | ReactNode;
}
// Available tours
export type TourName =
  | "overviewPage"
  | "reportsPage"
  | "expensesPage"
  | "mileagesPage"
  | "inboxPage"
  | "travelReportPage"
  | "expenseReportPage"
  | "mileageReportPage"
  | "expenseEditor"
  | "mileageEditor"
  | "segmentEditor";

export const getTour = (tourName: TourName): TourStep[] => {
  const tour: TourStep[] = eval(tourName).map((o: TourStep) => ({
    selector: o.selector,
    globalEvent: o.globalEvent,
    content: <div>{i18n.t(`tours.${o.content}`)}</div>
  }));
  return tour;
};

const overviewPage: TourStep[] = [
  {
    selector: "",
    content: "welcome"
  },
  {
    selector: ".homepage-header-tourstart",
    content: "tourStart"
  },
  {
    selector: "",
    content: "homepage"
  },
  {
    selector: "#createButtons_TravelReport",
    content: "createTravelReport"
  },
  {
    selector: "#createButtons_ExpenseReport",
    content: "createExpenseReport"
  },
  {
    selector: "#createButtons_MileageReport",
    content: "createMileageReport"
  },
  {
    selector: ".report-list",
    content: "reportListHomepage"
  },
  {
    selector: ".inbox-list",
    content: "inboxListHomepage"
  },
  {
    selector: ".report-list .widgets",
    content: "listWidgets"
  },
  {
    selector: "#userMenu",
    content: "topMenu"
  },
  {
    selector: "#commandBox",
    content: "commandBox"
  },
  {
    selector: "#navbar_Reports",
    content: "navbarReports",
    globalEvent: "OPEN_SIDEBAR"
  },
  {
    selector: "#navbar_Expenses",
    content: "navbarExpenses"
  },
  {
    selector: "#navbar_Mileages",
    content: "navbarMileages"
  },
  {
    selector: "#navbar_Inbox",
    content: "navbarInbox"
  },
  {
    selector: ".homepage-header-tourstart",
    content: "homepageEnd"
  }
];

const reportsPage: TourStep[] = [
  {
    selector: "",
    content: "reportsPage"
  },
  {
    selector: "#createButtons_TravelReport",
    content: "createTravelReport"
  },
  {
    selector: "#createButtons_ExpenseReport",
    content: "createExpenseReport"
  },
  {
    selector: "#createButtons_MileageReport",
    content: "createMileageReport"
  },
  {
    selector: ".report-list",
    content: "reportList"
  },
  {
    selector: ".report-list .widgets",
    content: "listWidgets"
  },
  {
    // Selector not always present, uncomment when skipMissing is added to reactour
    // selector: ".report-list .item-attach-button",
    content: "reportListAttach"
  },
  {
    // Selector not always present, uncomment when skipMissing is added to reactour
    // selector: ".report-list .item-actions .dropdown.btn-group",
    content: "reportListTrash"
  },
  {
    selector: "#userMenu",
    content: "topMenu"
  },
  {
    selector: "#commandBox",
    content: "commandBox"
  },
  {
    selector: "#navbar_Overview",
    content: "navbarOverview"
  },
  {
    selector: "#navbar_Expenses",
    content: "navbarExpenses"
  },
  {
    selector: "#navbar_Mileages",
    content: "navbarMileages"
  },
  {
    selector: "#navbar_Inbox",
    content: "navbarInbox"
  }
];

const expensesPage: TourStep[] = [
  {
    selector: "",
    content: "expensesPage"
  },
  {
    selector: "#createButtons_Expense",
    content: "createExpense"
  },
  {
    selector: "#createButtons_ExpenseReportFromExpenses",
    content: "gatherExpenses"
  },
  {
    selector: ".expense-list",
    content: "expenseList"
  },
  {
    selector: ".expense-list .widgets",
    content: "listWidgets"
  },
  {
    // Selector not always present, uncomment when skipMissing is added to reactour
    // selector: ".expense-list .expense-delete-button",
    content: "expenseListTrash"
  },
  {
    // Selector not always present, uncomment when skipMissing is added to reactour
    // selector: ".expense-list .expense-attach-button",
    content: "expenseListAttach"
  },
  {
    selector: "#userMenu",
    content: "topMenu"
  },
  {
    selector: "#commandBox",
    content: "commandBox"
  },
  {
    selector: "#navbar_Overview",
    content: "navbarOverview",
    globalEvent: "OPEN_SIDEBAR"
  },
  {
    selector: "#navbar_Reports",
    content: "navbarReports"
  },
  {
    selector: "#navbar_Mileages",
    content: "navbarMileages"
  },
  {
    selector: "#navbar_Inbox",
    content: "navbarInbox"
  }
];

const mileagesPage: TourStep[] = [
  {
    selector: "",
    content: "mileagesPage"
  },
  {
    selector: "#createButtons_Mileage",
    content: "createDrivingExpense"
  },
  {
    selector: "#createButtons_MileageReportFromExpenses",
    content: "gatherDrivingExpenses"
  },
  {
    selector: ".expense-list",
    content: "drivingList"
  },
  {
    selector: ".mileage-list",
    content: "mileageList"
  },
  {
    selector: ".expense-list .widgets",
    content: "listWidgets"
  },
  {
    // Selector not always present, uncomment when skipMissing is added to reactour
    // selector: ".expense-list .expense-delete-button",
    content: "mileageListTrash"
  },
  {
    // Selector not always present, uncomment when skipMissing is added to reactour
    // selector: ".expense-list .expense-attach-button",
    content: "mileageListAttach"
  },
  {
    selector: "#userMenu",
    content: "topMenu"
  },
  {
    selector: "#commandBox",
    content: "commandBox"
  },
  {
    selector: "#navbar_Overview",
    content: "navbarOverview",
    globalEvent: "OPEN_SIDEBAR"
  },
  {
    selector: "#navbar_Reports",
    content: "navbarReports"
  },
  {
    selector: "#navbar_Expenses",
    content: "navbarExpenses"
  },
  {
    selector: "#navbar_Inbox",
    content: "navbarInbox"
  }
];

const inboxPage: TourStep[] = [
  {
    selector: "",
    content: "inboxPage"
  },
  {
    selector: ".inbox-list",
    content: "inboxList"
  },
  {
    selector: ".uploader-dropzone",
    content: "inboxUpload"
  },
  {
    selector: ".inbox-list .widgets",
    content: "listWidgets"
  },
  {
    // Selector not always present, uncomment when skipMissing is added to reactour
    // selector: ".inbox-list .inbox-delete-button",
    content: "inboxListTrash"
  },
  {
    // Selector not always present, uncomment when skipMissing is added to reactour
    // selector: ".inbox-list .inbox-attach-button",
    content: "inboxListAttach"
  },
  {
    selector: "#userMenu",
    content: "topMenu"
  },
  {
    selector: "#commandBox",
    content: "commandBox"
  },
  {
    selector: "#navbar_Overview",
    content: "navbarOverview",
    globalEvent: "OPEN_SIDEBAR"
  },
  {
    selector: "#navbar_Reports",
    content: "navbarReports"
  },
  {
    selector: "#navbar_Expenses",
    content: "navbarExpenses"
  },
  {
    selector: "#navbar_Mileages",
    content: "navbarMileages"
  }
];

const travelReportPage: TourStep[] = [
  {
    selector: "",
    content: "travelReportPage"
  },
  {
    selector: "#reportHeaderMundaneFields",
    content: "reportHeaderMundaneFields"
  },
  {
    selector: "#reportHeaderRateType",
    content: "reportDiet"
  },
  {
    selector: "#reportHeaderAdvance",
    content: "reportAdvance"
  },
  {
    selector: "#reportSegments",
    content: "reportSegments"
  },
  {
    selector: "#reportNewSegment",
    content: "reportNewSegment"
  },
  {
    selector: "#reportAllSegments",
    content: "reportAllSegments"
  },
  {
    // Selector not always present, uncomment when skipMissing is added to reactour
    // selector: "#reportAutoExpenseOverrides",
    content: "reportAutoExpenseOverrides"
  },
  {
    selector: "#reportExpenses",
    content: "reportExpenses"
  },
  {
    selector: "#reportNewExpense",
    content: "reportNewExpense"
  },
  {
    selector: "#reportPickExpense",
    content: "reportPickExpense"
  },
  {
    selector: "#reportAllExpenses",
    content: "reportAllExpenses"
  },
  {
    selector: ".attachments-component",
    content: "reportAttachments"
  },
  {
    selector: ".report-bottom-calculation",
    content: "reportCalculation"
  },
  {
    selector: ".report-bottom-buttons",
    content: "reportButtons"
  }
];

const expenseReportPage: TourStep[] = [
  {
    selector: "",
    content: "expenseReportPage"
  },
  {
    selector: "#reportHeaderMundaneFields",
    content: "reportHeaderMundaneFields"
  },
  {
    selector: "#reportHeaderAdvance",
    content: "reportAdvance"
  },
  {
    selector: "#reportExpenses",
    content: "reportExpenses"
  },
  {
    selector: "#reportNewExpense",
    content: "reportNewExpense"
  },
  {
    selector: "#reportPickExpense",
    content: "reportPickExpense"
  },
  {
    selector: "#reportAllExpenses",
    content: "reportAllExpenses"
  },
  {
    selector: ".attachments-component",
    content: "reportAttachments"
  },
  {
    selector: ".report-bottom-calculation",
    content: "reportCalculation"
  },
  {
    selector: ".report-bottom-buttons",
    content: "reportButtons"
  }
];

const mileageReportPage: TourStep[] = [
  {
    selector: "",
    content: "mileageReportPage"
  },
  {
    selector: "#reportHeaderMundaneFields",
    content: "reportHeaderMundaneFields"
  },
  {
    selector: "#reportHeaderAdvance",
    content: "reportAdvance"
  },
  {
    selector: "#reportExpenses",
    content: "reportMileages"
  },
  {
    selector: "#reportNewExpense",
    content: "reportNewMileage"
  },
  {
    selector: "#reportPickExpense",
    content: "reportPickMileage"
  },
  {
    selector: "#reportAllExpenses",
    content: "reportAllMileages"
  },
  {
    selector: ".attachments-component",
    content: "reportAttachments"
  },
  {
    selector: ".report-bottom-calculation",
    content: "reportCalculation"
  },
  {
    selector: ".report-bottom-buttons",
    content: "reportButtons"
  }
];

const expenseEditor: TourStep[] = [
  {
    selector: "",
    content: "expenseEditor"
  }
];

const mileageEditor: TourStep[] = [
  {
    selector: "",
    content: "mileageEditor"
  }
];

const segmentEditor: TourStep[] = [
  {
    selector: "",
    content: "segmentEditor"
  }
];
