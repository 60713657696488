import i18next from "i18next";
import moment from "moment";
import merge from "lodash/merge";
import norwegianTranslations from "./locales/nb/translations.json";
import englishTranslations from "./locales/en/translations.json";
import norwegianTheme from "./locales/themes/nb/translations.json";
import englishTheme from "./locales/themes/en/translations.json";

i18next.init({
  fallbackLng: "nb",
  ns: ["translations"],
  defaultNS: "translations",
  nsSeparator: ":::",
  resources: {
    nb: {
      translations: merge(norwegianTranslations, norwegianTheme)
    },
    en: {
      translations: merge(englishTranslations, englishTheme)
    }
  },
  interpolation: {
    format: (value, format, lng) => {
      //To have a custom format for a string write eg (the minus is to not escape items like /): {{-starts, date hh:mm}}
      //If no format is submitted behind date, it will default to L LT
      if (format && format.substring(0, 4) === "date") {
        let formatString = format.substring(5);
        if (!formatString) {
          formatString = "L LT";
        }
        return moment(value).format(formatString);
      }
      return value;
    }
  }
});

export default i18next;
