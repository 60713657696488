import { FunctionComponent, useEffect } from "react";
import { useLocation } from "react-router-dom";
//import ReactGA from "react-ga";

// Deprecated after switching from React GA to Azure application insights, remove when we're sure about the switch
const RouteLogger: FunctionComponent = ({ children }) => {
  const location = useLocation();
  const url = location ? (location.pathname ? location.pathname : "") : "";

  useEffect(() => {
    // Send to event tracking
    //ReactGA.pageview(url);
  }, [url]);

  return <div id="root-wrapper-1">{children}</div>;
};

export default RouteLogger;
