import moment from "moment";
import { InboxAttachment } from "../types";
import { uuid } from "../utils/helpers";

interface InboxAttachmentConstructorOptions {
  inboxUuid: string;
  originalFilename: string;
  mimeType: string;
  blobUuid: string;
}
export const inboxAttachmentConstructor = (opts: InboxAttachmentConstructorOptions): InboxAttachment => {
  const { inboxUuid, originalFilename, mimeType, blobUuid } = opts;
  const inboxAttachmentUuid = uuid();
  const now = moment().toISOString();
  return {
    uuid: inboxAttachmentUuid,
    changed: now,
    inboxUuid,
    originalFilename,
    mimeType,
    blobUuid,
    deleted: "",
    dirty: now
  };
};
