// This module assumes that the i18n config resides in the following file src/utils/i18n/i18nConfig.ts
import moment from "moment";
import i18n from "../i18n/i18nConfig";
import { setApiLanguage } from "../api/api";
import { useStore } from "../store/store";
import { SupportedLanguages } from "../types";

// Loads a new language and sets it in all relevant spots
export const changeLanguage = async (language: SupportedLanguages) => {
  await i18n.changeLanguage(language, () => {
    moment.locale(language);
    moment.updateLocale(language, { monthsParseExact: false });
    setApiLanguage(language);
    useStore.setState({ language });
  });
};
