import moment from "moment";
import { ReportCalculation, ReportCalculationAutoExpense, ReportCalculationItem, ReportSegment } from "../types";

// The main calculation object for a report
export const calculationConstructor = (): ReportCalculation => {
  const now = moment().toISOString();
  return {
    sumTotal: 0,
    generated: now,
    generatedBy: "",
    sumTotalInclNonRefund: 0, // Added SumTotalInclNonRefund to display all costs on a report including expenses marked Refund: 0
    sumTotalInclNonRefundVat: 0,
    overrideSum: 0,
    overrideSumEnabled: 0,
    calculationItems: []
  };
};

// A single calculationItem; an aggregated sum with one of the following itemTypes:
//1 = Advance (a sum already paid to the person that should be subtracted from the total expenses)
//2 = Expense (the expenses contained in Report.Expenses)
//3 = AutoExpense (automatically generated expenses, typically by daily diet rates)
//4 = Vat (there will be one calculationitem for each VAT percentage)
//5 = Expense, but only the ones from category 2 classed as driving
//6 = Expense, but only the ones from category 2 NOT classed as driving
//7 = The tax free part of driving expenses from category 5 (as of 01.01.2016: kr 3.80 per km for regular and electric car entries, not the other transport methods)
//8 = The taxable (rest) part of driving expenses from category 5
//9 = Custom type for Biltema: if this is a Biltema report that triggered their refund cap rules, this itemtype will exist and contain the original value for SumTotal (while SumTotal will be overwritten by the cap)
interface CalculationItemConstructorOptions {
  description?: string;
  itemType?: number;
  sum?: number;
  basisSum?: number;
  autoExpenses?: ReportCalculationAutoExpense[];
}
export const calculationItemConstructor = (opts: CalculationItemConstructorOptions): ReportCalculationItem => {
  const { description = "", itemType = 0, sum = 0, basisSum = 0, autoExpenses = [] } = opts;
  return {
    itemType,
    description,
    sum,
    basisSum,
    autoExpenses
  };
};

// A group of autoexpenses (autogenerated expenses), usually maps to a reportsegment's location
export const autoExpenseGroupConstructor = () => {
  const now = moment().toISOString();
  return {
    autoExpenses: [],
    fromDate: now,
    toDate: now,
    autoExpenseType: "", //"state_diet", "state_lodging", "custom_daily", "state_overnight_extra", "state_nondomestic_extra"
    location: "",
    description: ""
  };
};

// An autoexpense (autogenerated expense) for an atomic interval, usually a day for dietary autoexpenses
export const autoExpenseConstructor = (): ReportCalculationAutoExpense => {
  const now = moment().toISOString();
  return {
    fromDate: now,
    toDate: now,
    geoCityId: 0,
    geoCountryId: 0,
    location: "",
    autoExpenseType: "", //"state_diet" (Diett), "state_lodging" (Losji), "custom_daily" (Egendefinert døgnsats), "state_overnight_extra" (Nattillegg), "state_nondomestic_extra" (Kompensasjons/utenlandstillegg)
    sum: 0,
    excluded: 0,
    freeBreakfast: 0,
    freeLunch: 0,
    freeDinner: 0,

    mappings: [], // used for export mappings server side

    // Expanded version. These fields must be populated in all calculators from V8 and up. Used for mapping purposes.
    hours: 0, // If daily diet, number of hours (handled as a double serverside, fractions accepted)
    units: 1, // If multiple units (for example multiple days for state_overnight_extra), save it here
    unitSum: 0, // Sum NOK per unit as above in hundredths
    domestic: 1, // 1/0
    overnight: 0, // 1/0
    lodgingType: 1, // 1=Hotel, 2=Pensjonat with cooking, 3=Pensjonat without cooking, 4=None/private/no receipt
    farFromHome: 1, // 1/0
    originalSum: 0,
    breakfastReductionSum: 0,
    lunchReductionSum: 0,
    dinnerReductionSum: 0,
    isAdministrativeRatebased: 0,

    // As of V12 we're also calculating how much of the autoexpense is taxable/taxfree
    taxableSum: 0,
    taxfreeSum: 0
  };
};

interface SegmentDayConstructorOptions {
  from: string;
  to: string;
  segment: ReportSegment;
  firstDay: boolean;
}
export const segmentDayConstructor = (opts: SegmentDayConstructorOptions) => {
  const { from, to, segment, firstDay } = opts;
  return {
    from,
    to,
    segment,
    firstDay,
    isFullDay: () => {
      return Math.ceil(moment(to).diff(moment(from), "hours", true)) >= 24;
    }
  };
};
