import i18n from "../i18n/i18nConfig";
import moment from "moment";
import { capitalize, uuid } from "../utils/helpers";
import { reportCustomValueConstructor } from "../constructors/reportCustomValueConstructor";
import {
  Attachment,
  ExchangeRatesByDate,
  Expense,
  GeoCountry,
  GeoLocation,
  OfficialRateSet,
  Report,
  ReportCustomValue,
  UserConfiguration
} from "../types";
import { reportConstructor } from "../constructors/reportConstructor";
import { calculateReport } from "./reportCalculation/calculator";
import * as reportApi from "../api/report";

/**
 * Returns the first and last datetime for a report based on its segments
 * @param {object} report - The report object
 * @returns {{starts: Date, stops: Date}} an object describing the date range for the report
 */
export const dateRangeByReport = (report: Report): { starts: string; stops: string } => {
  const segments = report.reportSegments.filter((o) => o && !o.deleted);
  if (segments.length > 0 && segments[0]) {
    //sort the segments by start date
    segments.sort((a, b) => new Date(a.starts).getTime() - new Date(b.starts).getTime());

    const starts = segments[0].starts;
    //We do not allow overlapping segments so the last element by start order will be the last element to stop as well
    const stops = segments[segments.length - 1].stops;
    return { starts, stops };
  }
  return { starts: report.created || report.changed || "", stops: report.created || report.changed || "" };
};

/**
 * Returns the status of a report based on its state of archival/approval
 * @param {object} report - The report object
 * @returns {{type: number, text: string}} Status of the report. -1 = unknown, 0 = open (in progress), 1 = awaiting approval, 2 = finished, 3 = open (approval rejected/cancelled)
 */
export const reportStatusByReport = (report: Report): { type: -1 | 0 | 1 | 2 | 3; text: string } => {
  if (report.status === 1) {
    if (report.reportApprovals && report.reportApprovals.length > 0) {
      const lastStatus = report.reportApprovals[report.reportApprovals.length - 1].approvalStatus;
      if (lastStatus === "rejected" || lastStatus === "cancelled") {
        return { type: 3, text: i18n.t("reportStatus.rejected") };
      }
    }

    let isRejected = false;
    let lastApprovalDate = null;

    // If we have any classic report approvals, check if the last one was a rejection/cancellation
    if (report.reportApprovals && report.reportApprovals.length > 0) {
      const lastApproval = report.reportApprovals[report.reportApprovals.length - 1];
      lastApprovalDate = moment(lastApproval.changed);
      if (lastApproval.approvalStatus === "rejected" || lastApproval.approvalStatus === "cancelled") isRejected = true;
    }

    // Check for any newer advanced report approvals
    if (report.advancedApprovalAttempts && report.advancedApprovalAttempts.length > 0) {
      const lastApproval = report.advancedApprovalAttempts[report.advancedApprovalAttempts.length - 1];
      if (lastApprovalDate === null || moment(lastApproval.changed).isAfter(lastApprovalDate)) {
        isRejected = lastApproval.status === 2 || lastApproval.status === 4;
      }
    }

    if (isRejected) return { type: 3, text: i18n.t("reportStatus.rejected") };

    return { type: 0, text: i18n.t("reportStatus.inProgress") };
  }

  if (report.status === 2) {
    if (!report.requiresApproval) return { type: 2, text: i18n.t("reportStatus.finished") };
    if (report.requiresApproval) {
      if (!report.approved) return { type: 1, text: i18n.t("reportStatus.forApproval") };
      if (report.approved) return { type: 2, text: i18n.t("reportStatus.finished") };
    }
  }
  return { type: -1, text: "Unknown" };
};

// Grab attachments from a report and its expenses
export const attachmentsByReport = (report: Report): Attachment[] => {
  const attachments = report.attachments.filter((a) => !a.deleted);
  report.expenses.forEach((expense) => {
    const expenseAttachments = expense.attachments.filter((a) => !a.deleted);
    attachments.push(...expenseAttachments);
  });
  return attachments;
};

// Report Customvalue constructor wrapper provided for use by children
export const createReportCustomValue = (reportUuid: string, reportCustomFieldId: number, value: string): ReportCustomValue => {
  const customValue = reportCustomValueConstructor({
    reportUuid,
    reportCustomFieldId,
    value
  });
  return customValue;
};

/**
 * Clones and returns a report with any child entities, adding new UUIDs, parent entity UUIDs, and flagging everything with locallyChanged to make it ready for saving.
 * If you are cloning/copying a report and you made any changes to it (like removing expenses), make sure the report has been properly recalculated before passing it in here.
 * You can pass in an optional parameter for the new report's uuid, but make sure it's randomly generated.
 * @param {Object} report - A complete report
 * @param {string} newDescription - Optional, force a different description than the original
 * @param {forceNewReportUuid} - Optional, specify UUID instead of a randomly generated one
 */
export const cloneReport = (report: Report, newDescription?: string, forceNewReportUuid?: string) => {
  const newReportUuid = forceNewReportUuid ? forceNewReportUuid.toLowerCase() : uuid();
  const now = moment().toISOString();

  let newReport: Report = {
    ...report,
    uuid: newReportUuid,
    reportApprovals: [],
    description: newDescription || report.description,
    approved: false,
    approvalChainId: 0,
    archivedDate: "1753-01-01T00:00:00+01:00",
    changed: "1753-01-01T00:00:00+01:00",
    created: now,
    status: 1,
    referenceNumber: "",
    dirty: now
  };

  if (newReport.attachments)
    newReport.attachments = newReport.attachments.map((o) => ({
      ...o,
      uuid: uuid(),
      reportUuid: newReportUuid,
      dirty: now
    }));

  if (newReport.autoExpenseOverrides)
    newReport.autoExpenseOverrides = newReport.autoExpenseOverrides.map((o) => ({
      ...o,
      uuid: uuid(),
      reportUuid: newReportUuid,
      dirty: now
    }));

  if (newReport.expenses)
    newReport.expenses = newReport.expenses.map((o) => {
      const newExpenseUuid = uuid();
      return {
        ...o,
        uuid: newExpenseUuid,
        reportUuid: newReportUuid,
        dirty: now,
        attachments: o.attachments
          ? o.attachments.map((p) => ({
              ...p,
              uuid: uuid(),
              expenseUuid: newExpenseUuid,
              dirty: now
            }))
          : [],
        expenseCustomValues: o.expenseCustomValues
          ? o.expenseCustomValues.map((p) => ({
              ...p,
              uuid: uuid(),
              expenseUuid: newExpenseUuid,
              dirty: now
            }))
          : []
      };
    });

  if (newReport.reportCustomValues)
    newReport.reportCustomValues = newReport.reportCustomValues.map((o) => ({
      ...o,
      uuid: uuid(),
      reportUuid: newReportUuid,
      dirty: now
    }));

  if (newReport.reportSegments)
    newReport.reportSegments = newReport.reportSegments.map((o) => ({
      ...o,
      uuid: uuid(),
      reportUuid: newReportUuid,
      dirty: now
    }));

  return newReport;
};

// Create a new report based on existing expenses, and move all provided expenses onto it
export const createReportFromExpenses = async (
  reportType: 1 | 2 | 3, // 1 = Travel, 2 = Expense, 3 = Driving
  expenses: Expense[],
  userConfiguration: UserConfiguration,
  officialRates: OfficialRateSet[],
  countries: GeoCountry[],
  majorCities: GeoLocation[],
  exchangeRatesNBSeed: ExchangeRatesByDate[]
): Promise<Report> => {
  const now = moment().toISOString();

  // Configuration shorthands
  const personConfig = userConfiguration.person;
  const productConfig = userConfiguration.product;
  const defaultCustomDietRate =
    (productConfig.defaultCustomDietRate && !isNaN(Number(productConfig.defaultCustomDietRate)) ? Number(productConfig.defaultCustomDietRate) : 0) *
    100;

  // Create the report
  const newReport = reportConstructor({
    reportType,
    personId: personConfig.id,
    companyName: personConfig.parentName,
    countryName: productConfig.defaultLocation.countryName,
    countryId: productConfig.defaultLocation.countryId,
    cityName: productConfig.defaultLocation.cityName,
    cityId: productConfig.defaultLocation.cityId,
    rateCustomDiet: defaultCustomDietRate,
    starts: now,
    stops: now
  });

  // Modify the expenses
  const updatedExpenses = expenses.map((expense) => {
    expense.reportUuid = newReport.uuid;
    expense.dirty = now;
    return expense;
  });

  // Get the min/max date for the expenses to generate a good report description
  const expenseDates = updatedExpenses.map((o) => o.starts).sort((a, b) => moment(a).unix() - moment(b).unix());
  const minDate = expenseDates[0];
  const maxDate = expenseDates[expenseDates.length - 1];

  const minDateString = new Date(minDate).toLocaleDateString(i18n.language, {
    day: "2-digit",
    month: "short"
  });
  const maxDateString = new Date(maxDate).toLocaleDateString(i18n.language, {
    day: "2-digit",
    month: "short"
  });

  const desc = reportType === 3 ? capitalize(i18n.t("mileage_plural")) : capitalize(i18n.t("expense_plural"));
  newReport.description = `${desc} ${minDateString} - ${maxDateString}`;

  // Add the expenses to report, and recalculate it
  newReport.expenses = updatedExpenses;
  newReport.calculation = calculateReport(newReport, userConfiguration, officialRates, countries, majorCities, exchangeRatesNBSeed);

  // Return the report. Remember to save it, and call refreshExpenses() to make sure the expenses we just grabbed are removed from the query
  return newReport;
};

/**
 * Pulls a report from the backend, recalculates it, then saves it back and returns it.
 * This function should always be called immediately before archiving a report, and is a safeguard against archiving reports with an outdated calculation.
 * @param {string} reportUuid - UUID of the report to be recalculated
 * @param {UserConfiguration} userConfiguration - The current user's configuration, used by calculator
 * @param {OfficialRateSet[]} officialRates - Up to date array of official ratesets, used by calculator
 * @param {GeoCountry[]} countries - Up to date country list, used by calculator
 * @param {GeoLocation[]} majorCities - Up to date major city list, used by calculator
 */
export const recalculateAndSaveReportFromBackend = async (
  reportUuid: string,
  userConfiguration: UserConfiguration,
  officialRates: OfficialRateSet[],
  countries: GeoCountry[],
  majorCities: GeoLocation[],
  exchangeRatesNBSeed: ExchangeRatesByDate[]
): Promise<Report> => {
  const now = moment().toISOString();
  const report = await reportApi.details(reportUuid);

  report.calculation = calculateReport(report, userConfiguration, officialRates, countries, majorCities, exchangeRatesNBSeed);
  report.dirty = now;

  const savedReport = await reportApi.save(report);
  return savedReport;
};
