import Icon from "../common/Icon";
import i18n from "../../shared/i18n/i18nConfig";
import { Inbox } from "../../shared/types";
import { showPreview } from "../../utils/webclientStore";

interface InboxThumbnailProps {
  inboxElement: Inbox;
  renderMode: "table" | "drawer" | "cards";
}
const InboxThumbnail = ({ inboxElement, renderMode }: InboxThumbnailProps) => {
  let iconName = "email";
  let title = i18n.t("attachmentMeta.email");
  if (inboxElement.source !== "email") {
    if (inboxElement.source === "cardtransaction") {
      iconName = "creditCard";
      title = i18n.t("attachmentMeta.cardtransaction");
    } else {
      iconName = inboxElement.isHtml ? "formatCode" : "formatText";
      title = inboxElement.isHtml ? i18n.t("attachmentMeta.html") : i18n.t("attachmentMeta.plaintext");
    }
  }

  const thumbnail = (
    <div
      className="thumbnail-wrapper thumbnail-inbox-button"
      role="button"
      tabIndex={0}
      onClick={() => {
        if (renderMode !== "drawer") {
          showPreview({
            mimeType: inboxElement.isHtml ? "text/html" : "text/plain",
            body: inboxElement.body,
            blobUuid: "",
            source: inboxElement.source
          });
        }
      }}
    >
      <div className="thumbnail-inbox" title={title}>
        <Icon icon={iconName} />
        {renderMode === "drawer" && (
          <div className="sub-details">
            {inboxElement.subject ||
              new Date(inboxElement.changed).toLocaleDateString(i18n.language, {
                day: "2-digit",
                month: "short",
                year: "numeric"
              })}
          </div>
        )}
      </div>
    </div>
  );

  return thumbnail;
};

export default InboxThumbnail;
