import moment from "moment";
import { Inbox, InboxAttachment } from "../types";
import { uuid } from "../utils/helpers";

interface InboxConstructorOptions {
  personId: number;
  subject: string;
  source: string;
  inboxAttachments: InboxAttachment[];
}
export const inboxConstructor = (opts: InboxConstructorOptions): Inbox => {
  const { personId, subject, source, inboxAttachments } = opts;
  const inboxUuid = uuid();
  const inboxBlobUuid = uuid();
  const created = moment().toISOString();
  const changed = moment().toISOString();
  return {
    uuid: inboxUuid,
    created,
    changed,
    fromAddress: "",
    toAddress: "",
    personId,
    body: "",
    isHtml: false,
    subject,
    source,
    deleted: "",
    blobUuid: inboxBlobUuid, // Only used for references to raw MMS zip files in backend
    inboxAttachments,
    dirty: created
  };
};
