import React, { useState } from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";
import moment from "moment";
import { Row, Col } from "react-bootstrap";
import ReportAutoExpenseOverrideListItem from "../reportAutoExpenseOverride/ReportAutoExpenseOverrideListItem";
import { autoExpenseOverrideConstructor } from "../../shared/constructors/autoExpenseOverrideConstructor";
import Icon from "../common/Icon";
import CheckboxInput from "../common/CheckboxInput";
import "../../styles/report.css";
import "../../styles/reportautoexpenseoverrides.css";
import { AutoExpenseOverride, ReportCalculation, ReportCalculationAutoExpense } from "../../shared/types";

interface ReportAutoExpenseOverridesProps {
  autoExpenseOverrides: AutoExpenseOverride[];
  reportUuid: string;
  onChange: (overrides: AutoExpenseOverride[]) => void;
  reportCalculation: ReportCalculation;
  readOnly?: boolean;
}
const ReportAutoExpenseOverrides = ({ autoExpenseOverrides, reportUuid, onChange, reportCalculation, readOnly }: ReportAutoExpenseOverridesProps) => {
  const [t] = useTranslation();

  const [debugCounter, setDebugCounter] = useState(0); // Increased when clicking the header, if >= 5 then set showDebugValues = true for items

  // A single field on a single override date has changed.
  // Merge change into the list and throw it up the chain
  const onChangeOverride = (partialOverride: Partial<AutoExpenseOverride>) => {
    const now = moment().toISOString();

    if (!partialOverride.expenseDate) throw Error("expenseDate is required");

    // Grab existing overrides, except any on the same date
    // Only use day part to ignore timezone differences
    // const overrides = [...autoExpenseOverrides].filter((o) => !moment(o.expenseDate).isSame(partialOverride.expenseDate, "day"));
    const overrides = [...autoExpenseOverrides].filter(
      (o) => !partialOverride.expenseDate || moment(o.expenseDate).format("YYYY-MM-DD") !== moment(partialOverride.expenseDate).format("YYYY-MM-DD")
    );

    // Look for existing overrides on the same date
    // There should never be more than one, but use .filter in case this somehow happened to avoid weird behaviour, ref. TTN-728
    // This ensures that if there are more than one, they all have the same value, and any downstream logic will behave the same whether using .find by date, the first/last one, etc
    // Only use day part to ignore timezone differences
    // const existing = autoExpenseOverrides.filter((o) => moment(o.expenseDate).isSame(partialOverride.expenseDate, "day"));
    const existing = autoExpenseOverrides.filter(
      (o) => partialOverride.expenseDate && moment(o.expenseDate).format("YYYY-MM-DD") === moment(partialOverride.expenseDate).format("YYYY-MM-DD")
    );

    if (existing.length) {
      // Update the existing overrides and push them back into the list
      const updated = existing.map((o) => {
        return { ...o, ...partialOverride, dirty: now };
      });
      overrides.push(...updated);
      // const updated = { ...existing, ...partialOverride, dirty: now };
      // overrides.push(updated);
    } else {
      // Create a new override, update with partial info, and push it into the list
      const fresh = {
        ...autoExpenseOverrideConstructor({
          reportUuid: reportUuid,
          expenseDate: partialOverride.expenseDate
        }),
        ...partialOverride
      };
      overrides.push(fresh);
    }

    // Pass the overrides back up. Keep even the ones without exclusions, despite overhead, to ensure consistency with backend
    onChange(overrides);
  };

  // Bulk set an override flag for one autoexpense with all its dates
  const onBulkOverride = (
    autoExpenses: ReportCalculationAutoExpense[],
    field: "excludeDiet" | "freeBreakfast" | "freeLunch" | "freeDinner",
    checked: boolean
  ) => {
    const now = moment().toISOString();
    let overrides = [...autoExpenseOverrides]; // All overrides on this report
    for (const expense of autoExpenses) {
      // Each autoexpense in the segment we're bulk overriding (one per day)

      // Update any existing overrides for the same day as this autoexpense
      // There should be max one override per autoexpense, but in case we have several, update them all
      let foundExisting = false;
      const expenseFromDaypart = moment(expense.fromDate).format("YYYY-MM-DD");
      overrides = overrides.map((o) => {
        const overrideDaypart = moment(o.expenseDate).format("YYYY-MM-DD");
        // Only use day part to ignore timezone differences
        // if (moment(o.expenseDate).isSame(expense.fromDate, "day")) {
        if (overrideDaypart === expenseFromDaypart) {
          foundExisting = true;
          return { ...o, [field]: checked, dirty: now };
        } else {
          return { ...o, dirty: now };
        }
      });

      // If we found none  matching the date, create a new override
      if (!foundExisting) {
        // Create a new override, update with partial info, and push it into the list
        const fresh = autoExpenseOverrideConstructor({
          reportUuid: reportUuid,
          expenseDate: expense.fromDate
        });
        fresh[field] = checked;
        overrides.push(fresh);
      }

      ////// Old version, only considering one override per expense date:
      // const i = overrides.findIndex((o) => moment(o.expenseDate).isSame(expense.fromDate, "day"));
      // if (i >= 0) {
      //   overrides[i][field] = checked;
      //   overrides[i].dirty = now;
      // } else {
      //   // Create a new override, update with partial info, and push it into the list
      //   const fresh = autoExpenseOverrideConstructor({
      //     reportUuid: reportUuid,
      //     expenseDate: expense.fromDate
      //   });
      //   fresh[field] = checked;
      //   overrides.push(fresh);
      // }
    }

    // Pass the overrides back up. Keep even the ones without exclusions, despite overhead, to ensure consistency with backend
    onChange(overrides);
  };

  return (
    <div className="report-component" id="reportAutoExpenseOverrides">
      <div className="report-component-header">
        <div className="report-component-title" onClick={() => setDebugCounter(debugCounter + 1)}>
          {t("dietary.dietaryOverrides")}
        </div>
      </div>

      {/* Display all overrides if debugging is active. This helps resolve issues where multiple overrides exist per day. */}
      {debugCounter >= 5 && (
        <table cellSpacing={"10px"} cellPadding={"10px"}>
          <tbody>
            {autoExpenseOverrides
              .sort((a, b) => (a.expenseDate < b.expenseDate ? -1 : 1))
              .map((o) => (
                <tr>
                  <td>{o.expenseDate.toString()}</td>
                  <td>&nbsp;{o.excludeDiet ? 1 : 0}</td>
                  <td>&nbsp;{o.freeBreakfast ? 1 : 0}</td>
                  <td>&nbsp;{o.freeLunch ? 1 : 0}</td>
                  <td>&nbsp;{o.freeDinner ? 1 : 0}</td>
                </tr>
              ))}
          </tbody>
        </table>
      )}

      {reportCalculation.calculationItems
        .filter((item) => item.itemType === 3 && item.autoExpenses.length > 0)
        .map((item) => {
          const rows = item.autoExpenses
            .filter((autoExpense) => autoExpense.autoExpenseType === "state_diet" || autoExpense.autoExpenseType === "custom_diet")
            .map((autoExpense) => (
              <ReportAutoExpenseOverrideListItem
                key={autoExpense.fromDate}
                autoExpense={autoExpense}
                readOnly={readOnly}
                showDebugValues={debugCounter >= 5}
                // Only use day part to ignore timezone differences
                // override={autoExpenseOverrides.find((o) => moment(o.expenseDate).isSame(moment(autoExpense.fromDate), "day"))}
                override={autoExpenseOverrides.find(
                  (o) => moment(o.expenseDate).format("YYYY-MM-DD") === moment(autoExpense.fromDate).format("YYYY-MM-DD")
                )}
                onChangeOverride={onChangeOverride}
              />
            ));

          return (
            <div className="report-autoexpenseoverride-item" key={`${item.description}${item.autoExpenses[0].fromDate}`}>
              <div className="report-autoexpenseoverride-item-header">
                <Row>
                  <Col sm={5} xs={4}>
                    {item.description}
                  </Col>

                  {rows.length > 1 && (
                    <React.Fragment>
                      <Col sm={3} xsHidden className="select-all-desc">
                        {t("dietary.selectAll")}
                      </Col>
                      <Col sm={4} xs={5}>
                        <CheckboxInput
                          field={"excludeDiet"}
                          label={<Icon icon="remove" />}
                          onChange={(o: boolean) => onBulkOverride(item.autoExpenses, "excludeDiet", o)}
                          title={t("dietary.excludeDays")}
                          readOnly={readOnly}
                        />

                        <CheckboxInput
                          field={"freeBreakfast"}
                          label={t("dietary.breakfastSingleLetter")}
                          onChange={(o: boolean) => onBulkOverride(item.autoExpenses, "freeBreakfast", o)}
                          title={t("dietary.excludeBreakfasts")}
                          readOnly={readOnly}
                        />

                        <CheckboxInput
                          field={"freeLunch"}
                          label={t("dietary.lunchSingleLetter")}
                          onChange={(o: boolean) => onBulkOverride(item.autoExpenses, "freeLunch", o)}
                          title={t("dietary.excludeLunches")}
                          readOnly={readOnly}
                        />

                        <CheckboxInput
                          field={"freeDinner"}
                          label={t("dietary.dinnerSingleLetter")}
                          onChange={(o: boolean) => onBulkOverride(item.autoExpenses, "freeDinner", o)}
                          title={t("dietary.excludeDinners")}
                          readOnly={readOnly}
                        />
                      </Col>
                    </React.Fragment>
                  )}
                </Row>
              </div>

              <div className="report-autoexpenseoverride-item-content">{rows}</div>
            </div>
          );
        })}
    </div>
  );
};

ReportAutoExpenseOverrides.propTypes = {
  reportUuid: PropTypes.string,
  reportCalculation: PropTypes.object.isRequired,
  autoExpenseOverrides: PropTypes.array.isRequired,
  readOnly: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};

export default ReportAutoExpenseOverrides;
