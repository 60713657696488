import moment from "moment";
import { Attachment, AttachmentSource, AttachmentAttachedFrom, Expense, Inbox, InboxAttachment, Report } from "../types";
import { uuid } from "../utils/helpers";

// Only provide reportUuid OR expenseUuid, an attachment can only be attached to one entity
interface AttachmentConstructorOptions {
  reportUuid?: string;
  expenseUuid?: string;
  body?: string;
  source?: AttachmentSource;
  subject?: string;
  originalFilename?: string;
  mimeType?: string;
  externalContent?: boolean;
  blobUuid?: string;
  attachedFrom?: AttachmentAttachedFrom;
  inboxUuid?: string;
  inboxAttachmentUuid?: string;
}
export const attachmentConstructor = (opts: AttachmentConstructorOptions): Attachment => {
  const {
    reportUuid = "",
    expenseUuid = "",
    body = "",
    source = "webupload",
    subject = "",
    originalFilename = "",
    mimeType = "",
    externalContent = false,
    blobUuid = "",
    attachedFrom = "webupload",
    inboxUuid = "",
    inboxAttachmentUuid = ""
  } = opts;
  const created = moment().toISOString();
  const attachmentUuid = uuid();
  return {
    uuid: attachmentUuid,
    changed: "",
    reportUuid,
    expenseUuid,
    body,
    source,
    subject,
    originalFilename,
    mimeType,
    externalContent,
    blobUuid,
    attachedFrom,
    inboxUuid,
    inboxAttachmentUuid,
    dirty: created,
    deleted: ""
  };
};

/**
 * Create an attachment that copies the content from an inbox element.
 */
interface AttachmentFromInboxOptions {
  report?: Report; // The report to attach to. Provide either this or expense, not both.
  expense?: Expense; // The expense to attach to.
  inbox: Inbox; // The inbox entity that contains the attachment content
}
export const attachmentFromInbox = (opts: AttachmentFromInboxOptions) => {
  const { report, expense, inbox } = opts;
  if (report && expense) throw new Error("Pass report OR expense to attachmentFromInbox, not both!");
  const attachment = attachmentConstructor({
    reportUuid: report ? report.uuid : "",
    expenseUuid: expense ? expense.uuid : "",
    source: inbox.source === "cardtransaction" ? "cardtransaction" : "email",
    subject: inbox.subject,
    body: inbox.body,
    mimeType: inbox.isHtml ? "text/html" : "text/plain",
    externalContent: false,
    attachedFrom: "inbox",
    inboxUuid: inbox.uuid
  });
  return attachment;
};

/**
 * Create an attachment that points to the blob content of an inbox attachment.
 */
interface AttachmentFromInboxAttachmentOptions {
  report?: Report; // The report to attach to. Provide either this or expense, not both.
  expense?: Expense; // The expense to attach to.
  inboxAttachment: InboxAttachment; // The inboxAttachment entity that contains the attachment content
}
export const attachmentFromInboxAttachment = (opts: AttachmentFromInboxAttachmentOptions) => {
  const { report, expense, inboxAttachment } = opts;
  if (report && expense) throw new Error("Pass report OR expense to attachmentFromInboxAttachment, not both!");

  const attachment = attachmentConstructor({
    reportUuid: report ? report.uuid : "",
    expenseUuid: expense ? expense.uuid : "",
    source: "email",
    originalFilename: inboxAttachment.originalFilename,
    mimeType: inboxAttachment.mimeType,
    externalContent: true,
    blobUuid: inboxAttachment.blobUuid,
    attachedFrom: "inboxattachment",
    inboxAttachmentUuid: inboxAttachment.uuid
  });
  return attachment;
};
