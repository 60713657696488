import React, { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router-dom";

import NavigationPrompt from "react-router-navigation-prompt";
import { Grid, Button, DropdownButton, MenuItem, Modal, FormControl } from "react-bootstrap";
import moment from "moment";
import { PoseGroup } from "react-pose";
import { showToast } from "../../utils/toastWrapper";

import {
  useUserConfiguration,
  saveReport,
  useCountries,
  useMajorCities,
  useOfficialRates,
  useReports,
  saveExpense,
  refreshReports,
  refreshAllApproval,
  useSeedExchangeRatesNB
} from "../../shared/queries/queries";
import { reportSegmentConstructor } from "../../shared/constructors/reportSegmentConstructor";
import { expenseConstructor, ExpenseConstructorOptions } from "../../shared/constructors/expenseConstructor";
import { createPdfExport, emailPdfExport, archive as archiveReport, detailsByAdvancedApprovalAttemptDecision } from "../../shared/api/report";
import { pdfExport } from "../../shared/api/blob";
import { currentExpenseTypesByProductConfig } from "../../shared/utils/currentExpenseTypes";
import ReportApprovalStatus from "./ReportApprovalStatus";
import AdvancedReportApprovalStatus from "./AdvancedReportApprovalStatus";
import ReportHeader from "./ReportHeader";
import ReportSegments from "./ReportSegments";
import ExpenseList from "../expense/ExpenseList";
import ExpenseEditor from "../expense/ExpenseEditor";
import ReportCalculationBox from "./ReportCalculationBox";
import Attachments from "../attachment/Attachments";
import AttachablesDrawer from "../common/AttachablesDrawer";
import AttachablesDrawerPoseWrapper from "../attachment/AttachablesDrawerPoseWrapper";
import CustomFields from "../customField/CustomFields";
import "../../styles/report.css";
import ReportAutoExpenseOverrides from "./ReportAutoExpenseOverrides";
import { calculateReport } from "../../shared/utils/reportCalculation/calculator";
import AttachmentPreview from "../attachment/AttachmentPreview";
import TextFieldGroup from "../common/TextFieldGroup";
import Icon from "../common/Icon";
import Spinner from "../common/Spinner";
import { capitalize, uuid } from "../../shared/utils/helpers";
import { validateReport } from "../../shared/validation/report";
import { useStore } from "../../shared/store/store";
import { cloneReport, recalculateAndSaveReportFromBackend } from "../../shared/utils/reportUtils";
import { getExpenseClone } from "../../shared/utils/expenseUtils";
import {
  ApprovalChain,
  Attachment,
  AutoExpenseOverride,
  Expense,
  ExpenseCustomValue,
  ExpenseType,
  ProductConfigAdvancedApprovalFlow,
  ProductConfiguration,
  Report,
  ReportCustomValue,
  ReportSegment,
  ValidateReportErrors,
  ValidateExpenseErrors
} from "../../shared/types";
import ButtonWithSpinner from "../common/ButtonWithSpinner";
import { applyPolicyToNewExpense, applyPolicyToNewReportSegment } from "../../shared/utils/uxPolicyHelpers";
import { setLastUsedApproverGroupId } from "../../utils/webclientStore";

// The main page for viewing/managing a single report
const ReportPage = () => {
  const [t] = useTranslation();
  const history = useHistory();
  const location = useLocation<{
    externalReport?: Report;
    externalProductConfig?: ProductConfiguration;
    externalDecisionUuid?: string;
    advancedApprovalAttemptDecisionUuid?: string;
    // If advancedApprovalAttemptDecisionUuid is provided, the user is viewing the report as an approver based on an attempt decision UUID
    // This also allows the approver to make certain changes to the report
    // When an approver saves changes to a report based on advancedApprovalAttemptDecisionUuid, make sure to pass the uuid as a param to saveReport
  }>();
  const { uuid: reportUuid } = useParams<{ uuid: string }>(); // The reportUuid to edit, from the path '/report/:uuid'
  const language = useStore((state) => state.language);
  const currentUserId = useStore((state) => state.currentUserId);
  const lastUsedApproverGroupId: number = useStore((state) => state.clientSpecific.lastUsedApproverGroupId);

  ///////////////////////// QUERIES
  const reportsQuery = useReports();
  const userConfigurationQuery = useUserConfiguration();
  const officialRatesQuery = useOfficialRates();
  const countriesQuery = useCountries();
  const majorCitiesQuery = useMajorCities();
  const seedExchangeRatesNBQuery = useSeedExchangeRatesNB();

  ///////////////////////// STATE
  const [currentReport, setCurrentReport] = useState<Report | null>(null);
  const [userId] = useState<number | undefined>(currentUserId);
  const [editorIsDirty, setEditorIsDirty] = useState(false);
  const [previousLanguage, setPreviousLanguage] = useState(language);
  const [forceBailout, setForceBailout] = useState(false);

  // Normally, readonly mode prevents the expense editor from recalculating anything on mount or change
  // Click the component header 10 times to put it into debug mode, and let it override this behaviour and recalculate anyway
  const [ignoreReadOnlyForRecalculations, setIgnoreReadOnlyForRecalculations] = useState(false);
  const [isArchiving, setIsArchiving] = useState(false);
  const [validationErrors, setValidationErrors] = useState<ValidateReportErrors>({
    header: [],
    reportCustomFields: [],
    expenses: [],
    segments: []
  });
  const [approvalComment, setApprovalComment] = useState("");
  const [pdfEmailPromptOpen, setPdfEmailPromptOpen] = useState(false);
  const [pdfRecipient, setPdfRecipient] = useState("");
  const [persistQueued, setPersistQueued] = useState(false);

  const [expensesTitleClickCount, setExpensesTitleClickCount] = useState(0);
  const [currentExpenseToEdit, setCurrentExpenseToEdit] = useState<Expense | null>(null);
  const [additionalExpenses, setAdditionalExpenses] = useState<Expense[]>([]);
  const [showAttachablesDrawerExpense, setShowAttachablesDrawerExpense] = useState(false);
  const [addingExpenseUuid, setAddingExpenseUuid] = useState<string | null>(null);
  const [approverIsEditing, setApproverIsEditing] = useState<boolean>(false);

  // Use to disable approval buttons when the export dropdown is open, since the dropdown menu can overlap the buttons and produce misclicks
  const [reportExportDropdownIsOpen, setReportExportDropdownIsOpen] = useState<boolean>(false);

  ///////////////////////// EXTERNAL REPORT HANDLING
  // Do we have an external report object (typically from another user for approval) passed in from router for viewing?
  const externalReport = location.state ? location.state.externalReport : undefined;
  // External product configuration object passed in from router for rendering external report correctly
  const externalProductConfig = externalReport ? location.state.externalProductConfig : undefined;
  const externalDecisionUuid = location.state ? location.state.externalDecisionUuid : undefined;
  const advancedApprovalAttemptDecisionUuid = location.state ? location.state.advancedApprovalAttemptDecisionUuid : undefined; // User is an advanced approver, and came here from approvals

  // Advanced approvers can make certain changes to a report if the report is currently queued for them:
  // They can save the report, including changes to all subentities
  // They can add or delete subentities, including attachments, but not move them from/to other reports or pick from any drawers
  // They can not clone or delete a report
  // This flag is a limited negation of readonly, and true if the user came here from advanced approvals and the approver edit state is set
  const approverEditingMode = Boolean(advancedApprovalAttemptDecisionUuid && approverIsEditing);

  // If we have an advancedApprovalAttemptDecisionUuid, check if we're currently queued up to decide
  // Use this flag to enable the unlock button for approver edits
  let approverIsQueued = false;
  if (
    advancedApprovalAttemptDecisionUuid &&
    currentReport &&
    currentReport.advancedApprovalAttempts &&
    currentReport.advancedApprovalAttempts.length > 0
  ) {
    currentReport.advancedApprovalAttempts
      .filter((attempt) => attempt.status === 0)
      .forEach((attempt) => {
        if (attempt.decisions.find((decision) => decision.uuid === advancedApprovalAttemptDecisionUuid && decision.decision === 1))
          approverIsQueued = true;
      });
  }

  // Same flag, but for classic approvals. If any of these flags are true, we should show the approve/reject buttons
  let approverIsQueuedClassic = false;
  if (currentReport && currentReport.reportApprovals && currentReport.reportApprovals.length > 0) {
    currentReport.reportApprovals.forEach((approval) => {
      if (approval.approvalStatus === "nextup" && approval.personId === currentUserId) approverIsQueuedClassic = true;
    });
  }

  // Shorthand bool
  const reportIsExternal = !!externalReport;

  ///////////////////////// EFFECTS

  // Redirect to homepage if user is switched
  useEffect(() => {
    if (currentUserId !== userId) setForceBailout(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserId]);
  useEffect(() => {
    if (forceBailout) history.push("/#");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceBailout]);

  // Whenever reportsQuery or externalReport gets fresh data, or we switch users, attempt to find the report and load it into the editor
  useEffect(() => {
    if (externalReport) {
      // If we have an external report, use it instead of looking at local reports
      setCurrentReport(externalReport);
    } else if (
      reportUuid &&
      reportsQuery.data &&
      (!reportsQuery.data.incrementalRefreshStatus || reportsQuery.data.incrementalRefreshStatus !== "loading") // If we're in the middle of an incremental refresh, the query will be outdated and make the UI glitch with old data
    ) {
      // Find the local report
      const report = reportsQuery.data.reports.find((o) => o.uuid === reportUuid && !o.deleted);
      if (report) {
        if (!currentReport) window.scrollTo(0, 0); // If coming from far down the frontpage list, chrome will attempt to maintain scroll distance, we should reset
        setCurrentReport(report);
      } else {
        // Report UUID not found, redirect to homepage
        history.push("/#");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalReport, reportUuid, reportsQuery.data]);

  // Keep default PDF recipient in sync with user's email
  useEffect(() => {
    if (userConfigurationQuery.data) {
      setPdfRecipient(userConfigurationQuery.data.configuration.person.email);
    }
  }, [userConfigurationQuery.data]);

  // If validationErrors has changed, check if any elements failed validation and scroll them into view
  useEffect(() => {
    let failedElement = document.querySelector(
      // Report header, expenselist items, expense fields
      ".has-error, .validation-errors"
    );
    if (!failedElement)
      failedElement = document.querySelector(
        ".validation-error" // Expenselist header
      );
    if (failedElement)
      failedElement.scrollIntoView({
        behavior: "smooth",
        block: "center"
      });
  }, [validationErrors]);

  // If a persist has been queued, persist component state to backend
  useEffect(() => {
    if (persistQueued && currentReport) {
      const isValid = validate(currentReport);
      if ((!readOnly || approverEditingMode) && isValid) {
        // All is well, start saving the report to backend
        saveReport(currentReport, approverEditingMode && advancedApprovalAttemptDecisionUuid ? advancedApprovalAttemptDecisionUuid : undefined)
          .then(() => {
            setEditorIsDirty(false);
            setPersistQueued(false);
            // Normally, saving the report will update the underlying reports query at this point, and refresh the view to match the backend data
            if (approverEditingMode && advancedApprovalAttemptDecisionUuid) {
              // In this case we're saving someone else's report as an approver, and need to manually refresh it:
              detailsByAdvancedApprovalAttemptDecision(advancedApprovalAttemptDecisionUuid).then((external) => {
                setCurrentReport(external.report);
              });
            }
          })
          .catch((err: any) => {
            showToast({ type: "error", title: t("notifications.reportSaveFailed"), text: err?.backendMessage || "" });
            setPersistQueued(false);
          });
      } else {
        // We're not in a state to save the report
        // In most cases we should reset the editorIsDirty flag to let us navigate away, disable save buttons etc
        // if it's due to a validation issue, keep the dirty flag so the user can fix the validation issues and re-attempt to save
        if (isValid) setEditorIsDirty(false);
        setPersistQueued(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [persistQueued]);

  // When the language changes, the report is recalculated with the new language
  // Set the dirty flag so the user remembers to save changes, but only if it differs from the initial/previous language, or this will fire on the initial render
  useEffect(() => {
    if (language !== previousLanguage) {
      setPreviousLanguage(language);
      setEditorIsDirty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  // When the report changes, check if we're either adding an expense or the underlying report was updated after a backend push
  useEffect(() => {
    if (currentReport) {
      // Only execute if there *is* a report
      if (addingExpenseUuid) {
        // We are currently adding a new expense
        // Pick it up from the report, load it into the editor, reset the flag and clear any additional pending expenses (toll passings)
        const expenseToEdit = currentReport.expenses.find((o) => o.uuid === addingExpenseUuid);
        if (expenseToEdit) {
          setCurrentExpenseToEdit(expenseToEdit);
          setAdditionalExpenses([]);
          setAddingExpenseUuid(null);
        }
      } else if (currentExpenseToEdit) {
        // We're currently editing an expense, and the underlying report was updated (either from a backend push or from a change event passed up)
        // Make sure we have the most recent version of the expense loaded into the editor
        const updatedExpense = currentReport.expenses.find((o) => o.uuid === currentExpenseToEdit.uuid);
        if (updatedExpense) setCurrentExpenseToEdit(updatedExpense);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentReport]);

  // Bail out here if we don't have a valid user configuration or all the static data yet
  if (
    !currentReport ||
    !userConfigurationQuery.data ||
    !officialRatesQuery.data ||
    !countriesQuery.data ||
    !majorCitiesQuery.data ||
    !seedExchangeRatesNBQuery.data
  ) {
    return <Spinner size="75px" margin="1em" />;
  }

  ///////////////////////// CONFIGURATION SHORTHANDS
  const userConfiguration = userConfigurationQuery.data.configuration;
  const officialRates = officialRatesQuery.data;
  const countries = countriesQuery.data;
  const majorCities = majorCitiesQuery.data;
  const personId = userConfiguration.person.id;
  const productConfig = userConfiguration.product;
  const defaultLocation = productConfig.defaultLocation;
  const exchangeRatesNBSeed = seedExchangeRatesNBQuery.data;

  ///////////////////////// PARSE CONFIGURATION
  // Approval chains/flows: approvalChains contains the old ones, advancedApprovalFlows the new ones.
  // We must support both until the old type is phased out
  let approvalChains = productConfig.approvalChains ? productConfig.approvalChains.filter((o) => o.active) : [];
  let advancedApprovalFlows = productConfig.advancedApprovalFlows ? productConfig.advancedApprovalFlows.filter((o) => o.active) : [];
  const useApprovals = approvalChains.length > 0 || advancedApprovalFlows.length > 0;
  let useApprovalSuggestions = false;
  let useAdvancedApprovalSuggestions = false;

  // Enforce classic approval policy
  if (productConfig.approvalChainPolicy && productConfig.approvalChainPolicy.chainIds !== undefined) {
    // We have a policy defined, and the array of chain IDs exist (empty is fine)
    if (productConfig.approvalChainPolicy.mode === "enforce") {
      // In enforce mode, only the provided chain IDs are allowed
      approvalChains = approvalChains.filter((chain) => productConfig.approvalChainPolicy.chainIds.includes(chain.id));
    }
    if (productConfig.approvalChainPolicy.mode === "suggest") {
      // In suggest mode, highlight the suggested chains but allow all of them
      useApprovalSuggestions = true;
      approvalChains = approvalChains.map((chain) => ({
        suggested: productConfig.approvalChainPolicy.chainIds.includes(chain.id),
        ...chain
      }));
    }
  }

  // Enforce advanced approval policy (cloned from classic approval policy block above)
  if (productConfig.advancedApprovalFlowPolicy && productConfig.advancedApprovalFlowPolicy.flowIds !== undefined) {
    // We have a policy defined, and the array of flow IDs exist (empty is fine)
    if (productConfig.advancedApprovalFlowPolicy.mode === "enforce") {
      // In enforce mode, only the provided flow IDs are allowed
      advancedApprovalFlows = advancedApprovalFlows.filter((flow) => productConfig.advancedApprovalFlowPolicy.flowIds.includes(flow.id));
    }
    if (productConfig.advancedApprovalFlowPolicy.mode === "suggest") {
      // In suggest mode, highlight the suggested flows but allow all of them
      useAdvancedApprovalSuggestions = true;
      advancedApprovalFlows = advancedApprovalFlows.map((flow) => ({
        suggested: productConfig.advancedApprovalFlowPolicy.flowIds.includes(flow.id),
        ...flow
      }));
    }
  }

  // Grab expensetypes, but use the externally provided ones if they exist, so we can render the right expense types for whoever's report we're looking at
  const expenseTypes = currentExpenseTypesByProductConfig(externalProductConfig || productConfig);

  // Use driving as the default expense type if this is a driving log
  // This is only used in createExpense. If undefined, it will throw. We should still be able to view existing reports without having an available default expensetype, though
  const defaultExpenseType: ExpenseType | undefined =
    currentReport.reportTypeId === 3
      ? expenseTypes.find((o) => o.is_Driving)
      : expenseTypes.find((o) => o.is_Default) || (expenseTypes && expenseTypes[0]);

  // Grab customfields and possible vat exemption
  const reportCustomFields = (externalProductConfig || productConfig).reportCustomFields.filter(
    (o) => o.reportType === 0 || o.reportType === currentReport.reportTypeId
  );
  const expenseCustomFields = (externalProductConfig || productConfig).expenseCustomFields;
  const nonVatEnabled = productConfig.nonVatEnabled ? true : false;

  // Archived or externally loaded reports can not be modified
  const readOnly = isArchiving || externalReport || currentReport.status !== 1 ? true : false;

  // Refresh the calculation continuously
  if (!readOnly || approverEditingMode) {
    const newCalculation = calculateReport(currentReport, userConfiguration, officialRates, countries, majorCities, exchangeRatesNBSeed);
    currentReport.calculation = newCalculation;
  }

  const now = () => moment().toISOString();

  // An approval decision was made
  const decideApproval = (approved: boolean, comment: string, reportUuid: string, externalDecisionUuid?: string) => {
    history.push("/approval", {
      externalApproval: { approved, comment, reportUuid, externalDecisionUuid }
    });
  };

  // This is called from change* handlers to bail out early if the report is locked and we shouldn't override that (berserk mode or other reasons)
  const preventChanges = () => {
    if (approverEditingMode) return false;
    return currentReport.status === 2 && !ignoreReadOnlyForRecalculations;
  };

  // Merge header changes into report
  const changeHeader = (partialHeader: Partial<Report>, fromConstructor?: boolean) => {
    if (preventChanges()) return;
    const report = { ...currentReport, ...partialHeader, dirty: now() };
    report.calculation = calculateReport(report, userConfiguration, officialRates, countries, majorCities, exchangeRatesNBSeed);
    if (!fromConstructor) setEditorIsDirty(true);
    setCurrentReport(report);
  };

  // Replace segment list
  const changeSegments = (segments: ReportSegment[], forcePersist?: boolean) => {
    if (preventChanges()) return;
    // Merge changes into state
    const report = { ...currentReport, dirty: now() };
    report.reportSegments = segments;
    report.calculation = calculateReport(report, userConfiguration, officialRates, countries, majorCities, exchangeRatesNBSeed);
    setEditorIsDirty(true);
    setCurrentReport(report);
    if (forcePersist) setPersistQueued(true);
  };

  // Replace expense list
  const changeExpenses = (expenses: Expense[], forcePersist?: boolean) => {
    if (preventChanges()) return;
    // Merge changes into state
    const report = { ...currentReport, dirty: now() };
    report.expenses = expenses;
    report.calculation = calculateReport(report, userConfiguration, officialRates, countries, majorCities, exchangeRatesNBSeed);
    setEditorIsDirty(true);
    setCurrentReport(report);
    if (forcePersist) setPersistQueued(true);
  };

  // Replace customValue list
  // fromConstructor indicates that the customvalue changes are a result of default value initialization, not actual user input
  const changeCustomValues = (customValues: (ReportCustomValue | ExpenseCustomValue)[], fromConstructor?: boolean) => {
    if (preventChanges()) return;
    // Merge changes into state
    const report = { ...currentReport, dirty: now() };
    report.reportCustomValues = customValues as ReportCustomValue[];
    report.calculation = calculateReport(report, userConfiguration, officialRates, countries, majorCities, exchangeRatesNBSeed);
    // Unless the changes comes from actual user input, don't flag the editor as dirty.
    // This will prevent the "are you sure" modal from popping up if the user is trying to leave without making changes.
    if (!fromConstructor) setEditorIsDirty(true);
    setCurrentReport(report);
  };

  // Replace diet override list
  const changeAutoExpenseOverrides = (overrides: AutoExpenseOverride[]) => {
    if (preventChanges()) return;
    const report = { ...currentReport, dirty: now() };
    report.autoExpenseOverrides = overrides;
    report.calculation = calculateReport(report, userConfiguration, officialRates, countries, majorCities, exchangeRatesNBSeed);
    setEditorIsDirty(true);
    setCurrentReport(report);
  };

  // Segment constructor wrapper provided for use by children
  const createSegment = (starts: string, stops: string): ReportSegment => {
    const segment = reportSegmentConstructor({
      reportUuid: currentReport.uuid,
      starts,
      stops,
      countryName: defaultLocation.countryName,
      countryId: defaultLocation.countryId,
      cityName: defaultLocation.cityName,
      cityId: defaultLocation.cityId
    });
    const completeSegment = applyPolicyToNewReportSegment(segment, productConfig);
    return completeSegment;
  };

  const cancelChanges = () => {
    if (approverEditingMode) {
      history.push("/approval");
    } else {
      history.push("/reports");
    }
  };

  const saveChanges = () => {
    if (currentExpenseToEdit) {
      // Force a persist if an expense is open
      // This ensures all changes to the expense, and any addition expenses not yet created, has been persisted before the backend sync starts
      // We don't need to pass any of the bools, since we're manually hitting setPersistQueued in this function
      persistExpenseToReport(currentExpenseToEdit, additionalExpenses, false, false);
      setAdditionalExpenses([]);
    }
    setPersistQueued(true);
  };

  // Lock the report for further changes and archive it. Might involve starting the approval process depending on company config.
  // The archive-call does not save the report, so make sure all changes are saved before running this!
  const archive = async (chainOrFlowId: number, isAdvanced: boolean) => {
    if (!readOnly) {
      // As a safeguard against outdated calculations due to desync between backend and frontend, always pull a fresh copy from the backend, recalculate, and re-save it before archiving/submitting a report
      const resavedReport = await recalculateAndSaveReportFromBackend(
        currentReport.uuid,
        userConfiguration,
        officialRates,
        countries,
        majorCities,
        exchangeRatesNBSeed
      );
      setCurrentReport(resavedReport);

      // Run standard validation
      if (!validate(resavedReport)) return false;

      // Then run pre-archival validation from UX policy
      if (productConfig?.uxPolicy?.validation?.expensesMustHaveAttachments) {
        // Verify that each expense with receipt=true has at least one direct attachment
        let missingAttachments = false;
        for (const exp of resavedReport.expenses.filter((o) => !o.deleted && o.receipt)) {
          if (exp.attachments.filter((o) => !o.deleted).length < 1) missingAttachments = true;
        }
        if (missingAttachments) {
          showToast({
            autoClose: 20000,
            type: "error",
            title: t("notifications.reportExpensesMissingAttachmentsTitle"),
            text: t("notifications.reportExpensesMissingAttachmentsText")
          });
          return false;
        }
      }

      // All good? Start archiving!
      setIsArchiving(true);

      const approvalChainId = chainOrFlowId && !isAdvanced ? chainOrFlowId : 0;
      const advancedApprovalFlowId = chainOrFlowId && isAdvanced ? chainOrFlowId : 0;
      setLastUsedApproverGroupId(chainOrFlowId);

      archiveReport({ reportUuid: resavedReport.uuid, approvalChainId, advancedApprovalFlowId, language })
        .then(() => {
          showToast({ type: "success", title: t("notifications.reportArchivedTitle"), text: t("notifications.reportArchivedText") });
          refreshReports();
          refreshAllApproval();
          history.push("/");
        })
        .catch((err: any) => {
          setIsArchiving(false);
          showToast({ type: "error", title: t("genericError"), text: err?.backendMessage || "" });
        });
    }
  };

  const copyReport = async (withExpenses: boolean) => {
    const newDescription = `${t("copyOf")} ${currentReport.description}`;
    const newReport = cloneReport(currentReport, newDescription);
    if (!withExpenses) newReport.expenses = [];

    newReport.calculation = calculateReport(newReport, userConfiguration, officialRates, countries, majorCities, exchangeRatesNBSeed);

    saveReport(newReport)
      .then(() => {
        showToast({
          title: t("notifications.reportCopiedTitle"),
          text: t("notifications.reportCopiedText"),
          type: "success"
        });
      })
      .catch((err: any) => {
        showToast({ type: "error", title: t("notifications.reportCopyFailed"), text: err?.backendMessage || "" });
      });
  };

  // Move an expense on this report to standalone expenses
  const moveToStandaloneExpenses = (expense: Expense) => {
    if (preventChanges()) return;

    // Save the expense as standalone
    const updatedExpense = { ...expense, reportUuid: "", dirty: now() };
    saveExpense(updatedExpense)
      .then(() => {
        // Remove from report for recalculation purposes
        const report = { ...currentReport, dirty: now() };
        report.expenses = report.expenses.filter((o) => o.uuid !== updatedExpense.uuid);
        report.calculation = calculateReport(report, userConfiguration, officialRates, countries, majorCities, exchangeRatesNBSeed);
        setEditorIsDirty(true);
        setCurrentReport(report);
        setPersistQueued(true);

        showToast({
          title: t("attachActions.Expense moved"),
          text: t("attachActions.The expense was moved out of the report"),
          type: "success"
        });
      })
      .catch((err: any) => {
        showToast({ type: "error", title: t("notifications.expenseMoveFailed"), text: err?.backendMessage || "" });
      });
  };

  // The expenselist has moved an expense to another report
  // This was likely done from the self-contained attachablesdrawer, so the expense and target report has already been updated
  // Remove the expense from this report, recalculate, and save the report
  const expenseMovedToOtherReport = (expense: Expense) => {
    if (expense && expense.reportUuid && expense.reportUuid === currentReport.uuid) {
      const expensesNew = currentReport.expenses.filter((o) => o.uuid !== expense.uuid);
      changeExpenses(expensesNew, true);
    }
  };

  // Create a PDF export of a report and download it
  const pdfDownload = () => {
    showToast({
      title: t("notifications.reportExportStartedTitle"),
      text: t("notifications.reportExportStartedText"),
      type: "info"
    });
    createPdfExport(currentReport.uuid, language).then((response) => {
      if (!response) {
        showToast({
          title: t("notifications.reportExportFailedTitle"),
          text: t("notifications.reportExportFailedGeneric"),
          type: "error"
        }); // Export error
        return;
      }
      pdfExport(response).then((res) => {
        showToast({
          title: t("notifications.reportDownloadTitle"),
          text: t("notifications.reportDownloadText"),
          type: "success"
        }); // Success
        const blobUrl = (URL || webkitURL).createObjectURL(res); //eslint-disable-line
        const tempLink = document.createElement("a");
        const fileName = `${t("report")}-${currentReport.referenceNumber.replace(/[^a-z0-9]/gi, "_").toLowerCase()}.pdf`;
        tempLink.href = blobUrl;
        tempLink.setAttribute("download", fileName);
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
        // Uncomment the following line and use instead of the above when browser support is good enough
        // tempLink.dispatchEvent(new MouseEvent(`click`, { bubbles: true, cancelable: true, view: window }));
      });
    });
  };

  // Create a PDF export of a report and trigger an email to the provided recipient. Online only.
  const pdfEmail = () => {
    setPdfEmailPromptOpen(false);
    showToast({
      title: t("notifications.reportExportStartedTitle"),
      text: t("notifications.reportExportStartedText"),
      type: "info"
    });
    createPdfExport(currentReport.uuid, language).then((response) => {
      if (!response) {
        showToast({
          title: t("notifications.reportExportFailedTitle"),
          text: t("notifications.reportExportFailedGeneric"),
          type: "error"
        }); // Export error
        return;
      }
      emailPdfExport(response, pdfRecipient).then((result) => {
        if (result === 1)
          showToast({
            title: t("notifications.reportSentToTitle"),
            text: `${t("notifications.reportSentToText")} ${pdfRecipient}`,
            type: "success"
          }); // Success
        if (result === 2)
          showToast({
            title: t("notifications.reportExportFailedTitle"),
            text: t("notifications.reportExportFailedText"),
            type: "error"
          }); // Error
        if (result === 3)
          showToast({
            title: t("notifications.reportExportTooLargeTitle"),
            text: t("notifications.reportExportTooLargeText"),
            type: "info"
          }); // File too large
      });
    });
  };

  // Should be called before persisting, and if performance allows, whenever the report is changed
  // Only deals with customfields for now, expand on this later
  const validate = (report: Report) => {
    const reportClone = { ...report };
    const reportCustomFieldsEnabled = reportCustomFields.filter((field) => field.enabled);
    const expenseCustomFieldsEnabled = expenseCustomFields.filter((field) => field.enabled);
    const validationResult = validateReport(reportClone, reportCustomFieldsEnabled, expenseCustomFieldsEnabled, productConfig.uxPolicy);
    setValidationErrors(validationResult.errors);
    return validationResult.isValid;
  };

  // Render a menu choice for the "send to approval" button
  // Takes an approvalchain or a new advancedapprovalflow as param
  // If it's an advanced flow, set isAdvanced=true
  // This affects which archival endpoint the report is sent to
  const getArchiveMenuItem = (chainOrFlow: ApprovalChain | ProductConfigAdvancedApprovalFlow, isAdvanced: boolean) => {
    const labelText = `${t("reportDetailsView.sendTo")} ${chainOrFlow.description}`;
    const label = <span className={chainOrFlow.suggested ? "suggested-approver" : ""}>{labelText}</span>;
    const key = `${isAdvanced ? "f" : "c"}${chainOrFlow.id}`;
    return (
      <MenuItem
        className={lastUsedApproverGroupId === chainOrFlow.id ? "last-used-approver-group" : ""}
        key={key}
        eventKey={key}
        onSelect={() => archive(chainOrFlow.id, isAdvanced)}
      >
        {label}
      </MenuItem>
    );
  };

  const getArchiveOptions = () => {
    let options = [];

    // Classic approval chains
    if (useApprovalSuggestions) {
      // We are using suggested approvers
      const suggested = approvalChains.filter((chain) => chain.suggested);
      const nonSuggested = approvalChains.filter((chain) => !chain.suggested);

      // If we have suggestions, render a header and the suggestions
      if (suggested.length > 0) {
        options.push(
          <MenuItem disabled key={"m1"}>
            {t("reportDetailsView.recommended")}:
          </MenuItem>
        );
        //.. and the suggestions
        options.push(...suggested.map((chain) => getArchiveMenuItem(chain, false)));
      }

      // If we have non-suggestions, render a header and the non-suggestions
      if (nonSuggested.length > 0) {
        // Render a header and the non-suggestions
        options.push(
          <MenuItem disabled key={"m2"}>
            {t("reportDetailsView.others")}:
          </MenuItem>
        );
        //.. and the suggestions
        options.push(...nonSuggested.map((chain) => getArchiveMenuItem(chain, false)));
      }
    } else {
      // We're not using suggested approvers, just render the full option list
      options.push(...approvalChains.map((chain) => getArchiveMenuItem(chain, false)));
    }

    if (approvalChains.length > 0 && advancedApprovalFlows.length > 0) {
      // For some reason this company has configured both old chains and new flows...
      // Render a divider I guess?
      options.push(<MenuItem divider key={"m3"} />);
    }

    // Advanced approval flows, same logic as above
    if (useAdvancedApprovalSuggestions) {
      // We are using suggested approvers
      const suggested = advancedApprovalFlows.filter((flow) => flow.suggested);
      const nonSuggested = advancedApprovalFlows.filter((flow) => !flow.suggested);

      // If we have suggestions, render a header and the suggestions
      if (suggested.length > 0) {
        options.push(
          <MenuItem disabled key={"m4"}>
            {t("reportDetailsView.recommended")}:
          </MenuItem>
        );
        //.. and the suggestions
        options.push(...suggested.map((flow) => getArchiveMenuItem(flow, true)));
      }

      // If we have non-suggestions, render a header and the non-suggestions
      if (nonSuggested.length > 0) {
        // Render a header and the non-suggestions
        options.push(
          <MenuItem disabled key={"m5"}>
            {t("reportDetailsView.others")}:
          </MenuItem>
        );
        //.. and the suggestions
        options.push(...nonSuggested.map((flow) => getArchiveMenuItem(flow, true)));
      }
    } else {
      // We're not using suggested approvers, just render the full option list
      options.push(...advancedApprovalFlows.map((flow) => getArchiveMenuItem(flow, true)));
    }

    // If no approvers are available so far, possibly filtered or misconfigured, warn the user
    if (options.length === 0) {
      options.push(
        <MenuItem disabled key={"m6"}>
          {t("reportDetailsView.noApproversFound")}
        </MenuItem>
      );
    }

    return options;
  };

  // Click the expenses title 10 times to put the editor into debug mode for recalcucation purposes
  const expensesTitleClicked = () => {
    if (expensesTitleClickCount < 9) {
      setExpensesTitleClickCount(expensesTitleClickCount + 1);
    } else {
      setIgnoreReadOnlyForRecalculations(true);
    }
  };

  // Add a new expense to the report and switch to the editor
  const addExpense = () => {
    if (!defaultExpenseType) throw new Error("createExpense should never be called unless a default expensetype exists");

    let expensesNew = [...currentReport.expenses];

    // If we already have an expense open, and there are any additionalExpenses, it means we have tollstation passings that hasn't been created and committed to the report yet
    // We need to do some of the same logic as persistExpenseToReport does, before moving to the new expense:
    // - tag the current expense with a groupUuid
    // - create expenses from the toll passings, and stamp them with the same groupUuid
    if (currentExpenseToEdit && additionalExpenses.length > 0) {
      const now = moment().toISOString();
      const groupUuid = uuid();

      // Check if this expense is already on the report, and if so, use it as a base for our updated expense
      let updatedExpense = { ...currentExpenseToEdit };
      const existingExpense = expensesNew.find((o) => o.uuid === currentExpenseToEdit.uuid);
      if (existingExpense) updatedExpense = { ...existingExpense, ...updatedExpense };
      updatedExpense.reportUuid = currentReport.uuid;
      updatedExpense.groupUuid = groupUuid;
      updatedExpense.dirty = now;

      // Create report-friendly expenses from the toll passings and adjust their date to match the expense
      let additionalExpensesToAdd = additionalExpenses.map((o) => {
        return {
          ...o,
          groupUuid,
          reportUuid: currentReport.uuid,
          dirty: now,
          mileageTrackUuid: "",
          starts: updatedExpense.starts,
          ends: updatedExpense.starts
        };
      });

      // Overwrite the existing expense on the report, if it's already there
      expensesNew = expensesNew.map((o) => (o.uuid === updatedExpense.uuid ? updatedExpense : o));
      // If not, inject it
      if (!expensesNew.find((o) => o.uuid === updatedExpense.uuid)) expensesNew.push(updatedExpense);
      // Inject additional expenses
      expensesNew.push(...additionalExpensesToAdd);
    }
    // At this point, expensesNew holds the existing expenses, an updated version of the current one, and additional ones for toll passings

    // Create an expense. Default to today's date
    let expenseDate = moment().toISOString();
    if (currentReport) {
      // If we're on a travel report and have segments, override using first segment start date
      if (currentReport.reportTypeId === 1) {
        const segmentDates = currentReport.reportSegments
          .filter((o) => !o.deleted)
          .map((o) => o.starts)
          .sort();
        if (segmentDates.length > 0) expenseDate = segmentDates[0];
      }
      // If we have other expenses, override using last expense date
      const expenseDates = currentReport.expenses
        .filter((o) => !o.deleted)
        .map((o) => o.starts)
        .sort()
        .reverse();
      if (expenseDates.length > 0) expenseDate = expenseDates[0];
    }

    const expenseStub: ExpenseConstructorOptions = {
      expenseTypeId: defaultExpenseType.id,
      personId,
      reportUuid: currentReport.uuid,
      starts: expenseDate,
      ends: expenseDate,
      refund: userConfiguration.product.defaultRefund
    };

    if (nonVatEnabled) expenseStub.vatPercent = 0;
    if (defaultExpenseType.is_Driving) {
      expenseStub.vatPercent = 0;
      expenseStub.unitName = "Km";
      expenseStub.receipt = false;
    }
    const newExpense = expenseConstructor(expenseStub);

    // Apply policy defaults
    const completeExpense = applyPolicyToNewExpense(newExpense, productConfig);

    // Insert it into the report
    setAddingExpenseUuid(completeExpense.uuid);
    changeExpenses([...expensesNew, completeExpense]);
    setShowAttachablesDrawerExpense(false);
  };

  // Copy/clone an expense in the list
  const copyExpense = (expense: Expense) => {
    const expensesNew = [...currentReport.expenses];
    const oldExpense = currentReport.expenses.find((o) => o.uuid === expense.uuid);
    if (oldExpense) {
      const clone = getExpenseClone(oldExpense);
      expensesNew.push(clone);
      changeExpenses(expensesNew, true);
    }
  };

  // Delete an expense
  const deleteExpense = async (expense: Expense) => {
    const now = moment().toISOString();
    const deletedExpense = {
      ...expense,
      deleted: now,
      dirty: now,
      attachments: expense.attachments.map((attachment) => {
        return { ...attachment, deleted: attachment.deleted || now, dirty: attachment.deleted ? undefined : now };
      })
    };

    // Overwrite the existing expense on the report, assuming it's already there
    const expensesNew = currentReport.expenses.map((o) => (o.uuid === deletedExpense.uuid ? deletedExpense : o));
    changeExpenses(expensesNew, true);
  };

  // Close the expense attachables drawer
  const closeAttachablesExpense = () => {
    setShowAttachablesDrawerExpense(false);
  };

  // Merge a single changed expense into the list and throw it up the chain
  // forcePersist indicates that we should immediately queue the expense to be persisted
  // fromConstructor indicates the changes comes from initialization of default values, not user input, but only relevant in expensepage
  const changeExpense = (partialExpense: Partial<Expense>, forcePersist: boolean, fromConstructor?: boolean) => {
    if (!currentExpenseToEdit) return;
    // Spread the changes onto the expense we're currently editing
    // (Yes, we can just spread this directly, partialExpense doesn't actually have any undefined properties that could overwrite existingExpense at runtime, those only exists in TS world)
    let updatedExpense: Expense = { ...currentExpenseToEdit, ...partialExpense };
    setCurrentExpenseToEdit(updatedExpense);
    persistExpenseToReport(updatedExpense, additionalExpenses, forcePersist, false);
  };

  const changeAdditionalExpenses = (additionalExpenses: Expense[], fromConstructor?: boolean) => {
    setAdditionalExpenses(additionalExpenses);
    if (!fromConstructor) setEditorIsDirty(true);
  };

  const showExpenseList = () => {
    if (currentExpenseToEdit) {
      // Force a persist when we move from the editor to the list. This is required for the attachment buttons in the expense list to be enabled.
      // TODO: check more intelligently if anything actually changed before forcing the persist. This is a performance drag.
      persistExpenseToReport(currentExpenseToEdit, additionalExpenses, true, true);
    }
    setCurrentExpenseToEdit(null);
    setShowAttachablesDrawerExpense(false);
  };

  // Persist an expense (and any additional expenses) to the underlying report, and return the new complete expense array for the report
  const persistExpenseToReport = (expense: Expense, additionalExpenses: Expense[], forcePersist: boolean, editorClosing: boolean): Expense[] => {
    const now = moment().toISOString();

    // Check if this expense is already on the report, and if so, use it as a base for our updated expense
    let updatedExpense = { ...expense };
    const existingExpense = currentReport.expenses.find((o) => o.uuid === expense.uuid);
    if (existingExpense) updatedExpense = { ...existingExpense, ...updatedExpense };

    // If we have any additional expenses, clean them up now before we start upserting
    let additionalExpensesToAdd: Expense[] = [];
    if (additionalExpenses.length > 0) {
      const groupUuid = uuid();
      updatedExpense.groupUuid = groupUuid;
      additionalExpensesToAdd = additionalExpenses.map((o) => {
        return {
          ...o,
          groupUuid,
          reportUuid: currentReport.uuid,
          dirty: now,
          mileageTrackUuid: "",
          starts: updatedExpense.starts,
          ends: updatedExpense.starts
        };
      });
    }

    // Enforce some properties in case the editor broke anything, and flag the expense as dirty
    updatedExpense.reportUuid = currentReport.uuid;
    updatedExpense.dirty = now;

    // Overwrite the existing expense on the report, if it's already there
    let expensesNew = currentReport.expenses.map((o) => (o.uuid === updatedExpense.uuid ? updatedExpense : o));
    // If not, inject it
    if (!expensesNew.find((o) => o.uuid === updatedExpense.uuid)) expensesNew.push(updatedExpense);

    // If we have related expenses, perform the same logic on each of them (update or insert)
    for (const additonalExpense of additionalExpensesToAdd) {
      expensesNew = expensesNew.map((o) => (o.uuid === additonalExpense.uuid ? additonalExpense : o));
      if (!expensesNew.find((o) => o.uuid === additonalExpense.uuid)) expensesNew.push(additonalExpense);
    }

    // Pass the updated expense array back and persist it if we're either forcing it or we're closing the editor
    changeExpenses(expensesNew, forcePersist || editorClosing);
    return expensesNew;
  };

  // Only show the segment editor if we're creating a travel report
  const showSegments = currentReport.reportTypeId === 1;
  // Only show the dietary override editor for travel reports, with a rate type, and if we have any applicable days to override
  const showDietary =
    currentReport.reportTypeId === 1 &&
    currentReport.rateType !== 1 &&
    currentReport.calculation.calculationItems.filter((item) => item.itemType === 3).length > 0;

  // All expensetypes are available for travel reports, but no driving for expense reports and only driving for driving reports
  let availableExpenseTypes = expenseTypes;
  if (currentReport.reportTypeId === 2) availableExpenseTypes = expenseTypes.filter((et) => !et.is_Driving);
  if (currentReport.reportTypeId === 3) availableExpenseTypes = expenseTypes.filter((et) => et.is_Driving);

  // Gather all attachments on the report itself and all expenses so we can show a merged list of them at the bottom
  const reportAttachments: Attachment[] = currentReport.attachments;
  const expenseAttachments: Attachment[] = currentReport.expenses
    .filter((o) => !o.deleted)
    .reduce((all: Attachment[], expense) => [...all, ...expense.attachments], []);
  const mergedAttachments = [...reportAttachments, ...expenseAttachments].filter((o) => !o.deleted);

  const expensesTitleStyle = ignoreReadOnlyForRecalculations ? { color: "red" } : {};

  return (
    <React.Fragment>
      <NavigationPrompt when={(!readOnly || approverEditingMode) && editorIsDirty && !forceBailout}>
        {({ onConfirm, onCancel }) => (
          <Modal show={true} onHide={onCancel}>
            <Modal.Header>
              <Modal.Title>{t("reportDetailsView.discardChanges")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{t("reportDetailsView.discardWarningText")}</Modal.Body>
            <Modal.Footer>
              <Button onClick={onCancel}>{t("no")}</Button>
              <Button bsStyle="danger" onClick={onConfirm}>
                {t("yes")}
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </NavigationPrompt>

      <Grid fluid>
        <AttachmentPreview />
        <div className="report-create-wrapper">
          {currentReport.reportApprovals && currentReport.reportApprovals.length > 0 && (
            <ReportApprovalStatus reportApprovals={currentReport.reportApprovals} />
          )}
          {currentReport.advancedApprovalAttempts && currentReport.advancedApprovalAttempts.length > 0 && (
            <AdvancedReportApprovalStatus
              attempts={currentReport.advancedApprovalAttempts}
              reportUuid={currentReport.uuid}
              hideRecallWidget={reportIsExternal}
            />
          )}
          <ReportHeader
            report={currentReport}
            userConfiguration={userConfiguration}
            readOnly={readOnly && !approverEditingMode}
            onChange={changeHeader}
            showTour
            validationErrors={validationErrors.header}
          />
          <CustomFields
            reportUuid={currentReport.uuid}
            customFields={reportCustomFields}
            customValues={currentReport.reportCustomValues}
            readOnly={readOnly && !approverEditingMode}
            onChangeCustomValues={changeCustomValues}
            validationErrors={validationErrors.reportCustomFields}
          />
          {showSegments && (
            <React.Fragment>
              <div className="report-spacer" />

              <ReportSegments
                segmentList={currentReport.reportSegments}
                readOnly={readOnly && !approverEditingMode}
                reportTypeId={currentReport.reportTypeId}
                onChange={changeSegments}
                createSegment={createSegment}
              />
            </React.Fragment>
          )}
          {showDietary && (
            <React.Fragment>
              <div className="report-spacer" />
              <ReportAutoExpenseOverrides
                reportUuid={currentReport.uuid}
                reportCalculation={currentReport.calculation}
                autoExpenseOverrides={currentReport.autoExpenseOverrides}
                readOnly={readOnly && !approverEditingMode}
                onChange={changeAutoExpenseOverrides}
              />
            </React.Fragment>
          )}
          <div className="report-spacer" />

          <div className="report-component" id="reportExpenses">
            <div className="report-component-header">
              <div className="report-component-title" style={expensesTitleStyle} onClick={() => expensesTitleClicked()}>
                {t("expenseEditorView.expenses")}
              </div>
              <div className="header-buttons">
                <Button onClick={() => addExpense()} disabled={readOnly && !approverEditingMode} id="reportNewExpense">
                  <Icon icon="add" />
                  <span>{capitalize(t("new"))}</span>
                </Button>

                <Button
                  disabled={readOnly}
                  bsStyle="link"
                  onClick={() => {
                    setCurrentExpenseToEdit(null);
                    setAdditionalExpenses([]);
                    setShowAttachablesDrawerExpense(!showAttachablesDrawerExpense);
                  }}
                  id="reportPickExpense"
                >
                  {showAttachablesDrawerExpense ? <Icon icon="close" /> : <Icon icon="add" />}
                  {showAttachablesDrawerExpense ? t("reportDetailsView.closeDrawer") : t("reportDetailsView.pick")}
                </Button>

                <Button bsStyle="link" onClick={() => showExpenseList()} id="reportAllExpenses">
                  {capitalize(t("showAll"))} ({currentReport.expenses.filter((o) => !o.deleted).length.toString()})
                </Button>
                {validationErrors && validationErrors.expenses && validationErrors.expenses.length > 0 && (
                  <span className="validation-error">{t("validationMessages.issuesFound")}</span>
                )}
              </div>
            </div>

            <PoseGroup>
              {!readOnly && showAttachablesDrawerExpense && (
                <AttachablesDrawerPoseWrapper key="attachables-drawer-pose-wrapper" className="attachables-drawer-pose-wrapper">
                  <AttachablesDrawer parentType="report" parent={currentReport} showTabs={["expense"]} onClose={closeAttachablesExpense} />
                </AttachablesDrawerPoseWrapper>
              )}
            </PoseGroup>
            {!!currentExpenseToEdit ? (
              <ExpenseEditor
                expense={currentExpenseToEdit}
                readOnly={readOnly && !approverEditingMode}
                enableAttachmentsDrawer={!approverEditingMode}
                ignoreReadOnlyForRecalculations={ignoreReadOnlyForRecalculations}
                onChange={changeExpense}
                onChangeAdditionalExpenses={changeAdditionalExpenses}
                availableExpenseTypes={availableExpenseTypes}
                validationErrors={
                  currentExpenseToEdit &&
                  validationErrors.expenses &&
                  validationErrors.expenses.find((v: ValidateExpenseErrors) => v.uuid === currentExpenseToEdit.uuid)
                }
                externalProductConfig={externalProductConfig}
              />
            ) : (
              <ExpenseList
                expenses={currentReport.expenses.filter((o) => !o.deleted)}
                readOnly={readOnly && !approverEditingMode}
                persisting={persistQueued}
                orderBy="changed"
                orderDesc
                renderMode="table"
                onEditExpense={(expenseToEdit) => {
                  setAdditionalExpenses([]);
                  setCurrentExpenseToEdit(expenseToEdit);
                }}
                validationErrors={validationErrors.expenses}
                externalProductConfig={externalProductConfig}
                onMoveToStandaloneExpenses={moveToStandaloneExpenses}
                onExpenseMovedToOtherReport={expenseMovedToOtherReport}
                onCopyExpense={copyExpense}
                onDeleteExpense={deleteExpense}
              />
            )}
          </div>

          <div className="report-spacer" />

          {/* Show combined attachments from report and expenses. We no longer allow uploading attachments directly to the report, so that is for legacy reasons. */}
          {mergedAttachments.length > 0 && (
            <Attachments
              attachments={mergedAttachments}
              readOnly
              showHeader
              headerText={t("reportDetailsView.allAttachments")}
              enableDrawer={false}
            />
          )}
        </div>

        <div className="report-bottom-fixed">
          {externalReport &&
            currentReport.status === 2 &&
            currentReport.requiresApproval &&
            !currentReport.approved &&
            (approverIsQueued || approverIsQueuedClassic) && (
              // The current report was loaded from the approvals page, we should show a sticky approval toolbar if the user is queued for deciding
              // It's an external report that is archived and up for approval, and the current approver is queued either in advanced or classic mode
              <div className="report-bottom-approval">
                <FormControl
                  type={"text"}
                  componentClass={"textarea"}
                  placeholder={t("yourComment")}
                  onChange={(event: React.FormEvent<FormControl & HTMLInputElement>) => setApprovalComment(event.currentTarget.value)}
                />

                <Button
                  bsStyle="success"
                  bsSize={"large"}
                  onClick={() => decideApproval(true, approvalComment, currentReport.uuid, externalDecisionUuid)}
                  title={capitalize(t("approve"))}
                  disabled={(approverEditingMode && editorIsDirty) || reportExportDropdownIsOpen}
                >
                  {capitalize(t("approve"))}
                </Button>

                <Button
                  bsStyle="danger"
                  bsSize={"large"}
                  onClick={() => decideApproval(false, approvalComment, currentReport.uuid, externalDecisionUuid)}
                  title={capitalize(t("disapprove"))}
                  disabled={(approverEditingMode && editorIsDirty) || reportExportDropdownIsOpen}
                >
                  {capitalize(t("disapprove"))}
                </Button>
              </div>
            )}

          <div className="report-bottom-controls">
            <div className="report-bottom-calculation">
              <ReportCalculationBox calculation={currentReport.calculation} rateType={currentReport.rateType} reportCreated={currentReport.created} />
            </div>
            <div className="report-bottom-buttons">
              {isArchiving && <Spinner size="30px" />}

              {advancedApprovalAttemptDecisionUuid && approverIsQueued && !approverEditingMode && (
                <Button bsStyle="warning" onClick={() => setApproverIsEditing(true)}>
                  {t("unlockForChanges")}
                </Button>
              )}
              {isArchiving && <Spinner size="30px" />}

              {/* {advancedApprovalAttemptDecisionUuid && approverEditingMode && (
                <Button bsStyle="warning" onClick={() => setApproverIsEditing(false)}>
                  {t("Abort changes")}
                </Button>
              )} */}

              {(!readOnly || approverEditingMode) && !isArchiving && (
                <>
                  <Button bsStyle="warning" disabled={persistQueued} onClick={() => cancelChanges()}>
                    {t("cancel")}
                  </Button>
                  <ButtonWithSpinner
                    disabled={!editorIsDirty || persistQueued}
                    showSpinner={persistQueued}
                    bsStyle="success"
                    onClick={() => saveChanges()}
                  >
                    {editorIsDirty ? t("saveChanges") : t("changesSaved")}
                  </ButtonWithSpinner>
                </>
              )}

              {!reportIsExternal && (
                <DropdownButton
                  pullRight
                  title={t("reportDetailsView.copy")}
                  dropup
                  bsStyle="default"
                  id="report-copy-button"
                  disabled={isArchiving || editorIsDirty}
                >
                  <MenuItem key={"reportCopyEmpty"} onSelect={() => copyReport(false)}>
                    {t("reportDetailsView.copyEmpty")}
                  </MenuItem>
                  <MenuItem key={"reportCopyWithExpenses"} onSelect={() => copyReport(true)}>
                    {t("reportDetailsView.copyWithExpenses")}
                  </MenuItem>
                </DropdownButton>
              )}

              {
                // Export buttons
                <DropdownButton
                  pullRight
                  title={t("reportDetailsView.export")}
                  dropup
                  bsStyle="default"
                  id="export-button"
                  disabled={isArchiving || editorIsDirty}
                  onToggle={(isOpen: boolean) => setReportExportDropdownIsOpen(isOpen)}
                >
                  <MenuItem key={1} eventKey={1} onSelect={() => pdfDownload()}>
                    <Icon icon="download" /> {t("reportDetailsView.pdfDownload")}
                  </MenuItem>
                  <MenuItem key={2} eventKey={3} onSelect={() => setPdfEmailPromptOpen(true)}>
                    <Icon icon="email" /> {t("reportDetailsView.pdfEmail")}
                  </MenuItem>
                </DropdownButton>
              }

              <Modal show={pdfEmailPromptOpen} onHide={() => setPdfEmailPromptOpen(false)}>
                <Modal.Header>
                  <Modal.Title>{t("reportDetailsView.sendTo")} ...</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  <TextFieldGroup
                    field="pdfRecipient"
                    value={pdfRecipient}
                    onChange={(value: string) => setPdfRecipient(value)}
                    onEnter={() => pdfEmail()}
                    placeholder={t("email")}
                    label=""
                    hideLabel
                  />
                </Modal.Body>

                <Modal.Footer>
                  <Button onClick={() => setPdfEmailPromptOpen(false)}>{t("cancel")}</Button>
                  <Button bsStyle="primary" onClick={() => pdfEmail()}>
                    {t("send")}
                  </Button>
                </Modal.Footer>
              </Modal>

              {
                // If we don't need approval, render the archive button for submitting without approval
                !readOnly && !reportIsExternal && !useApprovals && (
                  <ButtonWithSpinner
                    bsStyle="default"
                    onClick={() => archive(0, false)}
                    disabled={isArchiving || editorIsDirty}
                    showSpinner={isArchiving}
                  >
                    {t("reportDetailsView.archive")}
                  </ButtonWithSpinner>
                )
              }

              {
                // If we need approval, render the submit dropup button with approval items
                !readOnly && !reportIsExternal && useApprovals && (
                  <DropdownButton
                    pullRight
                    title={t("reportDetailsView.submit")}
                    dropup
                    bsStyle="default"
                    id="approvalchain-picker"
                    disabled={isArchiving || editorIsDirty}
                  >
                    {getArchiveOptions()}
                  </DropdownButton>
                )
              }
            </div>
          </div>
        </div>
      </Grid>
    </React.Fragment>
  );
};

export default ReportPage;
