import validator from "validator";
import isEmpty from "lodash/isEmpty";
import i18n from "../i18n/i18nConfig";
import { ValidateLoginResult } from "../types";

export const validateLogin = (username: string, password: string): ValidateLoginResult => {
  const errors: { username?: string; password?: string } = {};

  if (validator.isEmpty(username)) {
    errors.username = i18n.t("requiredField");
  } else if (!validator.isEmail(username)) {
    errors.username = i18n.t("invalidUserName");
  }

  if (validator.isEmpty(password)) {
    errors.password = i18n.t("requiredField");
  }

  return {
    errors,
    isValid: isEmpty(errors)
  };
};
