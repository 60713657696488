import posed from "react-pose";

const AttachablesDrawerPoseWrapper = posed.div({
  enter: {
    "max-height": "350px",
    transition: {
      duration: 400
    }
  },
  exit: {
    "max-height": "0px",
    transition: {
      duration: 200
    }
  }
});

export default AttachablesDrawerPoseWrapper;
