import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Badge, Button } from "react-bootstrap";
import { formatDateRange, capitalize } from "../../shared/utils/helpers";
import Icon from "../common/Icon";
import TextFieldGroup from "../common/TextFieldGroup";
import { Mileage } from "../../shared/types";
import { saveMileage } from "../../shared/queries/queries";
import moment from "moment";
import Spinner from "../common/Spinner";

interface MileageListItemProps {
  mileage: Mileage;
  timesLinked?: number;
  readOnly?: boolean;
  onDelete?: (mileage: Mileage) => void;
  onCreateExpenseFromMileage?: (mileage: Mileage) => void;
  onShowMap?: (mileage: Mileage) => void;
  showMapPreviewButton?: boolean;
  alternate?: boolean;
  disableCreateExpenseFromMileage?: boolean;
}

const MileageListItem = ({
  mileage,
  timesLinked,
  readOnly,
  onDelete,
  onCreateExpenseFromMileage,
  onShowMap,
  showMapPreviewButton,
  alternate,
  disableCreateExpenseFromMileage
}: MileageListItemProps) => {
  const [t] = useTranslation();
  const { uuid, starts, stops, description, totalMeters, addressFrom } = mileage;

  const dates = formatDateRange(starts, stops);
  const descriptionText = description.trim() ? description : capitalize(t("noDescription"));

  const [editingDescription, setEditingDescription] = useState(false);
  const [newDescription, setNewDescription] = useState(description);
  const [saving, setSaving] = useState(false);

  const saveDescription = () => {
    setEditingDescription(false);
    if (mileage.description !== newDescription) {
      const updatedMileage = { ...mileage, description: newDescription, dirty: moment().toISOString() };
      setSaving(true);
      saveMileage(updatedMileage, true).then(() => {
        setSaving(false);
      });
    }
  };

  const descriptionClicked = () => {
    if (showMapPreviewButton) {
      if (onShowMap) onShowMap(mileage);
      setEditingDescription(true);
    }
  }

  const descriptionLink = editingDescription ? (
    <TextFieldGroup
      hideLabel
      autoFocus
      field={`mileageDescription${uuid}`}
      value={description}
      placeholder={capitalize(t("description"))}
      onChange={(s) => setNewDescription(s)}
      onBlur={() => saveDescription()}
      onEscape={() => setEditingDescription(false)}
      // onBlur={() => setEditingDescription(false)}
      // addonRight={
      //   <>
      //     <Button onClick={() => saveDescription()} title={t("confirm")}>
      //       <Icon icon="checkMark" />
      //     </Button>
      //     <Button onClick={() => setEditingDescription(false)} title={t("cancel")}>
      //       <Icon icon="cancel" />
      //     </Button>
      //   </>
      // }
    />
  ) : (
    <button className="fakelink" onClick={() => showMapPreviewButton && descriptionClicked()}>
      <span>
      {descriptionText} {saving ? <Spinner size="16px" /> : ""}
      </span>
    </button>
  );







  const kmLength = totalMeters ? (totalMeters / 1000).toFixed(1) : "0";
  const km = `${kmLength} km`;

  const isLinked: boolean = Boolean(timesLinked && timesLinked > 0);
  // const linkedIcon = isLinked ? <Icon icon="link" title={t("isUsedToCreateExpense") + timesLinked?.toString()} /> : null;

  const linkedIcon = isLinked ? (
    <>
      <Badge
        className="track-linked-count"
        title={t("isUsedToCreateExpense", {
          count: timesLinked
        })}
      >
        <Icon icon="link" />
        {timesLinked}
      </Badge>
    </>
  ) : null;

  const deleteButton = !readOnly && (
    <Button
      disabled={isLinked}
      title={
        isLinked
          ? t("isUsedToCreateExpense", {
              count: timesLinked
            })
          : capitalize(t("delete"))
      }
      onClick={() => onDelete && onDelete(mileage)}
    >
      <Icon icon="trash" />
    </Button>
  );

  const previewButton = showMapPreviewButton && (
    <Button title={capitalize(t("viewRouteOnMap"))} onClick={() => onShowMap && onShowMap(mileage)}>
      <Icon icon="mapSearch" />
    </Button>
  );

  const generateButton = (
    <Button
      title={capitalize(t("createMileageBasedOnThis"))}
      onClick={() => onCreateExpenseFromMileage && onCreateExpenseFromMileage(mileage)}
      disabled={disableCreateExpenseFromMileage}
    >
      <Icon icon="mileage" />
    </Button>
  );

  let extraClass = "";
  if (alternate) extraClass += " alternate";

  return (
    <>
      <div className={`item-checkbox ${extraClass}`} />
      <div className={`item-dates ${extraClass}`}>{dates}</div>
      <div className={`item-description ${extraClass}`}>{descriptionLink}</div>
      <div className={`item-addresses ${extraClass}`}>{addressFrom || ""}</div>
      <div className={`item-icons ${extraClass}`}>{linkedIcon}</div>
      <div className={`item-distance ${extraClass}`}>{km}</div>
      <div className={`item-actions ${extraClass}`}>
        {deleteButton}
        {previewButton}
        {generateButton}
      </div>
    </>
  );
};

export default MileageListItem;
