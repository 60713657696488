import { useEffect, useMemo, useState } from "react";
import SelectGroup from "./SelectGroup";
import unionBy from "lodash/unionBy";
import { autocomplete } from "../../shared/api/geography";
import { useCountries, useMajorCities, useTopLocations } from "../../shared/queries/queries";
import { InputActionMeta } from "react-select";
import useDebounce from "../../shared/utils/useDebounce";

// A wrapper to make a geography-specific SelectGroup with online search capabilities
interface SelectGroupGeographyProps {
  field: string;
  value: string; //Must be: cityId|cityName|countryId|countryName|currentlocationLabel
  label: string;
  onChange: (value: string) => void;
  readOnly?: boolean;
  error?: string;
  required?: boolean;
}
const SelectGroupGeography = ({ field, value, label, onChange, readOnly, error, required }: SelectGroupGeographyProps) => {
  const [onlineLocations, setOnlineLocations] = useState<{ label: string; value: string }[]>([]);
  const [searchQuery, setSearchQuery] = useState("");

  const topLocationsQuery = useTopLocations();
  const countriesQuery = useCountries();
  const majorCitiesQuery = useMajorCities();
  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  const offlineLocations = useMemo(() => {
    const topLocations = topLocationsQuery.data
      ? topLocationsQuery.data.topLocations.map((location) => ({
          value: `${location.cityId}|${location.cityName}|${location.countryId}|${location.countryName}|${location.locationName}`,
          label: location.locationName
        }))
      : [];
    const countries = countriesQuery.data
      ? countriesQuery.data.map((country) => ({
          value: `0||${country.id}|${country.name}|${country.name}`,
          label: country.name
        }))
      : [];
    const majorCities = majorCitiesQuery.data
      ? majorCitiesQuery.data.map((location) => ({
          value: `${location.cityId}|${location.cityName}|${location.countryId}|${location.countryName}|${location.locationName}`,
          label: location.locationName
        }))
      : [];

    return unionBy(topLocations, countries, majorCities, "label");
  }, [topLocationsQuery.data, countriesQuery.data, majorCitiesQuery.data]);

  useEffect(() => {
    if (debouncedSearchQuery.trim() === "") {
      setSearchQuery("");
      return;
    }
    autocomplete(debouncedSearchQuery).then((results) => {
      const locs = results.map((result) => ({
        value: `${result.cityId}|${result.cityName}|${result.countryId}|${result.countryName}|${result.locationName}`,
        label: result.locationName
      }));
      setOnlineLocations(locs);
      setSearchQuery("");
    });
  }, [debouncedSearchQuery]);

  // Handle keystrokes for select autocomplete
  const handleInputChange = (query: string, meta: InputActionMeta) => {
    if (!meta.action || meta.action !== "input-change" || !query || query.length < 2) {
      // setOnlineLocations([]);
      setSearchQuery("");
      return;
    }
    setSearchQuery(query);
  };

  // Geography values should be on the format "cityId|cityName|countryId|countryName|friendlyLocationName"
  // Construct an option to ensure the currently selected location is an available option in the dropdown, even if it's not an offline-available location
  let currentLocation = null;
  if (value && value !== "")
    currentLocation = {
      value,
      label: value.split("|")[4]
    };

  const locationOptions = currentLocation
    ? unionBy([currentLocation], offlineLocations, onlineLocations, "label")
    : unionBy(offlineLocations, onlineLocations, "label");

  return (
    <SelectGroup
      field={field}
      label={label}
      value={value}
      error={error}
      required={required}
      readOnly={readOnly}
      options={locationOptions || []}
      onInputChange={handleInputChange}
      onChange={(newValue: string) => onChange(newValue)}
      isLoading={searchQuery !== ""}
    />
  );
};

export default SelectGroupGeography;
