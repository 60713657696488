import moment from "moment";
import { ExpenseCustomValue } from "../types";
import { uuid } from "../utils/helpers";

interface ExpenseCustomValueConstructorOptions {
  expenseUuid: string;
  expenseCustomFieldId: number;
  value?: string;
}
export const expenseCustomValueConstructor = (opts: ExpenseCustomValueConstructorOptions): ExpenseCustomValue => {
  const { expenseUuid, expenseCustomFieldId, value = "" } = opts;
  const created = moment().toISOString();
  const reportCustomValueUuid = uuid();
  return {
    uuid: reportCustomValueUuid,
    changed: "",
    expenseUuid,
    expenseCustomFieldId,
    value,
    dirty: created,
    deleted: ""
  };
};
