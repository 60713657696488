import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import * as reportApi from "../../shared/api/report";
import AdvancedApprovalAttemptTimeline from "../approval/AdvancedApprovalAttemptTimeline";
import { capitalize } from "../../shared/utils/helpers";
import { showToast } from "../../utils/toastWrapper";
import { AdvancedApprovalAttempt } from "../../shared/types";
import { refreshAllApproval, refreshReports } from "../../shared/queries/queries";
import ButtonWithSpinner from "../common/ButtonWithSpinner";

interface AdvancedReportApprovalStatusProps {
  attempts: AdvancedApprovalAttempt[];
  reportUuid: string;
  hideRecallWidget: boolean;
}
const AdvancedReportApprovalStatus = ({ attempts, reportUuid, hideRecallWidget }: AdvancedReportApprovalStatusProps) => {
  const [t] = useTranslation();

  const [showAll, setShowAll] = useState(false);
  const [recallPending, setRecallPending] = useState(false);

  const onRecall = async (reportUuid: string) => {
    setRecallPending(true);
    reportApi
      .recallAdvanced(reportUuid)
      .then(() => {
        refreshReports();
        refreshAllApproval();
      })
      .catch((err) => {
        showToast({ type: "error", title: t("error"), text: err?.backendMessage || "", autoClose: 12000 });
      });
  };

  const latestAttempt = attempts[attempts.length - 1];
  const otherAttempts = attempts.length > 1 ? attempts.slice(0, attempts.length - 1) : [];
  const prev = otherAttempts.length;

  return (
    <div className="report-component">
      <div className="report-component-header">
        <div className="report-component-title">{t("approvalStatus")}</div>
      </div>
      <div className="report-approval-status">
        <AdvancedApprovalAttemptTimeline attempt={latestAttempt} />
        {latestAttempt.status === 0 && !hideRecallWidget && (
          <div className="aaa-recall-wrapper">
            <ButtonWithSpinner
              bsStyle="warning"
              disabled={recallPending}
              showSpinner={recallPending}
              onClick={() => onRecall(reportUuid)}
              title={capitalize(t("approvals.recall"))}
            >
              {capitalize(t("approvals.recall"))}
            </ButtonWithSpinner>
          </div>
        )}
      </div>

      {otherAttempts.length > 0 && (
        <div>
          <div className="report-approval-show-more-button">
            <Button bsStyle="link" onClick={() => setShowAll(!showAll)}>
              {t(showAll ? "hideCountPreviousApprovalAttempts" : "showCountPreviousApprovalAttempts", {
                count: prev
              })}
            </Button>
          </div>
          {showAll &&
            otherAttempts.map((attempt) => (
              <div className="report-approval-status" key={attempt.uuid}>
                <AdvancedApprovalAttemptTimeline attempt={attempt} />
              </div>
            ))}
        </div>
      )}

      <div className="clearfix" />
    </div>
  );
};

export default AdvancedReportApprovalStatus;
