import { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { setAdminOnboardingWizardFinished, adminOnboardingGenerateApprovalFlow, adminOnboardingGenerateUxPolicy } from "../../shared/api/user";
import { useStore } from "../../shared/store/store";
import "../../styles/login.css";
import ButtonWithSpinner from "../common/ButtonWithSpinner";
// import useConstructor from "../../shared/utils/useConstructor";
import { setPostponeAdminOnboardingWizard } from "../../utils/webclientStore";
import { useUserConfiguration, refreshUserConfiguration } from "../../shared/queries/queries";
import CheckboxInput from "./CheckboxInput";

// A modal shown to company admins with wizard steps for onboarding actions: Simplified UX templates and approver config
interface AdminOnboardingWizardProps {
  // onHide: () => void;
  show: boolean;
}
const AdminOnboardingWizard = ({ show }: AdminOnboardingWizardProps) => {
  const [t] = useTranslation();

  // Translation but with linebreak support
  const tlb = (key: string) => {
    let i = 0;
    return t(key)
      .split("\n")
      .map((o) => <p key={i++}>{o}</p>);
  };

  const userConfigurationQuery = useUserConfiguration();
  const personConfig = userConfigurationQuery.data?.configuration.person;

  const postponeAdminOnboardingWizard: boolean = useStore((state) => state.clientSpecific.postponeAdminOnboardingWizard);

  type StepName = "start" | "approval" | "uxPolicy" | "finish";
  const [hiding, setHiding] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<StepName>("start");
  const [hideTravel, setHideTravel] = useState<boolean>(true);
  const [hideDriving, setHideDriving] = useState<boolean>(true);
  const [hideFields, setHideFields] = useState<boolean>(true);
  const [processing, setProcessing] = useState<boolean>(false);

  // Proceed to next page
  const nextStep = () => {
    if (currentStep === "start") {
      if (personConfig?.adminOnboardingStatus.parentMissingApprovalFlow) {
        setCurrentStep("approval");
      } else if (personConfig?.adminOnboardingStatus.parentMissingUxPolicy) {
        setCurrentStep("uxPolicy");
      } else {
        setCurrentStep("finish");
      }
    } else if (currentStep === "approval") {
      if (personConfig?.adminOnboardingStatus.parentMissingUxPolicy) {
        setCurrentStep("uxPolicy");
      } else {
        setCurrentStep("finish");
      }
    } else if (currentStep === "uxPolicy") {
      setCurrentStep("finish");
    } else {
      finish();
    }
  };

  // Finish wizard permanently by setting the store flag and poking the backend
  const finish = async () => {
    setProcessing(true);
    await setAdminOnboardingWizardFinished();
    await refreshUserConfiguration();
    setPostponeAdminOnboardingWizard(true);
    setProcessing(false);
  };

  // Postpone wizard for this session only by setting the client specific store flag
  const postpone = async () => {
    setPostponeAdminOnboardingWizard(true);
  };

  const confirmApproval = async () => {
    setProcessing(true);
    await adminOnboardingGenerateApprovalFlow();
    setProcessing(false);
    nextStep();
  };

  const confirmUxPolicy = async () => {
    setProcessing(true);
    await adminOnboardingGenerateUxPolicy(hideTravel, hideDriving, hideFields);
    setProcessing(false);
    nextStep();
  };

  const getTitle = () => {
    if (currentStep === "start") return t("adminOnboarding.titleStart");
    if (currentStep === "approval") return t("adminOnboarding.titleApproval");
    if (currentStep === "uxPolicy") return t("adminOnboarding.titleUxPolicy");
    if (currentStep === "finish") return t("adminOnboarding.titleFinish");
  };

  const getInfoText = () => {
    if (currentStep === "start") return tlb("adminOnboarding.textStart");
    if (currentStep === "approval") return tlb("adminOnboarding.textApproval");
    if (currentStep === "uxPolicy")
      return (
        <>
          {tlb("adminOnboarding.textUxPolicy")}
          <CheckboxInput
            field="hideTravel"
            value={hideTravel}
            label={t("adminOnboarding.hideTravel")}
            onChange={(value: boolean) => setHideTravel(value)}
          />
          <CheckboxInput
            field="hideDriving"
            value={hideDriving}
            label={t("adminOnboarding.hideDriving")}
            onChange={(value: boolean) => setHideDriving(value)}
          />
          <CheckboxInput
            field="hideFields"
            value={hideFields}
            label={t("adminOnboarding.hideFields")}
            onChange={(value: boolean) => setHideFields(value)}
          />
        </>
      );
    if (currentStep === "finish") return tlb("adminOnboarding.textFinish");
  };

  if (hiding) {
    return (
      <Modal
        show={!postponeAdminOnboardingWizard}
        backdrop={"static"}
        onHide={() => {
          return;
        }}
      >
        <Modal.Body>{t("adminOnboarding.showAgainLaterPrompt")}</Modal.Body>
        <Modal.Footer>
          <Button bsStyle="warning" onClick={() => finish()}>
            {t("adminOnboarding.dontShowAgain")}
          </Button>
          <Button bsStyle="success" onClick={() => postpone()}>
            {t("adminOnboarding.showAgainLater")}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <Modal show={show} backdrop={"static"} onHide={() => setHiding(true)}>
      <Modal.Header closeButton={currentStep !== "finish"}>
        <Modal.Title>{getTitle()}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {getInfoText()}

        {currentStep === "uxPolicy" && <div></div>}
      </Modal.Body>
      <Modal.Footer>
        {currentStep === "start" && (
          <>
            <ButtonWithSpinner showSpinner={processing} bsStyle="warning" onClick={() => finish()} disabled={processing}>
              {t("adminOnboarding.skipAndDontShowAgain")}
            </ButtonWithSpinner>
            <ButtonWithSpinner showSpinner={processing} bsStyle="default" onClick={() => postpone()} disabled={processing}>
              {t("adminOnboarding.askMeLater")}
            </ButtonWithSpinner>
            <ButtonWithSpinner showSpinner={processing} bsStyle="success" onClick={() => nextStep()} disabled={processing}>
              {t("adminOnboarding.continue")}
            </ButtonWithSpinner>
          </>
        )}
        {(currentStep === "approval" || currentStep === "uxPolicy") && (
          <Button bsStyle="warning" onClick={() => nextStep()}>
            {t("adminOnboarding.skip")}
          </Button>
        )}
        {currentStep === "approval" && (
          <ButtonWithSpinner showSpinner={processing} bsStyle="success" onClick={() => confirmApproval()} disabled={processing}>
            {t("adminOnboarding.confirm")}
          </ButtonWithSpinner>
        )}
        {currentStep === "uxPolicy" && (
          <ButtonWithSpinner showSpinner={processing} bsStyle="success" onClick={() => confirmUxPolicy()} disabled={processing}>
            {t("adminOnboarding.confirm")}
          </ButtonWithSpinner>
        )}
        {currentStep === "finish" && (
          <ButtonWithSpinner showSpinner={processing} bsStyle="success" onClick={() => nextStep()} disabled={processing}>
            {t("adminOnboarding.finish")}
          </ButtonWithSpinner>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default AdminOnboardingWizard;
