import { useState, useCallback, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
// import { useHistory } from "react-router-dom";
import moment from "moment";
import { groupBy } from "lodash";
import { useBus, BusEvent } from "../../utils/useBus";
import { useStore } from "../../shared/store/store";
import TemporalPicker from "../common/TemporalPicker";
import { Row, Col, FormGroup, ControlLabel, Button } from "react-bootstrap";
import CustomFields from "../customField/CustomFields";
import TextFieldGroup from "../common/TextFieldGroup";
import SelectGroup from "../common/SelectGroup";
import NumberFieldGroup from "../common/NumberFieldGroup";
import CheckboxInput from "../common/CheckboxInput";
import { expenseCategories, vatOptions } from "../../shared/utils/constants";
import { capitalize } from "../../shared/utils/helpers";
import { mileageConstructor } from "../../shared/constructors/mileageConstructor";
import {
  calculateKmPriceFromCustomValues,
  injectDrivingTaxCustomValues,
  getCommuterKilometersDrivenInYear,
  drivingCustomValueChangesTriggersKmRateRecalculation,
  createExpenseFromTollStationPassing,
  getBestTollstationExpenseType
} from "../../shared/utils/expenseUtils";
import Attachments from "../attachment/Attachments";
import MileageMap from "../mileage/MileageMap";
import Map from "../google/Map";
import Icon from "../common/Icon";
import i18n from "../../shared/i18n/i18nConfig";

import { useUserConfiguration, useExchangeRates, useMileage, useTollstationsByDirectionsRoute /* useExpenses */ } from "../../shared/queries/queries";

import "../../styles/report.css";
import {
  Attachment,
  Currency,
  Expense,
  ExpenseCustomValue,
  ExpenseType,
  ProductConfiguration,
  ReportCustomValue,
  ValidateExpenseErrors,
  MileageTrackSegment,
  Tollstation,
  Mileage
} from "../../shared/types";
import useConstructor from "../../shared/utils/useConstructor";
import Spinner from "../common/Spinner";
import { PreferredExchangeRate, addPreferredExchangeRate, removePreferredExchangeRate } from "../../utils/webclientStore";

// Editor for a single expense
interface ExpenseEditorProps {
  expense: Expense;
  onChange: (partialExpense: Partial<Expense>, forcePersist: boolean, fromConstructor?: boolean) => void;
  onChangeAdditionalExpenses: (additionalExpenses: Expense[], fromConstructor?: boolean) => void;
  readOnly?: boolean;
  enableAttachmentsDrawer: boolean;
  availableExpenseTypes: ExpenseType[];
  ignoreReadOnlyForRecalculations?: boolean;
  validationErrors?: ValidateExpenseErrors;
  externalProductConfig?: ProductConfiguration;
}
const ExpenseEditor = ({
  expense,
  onChange,
  onChangeAdditionalExpenses,
  readOnly,
  enableAttachmentsDrawer,
  availableExpenseTypes,
  ignoreReadOnlyForRecalculations,
  validationErrors,
  externalProductConfig
}: ExpenseEditorProps) => {
  const [t] = useTranslation();
  // const history = useHistory();

  let initialExchangeRate =
    expense.currency !== "NOK" && expense.unitPrice > 0 && expense.unitPriceNok > 0
      ? Number((expense.unitPriceNok / expense.unitPrice).toFixed(4))
      : 1;

  interface CurrencyOption {
    label: string;
    value: string;
  }
  const [currencyOptions, setCurrencyOptions] = useState<CurrencyOption[]>([{ label: "NOK: Norske kroner", value: "NOK" }]);
  const [exchangeRate, setExchangeRate] = useState(initialExchangeRate); // The currently active exchange rate (defaults to 1:1 for NOK)
  const [routeEstimatorQuery, setRouteEstimatorQuery] = useState<{ from: string; to: string } | null>(null);
  const [berserkMode, setBerserkMode] = useState(false);
  const [commuterWarning, setCommuterWarning] = useState("");
  const [mileageUuidToPreview, setMileageUuidToPreview] = useState("");
  const [routeToEstimateTollstations, setRouteToEstimateTollstations] = useState<any | null>(null);
  const [mileageToEstimateTollstations, setMileageToEstimateTollstations] = useState<Mileage | null>(null);
  const [tollStationsForRoute, setTollStationsForRoute] = useState<Tollstation[]>([]);
  const [tollStationsForRouteSelected, setTollStationsForRouteSelected] = useState<(Tollstation | null)[]>([]);
  // const [relatedExpenses, setRelatedExpenses] = useState<Expense[]>([]);

  // Clone some of the values we loaded in with. These will be used to block instant changes to exchangerate after loading an existing expense with a custom exchangerate
  const [initialExpense] = useState({
    starts: expense.starts.valueOf(),
    currency: expense.currency.valueOf(),
    exchangeRate: initialExchangeRate.valueOf()
  });

  const userConfigurationQuery = useUserConfiguration();
  const exchangeRatesQuery = useExchangeRates(expense.starts);
  const mileageQuery = useMileage(mileageUuidToPreview);
  const tollstationsByDirectionsRouteQuery = useTollstationsByDirectionsRoute(routeToEstimateTollstations);
  // const expensesQuery = useExpenses();
  const preferredExchangeRates: PreferredExchangeRate[] = useStore((state) => state.clientSpecific.preferredExchangeRates);

  // Build a static currency list based on the first available set of exchange rates
  useEffect(() => {
    // Bail out if currencies has already been populated, or the query is empty
    if (currencyOptions.length > 1 || !exchangeRatesQuery.data) return;
    // Clone the currencies, then put the popular ones on top, including a synthetic currency for NOK
    let newCurrencies: Currency[] = [...exchangeRatesQuery.data];
    if (newCurrencies.length > 0) {
      ["SEK", "DKK", "USD", "GBP", "EUR"].forEach((currencyCode) => {
        const currency = newCurrencies.find((o) => o.code === currencyCode);
        if (currency) newCurrencies.unshift(currency);
      });
    }
    newCurrencies.unshift({ code: "NOK", rate: 1, description: "Norske kroner" });

    // Apply UX policy for hidden currencies
    const hiddenCurrencies = (externalProductConfig || productConfig)?.uxPolicy?.expense?.hiddenCurrencyCodes;
    if (hiddenCurrencies) newCurrencies = newCurrencies.filter((o) => !hiddenCurrencies.includes(o.code));

    // Map to selectgroup-friendly options
    const newOptions = newCurrencies.map((c) => ({
      label: `${c.code}: ${c.description}`,
      value: c.code
    }));
    setCurrencyOptions(newOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exchangeRatesQuery.data]);

  // Handle bus events
  const busHandler = (event: BusEvent) => {
    if (event === "BERSERK_MODE") setBerserkMode(true);
  };
  useBus(busHandler);

  // Whenever the tollstation query flips to success, it means we have estimated a driving route and checked for tollstations along the route
  // Populate the relevant states with new tollstations
  useEffect(() => {
    if (tollstationsByDirectionsRouteQuery.status === "success" && tollstationsByDirectionsRouteQuery.data) {
      setTollStationsForRoute(tollstationsByDirectionsRouteQuery.data);
      setTollStationsForRouteSelected(tollstationsByDirectionsRouteQuery.data.map((o) => null));
      changeTollStations([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tollstationsByDirectionsRouteQuery.data]);

  // If the expense UUID changes, we've started editing a different expense without unmounting the editor first
  // This typically happens when the user clicks "New" while editing an expense, to add and edit a new one
  // Reset routeEstimatorQuery so we don't immediately render the route from the previous expense
  useEffect(() => {
    setRouteEstimatorQuery(null);
  }, [expense.uuid]);

  // Whenever expensesQuery gets fresh data, find any related expenses
  // useEffect(() => {
  //   if (expense.uuid && expense.groupUuid && expensesQuery.data) {
  //     const related = expensesQuery.data.expenses.filter((o) => o.groupUuid === expense.groupUuid && o.uuid !== expense.uuid && !o.deleted);
  //     setRelatedExpenses(related);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [expensesQuery.data, expense.uuid, expense.groupUuid]);

  // Configuration shorthands
  const productConfig: ProductConfiguration | null = userConfigurationQuery.data ? userConfigurationQuery.data.configuration.product : null;
  const allExpenseTypes = productConfig ? productConfig.expenseTypes : [];

  const allExpenseCustomFields = useMemo(() => {
    if (externalProductConfig) return externalProductConfig.expenseCustomFields;
    if (productConfig) return productConfig.expenseCustomFields;
    return [];
  }, [externalProductConfig, productConfig]);

  const customFieldDrivingTaxfree = allExpenseCustomFields.find((o) => o.systemName === "driving_taxfree_part");
  const customFieldDrivingTaxable = allExpenseCustomFields.find((o) => o.systemName === "driving_taxable_part");
  const customFieldDrivingFromaddress = allExpenseCustomFields.find((o) => o.systemName === "driving_fromaddress");
  const customFieldDrivingToAddress = allExpenseCustomFields.find((o) => o.systemName === "driving_toaddress");
  const nonVatEnabled = productConfig ? (productConfig.nonVatEnabled ? true : false) : false;

  // Grab a quick lookup array of expensecustomfields that apply to all expensetypes
  // This is used to decide which expensecustomvalues should be preserved across expensetype changes
  const expenseCustomFieldIdsForAllExpenseTypes = allExpenseCustomFields.filter((o) => o.expenseTypeId === 0).map((o) => o.id);

  // Grab the relevant customfields for this expense
  const expenseCustomFields = useMemo(
    () => allExpenseCustomFields.filter((o) => o.expenseTypeId === 0 || o.expenseTypeId === expense.expenseTypeId),
    [allExpenseCustomFields, expense.expenseTypeId]
  );

  // Grab any frequently used expense types from config.
  // Any that already exists in the available expenseTypes (i.e is not hidden/deactivated) should be cloned and inserted into a synthetic expense category before the others.
  const frequentExpenseTypes = useMemo(() => {
    if (productConfig)
      return productConfig.frequentExpenseTypes
        .filter((o) => availableExpenseTypes.find((p) => p.id === o.id))
        .map((o) => ({ ...o, expenseCategoryId: -1 }));
    return [];
  }, [productConfig, availableExpenseTypes]);

  // If we've loaded an existing expense into the editor, and it has an expenseTypeId that is currently unavailable, we need to dig it up from the legacy types and grudgingly offer it as an option
  // Create a local mutable copy which will be used going forward, and push the legacy type to it if needed
  const expenseTypes = useMemo(() => [...availableExpenseTypes], [availableExpenseTypes]);
  const expenseTypeFromLoadedExpense = expenseTypes.find((o) => o.id === expense.expenseTypeId);
  if (!expenseTypeFromLoadedExpense) {
    const legacyExpenseType = productConfig ? productConfig.expenseTypes.find((o) => o.id === expense.expenseTypeId) : null;
    if (legacyExpenseType) expenseTypes.push(legacyExpenseType);
  }

  // Used to detect whether the user has passed the 50k km driving limit in a year that reduces the mileage refund
  const currentYear = new Date().getFullYear();
  let commuterKilometersDrivenThisYear = userConfigurationQuery.data
    ? getCommuterKilometersDrivenInYear(userConfigurationQuery.data.configuration, currentYear)
    : 0;

  // Generate the selectgroup-friendly expensetype- and currency-options
  // Merge frequently used expense types with the full list, and build a selectgroup-friendly options array
  const buildExpenseTypeOptions = () => {
    const expenseTypeOptions: { value: number; label: string; isDisabled?: boolean }[] = [];
    const expenseCategoriesIncludingFrequent = [
      {
        description: "frequentlyUsed",
        id: -1
      },
      ...expenseCategories
    ];
    const expenseTypesIncludingFrequent = [...frequentExpenseTypes, ...expenseTypes];
    const categories = groupBy(expenseTypesIncludingFrequent, (r) => r.expenseCategoryId);

    Object.keys(categories)
      .sort()
      .forEach((cat) => {
        const ec = expenseCategoriesIncludingFrequent.find((ec) => ec.id.toString() === cat);
        const label = ec ? capitalize(t(`expenseCategories.${ec.description}`)) : "";
        expenseTypeOptions.push({ value: -1, label, isDisabled: true });
        categories[cat].forEach((exptype) => {
          expenseTypeOptions.push({
            value: exptype.id,
            label: i18n.exists(`expenseTypes.${exptype.description.toLowerCase()}`)
              ? capitalize(t(`expenseTypes.${exptype.description.toLowerCase()}`))
              : exptype.description
          });
        });
      });

    return expenseTypeOptions;
  };
  const expenseTypeOptions = buildExpenseTypeOptions();

  // UX policy
  const uxPolicyExpense = productConfig?.uxPolicy?.expense;
  const policyDescription = uxPolicyExpense?.fields?.description;
  const policyRefund = uxPolicyExpense?.fields?.refund;
  const policyReceipt = uxPolicyExpense?.fields?.receipt;
  const policyTotalVatNok = uxPolicyExpense?.fields?.totalVatNok;

  // Pass this callback to calculateKmPriceFromCustomValues. It will set the commuter limit warning messages as a side effect of calculating.
  const onCommuterWarning = useCallback(
    (warning) => {
      if (warning !== commuterWarning) setCommuterWarning(warning);
    },
    [commuterWarning]
  );

  // This is called by recalculate()
  // fromConstructor indicates the changes comes from initialization of default values, not user input
  const recalculateFinished = (changes: Partial<Expense>, fromConstructor?: boolean) => {
    if (readOnly && !ignoreReadOnlyForRecalculations) return;
    const forcePersist = changes.attachments !== undefined;
    onChange(changes, forcePersist, fromConstructor);
    return true;
  };

  const changeTollStations = (stations: (Tollstation | null)[]) => {
    if (mileageToEstimateTollstations) {
      // Check for related toll stations that should also be created
      const tollstationExpenseType = productConfig ? getBestTollstationExpenseType(productConfig.expenseTypes) : null;
      const tollExpenses =
        routeToEstimateTollstations && tollstationExpenseType
          ? stations
              .filter((station): station is Tollstation => station !== null)
              .map((station) => createExpenseFromTollStationPassing(station, mileageToEstimateTollstations, tollstationExpenseType))
          : [];
      onChangeAdditionalExpenses(tollExpenses, false);
    }
  };

  // Perform initial injection of customvalues if we're starting out with a driving expense
  // Used to be componentDidMount
  useConstructor(() => {
    if (!productConfig) return;
    const expenseType = expenseTypes.find((o) => o.id === expense.expenseTypeId) || expenseTypes.find((et) => et.is_Default) || expenseTypes[0];
    if (expenseType && expenseType.is_Driving && expense.unitPrice === 0) {
      // New mileage expense, populate default mileage cost
      const kmprices = calculateKmPriceFromCustomValues(
        expenseCustomFields,
        [],
        expense.starts,
        productConfig,
        commuterKilometersDrivenThisYear,
        onCommuterWarning
      );
      const changes: {
        unitPrice: number;
        unitPriceNok: number;
        totalValueNok: number;
        expenseCustomValues: ExpenseCustomValue[];
      } = {
        unitPrice: kmprices.kmRate,
        unitPriceNok: kmprices.kmRate,
        totalValueNok: kmprices.kmRate,
        expenseCustomValues: []
      };

      // If customfields for taxfree and taxable part of driving exists, they must be populated
      if (customFieldDrivingTaxfree && customFieldDrivingTaxable) {
        changes.expenseCustomValues = injectDrivingTaxCustomValues(
          expense.uuid,
          kmprices,
          1,
          changes.expenseCustomValues,
          customFieldDrivingTaxfree,
          customFieldDrivingTaxable
        );
      }
      // We explicitly do not set the dirty flag here as the user hasn't made any changes, so as not to trigger an "unsaved changes" warning when navigating
      recalculateFinished(changes, true);
      onChangeAdditionalExpenses([], true);
    }
  });

  // If we just received any new exchange rates, or the starts/currency fields were changed, conditionally recalculate the exchange rate
  useEffect(() => {
    if (expense.currency !== "NOK" && exchangeRatesQuery.data) {
      // Only inject rate and recalculate if the user has changed either currency or date since the expense was initially loaded
      // This prevents instant recalulation when an existing expense with a custom exchangerate is opened in the editor
      // All new expenses starts with NOK, so they will always have a currency change before reaching this point

      // if (moment(expense.starts).toISOString() !== moment(initialExpense.starts).toISOString() || expense.currency !== initialExpense.currency) {
      var expStarts = moment(expense.starts).format("YYYY-MM-DD");
      var initialStarts = moment(initialExpense.starts).format("YYYY-MM-DD");
      if (expStarts !== initialStarts || expense.currency !== initialExpense.currency) {
        const rate = exchangeRatesQuery.data.find((o) => o.code === expense.currency);
        if (rate && rate.rate !== exchangeRate) {
          let rateWithFee = rate.rate;
          if (productConfig?.exchangerateFee) {
            const fee =
              (productConfig.exchangerateFee && !isNaN(Number(productConfig.exchangerateFee)) ? Number(productConfig.exchangerateFee) : 0) / 100;
            rateWithFee = Number((rateWithFee * (fee + 1)).toFixed(6));
          }
          if (rateWithFee !== exchangeRate) {
            recalculate({ exchangeRate: rateWithFee });
          }
        }
      }
    }

    // Use only datepart (substring) of expense.starts to dodge timezone issues when the expense reloads from server, ref TTN-879
    // Using expense.starts as-is means that inside a report, after saving the expense, the timezone will have changed and the timestamp differs..
    // .. triggering a rerender with the default exchangerate
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exchangeRatesQuery.data, expense.starts.substring(0, 10), expense.currency]);

  // Bail out here if we don't have a valid product configuration
  if (!productConfig) return <div>Loading ExpenseEditor, waiting for productConfig...</div>;

  // The expensetype was changed
  const onChangeExpenseType = (selectedExpenseType: number) => {
    if (selectedExpenseType !== expense.expenseTypeId) recalculate({ expenseTypeId: selectedExpenseType });
  };

  // The currency was changed
  const onChangeCurrency = (selectedCurrency: string) => {
    if (selectedCurrency !== expense.currency) recalculate({ currency: selectedCurrency });
  };

  const onChangeDateRange = (startDate: string, endDate: string, fromConstructor?: boolean) => {
    const starts = moment(startDate).toISOString();
    const ends = moment(endDate).toISOString();
    recalculate({ starts, ends }, fromConstructor);
  };

  const onChangeCustomValues = (customValues: (ReportCustomValue | ExpenseCustomValue)[], fromConstructor?: boolean) => {
    recalculate({ expenseCustomValues: customValues as ExpenseCustomValue[] }, fromConstructor);
  };

  const onChangeAttachments = (attachments: Attachment[]) => {
    recalculate({ attachments });
  };

  // Calculate the VAT portion of a sum based on a percentage
  const getVAT = (sum: number, percent: number) => {
    if (sum === undefined || Number(sum) === 0 || percent === undefined || Number(percent) === 0) return 0;
    const divider = 100 + Number(percent === 0 ? 1 : percent);
    return (sum / divider) * percent;
  };

  // Check if we have a locked exchangerate stored
  // This flag affects the state of the rate lock toggle button
  const lockedExchangeRate = preferredExchangeRates.find((o) => o.currency === expense.currency);

  // Toggle the current exchange rate (lock/unlock)
  const toggleExchangeRatePreference = () => {
    if (lockedExchangeRate) {
      removePreferredExchangeRate(expense.currency);
    } else {
      addPreferredExchangeRate(expense.currency, exchangeRate);
    }
  };

  // All changes to the expense pass through here before being thrown up the chain.
  // If the change that was just applied should affect any other fields, this is the place to bolt those changes on
  // This function always flags the expense itself as dirty, but sub-entities like attachments and customvalues need to provide their own dirty flags
  // fromConstructor indicates the changes comes from initialization of default values, not user input
  const recalculate = (partial: Partial<Expense>, fromConstructor?: boolean) => {
    if (readOnly && !ignoreReadOnlyForRecalculations) return;
    const changes = { ...partial, dirty: moment().toISOString() };

    // Handle non-existing expensetype for users moved from another company with custom types
    const previousExpenseType =
      allExpenseTypes.find((o) => o.id === expense.expenseTypeId) || allExpenseTypes.find((et) => et.is_Default) || allExpenseTypes[0];

    ///////////////////
    // expenseTypeId //
    ///////////////////
    if (changes.expenseTypeId) {
      const expenseType = allExpenseTypes.find((o) => o.id === changes.expenseTypeId) || allExpenseTypes[0];

      // Check if the end date should change.
      // Expense types in category 1 (lodging) requires a range (both starts and ends dates), while other categories should have starts == ends
      let ends = expense.starts; // Default to setting ends = starts
      if (expenseType.expenseCategoryId === 1) {
        ends = expense.ends; // Default to the existing ends date...
        if (
          expense.ends === expense.starts // .. but if they were already identical, push the ends date one day forward
        )
          ends = moment(expense.starts).add(1, "days").toISOString();
      }
      changes.ends = ends;

      // Check if the new expensetype has a different default VAT percent than the previous one
      // If it has the same default, ther user is probably switching to a similar expense type, we'll leave any previous overrides alone.
      // This is only relevant if the currency is NOK!
      if (expense.currency === "NOK" && (previousExpenseType.vatPercent !== expenseType.vatPercent || (nonVatEnabled && expense.vatPercent !== 0))) {
        // The default VAT percentage changed. Set the new one, and recalculate VAT
        changes.vatPercent = expenseType.vatPercent;
        if (nonVatEnabled) changes.vatPercent = 0;
        changes.totalVatNok = getVAT(expense.totalValueNok, expenseType.vatPercent);
      }

      // Wipe any customValues that are expensetype-specific, let the CustomFields component recreate any defaults for the new expense type
      // Keep customValues that apply to all expensetypes. This way the user will keep his project number setting when switching from hotel to breakfast, but the hotel-specific value will be removed
      changes.expenseCustomValues = expense.expenseCustomValues
        ? expense.expenseCustomValues.filter((cv) => expenseCustomFieldIdsForAllExpenseTypes.includes(cv.expenseCustomFieldId))
        : [];

      // Check if we changed to a mileage/driving type from a normal expense type
      if (expenseType.is_Driving && !previousExpenseType.is_Driving) {
        changes.currency = "NOK";
        changes.unitName = "Km";
        changes.vatPercent = 0;
        changes.totalVatNok = 0;
        changes.units = 1;
        const kmprices = calculateKmPriceFromCustomValues(
          expenseCustomFields,
          [], // Any relevant customvalues were nuked when we switched expensetype
          expense.starts,
          productConfig,
          commuterKilometersDrivenThisYear,
          onCommuterWarning
        );
        changes.unitPrice = kmprices.kmRate;
        changes.unitPriceNok = kmprices.kmRate;
        changes.totalValueNok = kmprices.kmRate;
        changes.receipt = false;

        // If customfields for taxfree and taxable part of driving exists, they must be populated
        if (customFieldDrivingTaxfree && customFieldDrivingTaxable)
          changes.expenseCustomValues = injectDrivingTaxCustomValues(
            expense.uuid,
            kmprices,
            changes.units,
            changes.expenseCustomValues,
            customFieldDrivingTaxfree,
            customFieldDrivingTaxable
          );
      }

      // Check if we changed to a normal expense type from a mileage/driving type
      if (!expenseType.is_Driving && previousExpenseType.is_Driving) {
        changes.currency = "NOK";
        changes.unitName = "Antall";
        changes.vatPercent = expenseType.vatPercent;
        if (nonVatEnabled) changes.vatPercent = 0;
        changes.units = 1;
        changes.unitPrice = 0;
        changes.unitPriceNok = 0;
        changes.totalValueNok = 0;
        changes.totalVatNok = 0;
        changes.receipt = true;
      }

      return recalculateFinished(changes, fromConstructor);
    }

    ////////////
    // starts //
    ////////////
    if (changes.starts) {
      if (expense.currency !== "NOK") {
        // We're on a foreign currency, update NOK sums
        const nok = (expense.unitPrice || 0) * exchangeRate;
        changes.unitPriceNok = nok;
        changes.totalValueNok = nok;
      }
      const prevExpenseType: ExpenseType | undefined = allExpenseTypes.find((o) => o.id === previousExpenseType.id);
      if (prevExpenseType && prevExpenseType.is_Driving) {
        // We're changing the date of a driving expense
        const kmprices = calculateKmPriceFromCustomValues(
          expenseCustomFields,
          expense.expenseCustomValues,
          changes.starts,
          productConfig,
          commuterKilometersDrivenThisYear,
          onCommuterWarning,
          changes.unitPriceNok || undefined //expense.unitPriceNok
        );
        changes.unitPrice = kmprices.kmRate;
        changes.unitPriceNok = kmprices.kmRate;
        changes.totalValueNok = kmprices.kmRate * (expense.units !== undefined ? expense.units : 1);

        // If customfields for taxfree and taxable part of driving exists, they must be populated
        changes.expenseCustomValues = expense.expenseCustomValues;
        if (customFieldDrivingTaxfree && customFieldDrivingTaxable)
          changes.expenseCustomValues = injectDrivingTaxCustomValues(
            expense.uuid,
            kmprices,
            expense.units !== undefined ? expense.units : 1,
            changes.expenseCustomValues,
            customFieldDrivingTaxfree,
            customFieldDrivingTaxable
          );
      }

      return recalculateFinished(changes, fromConstructor);
    }

    //////////////
    // currency //
    //////////////
    if (changes.currency) {
      if (changes.currency !== "NOK") {
        // We're switching to foreign
        // Keep existing unitPrice, recalculate all NOK values and nuke VAT.
        changes.unitPriceNok = (expense.unitPrice || 0) * exchangeRate;
        changes.totalValueNok = (expense.unitPrice || 0) * exchangeRate;
        changes.vatPercent = 0;
        changes.totalVatNok = 0;
      } else if (changes.currency === "NOK") {
        // We're switching to NOK
        // Transfer unitPrice to the NOK values and reinstate VAT.
        const expenseType = allExpenseTypes.find((o) => o.id === previousExpenseType.id);

        changes.unitPriceNok = expense.unitPrice || 0;
        changes.totalValueNok = expense.unitPrice || 0;
        if (nonVatEnabled) changes.vatPercent = 0;
        if (expenseType) {
          changes.vatPercent = expenseType.vatPercent;
          changes.totalVatNok = getVAT(changes.totalValueNok, expenseType.vatPercent);
        }
      }

      return recalculateFinished(changes, fromConstructor);
    }

    //////////////////
    // exchangeRate //
    //////////////////
    if (changes.exchangeRate !== undefined) {
      // Exchange rate was either manually overridden, or a new exchange rate was just returned from the server.
      // If we're still in foreign currency mode, update the converted NOK sums
      if (expense.currency !== "NOK") {
        // If we have a saved preference for the new currency, use that preference instead
        var newRate = lockedExchangeRate && lockedExchangeRate.currency === expense.currency ? lockedExchangeRate.rate : changes.exchangeRate;
        setExchangeRate(newRate);

        changes.unitPriceNok = (expense.unitPrice || 0) * newRate;
        changes.totalValueNok = (expense.unitPrice || 0) * newRate;
        // IMPORTANT: This is not a stored field, it only exists in component state to be rendered and to calculate other fields!
        // It should only be picked up here if it was just physically overriden by the user.
        // We need to nuke it here to make sure it's not passed on to the parent, and comes back down later to make noise
        delete changes.exchangeRate;
        return recalculateFinished(changes, fromConstructor);
      }
    }

    ///////////////
    // units //
    ///////////////
    if (changes.units !== undefined) {
      // Units is only changed when we're on a driving/mileage expense type, so we only need to update totalValueNok and inject taxfree/taxable
      const nok = expense.unitPriceNok * changes.units;
      changes.totalValueNok = nok;

      // If customfields for taxfree and taxable part of driving exists, they must be populated
      if (customFieldDrivingTaxfree && customFieldDrivingTaxable) {
        changes.expenseCustomValues = expense.expenseCustomValues;
        const kmprices = calculateKmPriceFromCustomValues(
          expenseCustomFields,
          changes.expenseCustomValues,
          expense.starts,
          productConfig,
          commuterKilometersDrivenThisYear,
          onCommuterWarning,
          expense.unitPriceNok
        );
        changes.expenseCustomValues = injectDrivingTaxCustomValues(
          expense.uuid,
          kmprices,
          changes.units,
          changes.expenseCustomValues,
          customFieldDrivingTaxfree,
          customFieldDrivingTaxable
        );
      }
      return recalculateFinished(changes, fromConstructor);
    }

    ///////////////
    // unitPrice //
    ///////////////
    if (changes.unitPrice !== undefined) {
      // Unit price is only changed when we're on a foreign currency, so update unitPriceNok and totalValueNok
      const nok = changes.unitPrice * exchangeRate;
      changes.unitPriceNok = nok;
      changes.totalValueNok = nok;
      return recalculateFinished(changes, fromConstructor);
    }

    //////////////////
    // unitPriceNok //
    //////////////////
    if (changes.unitPriceNok !== undefined) {
      if (expense.currency === "NOK") {
        // Unit price in NOK was changed in NOK mode. Recalculate unitPrice, totalValueNok and VAT
        changes.unitPrice = changes.unitPriceNok;
        changes.totalValueNok = changes.unitPriceNok * expense.units;
        changes.totalVatNok = getVAT(changes.totalValueNok, expense.vatPercent);

        // If this is a driving expense, and customfields for taxfree and taxable part of driving exists, they must be populated
        const currentExpenseType = allExpenseTypes.find((o) => o.id === previousExpenseType.id);
        if (currentExpenseType && currentExpenseType.is_Driving && customFieldDrivingTaxfree && customFieldDrivingTaxable) {
          changes.expenseCustomValues = expense.expenseCustomValues;
          const kmprices = calculateKmPriceFromCustomValues(
            expenseCustomFields,
            changes.expenseCustomValues,
            expense.starts,
            productConfig,
            commuterKilometersDrivenThisYear,
            onCommuterWarning,
            changes.unitPriceNok
          );
          changes.expenseCustomValues = injectDrivingTaxCustomValues(
            expense.uuid,
            kmprices,
            expense.units,
            changes.expenseCustomValues,
            customFieldDrivingTaxfree,
            customFieldDrivingTaxable
          );
        }
      } else {
        // Unit price in NOK was changed in foreign currency mode. Recalculate totalValueNok, assuming the (foreign) unitPrice has been entered correctly, and the user only knows the two sums
        changes.totalValueNok = changes.unitPriceNok;
        // Reset the exchangerate in state to reflect the new numbers
        const newExchangeRate = expense.unitPrice > 0 && expense.unitPriceNok > 0 ? Number((changes.unitPriceNok / expense.unitPrice).toFixed(4)) : 1;
        if (lockedExchangeRate && lockedExchangeRate.currency === expense.currency) {
          // If we have a saved preference for the new currency, use that preference instead
          setExchangeRate(lockedExchangeRate.rate);
        } else {
          setExchangeRate(newExchangeRate);
        }
      }
      return recalculateFinished(changes, fromConstructor);
    }

    ////////////////
    // vatPercent //
    ////////////////
    if (changes.vatPercent !== undefined) {
      // VAT percentage was overridden
      // If we're using NOK, update totalVatNok
      if (expense.currency === "NOK") {
        changes.totalVatNok = getVAT(expense.unitPriceNok, changes.vatPercent);
      }
      return recalculateFinished(changes, fromConstructor);
    }

    /////////////////
    // totalVatNok //
    /////////////////
    if (changes.totalVatNok !== undefined) {
      // Total VAT sum in NOK was overridden
      // .. but nobody cares.
    }

    /////////////////////////
    // expenseCustomValues //
    /////////////////////////
    if (changes.expenseCustomValues !== undefined) {
      const prevExpenseType = allExpenseTypes.find((o) => o.id === previousExpenseType.id);
      if (prevExpenseType && prevExpenseType.is_Driving) {
        // We're changing customValues on a driving expense
        // Check if we changed any fields that forces a recalculation of the rate per kilometer
        const changesTriggersNewKmrate = drivingCustomValueChangesTriggersKmRateRecalculation(
          expenseCustomFields,
          expense.expenseCustomValues,
          changes.expenseCustomValues
        );
        const kmprices = calculateKmPriceFromCustomValues(
          expenseCustomFields,
          changes.expenseCustomValues,
          expense.starts,
          productConfig,
          commuterKilometersDrivenThisYear,
          onCommuterWarning,
          changesTriggersNewKmrate ? undefined : changes.unitPriceNok || expense.unitPriceNok // If our change did not affect the rate, pass along the current rate in case the user already overrode it
        );
        changes.unitPrice = kmprices.kmRate;
        changes.unitPriceNok = kmprices.kmRate;
        changes.totalValueNok = kmprices.kmRate * (expense.units !== undefined ? expense.units : 1);

        // If customfields for taxfree and taxable part of driving exists, they must be populated
        if (customFieldDrivingTaxfree && customFieldDrivingTaxable)
          changes.expenseCustomValues = injectDrivingTaxCustomValues(
            expense.uuid,
            kmprices,
            expense.units !== undefined ? expense.units : 1,
            changes.expenseCustomValues,
            customFieldDrivingTaxfree,
            customFieldDrivingTaxable
          );
      }
    }

    return recalculateFinished(changes, fromConstructor);
  };

  const {
    expenseTypeId,
    starts,
    ends,
    description,
    currency,
    units,
    unitPrice,
    unitPriceNok,
    totalValueNok,
    refund,
    receipt,
    vatPercent,
    totalVatNok,
    attachments
  } = expense;

  const expenseType = expenseTypes.find((o) => o.id === expenseTypeId) || expenseTypes.find((et) => et.is_Default) || expenseTypes[0];
  const useDateRange = expenseType && expenseType.expenseCategoryId === 1; // Lodging requires starts/ends dates, others require a single date
  const isDriving = expenseType && expenseType.is_Driving;

  let showRouteEstimatorLink = false;
  let drivingFromAddress = "";
  let drivingToAddress = "";

  if (isDriving && !expense.mileageTrackUuid && customFieldDrivingFromaddress && customFieldDrivingToAddress) {
    // This is a driving expense, it was not created from a GPS track, and customfields for addresses are present
    const cvFromAddress = expense.expenseCustomValues.find((o) => o.expenseCustomFieldId === customFieldDrivingFromaddress.id);
    const cvToAddress = expense.expenseCustomValues.find((o) => o.expenseCustomFieldId === customFieldDrivingToAddress.id);
    if (cvFromAddress && cvToAddress && cvFromAddress.value && cvToAddress.value) {
      // We have customvalues for the two address fields
      showRouteEstimatorLink = true;
      drivingFromAddress = cvFromAddress.value;
      drivingToAddress = cvToAddress.value;
    }
  }
  // Grab VAT options, apply UX policy if available to hide hidden options, and map to selectgroup-friendly array
  const hiddenVatOptions = (externalProductConfig || productConfig)?.uxPolicy?.expense?.hiddenVatPercentages || [];
  const availableVatOptions = vatOptions
    .filter((v) => !hiddenVatOptions.includes(v))
    .map((v) => ({
      label: `${v}%`,
      value: v
    }));

  if (berserkMode)
    availableVatOptions.push({
      label: "INVALID OPTION - WILL BREAK",
      value: 50
    });

  // The following line exists purely to dodge this hilarious bug by nudging the number of conditionals to a..  better? number, and can be removed when react is upgraded
  // https://github.com/facebook/react/issues/21328
  // https://github.com/facebook/react/issues/24279

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const wtfLol = (((true ? true : true) ? true : true) ? true : true) ? true : true;

  // Switch to the editor and edit the expense. Used for linking related expenses
  // const editExpense = (expense: Expense) => {
  //   history.push(`/expense/${expense.uuid}?from=expenses`);
  // };

  return (
    <Row>
      <Col sm={6}>
        <SelectGroup
          field="expenseTypeId"
          label={t("expenseEditorView.expenseType")}
          value={expenseType.id}
          onChange={(value: number) => onChangeExpenseType(value)}
          options={expenseTypeOptions}
          readOnly={readOnly}
        />

        <FormGroup>
          <ControlLabel>{useDateRange ? capitalize(t("expenseEditorView.timespan")) : capitalize(t("expenseEditorView.date"))}</ControlLabel>
          <div>
            <TemporalPicker
              useRange={useDateRange}
              startDate={starts}
              endDate={ends}
              onChange={(o: { start: string; end?: string }, fromConstructor?: boolean) =>
                onChangeDateRange(o.start, useDateRange ? (o.end ? o.end : o.start) : o.start, fromConstructor)
              }
              readOnly={readOnly}
              parentEntityUuid={expense.uuid}
            />
          </div>
        </FormGroup>

        <div className={policyDescription && policyDescription.hide ? "hidden" : ""}>
          <TextFieldGroup
            field="description"
            label={t("expenseEditorView.description")}
            value={description}
            onChange={(value: string) => recalculate({ description: value })}
            readOnly={readOnly || (policyDescription && policyDescription.readOnly)}
            required={policyDescription && policyDescription.required}
            error={validationErrors?.header.find((o) => o.fieldName === "description")?.message}
          />
        </div>

        {!isDriving && (
          <Row>
            <Col sm={6}>
              <SelectGroup
                field={"currency"}
                label={t("expenseEditorView.currency")}
                value={currency}
                onChange={(value: string) => onChangeCurrency(value)}
                options={currencyOptions}
                readOnly={readOnly}
              />
            </Col>
          </Row>
        )}

        {isDriving && (
          <Row>
            <Col sm={4}>
              <NumberFieldGroup
                field="units"
                label={t("expenseEditorView.numberOfKm")}
                value={units}
                onChange={(valueNumber: number | null, valueString: string) => recalculate({ units: valueNumber || 1 })}
                readOnly={readOnly}
              />
            </Col>
            <Col sm={4}>
              <NumberFieldGroup
                field="unitPriceNok"
                label={t("expenseEditorView.ratePerKm")}
                value={unitPriceNok}
                useHundredths
                allowDecimals
                decimals={2}
                onChange={(valueNumber: number | null, valueString: string) => recalculate({ unitPriceNok: valueNumber || 0 })}
                readOnly={readOnly}
              />
            </Col>

            <Col sm={4}>
              <NumberFieldGroup
                field="totalValueNok"
                label={t("expenseEditorView.totalValueNok")}
                value={totalValueNok}
                useHundredths
                allowDecimals
                readOnly
              />
            </Col>
          </Row>
        )}

        {isDriving && commuterWarning && commuterWarning !== "" && (
          <Row>
            <Col sm={12}>
              <div className="expense-commuter-warning">{commuterWarning}</div>
            </Col>
          </Row>
        )}

        {!isDriving && currency === "NOK" && (
          <Row>
            <Col sm={4}>
              <NumberFieldGroup
                field="unitPriceNok"
                label={`${t("expenseEditorView.amount")} ${currency}`}
                value={unitPriceNok}
                useHundredths
                allowDecimals
                decimals={2}
                onChange={(valueNumber: number | null, valueString: string) => recalculate({ unitPriceNok: valueNumber || 0 })}
                readOnly={readOnly}
              />
            </Col>

            <Col sm={4}>
              <SelectGroup
                field="vatPercent"
                label={t("expenseEditorView.vatRate")}
                value={vatPercent}
                onChange={(value: number) => recalculate({ vatPercent: value || 0 })}
                options={availableVatOptions}
                readOnly={readOnly}
              />
            </Col>

            <Col sm={4} hidden={policyTotalVatNok && policyTotalVatNok.hide}>
              <NumberFieldGroup
                field="totalVatNok"
                label={t("expenseEditorView.sumVat")}
                value={totalVatNok}
                useHundredths
                allowDecimals
                decimals={2}
                onChange={(valueNumber: number | null, valueString: string) => recalculate({ totalVatNok: valueNumber || 0 })}
                readOnly={readOnly || (policyTotalVatNok && policyTotalVatNok.readOnly)}
                required={policyTotalVatNok && policyTotalVatNok.required}
              />
            </Col>
          </Row>
        )}

        {!isDriving && currency !== "NOK" && (
          <Row>
            <Col sm={4}>
              <NumberFieldGroup
                field="unitPrice"
                label={`${t("expenseEditorView.amount")} ${currency}`}
                value={unitPrice}
                useHundredths
                allowDecimals
                decimals={2}
                onChange={(valueNumber: number | null, valueString: string) => recalculate({ unitPrice: valueNumber || 0 })}
                readOnly={readOnly}
              />
            </Col>
            <Col sm={4}>
              <NumberFieldGroup
                field="exchangeRate"
                label={t("expenseEditorView.exchangeRate")}
                value={exchangeRate}
                allowDecimals
                decimals={4}
                onChange={(valueNumber: number | null, valueString: string) => recalculate({ exchangeRate: valueNumber || 1 })}
                readOnly={readOnly || Boolean(lockedExchangeRate)}
                addonRight={
                  <Button onClick={() => toggleExchangeRatePreference()} title={lockedExchangeRate ? t("unlockExchangeRate") : t("lockExchangeRate")}>
                    <Icon icon={lockedExchangeRate ? "lockOff" : "lock"} />
                  </Button>
                }
              />
            </Col>

            <Col sm={4}>
              <NumberFieldGroup
                field="unitPriceNok"
                label={t("expenseEditorView.sumNok")}
                value={unitPriceNok}
                useHundredths
                allowDecimals
                decimals={2}
                onChange={(valueNumber: number | null, valueString: string) => recalculate({ unitPriceNok: valueNumber || 0 })}
                readOnly={readOnly}
              />
            </Col>
          </Row>
        )}
        {!isDriving && (
          <div className={policyReceipt && policyReceipt.hide ? "hidden" : ""}>
            <CheckboxInput
              field="receipt"
              value={receipt}
              label={t("expenseEditorView.receipt")}
              onChange={(value: boolean) => recalculate({ receipt: value })}
              readOnly={readOnly || (policyReceipt && policyReceipt.readOnly)}
            />
          </div>
        )}

        <div className={policyRefund && policyRefund.hide ? "hidden" : ""}>
          <CheckboxInput
            field="refund"
            value={refund}
            label={t("expenseEditorView.refund")}
            onChange={(value: boolean) => recalculate({ refund: value })}
            readOnly={readOnly || (policyRefund && policyRefund.readOnly)}
          />
        </div>

        <CustomFields
          expenseUuid={expense.uuid}
          customFields={expenseCustomFields}
          customValues={expense.expenseCustomValues.filter((o) => !o.deleted)}
          readOnly={readOnly}
          onChangeCustomValues={onChangeCustomValues}
          key={`${expense.uuid}_${expenseType.id}`}
          validationErrors={validationErrors && validationErrors.expenseCustomFields}
          // When key changes, the component reconstructs which resets the state values.
          // We want this to happen when expense type changes.
          // This negates the need for componentWillReciveProps
          // See https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#recommendation-fully-controlled-component
        />

        {/* {relatedExpenses && relatedExpenses.length > 0 && (
          <div>
            <hr />
            <h5>{t("relatedExpenses")}:</h5>
            {relatedExpenses.map((o) => (
              <div>
                <Button bsStyle="link" key={o.uuid} onClick={() => editExpense(o)}>
                  <span>{o.description || "(No description)"}</span>
                </Button>
              </div>
            ))}
          </div>
        )} */}
      </Col>

      <Col sm={6} className="expense-attachments">
        <Attachments
          attachments={attachments}
          parentType="expense"
          parent={expense}
          readOnly={readOnly}
          onChangeAttachments={onChangeAttachments}
          vertical
          showHeader
          headerText={t("reportDetailsView.attachmentsForExpense")}
          enableDrawer={enableAttachmentsDrawer}
        />

        {expense.mileageTrackUuid && !mileageUuidToPreview && (
          <div className="expense-show-route-on-map">
            <Button
              bsSize="xs"
              bsStyle="link"
              onClick={() => setMileageUuidToPreview(expense.mileageTrackUuid)}
              title={capitalize(t("viewRouteOnMap"))}
            >
              <Icon icon="mapSearch" />
              {isDriving ? t("expenseCameFromTrack") : t("tollPassingCameFromTrack")}
            </Button>
          </div>
        )}

        {showRouteEstimatorLink && drivingFromAddress && drivingToAddress && (
          <div className="expense-show-route-on-map">
            <Button
              bsSize="xs"
              bsStyle="primary"
              onClick={() => setRouteEstimatorQuery({ from: drivingFromAddress, to: drivingToAddress })}
              title={capitalize(t("estimateRoute"))}
            >
              {t("estimateRoute")}
            </Button>
          </div>
        )}

        {mileageUuidToPreview && mileageQuery.data && <MileageMap mileage={mileageQuery.data} />}
        {showRouteEstimatorLink && routeEstimatorQuery && (
          <div className="mileage-map">
            <Map
              routeQuery={routeEstimatorQuery}
              tollStations={tollstationsByDirectionsRouteQuery.data || []}
              estimatorCallback={(km: number, route?: any) => {
                // Update expense with the driving distance returned from route estimator
                recalculate({ units: km });

                if (route && route.overview_path && route.overview_path.length > 0) {
                  // Create a dummy mileage with metadata from the route, this will be used as a base to populate any toll expenses we create
                  const personId = userConfigurationQuery.data ? userConfigurationQuery.data.configuration.person.id : 0;
                  const dummySegment: MileageTrackSegment = {
                    starts: starts,
                    stops: ends,
                    addressFrom: "",
                    addressTo: "",
                    totalMeters: km * 1000,
                    coords: route.overview_path.map((p: any) => {
                      return { lat: p.lat().toString(), lon: p.lng().toString() };
                    })
                  };
                  const dummyTrack = {
                    trackSegments: [dummySegment]
                  };
                  const dummyMileage = mileageConstructor({ personId, track: dummyTrack });
                  setMileageToEstimateTollstations(dummyMileage);

                  // Save the raw route, this will be used to actually find toll stations
                  setRouteToEstimateTollstations(route);
                }
              }}
            />

            {routeToEstimateTollstations &&
              !expense.groupUuid && ( // If the expense has a groupUuid, we probably already created toll expenses for it
                <div className="expense-extra-tollstations-list">
                  {tollstationsByDirectionsRouteQuery.status === "loading" && (
                    <div>
                      <Spinner></Spinner> {t("checkingTollstations")}
                    </div>
                  )}
                  {tollstationsByDirectionsRouteQuery.status === "error" && <div>{t("checkingTollstationsFailed")}</div>}
                  {tollstationsByDirectionsRouteQuery.status === "success" && tollStationsForRoute.length === 0 && (
                    <div>{t("noTollStationsFound")}</div>
                  )}
                  {tollstationsByDirectionsRouteQuery.status === "success" && tollStationsForRoute.length > 0 && (
                    <div>
                      <div>{t("foundTollStationsCheckToCreate")}</div>
                      {tollStationsForRoute.map((station, stationIndex) => {
                        const stationIsChecked = tollStationsForRouteSelected[stationIndex] !== null;
                        return (
                          <CheckboxInput
                            field={`mileageExpenseStation${stationIndex}`}
                            key={`mileageExpenseStation${stationIndex}`}
                            value={stationIsChecked}
                            label={`${capitalize(t("mileageLogs.tollStationPassing"))}: kr ${station.chargeSmallCar} (${station.name})`}
                            onChange={(checked: boolean) => {
                              if (checked) {
                                const newSelected = tollStationsForRouteSelected.map((st, idx) => (idx === stationIndex ? station : st));
                                setTollStationsForRouteSelected(newSelected);
                                changeTollStations(newSelected);
                              } else {
                                const newSelected = tollStationsForRouteSelected.map((st, idx) => (idx === stationIndex ? null : st));
                                setTollStationsForRouteSelected(newSelected);
                                changeTollStations(newSelected);
                              }
                            }}
                          />
                        );
                      })}
                    </div>
                  )}
                </div>
              )}
            <br />
          </div>
        )}
        {/*
        // TODO: need to find a better way of presenting these. Also, this only shows related standalone expenses. Need to consider what happens when on a report.
        {relatedExpenses && relatedExpenses.length > 0 && (
          <div>
            <hr />
            <h5>{t("relatedExpenses")}:</h5>
            {relatedExpenses.map((o) => (
              <div>
                <Button bsStyle="link" key={o.uuid} onClick={() => editExpense(o)}>
                  <span>{o.description || "(No description)"}</span>
                </Button>
              </div>
            ))}
          </div>
        )} */}
      </Col>
    </Row>
  );
};

export default ExpenseEditor;
