import validator from "validator";
import isEmpty from "lodash/isEmpty";
import i18n from "../i18n/i18nConfig";
import { UserProfileToBackend, ValidateProfileResult } from "../types";

// Validates user profile details
export const validateProfile = (data: UserProfileToBackend): ValidateProfileResult => {
  const errors: { email?: string; email2?: string; password?: string; password2?: string } = {};

  if (validator.isEmpty(data.email)) {
    errors.email = i18n.t("requiredField");
  } else if (!validator.isEmail(data.email)) {
    errors.email = i18n.t("invalidEmail");
  }

  if (data.email !== data.email2) {
    errors.email2 = i18n.t("emailsNotEqual");
  }

  if (!validator.isEmpty(data.password) && !validator.isLength(data.password, { min: 4 })) {
    errors.password = i18n.t("tooShortPassword");
  }

  if (data.password !== data.password2) {
    errors.password2 = i18n.t("passwordsNotEqual");
  }

  return {
    errors,
    isValid: isEmpty(errors)
  };
};
