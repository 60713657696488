import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Panel, DropdownButton, MenuItem, Modal } from "react-bootstrap";
import Icon from "../common/Icon";
import DeleteModal from "../common/DeleteModal";
import { capitalize } from "../../shared/utils/helpers";
import { attachmentsByEntities } from "../../shared/utils/inboxUtils";
import { saveInbox, useReports, useExpenses } from "../../shared/queries/queries";
import InboxListItem from "./InboxListItem";
import AttachablesDrawer from "../common/AttachablesDrawer";
import Empty from "../common/Empty";
import AutoListLoader from "../common/AutoListLoader";
import { PoseGroup } from "react-pose";
import AttachablesDrawerPoseWrapper from "../attachment/AttachablesDrawerPoseWrapper";
import "../../styles/lists.css";
import CheckboxInput from "../common/CheckboxInput";
import Spinner from "../common/Spinner";
import { Inbox, InboxAttachment } from "../../shared/types";
import moment from "moment";
import { showToast } from "../../utils/toastWrapper";
import { analyzeBlob } from "../../shared/api/receiptAnalysis";
import { useHistory } from "react-router-dom";
import { useStore } from "../../shared/store/store";
import { setHideUsedInboxesDefault } from "../../utils/webclientStore";

// A list of inbox items
interface InboxlistProps {
  inboxes: Inbox[];
  orderBy?: "changed" | "subject" | "source";
  orderDesc?: boolean;
  searchString?: string;
  itemsPerPage?: number;
  title?: string;
  description?: string;
  hideTargetUuidsInDrawerMode?: string[];
  renderMode: "drawer" | "table" | "cards";
  onDrawerSelectionInbox?: (inbox: Inbox) => void;
  onDrawerSelectionInboxAttachment?: (inbox: Inbox, inboxAttachment: InboxAttachment) => void;
  showHeader?: boolean;
  showRefreshButton?: boolean;
  refreshInboxes?: () => void;
  isLoading?: boolean;
  isFetching?: boolean;
  errorMessage?: string;
  alwaysHideUsedInboxes?: boolean;
}

const InboxList = ({
  inboxes,
  orderBy: orderByDefault = "changed",
  orderDesc: orderDescDefault = true,
  searchString: searchStringDefault = "",
  itemsPerPage = 20,
  title = "",
  description = "",
  hideTargetUuidsInDrawerMode = [],
  renderMode,
  onDrawerSelectionInbox,
  onDrawerSelectionInboxAttachment,
  showHeader,
  showRefreshButton,
  refreshInboxes,
  isLoading,
  isFetching,
  errorMessage,
  alwaysHideUsedInboxes
}: InboxlistProps) => {
  const [t] = useTranslation();

  const hideUsedInboxesDefault: boolean = useStore((state) => state.clientSpecific.hideUsedInboxesDefault);

  const history = useHistory();
  const reportsQuery = useReports();
  const expensesQuery = useExpenses();

  const [orderBy, setOrderBy] = useState(orderByDefault);
  const [orderDesc, setOrderDesc] = useState(orderDescDefault);
  const [showItems, setShowItems] = useState(itemsPerPage);
  const [searchString, setSearchString] = useState(searchStringDefault);
  const [searching, setSearching] = useState(false);
  // The next two indicate if a drawer should be open, and for which parent
  const [attachablesInbox, setAttachablesInbox] = useState<Inbox | null>(null);
  const [attachablesInboxAttachment, setAttachablesInboxAttachment] = useState<InboxAttachment | null>(null);
  const [deletingInbox, setDeletingInbox] = useState<Inbox | null>(null);
  const [hideUsedInboxes, setHideUsedInboxes] = useState(hideUsedInboxesDefault);
  // An object with the inbox or inboxAttachment UUID as key, and an array of report/expense UUIDs as the value
  const [attachmentsByEntityUuid, setAttachmentsByEntityUuid] = useState<{ [uuid: string]: string[] }>({});
  const [analyzingInboxAttachmentUuid, setAnalyzingInboxAttachmentUuid] = useState<string>("");

  const analyzingInboxAttachmentUuidRef = useRef<string>(""); // Duplicate of state to avoid stale reference in callback closure, needed to make cancelling work

  // When reportsquery and expensesquery has been populated (or updated), generate the attachmentsByEntityUuid object
  // This is used by inbox list items to indicate how many times they have been attached to something, and to list already-attached entities available for detaching
  useEffect(() => {
    if (reportsQuery.data && expensesQuery.data) {
      const ret = attachmentsByEntities(reportsQuery.data.reports, expensesQuery.data.expenses);
      setAttachmentsByEntityUuid(ret);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportsQuery.data, expensesQuery.data]);

  // The list has a local search function, but also accepts a search string from parent
  // Update local search string with anything passed down by parent that is not null or undefined, blank strings are OK
  useEffect(() => {
    if (searchStringDefault !== null && searchStringDefault !== undefined && searchStringDefault !== searchString) {
      setSearchString(searchStringDefault);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchStringDefault]);

  // Generate the list we will be showing based on current search and order
  const filteredOrderedInboxes = () => {
    let orderedInboxes = [...inboxes];
    if (orderBy === "source") {
      orderedInboxes.sort((a, b) => (`${a.source} ${a.fromAddress}` < `${b.source} ${b.fromAddress}` ? -1 : 1));
    } else if (orderBy === "changed") {
      // sort expenses from it's last changed date.
      orderedInboxes.sort((a, b) => {
        let timeA = a.changed ? a.changed : a.created;
        let timeB = b.changed ? b.changed : b.created;
        return moment(timeA).unix() - moment(timeB).unix();
      });
    } else {
      orderedInboxes.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));
    }
    if (orderDesc) orderedInboxes.reverse();
    if (searchString !== "") {
      orderedInboxes = orderedInboxes.filter((inbox) => inbox.subject.toLowerCase().includes(searchString.toLowerCase()));
    }

    // If "hide used inboxes" is checked, or the parent requires this to always happen (typically homepage/cards mode), filter items that are already attached to something
    if (hideUsedInboxes || alwaysHideUsedInboxes) {
      // Abuse the attachments lookup array to get the UUIDs or all inbox/inboxAttachment entities that have been used for at least one attachment
      const attachmentSourceUuids = Object.keys(attachmentsByEntityUuid);

      // If an inbox item has one of the following "interpretedAs" values, also hide it from the homepage
      // This comes from a backend enum ("CommandResultInterpretedAs")
      // These values indicate the inbox item subject was used to perform a command succesfully (aka the item is no longer a "todo" item)
      const hideInterpretedAsValues = [1, 2, 10, 11, 12, 20];

      // Apply the filter
      orderedInboxes = orderedInboxes.filter(
        (inbox) =>
          !attachmentSourceUuids.includes(inbox.uuid) &&
          !inbox.inboxAttachments.some((inboxAttachment) => attachmentSourceUuids.includes(inboxAttachment.uuid)) &&
          (!inbox.interpretedAs || !hideInterpretedAsValues.includes(inbox.interpretedAs))
      );
    }

    return orderedInboxes;
  };

  const orderedInboxes = filteredOrderedInboxes();

  // Supports: changed, subject, source
  const setOrder = (orderKey: "changed" | "subject" | "source") => {
    // Sort descending by default for changed
    let orderDescNew = true;
    // Sort ascending by default for the rest
    if (["subject", "source"].includes(orderKey)) orderDescNew = false;
    // If we're reordering by the same key as last time, just invert the order
    if (orderKey === orderBy) orderDescNew = !orderDesc;
    setOrderBy(orderKey);
    setOrderDesc(orderDescNew);
  };

  const setFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchStringNew = event.target.value;
    setSearchString(searchStringNew);
  };

  const deleteInbox = (inbox: Inbox) => {
    setDeletingInbox(inbox);
  };

  const confirmDeleteInbox = async () => {
    if (!deletingInbox) return; // Prevent second dispatch if user double clicks
    const now = moment().toISOString();
    const deletedInbox = { ...deletingInbox, deleted: now, dirty: now };
    saveInbox(deletedInbox)
      .then(() => {
        setDeletingInbox(null);
      })
      .catch((err: any) => {
        setDeletingInbox(null);
        showToast({ type: "error", title: t("notifications.inboxDeleteFailed"), text: err?.backendMessage || "" });
      });
  };

  const cancelDeleteInbox = () => {
    setDeletingInbox(null);
  };

  const showMore = () => {
    setShowItems((prev) => prev + itemsPerPage);
  };

  const showAttachables = (inbox: Inbox, inboxAttachment: InboxAttachment | null) => {
    const currentInbox = attachablesInbox;
    const currentInboxAttachment = attachablesInboxAttachment;
    // If the same item is submitted for expansion while already opened, the user probably meant to close it
    let isAlreadyOpen = false;
    if (inboxAttachment && inboxAttachment === currentInboxAttachment) isAlreadyOpen = true;
    if (!inboxAttachment && !currentInboxAttachment && inbox && inbox === currentInbox) isAlreadyOpen = true;

    setAttachablesInbox(isAlreadyOpen ? null : inbox);
    setAttachablesInboxAttachment(isAlreadyOpen || !inboxAttachment ? null : inboxAttachment);
  };

  const closeAttachables = () => {
    setAttachablesInbox(null);
    setAttachablesInboxAttachment(null);
  };

  const toggleHideUsedInboxes = () => {
    setHideUsedInboxesDefault(!hideUsedInboxes);
    setHideUsedInboxes((prev) => !prev);
  };

  const analyzeInboxAttachment = (inboxAttachment: InboxAttachment) => {
    if (inboxAttachment.mimeType.startsWith("image/") || inboxAttachment.mimeType.startsWith("application/pdf")) {
      // Use the result to stub a standalone expense
      setAnalyzingInboxAttachmentUuid(inboxAttachment.uuid);
      analyzingInboxAttachmentUuidRef.current = inboxAttachment.uuid;
      analyzeBlob(inboxAttachment.blobUuid).then((analysis) => {
        // Check that we haven't cancelled
        if (analyzingInboxAttachmentUuidRef.current === inboxAttachment.uuid) {
          setAnalyzingInboxAttachmentUuid("");
          analyzingInboxAttachmentUuidRef.current = "";
          history.push("/expense/new?from=expenses", {
            fromOcr: {
              analysis,
              inboxAttachment
            }
          });
        }
      });
    }
  };

  const cancelAnalyzeReceipt = () => {
    setAnalyzingInboxAttachmentUuid("");
    analyzingInboxAttachmentUuidRef.current = "";
  };

  const orderTitle = `${t("ordering.by")} ${t(`ordering.${orderBy}`)} ${orderDesc ? t("ordering.descending") : ""}`;

  const header = (
    <Panel.Heading>
      <div className="widgets">
        {searching ? (
          <Button
            className="active-widget"
            onClick={() => {
              setSearching(false);
              setSearchString("");
            }}
          >
            <Icon icon="close" title={t("clearSearch")} />
          </Button>
        ) : (
          <Button onClick={() => setSearching(true)}>
            <Icon icon="search" title={capitalize(t("searchInboxes"))} />
          </Button>
        )}
        <DropdownButton
          title={<Icon icon="sort" title={`${capitalize(t("ordering.ordering"))}: ${orderTitle}`} />}
          id="bg-nested-dropdown"
          noCaret
          pullRight
          onSelect={(eventKey) => setOrder(eventKey)}
        >
          <MenuItem
            eventKey="changed"
            className={orderBy === "changed" ? "active-widget list-ordering-button " + (orderDesc ? "order-desc" : "order-asc") : ""}
          >
            {t("ordering.created")}
          </MenuItem>
          <MenuItem
            eventKey="subject"
            className={orderBy === "subject" ? "active-widget list-ordering-button " + (orderDesc ? "order-desc" : "order-asc") : ""}
          >
            {t("ordering.description")}
          </MenuItem>
          <MenuItem
            eventKey="source"
            className={orderBy === "source" ? "active-widget list-ordering-button " + (orderDesc ? "order-desc" : "order-asc") : ""}
          >
            {t("ordering.source")}
          </MenuItem>
        </DropdownButton>
        {renderMode === "table" && (
          <div className="inbox-list-header-checkbox-wrapper">
            <CheckboxInput
              field="hideUsedInboxes"
              value={hideUsedInboxes}
              onChange={() => toggleHideUsedInboxes()}
              label={t("hideUsedInboxes")}
              title={t("hideUsedInboxesDescription")}
            ></CheckboxInput>
          </div>
        )}
      </div>

      {searching ? (
        <div className="search-wrapper">
          <Icon icon="search" />
          <input
            autoFocus // eslint-disable-line
            type="text"
            className="search-box"
            placeholder={capitalize(t("searchInboxes"))}
            onChange={setFilter}
          />
        </div>
      ) : (
        <div className="info">
          <div className="title">
            {title}
            <span className="item-count">{` (${orderedInboxes.length.toString()})`}</span>
            {showRefreshButton && (
              <Button
                bsSize="xs"
                onClick={() => refreshInboxes && refreshInboxes()}
                className={`refresh ${isFetching ? "spin spin-fast" : ""}`}
                disabled={isFetching}
              >
                <Icon icon="refresh" />
              </Button>
            )}
          </div>
          <div className="description">{description}</div>
        </div>
      )}

      <div className="clearfix" />
    </Panel.Heading>
  );

  const columnNames = (
    <React.Fragment>
      <div className="table-header item-checkbox">&nbsp;</div>
      <div className="table-header item-dates">
        <div
          className={`table-header-clickable ${orderBy === "changed" ? (orderDesc ? "order-desc" : "order-asc") : ""}`}
          onClick={() => setOrder("changed")}
        >
          {capitalize(t("tableHeaders.time"))}
        </div>
      </div>
      <div className="table-header item-description">
        <div
          className={`table-header-clickable ${orderBy === "subject" ? (orderDesc ? "order-desc" : "order-asc") : ""}`}
          onClick={() => setOrder("subject")}
        >
          {capitalize(t("tableHeaders.description"))}
        </div>
      </div>
      <div className="table-header item-thumbnails">&nbsp;</div>
      <div className="table-header item-source">
        <div
          className={`table-header-clickable ${orderBy === "source" ? (orderDesc ? "order-desc" : "order-asc") : ""}`}
          onClick={() => setOrder("source")}
        >
          {capitalize(t("tableHeaders.source"))}
        </div>
      </div>

      <div className="table-header item-actions">&nbsp;</div>
    </React.Fragment>
  );

  let listClass = "list table";
  if (renderMode === "cards") listClass = "list cards";
  if (renderMode === "drawer") listClass = "list drawer";

  if (isLoading)
    return (
      <div className="inbox-list">
        <Panel>
          {showHeader && header}
          <div className={listClass}>
            <Spinner size="75px" margin="1em" />
          </div>
        </Panel>
      </div>
    );

  return (
    <div className="inbox-list">
      <Panel>
        {showHeader && header}
        {errorMessage && <div className="list-load-error">{errorMessage}</div>}
        <div className={listClass}>
          {renderMode === "table" && orderedInboxes.length > 0 && columnNames}
          {orderedInboxes.slice(0, showItems).map((inbox, index) => {
            return (
              <React.Fragment key={inbox.uuid}>
                <InboxListItem
                  inboxElement={inbox}
                  alternate={renderMode === "table" && index % 2 === 0}
                  renderMode={renderMode}
                  onDeleteInbox={deleteInbox}
                  onShowAttachables={showAttachables}
                  hideTargetUuidsInDrawerMode={hideTargetUuidsInDrawerMode}
                  onDrawerSelectionInbox={onDrawerSelectionInbox}
                  onDrawerSelectionInboxAttachment={onDrawerSelectionInboxAttachment}
                  onAnalyzeInboxAttachment={analyzeInboxAttachment}
                  attachmentsByEntityUuid={attachmentsByEntityUuid}
                />

                <PoseGroup>
                  {!!attachablesInbox && attachablesInbox.uuid === inbox.uuid && (
                    <AttachablesDrawerPoseWrapper key="attachables-drawer-pose-wrapper" className="attachables-drawer-pose-wrapper">
                      <AttachablesDrawer
                        parentType={attachablesInboxAttachment ? "inboxAttachment" : "inbox"}
                        parent={attachablesInboxAttachment ? attachablesInboxAttachment : attachablesInbox}
                        parentInboxIfParentIsInboxAttachment={inbox}
                        showTabs={["report", "expense"]}
                        onClose={closeAttachables}
                        hideTargetUuidsInDrawerMode={
                          attachablesInboxAttachment
                            ? attachmentsByEntityUuid[attachablesInboxAttachment.uuid]
                            : attachmentsByEntityUuid[attachablesInbox.uuid]
                        }
                      />
                    </AttachablesDrawerPoseWrapper>
                  )}
                </PoseGroup>
              </React.Fragment>
            );
          })}
        </div>

        {orderedInboxes.length === 0 && <Empty text={t("noInboxesFound")} />}
      </Panel>

      <AutoListLoader active={showItems < orderedInboxes.length} loadMoreFunction={showMore} />

      <DeleteModal show={!!deletingInbox} cancel={cancelDeleteInbox} confirm={confirmDeleteInbox} />

      <Modal show={analyzingInboxAttachmentUuid !== ""} onHide={cancelAnalyzeReceipt}>
        <Modal.Header>
          <Modal.Title>{t("analyzingReceipt")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Spinner size="100px" />
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="danger" onClick={cancelAnalyzeReceipt}>
            {t("cancel")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default InboxList;
