import React, { FunctionComponent, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { changeLanguage } from "../shared/actions/languageActions";
import { supportedLanguageCodes } from "../shared/utils/constants";

// A wrapper to look for querystring params to deal with and strip off
// Initially written to handle lang (language) param passed in from admin pages
const RouteCleaner: FunctionComponent = ({ children }) => {
  const history = useHistory();
  useEffect(() => {
    // code to run on component mount
    if (history.location && history.location.search) {
      const q = new URLSearchParams(history.location.search);
      const lang = q.get("lang");
      if (lang) {
        const validLang = supportedLanguageCodes.find((o) => o === lang) || supportedLanguageCodes[0];
        changeLanguage(validLang).then(() => {
          q.delete("lang");
          const qClean = q.toString();
          const url = history.location.pathname + (qClean !== "" ? "?" : "") + qClean;
          history.push(url);
        });
      }
    }
  }, [history]);

  return <React.Fragment>{children}</React.Fragment>;
};

export default RouteCleaner;
