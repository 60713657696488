import { useTranslation } from "react-i18next";
import Steps from "rc-steps";
import "rc-steps/assets/index.css";
import i18n from "../../shared/i18n/i18nConfig";
import { capitalize } from "../../shared/utils/helpers";
import "../../styles/rc-steps.css";
import { ClassicApprovalAttempt } from "../../shared/types";
import { Status } from "rc-steps/lib/interface";

interface ApprovalAttemptTimelineProps {
  approvalAttempt: ClassicApprovalAttempt;
}
const ApprovalAttemptTimeline = ({ approvalAttempt }: ApprovalAttemptTimelineProps) => {
  const [t] = useTranslation();
  // Default to setting the last step as current, for completed or aborted chains
  let currentStep = approvalAttempt.steps.length;
  // If someone is nextup, they are the current step
  const nextupIndex = approvalAttempt.steps.findIndex((o) => o.approvalStatus === "nextup");
  if (nextupIndex > -1) currentStep = nextupIndex;

  const isCancelled = approvalAttempt.steps.find((o) => o.approvalStatus === "cancelled" || o.approvalStatus === "rejected");
  return (
    <Steps labelPlacement="vertical" size="small" current={currentStep}>
      {approvalAttempt.steps.map((o) => {
        const timestamp = new Date(o.changed).toLocaleDateString(i18n.language, {
          day: "2-digit",
          month: "short"
        });

        const title = `${o.personFirstName} ${o.personLastName}`;
        let desc = "";
        if (o.approvalStatus === "approved") desc = `${capitalize(t("approved"))} ${timestamp}`;
        if (o.approvalStatus === "rejected") desc = `${capitalize(t("disapproved"))} ${timestamp}`;
        if (o.approvalStatus === "nextup") desc = capitalize(t("awaitingDecision"));

        if (o.comment) desc = `${desc}: «${o.comment}»`;

        let status: Status = "wait";
        if (o.approvalStatus === "cancelled" || o.approvalStatus === "rejected") status = "error";
        if (o.approvalStatus === "approved") status = "finish";
        if (o.approvalStatus === "nextup") status = "process";

        return <Steps.Step key={o.personId} title={title} description={desc} status={status} />;
      })}

      <Steps.Step
        title={isCancelled ? `${capitalize(t("disapproved"))} :-(` : `${capitalize(t("approved"))} :-)`}
        status={isCancelled ? "error" : "wait"}
      />
    </Steps>
  );
};

export default ApprovalAttemptTimeline;
