import React, { useEffect, useState, useMemo } from "react";
import moment from "moment";
import { Panel, DropdownButton, MenuItem, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Icon from "../common/Icon";
import DeleteModal from "../common/DeleteModal";
import { capitalize } from "../../shared/utils/helpers";
import ExpenseListItem from "./ExpenseListItem";
import AttachablesDrawer from "../common/AttachablesDrawer";
import { PoseGroup } from "react-pose";
import AttachablesDrawerPoseWrapper from "../attachment/AttachablesDrawerPoseWrapper";
import Empty from "../common/Empty";
import AutoListLoader from "../common/AutoListLoader";
import { getExpenseTypeDescription } from "../../shared/utils/expenseUtils";
import { useUserConfiguration, refreshExpenses } from "../../shared/queries/queries";
import Spinner from "../common/Spinner";
import "../../styles/lists.css";
import { Expense, ProductConfiguration } from "../../shared/types";
import { ValidateExpenseErrors } from "../../shared/types";
import CheckboxInput from "../common/CheckboxInput";

type ExpenseOrderBy = "changed" | "created" | "starts" | "description" | "type" | "sum";
interface ExpenseListProps {
  expenses: Expense[];
  orderBy?: ExpenseOrderBy;
  orderDesc?: boolean;
  searchString?: string;
  itemsPerPage?: number;
  title?: string;
  description?: string;
  hideTargetUuidsInDrawerMode?: string[];
  renderMode: "drawer" | "table";
  onDrawerSelection?: (expense: Expense) => void;
  showHeader?: boolean;
  showRefreshButton?: boolean;
  isLoading?: boolean;
  isFetching?: boolean;
  errorMessage?: string;
  readOnly?: boolean;
  persisting?: boolean; // Parent entity is currently being persisted, disable changes
  validationErrors?: ValidateExpenseErrors[];
  selectingMultiple?: boolean;
  onCopyExpense?: (expense: Expense) => void;
  onEditExpense?: (expense: Expense) => void;
  onDeleteExpense?: (expense: Expense) => Promise<void>;
  onChangeMultiSelection?: (selectedExpenseUuids: string[]) => void;
  externalProductConfig?: ProductConfiguration;
  onMoveToStandaloneExpenses?: (expense: Expense) => void;
  onExpenseMovedToOtherReport?: (expense: Expense) => void;
  cloningUuid?: string;
}

const ExpenseList = ({
  expenses,
  orderBy: orderByDefault = "changed",
  orderDesc: orderDescDefault = true,
  searchString: searchStringDefault = "",
  itemsPerPage = 20,
  title = "",
  description = "",
  hideTargetUuidsInDrawerMode = [], // When in drawer mode, don't render any inbox/inboxattachments with these uuids
  renderMode,
  onDrawerSelection,
  showHeader,
  showRefreshButton,
  isLoading,
  isFetching,
  errorMessage,
  readOnly,
  persisting,
  validationErrors,
  selectingMultiple,
  onCopyExpense,
  onEditExpense,
  onDeleteExpense,
  onChangeMultiSelection,
  externalProductConfig,
  onMoveToStandaloneExpenses,
  onExpenseMovedToOtherReport,
  cloningUuid
}: ExpenseListProps) => {
  const [t] = useTranslation();

  const [orderBy, setOrderBy] = useState<ExpenseOrderBy>(orderByDefault);
  const [orderDesc, setOrderDesc] = useState(orderDescDefault);
  const [showItems, setShowItems] = useState(itemsPerPage);
  const [searchString, setSearchString] = useState(searchStringDefault);
  const [searching, setSearching] = useState(false);
  const [attachablesParentUuid, setAttachablesParentUuid] = useState("");
  const [deletingExpense, setDeletingExpense] = useState<Expense | null>(null);
  const [uncheckedExpenseUuids, setUncheckedExpenseUuids] = useState<string[]>([]);
  const [filteredOrderedExpenses, setFilteredOrderedExpenses] = useState<Expense[]>([]);
  const [selectAllOn, setSelectAllOn] = useState<boolean>(true);

  const userConfigurationQuery = useUserConfiguration();

  // The list has a local search function, but also accepts a search string from parent
  // Update local search string with anything passed down by parent that is not null or undefined, blank strings are OK
  useEffect(() => {
    if (searchStringDefault !== null && searchStringDefault !== undefined && searchStringDefault !== searchString) {
      setSearchString(searchStringDefault);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchStringDefault]);

  // Use externally provided list if there is one. If we're viewing someone else's report for approval, we need to show their expensetypes
  const allExpenseTypes = useMemo(
    () => (externalProductConfig ? externalProductConfig.expenseTypes : userConfigurationQuery.data?.configuration?.product?.expenseTypes || []),
    [externalProductConfig, userConfigurationQuery.data]
  );

  // Order change handler
  const setOrder = (orderKey: ExpenseOrderBy) => {
    // Sort descending by default for changed, created, starts
    let orderDescNew = true;
    // Sort ascending by default for the rest
    if (["description", "type", "sum"].includes(orderKey)) orderDescNew = false;
    // If we're reordering by the same key as last time, just invert the order
    if (orderKey === orderBy) orderDescNew = !orderDesc;
    setOrderBy(orderKey);
    setOrderDesc(orderDescNew);
  };

  // Search string change handler
  const setFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchStringNew = event.target.value;
    setSearchString(searchStringNew);
  };

  // (Re)build an array of expense UUIDs and their searchable strings concatenated ("expensetypedescription: expense.description"), for later use as a search index
  const searchIndex = useMemo(() => {
    const index = expenses.map((expense) => {
      const expenseTypeDescription = getExpenseTypeDescription({
        availableExpenseTypes: allExpenseTypes,
        expenseTypeId: expense.expenseTypeId
      });
      return {
        uuid: expense.uuid,
        searchable: `${expenseTypeDescription}: ${expense.description}`
      };
    });
    return index;
  }, [allExpenseTypes, expenses]);

  // Update the list of selected expenses. Pass inn the currently visible ones, and the unchecked ones
  const updateMultiSelection = (expenses: Expense[], uncheckedExpenseUuids: string[]) => {
    if (onChangeMultiSelection) {
      const selectedExpenseUuids = expenses.map((o) => o.uuid).filter((o) => !uncheckedExpenseUuids.includes(o));
      onChangeMultiSelection(selectedExpenseUuids);
    }
  };

  // While other lists simply rerender this list on every render, we use state and useEffect here
  // This is to only rerender when the memoized expenses changed, to be able to hit the updateMultiSelection callback without causing an infinite rerender loop
  useEffect(() => {
    let orderedExpenses = [...expenses];
    if (orderBy === "changed") {
      orderedExpenses.sort((a, b) => {
        return moment(a.changed || a.created).unix() - moment(b.changed || b.created).unix();
      });
    } else if (orderBy === "created") {
      orderedExpenses.sort((a, b) => {
        return moment(a.created || a.changed).unix() - moment(b.created || b.changed).unix();
      });
    } else if (orderBy === "type") {
      orderedExpenses.sort((a, b) => {
        const etA = allExpenseTypes.find((et) => et.id === a.expenseTypeId);
        const etB = allExpenseTypes.find((et) => et.id === b.expenseTypeId);
        if (etA && etB) return etA.description < etB.description ? -1 : 1;
        return 1;
      });
    } else if (orderBy === "starts") {
      orderedExpenses.sort((a, b) => {
        return moment(a.starts).unix() - moment(b.starts).unix();
      });
    } else if (orderBy === "sum") {
      orderedExpenses.sort((a, b) => (a.totalValueNok < b.totalValueNok ? -1 : 1));
    } else {
      orderedExpenses.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));
    }

    if (orderDesc) orderedExpenses.reverse();
    if (searchString !== "") {
      // Use searchString to find matching expense UUIDs in the search index
      const matchingUuids = searchIndex.filter((o) => o.searchable.toLowerCase().includes(searchString.toLowerCase())).map((o) => o.uuid);
      // Filter the list against the matching UUIDs
      orderedExpenses = orderedExpenses.filter((expense) => matchingUuids.includes(expense.uuid));
    }

    updateMultiSelection(orderedExpenses, uncheckedExpenseUuids);
    setFilteredOrderedExpenses(orderedExpenses);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expenses, orderBy, orderDesc, searchString]);

  // Toggle the unchecked status for an expense item and report back to parent
  const toggleSelection = (expense: Expense, selected: boolean) => {
    const { uuid } = expense;
    if (!selected && !uncheckedExpenseUuids.includes(uuid)) {
      const uncheckedExpenseUuidsNew = [...uncheckedExpenseUuids, uuid];
      setUncheckedExpenseUuids(uncheckedExpenseUuidsNew);
      updateMultiSelection(filteredOrderedExpenses, uncheckedExpenseUuidsNew);
      if (uncheckedExpenseUuidsNew.length === filteredOrderedExpenses.length && selectAllOn) setSelectAllOn(false);
    }
    if (selected && uncheckedExpenseUuids.includes(uuid)) {
      const uncheckedExpenseUuidsNew = uncheckedExpenseUuids.filter((o) => o !== uuid);
      setUncheckedExpenseUuids(uncheckedExpenseUuidsNew);
      updateMultiSelection(filteredOrderedExpenses, uncheckedExpenseUuidsNew);
      if (uncheckedExpenseUuidsNew.length === 0 && !selectAllOn) setSelectAllOn(true);
    }
  };

  const promptDeleteExpense = (expense: Expense) => {
    setDeletingExpense(expense);
  };

  const confirmDeleteExpense = async () => {
    if (!deletingExpense) return; // Prevent second dispatch if user double clicks
    if (onDeleteExpense) {
      onDeleteExpense(deletingExpense).then(() => {
        setDeletingExpense(null);
      });
    }
  };

  const cancelDeleteExpense = () => {
    setDeletingExpense(null);
  };

  const showMore = () => {
    setShowItems((prev) => prev + itemsPerPage);
  };

  const showAttachables = (expense: Expense) => {
    // If the same item is submitted for expansion while already opened, the user probably meant to close it
    const isAlreadyOpen = expense.uuid && expense.uuid === attachablesParentUuid;
    setAttachablesParentUuid(isAlreadyOpen ? "" : expense.uuid);
  };

  const closeAttachables = () => {
    setAttachablesParentUuid("");
  };

  const orderTitle = `${t("ordering.by")} ${t(`ordering.${orderBy}`)} ${orderDesc ? t("ordering.descending") : ""}`;

  const header = (
    <Panel.Heading>
      <div className="widgets">
        {searching ? (
          <Button
            className="active-widget"
            onClick={() => {
              setSearching(false);
              setSearchString("");
            }}
          >
            <Icon icon="close" title={t("clearSearch")} />
          </Button>
        ) : (
          <Button onClick={() => setSearching(true)}>
            <Icon icon="search" title={capitalize(t("searchExpenses"))} />
          </Button>
        )}
        <DropdownButton
          title={<Icon icon="sort" title={`${capitalize(t("ordering.ordering"))}: ${orderTitle}`} />}
          id="bg-nested-dropdown"
          noCaret
          pullRight
          onSelect={(eventKey) => setOrder(eventKey)}
        >
          <MenuItem
            eventKey="created"
            className={orderBy === "created" ? "active-widget list-ordering-button " + (orderDesc ? "order-desc" : "order-asc") : ""}
          >
            {t("ordering.created")}
          </MenuItem>
          <MenuItem
            eventKey="changed"
            className={orderBy === "changed" ? "active-widget list-ordering-button " + (orderDesc ? "order-desc" : "order-asc") : ""}
          >
            {t("ordering.changed")}
          </MenuItem>
          <MenuItem
            eventKey="description"
            className={orderBy === "description" ? "active-widget list-ordering-button " + (orderDesc ? "order-desc" : "order-asc") : ""}
          >
            {t("ordering.description")}
          </MenuItem>
          <MenuItem
            eventKey="type"
            className={orderBy === "type" ? "active-widget list-ordering-button " + (orderDesc ? "order-desc" : "order-asc") : ""}
          >
            {t("ordering.type")}
          </MenuItem>
          <MenuItem
            eventKey="sum"
            className={orderBy === "sum" ? "active-widget list-ordering-button " + (orderDesc ? "order-desc" : "order-asc") : ""}
          >
            {t("ordering.sum")}
          </MenuItem>
        </DropdownButton>
      </div>

      {searching ? (
        <div className="search-wrapper">
          <Icon icon="search" />
          <input autoFocus type="text" className="search-box" placeholder={capitalize(t("searchExpenses"))} onChange={setFilter} />
        </div>
      ) : (
        <div className="info">
          <div className="title">
            {title}
            <span className="item-count">{` (${expenses.length.toString()})`}</span>
            {showRefreshButton && (
              <Button bsSize="xs" onClick={() => refreshExpenses()} className={`refresh ${isFetching ? "spin spin-fast" : ""}`} disabled={isFetching}>
                <Icon icon="refresh" />
              </Button>
            )}
          </div>
          <div className="description">{description}</div>
        </div>
      )}

      <div className="clearfix" />
    </Panel.Heading>
  );

  const toggleSelectAll = (checked: boolean) => {
    setSelectAllOn(checked);
    const uncheckedUuids = checked ? [] : filteredOrderedExpenses.map((o) => o.uuid);
    setUncheckedExpenseUuids(uncheckedUuids);
    updateMultiSelection(filteredOrderedExpenses, uncheckedUuids);
  };

  const checkbox = !!selectingMultiple ? (
    <CheckboxInput
      value={selectAllOn}
      field={`select_toggle_all`}
      onChange={(checked: boolean) => toggleSelectAll(checked)}
      title={t("toggleSelectAll")}
    />
  ) : null;

  const columnNames = (
    <React.Fragment>
      <div className="table-header item-checkbox">
        {!!selectingMultiple && checkbox}
        &nbsp;
      </div>
      <div className="table-header item-dates">
        <div
          className={`table-header-clickable ${orderBy === "starts" ? (orderDesc ? "order-desc" : "order-asc") : ""}`}
          onClick={() => setOrder("starts")}
        >
          {capitalize(t("tableHeaders.time"))}
        </div>
      </div>
      <div className="table-header item-description">
        <div
          className={`table-header-clickable ${orderBy === "description" ? (orderDesc ? "order-desc" : "order-asc") : ""}`}
          onClick={() => setOrder("description")}
        >
          {capitalize(t("tableHeaders.description"))}
        </div>
      </div>
      <div className="table-header item-type-description">
        <div
          className={`table-header-clickable ${orderBy === "type" ? (orderDesc ? "order-desc" : "order-asc") : ""}`}
          onClick={() => setOrder("type")}
        >
          {capitalize(t("tableHeaders.expenseType"))}
        </div>
      </div>
      <div className="table-header item-icons">&nbsp;</div>
      <div className="table-header item-sum">
        <div
          className={`table-header-clickable ${orderBy === "sum" ? (orderDesc ? "order-desc" : "order-asc") : ""}`}
          onClick={() => setOrder("sum")}
        >
          {capitalize(t("tableHeaders.sum"))}
        </div>
      </div>
      <div className="table-header item-thumbnails">&nbsp;</div>
      <div className="table-header item-actions">&nbsp;</div>
    </React.Fragment>
  );

  const visibleExpenses = filteredOrderedExpenses
    .filter((expense) => renderMode !== "drawer" || !hideTargetUuidsInDrawerMode.includes(expense.uuid))
    .slice(0, selectingMultiple ? 99999 : showItems); // If we're in selection mode, disable paging and show everything to avoid confusion about which expenses are selected

  let listClass = "list table";
  if (renderMode === "drawer") listClass = "list drawer";

  if (isLoading) {
    return (
      <div className="expense-list">
        <Panel>
          {showHeader && header}
          <div className={listClass}>
            <Spinner size="75px" margin="1em" />
          </div>
        </Panel>
      </div>
    );
  }

  return (
    <div className="expense-list">
      <Panel>
        {showHeader && header}
        {errorMessage && <div className="list-load-error">{errorMessage}</div>}
        <div className={listClass}>
          {renderMode === "table" && visibleExpenses.length > 0 && columnNames}
          {visibleExpenses.map((expense, index) => {
            const validationError = validationErrors && validationErrors.find((v) => v.uuid === expense.uuid);

            return (
              <React.Fragment key={expense.uuid}>
                <ExpenseListItem
                  expense={expense}
                  expenseType={allExpenseTypes.find((et) => et.id === expense.expenseTypeId)}
                  readOnly={readOnly}
                  persisting={persisting || !!deletingExpense}
                  renderMode={renderMode}
                  alternate={renderMode === "table" && index % 2 === 0}
                  onDelete={onDeleteExpense && promptDeleteExpense}
                  onCopy={onCopyExpense}
                  onEdit={onEditExpense}
                  onDrawerSelection={readOnly ? undefined : onDrawerSelection}
                  onShowAttachables={showAttachables}
                  selectable={!!selectingMultiple}
                  selected={!uncheckedExpenseUuids.includes(expense.uuid)}
                  onToggleSelection={toggleSelection}
                  readOnlyAttachments={false}
                  validationErrors={!!validationError}
                  onMoveToStandaloneExpenses={onMoveToStandaloneExpenses}
                  cloning={cloningUuid === expense.uuid}
                />

                {renderMode === "table" && (
                  <PoseGroup>
                    {attachablesParentUuid === expense.uuid && (
                      <AttachablesDrawerPoseWrapper key="attachables-drawer-pose-wrapper" className="attachables-drawer-pose-wrapper">
                        <AttachablesDrawer
                          parentType="expense"
                          parent={expense}
                          showTabs={["report", "inbox"]}
                          renderedInsideReportUuid={expense.reportUuid} // If we're already on a report, make sure that report doesn't show up as an option in the drawer
                          onClose={closeAttachables}
                          onExpenseMovedToOtherReport={onExpenseMovedToOtherReport}
                          hideTargetUuidsInDrawerMode={expense.attachments.reduce(
                            (all: string[], attachment) => [...all, attachment.inboxUuid, attachment.inboxAttachmentUuid],
                            []
                          )}
                        />
                      </AttachablesDrawerPoseWrapper>
                    )}
                  </PoseGroup>
                )}
              </React.Fragment>
            );
          })}
        </div>

        {filteredOrderedExpenses.length === 0 && <Empty text={t("noExpensesFound")} />}
      </Panel>
      <AutoListLoader active={showItems < filteredOrderedExpenses.length && !selectingMultiple} loadMoreFunction={showMore} />

      <DeleteModal show={!!deletingExpense} cancel={cancelDeleteExpense} confirm={confirmDeleteExpense} />
    </div>
  );
};

export default ExpenseList;
