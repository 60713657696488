import { Command, CommandResult } from "../types";
import { jsonFetch } from "./api";

/**
 * Executes a command
 */
export const run = async (command: Command): Promise<CommandResult> => {
  const fetchOptions = {
    method: "POST",
    body: JSON.stringify(command)
  };
  return jsonFetch(`e/command/run`, fetchOptions);
};
