import { useState } from "react";
import { Modal, FormGroup, ControlLabel, FormControl, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { getConfiguration, login, logout } from "../../shared/actions/userActions";
import { getApiBaseUrl } from "../../shared/api/api";
import { useStore } from "../../shared/store/store";
import { ValidateLoginErrors } from "../../shared/types";
import { validateLogin } from "../../shared/validation/login";
import "../../styles/login.css";
import { trackEvent } from "../../utils/eventTracker";
import { setAuthenticationExpired } from "../../utils/webclientStore";
import ButtonWithSpinner from "./ButtonWithSpinner";

// A login modal shown if the user needs to re-authenticate, typically due to expired credentials
const ReauthenticateModal = () => {
  const [t] = useTranslation();
  const [errors, setErrors] = useState<ValidateLoginErrors>({});
  const [isLoading, setIsLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  // Check if the current user has expired credentials so we can show the reauthentication modal
  const authenticationExpired = useStore((state) => state.clientSpecific.authenticationExpired);

  if (!authenticationExpired) return null;

  // Force logout and redirect to login page
  const goToLogin = async () => {
    await logout();
    window.location.replace(`${getApiBaseUrl()}/login/logout?redirectUrl=${window.location.origin}/logout`);
  };

  // Validate login form
  const isValid = () => {
    const { errors, isValid } = validateLogin(username, password);
    setErrors(errors);
    return isValid;
  };

  // Attempt to login
  const loginUser = async () => {
    setErrors({});
    if (isValid()) {
      setIsLoading(true);
      try {
        trackEvent("Login", "Reauthenticating user from credentials", username);
        await login(username, password);
        // Prepopulate the userconfig query
        await getConfiguration();
        setAuthenticationExpired(false);
        setIsLoading(false);
      } catch (error: any) {
        setErrors({
          form: t("login.invalidcredentials")
        });
        setIsLoading(false);
      }
    }
  };

  return (
    <Modal
      show={authenticationExpired}
      onHide={() => {}}
      onKeyPress={(event) => {
        if (event && event.key && event.key === "Enter") loginUser();
      }}
    >
      <Modal.Header>
        <Modal.Title>{t("credentialsExpiredTitle")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="reauthenticate-modal">
          <div>{t("credentialsExpiredText")}</div>
          <br />
          <FormGroup>
            <ControlLabel>{t("login.email")}</ControlLabel>
            <FormControl
              onChange={(e: React.FormEvent<FormControl & HTMLInputElement>) => setUsername(e.currentTarget.value)}
              value={username}
              type="email"
              name="username"
              placeholder={t("login.email")}
              disabled={isLoading}
              autoComplete="username"
            />
            {errors.username && <span className="error">{errors.username}</span>}
          </FormGroup>

          <FormGroup>
            <ControlLabel>{t("login.password")}</ControlLabel>
            <FormControl
              onChange={(e: React.FormEvent<FormControl & HTMLInputElement>) => setPassword(e.currentTarget.value)}
              value={password}
              type="password"
              name="password"
              placeholder={t("login.password")}
              disabled={isLoading}
              autoComplete="current-password"
            />
            {errors.password && <span className="error">{errors.password}</span>}
          </FormGroup>
          {errors.form && <div className="error">{errors.form}</div>}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button bsStyle="link" onClick={() => goToLogin()}>
          {t("goToLoginPage")}
        </Button>
        <ButtonWithSpinner showSpinner={isLoading} bsStyle="success" onClick={() => loginUser()} disabled={isLoading}>
          {t("login.login")}
        </ButtonWithSpinner>
      </Modal.Footer>
    </Modal>
  );
};

export default ReauthenticateModal;
