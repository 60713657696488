import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import PageHeader from "../common/PageHeader";
import ExpenseList from "../expense/ExpenseList";
import CreateButtons from "../common/CreateButtons";
import MileageList from "../mileage/MileageList";
import {
  useUserConfiguration,
  useExpenses,
  saveExpense,
  useMileages,
  saveMileage,
  useOfficialRates,
  useCountries,
  useMajorCities,
  refreshMileages,
  saveReport,
  refreshExpenses,
  useSeedExchangeRatesNB
} from "../../shared/queries/queries";
import { getDummyTrack, getDummyTrackWithTollstations } from "../../shared/constructors/mileageConstructor";
import { getExpenseClone } from "../../shared/utils/expenseUtils";
import { showToast } from "../../utils/toastWrapper";
import "../../styles/report.css";
import { Expense } from "../../shared/types";
import { BusEvent, useBus } from "../../utils/useBus";
import ButtonWithSpinner from "../common/ButtonWithSpinner";
import { createReportFromExpenses } from "../../shared/utils/reportUtils";
import Spinner from "../common/Spinner";
import { useStore } from "../../shared/store/store";
import { setRefreshingMileages } from "../../utils/webclientStore";
import moment from "moment";

const MileagesPage = () => {
  const [t] = useTranslation();
  const history = useHistory();

  // Monitor this to trigger a rerender if we switch users
  useStore((state) => state.currentUserId);

  const refreshingMileages: boolean = useStore((state) => state.clientSpecific.refreshingMileages);

  const userConfigurationQuery = useUserConfiguration();
  const expensesQuery = useExpenses();
  const mileagesQuery = useMileages();
  const officialRatesQuery = useOfficialRates();
  const countriesQuery = useCountries();
  const majorCitiesQuery = useMajorCities();
  const seedExchangeRatesNBQuery = useSeedExchangeRatesNB();

  const expenses = useMemo(
    () => (expensesQuery.data ? expensesQuery.data.expenses.filter((o) => !o.deleted && o.expenseTypeId === 18) : []),
    [expensesQuery.data]
  );
  const mileages = useMemo(() => (mileagesQuery.data ? mileagesQuery.data.mileages.filter((o) => !o.deleted) : []), [mileagesQuery.data]);
  const allExpenseUuids = expenses.map((o) => o.uuid);

  const isLoadingExpenses = !expensesQuery.data;
  const isLoadingMileages = !mileagesQuery.data;
  const isFetchingExpenses = expensesQuery.isFetching;
  const isFetchingMileages = mileagesQuery.isFetching || refreshingMileages;

  const [berserkMode, setBerserkMode] = useState(false);

  const [selectedExpenseUuids, setSelectedExpenseUuids] = useState(allExpenseUuids);
  const [selectingMultiple, setSelectingMultiple] = useState(false);

  const [cloningUuid, setCloningUuid] = useState("");
  const [creatingReportFromExpenses, setCreatingReportFromExpenses] = useState(false);

  const busHandler = (event: BusEvent) => {
    if (event === "BERSERK_MODE") setBerserkMode(true);
  };
  useBus(busHandler);

  // Bail out here if we don't have a valid user configuration or all the static data yet
  if (!userConfigurationQuery.data || !officialRatesQuery.data || !countriesQuery.data || !majorCitiesQuery.data || !seedExchangeRatesNBQuery.data) {
    console.log("MileagesPage: No userconfig or static data, bailing out");
    return <Spinner size="75px" margin="1em" />;
  }

  const updateMileages = async () => {
    setRefreshingMileages(true);
    await refreshMileages();
    setRefreshingMileages(false);
  };

  // Show selection of items before creating a mileage report
  const createMileageReportFromDriving = () => {
    setSelectingMultiple(true);
  };

  // Create a report and move all selected expenses onto it
  const confirmCreateMileageReportFromDriving = async () => {
    setCreatingReportFromExpenses(true);

    const userConfiguration = userConfigurationQuery.data.configuration;
    const officialRates = officialRatesQuery.data;
    const countries = countriesQuery.data;
    const majorCities = majorCitiesQuery.data;
    const exchangeRatesNBSeed = seedExchangeRatesNBQuery.data;

    try {
      const expensesToMove = expenses.filter((expense) => selectedExpenseUuids.includes(expense.uuid));
      // If no expenses are found, just return, this should not happen
      if (expensesToMove.length === 0) {
        setCreatingReportFromExpenses(false);
        return;
      }

      // Create the report
      const newReport = await createReportFromExpenses(
        3,
        expensesToMove,
        userConfiguration,
        officialRates,
        countries,
        majorCities,
        exchangeRatesNBSeed
      );

      // Save the report. This will also recursively save all the expenses
      saveReport(newReport)
        .then(() => {
          // Force a refresh of standalone expenses since some expenses will be gone from it now
          refreshExpenses();
          // Redirect to the new report
          history.push(`/report/${newReport.uuid}`);
        })
        .catch((err: any) => {
          showToast({ type: "error", title: t("notifications.reportSaveFailed"), text: err?.backendMessage || "" });
          setCreatingReportFromExpenses(false);
        });
    } catch {
      setCreatingReportFromExpenses(false);
      showToast({ type: "error", title: t("error"), text: t("genericError") });
    }
  };

  // Switch to the editor and create a new expense
  const addExpense = (expenseTypeId: number) => {
    history.push(`/expense/new?expenseType=${expenseTypeId}&from=mileages`);
  };

  // Switch to the editor and edit the expense
  const editExpense = (expense: Expense) => {
    history.push(`/expense/${expense.uuid}?from=mileages`);
  };

  const changeMultiSelection = (expenseUuidList: string[]) => {
    setSelectedExpenseUuids(expenseUuidList);
  };

  // Copy/clone an expense from the list
  const copyExpense = (expense: Expense) => {
    setCloningUuid(expense.uuid);
    const clone = getExpenseClone(expense);
    saveExpense(clone)
      .then(() => {
        setCloningUuid("");
        showToast({ type: "success", text: t("expenseCopied") });
      })
      .catch((err: any) => {
        setCloningUuid("");
        showToast({ type: "error", title: t("notifications.expenseCopyFailed"), text: err?.backendMessage || "" });
      });
  };

  const deleteExpense = async (expense: Expense) => {
    const now = moment().toISOString();
    const deletedExpense = { ...expense, deleted: now, dirty: now };
    await saveExpense(deletedExpense);
  };

  // Create a hardcoded GPS track, for test/debugging purposes only
  const addDummyTrack = async () => {
    const personId = userConfigurationQuery.data.configuration.person.id;
    const track = getDummyTrack(personId);
    await saveMileage(track);
  };
  const addDummyTrackWithTollstations = async () => {
    const personId = userConfigurationQuery.data.configuration.person.id;
    const track = getDummyTrackWithTollstations(personId);
    await saveMileage(track);
  };

  return (
    <div>
      <div>
        <PageHeader page="mileage" />

        {!selectingMultiple && (
          <CreateButtons
            showDriving
            showMileageReportFromDriving
            onCreateExpense={addExpense}
            onCreateReportFromExpenses={createMileageReportFromDriving}
            disabled={!expensesQuery.data || !mileagesQuery.data}
          />
        )}
        {selectingMultiple && (
          <div>
            <ButtonWithSpinner
              bsStyle="success"
              bsSize="large"
              disabled={selectedExpenseUuids.length === 0 || creatingReportFromExpenses}
              onClick={async () => await confirmCreateMileageReportFromDriving()}
              showSpinner={creatingReportFromExpenses}
            >
              {t("expensesView.confirmCreateMileageReport", {
                count: selectedExpenseUuids.length
              })}
            </ButtonWithSpinner>{" "}
            <Button bsStyle="warning" bsSize="large" disabled={creatingReportFromExpenses} onClick={() => setSelectingMultiple(false)}>
              {t("cancel")}
            </Button>
          </div>
        )}
      </div>

      <div className="report-component">
        <ExpenseList
          title={t("listHeaders.mileageAllTitle")}
          description={t("listHeaders.mileageAllInfo")}
          expenses={expenses}
          orderBy="changed"
          orderDesc
          readOnly={false}
          renderMode="table"
          showHeader
          onEditExpense={editExpense}
          onCopyExpense={copyExpense}
          onDeleteExpense={deleteExpense}
          selectingMultiple={selectingMultiple}
          onChangeMultiSelection={changeMultiSelection}
          showRefreshButton={!expensesQuery.isLoading}
          isLoading={isLoadingExpenses}
          isFetching={isFetchingExpenses}
          cloningUuid={cloningUuid}
          errorMessage={
            expensesQuery.isError || expensesQuery.data?.incrementalRefreshStatus === "error" ? t("queryErrors.loadDrivingTripsError") : ""
          }
        />

        {berserkMode && (
          <>
            <Button className="btn btn-xs btn-default" onClick={() => addDummyTrack()}>
              Create dummy track
            </Button>
            <Button className="btn btn-xs btn-default" onClick={() => addDummyTrackWithTollstations()}>
              Create dummy track with toll stations
            </Button>
          </>
        )}

        <MileageList
          title={t("listHeaders.mileageTracksAllTitle")}
          description={t("listHeaders.mileageTracksAllInfo")}
          mileages={mileages}
          orderBy="created"
          orderDesc
          readOnly={false}
          showHeader
          showRefreshButton={!isLoadingMileages}
          refreshMileages={updateMileages}
          isLoading={isLoadingMileages}
          isFetching={isFetchingMileages}
          errorMessage={mileagesQuery.isError || mileagesQuery.data?.incrementalRefreshStatus === "error" ? t("queryErrors.loadMileagesError") : ""}
        />
      </div>
    </div>
  );
};

export default MileagesPage;
