import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as reportApi from "../../shared/api/report";
import Spinner from "../common/Spinner";

// Handles advanced approval deeplinks sent out through email
// The URL should contain a decision UUID
const ApprovalDeeplink = () => {
  const [t] = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState("");

  const q = new URLSearchParams(location.search);
  const decisionUuid = q.get("uuid");

  useEffect(() => {
    if (decisionUuid) {
      // We have a decision UUID, fetch the report details
      reportApi
        .detailsByAdvancedApprovalAttemptDecision(decisionUuid)
        .then((external) => {
          history.push(`/report/${external.report.uuid}/view`, {
            externalReport: external.report,
            externalProductConfig: external.ownerProductConfig,
            externalDecisionUuid: decisionUuid
          });
        })
        .catch((err) => {
          const errorMessage = err?.backendMessage || t("approvals.couldNotLoadDeeplink");
          setErrorMessage(errorMessage);
        });
    } else {
      // No UUID provided, go to the main approval page instead
      history.push("/approval");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [decisionUuid]);

  if (errorMessage !== "") {
    return (
      <div style={{ padding: "25px" }}>
        <strong>{errorMessage}</strong>
        <br />
        <br />
        <Link to="/approval">{t("approvals.goToApprovals")}</Link>
      </div>
    );
  }
  return (
    <div style={{ padding: "25px" }}>
      <Spinner size="75px" />
    </div>
  );
};

export default ApprovalDeeplink;
