import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import Icon from "../common/Icon";
import AttachmentThumbnail from "../attachment/AttachmentThumbnail";
import CheckboxInput from "../common/CheckboxInput";
import { formatMoney, formatDateRange, capitalize } from "../../shared/utils/helpers";
import { getExpenseTypeDescription } from "../../shared/utils/expenseUtils";
import { Expense, ExpenseType } from "../../shared/types";
import Spinner from "../common/Spinner";

interface ExpenseListItemProps {
  expense: Expense;
  expenseType?: ExpenseType;
  readOnly?: boolean;
  persisting?: boolean; // Parent entity is currently being persisted, disable changes
  readOnlyAttachments: boolean;
  renderMode: "drawer" | "table";
  alternate: boolean;
  onDelete?: (expense: Expense) => void;
  onCopy?: (expense: Expense) => void;
  onEdit?: (expense: Expense) => void;
  onShowAttachables?: (expense: Expense) => void;
  onDrawerSelection?: (expense: Expense) => void;
  onMoveToStandaloneExpenses?: (expense: Expense) => void;
  onToggleSelection?: (expense: Expense, checked: boolean) => void;
  selectable: boolean;
  selected: boolean;
  validationErrors: boolean;
  cloning?: boolean;
}
const ExpenseListItem = ({
  expense,
  expenseType,
  readOnly,
  readOnlyAttachments,
  persisting,
  renderMode,
  alternate,
  onDelete,
  onCopy,
  onEdit,
  onShowAttachables,
  onDrawerSelection,
  selectable,
  selected,
  onToggleSelection,
  validationErrors,
  onMoveToStandaloneExpenses,
  cloning
}: ExpenseListItemProps) => {
  const [t] = useTranslation();
  const { starts, ends, totalValueNok, receipt, refund, description, currency, unitPrice, units, attachments } = expense;

  const dates = formatDateRange(starts, renderMode !== "drawer" ? ends : starts);
  const sumNok = formatMoney(totalValueNok);
  const sumOriginalCurrency = currency !== "NOK" ? `${currency} ${formatMoney(unitPrice * units)}` : "";

  const descriptionLink = (
    <button className="fakelink" onClick={() => onEdit && onEdit(expense)}>
      <span>{description || "(No description)"}</span>
    </button>
  );

  const icons = (
    <React.Fragment>
      {receipt && <Icon icon="receipt" title={t("hasReceipt")} />}
      {refund && <Icon icon="refund" title={t("isRefundable")} />}
    </React.Fragment>
  );

  const sum = (
    <React.Fragment>
      {`${sumNok}${sumOriginalCurrency && " / "}${sumOriginalCurrency}`}
      {/* <span>{sumNok}</span>
      {sumOriginalCurrency && <span>{` (${sumOriginalCurrency})`}</span>} */}
    </React.Fragment>
  );

  const attachmentThumbnails = attachments
    .filter((attachment) => !attachment.deleted)
    .slice(0, 4)
    .map((a) => (
      <div key={`attachmentThumbnail_${a.uuid}`}>
        <AttachmentThumbnail attachment={a} />
      </div>
    ));

  const attachButton = renderMode === "table" && !readOnly && onShowAttachables && (
    <Button
      className="item-attach-button"
      title={capitalize(t("moveOrAttachItems"))}
      onClick={() => onShowAttachables(expense)}
      disabled={readOnlyAttachments || persisting}
    >
      <Icon icon="attachment" />
    </Button>
  );

  const deleteButton = !readOnly && onDelete && (
    <Button title={capitalize(t("delete"))} onClick={() => onDelete(expense)} disabled={persisting}>
      <Icon icon="trash" />
    </Button>
  );

  const copyButton =
    !readOnly &&
    onCopy &&
    (cloning ? (
      <Spinner />
    ) : (
      <Button title={capitalize(t("copy"))} onClick={() => onCopy(expense)} disabled={cloning || persisting}>
        <Icon icon="copy" />
      </Button>
    ));

  const makeStandaloneButton = !readOnly && onMoveToStandaloneExpenses && (
    <Button title={capitalize(t("moveToStandaloneExpenses"))} onClick={() => onMoveToStandaloneExpenses(expense)} disabled={persisting}>
      <Icon icon="leaveReport" />
    </Button>
  );

  const checkbox =
    selectable && onToggleSelection ? (
      <CheckboxInput value={selected} field={`selected_${expense.uuid}`} onChange={(checked: boolean) => onToggleSelection(expense, checked)} />
    ) : null;

  const expenseTypeDescription = expenseType
    ? getExpenseTypeDescription({
        expenseType,
        expenseTypeId: expense.expenseTypeId
      })
    : "?";

  let extraClass = "";
  if (renderMode === "table" && alternate) extraClass += " alternate";
  if (validationErrors) extraClass += " validation-errors";

  /* Render as drawer */
  if (renderMode === "drawer") {
    const drawerItemClass = readOnly ? "drawer-item drawer-item-readonly" : "drawer-item";
    const title = readOnly ? t("attachablesDrawer.cantDetachFromExpenseBecauseReadOnly") : "";
    return (
      <div className={drawerItemClass} role="button" tabIndex={0} onClick={() => onDrawerSelection && onDrawerSelection(expense)} title={title}>
        <div className={`item-description ${extraClass}`}>{description}</div>
        <div className={`item-dates ${extraClass}`}>{dates}</div>
        <div className={`item-sum ${extraClass}`}>{sum}</div>
      </div>
    );
  }

  /* Render as table */
  return (
    <React.Fragment>
      <div className={`item-checkbox ${extraClass}`}>{checkbox}</div>
      <div className={`item-dates ${extraClass}`}>{dates}</div>
      <div className={`item-description ${extraClass}`}>{descriptionLink}</div>
      <div className={`item-type-description ${extraClass}`}>{expenseTypeDescription}</div>
      <div className={`item-icons ${extraClass}`}>{icons}</div>
      <div className={`item-sum ${extraClass}`}>{sum}</div>
      <div className={`item-thumbnails ${extraClass}`}>{attachmentThumbnails}</div>
      <div className={`item-actions ${extraClass}`}>
        {makeStandaloneButton}
        {attachButton}
        {copyButton}
        {deleteButton}
      </div>
    </React.Fragment>
  );
};

export default ExpenseListItem;
