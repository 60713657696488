import { useState, useEffect } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useBus, BusEvent } from "../../utils/useBus";
import { Row, Col, FormGroup, ControlLabel } from "react-bootstrap";
import TemporalPicker from "../common/TemporalPicker";
import SelectGroupGeography from "../common/SelectGroupGeography";
import SelectGroup from "../common/SelectGroup";
import CheckboxInput from "../common/CheckboxInput";
import Map from "../google/Map";
import "../../styles/report.css";
import { ReportSegment } from "../../shared/types";
import { lodgingTypes } from "../../shared/utils/constants";
import { useUserConfiguration } from "../../shared/queries/queries";

// Edit a single reportsegment
interface ReportSegmentEditorProps {
  segment: ReportSegment;
  reportTypeId: number;
  readOnly?: boolean;
  onChange: (partialSegment: Partial<ReportSegment>) => void;
}
const ReportSegmentEditor = ({ segment, reportTypeId, readOnly, onChange }: ReportSegmentEditorProps) => {
  const [t] = useTranslation();

  const userConfigurationQuery = useUserConfiguration();

  const [currentSegment, setCurrentSegment] = useState(segment);
  const [berserkMode, setBerserkMode] = useState(false);

  const busHandler = (event: BusEvent) => {
    if (event === "BERSERK_MODE") setBerserkMode(true);
  };
  useBus(busHandler);

  useEffect(() => {
    setCurrentSegment(segment);
  }, [segment]);

  // Stop useless updates from rerendering and breaking the date picker
  // shouldComponentUpdate(nextProps, nextState) {
  //   return (
  //     !isEqual(this.props, nextProps) ||
  //     this.state.segmentLocation !== nextState.segmentLocation ||
  //     this.state.segmentCityId !== nextState.segmentCityId ||
  //     this.state.segmentCountryId !== nextState.segmentCountryId ||
  //     this.state.berserkMode !== nextState.berserkMode
  //   );
  // }

  // A new location was picked
  const onChangeLocation = (selectedLocation: string) => {
    // Validate and throw to onChange
    const locationParts = selectedLocation.split("|");
    const partialSegment = {
      cityId: parseInt(locationParts[0], 10),
      cityName: locationParts[1],
      countryId: parseInt(locationParts[2], 10),
      countryName: locationParts[3],
      location: locationParts[4]
    };
    onChange(partialSegment);
  };

  const onChangeDateRange = (startDate: string, endDate?: string) => {
    // Validate and throw to onChange
    const starts = moment(startDate).toISOString();
    const stops = endDate ? moment(endDate).toISOString() : starts;
    const partialSegment = {
      starts,
      stops
    };
    onChange(partialSegment);
  };

  const onChangeField = (partialSegment: Partial<ReportSegment>) => {
    // Other field changed, throw it up the chain
    onChange(partialSegment);
  };

  const uxPolicyDiet = userConfigurationQuery?.data?.configuration?.product?.uxPolicy?.diet;

  let lodgingTypeOptions = lodgingTypes.map((o) => {
    return { value: o.id, label: t(`lodgingTypes.${o.description}`) };
  });

  // In berserk mode, add an invalid lodging type. This allows us to create an invalid reportsegment for testing
  if (berserkMode) lodgingTypeOptions.push({ value: 100, label: "INVALID OPTION - WILL BREAK" });

  // Remove available logdingTypes based on UX policy, except any that has already been selected
  if (uxPolicyDiet && uxPolicyDiet.hiddenLodgingTypeIds && uxPolicyDiet?.hiddenLodgingTypeIds.length > 0) {
    var hiddenIds = uxPolicyDiet.hiddenLodgingTypeIds.filter((o) => o !== segment.lodgingType);
    lodgingTypeOptions = lodgingTypeOptions.filter((o) => !hiddenIds.includes(o.value));
  }

  // Check UX policy for individual fields
  const policyFarFromHome = uxPolicyDiet?.fields?.farFromHome;
  const policyIncludeNightExtra = uxPolicyDiet?.fields?.includeNightExtra;
  const policyIncludeOvernightAbroadExtra = uxPolicyDiet?.fields?.includeOvernightAbroadExtra;

  // Construct a geography selectgroup friendly location value
  const currentLocationValue = `${currentSegment.cityId}|${currentSegment.cityName}|${currentSegment.countryId}|${currentSegment.countryName}|${currentSegment.location}`;

  return (
    <Row>
      <Col sm={6}>
        <FormGroup>
          <ControlLabel>{t("reportSegment.timespan")}</ControlLabel>
          <div>
            <TemporalPicker
              useRange
              useTime
              startDate={currentSegment.starts}
              endDate={currentSegment.stops}
              onChange={(o: { start: string; end?: string }) => onChangeDateRange(o.start, o.end)}
              readOnly={readOnly}
              parentEntityUuid={currentSegment.uuid}
            />
          </div>
        </FormGroup>

        <SelectGroupGeography
          field="location"
          label={t("reportSegment.location")}
          value={currentLocationValue}
          onChange={(value: string) => onChangeLocation(value)}
          readOnly={readOnly}
        />

        {reportTypeId === 1 && (
          <div>
            <SelectGroup
              field="lodgingType"
              label={t("reportSegment.lodgingType")}
              options={lodgingTypeOptions}
              value={currentSegment.lodgingType}
              onChange={(value: number) => onChangeField({ lodgingType: value })}
              readOnly={readOnly}
            />
          </div>
        )}

        <div>
          <div className={policyFarFromHome && policyFarFromHome.hide ? "hidden" : ""}>
            <CheckboxInput
              field="farFromHome"
              value={currentSegment.farFromHome}
              label={t("reportSegment.travelDistance")}
              onChange={(value: boolean) => onChangeField({ farFromHome: value })}
              readOnly={readOnly || (policyFarFromHome && policyFarFromHome.readOnly)}
            />
          </div>
          <div className={policyIncludeOvernightAbroadExtra && policyIncludeOvernightAbroadExtra.hide ? "hidden" : ""}>
            <CheckboxInput
              field="includeOvernightAbroadExtra"
              value={currentSegment.includeOvernightAbroadExtra}
              label={t("reportSegment.internationalAllowance")}
              onChange={(value: boolean) =>
                onChangeField({
                  includeOvernightAbroadExtra: value
                })
              }
              readOnly={readOnly || (policyIncludeOvernightAbroadExtra && policyIncludeOvernightAbroadExtra.readOnly)}
            />
          </div>
          <div className={policyIncludeNightExtra && policyIncludeNightExtra.hide ? "hidden" : ""}>
            <CheckboxInput
              field="includeNightExtra"
              value={currentSegment.includeNightExtra}
              label={t("reportSegment.overnightExtra")}
              onChange={(value: boolean) => onChangeField({ includeNightExtra: value })}
              readOnly={readOnly || (policyIncludeNightExtra && policyIncludeNightExtra.readOnly)}
            />
          </div>
          <div>
            <CheckboxInput
              field="endsDietCalculation"
              value={currentSegment.endsDietCalculation}
              label={t("reportSegment.endsDietCalculation")}
              onChange={(value: boolean) => onChangeField({ endsDietCalculation: value })}
              readOnly={readOnly}
            />
          </div>
        </div>
      </Col>

      <Col sm={6} xsHidden>
        <div className="segments-map" style={{ height: 330 }}>
          <Map segments={[currentSegment]} />
        </div>
      </Col>
    </Row>
  );
};

export default ReportSegmentEditor;
