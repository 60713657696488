import { useStore } from "../shared/store/store";

// Main interface for the webclient-specific part of the zustand store
export interface ClientSpecificStore {
  preview: StorePreview;
  authenticationExpired: boolean;
  refreshingReports: boolean;
  refreshingExpenses: boolean;
  refreshingInboxes: boolean;
  refreshingMileages: boolean;
  hideUsedInboxesDefault: boolean; // Sets the default for the "hide used" checkbox on the inbox page
  postponeAdminOnboardingWizard: boolean; // True if the user has seen the admin onboarding wizard and chosen to postpone it until next session
  preferredExchangeRates: PreferredExchangeRate[];
  lastUsedApproverGroupId: number;
}
// Interface for attachment previews
export interface StorePreview {
  mimeType: string;
  body?: string; // Provide this if we already have renderable content
  blobUuid?: string; // .. or this if we should fetch a blob and render it
  objectUrl?: string; // .. or this if we already have a local objectUrl containing the content
  source?: string;
}

// Users can "lock" exchange rates for individual currencies and save their preferences using this
export interface PreferredExchangeRate {
  currency: string;
  rate: number;
}

// Initial state
const initialPreview: StorePreview = { body: "", blobUuid: "", mimeType: "", objectUrl: "", source: "" };
export const initialClientSpecificStore: ClientSpecificStore = {
  preview: initialPreview,
  authenticationExpired: false,
  refreshingReports: false,
  refreshingExpenses: false,
  refreshingInboxes: false,
  refreshingMileages: false,
  hideUsedInboxesDefault: false,
  postponeAdminOnboardingWizard: false,
  preferredExchangeRates: [],
  lastUsedApproverGroupId: 0
};

// Actions to modify webclient-specific store state
export const showPreview = (preview: StorePreview) => {
  const current = useStore.getState().clientSpecific;
  useStore.setState({ clientSpecific: { ...current, preview: preview } });
};
export const hidePreview = () => {
  const current = useStore.getState().clientSpecific;
  useStore.setState({ clientSpecific: { ...current, preview: initialPreview } });
};
export const setRefreshingReports = (isRefreshing: boolean) => {
  const current = useStore.getState().clientSpecific;
  useStore.setState({ clientSpecific: { ...current, refreshingReports: isRefreshing } });
};
export const setRefreshingInboxes = (isRefreshing: boolean) => {
  const current = useStore.getState().clientSpecific;
  useStore.setState({ clientSpecific: { ...current, refreshingInboxes: isRefreshing } });
};
export const setRefreshingExpenses = (isRefreshing: boolean) => {
  const current = useStore.getState().clientSpecific;
  useStore.setState({ clientSpecific: { ...current, refreshingExpenses: isRefreshing } });
};
export const setRefreshingMileages = (isRefreshing: boolean) => {
  const current = useStore.getState().clientSpecific;
  useStore.setState({ clientSpecific: { ...current, refreshingMileages: isRefreshing } });
};
export const setAuthenticationExpired = (isExpired: boolean) => {
  const current = useStore.getState().clientSpecific;
  useStore.setState({ clientSpecific: { ...current, authenticationExpired: isExpired } });
};
export const setHideUsedInboxesDefault = (hide: boolean) => {
  const current = useStore.getState().clientSpecific;
  useStore.setState({ clientSpecific: { ...current, hideUsedInboxesDefault: hide } });
};
export const setPostponeAdminOnboardingWizard = (postpone: boolean) => {
  const current = useStore.getState().clientSpecific;
  useStore.setState({ clientSpecific: { ...current, postponeAdminOnboardingWizard: postpone } });
};

export const addPreferredExchangeRate = (currency: string, rate: number) => {
  const current = useStore.getState().clientSpecific;
  const newPreferred = current.preferredExchangeRates
    ? current.preferredExchangeRates.filter((o: PreferredExchangeRate) => o.currency !== currency)
    : [];
  newPreferred.push({ currency, rate });
  useStore.setState({ clientSpecific: { ...current, preferredExchangeRates: newPreferred } });
};
export const removePreferredExchangeRate = (currency: string) => {
  const current = useStore.getState().clientSpecific;
  const newPreferred = current.preferredExchangeRates
    ? current.preferredExchangeRates.filter((o: PreferredExchangeRate) => o.currency !== currency)
    : [];
  useStore.setState({ clientSpecific: { ...current, preferredExchangeRates: newPreferred } });
};

export const setLastUsedApproverGroupId = (groupId: number) => {
  const current = useStore.getState().clientSpecific;
  useStore.setState({ clientSpecific: { ...current, lastUsedApproverGroupId: groupId } });
};
