import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Dropzone, { FileRejection } from "react-dropzone";
import { showToast } from "../../utils/toastWrapper";
import Spinner from "./Spinner";
import Icon from "./Icon";
import { uuid } from "../../shared/utils/helpers";
import { save, saveSupportAttachment } from "../../shared/api/blob";
import { CancelablePromise, makeCancelable } from "../../utils/makeCancelable";
import { SupportAttachment } from "../../shared/types";
import "../../styles/uploader.css";

// File uploader component for profiles signatures and support case attachments
interface GenericUploaderProps {
  onCreatedSignature?: (blobUuid: string) => void; // Callback that handles newly created signature
  onCreatedSupportAttachment?: (originalFileName: string, blobRef: string, mimeType: string, file: Blob) => void; // Callback that handles newly created support attachments
  onError: () => void; // Callback to handle upload errors, maybe pass back some details here
  mode: "signature" | "support";
}
const GenericUploader = ({ onCreatedSignature, onCreatedSupportAttachment, onError, mode }: GenericUploaderProps) => {
  const [t] = useTranslation();

  const [processingUpload, setProcessingUpload] = useState(false);
  const [promises, setPromises] = useState<CancelablePromise[]>([]);

  // Cancel any fetch promises to allow garbage disposal
  useEffect(() => {
    return () => {
      promises.forEach((p) => p.cancel());
    };
  }, [promises]);

  const onDrop = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    if (fileRejections.length)
      showToast({
        title: t("uploader.invalidFilesTitle", { count: fileRejections.length }),
        text: t("uploader.invalidFiles", { count: fileRejections.length }),
        type: "warning"
      });

    if (acceptedFiles.length) {
      setProcessingUpload(true);

      // We got uploadable files; upload the first one as a blob and hit the callback when done
      const file = acceptedFiles[0];
      const originalFilename = file.name;
      if (mode === "signature") {
        if (!onCreatedSignature) throw new Error("GenericUploader called in signature mode without onCreatedSignature callback");
        const blobUuid = uuid();
        save(blobUuid, file, "signatures").then(() => {
          setProcessingUpload(false);
          // Signature callback requires the uploaded blobUuid for clientside rendering
          onCreatedSignature(blobUuid);
        });
      }
      if (mode === "support") {
        if (!onCreatedSupportAttachment) throw new Error("GenericUploader called in support mode without onCreatedSupportAttachment callback");
        const fi = fileInfo(originalFilename);
        const mimeType = fi.mimeType;
        const extension = fi.extension;
        const supportAttachment: SupportAttachment = {
          mimeType,
          extension,
          blob: file,
          originalFilename
        };

        const prom = makeCancelable(saveSupportAttachment(supportAttachment));
        setPromises([...promises, prom]);
        prom.promise
          .then((result) => {
            setProcessingUpload(false);
            // Support case attachment callback requires the original filename for clientside rendering, and serverside filename + mimeType for submitting with the support case
            onCreatedSupportAttachment(originalFilename, result.Blobref, mimeType, file);
          })
          .catch(() => {
            setProcessingUpload(false);
            onError && onError();
          });
      }
    }
  };

  // Extract mimetype and extension from file
  const fileInfo = (fileName: string) => {
    // If broken or not supported for some reason, just assume a jpeg
    if (!fileName) return { mimeType: "image/jpeg", extension: "jpg" };
    const lower = fileName.toLowerCase();
    if (lower.endsWith(".jpg")) return { mimeType: "image/jpeg", extension: "jpg" };
    if (lower.endsWith(".png")) return { mimeType: "image/png", extension: "png" };
    if (lower.endsWith(".gif")) return { mimeType: "image/gif", extension: "gif" };
    if (lower.endsWith(".bmp")) return { mimeType: "image/bmp", extension: "bmp" };
    if (lower.endsWith(".pdf")) return { mimeType: "application/pdf", extension: "pdf" };
    if (lower.endsWith(".txt")) return { mimeType: "text/plain", extension: "txt" };
    return { mimeType: "image/jpeg", extension: "jpg" };
  };

  const dropzoneClass = `uploader-dropzone signature-sized`;
  const activeClass = "uploader-dropzone-dropping";
  // Verify mode
  if (!["signature", "support"].includes(mode)) return null;
  return (
    <Dropzone onDrop={onDrop} accept=".jpg, .jpeg, .gif, .png">
      {({ getRootProps, getInputProps, isDragActive }) => (
        <div {...getRootProps({ className: `${dropzoneClass} ${isDragActive ? activeClass : ""}` })}>
          <input {...getInputProps()} />
          <div className="inner">
            {processingUpload && <Spinner size="4em" />}
            {!processingUpload && mode === "signature" && (
              <div>
                <Icon icon="upload" />
                <div className="first">
                  <strong>{t("uploader.dragAndDropSignature")}</strong>
                </div>

                <div className="second">
                  <span>{t("uploader.orClickToSelectSignature")}</span>
                </div>
              </div>
            )}
            {!processingUpload && mode === "support" && (
              <div>
                <Icon icon="upload" />
                <div className="first">{t("uploader.dragAndDropFile")}</div>
              </div>
            )}
          </div>
        </div>
      )}
    </Dropzone>
  );
};

export default GenericUploader;
