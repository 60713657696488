import i18n from "../i18n/i18nConfig";
import { Expense, ExpenseCustomField, ValidateExpenseErrors, ValidateExpenseResult, UxPolicy, ValidateExpenseError } from "../types";

// Validates an expense
// Intended to be called from report validator or from the standalone expense editor directly
// Typically run before persisting the expense, and before submitting the containing report
export const validateExpense = (expense: Expense, enabledExpenseCustomFields: ExpenseCustomField[], uxPolicy?: UxPolicy): ValidateExpenseResult => {
  const errors: ValidateExpenseErrors = {
    uuid: expense.uuid,
    header: [],
    expenseCustomFields: []
  };

  // Helper to generate required field errors for report header fields
  const requiredFieldError = (fieldName: any): ValidateExpenseError => {
    return { fieldName, message: i18n.t("requiredField") };
  };

  // Check if the UX policy specifies any required fields, and add errors if any of those fields have missing values
  const policyFields = uxPolicy?.expense?.fields;
  if (policyFields) {
    if (policyFields.description?.required && expense.description.trim() === "") errors.header.push(requiredFieldError("description"));
    // Ignore totalVatNok (calculated field), and receipt/refund (checkboxes)
  }

  const policyCustomFields = uxPolicy?.expense?.customFields;

  let requiredSystemNames: string[] = [];
  if (policyCustomFields) {
    for (const [key, val] of Object.entries(policyCustomFields)) {
      if (val.required) requiredSystemNames.push(key);
    }
  }

  enabledExpenseCustomFields
    .filter((field) => field.expenseTypeId === 0 || field.expenseTypeId === expense.expenseTypeId)
    .map((field) => {
      // Don't validate bool fields, they're allowed to be unchecked and still be valid
      if ((field.required || requiredSystemNames.includes(field.systemName)) && field.fieldType !== "bool") {
        const ev = expense.expenseCustomValues.filter((v) => !v.deleted).find((v) => v.expenseCustomFieldId === field.id);
        if (!ev || !ev.value || ev.value === "") {
          // Required customfield is empty
          // Make an exception for the driving_passengernames field. If it's required, it should only throw a validation error if the driving_passengers field has a numeric value > 0
          let addError: boolean = true;
          if (field.systemName === "driving_passengernames") {
            addError = false;
            const drivingPassengersCF = enabledExpenseCustomFields.find((o) => o.systemName === "driving_passengers");
            if (drivingPassengersCF) {
              const drivingPassengersCV = expense.expenseCustomValues.find((o) => o.expenseCustomFieldId === drivingPassengersCF.id);
              if (drivingPassengersCV) {
                if (drivingPassengersCV.value) {
                  const numPassengers = Number(drivingPassengersCV.value);
                  if (!isNaN(numPassengers) && numPassengers > 0) addError = true;
                }
              }
            }
          }

          if (addError) {
            errors.expenseCustomFields.push({
              fieldId: field.id,
              message: i18n.t("requiredField")
            });
          }
        }
      }
      return true;
    });

  const isValid = errors.header.length === 0 && errors.expenseCustomFields.length === 0;

  return {
    errors,
    isValid
  };
};
