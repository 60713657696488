import { useRef } from "react";

// Emulates a class component-style constructor for functional components
const useConstructor = (callBack: Function) => {
  const hasBeenCalled = useRef(false);
  if (hasBeenCalled.current) return;
  if (callBack) callBack();
  hasBeenCalled.current = true;
};

export default useConstructor;
