import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import AppRoot from "./AppRoot";
import RouteLogger from "./RouteLogger";
import RouteCleaner from "./RouteCleaner";
import HomePage from "./home/HomePage";
import LoginPage from "./login/LoginPage";
import ResetPasswordPage from "./login/ResetPasswordPage";
import SignupPage from "./login/SignupPage";
import ReportPage from "./report/ReportPage";
import ReportPageNew from "./report/ReportPageNew";
import ApprovalsPage from "./approval/ApprovalsPage";
import ProfilePage from "./profile/ProfilePage";
import AboutPage from "./about/AboutPage";
import LogoutPage from "./login/LogoutPage";
import EulaPage from "./eula/EulaPage";
import DataProcessorPage from "./eula/DataProcessorPage";
import PrivacyPage from "./eula/PrivacyPage";
import ApproveDeeplink from "./approval/ApproveDeeplink";
import AuthenticatedRoute from "./AuthenticatedRoute";

import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { createApplicationInsightsReactPlugin } from "../utils/eventTracker";

interface RootRouterProps {
  basename: string;
}
const RootRouter = ({ basename }: RootRouterProps) => {
  return (
    <Router basename={basename}>
      <RouteCleaner>
        <RouteLogger>
          <Switch>
            <Route exact path="(/login|/login/otl)" component={LoginPage} />
            <Route path="/login/resetpassword" component={ResetPasswordPage} />
            <Route path="/login/signup" component={SignupPage} />

            <Redirect from="/eula/databehandler" to="/eula/dpa" />
            <Route path="/eula/dpa" component={DataProcessorPage} />
            <Redirect from="/eula/personvern" to="/eula/privacy" />
            <Route path="/eula/privacy" component={PrivacyPage} />
            <Route path="/eula" component={EulaPage} />

            <AppRoot>
              <AuthenticatedRoute exact path="(/|/reports|/mileages|/expenses|/inbox)" component={HomePage} />
              <AuthenticatedRoute exact path="/createreport" component={ReportPageNew} />
              <AuthenticatedRoute path="/report/:uuid" component={ReportPage} />
              <AuthenticatedRoute path="/expense/:uuid" component={HomePage} />
              <AuthenticatedRoute path="/mileage/:uuid" component={HomePage} />
              <AuthenticatedRoute exact path="(/approval|/approval/history)" component={ApprovalsPage} />
              <AuthenticatedRoute exact path="/profile" component={ProfilePage} />
              <AuthenticatedRoute path="/approve/:uuid" component={ApproveDeeplink} />
              <Route path="/about" component={AboutPage} />
              <Route path="/logout" component={LogoutPage} />
            </AppRoot>
          </Switch>
        </RouteLogger>
      </RouteCleaner>
    </Router>
  );
};

// Azure Application Insights
var reactPlugin = createApplicationInsightsReactPlugin();

export default withAITracking(reactPlugin, RootRouter);
