import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Dropzone, { FileRejection } from "react-dropzone";
import { showToast } from "../../utils/toastWrapper";
import Spinner from "./Spinner";
import Icon from "../common/Icon";
import { uuid } from "../../shared/utils/helpers";
import { inboxConstructor } from "../../shared/constructors/inboxConstructor";
import { inboxAttachmentConstructor } from "../../shared/constructors/inboxAttachmentConstructor";
import { attachmentConstructor } from "../../shared/constructors/attachmentConstructor";
import { save as saveBlob } from "../../shared/api/blob";
import { saveExpense, saveInbox } from "../../shared/queries/queries";
import { useUserConfiguration } from "../../shared/queries/queries";
import "../../styles/uploader.css";
import { Attachment } from "../../shared/types";
import { analyzeBlob } from "../../shared/api/receiptAnalysis";
import { getExpenseFromAnalysis } from "../../shared/utils/expenseUtils";
import { attachmentFromInboxAttachment } from "../../shared/constructors/attachmentConstructor";
//import { useLocation } from "react-router";

interface UploaderProps {
  entityType: "inbox" | "report" | "expense"; // Which entity type the uploads should be attached to
  entityUuid: string; // ignored if entityType==inbox, otherwise provide report.uuid or expense.uuid
  onAttachmentsCreated?: (attachments: Attachment[]) => void; // The callback that handles newly created attachments for report and expense, not required for inbox
  disabled?: boolean;
  disablePaste?: boolean;
}
type BlobUpload = { uuid: string; fileName: string; mimeType: string };

const Uploader = ({ entityType, entityUuid, onAttachmentsCreated, disabled }: UploaderProps) => {
  const [t] = useTranslation();

  //const location = useLocation();

  // Pull valid pasted files and pass them to the regular drop handler
  const onPaste = (e: ClipboardEvent) => {
    // // If we're on a report, and this uploader belongs to an expense, just return and let the report attachments uploader handle this paste.
    // // Otherwise, we will get the same attachment on both report and expense.
    // UPDATE: This is no longer an issue since we removed the uploader on the report itself
    // if (location?.pathname?.startsWith("/report") && entityType === "expense") return;
    try {
      if (e?.clipboardData?.files) {
        const filelist = e.clipboardData.files;
        if (filelist.length > 0) {
          const goodFiles: File[] = [];
          for (const i in filelist) {
            const file = filelist[i];
            if (file && file.type && file.size && file.size > 0 && (file.type.startsWith("image/") || file.type.startsWith("application/pdf"))) {
              goodFiles.push(file);
            }
          }
          if (goodFiles.length > 0) {
            onDrop(goodFiles, []);
          }
        }
      }
    } catch {
      // If this breaks it's probably an incompatible browser, so we'll just ignore it
    }
  };

  // Handle pasting of files and file content
  useEffect(() => {
    document.addEventListener("paste", onPaste);
    return () => {
      document.removeEventListener("paste", onPaste);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onAttachmentsCreated]); // Re-listen when this changes. If not, we close over the previous handler which will lead to overwriting previous attachments

  const userConfigurationQuery = useUserConfiguration();
  const [processingUpload, setProcessingUpload] = useState(false);

  if (!userConfigurationQuery.data) return <div>Loading...</div>;

  // Bail out if userconfig is not loaded yet
  const userConfiguration = userConfigurationQuery.data.configuration;
  const personId = userConfiguration.person.id;

  const onDrop = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    if (fileRejections.length)
      showToast({
        title: t("uploader.invalidFilesTitle", { count: fileRejections.length }),
        text: t("uploader.invalidFiles", { count: fileRejections.length }),
        type: "warning"
      });

    if (acceptedFiles.length) {
      setProcessingUpload(true);

      // We got uploadable files; queue them to create blobs
      const blobs: BlobUpload[] = [];
      const uploads: Promise<void>[] = [];
      acceptedFiles.forEach(async (file) => {
        const blobUuid = uuid();
        blobs.push({
          uuid: blobUuid,
          fileName: file.name,
          mimeType: file.type
        });
        uploads.push(saveBlob(blobUuid, file));
      });

      // Upload/save the blobs
      Promise.all(uploads)
        .then(() => {
          // All went well, notify the entity creator
          uploadCompleted(blobs);
        })
        .catch((err) => {
          console.log("Uploader failed", err);
          // We were probably offline while having low quota, or it broke for other reasons
          setProcessingUpload(false);
          showToast({ title: t("uploader.uploadFailedTitle"), text: t("uploader.uploadFailedText"), type: "error" });
        });
    }
    return;
  };

  const uploadCompleted = async (blobs: BlobUpload[]) => {
    setProcessingUpload(false);

    showToast({ title: t("uploader.completedNotificationTitle"), text: t("uploader.completedNotificationText"), type: "success" });

    if (entityType === "inbox") {
      // Create an inbox
      const inbox = inboxConstructor({
        personId,
        source: "web",
        subject: t("uploader.directUpload"),
        inboxAttachments: []
      });

      // Create an inboxAttachment from each blob
      blobs.forEach((blob) => {
        const inboxAttachment = inboxAttachmentConstructor({
          inboxUuid: inbox.uuid,
          originalFilename: blob.fileName,
          mimeType: blob.mimeType,
          blobUuid: blob.uuid
        });
        inbox.inboxAttachments.push(inboxAttachment);
      });

      // Save the inbox with attachments
      saveInbox(inbox)
        .then(() => {
          // If we uploaded images or PDFs while online, immediately request OCR/classification
          // Consider this enabled if we either don't have the setting, or the setting is true
          const analysisEnabled = userConfiguration.product.analyzeReceipts === undefined || userConfiguration.product.analyzeReceipts === true;
          if (analysisEnabled) {
            inbox.inboxAttachments.forEach(async (inboxAttachment) => {
              if (inboxAttachment.mimeType.startsWith("image/") || inboxAttachment.mimeType.startsWith("application/pdf")) {
                // Use the result to create a standalone expense. Maybe add to active report in the future, but since we're already in the webclient it should be fine
                // Only do this if we at least have the sum
                const analysis = await analyzeBlob(inboxAttachment.blobUuid);
                if (analysis.sum && analysis.sum.value) {
                  const expense = await getExpenseFromAnalysis({
                    personId,
                    analysis,
                    userConfig: userConfiguration
                  });
                  const attachment = attachmentFromInboxAttachment({ expense, inboxAttachment });
                  expense.attachments.push(attachment);

                  saveExpense(expense)
                    .then(() => {
                      showToast({
                        title: t("uploader.createdExpenseFromAnalysisTitle"),
                        text: t("uploader.createdExpenseFromAnalysisText"),
                        type: "success"
                      });
                    })
                    .catch((err: any) => {
                      showToast({ type: "error", title: t("notifications.expenseSaveFailed"), text: err?.backendMessage || "" });
                    });
                }
              }
            });
          }
        })
        .catch((err: any) => {
          showToast({ type: "error", title: t("notifications.inboxSaveFailed"), text: err?.backendMessage || "" });
        });
    }

    if ((entityType === "report" || entityType === "expense") && entityUuid && onAttachmentsCreated) {
      // Construct an report/expense-attachment from each blob and pass it back up the chain
      const addedAttachments: Attachment[] = [];
      for (const blob of blobs) {
        const attachment = attachmentConstructor({
          reportUuid: entityType === "report" ? entityUuid : "",
          expenseUuid: entityType === "expense" ? entityUuid : "",
          externalContent: true,
          blobUuid: blob.uuid,
          mimeType: blob.mimeType,
          originalFilename: blob.fileName
        });
        addedAttachments.push(attachment);
      }
      onAttachmentsCreated(addedAttachments);
    }
  };

  const dropzoneClass = `uploader-dropzone ${entityType}-sized`;
  const activeClass = "uploader-dropzone-dropping";
  return (
    <Dropzone disabled={disabled} onDrop={onDrop} accept=".jpg, .jpeg, .gif, .png, .pdf">
      {({ getRootProps, getInputProps, isDragActive }) => (
        <div {...getRootProps({ className: `${dropzoneClass} ${isDragActive ? activeClass : ""}` })}>
          <input {...getInputProps()} />
          <div className="inner">
            {processingUpload ? (
              <Spinner size="4em" />
            ) : (
              <div>
                <Icon icon="upload" />
                <br />
                <div className="first">{t("uploader.dragAndDropImagesToAttach")}</div>

                <div className="second">
                  <span>{t("uploader.orClickToSelectFiles")}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </Dropzone>
  );
};

export default Uploader;
