//This module exports common config variables for both projects.
//These can be overridden by environment variables set during the build process in Azure for instance
//for the process.env function to work in the react app as well, they need to be prefixed with REACT_APP

declare const window: Window &
  typeof globalThis & {
    SERVER_APP_SETTINGS: any;
  };

const config = {
  // REACT_APP_ENVIRONMENT is intended to be overwritten by an environment variable, azure config, build process, etc.
  REACT_APP_ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT || "dev", // Default environment: "localhost", "dev", "staging" or "prod"
  // Root URLs to the different backend services
  REACT_APP_SERVER_LOCALHOST: process.env.REACT_APP_SERVER_LOCALHOST || "https://localhost:44390/", // URL to localhost backend
  REACT_APP_SERVER_DEV: process.env.REACT_APP_SERVER_DEV || "https://backend-dev.traveltext.no/", // URL to dev backend
  REACT_APP_SERVER_STAGING: process.env.REACT_APP_SERVER_STAGING || "https://backend-staging.traveltext.no/", // URL to staging backend
  REACT_APP_SERVER_PROD: process.env.REACT_APP_SERVER_PROD || "https://backend.traveltext.no/", // URL to prod backend
  // Inbox email domains
  REACT_APP_INBOX_DEFAULT_DOMAIN: "traveltext.no",
  REACT_APP_INBOX_PRODUCTION_DOMAIN: "innboks.traveltext.no",
  REACT_APP_INBOX_DEVELOPMENT_DOMAIN: "innboks-dev.traveltext.no",
  REACT_APP_INBOX_STAGING_DOMAIN: "innboks-staging.traveltext.no"
};

// If we're currently living inside a web app, running on azure, we might have some overriding config written into the window scope
if (typeof window !== "undefined" && window.SERVER_APP_SETTINGS) {
  config.REACT_APP_SERVER_DEV = window.SERVER_APP_SETTINGS.REACT_APP_SERVER_DEV;
  config.REACT_APP_SERVER_STAGING = window.SERVER_APP_SETTINGS.REACT_APP_SERVER_STAGING;
  config.REACT_APP_SERVER_PROD = window.SERVER_APP_SETTINGS.REACT_APP_SERVER_PROD;
  config.REACT_APP_ENVIRONMENT = window.SERVER_APP_SETTINGS.REACT_APP_ENVIRONMENT;
}

export default config;
