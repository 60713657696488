import cloneDeep from "lodash/cloneDeep";
import { AdvancedApprovalAttempt } from "../types";

/**
 * A report can have an advancedApprovalAttempts array, which is an array of advanced approval attempts (aka timelines)
 * Each attempt has a "decisions" array of decision statuses for each member in each step in the attempt
 * This function takes a single attempt and returns a copy, applying a "status" field to all steps and decisions in the attempt
 * It also copies the "usedForReportEditing" to each step member
 * This makes the timeline easier to render, and provides a single point of truth for what status a step has based on the decisions within
 * @param {object} attempt - A single attempt from report.advancedApprovalAttempts
 * @returns {object} A clone of the the attempt, with a "status" field on each step and decision
 */
export const addStatusesToAdvancedApprovalAttempt = (attempt: AdvancedApprovalAttempt) => {
  // Clone attempt so this can be used directly without disturbing props
  const extendedAttempt = cloneDeep(attempt);
  // Go through each step
  extendedAttempt.flow.steps.forEach((step) => {
    // Get members in step
    const stepMemberIds = step.members.map((member) => member.id);
    // Get decisions for those members
    const stepDecisions = extendedAttempt.decisions
      .filter((decision) => stepMemberIds.includes(decision.advancedApprovalStepMemberId))
      .map((decision) => decision.decision);
    // Calculate step status
    step.status = "waiting";
    if (stepDecisions.some((d) => d === 1)) step.status = "queued";
    if (stepDecisions.some((d) => d === 2)) step.status = "rejected";
    if (step.logicType === "any" && stepDecisions.some((d) => d === 3)) step.status = "approved";
    if (step.logicType === "all" && stepDecisions.every((d) => d === 3)) step.status = "approved";
    if (stepDecisions.every((d) => d === 4 || d === 10 || d === 20)) step.status = "cancelled";
    // Set individual member status
    step.members.forEach((member) => {
      const decision = extendedAttempt.decisions.find((d) => d.advancedApprovalStepMemberId === member.id);
      if (decision) {
        const status = decision.decision;
        member.status = "waiting";
        if (status === 1) member.status = "queued";
        if (status === 2) member.status = "rejected";
        if (status === 3) member.status = "approved";
        if (status === 4 || status === 10 || status === 20) member.status = "cancelled";
        member.usedForReportEditing = decision.usedForReportEditing;
      }
    });
  });
  return extendedAttempt;
};

/**
 * Take an advancedApprovalAttempts array, filter on the ones currently awaiting action that we have access to make a decision on
 * If a decision has a UUID, it means the backend has granted access to make a decision (either as the decision owner or by deputization)
 * Therefore, any attempt containing a decision that has a UUID and status 1 is considered pending for this user
 * Used to filter the lists on approval page by pending attempts and historic attempts
 * @param {Array} attempts an array of advancedApprovalAttempts
 * @returns {Array} The attempts currently awaiting action from the current user
 */
export const getAttemptsPending = (attempts: AdvancedApprovalAttempt[]) => {
  return attempts.filter((a) => a.decisions.find((d) => d.decision === 1 && d.uuid));
};

/**
 * Take a single advancedApprovalAttempt, find the first decision in it currently awaiting action that we have access to make a decision on
 * If a decision has a UUID, it means the backend has granted access to make a decision (either as the decision owner or by deputization)
 * Therefore, any attempt containing a decision that has a UUID and status 1 is considered pending for this user
 * Used to figure out which decision uuid to use when making decisions from the approval page
 * @param {object} attempt a single advancedApprovalAttempt
 * @returns {object} The first decision currently awaiting action from the current user
 */
export const getDecisionPending = (attempt: AdvancedApprovalAttempt) => {
  return attempt.decisions.find((d) => d.decision === 1 && d.uuid);
};

/**
 * Take a single advancedApprovalAttempt, return an array of names and comments made by the approvers
 * @param {object} attempt a single advancedApprovalAttempt
 * @returns {Array} {name, comment} - all nonblank comments made during the approval process
 */
export const getAttemptComments = (attempt: AdvancedApprovalAttempt) => {
  const allMembers = attempt.flow.steps.flatMap((s) => s.members);
  const comments = attempt.decisions
    .filter((d) => d.comment && d.comment.trim() !== "")
    .map((d) => {
      const member = allMembers.find((m) => m.id === d.advancedApprovalStepMemberId);
      return {
        name: member ? member.description : "",
        comment: d.comment
      };
    });
  return comments;
};

// A single attempt looks like this:
// var attempt = {
//   uuid,
//   created,
//   changed,
//   comment,
//   decisions: [
//     {
//       uuid,
//       changed,
//       comment,
//       decision,
//       advancedApprovalStepMemberId
//     }
//   ],
//   flow: {
//     description,
//     steps: [
//       {
//         logicType: (any | all),
//         description, members: [
//           {
//             id,
//             description,
//             memberType: (person | email)
//           }
//         ]
//       }
//     ]
//   }
// };

// decision.decision cheatsheet:
// 0 = waiting (decision exists in an active attempt, but is not queued to decide yet)
// 1 = queued (active / currently next up to decide in the attempt)
// 2 = rejected
// 3 = approved
// 4 = cancelled
// 10 = redundant
// 20 = failed

// Step status:
// waiting: all decisions = 0
// queued: at least one decision = 1
// rejected: at least one decision = 2
// approved: at least one decision = 3 in an any-step, or all decisions = 3 in an all-step
// cancelled: all decisions either 4 (cancelled), 10 (redundant) or 20 (failed)

// The attempt itself also has one of the following statuses:
// 0 = in progress
// 2 = rejected
// 3 = approved
// 4 = cancelled
