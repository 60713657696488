import { Button, ButtonProps } from "react-bootstrap";
import "../../styles/ldbtn.css";
import Spinner from "./Spinner";

interface ButtonWithSpinnerProps extends ButtonProps {
  showSpinner?: boolean;
}
const ButtonWithSpinner = (props: ButtonWithSpinnerProps) => {
  const { showSpinner, className, ...restProps } = props;
  const newClassName = `${className || ""} ${"ld-ext-right"} ${showSpinner && "running"}`;
  const newProps = { ...restProps, className: newClassName };
  return (
    <Button {...newProps}>
      {props.children}
      <div className="ld">
        <Spinner />
      </div>
    </Button>
  );
};

export default ButtonWithSpinner;
