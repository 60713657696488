import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

import { Grid, Nav, NavItem } from "react-bootstrap";
import moment from "moment";
import { showToast } from "../../utils/toastWrapper";
import PageHeader from "../common/PageHeader";
import SelectGroup from "../common/SelectGroup";
import AttachmentPreview from "../attachment/AttachmentPreview";
import Empty from "../common/Empty";
import * as reportApprovalApi from "../../shared/api/reportApproval";
import * as reportApi from "../../shared/api/report";
import ApprovalAttemptListItem from "./ApprovalAttemptListItem";
import AdvancedApprovalAttemptTimeline from "./AdvancedApprovalAttemptTimeline";
import { getAttemptsPending, getDecisionPending } from "../../shared/utils/advancedApproval";
import { capitalize } from "../../shared/utils/helpers";

import { useUserConfiguration, useClassicApprovals, useAdvancedApprovals, refreshAllApproval, refreshReports } from "../../shared/queries/queries";
import { useStore } from "../../shared/store/store";

import Spinner from "../common/Spinner";
import "../../styles/reportapproval.css";
import { AdvancedApprovalAttempt, AdvancedApprovalDeputization, ClassicApprovalAttempt } from "../../shared/types";
import useConstructor from "../../shared/utils/useConstructor";

const ApprovalsPage = () => {
  const [t] = useTranslation();
  const history = useHistory();
  const location = useLocation<{
    externalApproval?: { approved: boolean; comment: string; reportUuid: string; externalDecisionUuid: string };
  }>();

  // Monitor this to trigger a rerender if we switch users
  useStore((state) => state.currentUserId);
  const language = useStore((state) => state.language);

  const [classicAttempts, setClassicAttempts] = useState<ClassicApprovalAttempt[]>([]);
  const [advancedAttempts, setAdvancedAttempts] = useState<AdvancedApprovalAttempt[]>([]);
  const [deputizations, setDeputizations] = useState<AdvancedApprovalDeputization[]>([]);
  const [decidingUuids, setDecidingUuids] = useState<string[]>([]); // Array of approval UUIDs currently processing a decision
  const [haveExecutedExternalApproval, setHaveExecutedExternalApproval] = useState(false);
  const [isLoadingExternal, setIsLoadingExternal] = useState(false);
  const [isLoadingPending, setIsLoadingPending] = useState(true);
  const [companyFilterOptions, setCompanyFilterOptions] = useState<{ label: string; value: number }[]>([]);
  const [personFilterOptions, setPersonFilterOptions] = useState<{ label: string; value: string }[]>([]);
  const [companyFilter, setCompanyFilter] = useState(0);
  const [personFilter, setPersonFilter] = useState("-");
  const [yearFilterOptions, setYearFilterOptions] = useState<{ label: string; value: number }[]>([]);
  const [yearFilter, setYearFilter] = useState(0);
  const [monthFilter, setMonthFilter] = useState(0);
  const [companyRoles, setCompanyRoles] = useState<{ companyId: number; companyName: string }[]>([]);

  const userConfigurationQuery = useUserConfiguration();

  const classicApprovalsQuery = useClassicApprovals();
  const advancedApprovalsQuery = useAdvancedApprovals({
    onlyPending: false,
    skip: 0,
    take: 0
  });

  // Update this page on mount
  useConstructor(() => {
    refreshAllApproval();
  });

  const onDecide = (approved: boolean, reportUuid: string, approvalAttemptUuid: string, comment: string) => {
    const decisionDetails = {
      approvalAttemptUuid,
      approved,
      comment,
      language
    };
    setDecidingUuids((current) => [...current, approvalAttemptUuid]);
    reportApprovalApi.decide(decisionDetails).then(() => {
      refreshAllApproval();
      refreshReports();
      setDecidingUuids((current) => current.filter((o) => o !== approvalAttemptUuid));
      showToast({ title: t("success"), text: `${t("theReportWasSuccessfully")} ${approved ? t("approved") : t("disapproved")}`, type: "success" });
    });
  };

  // Decide an advanced approval decision
  // Normally just pass an approvalAttemptUuuid and this function will figure it out
  // If we got here from an external approval, we can grab overrideDecisionUuid and use it directly instead
  // (overrideDecisionUuid is optional and only for use by external decisions)
  const onDecideAdvanced = (approved: boolean, approvalAttemptUuid: string, comment: string, overrideDecisionUuid?: string) => {
    let decisionUuid = overrideDecisionUuid;
    if (!overrideDecisionUuid) {
      let currentAdvancedAttempts = advancedAttempts;
      if (advancedAttempts.length === 0 && advancedApprovalsQuery && advancedApprovalsQuery.data && advancedApprovalsQuery.data.attempts.length > 0) {
        // If this decision was triggered from the inline buttons on the report page, the advancedAttempts state array might not be populated yet since this is done in an effect monitoring the query
        // If we have no current attempts, we cheat and check the query directly to make the inline buttons work
        currentAdvancedAttempts = advancedApprovalsQuery.data.attempts.filter((v, i, a) => a.map((o) => o.uuid).indexOf(v.uuid) === i); // distinct;
      }
      const pendingAttempts = getAttemptsPending(currentAdvancedAttempts);
      const currentPendingAttempt = pendingAttempts.find((o) => o.uuid === approvalAttemptUuid);
      if (currentPendingAttempt) {
        const pendingDecision = getDecisionPending(currentPendingAttempt);
        if (pendingDecision) decisionUuid = pendingDecision.uuid;
      }
    }
    const decisionDetails = {
      advancedApprovalDecisionUuid: decisionUuid || "",
      approved,
      comment,
      language
    };
    if (decisionDetails.advancedApprovalDecisionUuid) {
      setDecidingUuids((current) => [...current, approvalAttemptUuid]);
      reportApprovalApi.decideAdvanced(decisionDetails).then(() => {
        refreshAllApproval();
        refreshReports();
        setDecidingUuids((current) => current.filter((o) => o !== approvalAttemptUuid));
        showToast({ title: t("success"), text: `${t("theReportWasSuccessfully")} ${approved ? t("approved") : t("disapproved")}`, type: "success" });
      });
    }
  };

  // Refresh approval lists
  useEffect(() => {
    // Fetch classic and advanced approvals, put the lists in state
    if (!classicApprovalsQuery.data || !advancedApprovalsQuery.data) return;
    const classicAttempts = classicApprovalsQuery.data.filter((v, i, a) => a.map((o) => o.approvalAttemptUuid).indexOf(v.approvalAttemptUuid) === i); // distinct;
    const advancedAttempts = advancedApprovalsQuery.data.attempts.filter((v, i, a) => a.map((o) => o.uuid).indexOf(v.uuid) === i); // distinct;
    setClassicAttempts(classicAttempts);
    setAdvancedAttempts(advancedAttempts);
    setDeputizations(advancedApprovalsQuery.data.deputizations);
    setIsLoadingPending(false);

    // Get unique companies across all attempts for filter dropdown
    let companyIds = classicAttempts.filter((o) => o.owner.parentType === "company").map((o) => o.owner.parentId);
    companyIds.push(
      ...advancedAttempts
        .filter((o) => o.report && o.report.personParentType === "company" && o.report.personParentId)
        .map((o) => (o.report ? o.report.personParentId : 0))
    );
    companyIds = companyIds.filter((v, i, a) => a.indexOf(v) === i);

    const companyFilterOptionsNew = [{ label: t("approvals.allCompanies"), value: 0 }];
    companyIds.forEach((id) => {
      const company = companyRoles.find((o) => o.companyId === id);
      if (company) {
        companyFilterOptionsNew.push({ label: id + " " + company.companyName, value: id });
      }
    });

    // Get unique persons across all attempts for filter dropdown
    let personEmails = classicAttempts.filter((o) => o.owner.parentType === "company").map((o) => o.owner.email);
    personEmails.push(...advancedAttempts.filter((o) => o.report && o.report.personEmail).map((o) => (o.report ? o.report.personEmail : "")));
    personEmails = personEmails.filter((v, i, a) => a.indexOf(v) === i);

    const personFilterOptionsNew = [{ label: t("approvals.allPersons"), value: "-" }];
    personEmails.forEach((email) => {
      personFilterOptionsNew.push({ label: email, value: email });
    });

    // Get unique years across all attempts
    let years = classicAttempts.map((o) => moment(o.created).year());
    years.push(...advancedAttempts.map((o) => moment(o.created).year()));
    years = years.filter((v, i, a) => a.indexOf(v) === i);
    const yearFilterOptionsNew = years.map((o) => {
      return { label: String(o), value: o };
    });
    yearFilterOptionsNew.unshift({ label: t("approvals.allYears"), value: 0 });

    setCompanyFilterOptions(companyFilterOptionsNew);
    setPersonFilterOptions(personFilterOptionsNew);
    setYearFilterOptions(yearFilterOptionsNew);

    // Check for external approvals from the report page that haven't been executed yet
    if (location.state && location.state.externalApproval && !haveExecutedExternalApproval) {
      // External approval decision was passed in from router, execute the decision and flag as executed
      // Check classic approvals first
      const decisionDetails = location.state.externalApproval;
      const pendingClassicAttemptToDecide = classicAttempts
        .filter((o) => o.steps.find((p) => p.approvalStatus === "nextup" && p.isMe))
        .find((o) => o.reportUuid === decisionDetails.reportUuid);
      const pendingAdvancedAttemptToDecide = getAttemptsPending(advancedAttempts).find(
        (o) => o.report && o.report.uuid === decisionDetails.reportUuid
      );

      if (pendingClassicAttemptToDecide) {
        onDecide(decisionDetails.approved, decisionDetails.reportUuid, pendingClassicAttemptToDecide.approvalAttemptUuid, decisionDetails.comment);
      } else if (pendingAdvancedAttemptToDecide) {
        onDecideAdvanced(decisionDetails.approved, pendingAdvancedAttemptToDecide.uuid, decisionDetails.comment);
      } else if (decisionDetails.reportUuid && decisionDetails.externalDecisionUuid) {
        // The decision UUID and report UUID was provided directly, no need to look up our own queue, just fire it off
        onDecideAdvanced(decisionDetails.approved, "external_dummy_uuid", decisionDetails.comment, decisionDetails.externalDecisionUuid);
      }

      setHaveExecutedExternalApproval(true);

      // Clear ut the location.state.externalApproval, otherwise any report passed in will be re-decided if the user refreshes the page
      // This is necessary because component state (haveExecutedExternalApproval) is cleared on a refresh, but location.state persists
      // We are not actually navigating anywhere, and the API calls above will run and toast back as expected
      history.push("/approval", {
        externalApproval: undefined
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classicApprovalsQuery.data, advancedApprovalsQuery.data, companyRoles, haveExecutedExternalApproval]);

  // Update company roles
  useEffect(() => {
    if (!userConfigurationQuery.data) return;
    const userConfiguration = userConfigurationQuery.data.configuration;

    if (userConfiguration.person && userConfiguration.person.roles) {
      const companyRolesNew = userConfiguration.person.roles
        .filter((o) => o.roleType === "company")
        .map((o) => {
          return { companyId: o.roleForId, companyName: o.entityName };
        });
      setCompanyRoles(companyRolesNew);
    }
  }, [userConfigurationQuery.data]);

  // Load up the report and pass it to the report viewer
  const onView = async (reportUuid: string, approvalAttemptUuid: string) => {
    setIsLoadingExternal(true);
    const external = await reportApi.detailsByApprovalAttempt(approvalAttemptUuid);
    history.push(`/report/${external.report.uuid}/view`, {
      externalReport: external.report,
      externalProductConfig: external.ownerProductConfig
    });
  };

  const onViewAdvanced = async (advancedApprovalAttemptDecisionUuid: string) => {
    setIsLoadingExternal(true);
    const external = await reportApi.detailsByAdvancedApprovalAttemptDecision(advancedApprovalAttemptDecisionUuid);
    history.push(`/report/${external.report.uuid}/view`, {
      externalReport: external.report,
      externalProductConfig: external.ownerProductConfig,
      advancedApprovalAttemptDecisionUuid
    });
  };

  const onRedirectToFlow = async (attemptUuid: string, currentDecisionUuid: string, newFlowId: number) => {
    setDecidingUuids([...decidingUuids, attemptUuid]);
    reportApprovalApi
      .redirectToNewFlow({ currentDecisionUuid, newFlowId })
      .then(() => {
        refreshAllApproval();
        refreshReports();
        setDecidingUuids(decidingUuids.filter((o) => o !== attemptUuid));
        showToast({ title: t("success"), text: `${t("theReportWasSuccessfully")} ${t("approvals.redirectedToFlow")}`, type: "success" });
      })
      .catch((err) => {
        showToast({ type: "error", title: t("error"), text: err?.backendMessage || "", autoClose: 12000 });
        setDecidingUuids(decidingUuids.filter((o) => o !== attemptUuid));
      });
  };

  if (isLoadingExternal || isLoadingPending) {
    // We're either loading a report and prepping to navigate away, or still loading the lists
    // Show an empty list and a spinner
    return (
      <Grid fluid>
        <AttachmentPreview />
        <PageHeader page={"approvals"} />
        <div className="reportapproval-wrapper">
          <Spinner size="75px" margin="1em" />
        </div>
      </Grid>
    );
  }

  // We have the lists and should render them
  let allAdvancedAttempts = advancedAttempts;
  let allClassicAttempts = classicAttempts;

  if (companyFilter > 0) {
    allAdvancedAttempts = allAdvancedAttempts.filter(
      (o) => o.report && o.report.personParentType === "company" && o.report.personParentId === companyFilter
    );
    allClassicAttempts = allClassicAttempts.filter((o) => o.owner.parentType === "company" && o.owner.parentId === companyFilter);
  }
  if (personFilter !== "-") {
    allAdvancedAttempts = allAdvancedAttempts.filter((o) => o.report && o.report.personEmail === personFilter);
    allClassicAttempts = allClassicAttempts.filter((o) => o.owner.email === personFilter);
  }

  if (yearFilter > 0) {
    allAdvancedAttempts = allAdvancedAttempts.filter((o) => moment(o.created).year() === yearFilter);
    allClassicAttempts = allClassicAttempts.filter((o) => moment(o.created).year() === yearFilter);
  }
  if (monthFilter > 0) {
    allAdvancedAttempts = allAdvancedAttempts.filter((o) => moment(o.created).month() + 1 === monthFilter);
    allClassicAttempts = allClassicAttempts.filter((o) => moment(o.created).month() + 1 === monthFilter);
  }

  const pendingClassicAttempts = allClassicAttempts
    .filter((o) => o.steps.find((p) => p.approvalStatus === "nextup" && p.isMe))
    .map((o) => (
      <ApprovalAttemptListItem
        key={o.approvalAttemptUuid}
        approvalAttempt={o}
        previousAttempts={allClassicAttempts.filter((p) => p.reportUuid === o.reportUuid && p.approvalAttemptUuid !== o.approvalAttemptUuid)}
        onDecide={onDecide}
        onView={onView}
        disabled={decidingUuids.includes(o.approvalAttemptUuid)} // Disable buttons if we're currently awaiting the result of a decide call to the API
        isDeciding={decidingUuids.includes(o.approvalAttemptUuid)} // Show spinner if we're currently awaiting the result of a decide call to the API
        active
      />
    ));

  const historicClassicAttempts = allClassicAttempts
    .filter((o) => !o.steps.find((p) => p.approvalStatus === "nextup" && p.isMe))
    .reverse() // Newest first
    .slice(0, 100) // TODO: Paging. We just show the 100 last ones for now
    .map((o) => (
      <ApprovalAttemptListItem
        key={o.approvalAttemptUuid}
        approvalAttempt={o}
        previousAttempts={[]}
        onDecide={onDecide}
        onView={onView}
        disabled
        active={false}
      />
    ));

  const pendingAA = getAttemptsPending(allAdvancedAttempts);
  const pendingAAUuids = pendingAA.map((o) => o.uuid);

  // TODO: Group by report, show previous attempts consecutively
  const pendingAdvancedAttempts = pendingAA.map((o) => (
    <AdvancedApprovalAttemptTimeline
      key={o.uuid}
      attempt={o}
      onDecideAdvanced={onDecideAdvanced}
      onViewAdvanced={onViewAdvanced}
      onRedirectToFlow={onRedirectToFlow}
      disabled={decidingUuids.includes(o.uuid)} // Disable buttons if we're currently awaiting the result of a decide call to the API
      isDeciding={decidingUuids.includes(o.uuid)} // Show spinner if we're currently awaiting the result of a decide call to the API
      active
    />
  ));

  const historicAdvancedAttempts = allAdvancedAttempts
    .filter((o) => !pendingAAUuids.includes(o.uuid))
    .map((o) => <AdvancedApprovalAttemptTimeline key={o.uuid} attempt={o} onViewAdvanced={onViewAdvanced} disabled active={false} />);

  const pendingCount = pendingClassicAttempts.length + pendingAdvancedAttempts.length;
  const historicCount = historicClassicAttempts.length + historicAdvancedAttempts.length;

  const m = moment();
  const monthFilterOptions = [{ label: t("approvals.allMonths"), value: 0 }];
  for (var i = 0; i < 12; i++) {
    monthFilterOptions.push({ label: capitalize(m.month(i).format("MMMM")), value: i + 1 });
  }

  // Switch between queue/history tabs
  const switchView = (eventKey: "pending" | "history") => {
    const path = history.location.pathname;
    if (eventKey === "history" && !path.startsWith("/approval/history")) history.push(`/approval/history`);
    else if (eventKey === "pending" && path.startsWith("/approval/history")) history.push(`/approval`);
  };
  // Check what view to render
  const currentView: string = history.location.pathname.startsWith("/approval/history") ? "history" : "pending";

  return (
    <Grid fluid>
      <AttachmentPreview />
      <PageHeader page={"approvals"} />
      <div className="reportapproval-wrapper">
        <div className="reportapproval-nav-wrapper">
          <Nav bsStyle="tabs" activeKey={currentView} onSelect={(eventKey: any) => switchView(eventKey)}>
            <NavItem eventKey={"pending"}>
              {t("pageHeaders.approvalsPendingPageTitle")} ({pendingCount})
            </NavItem>
            <NavItem eventKey={"history"}>{t("pageHeaders.approvalsDonePageTitle")}</NavItem>
          </Nav>
        </div>
        <div className="reportapproval-filters">
          {companyFilterOptions.length > 1 && (
            <div className="reportapproval-company-filter">
              <SelectGroup
                field="companyFilter"
                label=""
                value={companyFilter}
                onChange={(value: number) => setCompanyFilter(value)}
                options={companyFilterOptions}
              />
            </div>
          )}
          {personFilterOptions.length > 1 && (
            <div className="reportapproval-person-filter">
              <SelectGroup
                field="personFilter"
                label=""
                value={personFilter}
                onChange={(value: string) => setPersonFilter(value)}
                options={personFilterOptions}
              />
            </div>
          )}
          {yearFilterOptions.length > 1 && (
            <div className="reportapproval-year-filter">
              <SelectGroup
                field="yearFilter"
                label=""
                value={yearFilter}
                onChange={(value: number) => setYearFilter(value)}
                options={yearFilterOptions}
              />
            </div>
          )}
          {yearFilterOptions.length > 1 && (
            <div className="reportapproval-month-filter">
              <SelectGroup
                field="monthFilter"
                label=""
                value={monthFilter}
                onChange={(value: number) => setMonthFilter(value)}
                options={monthFilterOptions}
              />
            </div>
          )}
        </div>
        <div className="reportapproval-deputies-list">
          {deputizations.map((d) => (
            <div key={d.id}>
              {d.name} {t("approvals.hasDeputizedYou")}
            </div>
          ))}
        </div>
        {currentView === "pending" && (
          <div className="reportapproval-list">
            {pendingAdvancedAttempts}
            {pendingClassicAttempts}
            {pendingCount === 0 && <Empty text={t("noPendingApprovalsFound")} />}
          </div>
        )}

        {currentView === "history" && (
          <div className="reportapproval-list">
            {historicAdvancedAttempts}
            {historicClassicAttempts}
            {historicCount === 0 && <Empty text={t("noReviewedApprovalsFound")} />}
          </div>
        )}
      </div>
    </Grid>
  );
};

export default ApprovalsPage;
