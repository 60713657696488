import i18n from "../i18n/i18nConfig";
import {
  ExpenseCustomField,
  Report,
  ReportCustomField,
  ValidateReportErrors,
  ValidateReportResult,
  ValidateReportHeaderError,
  UxPolicy
} from "../types";
import { validateExpense } from "./expense";

// Validates a report
// Typically run before persisting the report, and before submitting it
export const validateReport = (
  report: Report,
  enabledReportCustomFields: ReportCustomField[],
  enabledExpenseCustomFields: ExpenseCustomField[],
  uxPolicy?: UxPolicy
): ValidateReportResult => {
  const errors: ValidateReportErrors = {
    header: [],
    reportCustomFields: [],
    expenses: [],
    segments: []
  };

  // Helper to generate required field errors for report header fields
  const requiredFieldError = (fieldName: any): ValidateReportHeaderError => {
    return { fieldName, message: i18n.t("requiredField") };
  };

  // Check if the UX policy specifies any required fields, and add errors if any of those fields have missing values
  const policyFields = uxPolicy?.report?.fields;
  if (policyFields) {
    if (policyFields.advance?.required && report.advance === 0) errors.header.push(requiredFieldError("advance"));
    if (policyFields.comment?.required && report.comment.trim() === "") errors.header.push(requiredFieldError("comment"));
    if (policyFields.companyName?.required && report.companyName.trim() === "") errors.header.push(requiredFieldError("companyName"));
    if (policyFields.description?.required && report.description.trim() === "") errors.header.push(requiredFieldError("description"));
    if (policyFields.purpose?.required && report.purpose.trim() === "") errors.header.push(requiredFieldError("purpose"));
    if (policyFields.referenceNumber?.required && report.referenceNumber.trim() === "") errors.header.push(requiredFieldError("referenceNumber"));
  }

  // Validate required company-specific report customfields
  enabledReportCustomFields
    .filter((field) => field.reportType === 0 || field.reportType === report.reportTypeId)
    .map((field) => {
      // Don't validate bool fields, they're allowed to be unchecked and still be valid
      if (field.required && field.fieldType !== "bool") {
        const rv = report.reportCustomValues.filter((v) => !v.deleted).find((v) => v.reportCustomFieldId === field.id);
        if (!rv || !rv.value || rv.value === "")
          errors.reportCustomFields.push({
            fieldId: field.id,
            message: i18n.t("requiredField")
          });
      }
      return true;
    });

  // Validate expenses on the report
  report.expenses
    .filter((expense) => !expense.deleted)
    .map((expense) => {
      const expenseValidationResult = validateExpense(expense, enabledExpenseCustomFields, uxPolicy);
      if (!expenseValidationResult.isValid) {
        errors.expenses.push();
        const expenseErrors = {
          // uuid: expense.uuid,
          // header: expenseValidationResult.errors.header,
          // expenseCustomFields: expenseValidationResult.errors.expenseCustomFields
          ...expenseValidationResult.errors
        };
        errors.expenses.push(expenseErrors);
      }
      return true;
    });

  // The report is valid if no errors have been found
  const isValid =
    errors.header.length === 0 && errors.reportCustomFields.length === 0 && errors.expenses.length === 0 && errors.segments.length === 0;

  return {
    errors,
    isValid
  };
};
