import { ReportCustomValue } from "../types";
import { jsonFetch } from "./api";

// Save a single reportcustomvalue
export const save = async (reportCustomValue: ReportCustomValue, fetchOpts?: any): Promise<ReportCustomValue> => {
  const fetchOptions = {
    method: "POST",
    body: JSON.stringify(reportCustomValue),
    ...fetchOpts
  };
  return jsonFetch(`e/reportcustomvalue/save`, fetchOptions);
};
