import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import BetterTour from "./BetterTour";
import Icon from "../common/Icon";
import { Button, Row, Col } from "react-bootstrap";
import { TourName } from "../../utils/tours";
// import { setOnboardingTourShown, forceConfigRefresh, resetOnboardingFlags } from "../../shared/api/user";
import { setOnboardingTourShown } from "../../shared/api/user";
import AdminOnboardingWizard from "./AdminOnboardingWizard";
import { useUserConfiguration, refreshUserConfiguration } from "../../shared/queries/queries";
import { useStore } from "../../shared/store/store";
import config from "../../shared/config";

/* A header component for various pages, also handles showing guided tours and the admin onboarding wizard */
export type PageHeaderPage =
  | "homepage"
  | "reports"
  | "expenses"
  | "mileage"
  | "inbox"
  | "newTravelReport"
  | "newExpenseReport"
  | "newDrivingLog"
  | "approvals"
  | "approvals-pending"
  | "approvals-history"
  | "card-transactions"
  | "about"
  | "profile";
interface PageHeaderProps {
  page: PageHeaderPage;
}
const PageHeader = ({ page }: PageHeaderProps) => {
  const [t] = useTranslation();
  const [tourName, setTourName] = useState<TourName | null>(null);
  const [initialTourStarted, setInitialTourStarted] = useState(false);

  const store = useStore();
  const userConfigurationQuery = useUserConfiguration();

  const postponeAdminOnboardingWizard: boolean = useStore((state) => state.clientSpecific.postponeAdminOnboardingWizard);
  let onboardingTourQueued = false;
  let onboardingAdminWizardQueued = false;
  let mailAddresses: string[] = [];

  // When userconfig is loaded...
  if (userConfigurationQuery.data) {
    const personConfig = userConfigurationQuery.data.configuration.person;

    // Queue the admin onboarding wizard if the user is an admin for their parent, has not done it before, and the company is missing either approval or UX policy config
    onboardingAdminWizardQueued =
      page === "homepage" &&
      !postponeAdminOnboardingWizard &&
      personConfig.adminOnboardingStatus &&
      personConfig.adminOnboardingStatus.isAdminForParent &&
      !personConfig.adminOnboardingStatus.onboardingFinished &&
      (personConfig.adminOnboardingStatus.parentMissingApprovalFlow || personConfig.adminOnboardingStatus.parentMissingUxPolicy);

    // Queue the onboarding tour if we haven't shown it before and haven't just started it, and we haven't already queued the admin wizard
    onboardingTourQueued = page === "homepage" && !onboardingAdminWizardQueued && !personConfig.onboardingTourShown && !initialTourStarted;

    // For the inbox page, the header contains information about where to send emails for them to end up in the inbox
    // These addresses are based on the user's mobile number(s) and what environment we're currently in
    const env = store.env;
    let mxDomain: string = config.REACT_APP_INBOX_PRODUCTION_DOMAIN;
    if (env === "dev") mxDomain = config.REACT_APP_INBOX_DEVELOPMENT_DOMAIN;
    if (env === "staging") mxDomain = config.REACT_APP_INBOX_STAGING_DOMAIN;

    mailAddresses = userConfigurationQuery.data.configuration.person.mobileNumbers.map((mob) => {
      if (mob.countryCode === "47") {
        return `${mob.mobileNumber}@${mxDomain}`;
      } else {
        return `00${mob.countryCode}${mob.mobileNumber}@${mxDomain}`;
      }
    });
  }

  const startTour = (tourName: TourName) => {
    setTourName(tourName);
  };

  // console.log("Admin queued: " + onboardingAdminWizardQueued + ", Tour queued: " + onboardingTourQueued);

  // Should we show the onboarding admin wizard?
  if (onboardingAdminWizardQueued) {
    // The user should see the admin wizard
  }

  // Should we show the onboarding tour?
  if (onboardingTourQueued) {
    // The user has never seen the welcome tour
    // Flag it as started locally and in backend, and run it
    setInitialTourStarted(true);
    setOnboardingTourShown().then(() => refreshUserConfiguration());
    startTour("overviewPage");
  }

  const getHeader = (title: string, text: string, subtext?: string, tour?: TourName) => {
    return (
      <React.Fragment>
        {tourName && <BetterTour tourName={tourName} onRequestClose={() => setTourName(null)} />}
        <AdminOnboardingWizard show={onboardingAdminWizardQueued} />
        {/* <div>
          <table>
            <tr>
              <th>onboardingAdminWizardQueued</th>
              <td>{onboardingAdminWizardQueued.toString()}</td>
            </tr>
            <tr>
              <th>onboardingTourQueued</th>
              <td>{onboardingTourQueued.toString()}</td>
            </tr>
            <tr>
              <th>initialTourStarted</th>
              <td>{initialTourStarted.toString()}</td>
            </tr>
            <tr>
              <th>postponeAdminOnboardingWizard</th>
              <td>{postponeAdminOnboardingWizard.toString()}</td>
            </tr>
          </table>
          <Button
            onClick={() =>
              forceConfigRefresh().then(() => {
                refreshUserConfiguration();
              })
            }
          >
            Refresh config (TODO: REMOVE ME!)
          </Button>
          <Button
            onClick={() =>
              resetOnboardingFlags().then(() => {
                refreshUserConfiguration();
              })
            }
          >
            Reset onboarding flags (TODO: REMOVE ME!)
          </Button>
        </div> */}
        <div className="homepage-header">
          <Row>
            <Col xs={8}>
              <div className="homepage-header-title">{title}</div>
              <div className="homepage-header-text">{text}</div>
              {subtext && <div className="homepage-header-text">{subtext}</div>}
            </Col>
            <Col xs={4}>
              {tour && (
                <div className="homepage-header-tourstart">
                  <Button title={t("help")} onClick={() => startTour(tour)}>
                    <Icon icon="help" />
                  </Button>
                </div>
              )}
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  };

  switch (page) {
    case "homepage":
      return getHeader(t("pageHeaders.homePageTitle"), t("pageHeaders.homePageInfo"), "", "overviewPage");
    case "reports":
      return getHeader(t("pageHeaders.reportsPageTitle"), t("pageHeaders.reportsPageInfo"), "", "reportsPage");
    case "expenses":
      return getHeader(t("pageHeaders.expensesPageTitle"), t("pageHeaders.expensesPageInfo"), "", "expensesPage");
    case "mileage":
      return getHeader(t("pageHeaders.mileagesPageTitle"), t("pageHeaders.mileagesPageInfo"), "", "mileagesPage");
    case "inbox":
      return getHeader(
        t("pageHeaders.inboxPageTitle"),
        t("pageHeaders.inboxPageInfo"),
        mailAddresses && mailAddresses.length > 0
          ? t("pageHeaders.inboxPageAddressInfo", {
              mailAddresses: mailAddresses.join(` ${t("or")} `),
              mmsNumber: "+47 41716660"
            })
          : t("pageHeaders.inboxMissingMobileNumber"),
        "inboxPage"
      );
    case "newTravelReport":
      return getHeader(t("pageHeaders.newTravelReportPageTitle"), t("pageHeaders.newTravelReportPageInfo"));
    case "newExpenseReport":
      return getHeader(t("pageHeaders.newExpenseReportPageTitle"), t("pageHeaders.newExpenseReportPageInfo"));
    case "newDrivingLog":
      return getHeader(t("pageHeaders.newDrivingReportPageTitle"), t("pageHeaders.newDrivingReportPageInfo"));
    case "approvals":
      return getHeader(t("pageHeaders.approvalsPageTitle"), t("pageHeaders.approvalsPageInfo"));
    case "approvals-pending":
      return getHeader(t("pageHeaders.approvalsPendingPageTitle"), t("pageHeaders.approvalsPendingPageInfo"));
    case "approvals-history":
      return getHeader(t("pageHeaders.approvalsDonePageTitle"), t("pageHeaders.approvalsDonePageInfo"));
    case "card-transactions":
      return getHeader(t("pageHeaders.cardPageTitle"), t("pageHeaders.cardPageInfo"));
    case "about":
      return getHeader(t("pageHeaders.aboutPageTitle"), t("pageHeaders.aboutPageInfo"));
    case "profile":
      return getHeader(t("pageHeaders.profilePageTitle"), t("pageHeaders.profilePageInfo"));
    default:
      return null;
  }
};

export default PageHeader;
