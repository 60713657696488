import { useTranslation } from "react-i18next";
import { getApiBaseUrl } from "../shared/api/api";
import { PersonRole } from "../shared/types";

// Renders a link to backend admin for a specific admin role
interface RoleLinkProps {
  role: PersonRole;
  token: string;
  language: string;
}
const RoleLink = ({ role, token, language }: RoleLinkProps) => {
  const [t] = useTranslation();
  const entityAddress = role.roleType === "superadmin" ? "" : `${role.roleType}/${role.roleForId}`;
  return (
    <li role="presentation" key={`${role.roleType}|${role.roleForId}`}>
      <form action={`${getApiBaseUrl()}a/${entityAddress}`} method="post">
        <input type="hidden" name="jwt" value={token} />
        <input type="hidden" name="clientUrl" value={`${window.location.origin}`} />
        {/* Backend uses "no", not "nb" */}
        <input type="hidden" name="language" value={language === "nb" ? "no" : language} />
        <input type="hidden" name="fromFrontend" value="1" />
        <input className="roleLink" type="submit" value={`${t(`roles.${role.roleType}`)} - ${role.entityName}`} role="menuitem" />
      </form>
    </li>
  );
};

export default RoleLink;
