import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Button } from "react-bootstrap";
import Uploader from "../common/Uploader";
import AttachablesDrawer from "../common/AttachablesDrawer";
import Icon from "../common/Icon";
import AttachmentThumbnail from "./AttachmentThumbnail";
import { PoseGroup } from "react-pose";
import AttachablesDrawerPoseWrapper from "./AttachablesDrawerPoseWrapper";
import "../../styles/attachments.css";
import { Report, Expense, Attachment, InboxAttachment } from "../../shared/types";
import moment from "moment";

// A component for managing attachments on a report or expense
// This doesn't actually call any API's, it just passes add/change/deletions to the parent component
// It can also render a drawer for picking new attachments
interface AttachmentsProps {
  parent?: Report | Expense;
  parentType?: "report" | "expense";
  attachments: Attachment[];
  readOnly?: boolean;
  showHeader?: boolean;
  headerText?: string;
  vertical?: boolean; // Defaults to rendering horizontally, will stack uploader and thumbnails vertically if specified
  onChangeAttachments?: (attachments: Attachment[], forcePersist: boolean) => void; // Called when the attachment list changes due to an upload or deletion
  enableDrawer?: boolean;
}

const Attachments = ({
  attachments,
  readOnly,
  parent,
  parentType,
  showHeader,
  headerText = "",
  vertical, // Defaults to rendering horizontally, will stack uploader and thumbnails vertically if specified
  onChangeAttachments, // Called when the attachment list changes due to an upload or deletion
  enableDrawer
}: AttachmentsProps) => {
  const [t] = useTranslation();

  const [showAttachablesDrawerInbox, setShowAttachablesDrawerInbox] = useState(false);

  // Add a new attachment to the parent entity
  const onAddAttachments = (addedAttachments: Attachment[]) => {
    const newAttachments = [...attachments, ...addedAttachments];
    onChangeAttachments && onChangeAttachments(newAttachments, true);
  };

  // Delete an attachment from the list
  const onDeleteAttachment = (attachment: Attachment | InboxAttachment) => {
    const now = moment().toISOString();
    const newAttachments = attachments.map((a) => (a.uuid === attachment.uuid ? { ...a, deleted: now, dirty: now } : a));
    onChangeAttachments && onChangeAttachments(newAttachments, true);
  };

  const closeAttachablesInbox = () => {
    setShowAttachablesDrawerInbox(false);
  };

  const visibleAttachments = attachments.filter((attachment) => !attachment.deleted);
  const attachmentCount = visibleAttachments.length;

  const uploader =
    parent && parentType ? <Uploader entityType={parentType} entityUuid={parent.uuid} onAttachmentsCreated={onAddAttachments} /> : null;

  const thumbnails = visibleAttachments.map((attachment) => (
    <AttachmentThumbnail
      key={attachment.uuid}
      attachment={attachment}
      readOnly={readOnly}
      showLabel
      showDelete={!readOnly}
      onDelete={onDeleteAttachment}
    />
  ));

  return (
    <div className="report-component attachments-component">
      {showHeader && (
        <div className="report-component-header">
          <div className="report-component-title">{headerText}</div>

          {!readOnly && enableDrawer && (
            <div className="header-buttons">
              <Button bsStyle="link" onClick={() => setShowAttachablesDrawerInbox(!showAttachablesDrawerInbox)}>
                {showAttachablesDrawerInbox ? <Icon icon="close" /> : <Icon icon="add" />}

                {showAttachablesDrawerInbox ? t("reportDetailsView.closeDrawer") : t("reportDetailsView.pick")}
              </Button>{" "}
            </div>
          )}
        </div>
      )}

      <PoseGroup>
        {!readOnly && enableDrawer && parent && parentType && showAttachablesDrawerInbox && (
          <AttachablesDrawerPoseWrapper key="attachables-drawer-pose-wrapper" className="attachables-drawer-pose-wrapper">
            <AttachablesDrawer
              parent={parent}
              parentType={parentType}
              showTabs={["inbox"]}
              onClose={closeAttachablesInbox}
              hideTargetUuidsInDrawerMode={visibleAttachments.reduce(
                (all: string[], attachment) => [...all, attachment.inboxUuid, attachment.inboxAttachmentUuid],
                []
              )}
            />
          </AttachablesDrawerPoseWrapper>
        )}
      </PoseGroup>

      <div className="attachments">
        {vertical ? (
          <div>
            <Row>
              <Col sm={12}>{!readOnly && uploader}</Col>
            </Row>
            <Row>
              <Col sm={12}>{thumbnails}</Col>
            </Row>
          </div>
        ) : (
          <Row>
            <Col sm={attachmentCount === 0 ? 0 : 6}>
              {thumbnails}
              <div className="clearfix" />
            </Col>
            <Col sm={attachmentCount === 0 ? 12 : 6}>{!readOnly && uploader}</Col>
          </Row>
        )}
      </div>
    </div>
  );
};

export default Attachments;
