import React, { useEffect, useState } from "react";
import { FormGroup, FormControl, ControlLabel, HelpBlock, InputGroup } from "react-bootstrap";
import { InputAutoComplete } from "../../shared/types";

interface TextFieldGroupProps {
  field: string;
  value: string | null; // We will infer a suitable starting value from other props if a null value is passed, but require an explicit null
  label?: string | HTMLElement | JSX.Element;
  error?: string;
  type?: "text" | "password";
  componentClass?: "input" | "textarea";
  rows?: number;
  placeholder?: string;
  readOnly?: boolean;
  hideLabel?: boolean;
  required?: boolean;
  addonLeft?: JSX.Element | HTMLElement | string;
  addonRight?: JSX.Element | HTMLElement | string;
  onChange: (value: string) => void;
  onFocus?: (e: React.FocusEvent<FormControl>) => void;
  onBlur?: (e: React.FocusEvent<FormControl>) => void;
  onEnter?: () => void;
  onEscape?: () => void;
  autoComplete?: InputAutoComplete;
  autoFocus?: boolean;
}

const TextFieldGroup = ({
  field,
  value,
  label,
  error,
  type = "text",
  componentClass = "input",
  rows = 1,
  placeholder,
  readOnly,
  hideLabel,
  required,
  addonLeft,
  addonRight,
  onChange,
  onFocus,
  onBlur,
  onEnter,
  onEscape,
  autoComplete,
  autoFocus
}: TextFieldGroupProps) => {
  const [stateValue, setStateValue] = useState<string>(value || "");
  const [lastGoodValue, setLastGoodValue] = useState<string>(value || "");

  // Update stateValue if the value prop has changed, and is not falsy
  useEffect(() => {
    if (value && value !== stateValue) setStateValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  // If the input is unfocused without a required value, reset it to the last known good value
  const handleBlur = (e: React.FocusEvent<FormControl>) => {
    if (required && (!stateValue || stateValue.trim() === "")) {
      setStateValue(lastGoodValue);
    }
    if (onBlur) onBlur(e);
  };

  // Update stateValue for immediate rendering, but only update lastGoodValue and emit it to parent if the value is sane
  const handleChange = (val: string) => {
    setStateValue(val);
    // Required field but no value, don't pass the value to parent
    if (required && (!val || val.trim() === "")) return;
    // All is good, update lastGoodValue and pass the new value to parent
    setLastGoodValue(val);
    if (onChange) onChange(val);
  };

  const mainCtrl = (
    <FormControl
      onKeyPress={(e) => {
        if (onEnter && e && e.key === "Enter") onEnter();
      }}
      onKeyDown={(e) => {
        // Escape does not work in chrome for onKeyPress, only actual characters
        if (onEscape && e && e.key === "Escape") onEscape();
      }}
      onChange={(e: React.FormEvent<FormControl & HTMLInputElement>) => handleChange(e.currentTarget.value)}
      onFocus={(e) => onFocus && onFocus(e)}
      onBlur={(e) => handleBlur(e)}
      value={stateValue}
      type={type}
      name={field}
      placeholder={placeholder}
      readOnly={readOnly}
      componentClass={componentClass}
      rows={rows}
      autoComplete={autoComplete}
      autoFocus={autoFocus}
    />
  );

  const mainGroup = (
    <InputGroup>
      {addonLeft && <InputGroup.Addon>{addonLeft}</InputGroup.Addon>}
      {mainCtrl}
      {addonRight && <InputGroup.Addon>{addonRight}</InputGroup.Addon>}
    </InputGroup>
  );

  return (
    <FormGroup validationState={error ? "error" : null} controlId={field}>
      {!hideLabel && (
        <ControlLabel>
          {label} {required && <span className="required-asterisk">*</span>}
        </ControlLabel>
      )}

      {addonLeft || addonRight ? mainGroup : mainCtrl}
      {error && <HelpBlock>{error}</HelpBlock>}
    </FormGroup>
  );
};

export default TextFieldGroup;
