import { useEffect, useRef, useState } from "react";
import Spinner from "./Spinner";

// Place this component under a lazy-loading list.
// When it is scrolled into view, if active, it will call loadMoreFunction
interface AutoListLoaderProps {
  loadMoreFunction: () => void;
  active: boolean;
}
const AutoListLoader = ({ loadMoreFunction, active }: AutoListLoaderProps) => {
  const [prevY, setPrevY] = useState(0);
  const loadingRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (active && entry && entry.isIntersecting) {
          // Fire when the IntersectionObserver observes the spinner element scrolling into view
          const y = entry.boundingClientRect.y;
          if (prevY === 0 && entry.rootBounds) {
            // Initial observation after mount
            const rootHeight = entry.rootBounds.height;
            if (y < rootHeight) {
              loadMoreFunction();
            }
          } else {
            // Observation after scrolling
            if (prevY > y) {
              loadMoreFunction();
            }
          }
          setPrevY(y);
        }
      },
      {
        root: null, // Use viewport
        rootMargin: "0px",
        threshold: 0 // Fire immediately when the first pixel of the spinner is scrolled into view
      }
    );
    if (loadingRef.current) {
      observer.observe(loadingRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingRef, active]);

  return (
    <div ref={loadingRef} style={active ? {} : { display: "none" }}>
      <Spinner size="50px" />
    </div>
  );
};

export default AutoListLoader;
