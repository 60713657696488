import { Expense, Report } from "../types";

/**
 * Returns an object whose keys are inbox/inboxAttachment UUIDs, and values indicating which entities they are currently attached to
 * Use as a lookup array when showing the number of attachments per inbox element, hiding inboxes with attachments, etc
 * @param {Report[]} reports - All reports from the useReports query
 * @param {Expense[]} expenses - All standalone expenses from the useExpenses query
 * @returns { [uuid: string]: string[] } An object with inbox/inboxAttachment UUIDs as keys, whose values are string arrays of report/expense UUIDs they are attached to
 */
export const attachmentsByEntities = (reports: Report[], expenses: Expense[], includeDuplicates?: boolean): { [uuid: string]: string[] } => {
  let result: { [uuid: string]: string[] } = {};

  result = reports
    .filter((report) => !report.deleted)
    .reduce((all, report) => {
      report.attachments
        .filter((a) => !a.deleted)
        .forEach((a) => {
          const uuid = a.inboxAttachmentUuid || a.inboxUuid;
          all[uuid] ? all[uuid].push(a.reportUuid) : (all[uuid] = [a.reportUuid]);
        });
      report.expenses
        .filter((e) => !e.deleted)
        .forEach((e) => {
          e.attachments
            .filter((a) => !a.deleted)
            .forEach((a) => {
              const uuid = a.inboxAttachmentUuid || a.inboxUuid;
              all[uuid] ? all[uuid].push(a.expenseUuid) : (all[uuid] = [a.expenseUuid]);
            });
        });
      return all;
    }, result);

  result = expenses
    .filter((expense) => !expense.deleted)
    .reduce((all, expense) => {
      expense.attachments
        .filter((a) => !a.deleted)
        .forEach((a) => {
          const uuid = a.inboxAttachmentUuid || a.inboxUuid;
          all[uuid] ? all[uuid].push(a.expenseUuid) : (all[uuid] = [a.expenseUuid]);
        });
      return all;
    }, result);

  // Only return distinct UUIDs in case of multiple equal attachments
  if (!includeDuplicates) Object.keys(result).forEach((key) => (result[key] = result[key].filter((v, i, a) => a.indexOf(v) === i)));

  return result;
};
