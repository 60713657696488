import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import moment from "moment";
import Map from "../google/Map";
import DeleteModal from "../common/DeleteModal";
import ReportSegmentListItem from "./ReportSegmentListItem";
import { ReportSegment } from "../../shared/types";

interface ReportSegmentListProps {
  segments: ReportSegment[];
  readOnly?: boolean;
  onSwitchSegmentToEdit: (segment: ReportSegment) => void;
  onDeleteSegment: (segment: ReportSegment) => void;
}
const ReportSegmentList = ({ segments, readOnly, onSwitchSegmentToEdit, onDeleteSegment }: ReportSegmentListProps) => {
  const [deletingSegment, setDeletingSegment] = useState<ReportSegment | null>(null);

  const getOverlappingSegmentUuids = () => {
    const ret = [];
    for (const segment of segments) {
      const starts = moment(segment.starts);
      const stops = moment(segment.starts);
      for (const segment2 of segments) {
        if (starts.isBefore(segment2.stops) && stops.isAfter(segment2.starts)) ret.push(...[segment.uuid, segment2.uuid]);
      }
    }
    return ret;
  };

  const deleteSegment = (segment: ReportSegment) => {
    setDeletingSegment(segment);
  };

  const confirmDeleteSegment = () => {
    if (!deletingSegment) return; // Prevent second dispatch if user double clicks
    onDeleteSegment(deletingSegment);
    setDeletingSegment(null);
  };

  const cancelDeleteSegment = () => {
    setDeletingSegment(null);
  };

  // Estimated height of segment blocks plus padding
  const mapHeightPixels = 115 * segments.length + (segments.length > 1 ? (segments.length - 1) * 15 : 0);
  const mapHeight = `${mapHeightPixels}px`;

  const overlappingSegmentUuids = getOverlappingSegmentUuids();

  return (
    <React.Fragment>
      <Row>
        <Col sm={6}>
          {segments.map((segment) => (
            <ReportSegmentListItem
              key={segment.uuid}
              segment={segment}
              readOnly={readOnly || segments.length <= 1}
              onDelete={deleteSegment}
              onEdit={onSwitchSegmentToEdit}
              overlaps={overlappingSegmentUuids.includes(segment.uuid)}
            />
          ))}
        </Col>

        <Col sm={6} xsHidden>
          <div className="segments-map" style={{ height: mapHeight }}>
            <Map segments={segments} cssHeight={mapHeight} />
          </div>
        </Col>
      </Row>
      <DeleteModal show={!!deletingSegment} cancel={cancelDeleteSegment} confirm={confirmDeleteSegment} />
    </React.Fragment>
  );
};

export default ReportSegmentList;
