import { OfficialRateSet } from "../types";
import { jsonFetch } from "./api";

// Fetch all official rates (ever), grouped in sets with valid dateranges. Used for diet calculation
export async function officialRates(): Promise<OfficialRateSet[]> {
  const fetchOptions = {
    method: "GET"
  };
  return jsonFetch("e/officialrates/list", fetchOptions);
}
