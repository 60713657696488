import merge from "lodash/merge";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import config from "../config";
import i18n from "../i18n/i18nConfig";
import { useStore } from "../store/store";
import { SupportedEnvironments, SupportedLanguages } from "../types";
import { queryClient, QK_USER_CONFIGURATION, currentUserId, refreshUserConfiguration } from "../queries/queries";

let apiLanguage = "nb";
let apiAuthorization = "";

// Get the current base URl from store
export const getApiBaseUrl = (): string => {
  const baseUrl = useStore.getState().apiBaseUrl;
  if (isEmpty(baseUrl)) throw new Error("API base url not set! This should be done during app init.");
  return baseUrl;
};

// Get the current full API url from store
export const getApiUrl = (): string => {
  return `${getApiBaseUrl()}api/`;
};

export const getBackendUrlFromEnvironment = (env: SupportedEnvironments): string => {
  if (env === "localhost") return config.REACT_APP_SERVER_LOCALHOST;
  if (env === "dev") return config.REACT_APP_SERVER_DEV;
  if (env === "staging") return config.REACT_APP_SERVER_STAGING;
  if (env === "prod") return config.REACT_APP_SERVER_PROD;
  throw new Error("Invalid environment, no backend URL available");
};

// Set app-wide language for API calls
export const setApiLanguage = (language: SupportedLanguages): void => {
  apiLanguage = language;
};

// Set the current token to be used for all API requests
export const setApiToken = (token: string): void => {
  apiAuthorization = `Bearer ${token}`;
};

// Default options for API requests
export const getFetchBaseOptions = (): RequestInit => {
  const clientType = useStore.getState().clientType;
  const clientPlatform = useStore.getState().clientPlatform;
  const clientVersion = useStore.getState().clientVersion;
  return {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Language: apiLanguage,
      Authorization: apiAuthorization,
      TraveltextClientversion: `${clientType}.${clientPlatform}.${clientVersion}`
    }
  };
};

// Check if the user configuration on the backend has a more recent updated-timestamp than our local one, and refresh query as necessary
const checkForConfigurationUpdates = (response: Response) => {
  if (!response.headers.has("UserconfigLastUpdated")) {
    // Probably an empty response, just return
    return;
  }
  try {
    const userConfigLastUpdatedOnServer = response.headers.get("UserconfigLastUpdated");
    const qs = queryClient.getQueryState([QK_USER_CONFIGURATION, { userId: currentUserId() }]);
    if (qs) {
      const serverMoment = moment(userConfigLastUpdatedOnServer);
      const clientMoment = moment(qs.dataUpdatedAt);
      if (serverMoment.isAfter(clientMoment)) {
        refreshUserConfiguration();
      }
    }
  } catch (err: any) {
    // Missing header, noninitialized query, or something else that can probably be ignored
  }
};

// Pipe responses through here to check if the client is outdated, and execute the client's update callback if required
const checkClientVersion = (response: Response) => {
  if (!response.headers.has("RequiredWebClientVersion") || !response.headers.has("RequiredMobileClientVersion")) return;
  const clientType = useStore.getState().clientType;
  const clientVersion = useStore.getState().clientVersion;
  const requiredVersion = response.headers.get(clientType === "web" ? "RequiredWebClientVersion" : "RequiredMobileClientVersion");
  if (requiredVersion && clientVersion && clientVersion < parseInt(requiredVersion, 10)) {
    const updateCallback = useStore.getState().updateCallback;
    if (updateCallback) updateCallback();
  }
};

// Low-level API fetcher used by jsonfetch and jsonFetchWithServerTime
// Use this function directly instead of just fetch(). This provides:
// - Predictable parsing of responses and errors
// - Promise rejection instead of exceptions when a request fails for any reason
// - Config update signal handling

// If apiCallCompletedHandler is set, *all* responses, successful or not, will be passed to it.
// This allows you to do things like show a re-authentication UI if we hit a 403, reset error messages on a success, etc.
// After passing the response/failure to apiCallCompletedHandler, the promise will be resolved or rejected.

// Queries through RQ will catch errors and fail silently, their errors can either be handled via apiErrorCallback or through RQ's built-in query status checks.
// Direct calls should be trycatched or promise-then/error-wrapped. You can have them fail silently and only deal with errors in the callback, or deal with errors directly.
export const rawFetch = async (address: string, fetchOptions: RequestInit, ignoreConfigurationUpdatedHeader?: boolean): Promise<Response> => {
  const fetchOpts = merge({}, getFetchBaseOptions(), fetchOptions);
  const apiCallCompletedHandler = useStore.getState().apiCallCompletedHandler;
  try {
    // Actual API call
    const response = await fetch(getApiUrl() + address, fetchOpts);

    // If we have a response handler, parse the response and call it
    if (apiCallCompletedHandler) {
      const responseDetails = parseApiResponse(address, fetchOptions, response);
      apiCallCompletedHandler(responseDetails);
    }

    // If the response indicates failure, reject the promise
    if (!response.ok) {
      const errorDetails: ErrorWithBackendMessageAndCode = new ErrorWithBackendMessageAndCode("");
      const extractedError = await extractBackendError(response);
      errorDetails.backendMessage = extractedError.message;
      errorDetails.backendCode = extractedError.code;
      return Promise.reject(errorDetails);
    }

    // If we made it this far, check if there's a client update signal in the response header, and poke the update handler
    checkClientVersion(response);
    if (!ignoreConfigurationUpdatedHeader) {
      checkForConfigurationUpdates(response);
    }

    // Done
    return response;
  } catch {
    // We didn't get a response, probably due to a network/connection failure
    // Parse the result and pass to handler
    if (apiCallCompletedHandler) {
      const responseDetails = parseApiResponse(address, fetchOptions, null);
      apiCallCompletedHandler(responseDetails);
    }
    // Reject the promise
    return Promise.reject(`rawFetch network fail: ${address}`);
  }
};

// Make an API call to a JSON endpoint
export const jsonFetch = async (address: string, fetchOptions: RequestInit, ignoreConfigurationUpdatedHeader?: boolean): Promise<any> => {
  const response = await rawFetch(address, fetchOptions, ignoreConfigurationUpdatedHeader);
  if (!response.headers.has("CONTENT-TYPE") || response.status === 204) {
    // Empty response, just return
    // https://github.com/github/fetch/issues/268
    // Trying to use response.json() on an null/empty body will break our current fetch implementation in react-native.
    // It also seems to inject a content-type header, so that check alone is not good enough. We also have to look at the response code.
    // We strive to use 204 in the API whenever we don't return any content.
    return Promise.resolve();
  }
  return response.json();
};

// Make an API call to a JSON endpoint, but also parse out the servertime header. Use this for calls that should react to config changes
export const jsonFetchWithServerTime = async (
  address: string,
  fetchOptions: RequestInit,
  ignoreConfigurationUpdatedHeader?: boolean
): Promise<{ serverTime: string; body: any }> => {
  const response = await rawFetch(address, fetchOptions, ignoreConfigurationUpdatedHeader);
  let serverTime = "";
  let body = {};
  try {
    if (response.headers.has("ServerTime")) serverTime = response.headers.get("ServerTime") || "";
  } catch (err) {
    console.log("Unable to read serverTime, this should not happen. Defaulting to yesterday.");
    serverTime = moment().add(-1, "d").toISOString();
  }
  if (response.headers.has("CONTENT-TYPE")) body = await response.json();
  return { body, serverTime };
};

export interface ApiResponseDetails {
  success: boolean;
  rawRequestUrl: string;
  rawFetchOptions: RequestInit;
  rawResponse: Response | null;
  networkFailure: boolean;
  httpStatusCode: number;
}

export class ErrorWithBackendMessageAndCode extends Error {
  backendMessage: string;
  backendCode: number;
  constructor(message?: string, code?: number) {
    super(message);
    this.backendMessage = message || "";
    this.backendCode = code || -1;
  }
}

// A failed API call might contain a response, which might contain a JSON body, which might contain a TT error code.
// Attempt to extract and translate the error message, or return an empty string.
// This can be used to supply additional error information when notifying the user about a failed API call.
export const extractBackendError = async (response: Response): Promise<{ message: string; code: number }> => {
  try {
    const json = await response.json();
    if (json.errors && json.errors[0] && json.errors[0].code) {
      const code = json.errors[0].code;
      const i18nkey = `backendErrorCodes.${code}`;
      if (i18n.exists(i18nkey)) {
        const message = i18n.t(i18nkey);
        return { message, code };
      } else {
        return { message: "", code };
      }
    }
    return { message: "", code: -1 };
  } catch {
    return { message: "", code: -1 };
  }
};

// export const apiPaths = {
//   e_report_list: { path: "e/report/list", name: "report list" },
//   e_expense_list: { path: "e/expense/list", name: "expense list" },
//   e_expense_liststandalone: { path: "e/expense/liststandalone", name: "expense_liststandalone" }
// };

// Handle responses from rawFetch, parse into a developer-friendly format suitable for passing to the apiCallCompletedHandler function
export const parseApiResponse = (requestUrl: string, fetchOptions: RequestInit, response: Response | null): ApiResponseDetails => {
  const responseDetails: ApiResponseDetails = {
    success: !!(response && response.ok),
    rawRequestUrl: requestUrl,
    rawFetchOptions: fetchOptions,
    rawResponse: response,
    networkFailure: response === null,
    httpStatusCode: response ? response.status : 0
  };

  // If success, we can just return the result.
  // If we didn't get a response, this is likely a network error or the backend is down. The defaults above has both cases covered, just return here
  if (responseDetails.success || !response) return responseDetails;

  // HTTP 404 Not found: This is fine for blobs, other paths indicates a broken/wrong backend or maybe a typo in the API call
  if (response.status === 404) {
    if (requestUrl.startsWith("e/blob/attachments")) {
      // This is fine
      return responseDetails;
    }
  }

  return responseDetails;
};
