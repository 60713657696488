import { AutoExpenseOverride } from "../types";
import { jsonFetch } from "./api";

// Save a single autoexpenseoverride
export const save = async (autoExpenseOverride: AutoExpenseOverride, fetchOpts?: any): Promise<AutoExpenseOverride> => {
  const fetchOptions = {
    method: "POST",
    body: JSON.stringify(autoExpenseOverride),
    ...fetchOpts
  };
  return jsonFetch(`e/autoexpenseoverride/save`, fetchOptions);
};
