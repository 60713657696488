import moment from "moment";
import { ReportSegment } from "../types";
import { uuid } from "../utils/helpers";
import { getLocationString } from "../utils/helpers";

interface ReportSegmentConstructorOptions {
  reportUuid: string;
  starts: string;
  stops: string;
  countryName?: string;
  countryId?: number;
  cityName?: string;
  cityId?: number;
  endsDietCalculation?: boolean;
  farFromHome?: boolean;
  includeNightExtra?: boolean;
  includeOvernightAbroadExtra?: boolean;
  lodgingType?: number;
  rateDaily?: number;
  rateMaxLodging?: number;
}
export const reportSegmentConstructor = (opts: ReportSegmentConstructorOptions): ReportSegment => {
  const {
    reportUuid = "",
    starts,
    stops,
    countryName = "",
    countryId = 0,
    cityName = "",
    cityId = 0,
    endsDietCalculation = false,
    farFromHome = true,
    includeNightExtra = false,
    includeOvernightAbroadExtra = false,
    lodgingType = 1,
    rateDaily = 0,
    rateMaxLodging = 0
  } = opts;
  const created = moment().toISOString();
  const reportSegmentUuid = uuid();
  return {
    uuid: reportSegmentUuid,
    created,
    changed: "",
    reportUuid,
    starts: starts || created,
    stops: stops || created,
    location: getLocationString(countryName, cityName),
    countryName,
    countryId,
    cityName,
    cityId,
    endsDietCalculation,
    farFromHome,
    includeNightExtra,
    includeOvernightAbroadExtra,
    lodgingType,
    rateDaily,
    rateMaxLodging,
    deleted: "",
    dirty: created
  };
};
