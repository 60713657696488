import React, { Suspense, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import Spinner from "../common/Spinner";
import { acceptEula } from "../../shared/api/user";
import "../../styles/eula.css";
import { refreshUserConfiguration, useUserConfiguration } from "../../shared/queries/queries";

const EulaPageContent = React.lazy(() => import("./EulaPageContent"));

// Show the current EULA
const EulaPage = () => {
  const [t] = useTranslation();
  const history = useHistory();
  const userConfigurationQuery = useUserConfiguration();

  const [loading, setLoading] = useState(false);
  const [redirectAfterAccept, setRedirectAfterAccept] = useState(false);

  const isAuthenticated = !!userConfigurationQuery.data;

  const hasReadEula =
    isAuthenticated && userConfigurationQuery.data && userConfigurationQuery.data.configuration.person
      ? userConfigurationQuery.data.configuration.person.licenceAgreed
      : null;

  // Accept the eula
  const accept = async () => {
    setLoading(true);
    if (await acceptEula()) {
      refreshUserConfiguration();
      setRedirectAfterAccept(true);
    }
  };

  // Redirect after accepting eula
  useEffect(() => {
    if (hasReadEula && redirectAfterAccept) history.push("/#");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasReadEula, redirectAfterAccept]);

  return (
    <div className="eula-outer">
      <div className="eula-inner">
        <Suspense
          fallback={
            <div>
              <Spinner size="4em" />
            </div>
          }
        >
          <EulaPageContent />
        </Suspense>

        <div className="eula-buttons">
          {!loading && isAuthenticated && !hasReadEula && (
            <Button bsStyle="primary" bsSize="lg" onClick={() => accept()}>
              {t("iAccept")}
            </Button>
          )}
          {loading && <Spinner size="4em" />}
        </div>
      </div>
    </div>
  );
};

export default EulaPage;
