import React, { useEffect, useState } from "react";
import { Panel, DropdownButton, MenuItem, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Icon from "../common/Icon";
import DeleteModal from "../common/DeleteModal";
import { capitalize } from "../../shared/utils/helpers";
import ReportListItem from "./ReportListItem";
import AttachablesDrawer from "../common/AttachablesDrawer";
import Empty from "../common/Empty";
import AutoListLoader from "../common/AutoListLoader";
import { PoseGroup } from "react-pose";
import AttachablesDrawerPoseWrapper from "../attachment/AttachablesDrawerPoseWrapper";
import { dateRangeByReport, reportStatusByReport, attachmentsByReport } from "../../shared/utils/reportUtils";
import { saveReport } from "../../shared/queries/queries";
import Spinner from "../common/Spinner";
import "../../styles/lists.css";
import { Report } from "../../shared/types";
import { showToast } from "../../utils/toastWrapper";

// Renders a list of reports
type ReportOrderBy = "changed" | "created" | "daterange" | "description" | "status" | "reportTypeId" | "sum";
interface ReportListProps {
  reports: Report[];
  orderBy?: ReportOrderBy;
  orderDesc?: boolean;
  searchString?: string;
  itemsPerPage?: number;
  title?: string;
  description?: string;
  hideTargetUuidsInDrawerMode?: string[];
  renderMode: "table" | "drawer" | "cards";
  onDrawerSelection?: (report: Report) => void;
  showHeader?: boolean;
  showRefreshButton?: boolean;
  refreshReports?: () => void;
  isLoading?: boolean;
  isFetching?: boolean;
  errorMessage?: string;
}
const ReportList = ({
  reports,
  orderBy: orderByDefault = "changed",
  orderDesc: orderDescDefault = true,
  searchString: searchStringDefault = "",
  itemsPerPage = 20,
  title = "",
  description = "",
  hideTargetUuidsInDrawerMode = [],
  renderMode,
  onDrawerSelection,
  showHeader,
  showRefreshButton,
  refreshReports,
  isLoading,
  isFetching,
  errorMessage
}: ReportListProps) => {
  const [t] = useTranslation();
  const history = useHistory();

  const [orderBy, setOrderBy] = useState<ReportOrderBy>(orderByDefault);
  const [orderDesc, setOrderDesc] = useState(orderDescDefault);
  const [showItems, setShowItems] = useState(itemsPerPage);
  const [searchString, setSearchString] = useState(searchStringDefault);
  const [searching, setSearching] = useState(false);
  const [attachablesParentUuid, setAttachablesParentUuid] = useState("");
  const [deletingReport, setDeletingReport] = useState<Report | null>(null);

  // Supports: changed, created, daterange, description, status, reportTypeId, sum
  const setOrder = (orderKey: ReportOrderBy) => {
    // Sort descending by default for changed, created, daterange
    let orderDescNew = true;
    // Sort ascending by default for the rest
    if (["description", "status", "reportTypeId", "sum"].includes(orderKey)) orderDescNew = false;
    // If we're reordering by the same key as last time, just invert the order
    if (orderKey === orderBy) orderDescNew = !orderDesc;
    setOrderBy(orderKey);
    setOrderDesc(orderDescNew);
  };

  const setFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchStringNew = event.target.value;
    setSearchString(searchStringNew);
  };

  // The list has a local search function, but also accepts a search string from parent
  // Update local search string with anything passed down by parent that is not null or undefined, blank strings are OK
  useEffect(() => {
    if (searchStringDefault !== null && searchStringDefault !== undefined && searchStringDefault !== searchString) {
      setSearchString(searchStringDefault);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchStringDefault]);

  // Build a list of reports based on the current filter and order
  const filteredOrderedReports = () => {
    let orderedReports = [...reports];
    if (orderBy === "changed") {
      // sort reports from it's last changed date.
      orderedReports.sort((a, b) => {
        return moment(a.changed || a.created).unix() - moment(b.changed || b.created).unix();
      });
    } else if (orderBy === "created") {
      orderedReports.sort((a, b) => {
        return moment(a.created || a.changed).unix() - moment(b.created || b.changed).unix();
      });
    } else if (orderBy === "sum") {
      orderedReports.sort((a, b) => (a.calculation.sumTotal < b.calculation.sumTotal ? -1 : 1));
    } else if (orderBy === "daterange") {
      orderedReports.sort((a, b) => (dateRangeByReport(a).starts < dateRangeByReport(b).starts ? -1 : 1));
    } else if (orderBy === "status") {
      orderedReports.sort((a, b) => (reportStatusByReport(a).text < reportStatusByReport(b).text ? -1 : 1));
    } else {
      orderedReports.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));
    }

    if (orderDesc) orderedReports.reverse();
    if (searchString !== "") {
      const searchStringLower = searchString.toLowerCase();
      orderedReports = orderedReports.filter(
        (report) =>
          report.description.toLowerCase().includes(searchStringLower) || report.referenceNumber.toLocaleLowerCase().includes(searchStringLower)
      );
    }
    return orderedReports;
  };

  const orderedReports = filteredOrderedReports();

  const deleteReport = (report: Report) => {
    setDeletingReport(report);
  };

  const confirmDeleteReport = async () => {
    if (!deletingReport) return; // Prevent second dispatch if user double clicks
    const now = moment().toISOString();
    const deletedReport = {
      ...deletingReport,
      deleted: now,
      dirty: now,
      expenses: deletingReport.expenses.map((expense) => {
        return {
          ...expense,
          deleted: expense.deleted || now,
          dirty: expense.deleted ? undefined : now,
          attachments: expense.attachments.map((attachment) => {
            return { ...attachment, deleted: attachment.deleted || now, dirty: attachment.deleted ? undefined : now };
          })
        };
      }),
      attachments: deletingReport.attachments.map((attachment) => {
        return { ...attachment, deleted: attachment.deleted || now, dirty: attachment.deleted ? undefined : now };
      })
    };

    saveReport(deletedReport)
      .then(() => {
        setDeletingReport(null);
      })
      .catch((err: any) => {
        setDeletingReport(null);
        showToast({ type: "error", title: t("notifications.reportDeleteFailed"), text: err?.backendMessage || "" });
      });
  };

  const cancelDeleteReport = () => {
    setDeletingReport(null);
  };

  const showMore = () => {
    setShowItems((prev) => prev + itemsPerPage);
  };

  const showAttachables = (report: Report) => {
    // If the same item is submitted for expansion while already opened, the user probably meant to close it
    const isAlreadyOpen = report.uuid === attachablesParentUuid;
    setAttachablesParentUuid(isAlreadyOpen ? "" : report.uuid);
  };

  const closeAttachables = () => {
    setAttachablesParentUuid("");
  };

  let orderName = t(`ordering.${orderBy}`);
  if (orderBy === "reportTypeId") orderName = t(`ordering.type`);
  const orderTitle = `${t("ordering.by")} ${orderName} ${orderDesc ? t("ordering.descending") : ""}`;

  const header = (
    <Panel.Heading>
      <div className="widgets">
        {searching ? (
          <Button
            className="active-widget"
            onClick={() => {
              setSearching(false);
              setSearchString("");
            }}
          >
            <Icon icon="close" title={t("clearSearch")} />
          </Button>
        ) : (
          <Button onClick={() => setSearching(true)}>
            <Icon icon="search" title={capitalize(t("searchReports"))} />
          </Button>
        )}
        <DropdownButton
          title={<Icon icon="sort" title={`${capitalize(t("ordering.ordering"))}: ${orderTitle}`} />}
          id="bg-nested-dropdown"
          noCaret
          pullRight
          onSelect={(eventKey) => setOrder(eventKey)}
        >
          <MenuItem
            eventKey="created"
            className={orderBy === "created" ? "active-widget list-ordering-button " + (orderDesc ? "order-desc" : "order-asc") : ""}
          >
            {t("ordering.created")}
          </MenuItem>
          <MenuItem
            eventKey="changed"
            className={orderBy === "changed" ? "active-widget list-ordering-button " + (orderDesc ? "order-desc" : "order-asc") : ""}
          >
            {t("ordering.changed")}
          </MenuItem>
          <MenuItem
            eventKey="description"
            className={orderBy === "description" ? "active-widget list-ordering-button " + (orderDesc ? "order-desc" : "order-asc") : ""}
          >
            {t("ordering.description")}
          </MenuItem>
          <MenuItem
            eventKey="status"
            className={orderBy === "status" ? "active-widget list-ordering-button " + (orderDesc ? "order-desc" : "order-asc") : ""}
          >
            {t("ordering.status")}
          </MenuItem>
          <MenuItem
            eventKey="reportTypeId"
            className={orderBy === "reportTypeId" ? "active-widget list-ordering-button " + (orderDesc ? "order-desc" : "order-asc") : ""}
          >
            {t("ordering.type")}
          </MenuItem>
          <MenuItem
            eventKey="sum"
            className={orderBy === "sum" ? "active-widget list-ordering-button " + (orderDesc ? "order-desc" : "order-asc") : ""}
          >
            {t("ordering.sum")}
          </MenuItem>
        </DropdownButton>
      </div>

      {searching ? (
        <div className="search-wrapper">
          <Icon icon="search" />
          <input
            autoFocus // eslint-disable-line
            type="text"
            className="search-box"
            placeholder={capitalize(t("searchReports"))}
            onChange={setFilter}
          />
        </div>
      ) : (
        <div className="info">
          <div className="title">
            {title}
            <span className="item-count">{` (${reports.length.toString()})`}</span>
            {showRefreshButton && (
              <Button
                bsSize="xs"
                onClick={() => refreshReports && refreshReports()}
                className={`refresh ${isFetching ? "spin spin-fast" : ""}`}
                disabled={isFetching}
              >
                <Icon icon="refresh" />
              </Button>
            )}
          </div>
          <div className="description">{description}</div>
        </div>
      )}

      <div className="clearfix" />
    </Panel.Heading>
  );

  const columnNames = (
    <React.Fragment>
      <div className="table-header item-checkbox">&nbsp;</div>
      <div className="table-header item-dates">
        <div
          className={`table-header-clickable ${orderBy === "daterange" ? (orderDesc ? "order-desc" : "order-asc") : ""}`}
          onClick={() => setOrder("daterange")}
        >
          {capitalize(t("tableHeaders.time"))}
        </div>
      </div>
      <div className="table-header item-description">
        <div
          className={`table-header-clickable ${orderBy === "description" ? (orderDesc ? "order-desc" : "order-asc") : ""}`}
          onClick={() => setOrder("description")}
        >
          {capitalize(t("tableHeaders.description"))}
        </div>
      </div>
      <div className="table-header item-type">
        <div
          className={`table-header-clickable ${orderBy === "reportTypeId" ? (orderDesc ? "order-desc" : "order-asc") : ""}`}
          onClick={() => setOrder("reportTypeId")}
        >
          {capitalize(t("tableHeaders.reportType"))}
        </div>
      </div>
      <div className="table-header item-sum">
        <div
          className={`table-header-clickable ${orderBy === "sum" ? (orderDesc ? "order-desc" : "order-asc") : ""}`}
          onClick={() => setOrder("sum")}
        >
          {capitalize(t("tableHeaders.sum"))}
        </div>
      </div>
      <div className="table-header item-status">
        <div
          className={`table-header-clickable ${orderBy === "status" ? (orderDesc ? "order-desc" : "order-asc") : ""}`}
          onClick={() => setOrder("status")}
        >
          {capitalize(t("tableHeaders.reportStatus"))}
        </div>
      </div>
      <div className="table-header item-thumbnails">&nbsp;</div>
      <div className="table-header item-actions">&nbsp;</div>
    </React.Fragment>
  );

  const visibleReports = orderedReports
    .filter((report) => renderMode !== "drawer" || !hideTargetUuidsInDrawerMode.includes(report.uuid))
    .slice(0, showItems);

  let listClass = "list table";
  if (renderMode === "cards") listClass = "list cards";
  if (renderMode === "drawer") listClass = "list drawer";

  const renderedVisibleReports = visibleReports.map((report, index) => {
    const attachments = attachmentsByReport(report);
    const readOnly = report.deleted || report.status === 2;

    return (
      <React.Fragment key={report.uuid}>
        <ReportListItem
          report={report}
          onDelete={deleteReport}
          onEdit={(report: Report) => history.push(`/report/${report.uuid}`)}
          renderMode={renderMode}
          alternate={renderMode === "table" && index % 2 === 0}
          onDrawerSelection={!readOnly && onDrawerSelection ? onDrawerSelection : undefined}
          onShowAttachables={showAttachables}
          attachments={attachments}
        />

        <PoseGroup>
          {attachablesParentUuid === report.uuid && (
            <AttachablesDrawerPoseWrapper key="attachables-drawer-pose-wrapper" className="attachables-drawer-pose-wrapper">
              <AttachablesDrawer
                parentType="report"
                parent={report}
                showTabs={["expense", "inbox"]}
                onClose={closeAttachables}
                hideTargetUuidsInDrawerMode={attachments.reduce(
                  (all: string[], attachment) => [...all, attachment.inboxUuid, attachment.inboxAttachmentUuid],
                  []
                )}
              />
            </AttachablesDrawerPoseWrapper>
          )}
        </PoseGroup>
      </React.Fragment>
    );
  });

  const spinner = <Spinner size="75px" margin="1em" />;

  return (
    <div className="report-list">
      <Panel>
        {showHeader && header}
        {errorMessage && <div className="list-load-error">{errorMessage}</div>}
        <div className={listClass}>
          {renderMode === "table" && visibleReports.length > 0 && columnNames}
          {isLoading ? spinner : renderedVisibleReports}
        </div>

        {!isLoading && orderedReports.length === 0 && <Empty text={t("noReportsFound")} />}
      </Panel>

      <AutoListLoader active={showItems < orderedReports.length} loadMoreFunction={showMore} />

      <DeleteModal show={!!deletingReport} cancel={cancelDeleteReport} confirm={confirmDeleteReport} />
    </div>
  );
};

export default ReportList;
