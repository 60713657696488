import { ReceiptAnalysis } from "../types";
import { jsonFetch } from "./api";

// OCR-analyze an attachment in blob storage (works for images and PDFs)
export const analyzeBlob = async (blobuuid: string): Promise<ReceiptAnalysis> => {
  const fetchOptions = {
    method: "GET"
  };
  const path = `e/blob/attachments/${blobuuid}/analyze`;
  return jsonFetch(path, fetchOptions);
};
