import moment from "moment";
import i18n from "../i18n/i18nConfig";
import cloneDeep from "lodash/cloneDeep";

import { rateBasedExpenseValues } from "../../shared/utils/reportCalculation/calculator";
import { currentExpenseTypes } from "../utils/currentExpenseTypes";
import { expenseConstructor, ExpenseConstructorOptions } from "../constructors/expenseConstructor";
import { expenseCustomValueConstructor } from "../constructors/expenseCustomValueConstructor";
import { uuid, capitalize } from "../utils/helpers";
import {
  Expense,
  ExpenseCustomValue,
  ExpenseType,
  UserConfiguration,
  ProductConfiguration,
  ExpenseCustomField,
  ReceiptAnalysis,
  Mileage,
  Tollstation,
  Report
} from "../types";
import { queryClient, QK_REPORT_LIST, QK_EXPENSE_LIST, currentUserId } from "../queries/queries";
import { exchangeRates } from "../api/exchangeRates";

// Use an OCR analysis result to create an expense
interface ExpenseFromAnalysisOptions {
  personId: number;
  analysis: ReceiptAnalysis;
  userConfig: UserConfiguration;
}
export const getExpenseFromAnalysis = async (opts: ExpenseFromAnalysisOptions): Promise<Expense> => {
  const { personId, analysis, userConfig } = opts;
  const expenseTypes = currentExpenseTypes(userConfig);
  const defaultExpenseType = expenseTypes.find((et) => et.is_Default) || expenseTypes[0];
  const defaultExpenseTypeId = defaultExpenseType.id;
  const nonVatEnabled = userConfig.product.nonVatEnabled ? true : false;
  const refund = userConfig.product.defaultRefund;

  // If we found a expensetype that is enabled for this user, use that. If not, use the user's default.
  const expenseType = analysis.expenseType?.id && expenseTypes.find((et) => et.id === analysis.expenseType?.id);
  const expenseTypeId = expenseType ? expenseType.id : defaultExpenseTypeId;

  // Use the transaction date from analysis, or default to today
  const starts = (analysis.date ? moment(analysis.date.value) : moment()).toISOString();

  // Handle sums and currency, default to NOK
  const units = 1;
  const currency = analysis.currency ? analysis.currency.value : "NOK";
  const sumHundredths = analysis.sum?.value ? analysis.sum.value * 100 : 0;
  const unitPrice = sumHundredths;
  let unitPriceNok = sumHundredths;
  if (currency !== "NOK") {
    // We're on a foreign currency, use calculated NOK price
    const rates = await exchangeRates(starts);
    const rate = rates.find((o) => o.code.toUpperCase() === currency.toUpperCase());
    const factor = rate ? rate.rate : 1;
    unitPriceNok = (sumHundredths || 0) * factor;
  }

  // Use vendorName as description, or default to hardcoded text
  const description = analysis.vendorName?.value.trim() || i18n.t("expenseFromReceiptDescription");

  // Use vatPercent from analysis, fall back to the expensetype vat, then fall back to default expensetype vat, and lastly to 0
  // Constructor will calculate the vat sum, so ignore that
  let vatPercent = 0;
  if (analysis.vatPercent) {
    vatPercent = analysis.vatPercent.value;
  } else {
    if (expenseType) {
      vatPercent = expenseType.vatPercent;
    } else {
      const defaultET = expenseTypes.find((et) => et.id === defaultExpenseTypeId);
      if (defaultET) vatPercent = defaultET.vatPercent;
    }
  }
  // Ignore everything and reset the vat if this flag is set in config
  if (nonVatEnabled) vatPercent = 0;

  const expenseStub: ExpenseConstructorOptions = {
    expenseTypeId,
    starts,
    ends: starts,
    units,
    currency,
    unitPrice,
    unitPriceNok,
    description,
    vatPercent,
    personId,
    refund
  };
  const expense = expenseConstructor(expenseStub);
  return expense;
};

// Get the current total number of commuter kilometers driven in a calendar year
// Include all non-deleted expenses with the correct customvalue indicating a commuter trip
// This number will affect which rate to use for a new commuter driving expense
export const getCommuterKilometersDrivenInYear = (userConfig: UserConfiguration, year: number = new Date().getFullYear()) => {
  try {
    const yearString = year.toString();

    // Get the reports and standalone expenses from queries
    const reportsData: any = queryClient.getQueryData([QK_REPORT_LIST, { userId: currentUserId() }]);
    const expensesData: any = queryClient.getQueryData([QK_EXPENSE_LIST, { userId: currentUserId() }]);

    const reports: Report[] = reportsData ? reportsData.reports : [];
    const standaloneExpenses: Expense[] = expensesData ? expensesData.expenses : [];

    // Find the customfield indicating a commuter trip. If there is none, just return 0
    const cf = userConfig.product.expenseCustomFields.find((o) => o.systemName === "driving_commuter");
    if (!cf) return 0;

    const drivingTypeIds = userConfig.product.expenseTypes.filter((o) => o.is_Driving).map((o) => o.id);

    // Get all expenses on reports
    const reportExpenses = reports
      .filter((report) => !report.deleted)
      .reduce((allExpenses: Expense[], report) => [...allExpenses, ...report.expenses.filter((expense) => !expense.deleted)], []);

    // Using reportExpenses and standaloneExpenses, sum up all kilometers on expenses where the commuter customvalue is set
    const kilometers = [...reportExpenses, ...standaloneExpenses]
      .filter((expense) => !expense.deleted && drivingTypeIds.includes(expense.expenseTypeId) && expense.starts.startsWith(yearString))
      .filter((expense) => !!expense.expenseCustomValues.find((cv) => !cv.deleted && cv.expenseCustomFieldId === cf.id && cv.value === "1"))
      .reduce((total, expense) => (total += expense.units), 0);
    return kilometers;
  } catch {
    return 0;
  }
};

// Clone an expense including customvalues and attachments
// The clone and all its children will get new UUIDs, parent UUIDs, an empty groupUuid/mileageUuid, and an updated dirty timestamp
export const getExpenseClone = (expense: Expense): Expense => {
  const clone = cloneDeep(expense);
  const newUuid = uuid();
  const now = moment().toISOString();
  clone.uuid = newUuid;
  clone.dirty = now;
  clone.groupUuid = "";
  clone.mileageTrackUuid = "";
  clone.expenseCustomValues.forEach((o) => (o.uuid = uuid()));
  clone.expenseCustomValues.forEach((o) => (o.expenseUuid = newUuid));
  clone.expenseCustomValues.forEach((o) => (o.dirty = now));
  clone.attachments.forEach((o) => (o.uuid = uuid()));
  clone.attachments.forEach((o) => (o.expenseUuid = newUuid));
  clone.attachments.forEach((o) => (o.dirty = now));
  return clone;
};

// Pass in either availableExpenseTypes (array of expenseTypes) + expenseTypeId, expenseType (single expenseType, if known), or expenseTypeText (just the expenseType description)
// This function will try to find a matching expenseType and return a localized description.
// The point of this function is to help both expenselist and expenseitems render a proper description even if the expensetype does not exist or is not available for the current user
// This can happen if a user is moved from a company with a custom expensetype to a company without it
interface GetExpenseTypeDescriptionOptions {
  availableExpenseTypes?: ExpenseType[];
  expenseType?: ExpenseType;
  expenseTypeText?: string;
  expenseTypeId?: number;
}
export const getExpenseTypeDescription = (opts: GetExpenseTypeDescriptionOptions): string => {
  const { availableExpenseTypes, expenseType, expenseTypeText, expenseTypeId } = opts;

  // Look for a description in the following order
  let desc = "";
  if (expenseTypeText) {
    desc = expenseTypeText.toLowerCase();
  } else if (expenseType) {
    desc = expenseType.description.toLowerCase();
  } else if (availableExpenseTypes && expenseTypeId) {
    // Does the ID match any available types?
    const et = availableExpenseTypes && availableExpenseTypes.find((o) => o.id === expenseTypeId);
    if (et) desc = et.description.toLowerCase();
  }

  // If nothing matched, return the localized version of "unknown"
  if (!desc || desc === "") return capitalize(i18n.t("expenseTypes.unknown"));

  // Attempt to localize the one we found, or if it's a custom one we can't translate, just return the raw expenseType description
  return i18n.exists(`expenseTypes.${desc}`) ? capitalize(i18n.t(`expenseTypes.${desc}`)) : desc;
};

// When the customvalues for a driving expense is changed, it could either be:
// - an informational change (driving_fromaddress, driving_toaddress, etc),
// - something that actually affects the kilometer rate calculation (driving_electriccar, driving_commuter, etc)
// The expense editor needs to know this in order to know whether it should keep the current kmrate (which could already be changed by the user), or recalculate it based on the new customvalues.

// This is the static list of customfield systemnames that affect calculation
export const drivingCustomFieldSystemNamesAffectingCalculation = [
  "driving_electriccar",
  "driving_commuter",
  "driving_boatover50hk",
  "driving_othertransports",
  "driving_motorcycle",
  "driving_hanger",
  "driving_forestroad",
  "driving_tromsoextra",
  "driving_passengers"
];
// .. and this function takes the old and new customvalues for an expense, as well as the customfields, and returns a simple bool to indicate whether this change should trigger a recalculation
export const drivingCustomValueChangesTriggersKmRateRecalculation = (
  expenseCustomFields: ExpenseCustomField[],
  oldCustomValues: ExpenseCustomValue[],
  newCustomValues: ExpenseCustomValue[]
): boolean => {
  for (const field of expenseCustomFields.filter((f) => f.enabled && drivingCustomFieldSystemNamesAffectingCalculation.includes(f.systemName))) {
    const oldValue = oldCustomValues.find((f) => f.expenseCustomFieldId === field.id);
    const newValue = newCustomValues.find((f) => f.expenseCustomFieldId === field.id);
    if ((oldValue && !newValue) || (!oldValue && newValue)) return true; // This value was added or removed
    if (oldValue && newValue && oldValue.value !== newValue.value) return true; // This value was changed
  }
  return false;
};

// Using values from the core calculator and the currently selected customValues from the expense, figure out what the current rate per kilometer should be
// The date provided should be the expense's (start) date, in order to pull the correct historical rates
// If you pass in overrideKmrate, it will be returned as kmRate and the tax parts will be based on it
interface KilometerRates {
  kmRate: number;
  taxfreePart: number;
  taxablePart: number;
}
export const calculateKmPriceFromCustomValues = (
  expenseCustomFields: ExpenseCustomField[],
  customValues: ExpenseCustomValue[],
  date: string,
  productConfiguration: ProductConfiguration,
  commuterKilometersDrivenThisYear: number,
  onCommuterWarning?: Function, // Poke this callback to set/reset any commuter limit warning messages
  overrideKmrate?: number
): KilometerRates => {
  // Anti-verbosity
  const cf = expenseCustomFields;
  const cv = customValues;

  const kmRateCustom = productConfiguration.defaultKmPrice
    ? Math.round(parseFloat(productConfiguration.defaultKmPrice.replace(",", ".")) * 100)
    : undefined;
  const kmRateElectricCarCustom = productConfiguration.defaultKmPriceElectricCar
    ? Math.round(parseFloat(productConfiguration.defaultKmPriceElectricCar.replace(",", ".")) * 100)
    : undefined;

  // Pull the rates based on date
  const rates = rateBasedExpenseValues(date);

  // This is the default rate per kilometer, and should be overriden based on customvalues
  let kmRate = rates.driving_Km_Hundredths;
  let kmRateElectric = rates.driving_ElectricCar_Km_Hundredths;
  let taxfreePart = rates.driving_Km_TaxFree_Hundredths;
  let taxablePart = (overrideKmrate ? overrideKmrate : kmRate) - taxfreePart;

  // The default kmrate can be overridden through company configuration. Check if it exists and wrangle into a number
  if (kmRateCustom) kmRate = kmRateCustom;
  if (kmRateElectricCarCustom) kmRateElectric = kmRateElectricCarCustom;

  // Find all related customfields, if we have them
  const f_electriccar = cf.find((o) => o.systemName === "driving_electriccar");
  const f_commuter = cf.find((o) => o.systemName === "driving_commuter");
  const f_boatover50hk = cf.find((o) => o.systemName === "driving_boatover50hk");
  const f_othertransports = cf.find((o) => o.systemName === "driving_othertransports");
  const f_motorcycle = cf.find((o) => o.systemName === "driving_motorcycle");
  const f_hanger = cf.find((o) => o.systemName === "driving_hanger");
  const f_forestroad = cf.find((o) => o.systemName === "driving_forestroad");
  const f_tromsoextra = cf.find((o) => o.systemName === "driving_tromsoextra");
  const f_passengers = cf.find((o) => o.systemName === "driving_passengers");

  // Some tracking vars we'll use to calculate taxfree/taxable parts afterwards
  let isBoat = false;
  let isOtherTrans = false;
  let isMotorcycle = false;
  let isCommuter = false;
  let numPassengers = 0;
  let isHanger = false;
  let isForest = false;

  // First, look for values that replace the base unit cost (electriccar, commuter, boatover50hk, othertransport, motorcycle)

  // Does the field for electric car exist?
  if (f_electriccar) {
    // Do we have a customvalue for it?
    const v = cv.find((o) => o.expenseCustomFieldId === f_electriccar.id);
    // Is that customvalue truish enough?
    if (v && v.value && v.value !== "0") {
      // Override the rate per kilometer
      kmRate = kmRateElectric;
    }
  }

  // .. and so on for the other ones
  let newCommuterWarning = "";
  if (f_commuter) {
    const v = cv.find((o) => o.expenseCustomFieldId === f_commuter.id);
    if (v && v.value && v.value !== "0") {
      kmRate = rates.driving_Commuter_Km_Hundredths;
      const kmLimit = rates.driving_Commuter_LimitA_Km;
      if (kmLimit > 0) {
        const warningThreshold = kmLimit * 0.9;
        if (commuterKilometersDrivenThisYear > warningThreshold) {
          newCommuterWarning = i18n.t("expenseEditorView.commuterWarningApproachingLimit", {
            currentKm: commuterKilometersDrivenThisYear,
            remainingKm: (kmLimit - commuterKilometersDrivenThisYear).toString()
          });
        }
        if (commuterKilometersDrivenThisYear >= kmLimit) {
          newCommuterWarning = i18n.t("expenseEditorView.commuterWarningExceededLimit", {
            currentKm: commuterKilometersDrivenThisYear,
            maxKm: kmLimit.toString()
          });
          kmRate = rates.driving_Commuter_Over_LimitA_Km_Hundredths;
        }
      }
      isCommuter = true;
    }
  }
  if (onCommuterWarning && rates.driving_Commuter_LimitA_Km > 0) {
    // Only poke the callback if a limit exists
    onCommuterWarning(newCommuterWarning);
  }

  if (f_boatover50hk) {
    const v = cv.find((o) => o.expenseCustomFieldId === f_boatover50hk.id);
    if (v && v.value && v.value !== "0") {
      kmRate = rates.driving_BoatOver50hk_Km_Hundredths;
      isBoat = true;
    }
  }
  if (f_othertransports) {
    const v = cv.find((o) => o.expenseCustomFieldId === f_othertransports.id);
    if (v && v.value && v.value !== "0") {
      kmRate = rates.driving_OtherTransports_Km_Hundredths;
      isOtherTrans = true;
    }
  }
  if (f_motorcycle) {
    const v = cv.find((o) => o.expenseCustomFieldId === f_motorcycle.id);
    if (v && v.value && v.value !== "0") {
      kmRate = rates.driving_Motorcycle_Km_Hundredths;
      isMotorcycle = true;
    }
  }

  // That's all the values that trigger replacement
  // Next, look for values that trigger *addons* to the base unit cost (passengers, hanger, forestroad, tromsoextra)

  if (f_passengers) {
    const v = cv.find((o) => o.expenseCustomFieldId === f_passengers.id);
    if (v && v.value && v.value !== "0") {
      const passengers = Number.parseInt(v.value, 10);
      if (!isNaN(passengers)) {
        kmRate += passengers * rates.driving_Passengers_Km_Hundredths;
        numPassengers = passengers;
      }
    }
  }
  if (f_hanger) {
    const v = cv.find((o) => o.expenseCustomFieldId === f_hanger.id);
    if (v && v.value && v.value !== "0") {
      kmRate += rates.driving_Hanger_Km_Hundredths;
      isHanger = true;
    }
  }
  if (f_forestroad) {
    const v = cv.find((o) => o.expenseCustomFieldId === f_forestroad.id);
    if (v && v.value && v.value !== "0") {
      kmRate += rates.driving_ForestRoad_Km_Hundredths;
      isForest = true;
    }
  }
  if (f_tromsoextra) {
    const v = cv.find((o) => o.expenseCustomFieldId === f_tromsoextra.id);
    if (v && v.value && v.value !== "0") {
      kmRate += rates.driving_TromsoExtra_Km_Hundredths;
    }
  }

  if (isBoat || isOtherTrans || isCommuter || isMotorcycle) {
    // For these categories, the whole sum is always tax free
    taxfreePart = overrideKmrate ? overrideKmrate : kmRate;
    taxablePart = 0;
  } else {
    // Figure out how much is taxable per unit (total unitprice - passenger extra - hanger extra - forest extra - taxfree part), then subtract that from the full sum
    taxablePart = overrideKmrate ? overrideKmrate : kmRate;
    if (numPassengers > 0) taxablePart -= rates.driving_Passengers_Km_Hundredths * numPassengers;
    if (isHanger) taxablePart -= rates.driving_Hanger_Km_Hundredths;
    if (isForest) taxablePart -= rates.driving_ForestRoad_Km_Hundredths;
    taxablePart -= rates.driving_Km_TaxFree_Hundredths;
    if (taxablePart < 0) taxablePart = 0;
    taxfreePart = (overrideKmrate ? overrideKmrate : kmRate) - taxablePart;
  }

  const ret = {
    kmRate: overrideKmrate ? overrideKmrate : kmRate,
    taxfreePart,
    taxablePart
  };
  return ret;
};

// When the expense recalculation code hits a change that affects a driving expense,
// customValues must be piped through here in order to update the magic taxfree and taxable values
export const injectDrivingTaxCustomValues = (
  expenseUuid: string,
  kmprices: KilometerRates,
  units: number,
  customValues: ExpenseCustomValue[],
  customFieldDrivingTaxfree?: ExpenseCustomField,
  customFieldDrivingTaxable?: ExpenseCustomField
): ExpenseCustomValue[] => {
  const now = moment().toISOString();
  const customValuesNew = [...customValues];
  // If the customfields for taxfree and taxable part of driving exists, they must be populated
  if (customFieldDrivingTaxfree && customFieldDrivingTaxable) {
    const taxfreeValue = ((kmprices.taxfreePart * units) / 100).toFixed(2);
    const taxableValue = ((kmprices.taxablePart * units) / 100).toFixed(2);

    // Customvalue for taxfree part
    const indexTaxfree = customValuesNew.findIndex((o) => o.expenseCustomFieldId === customFieldDrivingTaxfree.id);
    if (indexTaxfree > -1) {
      // Update
      customValuesNew[indexTaxfree].value = taxfreeValue;
      customValuesNew[indexTaxfree].dirty = now;
    } else {
      // Insert
      const cvTaxfree = createExpenseCustomValue(expenseUuid, customFieldDrivingTaxfree.id, taxfreeValue);
      customValuesNew.push(cvTaxfree);
    }

    // Customvalue for taxable part
    const indexTaxable = customValuesNew.findIndex((o) => o.expenseCustomFieldId === customFieldDrivingTaxable.id);
    if (indexTaxable > -1) {
      // Update
      customValuesNew[indexTaxable].value = taxableValue;
      customValuesNew[indexTaxable].dirty = now;
    } else {
      // Insert
      const cvTaxable = createExpenseCustomValue(expenseUuid, customFieldDrivingTaxable.id, taxableValue);
      customValuesNew.push(cvTaxable);
    }
  }
  return customValuesNew;
};

// Expense Customvalue constructor wrapper provided for use by children
export const createExpenseCustomValue = (expenseUuid: string, expenseCustomFieldId: number, value: string): ExpenseCustomValue => {
  const customValue = expenseCustomValueConstructor({
    expenseUuid,
    expenseCustomFieldId,
    value
  });
  return customValue;
};

// Use a mileage to create an expense
export const getExpenseFromMileage = (userConfig: UserConfiguration, drivingExpenseType: ExpenseType, mileage: Mileage, description?: string) => {
  // Shorthand
  const expenseCustomFields = userConfig.product.expenseCustomFields;
  // These customfields should be populated if they exist
  const customFieldFromAddress = expenseCustomFields.find((field) => field.enabled && field.systemName === "driving_fromaddress");
  const customFieldToAddress = expenseCustomFields.find((field) => field.enabled && field.systemName === "driving_toaddress");
  // The tax fields too, but they are not enabled so as to hide them from the user (usually)
  const customFieldTaxfreePart = expenseCustomFields.find((field) => field.systemName === "driving_taxfree_part");
  const customFieldTaxablePart = expenseCustomFields.find((field) => field.systemName === "driving_taxable_part");

  // Convert distance to kilometers
  const kilometers = parseInt((mileage.totalMeters / 1000).toFixed(0), 10);

  // Grab the standard mileage rates
  const rates = rateBasedExpenseValues(mileage.starts);
  let kmRate = rates.driving_Km_Hundredths;

  // The default kmrate can be overridden through company configuration. Check if it exists and wrangle into a number
  // If we add electric car rates or other customvalue modifiers to the kmrate in the future, these need to be dealt with here
  // See webclient expense editor for reference implementation
  if (userConfig.product.defaultKmPrice) {
    kmRate = Math.round(parseFloat(userConfig.product.defaultKmPrice.replace(",", ".")) * 100);
  }

  const refund = userConfig.product.defaultRefund;

  const expense = expenseConstructor({
    expenseTypeId: drivingExpenseType.id,
    personId: mileage.personId,
    mileageTrackUuid: mileage.uuid,
    description,
    starts: mileage.starts,
    ends: mileage.stops,
    units: kilometers,
    unitName: "Km",
    unitPrice: kmRate,
    unitPriceNok: kmRate,
    vatPercent: 0,
    receipt: false,
    refund
  });

  // Create customvalues for the from- and to-addresses, if the fields exist
  const expenseCustomValues = [];
  if (customFieldFromAddress) {
    const cvFrom = expenseCustomValueConstructor({
      expenseUuid: expense.uuid,
      expenseCustomFieldId: customFieldFromAddress.id,
      value: mileage.addressFrom
    });
    expenseCustomValues.push(cvFrom);
  }
  if (customFieldToAddress) {
    const cvTo = expenseCustomValueConstructor({
      expenseUuid: expense.uuid,
      expenseCustomFieldId: customFieldToAddress.id,
      value: mileage.addressTo
    });
    expenseCustomValues.push(cvTo);
  }

  // Create customvalues for the taxfree/taxable parts, if the fields exist
  if (customFieldTaxfreePart && customFieldTaxablePart) {
    // The taxfree part is up to the kmRate, and taxable part is whatever remains
    let taxfreePart = rates.driving_Km_TaxFree_Hundredths;
    if (taxfreePart > kmRate) taxfreePart = kmRate;
    let taxablePart = kmRate - taxfreePart;

    const taxfreeValue = ((taxfreePart * kilometers) / 100).toFixed(2);
    const taxableValue = ((taxablePart * kilometers) / 100).toFixed(2);

    const cvTaxfree = expenseCustomValueConstructor({
      expenseUuid: expense.uuid,
      expenseCustomFieldId: customFieldTaxfreePart.id,
      value: taxfreeValue
    });
    const cvTaxable = expenseCustomValueConstructor({
      expenseUuid: expense.uuid,
      expenseCustomFieldId: customFieldTaxablePart.id,
      value: taxableValue
    });

    expenseCustomValues.push(cvTaxfree);
    expenseCustomValues.push(cvTaxable);
  }

  // Add customvalues to the expense
  expense.expenseCustomValues = expenseCustomValues;

  // Return the expense. Remember to save it, and call refreshExpenses()
  return expense;
};

// Use a toll station passing in a mileage to create an expense
export const createExpenseFromTollStationPassing = (
  station: Tollstation,
  mileage: Mileage,
  tollstationExpenseType: ExpenseType,
  userConfig?: UserConfiguration
) => {
  // We hardcode the default charge for now. When we allow the user to define their car type in the future, this should be changed to look at other station fields
  const chargeNokHundredths = station.chargeSmallCar * 100;

  const refund = userConfig ? userConfig.product.defaultRefund : true;
  const expense = expenseConstructor({
    expenseTypeId: tollstationExpenseType.id,
    personId: mileage.personId,
    mileageTrackUuid: mileage.uuid,
    description: `${station.name} (${station.type})`,
    starts: mileage.starts,
    ends: mileage.stops,
    units: 1,
    unitName: "Antall",
    unitPrice: chargeNokHundredths,
    unitPriceNok: chargeNokHundredths,
    vatPercent: tollstationExpenseType.vatPercent,
    receipt: false,
    refund
  });

  return expense;
};

// Find the most suitable driving expense type
export const getBestDrivingExpenseType = (expenseTypes: ExpenseType[]): ExpenseType | null => {
  const ret = expenseTypes.filter((o) => o.enabled).find((o) => o.is_Driving);
  return ret || null;
};

// Fuzzy matching to find the most suitable toll station expense type
export const getBestTollstationExpenseType = (expenseTypes: ExpenseType[]): ExpenseType | null => {
  const ret = expenseTypes
    .filter((o) => o.enabled)
    .find(
      (o) =>
        o.id === 8 ||
        o.description === "Bompenger" ||
        o.description.toLowerCase().includes("bompenge") ||
        o.description.toLowerCase().includes("bomstasjon") ||
        o.description.toLowerCase().includes("tollstation") ||
        o.description.toLowerCase().includes("tollbooth") ||
        o.is_Default
    );
  return ret || null;
};
