import moment from "moment";
import { ExchangeRate, ExchangeRatesByDate } from "../types";
import { jsonFetch } from "./api";

/**
 * Gets all exchangerates for today or a given date
 * @param {string} date - The date of the exchange rates needed. Defaults to today
 * @returns {response} An array of exchange rates
 */
export async function exchangeRates(date?: string): Promise<ExchangeRate[]> {
  const fetchOptions = {
    method: "GET"
  };
  const when = date ? moment(date).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD");
  return jsonFetch(`exchangerates/getexchangerates?date=${when}`, fetchOptions);
}

/**
 * Gets all exchangerates provided by Norges Bank for today or a given date
 * These rates have the same structure as the fixer.io-provided rates from the getexchangerates endpoint, but slightly different values
 * These are required for diet calculations as of 2024
 * @param {string} date - The date of the exchange rates needed. Defaults to today
 * @returns {response} An array of exchange rates
 */
export async function exchangeRatesNB(date?: string): Promise<ExchangeRate[]> {
  const fetchOptions = {
    method: "GET"
  };
  const when = date ? moment(date).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD");
  return jsonFetch(`exchangerates/getexchangeratesNB?date=${when}`, fetchOptions);
}

/**
 * Gets a set of "seed" exchangerates provided by Norges Bank - the last 100 sets available at time of writing
 * These exchangerates are intended to be loaded when the application launches, and used for diet calculations as of 2024
 * The idea is to cover most of the initial needs for diet calculations without having to poke the backend
 * @returns {response} An array of exchange rates
 */
export async function seedExchangeRatesNB(): Promise<ExchangeRatesByDate[]> {
  const fetchOptions = {
    method: "GET"
  };
  return jsonFetch(`exchangerates/getseedexchangeratesNB`, fetchOptions);
}
