import React, { useState } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { FormGroup, ControlLabel, FormControl } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { validateEmail, validatePasswords } from "../../shared/validation/resetPassword";
import * as userApi from "../../shared/api/user";
import "../../styles/login.css";
import Spinner from "../common/Spinner";
import { ValidateResetPasswordErrors } from "../../shared/types";

const ResetPasswordPage = () => {
  const [t] = useTranslation();
  const history = useHistory();

  const q = new URLSearchParams(history.location.search);
  const qToken: string | null = q.get("token");

  const [email, setEmail] = useState("");
  const [token, setToken] = useState(qToken);
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [errors, setErrors] = useState<ValidateResetPasswordErrors>({});
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(qToken ? 2 : 1);

  const onSubmitEmail = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isValidEmail()) {
      setIsLoading(true);

      if (await userApi.recoverPassword(email, true, window.location.href)) {
        setStep(2);
      } else {
        setErrors({ form: "An error has occured" });
      }
      setIsLoading(false);
    }
  };

  const onSubmitPassword = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isValidPasswords()) {
      setIsLoading(true);
      if (token && (await userApi.resetPassword(token, password1))) {
        setStep(3);
      } else {
        setErrors({ form: "An error has occured" });
      }
      setIsLoading(false);
    }
  };

  const isValidEmail = () => {
    const { errors, isValid } = validateEmail(email);
    setErrors(errors);
    return isValid;
  };

  const isValidPasswords = () => {
    const { errors, isValid } = validatePasswords(password1, password2, token || "");
    setErrors(errors);
    return isValid;
  };

  return (
    <div className="login-page">
      <div className="login-grid">
        <div className="login-top" />
        <div className="login-middle">
          <div className="login-middle-mobile-top" />
          <div className="login-form">
            <div id="login-form-header">
              <h2>{t("login.forgotpass")}</h2>
              <hr />
            </div>

            {step === 1 && !isLoading && (
              <form onSubmit={onSubmitEmail}>
                <FormGroup>
                  <ControlLabel>{t("resetPassword.enterEmail")}</ControlLabel>
                  <FormControl
                    onChange={(e: React.FormEvent<FormControl & HTMLInputElement>) => setEmail(e.currentTarget.value)}
                    value={email}
                    type="email"
                    name="email"
                    placeholder={t("login.email")}
                    disabled={isLoading}
                    autoComplete="username"
                  />
                  {errors.email && <span className="error">{errors.email}</span>}
                </FormGroup>

                <FormGroup>
                  <button className="main-button" type="submit" disabled={isLoading}>
                    {t("resetPassword.requestCode")}
                  </button>
                </FormGroup>
              </form>
            )}

            {step === 2 && !isLoading && (
              <form onSubmit={onSubmitPassword}>
                <FormGroup>
                  <ControlLabel>{t("resetPassword.enterCode")}</ControlLabel>
                  <FormControl
                    onChange={(e: React.FormEvent<FormControl & HTMLInputElement>) => setToken(e.currentTarget.value)}
                    value={token || ""}
                    type="text"
                    name="token"
                    placeholder={t("resetPassword.codePlaceholder")}
                    disabled={isLoading}
                    autoComplete="one-time-code"
                  />
                  {errors.token && <span className="error">{errors.token}</span>}
                </FormGroup>

                <FormGroup>
                  <ControlLabel>{t("resetPassword.newPassword")}</ControlLabel>
                  <FormControl
                    onChange={(e: React.FormEvent<FormControl & HTMLInputElement>) => setPassword1(e.currentTarget.value)}
                    value={password1}
                    type="password"
                    name="password1"
                    placeholder={t("resetPassword.newPassword")}
                    disabled={isLoading}
                    autoComplete="new-password"
                  />
                  {errors.password1 && <span className="error">{errors.password1}</span>}
                </FormGroup>

                <FormGroup>
                  <ControlLabel>{t("resetPassword.newPasswordAgain")}</ControlLabel>
                  <FormControl
                    onChange={(e: React.FormEvent<FormControl & HTMLInputElement>) => setPassword2(e.currentTarget.value)}
                    value={password2}
                    type="password"
                    name="password2"
                    placeholder={t("resetPassword.newPasswordAgain")}
                    disabled={isLoading}
                    autoComplete="new-password"
                  />
                  {errors.password2 && <span className="error">{errors.password2}</span>}
                </FormGroup>

                <FormGroup>
                  <button className="main-button" type="submit" disabled={isLoading}>
                    {t("resetPassword.setNewPassword")}
                  </button>
                </FormGroup>
              </form>
            )}
            {step === 3 && (
              <div>
                <h3>{t("resetPassword.success")}</h3>
                {t("resetPassword.signInAgain")}
                <br />
                <br />
              </div>
            )}

            {isLoading && <Spinner size="2em" />}
            {errors.form && <div className="error">{errors.form}</div>}

            <div>
              <Link to="/login">{t("resetPassword.returnToLogin")}</Link>
            </div>
          </div>
        </div>
        <div className="login-bottom" />
      </div>
    </div>
  );
};

export default ResetPasswordPage;
