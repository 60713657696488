import * as baseIcons from "../../theme/baseIcons";
import * as themeIcons from "../../theme/themeIcons";

// Wrapper for SVG icons
// We default to pulling icons from baseIcons, overridden with icons from themeIcons where available
interface IconProps {
  icon: string;
  title?: string;
  style?: React.CSSProperties;
  extraClassName?: string;
}
const Icon = ({ icon, title, style, extraClassName }: IconProps) => {
  // @ts-ignore Unnecessary complexity for dynamic property accessors
  const iconSvg = themeIcons[icon] || baseIcons[icon];
  const className = extraClassName ? `svg-icon-wrapper ${extraClassName}` : "svg-icon-wrapper";
  if (iconSvg)
    return (
      <div className={className} style={style} title={title}>
        {iconSvg}
      </div>
    );
  return (
    <div className={className}>
      <h1>MISSING ICON</h1>
    </div>
  );
};

// Use to return the raw contents of a google map symbol
export const mapIcon = (icon: string) => {
  // @ts-ignore Unnecessary complexity for dynamic property accessors
  const symbol = themeIcons[icon] || baseIcons[icon];
  return symbol;
};

export default Icon;
