import React, { Suspense, useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import Icon from "../common/Icon";
import Iframe from "../common/Iframe";
import { attachment } from "../../shared/api/blob";
import Spinner from "../common/Spinner";
import { useStore } from "../../shared/store/store";
import { StorePreview, hidePreview } from "../../utils/webclientStore";
import { CancelablePromise, makeCancelable } from "../../utils/makeCancelable";

// If state.clientSpecific.preview contains something (image, PDF, etc), this will render a preview box for it
const PdfViewer = React.lazy(() => import("./PdfViewer"));
const AttachmentPreview = () => {
  const [t] = useTranslation();

  const [blobUrl, setBlobUrl] = useState("");
  const [blobUrlFull, setBlobUrlFull] = useState("");
  const [pdfPageNumber, setPdfPageNumber] = useState(1);
  const [pdfPages, setPdfPages] = useState(1);
  const [fetchError, setFetchError] = useState(false);
  const [downloadingFullSize, setDownloadingFullSize] = useState(false);
  const [loadingFullSize, setLoadingFullSize] = useState(false);
  const [panning, setPanning] = useState(false);
  const [promises, setPromises] = useState<CancelablePromise[]>([]);
  const zoomInRef = useRef<Function | null>(null);
  const zoomOutRef = useRef<Function | null>(null);

  const previewState: StorePreview = useStore((state) => state.clientSpecific.preview);

  const { body, blobUuid, mimeType, objectUrl, source } = previewState;
  // If blobUuid and mimeType changes, and they have valid values, reset the PDF page number and fetch the new attachment
  useEffect(() => {
    setPdfPageNumber(1);
    if (blobUuid && mimeType && isValidBlobMimeType(mimeType)) {
      fetchAttachment(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [body, blobUuid, mimeType, objectUrl]);

  // Cancel any fetch promises to allow garbage disposal
  useEffect(() => {
    return () => {
      promises.forEach((p) => p.cancel());
    };
  }, [promises]);

  // When blobUrls are removed (due to closing the preview), clean up to allow garbage collection of the blob ref https://developer.mozilla.org/en-US/docs/Web/API/URL/revokeObjectURL
  useEffect(() => {
    return () => {
      if (blobUrl) {
        (URL || webkitURL).revokeObjectURL(blobUrl);
      }
      if (blobUrlFull) {
        (URL || webkitURL).revokeObjectURL(blobUrlFull);
      }
    };
  }, [blobUrl, blobUrlFull]);

  const getDownloadFilename = () => {
    let name = t("attachmentPreview.attachment");
    switch (mimeType) {
      case "image/jpeg":
      case "image/jpg": {
        name += ".jpg";
        break;
      }
      case "image/png": {
        name += ".png";
        break;
      }
      case "image/tiff": {
        name += ".tif";
        break;
      }
      case "image/gif": {
        name += ".gif";
        break;
      }
      case "application/pdf": {
        name += ".pdf";
        break;
      }
      case "text/html": {
        name += ".html";
        break;
      }
      case "text/plain": {
        name += ".txt";
        break;
      }
      default: {
        name += ".dat";
      }
    }
    return name;
  };

  const isValidBlobMimeType = (mimeType: string) => {
    const mimeLower = mimeType.toLowerCase();
    return mimeLower.startsWith("image/") || mimeLower === "application/pdf";
  };

  // Download an attachment from server and update state with the local blob URL
  // If previewOnly is true, we're loading a new attachment and should reset state
  // If previewOnly is false, we're loading the full sized version of the current attachment and should only reset that part of state
  const fetchAttachment = async (previewOnly: boolean) => {
    // Requires a blobUuid
    if (!blobUuid) return;

    setFetchError(false);
    if (previewOnly) {
      setBlobUrl("");
      setBlobUrlFull("");
    } else {
      setBlobUrlFull("");
    }
    if (!previewOnly) {
      setLoadingFullSize(true);
    }

    try {
      const prom = makeCancelable(attachment(blobUuid, previewOnly, mimeType));
      setPromises([...promises, prom]);
      const res = await prom.promise;
      const blobUrl = blobToObjectUrl(res);
      // Previews go in blobUrl (which may also contain a full version if it's not an image), full sized versions go in blobUrlFull
      if (previewOnly) {
        setBlobUrl(blobUrl);
      } else {
        setBlobUrlFull(blobUrl);
        setLoadingFullSize(false);
      }
    } catch (err) {
      setFetchError(true);
    }
  };

  const blobToObjectUrl = (blob: Blob): string => {
    const urlCreator = window.URL || window.webkitURL;
    return urlCreator.createObjectURL(blob);
  };

  const download = () => {
    // Requires a blobUuid
    if (!blobUuid) return;

    const tempLink = document.createElement("a");
    const fileName = getDownloadFilename();

    // Check if the attachment is an image or something else
    if (mimeType.toLowerCase().startsWith("image/")) {
      if (blobUrlFull) {
        // The attachment is an image, and we already have the full sized blob and can just download it
        tempLink.href = blobUrlFull;
        tempLink.setAttribute("download", fileName);
        tempLink.click();
      } else {
        // The attachment is an image, but we only have a preview cached and need to fetch the full-sized blob first
        setDownloadingFullSize(true);

        const prom = makeCancelable(attachment(blobUuid, false, mimeType));
        setPromises([...promises, prom]);

        prom.promise
          .then((res) => {
            const blobUrlFull = blobToObjectUrl(res);
            tempLink.href = blobUrlFull;
            tempLink.setAttribute("download", fileName);
            tempLink.click();
            setDownloadingFullSize(false);
            setBlobUrlFull(blobUrlFull);
          })
          .catch((err) => {
            setFetchError(true);
            setDownloadingFullSize(false);
          });
      }
    } else {
      // This is not an image, we already have the full blob and can just download it
      tempLink.href = blobUrl;
      tempLink.setAttribute("download", fileName);
      tempLink.click();
    }
  };

  // Reset local state and store to clear preview data from app state
  const close = () => {
    setBlobUrl("");
    setBlobUrlFull("");
    promises.forEach((p) => p.cancel());
    setPromises([]);
    hidePreview();
  };

  // Close if escape key is hit
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Escape") close();
  };

  // PDF paging
  const pdfNextPage = (e: React.MouseEvent<Button, MouseEvent>) => {
    setPdfPageNumber(pdfPageNumber + 1);
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  };

  const pdfPrevPage = (e: React.MouseEvent<Button, MouseEvent>) => {
    setPdfPageNumber(pdfPageNumber - 1);
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  };

  // If we got a pre-created objectUrl, ignore everything else and render it as if it was a full size image
  if (!mimeType && !objectUrl) return null;

  const mimeLower = mimeType.toLowerCase();
  const renderFrame = mimeLower.startsWith("text/");
  const renderImage = mimeLower.startsWith("image/");
  const renderImageFull = mimeLower.startsWith("image/") && blobUrlFull;
  const renderPdf = mimeLower.startsWith("application/pdf");
  const renderPlainText = mimeLower.startsWith("text/plain");
  const renderCardTransaction = renderPlainText && source && source === "cardtransaction";

  const cssClass = `attachment-preview ${renderFrame ? "attachment-iframe" : ""} ${renderCardTransaction ? "attachment-iframe-cardtransaction" : ""}`;

  let bodyContent = body;

  if (body && renderPlainText && !renderCardTransaction) {
    // For most plain text attachments (usually email body content), wrap the body in pre-tags to maintain linebreaks properly
    bodyContent = `<pre>${bodyContent}</pre>`;
  } else if (body && renderPlainText && renderCardTransaction) {
    // Plain text attachments that were generated by a card transaction should have a specific format:
    // label1: some value
    // label2: some other value
    // ...etc
    // It will look okay just wrapped in a <pre>, but attempt to generate a nice HTML table from it first
    let foundLines = false;
    bodyContent = `<style>
          body { position: relative; }
          table { 
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);  
          }
          table tr td:first-child {
            font-weight: bold;
            padding-right: 10px;
          }
          table tr td:first-child::after {
            content: ':';
          }          
      </style>`;
    bodyContent += "<table id='card-transaction-preview'>";

    body.split(/\r?\n/).forEach((line) => {
      if (line && line.trim() !== "" && line.indexOf(":") > 0) {
        const parts = line.split(/:(.+)/);
        if (parts.length > 1) {
          bodyContent += `<tr><td>${parts[0]}</td><td style='white-space: nowrap;'>${parts[1]}</td></tr>`;
          foundLines = true;
        }
      }
    });
    bodyContent += "</table>";
    // No valid lines found, fall back to <pre>-wrapping
    if (!foundLines) bodyContent = `<pre>${body}</pre>`;
  }

  return (
    <div
      role="dialog"
      className="attachment-preview-wrapper"
      onClick={() => {
        close(); // Close on background overlay clicks
      }}
      onKeyDown={handleKeyDown}
    >
      <div className={cssClass}>
        <div className="attachment-preview-buttons">
          {
            // Render zoom buttons if we have a fullsize image or a pre-created objectUrl
            ((renderImageFull && !fetchError) || objectUrl) && (
              <React.Fragment>
                <button
                  title={t("attachmentPreview.zoomIn")}
                  className="attachment-preview-zoom-in"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.nativeEvent.stopImmediatePropagation();
                    zoomInRef.current && zoomInRef.current();
                  }}
                >
                  <Icon icon="zoomIn" />
                </button>{" "}
                <button
                  title={t("attachmentPreview.zoomOut")}
                  className="attachment-preview-zoom-out"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.nativeEvent.stopImmediatePropagation();
                    zoomOutRef.current && zoomOutRef.current();
                  }}
                >
                  <Icon icon="zoomOut" />
                </button>
              </React.Fragment>
            )
          }

          {
            // Render a download button if we have a fullsize image, but not for a pre-created objectUrl
            !renderFrame && !fetchError && !objectUrl && (
              <button
                title={t("attachmentPreview.download")}
                className="attachment-preview-download"
                onClick={(e) => {
                  e.stopPropagation();
                  e.nativeEvent.stopImmediatePropagation();
                  !downloadingFullSize && download();
                }}
              >
                {downloadingFullSize ? <Spinner size="24px" /> : <Icon icon="download" />}
              </button>
            )
          }

          <button title={t("attachmentPreview.hide")} className="attachment-preview-hide" onClick={close}>
            <Icon icon="close" />
          </button>
        </div>

        {
          // Render an error message if we failed at fetching a blob
          fetchError && (
            <div style={{ padding: 10 }}>
              <br />
              {t("attachmentPreview.errorFetching")}
            </div>
          )
        }

        {
          // Render an iframe
          bodyContent && renderFrame && !fetchError && <Iframe content={bodyContent} />
        }

        {
          // Render a static preview image if we have an image, but not the full version
          renderImage && !renderImageFull && !fetchError && (
            <div
              className="attachment-preview-static-image-wrapper"
              style={{ zIndex: 10003 }}
              onClick={(e) => {
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();
                fetchAttachment(false);
              }}
            >
              {blobUrl && !loadingFullSize && <img alt={"Preview"} className="attachment-preview-image" src={blobUrl} />}
              {(!blobUrl || loadingFullSize) && <Spinner size="4em" />}
            </div>
          )
        }

        {
          // Render a full size image with zoom/pan if we have it, or a pre-created objectUrl
          ((renderImageFull && !fetchError) || objectUrl) && (
            <div
              className={`attachment-preview-zoomable-wrapper ${panning ? "panning" : ""}`}
              onClick={(e) => {
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();
              }}
            >
              <TransformWrapper onPanningStart={() => setPanning(true)} onPanningStop={() => setPanning(false)}>
                {({ zoomIn, zoomOut, ...rest }) => {
                  zoomInRef.current = zoomIn;
                  zoomOutRef.current = zoomOut;

                  return (
                    <TransformComponent>
                      <img alt={"Fullsize"} className="attachment-preview-zoomable" src={blobUrlFull || objectUrl} />
                    </TransformComponent>
                  );
                }}
              </TransformWrapper>
            </div>
          )
        }

        {
          // Render a PDF viewer
          renderPdf && !fetchError && (
            <div className="pdf-preview-wrapper">
              {blobUrl && (
                <div>
                  <Suspense
                    fallback={
                      <div>
                        <Spinner size="4em" />
                      </div>
                    }
                  >
                    <PdfViewer file={blobUrl} pageNumber={pdfPageNumber} onLoadSuccess={(pdf: any) => setPdfPages(pdf.numPages)} onAbort={close} />
                  </Suspense>
                  <div>
                    {pdfPageNumber > 1 && (
                      <Button bsStyle="link" onClick={(e) => pdfPrevPage(e)}>
                        <Icon icon="arrowLeft" />
                      </Button>
                    )}
                    Page {pdfPageNumber} / {pdfPages}
                    {pdfPageNumber < pdfPages && (
                      <Button bsStyle="link" onClick={(e) => pdfNextPage(e)}>
                        <Icon icon="arrowRight" />
                      </Button>
                    )}
                  </div>
                </div>
              )}
              {!blobUrl && <Spinner size="4em" />}
            </div>
          )
        }
      </div>
    </div>
  );
};

export default AttachmentPreview;
