import { merge } from "lodash";
import { SupportAttachment } from "../types";
import { getFetchBaseOptions, jsonFetch, rawFetch } from "./api";

const fetchBlobDefaultOptions = {
  cache: "force-cache", // Since our api never overwrites a blob, a cached result will always be valid
  headers: {
    "Content-Type": "application/octet-stream"
  }
};

// Save a blob to backend.
// If blobType unspecified, we assume it's an attachment (for the "attachments" endpoint).
// For signatures, specify "signatures".
// Backend returns no content, so a fresh uuid must be specified
export const save = async (blobUuid: string, blob: Blob, blobType: "attachments" | "signatures" = "attachments"): Promise<void> => {
  const fetchOptions = {
    method: "POST",
    headers: {
      "Content-Type": blob.type
    },
    body: blob
  };
  const url = `e/blob/${blobType}/${blobUuid}/save?mimetype=${blob.type}`;
  return jsonFetch(url, fetchOptions);
};

// Save an support request attachment
// This is intended for files a user wants to attach to a support request, typically screenshots of the application
// mimeType and extension should be provided so the blob can be attached to a support email in a clean way
// extension should not include the period, just "jpg" for example
// The backend will return { Blobref: filename }, where the filename is a reference that can be attached to a support request
export const saveSupportAttachment = (attachment: SupportAttachment): Promise<{ Blobref: string }> => {
  const { blob, mimeType, extension } = attachment;
  if (!blob) throw new Error("No blob passed to saveSupportAttachment");
  const fetchOptions = {
    method: "POST",
    headers: {
      "Content-Type": blob.type
    },
    body: blob
  };
  const url = `e/blob/supportattachment/save?mimetype=${mimeType}&extension=${extension}`;
  return jsonFetch(url, fetchOptions);
};

// Fetch a raw blob. Just return an empty blob if the request fails
export const raw = async (url: string, fetchOptions: RequestInit): Promise<Blob> => {
  const fetchOpts = merge({}, fetchBlobDefaultOptions, fetchOptions);
  try {
    const response = await rawFetch(url, fetchOpts, true);
    return response.blob();
  } catch {
    return new Blob();
  }
};

// Fetch an attachment blob
// If the attachment requested is an image, you can pass in preview=true for a preview sized image
// If not, the raw attachment will be fetched (for PDF and other non-images). This also requires the actual mimeType of the attachment
export const attachment = async (blobUuid: string, fetchPreview: boolean, mimeType: string): Promise<Blob> => {
  const fetchOptions = merge({}, getFetchBaseOptions(), fetchBlobDefaultOptions, { method: "GET" });
  const previewUrl = fetchPreview ? "/preview" : "";
  const mimeTypeUrl = mimeType ? `?mimeType=${mimeType}` : "";
  const url = `e/blob/attachments/${blobUuid}${previewUrl}${mimeTypeUrl}`;
  return await raw(url, fetchOptions);
};

// Fetch a thumbnail of an attachment blob
export const attachmentThumbnail = async (blobUuid: string, mimeType: string): Promise<Blob> => {
  const fetchOptions = merge({}, getFetchBaseOptions(), fetchBlobDefaultOptions, {
    method: "GET",
    headers: {
      "Content-Type": "image/jpeg"
    }
  });
  const url = `e/blob/attachments/${blobUuid}/thumbnail?mimeType=${mimeType}`;
  return await raw(url, fetchOptions);
};

// Fetch an uploaded signature
// The UUID is found on the user's profile. This will always be a JPG (old signatures) or PNG (new) if it exists, max 400x160px.
export const signature = async (blobUuid: string): Promise<Blob> => {
  const fetchOptions = merge({}, getFetchBaseOptions(), fetchBlobDefaultOptions, {
    method: "GET"
  });
  const url = `e/blob/signatures/${blobUuid}`;
  return await raw(url, fetchOptions);
};

// Get an exported report PDF.
// The blobUuid is returned by the report API createPdfExport function
export const pdfExport = async (blobUuid: string): Promise<Blob> => {
  const fetchOptions = merge({}, getFetchBaseOptions(), fetchBlobDefaultOptions, {
    method: "GET"
  });
  const url = `e/blob/exports/${blobUuid}`;
  return await raw(url, fetchOptions);
};

// Save a state export blob to backend.
// The backend will return a reference code the user can pass to a developer to investigate the state dump
export const saveStateExport = async (blobOrFile: Blob | File, notification?: string): Promise<{ Refcode: string }> => {
  const fetchOptions = {
    method: "POST",
    headers: {
      "Content-Type": blobOrFile.type
    },
    body: blobOrFile
  };
  const url = `e/blob/stateexport/save${notification ? `?notification=${notification}` : ""}`;
  return jsonFetch(url, fetchOptions);
};
