import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.css";
import "moment/locale/nb";
import "./styles/app.css";
import App from "./components/App";
// import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  // TODO-V2-OPTIONAL reintroduce strict mode. This will trigger warnings for react-bootstrap using legacy context API
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>,
  <React.Fragment>
    <App />
  </React.Fragment>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// TODO-V2-LAST: Do we need this?
// reportWebVitals();
