// import ReactGA from "react-ga";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";

let appInsights: ApplicationInsights | null;
export const createApplicationInsightsReactPlugin = () => {
  //const browserHistory = createBrowserHistory({ basename: "" });
  var reactPlugin = new ReactPlugin();
  appInsights = new ApplicationInsights({
    config: {
      connectionString:
        "InstrumentationKey=1405a6e6-9192-4ba1-b2e7-5905eef5e123;IngestionEndpoint=https://northeurope-2.in.applicationinsights.azure.com/;LiveEndpoint=https://northeurope.livediagnostics.monitor.azure.com/",
      enableAutoRouteTracking: true,
      extensions: [reactPlugin]
    }
  });
  appInsights.loadAppInsights();
  return reactPlugin;
};

export const trackEvent = (category: string, action: string, userIdentifier?: number | string): void => {
  if (appInsights) {
    const evtProps = { action, category, userIdentifier };
    appInsights.trackEvent({ name: action }, evtProps);
  }
  // if (userIdentifier) ReactGA.set({ userIdentifier: userIdentifier || "" });
  // ReactGA.event({
  //   category,
  //   action
  // });
};
