import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import TextFieldGroup from "./common/TextFieldGroup";
import SelectGroup from "./common/SelectGroup";
import Icon from "./common/Icon";
import GenericUploader from "./common/GenericUploader";
import { useTranslation } from "react-i18next";
import { sendSupportRequest } from "../shared/api/support";
import html2canvas from "html2canvas";
import { showToast } from "./../utils/toastWrapper";
import { saveSupportAttachment } from "./../shared/api/blob";
import Spinner from "./common/Spinner";
import ImageThumbnail from "./common/ImageThumbnail";
import "../styles/contactus.css";
import { PersonConfiguration, SupportAttachment } from "../shared/types";

// Renders a modal for sending a message to customer suppport
interface ContactUsProps {
  userConfiguration: PersonConfiguration;
  onHide: () => void;
  show: boolean;
}
const ContactUs = ({ userConfiguration, onHide, show }: ContactUsProps) => {
  const [t] = useTranslation();

  const initialPhone =
    userConfiguration &&
    userConfiguration.mobileNumbers &&
    userConfiguration.mobileNumbers.length > 0 &&
    userConfiguration.mobileNumbers[0].mobileNumber
      ? userConfiguration.mobileNumbers[0].mobileNumber
      : "";
  const initialEmail = userConfiguration ? userConfiguration.email : "";

  const [email, setEmail] = useState(initialEmail);
  const [phone, setPhone] = useState(initialPhone);
  const [category, setCategory] = useState("");
  const [message, setMessage] = useState("");
  const [processing, setProcessing] = useState(false);
  const [attachments, setAttachments] = useState<SupportAttachment[]>([]);
  const [screenShotting, setScreenShotting] = useState(false);
  const [showAttachments, setShowAttachments] = useState(false);

  const resetState = (): void => {
    setEmail(initialEmail);
    setPhone(initialPhone);
    setCategory("");
    setMessage("");
    setProcessing(false);
    setAttachments([]);
    setScreenShotting(false);
    setShowAttachments(false);
  };

  // Close the modal and reset
  const abortContact = (): void => {
    resetState();
    onHide();
  };

  // Send the support request
  const confirmContact = async (): Promise<void> => {
    setProcessing(true);
    const url = window.location.href;
    const supportRequest = {
      source: "web",
      email,
      phone,
      category,
      message,
      url,
      attachments
    };
    sendSupportRequest(supportRequest)
      .then((res) => {
        resetState();
        onHide();
        showToast({ text: t("contactUs.messageSent"), type: "success" });
      })
      .catch(() => {
        setProcessing(false);
        showToast({ text: t("genericError"), type: "error" });
      });
  };

  // Render the page to a canvas, save the canvas bitmap
  const screenShot = async (): Promise<void> => {
    setScreenShotting(true);
    const body = document.querySelector("body");
    if (body) {
      html2canvas(body, {
        onclone: (clone) => {
          // html2canvas has issues with absolutely positioned elements, so the (clones of) the root elements must be styled to take up the full viewport height
          const clone1 = clone.querySelector("#root-wrapper-1") as HTMLElement;
          const clone2 = clone.querySelector("#root-wrapper-2") as HTMLElement;
          if (clone1) clone1.style.height = "100vh";
          if (clone2) clone2.style.height = "100vh";
        },
        ignoreElements: (el) => {
          // Don't render the modal we're currently in
          return el.getAttribute("role") === "dialog";
        }
      }).then(async (canvas) => {
        const dataUrl = canvas.toDataURL();
        const imageBlob = await (await fetch(dataUrl)).blob();

        saveSupportAttachment({
          blob: imageBlob,
          mimeType: "image/jpeg",
          extension: "jpg",
          originalFilename: "screenshot"
        })
          .then((result) => {
            attachmentUploaded(t("contactUs.screenshot"), result.Blobref, "image/jpeg", imageBlob);
            setScreenShotting(false);
          })
          .catch(() => {
            setScreenShotting(false);
            attachmentUploadError();
          });
      });
    }
  };

  const attachmentUploaded = (originalFilename: string, blobFilename: string, mimeType: string, blob: Blob): void => {
    const newAttachment: SupportAttachment = {
      originalFilename,
      blobRef: blobFilename,
      mimeType,
      blob,
      extension: "image/jpeg"
    };
    const attachmentsNew = [...attachments, newAttachment];
    setAttachments(attachmentsNew);
  };

  const attachmentUploadError = (): void => {
    showToast({ text: t("genericError"), type: "error" });
  };

  const removeAttachment = (blobFilename: string): void => {
    const attachmentsNew = attachments.filter((a) => a.blobRef !== blobFilename);
    setAttachments(attachmentsNew);
  };

  const categories = [
    t("contactUs.categorySelect"),
    t("contactUs.categoryProblem"),
    t("contactUs.categoryQuestion"),
    t("contactUs.categorySuggest"),
    t("contactUs.categoryComment")
  ];
  const categoryOptions = categories.map((o) => ({ label: o, value: o, disabled: false }));
  categoryOptions[0].disabled = true;

  return (
    <Modal show={show} onHide={onHide} id="modal_contactus">
      <Modal.Header closeButton={true}>
        <Modal.Title>{t("contactUs.viewTitle")}</Modal.Title>
      </Modal.Header>
      {!processing && (
        <Modal.Body>
          <TextFieldGroup
            field="email"
            label={t("contactUs.emailLabel")}
            value={email}
            onChange={(value: string) => setEmail(value)}
            placeholder={t("contactUs.emailPlaceholder")}
          />
          <TextFieldGroup
            field="phone"
            label={t("contactUs.phoneLabel")}
            value={phone}
            onChange={(value: string) => setPhone(value)}
            placeholder={t("contactUs.phonePlaceholder")}
          />
          <SelectGroup
            field="category"
            label={t("contactUs.categoryLabel")}
            value={category || t("contactUs.categorySelect")}
            onChange={(value: string) => setCategory(value)}
            options={categoryOptions}
          />
          {category && (
            <div className="contactus-step2">
              <TextFieldGroup
                field="message"
                label={t("contactUs.messageLabel")}
                value={message}
                onChange={(value: string) => setMessage(value)}
                placeholder={t("contactUs.messagePlaceholder")}
                componentClass="textarea"
              />
              {!showAttachments && (
                <div className="contactus-add-attachment" onClick={() => setShowAttachments(true)}>
                  <Icon icon="add"></Icon>
                  <label>{t("contactUs.addAttachment")}</label>
                </div>
              )}
              {showAttachments && (
                <div>
                  <label>{t("contactUs.addAttachment")}</label>
                  <br />
                  <div className="contactus-attach">
                    <div>
                      <GenericUploader mode="support" onCreatedSupportAttachment={attachmentUploaded} onError={attachmentUploadError} />
                    </div>
                    <div className="contactus-screenshot">
                      <Button onClick={() => screenShot()} disabled={screenShotting}>
                        {screenShotting && <Spinner size="4em" />}
                        {!screenShotting && (
                          <React.Fragment>
                            <Icon icon="screenshot" />
                            <br />
                            {t("contactUs.addScreenshotCurrentPage")}
                          </React.Fragment>
                        )}
                      </Button>
                    </div>
                  </div>
                </div>
              )}

              {attachments.length > 0 && <label>{t("contactUs.attached")}</label>}
              <div className="contactus-attachment-list">
                {attachments.map(
                  (a) =>
                    a.blob && (
                      <div key={a.blobRef}>
                        <ImageThumbnail
                          blob={a.blob}
                          title={a.originalFilename}
                          mimeType={a.mimeType}
                          allowPreview
                          onDelete={() => a.blobRef && removeAttachment(a.blobRef)}
                        />
                      </div>
                    )
                )}
              </div>
            </div>
          )}
          {(t("contactUs.otherEmailValue") || t("contactUs.otherPhoneValue") || t("contactUs.otherAddressValue1")) && (
            <>
              <hr />
              <div className="contactus-header">{t("contactUs.otherLabel")}</div>
            </>
          )}
          <div className="contactus-other">
            {t("contactUs.otherEmailValue") && (
              <div className="contactus-other-option">
                <div className="icon">
                  <Icon icon="email" />
                </div>
                <div className="contactus-label">{t("contactUs.otherEmail")}</div>
                <div className="contactus-value">
                  <a href={`mailto:${t("contactUs.otherEmailValue")}`}>{t("contactUs.otherEmailValue")}</a>
                </div>
              </div>
            )}

            {t("contactUs.otherPhoneValue") && (
              <div className="contactus-other-option">
                <div className="icon">
                  <Icon icon="phone" />
                </div>
                <div className="contactus-label">{t("contactUs.otherPhone")}</div>
                <div className="contactus-value">
                  <a href={`tel:${t("contactUs.otherPhoneValue")}`}>{t("contactUs.otherPhoneValue")}</a>
                </div>
              </div>
            )}

            {t("contactUs.otherAddressValue1") && (
              <div className="contactus-other-option">
                <div className="icon">
                  <Icon icon="mapMarker" />
                </div>
                <div className="contactus-label">{t("contactUs.otherAddress")}</div>
                <div className="contactus-value">
                  <a target="_blank" rel="noopener noreferrer" href={t("contactUs.otherAddressUrl")}>
                    {t("contactUs.otherAddressValue1")}
                    <br />
                    {t("contactUs.otherAddressValue2")}
                  </a>
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
      )}
      {processing && (
        <Modal.Body>
          <Spinner size="100px" />
        </Modal.Body>
      )}
      {!processing && (
        <Modal.Footer>
          <Button onClick={abortContact} disabled={processing || screenShotting}>
            {t("cancel")}
          </Button>
          <Button bsStyle="success" onClick={confirmContact} disabled={!message || processing || screenShotting}>
            {t("submit")}
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default ContactUs;
