import jwt_decode from "jwt-decode";
import { jsonFetch, jsonFetchWithServerTime, rawFetch } from "./api";
import { AuthenticateJwtPayload, User, AdvancedApprovalDeputy, UserConfiguration, UserProfileFromBackend, UserProfileToBackend } from "../types";

/**
 * @typedef {object} profile
 * @property {string} address
 * @property {string} zip
 * @property {string} city
 * @property {string} email - User's login email address, must be valid and not in use by others
 * @property {string} password - New password, minimum 3 characters, pass empty string to keep unchanged
 * @property {string} phone
 * @property {string} workRole
 * @property {int} countryId - Valid country ID from the geo lookup endpoint
 * @property {int} cityId - Optional valid city ID from the geo lookup endpoint
 * @property {string} signatureUuid - The UUID of a blob uploaded to the signatures endpoint, or empty string
 * @property {string[]} mobiles - Zero or more valid mobile numbers not in use by others, in international format (+, countrycode, number) e.g: "+4790001234"
 * @property {string[]} emailSenderWhitelist - Zero or more valid email addresses that the system will allow as senders for email commands. Only relevant if feature is activated on a company level.
 */

/**
 * @typedef {object} deputy
 * @property {int} id - ID of the existing deputy entity, if we're modifying an existing one
 * @property {string} validFrom - Timestamp
 * @property {string} validTo - Timestamp
 * @property {int} deputyPersonId - PersonID of the deputy
 * @property {string} deputyFirstName - Name of the deputy, only for viewing, changes will not be saved
 * @property {string} deputyLastName - Name of the deputy, only for viewing, changes will not be saved
 */

/**
 * Used to authenticate a user by username and password
 * @param {string} username
 * @param {string} password
 * @returns {Promise<User>} Decoded JWT token converted into a User
 */
export const authenticate = async (username: string, password: string): Promise<User> => {
  const fetchOptions = {
    method: "POST",
    body: JSON.stringify({
      email: username,
      password
    })
  };
  const token = await jsonFetch("authentication/authenticate", fetchOptions);
  const decodedToken = jwt_decode<AuthenticateJwtPayload>(token);
  return {
    id: Number(decodedToken.nameid),
    exp: decodedToken.exp,
    iat: decodedToken.iat,
    jwtToken: token
  };
};

/**
 * Used to authenticate (or usually impersonate) a user by either an API key or a one-time-login-token generated from the backend
 * Provide only one of the token types!
 * The personId must match the token provided!
 * A oneTimeLoginToken is only usable once, and is rendered unusuable after the first attempt whether it succeeds or fails
 * @param {string} apiToken
 * @param {string} oneTimeLoginToken
 * @param {number} personId
 * @returns {string} JWT token, decodes into  {{userId: string, exp: number, iat: number, jwtToken: string}} an object describing the user
 */
export const authenticateWithToken = async (apiToken: string, oneTimeLoginToken: string, personId: number): Promise<User> => {
  const fetchOptions = {
    method: "POST",
    body: JSON.stringify({
      apiToken,
      oneTimeLoginToken,
      personId
    })
  };
  const token = await jsonFetch("authentication/impersonate", fetchOptions);
  const decodedToken = jwt_decode<AuthenticateJwtPayload>(token);
  return {
    id: Number(decodedToken.nameid),
    exp: decodedToken.exp,
    iat: decodedToken.iat,
    jwtToken: token
  };
};

/**
 * A method for retrieving the current user configuraiton
 * @param {string?} jwtToken If passed, will grab the config for the user owning that token. Otherwise, uses the current user's token.
 */
export const configuration = async (jwtToken?: string): Promise<{ serverTime: string; configuration: UserConfiguration }> => {
  const fetchOptions: RequestInit = {
    method: "GET"
  };
  if (jwtToken) fetchOptions.headers = { Authorization: `Bearer ${jwtToken}` };
  const ignoreConfigurationUpdatedHeader = true;
  const { body: config, serverTime } = await jsonFetchWithServerTime("e/user/configuration", fetchOptions, ignoreConfigurationUpdatedHeader);
  return {
    configuration: config,
    serverTime
  };
};

/**
 * @typedef {object} cssResponse
 * @property {object} css - An object containing the user's company custom CSS
 * @property {string} serverTime - UTC servertime
 */

/**
 * A method for retrieving the current user's company custom CSS
 * @returns {cssResponse} The CSS and the server time
 */
export const css = async (): Promise<{ serverTime: string; css: string }> => {
  const fetchOptions = {
    method: "GET"
  };
  const { body: styling, serverTime } = await jsonFetchWithServerTime("e/user/css", fetchOptions);
  return {
    css: styling,
    serverTime
  };
};

/**
 * Request a password recovery code for a user. Returns true if OK, false if error/unauthorized
 * @param {string} email - The user's email address
 * @param {boolean} web - If true, the email sent to the user will contain an URL instead of the naked recovery code
 */
export const recoverPassword = async (email: string, web: boolean, clientUrl: string): Promise<boolean> => {
  const fetchOptions = {
    method: "POST",
    body: JSON.stringify({
      email,
      web,
      clientUrl
    })
  };
  try {
    await rawFetch("authentication/recoverPassword", fetchOptions);
  } catch (err) {
    return false;
  }
  return true;
};

/**
 * Reset a user's password based on a recovery token. Returns true if OK, false if error/unauthorized
 * @param {string} token - The user's recovery token, received by email
 * @param {boolean} password - The new chosen password
 */
export const resetPassword = async (token: string, password: string): Promise<boolean> => {
  const fetchOptions = {
    method: "POST",
    body: JSON.stringify({
      token,
      password
    })
  };
  try {
    await rawFetch("authentication/resetPassword", fetchOptions);
  } catch (err) {
    return false;
  }
  return true;
};

/**
 * A method for retrieving the current user's profile
 * @returns {object} The user's profile details
 */
export const getProfile = async (): Promise<UserProfileFromBackend> => {
  const fetchOptions = {
    method: "GET"
  };
  return jsonFetch("e/user/profile", fetchOptions);
};

/**
 * Save the current user's profile details
 * @param {profile} profile The user details
 */
export const saveProfile = async (profile: UserProfileToBackend): Promise<void> => {
  const fetchOptions = {
    method: "POST",
    body: JSON.stringify(profile)
  };
  return jsonFetch(`e/user/profile`, fetchOptions);
};

/**
 * Generate a personal API key for the user and immediately store it
 */
export const generateApiKey = async (): Promise<string> => {
  const fetchOptions = {
    method: "POST"
  };
  return jsonFetch(`e/user/generateApiKey`, fetchOptions);
};

/**
 * Delete a personal API key for the user immediately
 */
export const deleteApiKey = async (key: string): Promise<void> => {
  const fetchOptions = {
    method: "POST",
    body: JSON.stringify(key)
  };
  return jsonFetch(`e/user/deleteApiKey`, fetchOptions);
};

/**
 * Mark EULA as read
 */
export const acceptEula = async (): Promise<boolean> => {
  const fetchOptions = {
    method: "POST"
  };
  try {
    await rawFetch("e/user/accepteula", fetchOptions);
  } catch (err) {
    return false;
  }
  return true;
};

/**
 * Mark onboarding tour as shown
 */
export const setOnboardingTourShown = async (): Promise<boolean> => {
  const fetchOptions = {
    method: "POST"
  };
  try {
    await rawFetch("e/user/onboardingtourshown", fetchOptions);
  } catch (err) {
    return false;
  }
  return true;
};

/**
 * Fetch all deputies the user has created
 * @returns {Array} {id, validFrom, validTo, deputyPersonId, deputyName}
 */
export const getAdvancedApprovalDeputies = async (): Promise<AdvancedApprovalDeputy[]> => {
  const fetchOptions = {
    method: "GET"
  };
  return jsonFetch("e/user/AdvancedApprovalDeputies", fetchOptions);
};

/**
 * Fetch all candidate users available for assigning new deputies (usually all other people in the same company)
 * @returns {Array} {id, name}
 */
export const getAdvancedApprovalDeputyCandidates = async (): Promise<{ id: number; name: string }[]> => {
  const fetchOptions = {
    method: "GET"
  };
  return jsonFetch("e/user/AdvancedApprovalDeputyCandidates", fetchOptions);
};

/**
 * Delete a deputy
 */
export const deleteAdvancedApprovalDeputy = async (advancedApprovalDeputyId: number): Promise<void> => {
  const fetchOptions = {
    method: "POST"
  };
  return jsonFetch(`e/user/DeleteAdvancedApprovalDeputy?advancedApprovalDeputyId=${advancedApprovalDeputyId}`, fetchOptions);
};

/**
 * Add or update a deputy, set id = 0 for adding a new one
 * @param {deputy} deputy The deputy entity we're adding or updating
 */
export const saveAdvancedApprovalDeputy = async (deputy: AdvancedApprovalDeputy): Promise<void> => {
  const fetchOptions = {
    method: "POST",
    body: JSON.stringify(deputy)
  };
  return jsonFetch(`e/user/SaveAdvancedApprovalDeputy`, fetchOptions);
};

/**
 * Mark admin onboarding wizard as finished and not to be shown again
 */
export const setAdminOnboardingWizardFinished = async (): Promise<boolean> => {
  const fetchOptions = {
    method: "POST"
  };
  try {
    await rawFetch("e/user/AdminOnboardingWizardFinished", fetchOptions);
  } catch (err) {
    return false;
  }
  return true;
};

/**
 * Create a default approval flow for the parent company of this user during the admin onboarding wizard
 */
export const adminOnboardingGenerateApprovalFlow = async (): Promise<boolean> => {
  const fetchOptions = {
    method: "POST"
  };
  try {
    await rawFetch("e/user/AdminOnboardingGenerateApprovalFlow", fetchOptions);
  } catch (err) {
    return false;
  }
  return true;
};

/**
 * Create a default UX policy for the parent company of this user during the admin onboarding wizard
 */
export const adminOnboardingGenerateUxPolicy = async (hideTravel: boolean, hideDriving: boolean, hideFields: boolean): Promise<boolean> => {
  const fetchOptions = {
    method: "POST",
    body: JSON.stringify({ hideTravel, hideDriving, hideFields })
  };
  try {
    await rawFetch("e/user/AdminOnboardingGenerateUxPolicy", fetchOptions);
  } catch (err) {
    return false;
  }
  return true;
};

/**
 * Force a refresh of cached userconfig on the backend for the current user, used for debugging purposes
 */
export const forceConfigRefresh = async (): Promise<boolean> => {
  const fetchOptions = {
    method: "POST"
  };
  try {
    await rawFetch(`e/user/ForceConfigRefresh`, fetchOptions);
  } catch (err) {
    return false;
  }
  return true;
};

/**
 * Reset the onboarding flags (tour and admin wizard) for the current user, used for debugging purposes
 */
export const resetOnboardingFlags = async (): Promise<boolean> => {
  const fetchOptions = {
    method: "POST"
  };
  try {
    await rawFetch(`e/user/ResetOnboardingFlags`, fetchOptions);
  } catch (err) {
    return false;
  }
  return true;
};
