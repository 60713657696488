import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import ButtonWithSpinner from "../common/ButtonWithSpinner";
import ExpenseEditor from "../expense/ExpenseEditor";
import NavigationPrompt from "react-router-navigation-prompt";
import { useUserConfiguration, useExpenses, saveExpense } from "../../shared/queries/queries";
import { expenseConstructor } from "../../shared/constructors/expenseConstructor";
import { validateExpense } from "../../shared/validation/expense";
import "../../styles/report.css";
import { Expense, ExpenseCustomField, InboxAttachment, ReceiptAnalysis, UserConfiguration, ValidateExpenseErrors } from "../../shared/types";
import { currentExpenseTypes } from "../../shared/utils/currentExpenseTypes";
import { capitalize } from "lodash";
import { useStore } from "../../shared/store/store";
import { showToast } from "../../utils/toastWrapper";
import { getExpenseFromAnalysis } from "../../shared/utils/expenseUtils";
import { attachmentFromInboxAttachment } from "../../shared/constructors/attachmentConstructor";
import { applyPolicyToNewExpense } from "../../shared/utils/uxPolicyHelpers";
import { uuid } from "../../shared/utils/helpers";

// Show an expense editor for a single expense by UUID querystring param
const ExpensePage = () => {
  const [t] = useTranslation();
  const history = useHistory();
  const location = useLocation<{
    fromOcr?: { analysis: ReceiptAnalysis; inboxAttachment: InboxAttachment };
    // If fromOcr is provided, we were routed here after OCR'ing an inboxAttachment
    // Use this data to enhance the expense stub with expense details and create an attachment
  }>();

  // Monitor this to trigger a rerender if we switch users
  useStore((state) => state.currentUserId);

  const userConfigurationQuery = useUserConfiguration();
  const userConfiguration: UserConfiguration | undefined = userConfigurationQuery.data ? userConfigurationQuery.data.configuration : undefined;
  const nonVatEnabled = userConfiguration ? (userConfiguration.product.nonVatEnabled ? true : false) : false;
  const expenseTypes = currentExpenseTypes(userConfiguration);
  const expenseCustomFields: ExpenseCustomField[] = userConfiguration ? userConfiguration.product.expenseCustomFields : [];

  const { uuid: expenseUuid } = useParams<{ uuid: string }>(); // The expenseUuid to edit, from the path '/expense/:uuid'
  const expensesQuery = useExpenses();
  const searchParams = new URLSearchParams(useLocation().search);
  const newExpenseTypeId = Number(searchParams.get("expenseType"));
  const returnPage = searchParams.get("from") === "mileages" ? "mileages" : "expenses";
  const [saving, setSaving] = useState<boolean>(false);
  const [leaving, setLeaving] = useState<boolean>(false);

  const [currentExpense, setCurrentExpense] = useState<Expense | null>(null);
  const [additionalExpenses, setAdditionalExpenses] = useState<Expense[]>([]);
  const [editorIsDirty, setEditorIsDirty] = useState(false);
  const [validationErrors, setValidationErrors] = useState<ValidateExpenseErrors>({
    uuid: "",
    header: [],
    expenseCustomFields: []
  });

  // At this point we have:
  // Always expenseUuid from querystring: uuid or "new"
  // An inflight or completed expenseQuery

  // Whenever expensesQuery gets fresh data, find the expense and load it into the editor
  useEffect(() => {
    if (expensesQuery.data && expenseUuid) {
      const expense = expensesQuery.data.expenses.find((o) => o.uuid === expenseUuid && !o.deleted);
      if (expense) {
        setCurrentExpense(expense);
      }
    }
  }, [expensesQuery.data, expenseUuid]);

  // If the leaving flag has been set, navigate back to where we came from
  useEffect(() => {
    if (leaving) {
      history.push(`/${returnPage}`);
    }
  }, [leaving, history, returnPage]);

  // If validationErrors has changed, check if any elements failed validation and scroll them into view
  useEffect(() => {
    let failedElement = document.querySelector(
      // Expenselist items, expense fields
      ".has-error, .validation-errors"
    );
    if (!failedElement)
      failedElement = document.querySelector(
        ".validation-error" // Expenselist header
      );
    if (failedElement)
      failedElement.scrollIntoView({
        behavior: "smooth",
        block: "center"
      });
  }, [validationErrors]);

  // Merge in changes to the single expense in component state
  // forcePersist indicates that we should immediately queue the expense to be persisted, but is only used in reports, not here
  // fromConstructor indicates the changes comes from initialization of default values, not user input
  const onChangeExpense = (partialExpense: Partial<Expense>, forcePersist: boolean, fromConstructor?: boolean) => {
    if (!currentExpense) return;
    setCurrentExpense((current) => {
      return { ...(current as Expense), ...partialExpense };
    });
    if (!fromConstructor) setEditorIsDirty(true);
  };

  // Additional expenses requested by the editor to be created. Typically toll station passings related to a driving expense
  // When we save to backend, make sure to also save these and stamp with the correct groupUuid as the main expense, and set their date to match the expense
  const onChangeAdditionalExpenses = (additionalExpenses: Expense[], fromConstructor?: boolean) => {
    setAdditionalExpenses(additionalExpenses);
    if (!fromConstructor) setEditorIsDirty(true);
  };

  // Save changes and exit
  const finishEditing = async () => {
    if (!currentExpense) return false;
    if (!validateCurrentExpense()) return false;
    setSaving(true);
    const groupUuid = additionalExpenses.length > 0 ? uuid() : "";
    const expenseToSave = { ...currentExpense, groupUuid };

    saveExpense(expenseToSave)
      .then(() => {
        if (additionalExpenses.length > 0) {
          Promise.all(
            additionalExpenses.map((additionalExpense) =>
              saveExpense({
                ...additionalExpense,
                groupUuid: groupUuid,
                mileageTrackUuid: "",
                starts: expenseToSave.starts,
                ends: expenseToSave.starts
              })
            )
          )
            .then(() => {
              setSaving(false);
              setLeaving(true);
            })
            .catch((err: any) => {
              showToast({ type: "error", title: t("notifications.expenseSaveFailed"), text: err?.backendMessage || "" });
            });
        } else {
          setSaving(false);
          setLeaving(true);
        }
      })
      .catch((err: any) => {
        setSaving(false);
        showToast({ type: "error", title: t("notifications.expenseSaveFailed"), text: err?.backendMessage || "" });
      });
  };

  // Discard changes and exit
  const abortEditing = () => {
    setLeaving(true);
  };

  // Should be called before persisting, and if performance allows, whenever the expense is changed
  // Only deals with customfields for now, expand on this later
  const validateCurrentExpense = () => {
    if (!currentExpense) return false;
    const expense = { ...currentExpense };
    const enabledExpenseCustomFields = expenseCustomFields.filter((field) => field.enabled);
    const validationResult = validateExpense(expense, enabledExpenseCustomFields, userConfiguration?.product?.uxPolicy);
    setValidationErrors(validationResult.errors);
    return validationResult.isValid;
  };

  // Bail out if we have no userconfiguration. This should not happen as this is prepopulated in Homepage
  if (!userConfiguration) {
    console.log("Expensepage: No userconfiguration yet, emitting loading indicator");
    return <div>Loading... (no userconfiguration loaded)</div>;
  }

  // Are we creating a new expense?
  if (!currentExpense && expenseUuid === "new") {
    // Did we receive an OCR analysis to stub the expense from?
    const fromOcr = location.state && location.state.fromOcr ? location.state.fromOcr : undefined;

    if (fromOcr) {
      // We have an OCR analysis to stub the expense
      getExpenseFromAnalysis({
        personId: userConfiguration.person.id,
        analysis: fromOcr.analysis,
        userConfig: userConfiguration
      }).then((expense) => {
        const attachment = attachmentFromInboxAttachment({ expense, inboxAttachment: fromOcr.inboxAttachment });
        expense.attachments.push(attachment);
        setCurrentExpense(expense);
      });
    } else if (!!newExpenseTypeId && !Number.isNaN(newExpenseTypeId)) {
      // We don't have an OCR result, but we got an expensetype ID in the querystring
      const newExpenseType = expenseTypes.find((o) => o.id === newExpenseTypeId);
      if (newExpenseType) {
        // Stub the expense based on whether the expensetype is driving or not
        const expenseStub = newExpenseType.is_Driving
          ? {
              expenseTypeId: newExpenseTypeId,
              personId: userConfiguration.person.id,
              vatPercent: 0,
              description: capitalize(t("newMileageDescription")),
              unitName: "Km",
              receipt: false,
              refund: userConfiguration.product.defaultRefund
            }
          : {
              expenseTypeId: newExpenseTypeId,
              personId: userConfiguration.person.id,
              vatPercent: nonVatEnabled ? 0 : newExpenseType.vatPercent,
              description: capitalize(t("newExpenseDescription")),
              refund: userConfiguration.product.defaultRefund
            };
        const newExpense = expenseConstructor(expenseStub);
        // Apply policy defaults
        const completeExpense = applyPolicyToNewExpense(newExpense, userConfiguration.product);

        setCurrentExpense(completeExpense);
      }
    }
  }

  if (!currentExpense) {
    console.log("Expensepage: No expense yet, emitting loading indicator");
    return <div>Loading... (no expense loaded)</div>;
  }

  return (
    <div>
      <NavigationPrompt afterConfirm={() => abortEditing()} when={!leaving && currentExpense && !!currentExpense.dirty && editorIsDirty}>
        {({ onConfirm, onCancel }) => (
          <Modal show={true} onHide={onCancel}>
            <Modal.Header>
              <Modal.Title>{t("reportDetailsView.discardChanges")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{t("reportDetailsView.discardWarningText")}</Modal.Body>
            <Modal.Footer>
              <Button onClick={onCancel}>{t("no")}</Button>
              <Button bsStyle="danger" onClick={onConfirm}>
                {t("yes")}
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </NavigationPrompt>

      <div className="report-component">
        <div className="report-create-wrapper">
          <div className="report-component-header">
            <div className="report-component-title">{currentExpense.description || `[${t("noDescription")}]`}</div>
          </div>
          <ExpenseEditor
            expense={currentExpense}
            onChange={onChangeExpense}
            onChangeAdditionalExpenses={onChangeAdditionalExpenses}
            availableExpenseTypes={expenseTypes}
            validationErrors={validationErrors}
            enableAttachmentsDrawer
            readOnly={saving}
          />
          <div style={{ textAlign: "right" }}>
            <ButtonWithSpinner bsSize="large" bsStyle="warning" disabled={saving} onClick={() => abortEditing()}>
              {t("cancel")}
            </ButtonWithSpinner>
            &nbsp;
            <ButtonWithSpinner bsSize="large" bsStyle="success" disabled={saving} showSpinner={saving} onClick={() => finishEditing()}>
              {t("done")}
            </ButtonWithSpinner>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpensePage;
