import { Route, Redirect } from "react-router-dom";
import { useStore } from "../shared/store/store";

// Use this in place of the react-router <Route> for routes that should only render if there is an authenticated user
interface AuthenticatedRouteProps {
  component: React.ComponentType<any>;
  path?: any;
  exact?: boolean;
}
const AuthenticatedRoute = ({ component: Comp, path, exact }: AuthenticatedRouteProps) => {
  const isAuthenticated = !!useStore.getState().currentUserId;
  return <Route exact={exact} path={path} render={(props) => (isAuthenticated === true ? <Comp /> : <Redirect to="/login" />)} />;
};

export default AuthenticatedRoute;
