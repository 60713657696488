import isEmpty from "lodash/isEmpty";
import * as userApi from "../api/user";
import { setApiToken } from "../api/api";
import { queryClient, setUserId, QK_USER_CONFIGURATION } from "../queries/queries";
import { useStore } from "../store/store";
import { User } from "../types";

/**
 * Changes the current user
 * @param {number} userId
 */
export const switchUser = async (userId: number): Promise<void> => {
  useStore.getState().switchUser(userId);
  const user = useStore.getState().getCurrentUser();
  if (user) {
    const jwt = user.jwtToken;
    setApiToken(jwt);
    setUserId(userId);
  } else {
    throw new Error("Attempted to switch to non-existing user. This is a bug.");
  }
};

/**
 * Returns the current user, or check if a user is currently logged in
 */
export const getCurrentUser = async (): Promise<User | null> => {
  const user = useStore.getState().getCurrentUser();
  if (user) {
    return user;
  } else {
    return null;
  }
};

/**
 * Log in a user with credentials
 * Client is responsible for wrapping this in a trycatch and handling authentication errors or other errors!
 * @param {string} username
 * @param {string} password
 */
export const login = async (username: string, password: string): Promise<void> => {
  const user = await userApi.authenticate(username, password);
  useStore.getState().addUser(user);
  switchUser(user.id);
};

/**
 * Log in a user with an API key or one-time-login-token
 * @param {string} apiToken
 * @param {string} oneTimeLoginToken
 * @param {number} personId Who we are attempting to login
 */
export const loginWithToken = async (apiToken: string, oneTimeLoginToken: string, personId: number): Promise<void> => {
  const user = await userApi.authenticateWithToken(apiToken, oneTimeLoginToken, personId);
  useStore.getState().addUser(user);
  switchUser(user.id);
};

/**
 * Log out the current user, of if a userId param is provided, log out that specific user
 * @param {number} userId
 */
export const logout = async (userId?: number): Promise<void> => {
  if (userId) {
    useStore.getState().removeUser(userId);
  } else {
    const user = useStore.getState().getCurrentUser();
    if (user && !isEmpty(user)) {
      useStore.getState().removeUser(user.id);
    }
  }
};

/**
 * Gets the configuration for the currently active user
 */
export const getConfiguration = async () => {
  const userId = useStore.getState().currentUserId;
  const result = await queryClient.fetchQuery([QK_USER_CONFIGURATION, { userId }], () => userApi.configuration());
  return result;
};
