import { ApiReportsListResponse, ProductConfiguration, Report } from "../types";
import { jsonFetch, jsonFetchWithServerTime } from "./api";

/**
 * Gets all non-deleted reports for the currently logged in user.
 * @param {string} changedSince - If this is included only expenses changed after this date will be returned
 * @returns {response} An object containing an array of reports and serverTime (string)
 */
export const list = async (changedSince?: string): Promise<ApiReportsListResponse> => {
  const fetchOptions = {
    method: "GET"
  };
  const qs = changedSince ? `?changedSince=${changedSince}` : "?skipDeleted=true";
  const { body: reports, serverTime } = await jsonFetchWithServerTime(`e/report/list${qs}`, fetchOptions);
  return {
    reports,
    serverTime
  };
};

/**
 * Gets a single report by uuid
 * @param {string} uuid The uuid of the report
 * @returns {report} A single report object containing
 * reportSegments,
 * expenses,
 * reportApprovals,
 * reportCustomValues
 * attachments,
 * autoExpenseOverrides
 */
export const details = async (uuid: string): Promise<Report> => {
  const fetchOptions = {
    method: "GET"
  };
  return jsonFetch(`e/report/${uuid}`, fetchOptions);
};

/**
 * Gets a single report by approvalAttemptUuid. Like the details method, but for a report belonging to someone else that the user is inspecting before approving/rejecting
 * @param {string} approvalAttemptUuid The uuid of the report approval attempt
 * @returns {report} A report object
 */
export const detailsByApprovalAttempt = async (
  approvalAttemptUuid: string
): Promise<{ report: Report; ownerProductConfig: ProductConfiguration }> => {
  const fetchOptions = {
    method: "GET"
  };
  return jsonFetch(`e/report/ByApprovalAttempt/${approvalAttemptUuid}`, fetchOptions);
};

/**
 * Gets a single report by approvalAttemptUuid. Like the details method, but for a report belonging to someone else that the user is inspecting before approving/rejecting
 * @param {string} approvalAttemptUuid The uuid of the report approval attempt
 * @returns {report} A report object
 */
export const detailsByAdvancedApprovalAttemptDecision = async (
  advancedApprovalAttemptDecisionUuid: string
): Promise<{ report: Report; ownerProductConfig: ProductConfiguration }> => {
  const fetchOptions = {
    method: "GET"
  };
  return jsonFetch(`e/report/ByAdvancedApprovalAttemptDecision/${advancedApprovalAttemptDecisionUuid}`, fetchOptions);
};

/**
 * Save a single report
 * @param {report} report The report object to save
 * @returns {report} The server version of the saved report object
 */
export const save = async (report: Report, fetchOpts?: any): Promise<Report> => {
  const fetchOptions = {
    method: "POST",
    body: JSON.stringify(report),
    ...fetchOpts
  };
  return jsonFetch(`e/report/save`, fetchOptions);
};

/**
 * Archives/delivers a report
 * Only pass one one of the chain/flow ID's!
 * @param {object} archivalDetails reportUuid, approvalChainId, language, advancedApprovalFlowId
 * @returns {report} A complete report object
 */
interface ReportArchiveOptions {
  reportUuid: string;
  approvalChainId: number;
  language: string;
  advancedApprovalFlowId: number;
}
export const archive = async (archivalDetails: ReportArchiveOptions): Promise<Report> => {
  const fetchOptions = {
    method: "POST",
    body: JSON.stringify(archivalDetails)
  };
  return jsonFetch(`e/report/archive`, fetchOptions);
};

/**
 * Creates a serverside PDF export of a report, and returns the blobUuid
 * That uuid can be used with emailPdfExport or downloadPdfExport
 *
 * @param {string} reportUuid Uuid of the report
 * @param {string} language The preferred language to generate the report in, must be "en" or "no"
 * @returns {string} Uuid of the resulting blob
 */
export const createPdfExport = async (reportUuid: string, language: string): Promise<string> => {
  const fetchOptions = {
    method: "GET",
    headers: { "Accept-Language": language || "no" }
  };
  try {
    const response = await jsonFetch(`e/report/createPdfExport?reportUuid=${reportUuid}`, fetchOptions);
    return response;
  } catch (err) {
    console.log("createPdfExport error", err);
    return "";
  }
};

/**
 * Emails an exported report PDF to a recipient.
 * In case of a HTTP 409, the file is too big to email. In this case, it is recommended you suggest that the user download the PDF instead.
 *
 * @param {string} blobUuid Uuid of the exported blob, as returned from createPdfExport
 * @param {string} recipient A valid email address to receive the PDF
 * @returns {number} 1 = success, 2 = error (probably invalid email), 3 = file is too large, direct download recommended
 */
export const emailPdfExport = async (blobUuid: string, recipient: string): Promise<number> => {
  const fetchOptions = {
    method: "GET"
  };
  try {
    await jsonFetch(`e/report/emailPdfExport?exportUuid=${blobUuid}&recipient=${recipient}`, fetchOptions); // Yes, the endpoint takes a blobUuid despite bad naming
  } catch (err: any) {
    if (err && err.response && err.response.status === 409) return 3; // Too large for email
    return 2;
  }
  return 1;
};

/**
 * Recall a report from advanced approval process, cancelling the attempt and reopening it for the user
 * @param {string} reportUuid the uuis of the report
 * @returns {} 204 OK
 */
export const recallAdvanced = async (reportUuid: string): Promise<void> => {
  const fetchOptions = {
    method: "POST"
  };
  return jsonFetch(`e/report/recallfromadvancedapproval?reportUuid=${reportUuid}`, fetchOptions);
};
