import validator from "validator";
import isEmpty from "lodash/isEmpty";
import i18n from "../i18n/i18nConfig";
import { ValidateResetPasswordResult } from "../types";

// Validates email
export const validateEmail = (email: string): ValidateResetPasswordResult => {
  const errors: { email?: string } = {};

  if (validator.isEmpty(email)) {
    errors.email = i18n.t("requiredField");
  } else if (!validator.isEmail(email)) {
    errors.email = i18n.t("invalidUsername");
  }

  return {
    errors,
    isValid: isEmpty(errors)
  };
};

// Validates password1, password2, token
export const validatePasswords = (password1: string, password2: string, token: string): ValidateResetPasswordResult => {
  const errors: { password1?: string; password2?: string; token?: string } = {};

  if (validator.isEmpty(password1)) {
    errors.password1 = i18n.t("requiredField");
  } else if (!validator.isLength(password1, { min: 4 })) {
    errors.password1 = i18n.t("tooShortPassword");
  }

  if (validator.isEmpty(password2)) {
    errors.password2 = i18n.t("requiredField");
  } else if (!validator.isLength(password2, { min: 4 })) {
    errors.password2 = i18n.t("tooShortPassword");
  }

  if (validator.isEmpty(token)) {
    errors.token = i18n.t("requiredField");
  }

  if (password1 !== password2) {
    errors.password2 = i18n.t("passwordsNotEqual");
  }

  return {
    errors,
    isValid: isEmpty(errors)
  };
};
