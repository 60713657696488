import { useState } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { Button, FormGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { changeLanguage } from "../../shared/actions/languageActions";
import * as signupApi from "../../shared/api/signup";
import {
  validateSignup
  // validateUpgrade
} from "../../shared/validation/signup";
import TextFieldGroup from "../common/TextFieldGroup";
import Flag from "../common/Flag";
import Icon from "../common/Icon";
import "../../styles/login.css";
import Spinner from "../common/Spinner";
import { ValidateSignupErrors } from "../../shared/types";
import { useStore } from "../../shared/store/store";
import useConstructor from "../../shared/utils/useConstructor";
import { trackEvent } from "../../utils/eventTracker";

// Self-signup page
const SignupPage = () => {
  const [t] = useTranslation();
  const location = useLocation();
  const language = useStore((state) => state.language);

  let loginurl = "https://web.traveltext.no/login";
  if (typeof window !== "undefined") loginurl = `${window.location.protocol}//${window.location.host}/login`;

  const q = new URLSearchParams(location.search);
  const resellerToken = q.get("r") || "";

  const [isLoading, setIsLoading] = useState(false);
  const [smsSent, setSmsSent] = useState(false);
  const [saved, setSaved] = useState(false);
  const [errors, setErrors] = useState<ValidateSignupErrors>({});
  const [hasSeenCompanyWarning, setHasSeenCompanyWarning] = useState(false);
  const [signupData, setSignupData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    mobile: "",
    company: "",
    orgno: "",
    code: "",
    campaign: "",
    pass: "",
    loginurl,
    devkey: "",
    resellerToken,
    resellerName: ""
  });

  // Resellers are given an URL (in the admin backend) which they can send to their potential users
  // The URL contains a token, which enables users to signup and have their company placed under that reseller in the hierarchy
  const checkReseller = () => {
    if (signupData.resellerToken) {
      signupApi.getResellerDetails(resellerToken).then((details) => {
        if (details && details.name) setSignupData({ ...signupData, resellerName: details.name });
      });
    }
  };

  // Check reseller after mounting
  useConstructor(() => {
    trackEvent("SelfSignup", "Initialized signup");
    checkReseller();
  });

  // Clean mobile number onblur
  const changeMobile = () => {
    const mobile = signupData.mobile.replace(/ /g, "");
    setSignupData({ ...signupData, mobile });
  };

  // Validate step 1 and send SMS to user
  const sendSms = () => {
    trackEvent("SelfSignup", "Attempting to send SMS");
    const { errors, isValid } = validateSignup(signupData);
    setErrors(errors);

    if (isValid) {
      setIsLoading(true);
      Promise.all([
        signupApi.isMobileAvailable(signupData.mobile).then((available) => {
          if (!available) setErrors({ ...errors, mobile: t("mobileInUse") });
        }),
        signupApi.isEmailAvailable(signupData.email).then((available) => {
          if (!available) setErrors({ ...errors, email: t("emailInUse") });
        })
      ]).then(() => {
        if (Object.keys(errors).length === 0) {
          // No errors, trigger SMS
          trackEvent("SelfSignup", "Sent SMS");
          signupApi.sendSms(signupData).then((smsSent) => {
            setIsLoading(false);
            setSmsSent(smsSent);
          });
        } else {
          // Errors found
          setIsLoading(false);
        }
      });
    }
  };

  // Finalize the signup
  const save = () => {
    trackEvent("SelfSignup", "Attempting to finalize signup");
    const { errors, isValid } = validateSignup(signupData, true);
    console.log(errors, isValid);
    setErrors(errors);
    if (isValid) {
      setIsLoading(true);
      console.log("saving");

      signupApi.save(signupData).then((saved) => {
        if (saved) {
          // Save succeeded
          trackEvent("SelfSignup", "Finalized signup");
          setIsLoading(false);
          setSaved(saved);
        } else {
          // Save failed, likely due to an invalid one time code
          setIsLoading(false);
          setErrors({ ...errors, code: t("invalidCode") });
        }
      });
    }
  };

  const { firstname, lastname, email, mobile, company, orgno, code, resellerName, pass } = signupData;

  return (
    <div className="login-page">
      <div className="login-grid">
        <div className="login-top" />
        <div className="login-middle">
          <div className="login-middle-mobile-top" />
          <div className="login-form">
            {!saved && (
              <div id="login-form-header">
                <h2>{t("signup.header")}</h2>
                <hr />
                <h4>{t("signup.pitch")}</h4>

                {resellerName && (
                  <div>
                    {t("signup.yourResellerIs")} {resellerName}
                  </div>
                )}
                <br />
              </div>
            )}
            {!hasSeenCompanyWarning && (
              <>
                <Button
                  className="login-use-another-account signup-company-warning-bottom"
                  onClick={() =>
                    window.open(
                      "https://traveltext.freshdesk.com/support/solutions/articles/80000477073-selskapet-mitt-er-allerede-registrert-my-company-is-already-registered",
                      "_blank"
                    )
                  }
                >
                  <div className="login-use-another-account-title">{t("signup.companyWarningYes")}</div>
                  <div className="login-use-another-account-icon">
                    <Icon icon="arrowRightNaked"></Icon>
                  </div>
                </Button>
                <Button className="login-use-another-account" onClick={() => setHasSeenCompanyWarning(true)}>
                  <div className="login-use-another-account-title">{t("signup.companyWarningNo")}</div>
                  <div className="login-use-another-account-icon">
                    <Icon icon="arrowRightNaked"></Icon>
                  </div>
                </Button>
              </>
            )}

            {hasSeenCompanyWarning && !smsSent && !saved && !isLoading && (
              <div>
                <TextFieldGroup
                  field="firstname"
                  value={firstname}
                  onChange={(value: string) => setSignupData({ ...signupData, firstname: value })}
                  placeholder={t("signup.firstname")}
                  error={errors.firstname}
                  label={t("signup.firstname")}
                  hideLabel
                />

                <TextFieldGroup
                  field="lastname"
                  value={lastname}
                  onChange={(value: string) => setSignupData({ ...signupData, lastname: value })}
                  placeholder={t("signup.lastname")}
                  error={errors.lastname}
                  label={t("signup.lastname")}
                  hideLabel
                />

                <TextFieldGroup
                  field="email"
                  value={email}
                  onChange={(value: string) => setSignupData({ ...signupData, email: value })}
                  placeholder={t("signup.email")}
                  error={errors.email}
                  label={t("signup.email")}
                  hideLabel
                />

                <TextFieldGroup
                  field="mobile"
                  value={mobile}
                  onChange={(value: string) => setSignupData({ ...signupData, mobile: value })}
                  onBlur={() => changeMobile()}
                  placeholder={t("signup.mobile")}
                  error={errors.mobile}
                  label={t("signup.mobile")}
                  hideLabel
                  addonLeft="+47"
                />

                <TextFieldGroup
                  field="company"
                  value={company}
                  onChange={(value: string) => setSignupData({ ...signupData, company: value })}
                  placeholder={t("signup.company")}
                  error={errors.company}
                  label={t("signup.company")}
                  hideLabel
                />

                <TextFieldGroup
                  field="orgno"
                  value={orgno}
                  onChange={(value: string) => setSignupData({ ...signupData, orgno: value })}
                  placeholder={t("signup.orgno")}
                  error={errors.orgno}
                  label={t("signup.orgno")}
                  hideLabel
                />

                <FormGroup>
                  <Button className="main-button" disabled={isLoading} onClick={() => sendSms()}>
                    {t("signup.done")}
                  </Button>
                </FormGroup>
              </div>
            )}

            {hasSeenCompanyWarning && smsSent && !saved && !isLoading && (
              <div>
                <TextFieldGroup
                  field="code"
                  value={code}
                  onChange={(value: string) => setSignupData({ ...signupData, code: value })}
                  placeholder={t("signup.code")}
                  error={errors.code}
                  label={t("signup.code")}
                  hideLabel
                />

                <TextFieldGroup
                  field="pass"
                  value={pass}
                  onChange={(value: string) => setSignupData({ ...signupData, pass: value })}
                  placeholder={t("signup.pass")}
                  error={errors.pass}
                  label={t("signup.pass")}
                  hideLabel
                />

                <FormGroup>
                  <Button className="main-button" disabled={isLoading} onClick={() => save()}>
                    {t("signup.done")}
                  </Button>
                </FormGroup>

                <FormGroup>
                  <Button className="resend-button" disabled={isLoading} onClick={() => sendSms()}>
                    {t("signup.sendagain")}
                  </Button>
                </FormGroup>
              </div>
            )}

            {hasSeenCompanyWarning && saved && !isLoading && (
              <div>
                <h3>{t("signup.welcome1")}</h3>
                {t("signup.welcome2")}
              </div>
            )}

            {isLoading && <Spinner size="2em" />}
            {errors.form && <div className="error">{errors.form}</div>}

            {!isLoading && (
              <div>
                <div id="login-info">
                  <hr />
                  <a href="https://info.traveltext.no">{t("signup.contactus")}</a> {t("signup.anyquestions")}
                  <br />
                </div>

                <div id="login-goto-login">
                  <Link to="/login">{t("signup.tologin")}</Link>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="login-bottom">
          <div className="language-switcher">
            {language && language !== "nb" && (
              <Button bsSize="xs" onClick={async () => await changeLanguage("nb")}>
                <Flag country="no" /> Bytt til Norsk
              </Button>
            )}

            {language !== "en" && (
              <Button bsSize="xs" onClick={async () => await changeLanguage("en")}>
                <Flag country="gb" /> Switch to english
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupPage;
