import React, { useState } from "react";
import PropTypes from "prop-types";
import { useBus } from "../utils/useBus";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { showToast } from "../utils/toastWrapper";
import { run } from "../shared/api/command";
import { generateFriendlyResponseMessage } from "../shared/utils/command";
import { ToastStatus } from "../utils/toastWrapper";
import { Command } from "../shared/types";
import { refreshReports, refreshExpenses, refreshInboxes } from "../shared/queries/queries";

interface CommandBoxProps {
  placeholder: string;
  disabled?: boolean;
}
const CommandBox = ({ placeholder, disabled }: CommandBoxProps) => {
  const [t] = useTranslation();
  const location = useLocation();

  const [commandText, setCommandText] = useState("");
  const bus = useBus(() => {});

  // Set command hints by checking what kind of page we're on
  // If on a report page, we want to force any expenses to attach to that reportuuid
  // If somewhere in standalone expenses, force any expenses to be standalone
  const getHintsFromPath = () => {
    // No match from router in this context, figure it out manually
    const path = location.pathname.toLowerCase();
    const rxReport = /(\/report\/)([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})/;
    const rxExpenses = /(\/expenses)|(\/mileages)/;

    const mReport = rxReport.exec(path);
    const forceReportUuid = mReport && mReport.length >= 2 ? mReport[2] : "";

    const mExpenses = rxExpenses.exec(path);
    const forceStandAlone = mExpenses ? true : false;

    return { forceReportUuid, forceStandAlone };
  };

  const checkCheatCodes = (text: string) => {
    const cheatCodes = ["IDBEHOLDS", "IDKFA", "IDDQD"];
    const cmd = text.trim().toUpperCase();
    if (cheatCodes.includes(cmd)) {
      bus("BERSERK_MODE");
      return true;
    }
    return false;
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      if (!checkCheatCodes(commandText)) {
        const forceHints = getHintsFromPath();
        const payload: Command = {
          command: commandText,
          attachmentBase64: "", // Put base64 and mimetype in here if we want to support command attachments later, or a reference to an existing blobUuid
          mimeType: "",
          blobUuid: "",
          forceReportUuid: forceHints.forceReportUuid,
          forceStandAlone: forceHints.forceStandAlone,
          rigid: false,
          language: "nb-NO"
        };

        run(payload)
          .then((result) => {
            // Parse the result to a friendly toast message
            const friendlyResult = generateFriendlyResponseMessage(result);
            let toastStatus: ToastStatus = "success";
            if (friendlyResult.statusType === "warning") toastStatus = "warning";
            if (friendlyResult.statusType === "error") toastStatus = "error";
            showToast({ title: "", text: friendlyResult.message, type: toastStatus });

            // Invalidate a query depending on what the command did
            if ([1, 2, 10, 20].includes(result.interpretedAs)) {
              refreshReports();
            }
            if ([11, 12].includes(result.interpretedAs)) refreshExpenses();
            if ([30].includes(result.interpretedAs)) refreshInboxes();
          })
          .catch((err) => {
            showToast({ title: t("error"), text: t("genericError"), type: "error" });
          });
      }
      setCommandText("");
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCommandText(event.target.value);
  };

  return (
    <input
      id="commandBox"
      type="text"
      onChange={handleChange}
      onKeyPress={handleKeyPress}
      placeholder={placeholder}
      value={commandText}
      title={t("commandBoxTitle")}
      disabled={disabled}
    />
  );
};

CommandBox.propTypes = {
  placeholder: PropTypes.string
};

export default CommandBox;
