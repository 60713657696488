import React, { useEffect, useState } from "react";
import { FormGroup, ControlLabel, HelpBlock } from "react-bootstrap";
// import Select, { createFilter } from "react-select";
import Select, { InputActionMeta, SingleValue } from "react-select";
import { FixedSizeList } from "react-window";

interface SelectGroupProps {
  field: string;
  value: any;
  label: JSX.Element | string;
  options: SelectGroupOption[];
  error?: string;
  readOnly?: boolean;
  onChange: (newValue: any) => void;
  onInputChange?: (newValue: string, actionMeta: InputActionMeta) => void;
  isLoading?: boolean;
  required?: boolean;
}
interface SelectGroupOption {
  label: string;
  value: any;
  isDisabled?: boolean;
}
const SelectGroup = ({ field, value, label, options, error, readOnly, onChange, onInputChange, isLoading, required }: SelectGroupProps) => {
  const [stateValue, setStateValue] = useState<any>(value || "");

  // Update stateValue if the value prop has changed (and has a value, including 0), and exists in the available options
  useEffect(() => {
    if ((value || value === 0) && value !== stateValue && options.find((o) => !o.isDisabled)) {
      setStateValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  // New value received from select
  const handleChange = (selectedOption: SingleValue<SelectGroupOption>) => {
    if (!selectedOption) return; // Must have selected an option
    if (!selectedOption.value && selectedOption.value !== 0) return; // Option must have a value (0 is a valid value)
    setStateValue(selectedOption.value);
    if (onChange) onChange(selectedOption.value);
  };

  // Enable shift-home and shift-end
  const handleKeyDown = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    switch (evt.key) {
      case "Home":
        evt.preventDefault();

        if (evt.shiftKey) (evt.target as HTMLInputElement).selectionStart = 0;
        else (evt.target as HTMLInputElement).setSelectionRange(0, 0);
        break;
      case "End":
        evt.preventDefault();
        const len = (evt.target as HTMLInputElement).value.length;
        if (evt.shiftKey) (evt.target as HTMLInputElement).selectionEnd = len;
        else (evt.target as HTMLInputElement).setSelectionRange(len, len);
        break;
      default: {
      }
    }
  };

  // Find the corresponding option to the selected value.
  // Use value of first enabled option if no value is provided in props
  let selectedOption = null;
  if (stateValue && stateValue !== 0) selectedOption = options.find((o) => !o.isDisabled && o.value === stateValue);
  if (!selectedOption) selectedOption = options.find((o) => !o.isDisabled);

  return (
    <FormGroup validationState={error ? "error" : null} controlId={field}>
      <ControlLabel>
        {label} {required && <span className="required-asterisk">*</span>}
      </ControlLabel>
      <Select
        name={field}
        value={selectedOption}
        options={options}
        onInputChange={onInputChange || undefined}
        isDisabled={readOnly}
        isLoading={isLoading}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        className="react-select-main"
        classNamePrefix="react-select"
        components={{ MenuList }}
        // Using the following filter, accents are respected and "ølen" will not match for "olen", which is good
        // However, this also means "belen" will not match "belén", which is bad.
        // We might want to fine tune a custom filter at some point.
        // filterOption={createFilter({ ignoreAccents: false })}
      />

      {error && <HelpBlock>{error}</HelpBlock>}
    </FormGroup>
  );
};

const menuItemHeight = 30;
const menuMaxVisibleItems = 12;
const MenuList = (props: any) => {
  const { options, children, getValue } = props;
  if (!children || !children.length || children.length < 1) return null; // TODO: Maybe show a "no hits found" message?

  // Adjust the menu height to match the number of elements, up to a maximum
  const height = 5 + menuItemHeight * Math.min(menuMaxVisibleItems, children.length);

  // Get the current value, and set the scroll offset so that it shows on top of the list
  const [value] = getValue();
  let initialScrollOffset = options.indexOf(value) * menuItemHeight;
  // But only if the list is actually long enough to scroll at all
  let overflowHeight = children.length * menuItemHeight - menuMaxVisibleItems * menuItemHeight;
  if (overflowHeight < 0) overflowHeight = 0;
  initialScrollOffset = Math.min(initialScrollOffset, overflowHeight);

  const childrenWithoutMouse = children.map((key: any, i: any) => {
    // We nuke this props to prevent major input lag. They are replaced with CSS hovers at the cost of some keyboard accessibility.
    delete key.props.innerProps.onMouseMove;
    delete key.props.innerProps.onMouseOver;

    return key;
  });

  const lastItemClass = children.length <= menuMaxVisibleItems ? "react-select-short-list-last-option-wrapper" : "";

  return (
    <FixedSizeList width="100%" height={height} itemCount={children.length} itemSize={menuItemHeight} initialScrollOffset={initialScrollOffset}>
      {({ index, style }) => (
        <div className={index === children.length - 1 ? lastItemClass : ""} style={style}>
          {childrenWithoutMouse[index]}
        </div>
      )}
    </FixedSizeList>
  );
};

export default SelectGroup;
