import { useTranslation } from "react-i18next";
import moment from "moment";
import { Button } from "react-bootstrap";
import Icon from "../common/Icon";
import { capitalize } from "../../shared/utils/helpers";
import "../../styles/reportsegmentlist.css";
import { ReportSegment } from "../../shared/types";
import { lodgingTypes } from "../../shared/utils/constants";

interface ReportSegmentListItemProps {
  segment: ReportSegment;
  onDelete: (segment: ReportSegment) => void;
  onEdit: (segment: ReportSegment) => void;
  readOnly?: boolean;
  overlaps?: boolean;
}
const ReportSegmentListItem = ({ segment, readOnly, onDelete, onEdit, overlaps }: ReportSegmentListItemProps) => {
  const [t] = useTranslation();

  const { location, starts, stops, lodgingType, deleted } = segment;

  const lodgingTypeDescriptions = [""]; // There is no type with ID 0
  for (const lt of lodgingTypes) {
    lodgingTypeDescriptions[lt.id] = t(`lodgingTypes.${lt.description}`);
  }
  // const lodgingTypes = ["", t("lodgingTypes.hotel"), t("lodgingTypes.dormWithKitchen"), t("lodgingTypes.dormWithoutKitchen"), t("lodgingTypes.none")];

  return deleted ? null : (
    <div className="reportsegment-list-item" onClick={() => onEdit(segment)}>
      <div className="reportsegment-list-item-location">
        {overlaps && <Icon icon="alertCircle" title={t("reportSegment.thisSegmentOverlaps")} extraClassName="segment-overlaps-alert" />}
        <strong>{location}</strong>
      </div>
      <div className="reportsegment-list-item-dates">
        <strong>{moment(starts).format("D.MMM")}</strong>
        {moment(starts).format(" YYYY (HH:mm)")} - <strong>{moment(stops).format("D.MMM")}</strong>
        {moment(stops).format(" YYYY (HH:mm)")}
      </div>
      <div className="reportsegment-list-item-lodging">{lodgingTypeDescriptions[lodgingType]}</div>
      {!readOnly && (
        <div className="reportsegment-list-item-buttons">
          <Button
            title={capitalize(t("delete"))}
            onClick={(e) => {
              onDelete(segment);
              e.stopPropagation();
            }}
          >
            <Icon icon="trash" />
          </Button>
        </div>
      )}
    </div>
  );
};

export default ReportSegmentListItem;
