import { useEffect } from "react";

// A barebones event bus for passing strings across the application
export type BusEvent = "BERSERK_MODE" | "OPEN_SIDEBAR";

let subscribers: Function[] = [];

const subscribe = (callback: Function) => {
  subscribers = [...subscribers, callback];
  return () => {
    subscribers = subscribers.filter((subscriber) => subscriber !== callback);
  };
};

export const broadcast = (event: BusEvent) => {
  subscribers.forEach((callback) => callback(event));
};

export const useBus = (callback: Function) => {
  useEffect(() => subscribe(callback));
  return broadcast;
};
