import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import flatpickr from "flatpickr";
import i18n from "../../shared/i18n/i18nConfig";
//eslint-disable-next-line
import { Norwegian } from "flatpickr/dist/l10n/no.js";
import "../../styles/flatpickr.css";
import "../../styles/temporalpicker.css";
import { Instance } from "flatpickr/dist/types/instance";
import { Options } from "flatpickr/dist/types/options";

const fullFormat = "YYYY-MM-DD HH:mm";
type TemporalPickerResult = { start: string; end?: string };
interface TemporalPickerProps {
  startDate: string;
  endDate?: string;
  onChange: (val: TemporalPickerResult, fromConstructor?: boolean) => void; // Function that accepts a single moment (if useRange is false) or two moments (if useRange is true)
  readOnly?: boolean;
  useRange?: boolean; // Renders a rangepicker instead of single
  useTime?: boolean; // Renders timepicker(s) in addition to date
  parentEntityUuid?: string; // UUID of the entity that owns whatever we're outputting. This is really just used to force a reinit if updated/changed
}

const TemporalPicker = ({ startDate, endDate, onChange, readOnly, useRange, useTime, parentEntityUuid }: TemporalPickerProps) => {
  // Always fullFormat strings in state
  const [stateStart, setStateStart] = useState<string>(moment(startDate).format(fullFormat));
  const [stateEnd, setStateEnd] = useState<string>(moment(endDate || startDate).format(fullFormat));
  const [hasChosenEnd, setHasChosenEnd] = useState(false);

  // These are only used for initial flatpickr mounting, values are ignored
  const hiddenDateInput1 = useRef<HTMLInputElement>(null);
  const hiddenDateInput2 = useRef<HTMLInputElement>(null);
  const hiddenTimeInput1 = useRef<HTMLInputElement>(null);
  const hiddenTimeInput2 = useRef<HTMLInputElement>(null);

  // Visible input nodes
  const di1 = useRef<HTMLInputElement>(null);
  const di2 = useRef<HTMLInputElement>(null);
  const ti1 = useRef<HTMLInputElement>(null);
  const ti2 = useRef<HTMLInputElement>(null);

  // Flatpickr instances
  const dp1 = useRef<Instance>();
  const dp2 = useRef<Instance>();
  const tp1 = useRef<Instance>();
  const tp2 = useRef<Instance>();

  // USE CASES:
  // Report diet date (has default)
  // Segment range (has defaults from and to)
  // Expense date or range (has defaults)

  // TODO:
  // Deal with external forbidden dates/ranges, like overlapping segments

  // Accept a new start, end, or both
  // This will set local state, and emit to onChange if different than then current props
  const commitChange = (startVal?: string, endVal?: string, fromConstructor?: boolean) => {
    // Grab current values from state and props
    const currentState = { start: stateStart, end: stateEnd };
    // const currentProps = { start: moment(startDate).format(fullFormat), end: moment(endDate || startDate).format(fullFormat) };
    const newState = { start: startVal || stateStart, end: endVal || stateEnd };

    // Set new state if values have changed
    if (newState.start !== currentState.start) setStateStart(newState.start);
    if (newState.end !== currentState.end) setStateEnd(newState.end);

    // Emit onChange
    const result = useRange ? { start: newState.start, end: newState.end } : { start: newState.start };
    onChange(result, fromConstructor);
  };

  // This needs to run if the control rerenders with different useRange/useTime props, so we wrap it up here and call it on both mount and update
  const reInitialize = () => {
    const initialStart = moment(startDate).format(fullFormat);
    const initialEnd = moment(endDate || startDate).format(fullFormat);

    commitChange(initialStart, initialEnd, true);
    setHasChosenEnd(false);

    flatpickr.l10ns.default.firstDayOfWeek = 1;
    const defaultOptions: Options = {
      enableTime: false,
      time_24hr: true,
      allowInput: true,
      mode: useRange ? "range" : "single",
      dateFormat: "Y-m-d"
    };

    // Locale defaults to english unless overridden
    if (i18n.language === "nb" || i18n.language === "nn" || i18n.language === "no") defaultOptions.locale = Norwegian;

    const defaultOptionsTime: Options = {
      ...defaultOptions,
      enableTime: true,
      noCalendar: true,
      dateFormat: "H:i"
    };

    // Initialize picker(s) and input value(s)
    if (hiddenDateInput1.current && di1.current) {
      dp1.current = flatpickr(hiddenDateInput1.current, {
        ...defaultOptions,
        positionElement: di1.current,
        onChange: (val) => dp1.current && di1.current && onChangePicker(val, dp1.current, di1.current),
        onOpen: () => dp1.current && onOpenPicker(dp1.current)
      });
    }
    if (di1.current) di1.current.value = localize(initialStart, false);

    if (useTime && hiddenTimeInput1.current && ti1.current) {
      tp1.current = flatpickr(hiddenTimeInput1.current, {
        ...defaultOptionsTime,
        positionElement: ti1.current,
        onChange: (val) => tp1.current && ti1.current && onChangePicker(val, tp1.current, ti1.current),
        onOpen: () => tp1.current && onOpenPicker(tp1.current)
      });
      if (ti1.current) ti1.current.value = localize(initialStart, true);
    }

    // Initialize second picker(s) and input value(s) if using range
    if (useRange && hiddenDateInput2.current && di2.current) {
      dp2.current = flatpickr(hiddenDateInput2.current, {
        ...defaultOptions,
        positionElement: di2.current,
        onChange: (val) => dp2.current && di2.current && onChangePicker(val, dp2.current, di2.current),
        onOpen: () => dp2.current && onOpenPicker(dp2.current)
      });
      if (di2.current) di2.current.value = localize(initialEnd, false);

      if (useTime && hiddenTimeInput2.current && ti2.current) {
        tp2.current = flatpickr(hiddenTimeInput2.current, {
          ...defaultOptionsTime,
          positionElement: ti2.current,
          onChange: (val) => tp2.current && ti2.current && onChangePicker(val, tp2.current, ti2.current),
          onOpen: () => tp2.current && onOpenPicker(tp2.current)
        });
        if (ti2.current) ti2.current.value = localize(initialEnd, true);
      }
    }
  };

  // Reinitialize if config props or parent changed, doubles as a component constructor
  useEffect(() => {
    reInitialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useRange, useTime, parentEntityUuid]);

  // The initial onChange handlers for ref'd datepicker instances are closures referencing the state and onChange prop when we created the handlers
  // When state changes, or onChange changes (for example due to other widgets in ExpenseEditor updating the expense), we need to refresh the handlers
  // (onChange was added to the dep array due to TTN-412)
  useEffect(() => {
    if (dp1.current) {
      dp1.current.set("onChange", (val: Date[]) => dp1.current && di1.current && onChangePicker(val, dp1.current, di1.current));
      dp1.current.set("onOpen", () => dp1.current && onOpenPicker(dp1.current));
    }
    if (tp1.current) {
      tp1.current.set("onChange", (val: Date[]) => tp1.current && ti1.current && onChangePicker(val, tp1.current, ti1.current));
      tp1.current.set("onOpen", () => tp1.current && onOpenPicker(tp1.current));
    }
    if (dp2.current) {
      dp2.current.set("onChange", (val: Date[]) => dp2.current && di2.current && onChangePicker(val, dp2.current, di2.current));
      dp2.current.set("onOpen", () => dp2.current && onOpenPicker(dp2.current));
    }
    if (tp2.current) {
      tp2.current.set("onChange", (val: Date[]) => tp2.current && ti2.current && onChangePicker(val, tp2.current, ti2.current));
      tp2.current.set("onOpen", () => tp2.current && onOpenPicker(tp2.current));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateStart, stateEnd, onChange]);

  // Value entered using keyboard in an input box
  // Attempt to parse and validate, then set state and picker value(s)
  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>, pickerRef?: Instance, inputRef?: HTMLInputElement) => {
    if (!pickerRef || !inputRef) return;
    const parts = getPartsFromRef(pickerRef);
    const parsed = parseInput(e.target.value, parts.isTimePart);
    if (parsed) {
      const source = moment.utc(parsed);
      inputRef.classList.remove("range-error");

      if (parts.isStartPart) {
        let newStart = stateStart;
        if (parts.isTimePart) {
          // Start time changed
          const tempStart = moment.utc(stateStart).hour(source.hour()).minute(source.minute()).format(fullFormat);
          // Range validation
          if (hasChosenEnd && moment(tempStart).isAfter(stateEnd)) {
            inputRef.classList.add("range-error");
            return;
          }
          commitChange(tempStart, !useRange ? tempStart : undefined);
          newStart = tempStart;
          if (tp1.current) tp1.current._setHoursFromDate(moment(tempStart).toDate());
        } else {
          // Start date changed
          const tempStart = moment.utc(stateStart).year(source.year()).month(source.month()).date(source.date()).format(fullFormat);
          // Range validation
          if (hasChosenEnd && moment(tempStart).isAfter(stateEnd)) {
            inputRef.classList.add("range-error");
            return;
          }
          commitChange(tempStart, !useRange ? tempStart : undefined);
          newStart = tempStart;

          // Inform one or both datepickers about the new selection
          // Whichever one is open will then immediately rerender with the new selection and range indicator if applicable
          if (useRange && dp1.current && dp2.current) {
            // Update selection in both date pickers
            dp1.current.setDate([tempStart, stateEnd]);
            dp2.current.setDate([tempStart, stateEnd]);
          } else {
            // Single mode, just update the from date picker selection
            if (dp1.current) dp1.current.setDate(tempStart);
          }
        }

        if (useRange && !hasChosenEnd) {
          // We haven't actively chosen an end date yet
          // If the new start date is after the current end date, push the end date to match the new start date
          let tempEnd = stateEnd;
          const currentEndMoment = moment(stateEnd);
          if (currentEndMoment.isBefore(newStart, "minute")) {
            tempEnd = newStart;
            commitChange(undefined, tempEnd);
            // Update end input(s)
            if (di2.current) di2.current.value = localize(tempEnd, false);
            if (useTime && ti2.current) ti2.current.value = localize(tempEnd, true);
          }
        }
      } else {
        if (parts.isTimePart) {
          // End time changed
          const tempEnd = moment.utc(stateEnd).hour(source.hour()).minute(source.minute()).format(fullFormat);
          // Range validation
          if (moment(tempEnd).isBefore(stateStart)) {
            inputRef.classList.add("range-error");
            return;
          }
          commitChange(undefined, tempEnd);
          setHasChosenEnd(true);
          if (tp2.current) tp2.current._setHoursFromDate(moment(tempEnd).toDate());
        } else {
          // End date changed
          const tempEnd = moment.utc(stateEnd).year(source.year()).month(source.month()).date(source.date()).format(fullFormat);
          // Range validation
          if (moment(tempEnd).isBefore(stateStart)) {
            inputRef.classList.add("range-error");
            return;
          }
          commitChange(undefined, tempEnd);
          setHasChosenEnd(true);
          if (dp1.current) dp1.current.setDate([stateStart, tempEnd]);
          if (dp2.current) dp2.current.setDate([stateStart, tempEnd]);
        }
      }
    }
  };

  // When an input gets focus, open the related picker
  // Intercept event propagation to prevent internal flatpickr capture
  const onClickOrFocusInput = (e: React.MouseEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement>, pickerRef: Instance) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    if (!pickerRef.isOpen) pickerRef.open();
  };

  // When an input loses focus, close the picker and render the currently chosen date as a localized string
  // Intercept event propagation to prevent internal flatpickr capture
  const onBlurInput = (e: React.FocusEvent<HTMLInputElement>, pickerRef: Instance, inputRef: HTMLInputElement) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    const parts = getPartsFromRef(pickerRef);
    if (!parts.isTimePart && pickerRef.isOpen) pickerRef.close();
    if (inputRef) {
      inputRef.value = localize(parts.statePart, parts.isTimePart);
      inputRef.classList.remove("range-error");
    }
  };

  // Fired when a picker opens
  // This is a good time to set range/minmax values based on state
  // For datepickers, also focus on the currently selected date (to compensate for messing with the other end of the range)
  const onOpenPicker = (pickerRef: Instance) => {
    // Close other pickers
    const dp1c = dp1.current;
    const dp2c = dp2.current;
    const tp1c = tp1.current;
    const tp2c = tp2.current;
    if (dp1c && pickerRef !== dp1c && dp1c.isOpen) dp1c.close();
    if (dp2c && useRange && pickerRef !== dp2c && dp2c.isOpen) dp2c.close();
    if (tp1c && useTime && pickerRef !== tp1c && tp1c.isOpen) tp1c.close();
    if (tp2c && useRange && useTime && pickerRef !== tp2c && tp2c.isOpen) tp2c.close();

    const parts = getPartsFromRef(pickerRef);
    if (parts.isTimePart) {
      const tempStart = moment(stateStart).format("HH:mm");
      const tempEnd = moment(stateEnd).format("HH:mm");
      const startAndEndIsSameDay = moment(stateStart).isSame(stateEnd, "day");
      if (parts.isStartPart && tp1c) {
        // Time picker start
        tp1c.setDate(tempStart);
        // If range mode, and start/end day is the same, limit the max time selection
        if (useRange && hasChosenEnd && startAndEndIsSameDay) {
          tp1c.set("maxDate", tempEnd);
        } else {
          tp1c.set("maxDate", undefined);
        }
      } else {
        // Time picker end
        if (tp2c) {
          tp2c.setDate(tempEnd);
          if (startAndEndIsSameDay) {
            tp2c.set("minDate", tempStart);
          } else {
            tp2c.set("minDate", undefined);
          }
        }
      }
    } else {
      const tempStart = moment(stateStart).format("YYYY-MM-DD");
      const tempEnd = moment(stateEnd).format("YYYY-MM-DD");
      if (parts.isStartPart && dp1c) {
        // Date picker start
        if (useRange) {
          dp1c.set("onDayCreate", (dObj: Date[], dStr: string, fp: Instance, dayElem: any) => {
            if (moment(dayElem.dateObj).format("YYYY-MM-DD") === tempStart) dayElem.classList.add("last-selected");
          });
          if (hasChosenEnd) {
            // We're in range mode, and we've already picked an end date during the component's lifetime
            // This means we're likely modifying a previous choice
            // Reset the start selection and define the end selection so we can pick the start part of the range
            dp1c.set("disable", [
              function (date: Date) {
                return moment(date).isAfter(tempEnd);
              }
            ]);
            dp1c.setDate(["", tempEnd]);
            dp1c.set("mode", "range");
          } else {
            // We haven't picked an end date yet in the component's lifetime, so all options are open for picking a start date
            dp1c.setDate([tempStart, ""]);
            dp1c.set("mode", "single");
          }
        } else {
          // If single mode, keep the start selection and don't limit anything
          dp1c.setDate(tempStart);
        }
        dp1c.jumpToDate(tempStart);
      } else {
        if (dp2c) {
          // Date picker end
          dp2c.set("onDayCreate", (dObj: Date[], dStr: string, fp: Instance, dayElem: any) => {
            if (moment(dayElem.dateObj).format("YYYY-MM-DD") === tempEnd) dayElem.classList.add("last-selected");
          });
          dp2c.set("disable", [
            function (date: Date) {
              return moment.utc(date).isBefore(tempStart);
            }
          ]);
          dp2c.setDate([tempStart, ""]);
          dp2c.jumpToDate(tempEnd);
        }
      }
    }
  };

  // Value changed by clicking a date in flatpickr
  // If we have a value, assume it's valid and within acceptable range since invalid ranges should not be available choices in the first place
  // Set state and input value, then unfocus the input (the picker closes automatically)
  const onChangePicker = (val: Date[], pickerRef: Instance, inputRef: HTMLInputElement) => {
    const dp1c = dp1.current;
    const dp2c = dp2.current;
    const ti1c = ti1.current;
    const ti2c = ti2.current;
    const di1c = di1.current;
    const di2c = di2.current;

    const parts = getPartsFromRef(pickerRef);

    // Grab the new value. The end date picker will return an array due to operating in range mode, so grab the second item from it
    const source = pickerRef === dp2c ? moment(val[1]) : moment(val[0]);

    // If we're not getting a value, just return. This should not happen, but apparently it does sometimes
    if (!val || val.length === 0) return;

    if (parts.isStartPart) {
      let newStart = stateStart;
      if (parts.isTimePart) {
        // Start time changed
        const tempStart = moment.utc(stateStart).hour(source.hour()).minute(source.minute()).format(fullFormat);

        commitChange(tempStart, !useRange ? tempStart : undefined);
        newStart = tempStart;
        if (ti1c) ti1c.value = localize(tempStart, true);
      } else {
        // Start date changed
        const startMoment = moment.utc(stateStart).year(source.year()).month(source.month()).date(source.date());

        // Check if we're setting start date equal to the end date, and the current end time is earlier than the new start time
        let startTimeChanged = false;
        if (useRange) {
          const currentEnd = moment.utc(stateEnd);
          const newStartAndEndIsSameDay = startMoment.isSame(currentEnd, "day");
          const newStartIsAfterEnd = startMoment.isAfter(currentEnd);
          if (newStartAndEndIsSameDay && newStartIsAfterEnd) {
            // Same day set, and the new start time is now invalid. Set it equal to end time.
            startMoment.hour(currentEnd.hour());
            startMoment.minute(currentEnd.minute());
            startTimeChanged = true;
          }
        }
        const tempStart = startMoment.format(fullFormat);
        newStart = tempStart;
        commitChange(tempStart, !useRange ? tempStart : undefined);
        if (di1c) di1c.value = localize(tempStart, false);
        if (ti1c && startTimeChanged) ti1c.value = localize(tempStart, true);
      }

      if (useRange && !hasChosenEnd) {
        // We haven't actively chosen an end date yet
        // If the new start date is after the current end date, push the end date to match the new start date
        let tempEnd = stateEnd;
        const currentEndMoment = moment(stateEnd);
        if (currentEndMoment.isBefore(newStart, "minute")) {
          tempEnd = newStart;
          commitChange(undefined, tempEnd);
          // Update end input(s)
          if (di2c) di2c.value = localize(tempEnd, false);
          if (ti2c && useTime) ti2c.value = localize(tempEnd, true);
        }
        if (!parts.isTimePart && dp2c) {
          // We're in range mode, we haven't actively chosen an end date yet, and we just changed the start date
          // The user probably wants to pick an end date too at this point, so send her over there
          dp2c.open();
        }
      }
    } else {
      if (parts.isTimePart) {
        // End time changed
        const tempEnd = moment.utc(stateEnd).hour(source.hour()).minute(source.minute()).format(fullFormat);
        commitChange(undefined, tempEnd);
        setHasChosenEnd(true);
        if (ti2c) ti2c.value = localize(tempEnd, true);
      } else {
        // End date changed
        const endMoment = moment.utc(stateEnd).year(source.year()).month(source.month()).date(source.date());

        // Check if we're setting start date equal to the end date, and the current end time is earlier than the new start time
        let endTimeChanged = false;
        if (useRange) {
          const currentStart = moment.utc(stateStart);
          const newEndAndStartIsSameDay = endMoment.isSame(currentStart, "day");
          const newEndIsBeforeStart = endMoment.isBefore(currentStart);
          if (newEndAndStartIsSameDay && newEndIsBeforeStart) {
            // Same day set, new end time is now invalid. Set it equal to start time.
            endMoment.hour(currentStart.hour());
            endMoment.minute(currentStart.minute());
            endTimeChanged = true;
          }
        }

        const tempEnd = endMoment.format(fullFormat);
        commitChange(undefined, tempEnd);
        setHasChosenEnd(true);
        if (dp1c) dp1c.setDate([stateStart, tempEnd]);
        if (di2c) di2c.value = localize(tempEnd, false);
        if (ti2c && endTimeChanged) ti2c.value = localize(tempEnd, true);
      }
    }
  };

  // There's a bug in flatpickr that screws up onKeyDown events despite keyboard input being allowed
  // This workaround at least enables moving the caret left and right using arrow keys, while we wait for a fix
  // https://github.com/flatpickr/flatpickr/issues/1329
  // https://github.com/flatpickr/flatpickr/issues/1688
  // https://github.com/IBM/carbon-components-react/issues/2011
  const onInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, inputRef: HTMLInputElement) => {
    if (inputRef && inputRef.selectionStart) {
      if (e.key === "ArrowLeft") {
        // Arrow left
        if (inputRef.selectionStart > 0) {
          inputRef.selectionStart -= 1;
          inputRef.selectionEnd = inputRef.selectionStart;
        }
      }
      if (e.key === "ArrowRight") {
        // Arrow right
        const curLength = inputRef.value.length;
        if (inputRef.selectionStart < curLength && inputRef.selectionEnd) {
          inputRef.selectionEnd += 1;
          inputRef.selectionStart = inputRef.selectionEnd;
        }
      }
    }
  };

  const getPartsFromRef = (pickerRef: Instance) => {
    const isStartPart = pickerRef === dp1.current || pickerRef === tp1.current ? true : false;
    const statePart = isStartPart ? stateStart : stateEnd;
    const isTimePart = pickerRef === tp1.current || pickerRef === tp2.current ? true : false;
    return { statePart, isStartPart, isTimePart };
  };

  const parseInput = (val: string, timePart: boolean) => {
    const formats = timePart
      ? ["HH:mm", "H:mm", "HH:m", "H:m"]
      : [
          "YYYY-MM-DD",
          "DD.MM.YYYY",
          "DD.MM.YY",
          "DD.MMMM YYYY",
          "DD.MMM YYYY",
          "DD.MMMM YY",
          "DD.MMM YY",
          "DD.MMMM",
          "DD.MMM",
          "DD.MM"
          // TODO: weekday parsing? Stricter parsing with more formats specified? Localization?
        ];
    const d = moment.utc(val, formats, "nb", false);
    if (d && d.year() > 1990 && d.year() < 2040) return d.toISOString(); // Only return hits within a reasonable range
    return undefined;
  };

  // For rendering a selected value in an input
  // Use Norwegian notation for consistency and to make click-to-edit more predictable. Maybe reconsider later
  const localize = (value: string, timePart: boolean) => {
    const format = timePart ? "HH:mm" : "DD.MM.YYYY";
    const localized = moment.utc(value).format(format);
    return localized;
  };

  // Debug
  // const logDates = () => {
  //   console.log("------------");
  //   if (dp1.current) {
  //     const v = dp1.current.selectedDates || [];
  //     const from = v[0] ? moment(v[0]).format("HH:mm") : "";
  //     const to = v[1] ? moment(v[1]).format("HH:mm") : "";
  //     console.log("tp1", from, to);
  //   }
  //   if (tp1.current) {
  //     const v = tp1.current.selectedDates || [];
  //     const from = v[0] ? moment(v[0]).format("HH:mm") : "";
  //     const to = v[1] ? moment(v[1]).format("HH:mm") : "";
  //     console.log("tp1", from, to);
  //   }
  //   if (dp2.current) {
  //     const v = dp2.current.selectedDates || [];
  //     const from = v[0] ? moment(v[0]).format("HH:mm") : "";
  //     const to = v[1] ? moment(v[1]).format("HH:mm") : "";
  //     console.log("dp2", from, to);
  //   }
  //   if (tp2.current) {
  //     const v = tp2.current.selectedDates || [];
  //     const from = v[0] ? moment(v[0]).format("HH:mm") : "";
  //     const to = v[1] ? moment(v[1]).format("HH:mm") : "";
  //     console.log("tp2", from, to);
  //   }
  // };

  // Default: <input date>
  // useTime: <input date> <input time>
  // useRange: <input date> <input date>
  // useTime useRange: <input date> <input time> <input date> <input time>

  const d1class = `form-control ${useRange ? (useTime ? "date-from-with-time" : "date-from") : useTime ? "date-single-with-time" : "date-single"}`;
  const t1class = `form-control ${useRange ? "time-from" : "time-single"}`;
  const d2class = `form-control ${useTime ? "date-to-with-time" : "date-to"}`;
  const t2class = `form-control time-to`;

  return (
    <div className="temporal-picker-wrapper">
      <input ref={hiddenDateInput1} style={{ display: "none" }} />
      <input
        ref={di1}
        type="text"
        className={d1class}
        onClick={(e) => dp1.current && onClickOrFocusInput(e, dp1.current)}
        onFocus={(e) => dp1.current && onClickOrFocusInput(e, dp1.current)}
        onBlur={(e) => dp1.current && di1.current && onBlurInput(e, dp1.current, di1.current)}
        onChange={(e) => dp1.current && di1.current && onChangeInput(e, dp1.current, di1.current)}
        onKeyDown={(e) => di1.current && onInputKeyDown(e, di1.current)}
        disabled={readOnly}
      />
      {useTime && (
        <React.Fragment>
          <input ref={hiddenTimeInput1} style={{ display: "none" }} />
          <input
            ref={ti1}
            type="text"
            className={t1class}
            onClick={(e) => tp1.current && onClickOrFocusInput(e, tp1.current)}
            onFocus={(e) => tp1.current && onClickOrFocusInput(e, tp1.current)}
            onBlur={(e) => tp1.current && ti1.current && onBlurInput(e, tp1.current, ti1.current)}
            onChange={(e) => tp1.current && ti1.current && onChangeInput(e, tp1.current, ti1.current)}
            onKeyDown={(e) => ti1.current && onInputKeyDown(e, ti1.current)}
            disabled={readOnly}
          />
        </React.Fragment>
      )}

      {useRange && (
        <React.Fragment>
          <input type="text" className="form-control range-splitter" disabled value="&#10142;" />
          <input ref={hiddenDateInput2} style={{ display: "none" }} />
          <input
            ref={di2}
            type="text"
            className={d2class}
            onClick={(e) => dp2.current && onClickOrFocusInput(e, dp2.current)}
            onFocus={(e) => dp2.current && onClickOrFocusInput(e, dp2.current)}
            onBlur={(e) => dp2.current && di2.current && onBlurInput(e, dp2.current, di2.current)}
            onChange={(e) => dp2.current && di2.current && onChangeInput(e, dp2.current, di2.current)}
            onKeyDown={(e) => di2.current && onInputKeyDown(e, di2.current)}
            disabled={readOnly}
          />
        </React.Fragment>
      )}
      {useRange && useTime && (
        <React.Fragment>
          <input ref={hiddenTimeInput2} style={{ display: "none" }} />
          <input
            ref={ti2}
            type="text"
            className={t2class}
            onClick={(e) => tp2.current && onClickOrFocusInput(e, tp2.current)}
            onFocus={(e) => tp2.current && onClickOrFocusInput(e, tp2.current)}
            onBlur={(e) => tp2.current && ti2.current && onBlurInput(e, tp2.current, ti2.current)}
            onChange={(e) => tp2.current && ti2.current && onChangeInput(e, tp2.current, ti2.current)}
            onKeyDown={(e) => ti2.current && onInputKeyDown(e, ti2.current)}
            disabled={readOnly}
          />
        </React.Fragment>
      )}
    </div>
  );
};

export default TemporalPicker;
