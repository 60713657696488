import React, { useCallback, useEffect, useState } from "react";
import { localizeDecimalSeparator } from "../../shared/utils/helpers";
import { FormGroup, FormControl, ControlLabel, HelpBlock, InputGroup } from "react-bootstrap";

// We operate on number|null internally, but null values should be treated as blank string for customvalue purposes
interface NumberFieldGroupProps {
  field: string;
  value: number | null; // We will infer a suitable starting value from other props if a null value is passed, but require an explicit null
  label?: string | HTMLElement | JSX.Element;
  error?: string;
  onChange?: (valueNumber: number | null, valueString: string) => void;
  placeholder?: string;
  readOnly?: boolean;
  hideLabel?: boolean;
  required?: boolean;
  addonLeft?: JSX.Element | HTMLElement | string;
  addonRight?: JSX.Element | HTMLElement | string;
  allowDecimals?: boolean; // If true, decimals are allowed. If not, we will enforce integer values only
  allowBlank?: boolean; // If true, blank values are allowed (and emitted as an empty string). Typically used for non-required customfields. If not allowed, we will always emit 0 (or the field's numeric minValue) if the users tries entering a blank value
  decimals?: number; //If allowDecimals is true and decimals is specified, we will render a fixed number of decimals (typically 2 for money and 4 for exchange rates)
  useHundredths?: boolean; // If true, we will accept and return all values in hundredths with no decimals (typically used for money)
  minValue?: number;
  maxValue?: number;
  showDebug?: boolean;
}
const NumberFieldGroup = ({
  field,
  value,
  label,
  error,
  onChange,
  placeholder,
  readOnly,
  hideLabel,
  required,
  addonLeft,
  addonRight,
  allowDecimals,
  allowBlank,
  decimals,
  useHundredths = false,
  minValue,
  maxValue,
  showDebug
}: NumberFieldGroupProps) => {
  const [defaultValueForBlank] = useState<null | number>(() => {
    let defaultValue: number | null = 0;
    if (minValue) defaultValue = minValue;
    if (allowBlank) defaultValue = null;
    return defaultValue;
  }); // // Default value to use if a useless one is passed down or entered by the user in the future

  // Get a renderable string version of a numeric value
  const getDisplayValue = useCallback(
    (val: number | null) => {
      if ((!val && val !== 0) || isNaN(val) || String(val).trim() === "") {
        if (defaultValueForBlank === null || defaultValueForBlank === undefined) return "";
        return String(defaultValueForBlank);
      }
      const output = useHundredths ? (val / 100).toFixed(2).toString() : val.toString();
      return localizeDecimalSeparator(String(output));
    },
    [defaultValueForBlank, useHundredths]
  );

  const [stateValue, setStateValue] = useState<null | number>(value || value === 0 ? Number(value) : defaultValueForBlank); // Internal number representation of value
  const [displayValue, setDisplayValue] = useState<null | string>(() => getDisplayValue(value)); // String representation for rendering in UI
  const [hasFocus, setHasFocus] = useState(false);

  // Update stateValue if the value prop has changed, and is not falsy, we're not currently focused/editing, and we have a truish or 0 value
  useEffect(() => {
    const newValue = value || value === 0 ? value : defaultValueForBlank;
    if (!hasFocus && (newValue || newValue === 0)) {
      const displayVal = getDisplayValue(newValue);
      setStateValue(newValue);
      setDisplayValue(displayVal);
    }
  }, [value, getDisplayValue, defaultValueForBlank, hasFocus]);

  // When the input is focused, set state focused
  const handleFocus = () => {
    setHasFocus(true);
  };

  // When the input is unfocused, render the current value and set state unfocused
  const handleBlur = () => {
    const displayVal = getDisplayValue(stateValue);
    setDisplayValue(displayVal);
    setHasFocus(false);
  };

  // User has changed input, parse it to a valid value
  const handleChange = (val: string) => {
    const valRaw = val;

    // Strip away all non-numeric stuff and clean up the input as best we can
    let parsed = val
      ? val
          .replace(/,/g, ".") // Replace commas with dots
          .replace(/[^\d.-]/g, "") // Remove non-digits and non-dots
          .replace(/(.*)\./, (x) => `${x.replace(/\./g, "")}.`) // Remove all dots except the last instance
      : "";
    if (!allowDecimals && parsed.trim() !== "" && parsed.includes(".")) parsed = parsed.split(".")[0]; // Strip decimals if not allowed

    // How are we looking so far?
    if (!parsed || parsed.trim() === "" || isNaN(Number(parsed))) {
      // The cleaned input is useless, use default
      acceptNewValue(defaultValueForBlank, String(defaultValueForBlank), valRaw);
    } else {
      // The cleaned value should be numeric by now
      let valNumber = Number(parsed);
      // Make sure it conforms to any defined limits
      if ((minValue || minValue === 0) && !isNaN(minValue) && valNumber < Number(minValue)) valNumber = Number(minValue);
      if ((maxValue || maxValue === 0) && !isNaN(maxValue) && valNumber > Number(maxValue)) valNumber = Number(maxValue);
      let valString = valNumber.toString();

      // Convert value to hundredths (typically for money fields) or fixed decimals (typically for exchangerates) if applicable
      if (useHundredths) {
        valNumber = Math.round(valNumber * 100);
        valString = valNumber.toString();
      } else if (allowDecimals && decimals) {
        valString = valNumber.toFixed(decimals);
        valNumber = parseFloat(valString);
      }
      acceptNewValue(valNumber, valString, valRaw);
    }
  };

  const acceptNewValue = (valNumber: number | null, valString: string, valRaw: string) => {
    // Set the new value and emit it.
    // Also mirror the raw input value to displayValue. Don't override what the user is typing yet, this is handled by onBlur
    setStateValue(valNumber);
    setDisplayValue(valRaw);
    if (onChange) onChange(valNumber, valString);
  };

  const mainCtrl = (
    <FormControl
      onChange={(event: React.FormEvent<FormControl & HTMLInputElement>) => handleChange(event.currentTarget.value)}
      onFocus={handleFocus}
      onBlur={handleBlur}
      value={displayValue || ""}
      name={field}
      placeholder={placeholder}
      readOnly={readOnly}
      componentClass="input"
      type="text"
    />
  );

  const mainGroup = (
    <InputGroup>
      {addonLeft && <InputGroup.Addon>{addonLeft}</InputGroup.Addon>}
      {mainCtrl}
      {addonRight && <InputGroup.Addon>{addonRight}</InputGroup.Addon>}
    </InputGroup>
  );

  return (
    <FormGroup validationState={error ? "error" : null} controlId={field}>
      {!hideLabel && (
        <ControlLabel>
          {label} {required && <span className="required-asterisk">*</span>}
        </ControlLabel>
      )}
      {addonLeft || addonRight ? mainGroup : mainCtrl}
      {error && <HelpBlock>{error}</HelpBlock>}

      {showDebug && (
        <div>
          <div>value: {stateValue ? stateValue.toString() : "null"}</div>
          <div>displayValue: {displayValue ? displayValue.toString() : "null"}</div>
          <div>hasFocus: {hasFocus.toString()}</div>
        </div>
      )}
    </FormGroup>
  );
};

export default NumberFieldGroup;
