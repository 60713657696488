import { useEffect, useRef } from "react";

interface IframeProps {
  content: string;
  frameBorder?: number;
}

const Iframe = ({ content = "", frameBorder = 0 }: IframeProps) => {
  const setFrameRef = (element: HTMLIFrameElement) => {
    frameRef.current = element;
  };

  const frameRef = useRef<HTMLIFrameElement | null>(null);

  useEffect(() => {
    let html = content.trim();
    if (!html) {
      html = `<html>
          <body>
            <div style='width: 100%; text-align:center; margin-top: 20px;'>
              <h3>No preview available</h3>
            </div>
          </body>
        </html>`;
    }
    if (!frameRef.current) return;
    const doc = frameRef.current.contentDocument;
    if (!doc) return;
    doc.open();
    doc.write(html);
    doc.close();
  }, [content]);

  return <iframe title="preview" ref={setFrameRef} frameBorder={frameBorder} />;
};

export default Iframe;
