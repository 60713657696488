import moment from "moment";
import { ReportCustomValue } from "../types";
import { uuid } from "../utils/helpers";

interface ReportCustomValueConstructorOptions {
  reportUuid: string;
  reportCustomFieldId: number;
  value?: string;
}
export const reportCustomValueConstructor = (opts: ReportCustomValueConstructorOptions): ReportCustomValue => {
  const { reportUuid, reportCustomFieldId, value = "" } = opts;
  const created = moment().toISOString();
  const reportCustomValueUuid = uuid();
  return {
    uuid: reportCustomValueUuid,
    changed: "",
    reportUuid,
    reportCustomFieldId,
    value,
    dirty: created,
    deleted: ""
  };
};
