import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import groupBy from "lodash/groupBy";
import ApprovalAttemptTimeline from "../approval/ApprovalAttemptTimeline";
import { ClassicApprovalAttempt, ReportApproval } from "../../shared/types";
import moment from "moment";

interface ReportApprovalStatusProps {
  reportApprovals: ReportApproval[];
}
const ReportApprovalStatus = ({ reportApprovals }: ReportApprovalStatusProps) => {
  const [t] = useTranslation();

  const [showAll, setShowAll] = useState(false);
  // reportApprovals can contain more than one approval attempt, and needs to be grouped by approvalAttemptUuid
  // All approvals are sequential. The last group is the newest, and the last item is the newest approval in a group.
  // If there is more than one group, show only the last one by default and let the user choose to display the other attempts manually.
  // This component converts and groups ReportApproval[] into ClassicApprovalAttempt[] for rendering on a report page

  const groupedApprovals = Object.values(groupBy(reportApprovals, (r) => r.approvalAttemptUuid)).reverse();
  const latestApprovalAttempt = groupedApprovals[0];
  const otherApprovalAttempts = groupedApprovals.length > 1 ? groupedApprovals.slice(1) : [];

  const reportApprovalsToClassicApprovalAttempt = (approvals: ReportApproval[]): ClassicApprovalAttempt => {
    const now = moment().toISOString();
    const firstApproval = approvals[0];
    const classicAttempt: ClassicApprovalAttempt = {
      approvalAttemptUuid: firstApproval.approvalAttemptUuid,
      created: firstApproval.created || firstApproval.changed || now,
      reportUuid: firstApproval.reportUuid,
      reportDescription: "",
      owner: {
        email: firstApproval.personEmail,
        name: firstApproval.personFirstName + " " + firstApproval.personLastName,
        parentId: 0,
        parentType: ""
      },
      steps: approvals.map((a) => ({
        ...a,
        created: a.created || a.changed || now,
        changed: a.changed || a.created || now,
        isMe: false
      }))
    };
    return classicAttempt;
  };

  return (
    <div className="report-component">
      <div className="report-component-header">
        <div className="report-component-title">{t("approvalStatus")}</div>
      </div>
      <div className="report-approval-status">
        <ApprovalAttemptTimeline approvalAttempt={reportApprovalsToClassicApprovalAttempt(latestApprovalAttempt)} />
      </div>

      {otherApprovalAttempts.length > 0 && (
        <div>
          <div className="report-approval-show-more-button">
            <Button bsStyle="link" onClick={() => setShowAll(!showAll)}>
              {t(showAll ? "hideCountPreviousApprovalAttempts" : "showCountPreviousApprovalAttempts", {
                count: otherApprovalAttempts.length
              })}
            </Button>
          </div>
          {showAll &&
            otherApprovalAttempts.map((attempt) => (
              <div className="report-approval-status" key={attempt[0].approvalAttemptUuid}>
                <ApprovalAttemptTimeline approvalAttempt={reportApprovalsToClassicApprovalAttempt(attempt)} />
              </div>
            ))}
        </div>
      )}

      <div className="clearfix" />
    </div>
  );
};

export default ReportApprovalStatus;
