import React from "react";
import emptyFolder from "../../images/empty-folder.svg";
import "../../styles/app.css";

interface EmptyProps {
  text: string;
}
const Empty = ({ text }: EmptyProps) => {
  return (
    <div className="empty-list">
      <div className="empty-list-inner">
        <img src={emptyFolder} alt={text} />
        <div className="empty-list-text">{text}</div>
      </div>
    </div>
  );
};

export default Empty;
