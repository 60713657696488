import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { attachmentThumbnail } from "../../shared/api/blob";
import Spinner from "../common/Spinner";
import { capitalize } from "../../shared/utils/helpers";
import i18n from "../../shared/i18n/i18nConfig";
import { Attachment, InboxAttachment } from "../../shared/types";
import { CancelablePromise, makeCancelable } from "../../utils/makeCancelable";
import { showPreview } from "../../utils/webclientStore";

interface AttachmentThumbnailProps {
  attachment: Attachment | InboxAttachment;
  readOnly?: boolean;
  showLabel?: boolean;
  showOriginalFilename?: boolean;
  showDelete?: boolean;
  onDelete?: (attachment: Attachment | InboxAttachment) => void;
  renderMode?: "table" | "drawer" | "cards";
}
const AttachmentThumbnail = ({
  attachment,
  readOnly,
  showLabel,
  showOriginalFilename,
  showDelete,
  onDelete,
  renderMode
}: AttachmentThumbnailProps) => {
  const [t] = useTranslation();
  const [imageUrl, setImageUrl] = useState("");
  const [broken, setBroken] = useState(false);

  // Fetch and show the current attachment prop
  useEffect(() => {
    const { blobUuid, mimeType } = attachment;
    let prom: CancelablePromise | null = null;
    if (isImageOrPdf()) {
      prom = makeCancelable(attachmentThumbnail(blobUuid, mimeType));
      prom.promise
        .then((res: Blob) => {
          if (res.size > 0) {
            // Valid blob received
            const imgurl = blobToImageUrl(res);
            setImageUrl(imgurl);
            setBroken(false);
          } else {
            // Empty blob received, thumbnail does not exist. Render a default icon.
            setImageUrl("");
            setBroken(true);
          }
        })
        .catch((err) => {
          setImageUrl("");
          setBroken(true);
        });
    }
    return () => {
      if (prom) prom.cancel();
      if (imageUrl) {
        // Clean up to allow garbage collection of the blob ref https://developer.mozilla.org/en-US/docs/Web/API/URL/revokeObjectURL
        (URL || webkitURL).revokeObjectURL(imageUrl);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachment]);

  const isAttachment = (value: Attachment | InboxAttachment): value is Attachment => {
    return value.hasOwnProperty("source");
  };

  const getLabel = () => {
    let labelString = "";
    const source = isAttachment(attachment) ? attachment.source : "";
    switch (attachment.mimeType) {
      case "image/jpeg":
      case "image/jpg": {
        labelString += `JPG-${t("thumbnails.image")}`;
        break;
      }
      case "image/png": {
        labelString += `PNG-${t("thumbnails.image")}`;
        break;
      }
      case "image/tiff": {
        labelString += `TIFF-${t("thumbnails.image")}`;
        break;
      }
      case "image/gif": {
        labelString += `GIF-${t("thumbnails.image")}`;
        break;
      }
      case "application/pdf": {
        labelString += `PDF-${t("thumbnails.document")}`;
        break;
      }
      case "text/html": {
        labelString += `HTML-${t("thumbnails.content")}`;
        break;
      }
      case "text/plain": {
        if (source === "cardtransaction") {
          labelString += capitalize(t("thumbnails.cardtransaction"));
        } else {
          labelString += capitalize(t("thumbnails.plainText"));
        }
        break;
      }
      default: {
        labelString += capitalize(t("thumbnails.attachment"));
      }
    }
    switch (source) {
      case "webupload": {
        labelString += ` ${t("thumbnails.from")} ${t("thumbnails.web")}`;
        break;
      }
      case "ncliapi": {
        labelString += ` ${t("thumbnails.from")} ${t("thumbnails.command")}`;
        break;
      }
      case "berghansen": {
        labelString += ` ${t("thumbnails.from")} ${t("thumbnails.bergHansen")}`;
        break;
      }
      case "sms": {
        labelString += ` ${t("thumbnails.from")} ${t("thumbnails.sms")}`;
        break;
      }
      case "api": {
        labelString += ` ${t("thumbnails.from")} ${t("thumbnails.api")}`;
        break;
      }
      case "commandapi": {
        labelString += ` ${t("thumbnails.from")} ${t("thumbnails.liteApp")}`;
        break;
      }
      case "email": {
        labelString += ` ${t("thumbnails.from")} ${t("thumbnails.email")}`;
        break;
      }
      case "mms": {
        labelString += ` ${t("thumbnails.from")} ${t("thumbnails.mms")}`;
        break;
      }
      default:
    }
    return labelString;
  };

  // Returns SVG path for popular non-image file types
  const getSvg = () => {
    const source = isAttachment(attachment) ? attachment.source : "";
    switch (attachment.mimeType) {
      case "application/pdf": {
        return (
          <svg viewBox="-40 -40 1100 1100">
            <path
              fill="#F10106"
              d="M842.012 589.48c-13.648-13.446-43.914-20.566-89.972-21.172-31.178-0.344-68.702 2.402-108.17 7.928-17.674-10.198-35.892-21.294-50.188-34.658-38.462-35.916-70.568-85.772-90.576-140.594 1.304-5.12 2.414-9.62 3.448-14.212 0 0 21.666-123.060 15.932-164.666-0.792-5.706-1.276-7.362-2.808-11.796l-1.882-4.834c-5.894-13.592-17.448-27.994-35.564-27.208l-10.916-0.344c-20.202 0-36.664 10.332-40.986 25.774-13.138 48.434 0.418 120.892 24.98 214.738l-6.288 15.286c-17.588 42.876-39.63 86.060-59.078 124.158l-2.528 4.954c-20.46 40.040-39.026 74.028-55.856 102.822l-17.376 9.188c-1.264 0.668-31.044 16.418-38.028 20.644-59.256 35.38-98.524 75.542-105.038 107.416-2.072 10.17-0.53 23.186 10.014 29.212l16.806 8.458c7.292 3.652 14.978 5.502 22.854 5.502 42.206 0 91.202-52.572 158.698-170.366 77.93-25.37 166.652-46.458 244.412-58.090 59.258 33.368 132.142 56.544 178.142 56.544 8.168 0 15.212-0.78 20.932-2.294 8.822-2.336 16.258-7.368 20.792-14.194 8.926-13.432 10.734-31.932 8.312-50.876-0.72-5.622-5.21-12.574-10.068-17.32zM211.646 814.048c7.698-21.042 38.16-62.644 83.206-99.556 2.832-2.296 9.808-8.832 16.194-14.902-47.104 75.124-78.648 105.066-99.4 114.458zM478.434 199.686c13.566 0 21.284 34.194 21.924 66.254s-6.858 54.56-16.158 71.208c-7.702-24.648-11.426-63.5-11.426-88.904 0 0-0.566-48.558 5.66-48.558v0zM398.852 637.494c9.45-16.916 19.282-34.756 29.33-53.678 24.492-46.316 39.958-82.556 51.478-112.346 22.91 41.684 51.444 77.12 84.984 105.512 4.186 3.542 8.62 7.102 13.276 10.65-68.21 13.496-127.164 29.91-179.068 49.862v0zM828.902 633.652c-4.152 2.598-16.052 4.1-23.708 4.1-24.708 0-55.272-11.294-98.126-29.666 16.468-1.218 31.562-1.838 45.102-1.838 24.782 0 32.12-0.108 56.35 6.072 24.228 6.18 24.538 18.734 20.382 21.332v0z"
            />
            <path
              fill="#777777"
              d="M917.806 229.076c-22.21-30.292-53.174-65.7-87.178-99.704s-69.412-64.964-99.704-87.178c-51.574-37.82-76.592-42.194-90.924-42.194h-496c-44.112 0-80 35.888-80 80v864c0 44.112 35.886 80 80 80h736c44.112 0 80-35.888 80-80v-624c0-14.332-4.372-39.35-42.194-90.924v0zM785.374 174.626c30.7 30.7 54.8 58.398 72.58 81.374h-153.954v-153.946c22.982 17.78 50.678 41.878 81.374 72.572v0zM896 944c0 8.672-7.328 16-16 16h-736c-8.672 0-16-7.328-16-16v-864c0-8.672 7.328-16 16-16 0 0 495.956-0.002 496 0v224c0 17.672 14.324 32 32 32h224v624z"
            />
          </svg>
        );
      }
      case "text/html": {
        if (source === "email") {
          return (
            <svg viewBox="0 0 24 24">
              <path
                fill="#777777"
                d="M4,4H20C21.1,4 22,4.9 22,6V18C22,19.1 21.1,20 20,20H4C2.89,20 2,19.1 2,18V6C2,4.89 2.89,4 4,4M12,11L20,6H4L12,11M4,18H20V8.37L12,13.36L4,8.37V18Z"
              />
            </svg>
          );
        }
        return (
          <svg viewBox="-80 -160 1440 1280">
            <path fill="#777777" d="M832 736l96 96 320-320-320-320-96 96 224 224z" />
            <path fill="#777777" d="M448 288l-96-96-320 320 320 320 96-96-224-224z" />
            <path fill="#777777" d="M701.298 150.519l69.468 18.944-191.987 704.026-69.468-18.944 191.987-704.026z" />
          </svg>
        );
      }
      case "text/plain": {
        if (source === "mms" || source === "sms") {
          return (
            <svg viewBox="0 0 1000 1000">
              <path
                fill="#777777"
                d="M726 470v-86h-86v86h86zM554 470v-86h-84v86h84zM384 470v-86h-86v86h86zM854 86c46 0 84 38 84 84v512c0 46-38 86-84 86h-598l-170 170v-768c0-46 38-84 84-84h684z"
              />
            </svg>
          );
        }
        if (source === "email") {
          return (
            <svg viewBox="0 0 24 24">
              <path
                fill="#777777"
                d="M4,4H20C21.1,4 22,4.9 22,6V18C22,19.1 21.1,20 20,20H4C2.89,20 2,19.1 2,18V6C2,4.89 2.89,4 4,4M12,11L20,6H4L12,11M4,18H20V8.37L12,13.36L4,8.37V18Z"
              />
            </svg>
          );
        }
        if (source === "cardtransaction") {
          return (
            <svg viewBox="0 0 24 24">
              <path
                fill="#777777"
                d="M20,8H4V6H20M20,18H4V12H20M20,4H4C2.89,4 2,4.89 2,6V18C2,19.1 2.9,20 4,20H20C21.1,20 22,19.1 22,18V6C22,4.89 21.1,4 20,4Z"
              />
            </svg>
          );
        }
        return (
          <svg viewBox="-40 -40 1100 1100">
            <path
              fill="#777777"
              d="M917.806 229.076c-22.212-30.292-53.174-65.7-87.178-99.704s-69.412-64.964-99.704-87.178c-51.574-37.82-76.592-42.194-90.924-42.194h-496c-44.112 0-80 35.888-80 80v864c0 44.112 35.888 80 80 80h736c44.112 0 80-35.888 80-80v-624c0-14.332-4.372-39.35-42.194-90.924zM785.374 174.626c30.7 30.7 54.8 58.398 72.58 81.374h-153.954v-153.946c22.984 17.78 50.678 41.878 81.374 72.572zM896 944c0 8.672-7.328 16-16 16h-736c-8.672 0-16-7.328-16-16v-864c0-8.672 7.328-16 16-16 0 0 495.956-0.002 496 0v224c0 17.672 14.326 32 32 32h224v624z"
            />
            <path fill="#777777" d="M736 832h-448c-17.672 0-32-14.326-32-32s14.328-32 32-32h448c17.674 0 32 14.326 32 32s-14.326 32-32 32z" />
            <path fill="#777777" d="M736 704h-448c-17.672 0-32-14.326-32-32s14.328-32 32-32h448c17.674 0 32 14.326 32 32s-14.326 32-32 32z" />
            <path fill="#777777" d="M736 576h-448c-17.672 0-32-14.326-32-32s14.328-32 32-32h448c17.674 0 32 14.326 32 32s-14.326 32-32 32z" />
          </svg>
        );
      }
      default: {
        return (
          <svg viewBox="-40 -40 1100 1100">
            <path
              fill="#777777"
              d="M917.806 229.076c-22.212-30.292-53.174-65.7-87.178-99.704s-69.412-64.964-99.704-87.178c-51.574-37.82-76.592-42.194-90.924-42.194h-496c-44.112 0-80 35.888-80 80v864c0 44.112 35.888 80 80 80h736c44.112 0 80-35.888 80-80v-624c0-14.332-4.372-39.35-42.194-90.924zM785.374 174.626c30.7 30.7 54.8 58.398 72.58 81.374h-153.954v-153.946c22.984 17.78 50.678 41.878 81.374 72.572zM896 944c0 8.672-7.328 16-16 16h-736c-8.672 0-16-7.328-16-16v-864c0-8.672 7.328-16 16-16 0 0 495.956-0.002 496 0v224c0 17.672 14.326 32 32 32h224v624z"
            />
          </svg>
        );
      }
    }
  };

  const blobToImageUrl = (blob: Blob): string => {
    const urlCreator = window.URL || window.webkitURL;
    return urlCreator.createObjectURL(blob);
  };

  const isImageOrPdf = () => {
    return (
      attachment &&
      attachment.mimeType &&
      (attachment.mimeType.toLowerCase().startsWith("image/") || attachment.mimeType.toLowerCase().startsWith("application/pdf"))
    );
  };

  const contentClass = "thumbnail-content";

  const notImage = <div className={contentClass}>{getSvg()}</div>;

  const label = getLabel();
  let title = label;

  if (isAttachment(attachment) && attachment.subject) title = `${attachment.subject} (${label})`;
  else if (attachment.originalFilename) title = `${attachment.originalFilename} (${label})`;

  return (
    <div className="thumbnail-wrapper" title={title}>
      {showDelete && !readOnly && (
        <button
          className="inbox-attach-button thumbnail-delete"
          title={t("thumbnails.deleteAttachment")}
          onClick={() => onDelete && onDelete(attachment)}
        >
          &times;
        </button>
      )}

      <div
        role="button"
        tabIndex={0}
        className={`thumbnail-content-wrapper ${broken ? "broken-thumbnail" : ""}`}
        onClick={() => {
          if (renderMode !== "drawer") {
            let pBody = "";
            let pBlobUuid = attachment.blobUuid;
            let pSource = "";
            if (isAttachment(attachment)) {
              pBody = attachment.externalContent ? "" : attachment.body;
              pBlobUuid = attachment.externalContent !== false ? attachment.blobUuid : ""; // Explicitly check that externalContent is "not false". (If undefined, it's also external.)
              pSource = attachment.source;
            }
            showPreview({
              mimeType: attachment.mimeType,
              body: pBody,
              blobUuid: pBlobUuid,
              source: pSource
            });
          }
        }}
      >
        {!isImageOrPdf() && notImage}

        {isImageOrPdf() && imageUrl && <img className={contentClass} src={imageUrl} alt={title} />}

        {isImageOrPdf() && !imageUrl && !broken && <Spinner size="43px" />}
        {broken && (
          <svg viewBox="0 0 24 24">
            <path
              fill="#777777"
              d="M19,3A2,2 0 0,1 21,5V11H19V13H19L17,13V15H15V17H13V19H11V21H5C3.89,21 3,20.1 3,19V5A2,2 0 0,1 5,3H19M21,15V19A2,2 0 0,1 19,21H19L15,21V19H17V17H19V15H21M19,8.5A0.5,0.5 0 0,0 18.5,8H5.5A0.5,0.5 0 0,0 5,8.5V15.5A0.5,0.5 0 0,0 5.5,16H11V15H13V13H15V11H17V9H19V8.5Z"
            />
          </svg>
        )}

        {showLabel && <div className="thumbnail-label">{label}</div>}

        {renderMode === "drawer" && (
          <div className="sub-details">
            {attachment.originalFilename ||
              new Date(attachment.changed).toLocaleDateString(i18n.language, {
                day: "2-digit",
                month: "short",
                year: "numeric"
              })}
          </div>
        )}

        {showOriginalFilename && <div className="thumbnail-label">{attachment.originalFilename}</div>}
      </div>
    </div>
  );
};

export default AttachmentThumbnail;
