// Tacks a cancel() method onto promises
// Use this for promises that could be inflight when a component unmounts, so we can clean up
// https://reactjs.org/blog/2015/12/16/ismounted-antipattern.html
export interface CancelablePromise {
  promise: Promise<any>;
  cancel: () => void;
}
export const makeCancelable = (promise: Promise<any>): CancelablePromise => {
  let hasCanceled_ = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then((val: any) => (hasCanceled_ ? reject({ isCanceled: true }) : resolve(val)));
    promise.catch((error: any) => (hasCanceled_ ? reject({ isCanceled: true }) : reject(error)));
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true;
    }
  };
};
