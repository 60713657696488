import { Row, Col, Grid } from "react-bootstrap";

import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

import {
  useUserConfiguration,
  useReports,
  useInboxes,
  refreshReports,
  refreshInboxes,
  useOfficialRates,
  useCountries,
  useMajorCities
} from "../../shared/queries/queries";

import PageHeader from "../common/PageHeader";
import ReportList from "../report/ReportList";
import ExpensesPage from "../expense/ExpensesPage";
import ExpensePage from "../expense/ExpensePage";
import MileagesPage from "../mileage/MileagesPage";
import InboxList from "../inbox/InboxList";
import Uploader from "../common/Uploader";

import CreateButtons from "../common/CreateButtons";
import AttachmentPreview from "../attachment/AttachmentPreview";
import { PoseGroup } from "react-pose";
import HomePagePoseWrapper from "./HomePagePoseWrapper";
import { useStore } from "../../shared/store/store";
import { setRefreshingReports, setRefreshingInboxes } from "../../utils/webclientStore";
import Spinner from "../common/Spinner";

const HomePage = () => {
  const [t] = useTranslation();
  const history = useHistory();
  const location = useLocation();

  // Monitor this to trigger a rerender if we switch users
  useStore((state) => state.currentUserId);

  const refreshingReports: boolean = useStore((state) => state.clientSpecific.refreshingReports);
  const refreshingInboxes: boolean = useStore((state) => state.clientSpecific.refreshingInboxes);

  const userConfigurationQuery = useUserConfiguration();
  const reportsQuery = useReports();
  const inboxesQuery = useInboxes();

  // Prepopulate these
  useOfficialRates();
  useCountries();
  useMajorCities();

  // Bail out here if we don't have a valid user configuration
  if (!userConfigurationQuery.data) return <Spinner size="75px" margin="1em" />;

  const userConfiguration = userConfigurationQuery.data.configuration;
  const hasReadEula = userConfiguration.person.licenceAgreed;

  if (!hasReadEula) history.push("/eula");

  const page = location.pathname;
  const reports = reportsQuery.data ? reportsQuery.data.reports.filter((o) => !o.deleted) : [];
  const inboxes = inboxesQuery.data ? inboxesQuery.data.inboxes.filter((o) => !o.deleted) : [];

  const updateReports = async () => {
    setRefreshingReports(true);
    await refreshReports();
    setRefreshingReports(false);
  };

  const updateInboxes = async () => {
    setRefreshingInboxes(true);
    await refreshInboxes();
    setRefreshingInboxes(false);
  };

  return (
    <Grid fluid>
      <AttachmentPreview />

      <PoseGroup>
        {page === "/" && (
          <HomePagePoseWrapper key="homepage-pose-wrapper-root">
            <PageHeader page="homepage" />
            <Row>
              <Col sm={12}>
                <CreateButtons showTravelReport showExpenseReport showMileageReport disabled={!reportsQuery.data} />
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <ReportList
                  title={t("listHeaders.reportsOpenTitle")}
                  description={t("listHeaders.reportsOpenInfo")}
                  reports={reports.filter((report) => report.status === 1)}
                  orderBy="changed"
                  orderDesc
                  showHeader
                  showRefreshButton={!reportsQuery.isLoading}
                  refreshReports={updateReports}
                  renderMode="cards"
                  isLoading={reportsQuery.isLoading}
                  isFetching={reportsQuery.isFetching || refreshingReports}
                  errorMessage={
                    reportsQuery.isError || reportsQuery.data?.incrementalRefreshStatus === "error" ? t("queryErrors.loadReportsError") : ""
                  }
                />
              </Col>

              <Col sm={6}>
                <InboxList
                  title={t("listHeaders.inboxUnconnectedTitle")}
                  description={t("listHeaders.inboxUnconnectedInfo")}
                  inboxes={inboxes}
                  orderBy="changed"
                  orderDesc
                  showHeader
                  showRefreshButton={!inboxesQuery.isLoading}
                  refreshInboxes={updateInboxes}
                  renderMode="cards"
                  isLoading={inboxesQuery.isLoading}
                  isFetching={inboxesQuery.isFetching || refreshingInboxes}
                  alwaysHideUsedInboxes={true}
                  errorMessage={
                    inboxesQuery.isError || inboxesQuery.data?.incrementalRefreshStatus === "error" ? t("queryErrors.loadInboxesError") : ""
                  }
                />
              </Col>
            </Row>
          </HomePagePoseWrapper>
        )}

        {page === "/reports" && (
          <HomePagePoseWrapper key="homepage-pose-wrapper-reports">
            <PageHeader page="reports" />
            <div>
              <CreateButtons showTravelReport showExpenseReport showMileageReport disabled={!reportsQuery.data} />
            </div>
            <div>
              <ReportList
                title={t("listHeaders.reportsAllTitle")}
                description={t("listHeaders.reportsAllInfo")}
                reports={reports}
                orderBy="changed"
                orderDesc
                renderMode="table"
                showHeader
                showRefreshButton={!reportsQuery.isLoading}
                refreshReports={updateReports}
                isLoading={reportsQuery.isLoading}
                isFetching={reportsQuery.isFetching || refreshingReports}
                errorMessage={
                  reportsQuery.isError || reportsQuery.data?.incrementalRefreshStatus === "error" ? t("queryErrors.loadReportsError") : ""
                }
              />
            </div>
          </HomePagePoseWrapper>
        )}

        {page.startsWith("/expenses") && (
          <HomePagePoseWrapper key="homepage-pose-wrapper-expenses">
            <ExpensesPage />
          </HomePagePoseWrapper>
        )}

        {page.startsWith("/expense/") && (
          <HomePagePoseWrapper key="homepage-pose-wrapper-expenses">
            <ExpensePage />
          </HomePagePoseWrapper>
        )}

        {page.startsWith("/mileages") && (
          <HomePagePoseWrapper key="homepage-pose-wrapper-mileage">
            <MileagesPage />
          </HomePagePoseWrapper>
        )}

        {page === "/inbox" && (
          <HomePagePoseWrapper key="homepage-pose-wrapper-inbox">
            <PageHeader page="inbox" />
            <div>
              <Uploader entityType="inbox" entityUuid="" disabled={!inboxesQuery.data} />
            </div>
            <div>
              <InboxList
                title={t("listHeaders.inboxAllTitle")}
                description={t("listHeaders.inboxAllInfo")}
                inboxes={inboxes}
                orderBy="changed"
                orderDesc
                renderMode="table"
                showHeader
                showRefreshButton={!inboxesQuery.isLoading}
                refreshInboxes={updateInboxes}
                isLoading={inboxesQuery.isLoading}
                isFetching={inboxesQuery.isFetching || refreshingInboxes}
                errorMessage={
                  inboxesQuery.isError || inboxesQuery.data?.incrementalRefreshStatus === "error" ? t("queryErrors.loadInboxesError") : ""
                }
              />
            </div>
          </HomePagePoseWrapper>
        )}
      </PoseGroup>
    </Grid>
  );
};
export default HomePage;
