import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import Icon from "../common/Icon";
import moment from "moment";
import "../../styles/reportcalculation.css";
import { formatMoney } from "../../shared/utils/helpers";
import { ReportCalculation } from "../../shared/types";

interface ReportCalculationProps {
  calculation: ReportCalculation;
  rateType: number;
  reportCreated?: string;
}
const ReportCalculationBox = ({ calculation, rateType, reportCreated }: ReportCalculationProps) => {
  const [t] = useTranslation();

  const [collapsed, setCollapsed] = useState(true);

  let sumDiet = 0;
  let taxableSumDiet = 0;
  let sumTotal = 0;
  let totalVAT = 0;

  const rows = [];

  if (calculation) {
    if (calculation.calculationItems) {
      // itemType 3: autoexpenses/diet
      calculation.calculationItems
        .filter((item) => item.itemType === 3)
        .forEach((item, index) => {
          const taxableSum = item.autoExpenses ? item.autoExpenses.reduce((sum, autoExpense) => sum + autoExpense.taxableSum, 0) : 0;
          sumDiet += item.sum;
          taxableSumDiet += taxableSum;
          sumTotal += item.sum;

          rows.push({
            text: `${item.description}:`,
            value: formatMoney(item.sum),
            className: "",
            key: `calculation_diet_${index}`
          });

          if (
            taxableSum &&
            (rateType === 2 || rateType === 3 || rateType === 1001) // Render taxable part, if applicable (values >0 only exists for reports created post 2016-09-29)
          )
            rows.push({
              text: `${t("calculation.whereOfTaxable")}:`,
              value: formatMoney(taxableSum),
              className: "gray",
              key: `calculation_diet_${index}_taxable`
            });
        });

      // Sum total for itemType 3: autoexpenses/diet
      if (sumDiet) {
        rows.push({
          text: `${t("calculation.sumDiet")}:`,
          value: formatMoney(sumDiet),
          className: "overlined",
          key: "calculation_diet_sum"
        });
        if (
          taxableSumDiet &&
          (rateType === 2 || rateType === 3 || rateType === 1001) // Render taxable part, if applicable (values >0 only exists for reports created post 2016-09-29)
        )
          rows.push({
            text: `${t("calculation.whereOfTaxable")}:`,
            value: formatMoney(taxableSumDiet),
            className: "gray",
            key: "calculation_diet_sum_taxable"
          });
      }

      // itemType 5 and 6: driving and expenses
      calculation.calculationItems
        .filter((item) => item.itemType === 5 || item.itemType === 6)
        .forEach((item, index) => {
          rows.push({
            text: `${item.description}:`,
            value: formatMoney(item.sum),
            className: "",
            key: `calculation_outlay_${index}`
          });
          sumTotal += item.sum;
        });
    }

    // Sum total before advance/override is applied
    rows.push({
      text: `${t("calculation.total")}:`,
      value: formatMoney(sumTotal),
      className: "heavy overlined",
      key: "calculation_total"
    });

    // itemType 1: advance
    if (calculation.calculationItems) {
      calculation.calculationItems
        .filter((item) => item.itemType === 1)
        .forEach((item, index) => {
          rows.push({
            text: `${item.description}:`,
            value: formatMoney(item.sum),
            className: "",
            key: `calculation_advance_${index}`
          });
          sumTotal += item.sum;
        });
    }

    // Payout
    rows.push({
      text: `${t("calculation.forRefund")}:`,
      value: formatMoney(calculation.sumTotal),
      className: "supermassive overlined",
      key: "calculation_payout"
    });

    // itemType 4: VAT and 9: biltema
    if (calculation.calculationItems) {
      calculation.calculationItems
        .filter((item) => item.itemType === 4 || item.itemType === 9)
        .forEach((item, index) => {
          rows.push({
            text: `${item.description}:`,
            value: formatMoney(item.sum),
            className: "gray no-bottom-border",
            key: `calculation_itemtype_${item.itemType}_${index}`
          });
          if (item.itemType === 4) totalVAT += item.sum;

          rows.push({
            text: `${t("calculation.grossBasisSum")}:`,
            value: formatMoney(item.basisSum),
            className: "gray",
            key: `calculation_itemtype_${item.itemType}_gross_${index}`
          });
        });
    }

    // Net reinvoicable sum
    // Net amount for reinvoicing calculation fixed for reports after 2017-01-25
    // New calculation does not exclude "do not refund" expenses and does not subtract advance
    const reportCreatedAfterBugfix = reportCreated ? moment(reportCreated) > moment("2017-01-26") : true;
    const reInvoicable = reportCreatedAfterBugfix ? calculation.sumTotalInclNonRefund - calculation.sumTotalInclNonRefundVat : sumTotal - totalVAT;
    rows.push({
      text: `${t("calculation.netSum")}:`,
      value: formatMoney(isNaN(reInvoicable) ? 0 : reInvoicable),
      className: "gray",
      key: "calculation_reinvoicable"
    });

    // itemType 7: taxfree part and 8: taxable part of driving items
    if (calculation.calculationItems) {
      calculation.calculationItems
        .filter((item) => item.itemType === 7 || item.itemType === 8)
        .forEach((item, index) => {
          rows.push({
            text: `${item.itemType === 7 ? t("calculation.taxfree") : t("calculation.taxable")} ${t("calculation.partOfMileage")}:`,
            value: formatMoney(item.sum),
            className: "gray",
            key: `calculation_itemtype_${item.itemType}_${index}`
          });
        });
    }
  }

  const toggleButton = (
    <Button bsStyle="link" onClick={() => setCollapsed(!collapsed)}>
      <Icon icon={collapsed ? "expandVertical" : "collapseVertical"} /> {collapsed ? t("showMore") : t("showLess")}
    </Button>
  );

  // Collapsed summary: refundable sum
  const collapsedSummary = (
    <div>
      <div className="calculation-summary">
        {t("calculation.forRefund")}: {formatMoney(calculation.sumTotal)} {toggleButton}
      </div>
    </div>
  );

  return (
    <div className="report-component" id="reportCalculation">
      <div className="report-calculation-wrapper">
        {collapsed && collapsedSummary}
        {!collapsed && (
          <table className="report-calculation">
            <tbody>
              {rows.map((row) => (
                <tr className={row.className} key={row.key}>
                  <td>{row.text}</td>
                  <td>{row.value}</td>
                </tr>
              ))}
              <tr>
                <td colSpan={2}>{toggleButton}</td>
              </tr>
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default ReportCalculationBox;
