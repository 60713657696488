import { useTranslation } from "react-i18next";
import moment from "moment";
import { Row, Col } from "react-bootstrap";
import CheckboxInput from "../common/CheckboxInput";
import Icon from "../common/Icon";
import { formatMoney } from "../../shared/utils/helpers";
import "../../styles/reportautoexpenseoverrides.css";
import { AutoExpenseOverride, ReportCalculationAutoExpense } from "../../shared/types";

interface ReportAutoExpenseOverrideListItemProps {
  autoExpense: ReportCalculationAutoExpense;
  onChangeOverride: (partialOverride: Partial<AutoExpenseOverride>) => void;
  override?: AutoExpenseOverride;
  readOnly?: boolean;
  showDebugValues?: boolean;
}
const ReportAutoExpenseOverrideListItem = ({
  autoExpense,
  onChangeOverride,
  override,
  readOnly,
  showDebugValues
}: ReportAutoExpenseOverrideListItemProps) => {
  const [t] = useTranslation();

  // Generate a partial override and pass it up the chain. We're uuid agnostic at this point, and only care about the date and exclusion toggle
  const onChangeOverrideField = (field: "excludeDiet" | "freeBreakfast" | "freeLunch" | "freeDinner", value: boolean) => {
    const override = {
      expenseDate: moment(autoExpense.fromDate).format("YYYY-MM-DD"),
      [field]: value
    };
    onChangeOverride(override);
  };

  // Description
  let desc = `${autoExpense.overnight ? t("dietary.24hourRate") : t("dietary.dayRate")} ${t("for")} ${autoExpense.location}`; // state_diet
  if (autoExpense.autoExpenseType === "state_lodging") desc = `${t("dietary.rates")} ${t("for")} ${autoExpense.location}`;

  if (autoExpense.autoExpenseType === "custom_diet") desc = `${t("dietary.customRates")} ${t("for")} ${autoExpense.location}`;

  if (autoExpense.autoExpenseType === "custom_lodging") desc = `${t("dietary.customLodging")} ${t("for")} ${autoExpense.location}`;

  if (autoExpense.autoExpenseType === "state_overnight_extra") desc = `${t("dietary.nightExtra")} ${t("for")} ${autoExpense.location}`;

  if (autoExpense.autoExpenseType === "state_nondomestic_extra") desc = `${t("dietary.nonDomesticExtra")} ${t("for")} ${autoExpense.location}`;

  const sum = formatMoney(autoExpense.sum);
  const taxFree = autoExpense.taxfreeSum ? formatMoney(autoExpense.taxfreeSum) : 0;
  const taxable = autoExpense.taxableSum ? formatMoney(autoExpense.taxableSum) : 0;
  const from = moment(autoExpense.fromDate);
  const to = moment(autoExpense.toDate);

  const sameMonth = from.isSame(to, "month");
  const same24 = from.minute() === to.minute() && from.hour() === to.hour();

  return (
    <Row>
      <Col xs={4} xsHidden={false} smHidden mdHidden lgHidden>
        {from.format(sameMonth ? "D" : "D.MMM")} - {to.format("D.MMM YYYY")}
      </Col>
      <Col sm={4} xsHidden smHidden={false} mdHidden lgHidden>
        {from.format(same24 ? "D.MMM" : "D.MMM (HH:mm)")} - {to.format(same24 ? "D.MMM YYYY" : "D.MMM YYYY (HH:mm)")}
      </Col>
      <Col md={4} lg={4} xsHidden smHidden mdHidden={false} lgHidden={false}>
        {from.format("D.MMM (HH:mm)")} - {to.format("D.MMM YYYY (HH:mm)")}
      </Col>
      <Col sm={4} xsHidden>
        {desc}
      </Col>
      <Col sm={3} xs={5}>
        <CheckboxInput
          field={"excludeDiet"}
          label={<Icon icon="remove" />}
          onChange={(o: boolean) => onChangeOverrideField("excludeDiet", o)}
          title={t("dietary.excludeDay")}
          readOnly={readOnly}
          value={override ? override.excludeDiet : false}
        />

        <CheckboxInput
          field={"freeBreakfast"}
          label={t("dietary.breakfastSingleLetter")}
          onChange={(o: boolean) => onChangeOverrideField("freeBreakfast", o)}
          title={t("dietary.excludeBreakfast")}
          readOnly={readOnly || (override ? override.excludeDiet : false)}
          value={override ? override.freeBreakfast : false}
        />

        <CheckboxInput
          field={"freeLunch"}
          label={t("dietary.lunchSingleLetter")}
          onChange={(o: boolean) => onChangeOverrideField("freeLunch", o)}
          title={t("dietary.excludeLunch")}
          readOnly={readOnly || (override ? override.excludeDiet : false)}
          value={override ? override.freeLunch : false}
        />

        <CheckboxInput
          field={"freeDinner"}
          label={t("dietary.dinnerSingleLetter")}
          onChange={(o: boolean) => onChangeOverrideField("freeDinner", o)}
          title={t("dietary.excludeDinner")}
          readOnly={readOnly || (override ? override.excludeDiet : false)}
          value={override ? override.freeDinner : false}
        />
      </Col>
      <Col sm={1} xs={3}>
        <strong>
          {sum}
          {showDebugValues && ` (${taxFree} / ${taxable})`}
        </strong>
      </Col>
    </Row>
  );
};

export default ReportAutoExpenseOverrideListItem;
