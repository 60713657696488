import moment from "moment";
import i18n from "../../i18n/i18nConfig";
import { getAutoExpenses } from "./autoExpenses";
import { calculationConstructor, calculationItemConstructor } from "../../constructors/reportCalculationConstructors";
import { ExchangeRatesByDate, GeoCountry, GeoLocation, OfficialRateSet, Report, UserConfiguration } from "../../types";
import { useStore } from "../../store/store";

// This file is typescripted and serves as a guard for the autoExpenses*.js, which are not
// Do not use the others ones directly, for type safety.

// This class describes the costs/sums associated with different rate based items.
// Source of truth for questions like "what was the rate per kilometer for driving an electric car on 2015-01-01?"
export const rateBasedExpenseValues = (when: string) => {
  const timestamp = moment(when);

  let driving_Km_Hundredths = 405;
  let driving_Km_TaxFree_Hundredths = 405;

  let driving_ElectricCar_Km_Hundredths = 420;

  let driving_Commuter_Km_Hundredths = 150;
  let driving_Commuter_Over_LimitA_Km_Hundredths = 70;
  let driving_Commuter_Over_LimitB_Km_Hundredths = 0; // In case we want to do tax calculations later, do not use this for now
  let driving_Commuter_Deductible_Hundredths = 1500000; // THe first 15k kroners is deductible (bunnfradrag/egenandel), do not use this either for now
  let driving_Commuter_LimitA_Km = 50000;
  let driving_Commuter_LimitB_Km = 75000;

  let driving_BoatOver50hk_Km_Hundredths = 750;
  const driving_OtherTransports_Km_Hundredths = 200;
  const driving_Motorcycle_Km_Hundredths = 295;

  const driving_Passengers_Km_Hundredths = 100;
  const driving_Hanger_Km_Hundredths = 100;
  const driving_ForestRoad_Km_Hundredths = 100;
  const driving_TromsoExtra_Km_Hundredths = 10;
  const driving_OtherExpenses_Km_Hundredths = 0;

  if (timestamp.year() >= 2015) {
    driving_Km_Hundredths = 410;
    driving_Km_TaxFree_Hundredths = 410;
    driving_Commuter_Deductible_Hundredths = 1600000;
  }
  if (timestamp.year() >= 2016) {
    driving_Km_TaxFree_Hundredths = 380;
    driving_Commuter_Deductible_Hundredths = 2200000;
  }
  if (timestamp.year() >= 2017) {
    driving_Km_TaxFree_Hundredths = 350;
    driving_Commuter_Km_Hundredths = 156;
    driving_Commuter_Over_LimitA_Km_Hundredths = 76;
  }
  if (timestamp.year() >= 2018) {
    driving_Commuter_Deductible_Hundredths = 2235000;
  }
  if (timestamp.isAfter("2018-06-25")) {
    driving_Km_Hundredths = 390;
    driving_ElectricCar_Km_Hundredths = 390;
  }
  if (timestamp.year() >= 2019) {
    driving_Km_Hundredths = 403;
    driving_ElectricCar_Km_Hundredths = 403;
    driving_Commuter_Deductible_Hundredths = 2270000;
  }
  if (timestamp.year() >= 2020) {
    driving_Commuter_Deductible_Hundredths = 2310000;
  }
  if (timestamp.year() >= 2021) {
    driving_Commuter_Deductible_Hundredths = 2390000;
  }
  if (timestamp.year() >= 2022) {
    driving_BoatOver50hk_Km_Hundredths = 1000;
    driving_Commuter_Deductible_Hundredths = 1400000;
    driving_Commuter_Km_Hundredths = 165;
    driving_Commuter_Over_LimitA_Km_Hundredths = 165;
    driving_Commuter_LimitA_Km = 0; // This limit was removed in 2022
    driving_Commuter_LimitB_Km = 0;
  }
  if (timestamp.year() >= 2023) {
    driving_Km_Hundredths = 448;
    driving_ElectricCar_Km_Hundredths = 448;
    driving_Commuter_Km_Hundredths = 170;
    driving_Commuter_Over_LimitA_Km_Hundredths = 170;
  }
  if (timestamp.year() >= 2024) {
    driving_Km_Hundredths = 490;
    driving_ElectricCar_Km_Hundredths = 490;
  }

  return {
    driving_Km_Hundredths,
    driving_Km_TaxFree_Hundredths,
    driving_ElectricCar_Km_Hundredths,
    driving_Commuter_Km_Hundredths,
    driving_Commuter_Over_LimitA_Km_Hundredths,
    driving_Commuter_Over_LimitB_Km_Hundredths,
    driving_Commuter_Deductible_Hundredths,
    driving_Commuter_LimitA_Km,
    driving_Commuter_LimitB_Km,
    driving_BoatOver50hk_Km_Hundredths,
    driving_OtherTransports_Km_Hundredths,
    driving_Motorcycle_Km_Hundredths,
    driving_Passengers_Km_Hundredths,
    driving_Hanger_Km_Hundredths,
    driving_ForestRoad_Km_Hundredths,
    driving_TromsoExtra_Km_Hundredths,
    driving_OtherExpenses_Km_Hundredths
  };
};

// Calculates a report and generates autoexpenses. Takes a report, a userconfiguration, and an object with other required static data
export const calculateReport = (
  report: Report,
  configuration: UserConfiguration,
  officialRates: OfficialRateSet[],
  countries: GeoCountry[],
  majorCities: GeoLocation[],
  exchangeRatesNBSeed: ExchangeRatesByDate[]
) => {
  const ret = calculationConstructor();

  // Check if we can access a valid store, and try to extract client and user information from it to stamp on the calculation
  // This allows us to troubleshoot any calculation issues later
  ret.generatedBy = "default";
  if (useStore && useStore.getState && useStore.getState().ready) {
    const clientType = useStore.getState().clientType;
    const clientPlatform = useStore.getState().clientPlatform;
    const clientVersion = useStore.getState().clientVersion;
    const currentUserId = useStore.getState().currentUserId;
    ret.generatedBy = `${clientType}.${clientPlatform}.${clientVersion}.uid-${currentUserId}`;
  }

  // Grab the ID's of some needed customfields and expensetypes
  const customFieldIdDrivingTaxfreePart = configuration.product.expenseCustomFields.find((field) => field.systemName === "driving_taxfree_part")?.id;
  const customFieldIdDrivingTaxablePart = configuration.product.expenseCustomFields.find((field) => field.systemName === "driving_taxable_part")?.id;
  const drivingExpenseTypeIds = configuration.product.expenseTypes.filter((expensetype) => expensetype.is_Driving).map((et) => et.id);

  // If override is enabled, this is the final payout sum regardless of actual report contents
  ret.overrideSum = report.overrideSum;
  ret.overrideSumEnabled = report.overrideSumEnabled ? 1 : 0;

  // Expense values
  let expensesum = 0;
  let expensesumDriving = 0;
  let expensesumNonDriving = 0;
  let expensesumDrivingTaxfreePart = 0;
  let expensesumDrivingTaxablePart = 0;
  let sumKilometers = 0;

  report.expenses
    .filter((r) => !r.deleted)
    .map((exp) => ({
      uuid: exp.uuid,
      totalValueNok: exp.totalValueNok,
      units: exp.units,
      refund: exp.refund,
      is_Driving: drivingExpenseTypeIds.includes(exp.expenseTypeId) ? 1 : 0,
      expenseCustomValues: exp.expenseCustomValues.filter((ecv) => !ecv.deleted)
    }))
    .forEach((exp) => {
      ret.sumTotalInclNonRefund += exp.totalValueNok;
      if (exp.refund) {
        expensesum += exp.totalValueNok;
        ret.sumTotal += exp.totalValueNok;
        if (exp.is_Driving) {
          expensesumDriving += exp.totalValueNok;
          if (exp.units && !isNaN(exp.units)) sumKilometers += exp.units;
          // Check for customvalues representing the taxfree / taxable part and add to itemtypes 7 and 8
          expensesumDrivingTaxfreePart += exp.expenseCustomValues
            .filter((o) => o.expenseCustomFieldId === customFieldIdDrivingTaxfreePart)
            .reduce((total, o) => (total += Number(o.value.replace(".", "").replace(",", ""))), 0);
          expensesumDrivingTaxablePart += exp.expenseCustomValues
            .filter((o) => o.expenseCustomFieldId === customFieldIdDrivingTaxablePart)
            .reduce((total, o) => (total += Number(o.value.replace(".", "").replace(",", ""))), 0);
        } else {
          expensesumNonDriving += exp.totalValueNok;
        }
      }
    });

  if (expensesum > 0) {
    ret.calculationItems.push(
      calculationItemConstructor({
        description: i18n.t("calculation.expenseLines"),
        itemType: 2,
        sum: expensesum
      })
    );
    ret.calculationItems.push(
      calculationItemConstructor({
        description: `${i18n.t("calculation.mileage")} (${sumKilometers.toString()} km)`,
        itemType: 5,
        sum: expensesumDriving
      })
    );
    ret.calculationItems.push(
      calculationItemConstructor({
        description: i18n.t("calculation.outlay"),
        itemType: 6,
        sum: expensesumNonDriving
      })
    );
  }
  if (expensesumDrivingTaxfreePart > 0)
    ret.calculationItems.push(
      calculationItemConstructor({
        description: i18n.t("calculation.whereOfTaxfree"),
        itemType: 7,
        sum: expensesumDrivingTaxfreePart
      })
    );
  if (expensesumDrivingTaxablePart > 0)
    ret.calculationItems.push(
      calculationItemConstructor({
        description: i18n.t("calculation.whereOfTaxable"),
        itemType: 8,
        sum: expensesumDrivingTaxablePart
      })
    );

  // Grouped VAT sums
  report.expenses
    .filter((r) => !r.deleted)
    .filter((expense) => expense.refund)
    .map((expense) => expense.vatPercent)
    .filter((v, i, a) => a.indexOf(v) === i) // distinct
    .sort()
    .forEach((vatPercent) => {
      // each unique vat rate
      const vatsum = report.expenses
        .filter((r) => !r.deleted)
        .filter((expense) => expense.refund && expense.vatPercent === vatPercent)
        .reduce((total, expense) => (total += expense.totalVatNok), 0);

      const basissum = report.expenses
        .filter((r) => !r.deleted)
        .filter((expense) => expense.refund && expense.vatPercent === vatPercent)
        .reduce((total, expense) => (total += expense.totalValueNok), 0);

      if (vatsum > 0)
        ret.calculationItems.push(
          calculationItemConstructor({
            description: `${i18n.t("calculation.whereOfVat")} ${vatPercent.toString()}%`,
            itemType: 4,
            sum: vatsum,
            basisSum: basissum
          })
        );
    });

  // Separate VAT calculation for SumTotalInclNonRefundVat
  report.expenses
    .filter((r) => !r.deleted)
    .map((expense) => expense.vatPercent)
    .filter((v, i, a) => a.indexOf(v) === i) // distinct
    .sort()
    .forEach((vatPercent) => {
      // each unique vat rate
      const vatsum = report.expenses
        .filter((r) => !r.deleted)
        .filter((expense) => expense.vatPercent === vatPercent)
        .reduce((total, expense) => (total += expense.totalVatNok), 0);

      if (vatsum > 0) ret.sumTotalInclNonRefundVat += vatsum;
    });

  // Are we using custom rates (2), official rates (3) or taxfree rates (4) ? Pass each segment into the autoexpense calculator to get the automatic daily expenses
  if (
    report.rateType === 2 ||
    report.rateType === 3 ||
    report.rateType === 4 ||
    report.rateType === 5 ||
    report.rateType === 6 ||
    report.rateType === 1001
  ) {
    const autoExpenseGroups = getAutoExpenses(report, configuration, officialRates, countries, majorCities, exchangeRatesNBSeed);
    autoExpenseGroups.forEach((group: any) => {
      if (group.autoExpenses.length > 0) {
        const sum = group.autoExpenses.reduce((total: number, autoExpense: any) => (total += autoExpense.sum), 0);

        ret.calculationItems.push(
          calculationItemConstructor({
            description: group.description,
            itemType: 3,
            autoExpenses: group.autoExpenses,
            sum
          })
        );
        ret.sumTotal += sum;
        ret.sumTotalInclNonRefund += sum;
      }
    });
  }

  // Advance
  if (report.advance > 0) {
    ret.calculationItems.push(
      calculationItemConstructor({
        description: i18n.t("calculation.advance"),
        itemType: 1,
        sum: -report.advance
      })
    );
    ret.sumTotal -= report.advance;
  }
  return ret;
};
