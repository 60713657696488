import React, { useState } from "react";
import { I18nextProvider } from "react-i18next";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../styles/toastify.css";
import { initStore, StoreConfig, useStore } from "../shared/store/store";
import i18n from "../shared/i18n/i18nConfig";
import { changeLanguage } from "../shared/actions/languageActions";
import config from "../shared/config";
import RootRouter from "./RootRouter";
import ExternalCssInjector from "./ExternalCssInjector";
//import ReactGA from "react-ga";
import { trackEvent } from "../utils/eventTracker";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { queryClient } from "../shared/queries/queries";
import { switchUser } from "../shared/actions/userActions";
import { initialClientSpecificStore } from "../utils/webclientStore";
import { SupportedEnvironments } from "../shared/types";
import { getBackendUrlFromEnvironment } from "../shared/api/api";
import { ApiResponseDetails } from "../shared/api/api";
import ReauthenticateModal from "./common/ReauthenticateModal";
import { setAuthenticationExpired } from "../utils/webclientStore";

// Increment this upon releases. The backend will provide a minimum required version, which this will be compared to.
// If the API wrapper detects that this version is lower than the required version, updateCallback will be executed.
// The client should abandon all activity and immediately update itself.
const clientVersion: number = 6;

// The environment we're currently running in
// We can't typecheck this at compile time since it could be pulled from external config, but we gatekeep from here
/* @ts-expect-error */
const env: SupportedEnvironments = config.REACT_APP_ENVIRONMENT;

// This is hit when any attempt at an API call returns. Use to intercept any result and deal with errors that aren't handled by the caller directly.
const apiCallCompletedHandler = (responseDetails: ApiResponseDetails) => {
  if (!responseDetails.success && (responseDetails.httpStatusCode === 401 || responseDetails.httpStatusCode === 403)) {
    // A call for userconfiguration was rejected
    // If the call was NOT for the currently active user, we can assume it was to pull details about another user that is logged in to the client
    // This typically when someone stays authenticated as a user that is later deleted or has their access revoked
    // We should quietly discard that user from store.users, and return to prevent triggering a reauthentication modal
    if (responseDetails.rawRequestUrl === "e/user/configuration") {
      const headers = responseDetails.rawFetchOptions.headers;
      if (headers && "Authorization" in headers) {
        // This was a configuration API call, and there was an Authorization header in it
        const jwtWithBearer = headers.Authorization;
        const state = useStore.getState();
        if (jwtWithBearer && state.ready) {
          // The call had a JWT token and state is ready
          let removedUser = false;
          for (const user of state.users) {
            // Check each user in state
            if (user.id && user.jwtToken && user.id !== state.currentUserId && jwtWithBearer.includes(user.jwtToken)) {
              // The user has a token matching the one in the rejected call, and it is not the current user, we should remove it
              console.log("Removing unauthorized secondary user from state", user.id);
              state.removeUser(user.id);
              removedUser = true;
            }
          }
          // If we removed at least one user, consider this error handled and return early to prevent the "authentication expired" notification
          if (removedUser) return;
        }
      }
    }

    // User credentials was rejected for a backend call, flag it to trigger the reauthentication modal
    // This is intended to catch failed calls for userdata when the user is already logged in, typically due to an expired token
    if (responseDetails.rawRequestUrl !== "authentication/authenticate") {
      // Make sure to skip this for authentication calls, or we will be triggering the modal every time someone tries to login
      setAuthenticationExpired(true);
    }
  }
};

// If the API wrapper detects that a client update is available compared with the local clientType and clientVersion, this callback will be executed.
// The client should abandon all activity and immediately update itself.
const updateCallback = () => {
  console.log("updateCallback hit");
  // TODO-V2-OPTIONAL: was previously implemented in clientUpdates (serviceworker logic) implement as a simple refresh?
};

// Root component
const App = () => {
  const [storeReady, setStoreReady] = useState(false);

  // Initialize google analytics
  // ReactGA.initialize("UA-54058719-4", {
  //   debug: false,
  //   titleCase: false
  // });
  // ReactGA.set({ anonymizeIp: true });

  // If the store hasn't been initialized/rehydrated yet, do this first
  if (!useStore.getState().ready) {
    const apiBaseUrl = getBackendUrlFromEnvironment(env);
    const storeConfig: StoreConfig = {
      enableDevtools: true,
      persistOptions: { name: "tt-zustand-storage", getStorage: () => localStorage },
      persistIsAsync: false,
      defaultValues: {
        env,
        apiBaseUrl,
        clientType: "web",
        clientPlatform: "edith",
        clientVersion,
        apiCallCompletedHandler,
        updateCallback,
        clientSpecific: initialClientSpecificStore // Reset this as long as it only contains attachment preview stuff
      }
    };

    initStore(storeConfig).then(() => {
      const cid = useStore.getState().currentUserId;
      trackEvent("General", "App mounted", cid);

      // Check for a cookie with user migration data from the old webclient at client.traveltext.no
      const migrationCookie = document.cookie.split("; ").find((row) => row.startsWith("traveltext-edith-migration="));
      if (migrationCookie) {
        // Parse and load the cookie contents as user credentials
        const migrationData = JSON.parse(migrationCookie.split("=")[1]);
        console.log("Found migration data: ", migrationData);
        useStore.setState({ users: migrationData.users, currentUserId: migrationData.currentUserId });

        // Delete the migration cookie
        const migrationCookieDelete = "traveltext-edith-migration=; domain=.traveltext.no; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT";
        console.log("Deleting migration cookie");
        document.cookie = migrationCookieDelete;
        trackEvent("General", "User migrated", migrationData.currentUserId);
      }

      setStoreReady(true);
    });
  }

  // TODO-V2-LAST Prettify loading message
  // Store is not ready yet, bail out
  if (!storeReady) return <div>Loading...</div>;

  // Tell moment and the API about our current locale
  const language = useStore.getState().language;
  changeLanguage(language);

  // If we rehydrated with an active user, switch to them
  const currentUser = useStore.getState().getCurrentUser();
  if (currentUser) switchUser(currentUser.id);

  // TODO-V2-LAST: update and uncomment as needed
  // ComponentDidMount
  // useEffect(() => {
  //   const state = this.store.getState();
  //   const { currentUser } = state.userData.byId;
  //   const userId = isEmpty(currentUser) ? 0 : currentUser;
  //   // trackEvent("General", "App mounted", userId);
  // }, []);

  // Used by react-router
  const baseUrl: string = process.env.PUBLIC_URL;

  const notProdWarningTriangle =
    env !== "prod" ? (
      <div id="not-prod-warning-triangle" title={`environment: ${env}`}>
        {env[0]}
      </div>
    ) : null;

  return (
    <I18nextProvider i18n={i18n}>
      <QueryClientProvider client={queryClient}>
        <React.Fragment>
          <ReactQueryDevtools initialIsOpen={false} />
          {notProdWarningTriangle}
          <ToastContainer />
          <ReauthenticateModal />
          <React.Fragment>
            <RootRouter basename={baseUrl} />
            <ExternalCssInjector />
          </React.Fragment>
        </React.Fragment>
      </QueryClientProvider>
    </I18nextProvider>
  );
};

export default App;
