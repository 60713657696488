import { ApiMileageListResponse, Mileage, MileageTrackSegmentCoord, Tollstation } from "../types";
import { jsonFetch, jsonFetchWithServerTime } from "./api";

/**
 * @typedef {object} mileage
 * @property {string} uuid - Uuid of mileage track
 * @property {string} description - Description of mileage track
 * @property {string} addressFrom - Start address of the whole trip
 * @property {string} addressTo - End address of the whole trip
 * @property {string} starts - Start timestamp for the whole trip
 * @property {string} stops - End timestamp for the whole trip
 * @property {number} totalMeters - Total distance of all segments
 * @property {track} track - Object containing the track coords and details
 */

/**
 * @typedef {object} track
 * @property {trackSegment[]} trackSegments - Array of track segments
 */

/**
 * @typedef {object} trackSegment
 * @property {string} addressFrom - Start address for this segment
 * @property {string} addressTo - End address for this segment
 * @property {string} starts - Start timestamp for this segment
 * @property {string} stops - End timestamp for this segment
 * @property {number} totalMeters - Total distance of all segments
 * @property {coord[]} coords - Array of the coordinates this segment is made up of
 */

/**
 * @typedef {object} coord
 * @property {string} lat - Latitude as stringified decimal (12.34)
 * @property {string} lon - Longitude as stringified decimal (12.34)
 */

/**
 * @typedef {object} response
 * @property {mileage[]} mileages - an array of mileages
 * @property {string} serverTime - UTC servertime
 */

/**
 * @typedef {object} tollstationpassing
 * @property {number} id - Backend reference ID of the tollstation
 * @property {number} latitude
 * @property {number} longitude
 * @property {string} name - Name of the station
 * @property {string} type - Category of station, can usually be rendered in parantheses after the name for descriptive purposes
 * @property {string} lastModified" - ISO8601 modified date of the station details
 * @property {number} meteringDirection - Angle degrees of the "metering" direction of the road segment the station is placed on (this is a NVDB technical term)
 * @property {bool} chargeWithMetering - Whether the station charges for passing the same direction as the metering direction
 * @property {bool} chargeAgainstMetering - Whether the station charges for passing the opposite direction of the metering direction
 * @property {number} chargeSmallCar - Amount of norwegian kroners (NOK) charged for passing the station with a small (regular) car
 * @property {number} chargeBigCar - Amount of norwegian kroners (NOK) charged for passing the station with a big (bus, trailer) car
 * @property {number} rushChargeSmallCar - Amount of norwegian kroners (NOK) charged for passing the station with a small (regular) car during rush hour, as defined in the rush-properties
 * @property {number} rushChargeBigCar - Amount of norwegian kroners (NOK) charged for passing the station with a big (bus, trailer) car during rush hour, as defined in the rush-properties
 * @property {bool} timeDifferentiated - Whether the station differentiates the charge based on time of day, or not
 * @property {string} rushMorningStart - HH:MM timestamp that defines start of morning rush hour for charge purposes
 * @property {string} rushMorningEnd - HH:MM timestamp that defines end of morning rush hour for charge purposes
 * @property {string} rushEveningStart - HH:MM timestamp that defines start of evening rush hour for charge purposes
 * @property {string} rushEveningEnd - HH:MM timestamp that defines end of evening rush hour for charge purposes
 */

/**
 * Gets all mileages for the currently logged in user.
 * @param {string} changedSince - If this is included only mileages changed after this date will be returned
 * @param {bool} includeCoords - If true, returned mileage tracks will contain detailed segments with GPS coordinates. Do not use this for local state, only for online inspections! It can get seriously heavy!
 * @returns {response} - An object containing an array of mileages and serverTime (string)
 */
export const list = async (changedSince?: string, includeCoords?: boolean): Promise<ApiMileageListResponse> => {
  const fetchOptions = {
    method: "GET"
  };

  const includeCoordsString = includeCoords ? "?withTracks=true" : "?withTracks=false";
  const qs = changedSince ? `&changedSince=${changedSince}` : "&skipDeleted=true";

  const { body: mileages, serverTime } = await jsonFetchWithServerTime(`e/mileage/list${includeCoordsString}${qs}`, fetchOptions);
  return {
    mileages,
    serverTime
  };
};

/**
 * Gets a single mileage by uuid
 * @param {number} uuid The uuid of the mileage
 * @param {bool} includeCoords - If true, returned mileage tracks will contain detailed segments with GPS coordinates. Do not use this for local state, only for online inspections! It can get seriously heavy!
 * @returns {mileage} - A single mileage object
 */
export const details = async (uuid: string, includeCoords?: boolean): Promise<Mileage> => {
  const fetchOptions = {
    method: "GET"
  };
  const includeCoordsString = includeCoords ? "?withTracks=true" : "?withTracks=false";
  return jsonFetch(`e/mileage/${uuid}${includeCoordsString}`, fetchOptions);
};

/**
 * Saves a single mileage
 * @param {mileage} mileage - A single mileage object
 * @param {keepExistingCoordinates} boolean - If true, the backend will ignore the "track" property. Useful if we're only deleting a track or updating a description, but we haven't loaded the actual track yet
 * @returns {mileage} - A single mileage object
 */
export const save = async (mileage: Mileage, keepExistingCoordinates: boolean = false): Promise<Mileage> => {
  const fetchOptions = {
    method: "POST",
    body: JSON.stringify(mileage)
  };
  return jsonFetch(`e/mileage/save?keepExistingCoordinates=${keepExistingCoordinates.toString()}`, fetchOptions);
};

/**
 * Deletes a single mileage by uuid
 * @param {number} uuid The uuid of the mileage track
 */
export const del = (uuid: string): Promise<void> => {
  const fetchOptions = {
    method: "DELETE"
  };
  return jsonFetch(`e/mileage/${uuid}`, fetchOptions);
};

/**
 * Takes a single mileage, looks up any toll stations passings and returns an array of them
 * @param {mileage} mileage - A single mileage object
 * @returns {tollstationpassing[]} - An array of toll stations that was passed in the mileage object
 */
export const tollstations = async (mileage: Mileage): Promise<Tollstation[]> => {
  const fetchOptions = {
    method: "POST",
    body: JSON.stringify(mileage)
  };
  return jsonFetch(`e/mileage/tollstations`, fetchOptions);
};

/**
 * Takes a single route from a google maps directions response, looks up any toll stations passings and returns an array of them
 * The directions response is untyped and completely pass-through. The backend is responsible for dealing with whatever google returns.
 * @param {directionsRoute} directionsRoute - A single route object from a directions response (untyped, subject to change)
 * @returns {tollstationpassing[]} - An array of toll stations that was passed in the mileage object
 */
export const tollstationsByDirectionsRoute = async (directionsRoute: any): Promise<Tollstation[]> => {
  const fetchOptions = {
    method: "POST",
    body: JSON.stringify(directionsRoute)
  };
  return jsonFetch(`e/mileage/tollstationsbydirectionsroute`, fetchOptions);
};

/**
 * Takes an array of coordinates, attempts to snap them to nearby roads and smooth the path
 * Can only handle 100 coordinate pairs for each request, so longer paths must be split into multiple requests
 * @param {coord[]} coords - Array of coord pairs
 * @returns {coord[]} - Snapped/smoothed array of coord pairs
 */
export const snap = (coords: MileageTrackSegmentCoord[]): Promise<MileageTrackSegmentCoord[]> => {
  const fetchOptions = {
    method: "POST",
    body: JSON.stringify(coords)
  };
  return jsonFetch(`e/mileage/snap`, fetchOptions);
};

/**
 * Takes a coordinate pair, attempts to generate a street address by reverse geocoding it
 * @param {coord} coord - Single coord pair
 * @returns {string} - Nearest address (if anything is found nearby)
 */
export const reverseGeocode = async (coord: MileageTrackSegmentCoord): Promise<string> => {
  const fetchOptions = {
    method: "POST",
    body: JSON.stringify(coord)
  };
  return jsonFetch(`e/mileage/reversegeocode`, fetchOptions);
};
