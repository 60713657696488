import { FunctionComponent } from "react";
import TopBar from "./TopBar";
import SideBar from "./SideBar";

const AppRoot: FunctionComponent = (props) => {
  return (
    <div id="root-wrapper-2">
      <TopBar />
      <SideBar />
      <div id="body-wrapper">{props.children}</div>
    </div>
  );
};

export default AppRoot;
