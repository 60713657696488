import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { showPreview } from "../../utils/webclientStore";
import Spinner from "../common/Spinner";

// Takes a blob, renders a thumbnail with delete/preview capability
interface ImageThumbnailProps {
  onDelete?: () => void;
  allowPreview?: boolean;
  title: string;
  blob: Blob;
  mimeType: string;
}
const ImageThumbnail = ({ onDelete, allowPreview, title, blob, mimeType }: ImageThumbnailProps) => {
  const [t] = useTranslation();

  const [imageUrl, setImageUrl] = useState<string | null>(null);

  // When we receive a new blob, generate a local URL and show the image
  useEffect(() => {
    if (blob) {
      const url = window.URL || window.webkitURL;
      const imageUrlNew = url.createObjectURL(blob);
      setImageUrl(imageUrlNew);
      return () => {
        if (imageUrl) {
          url.revokeObjectURL(imageUrl);
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blob]);

  const contentClass = "thumbnail-content";

  return (
    <div className="thumbnail-wrapper">
      {onDelete && (
        <button className="inbox-attach-button thumbnail-delete" title={t("remove")} onClick={() => onDelete()}>
          &times;
        </button>
      )}

      <div
        role="button"
        tabIndex={0}
        className="thumbnail-content-wrapper"
        onClick={() =>
          allowPreview &&
          showPreview({
            mimeType: mimeType,
            objectUrl: imageUrl || undefined
          })
        }
      >
        {imageUrl && <img className={contentClass} src={imageUrl} alt={title} />}

        {!imageUrl && <Spinner size="43px" />}
      </div>
    </div>
  );
};

export default ImageThumbnail;
