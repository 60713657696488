/*eslint camelcase: 0*/
import moment from "moment";
import i18n from "../../../i18n/i18nConfig";
import {
  autoExpenseGroupConstructor,
  autoExpenseConstructor,
  segmentDayConstructor
} from "../../../constructors/reportCalculationConstructors";
import { getRateBySegment, getRateBySegmentDay } from "../autoExpenses";

// Custom ruleset for OneCo, originally based on V31.

// Valid until 2021-12-31

// This ruleset ONLY supports ratetype 1001, and should NOT be called for other ratetypes!

// From V31:
// Differences are as follows:
// This ruleset generally follows ratetype 3 rules (official rates), but instead of a standard overnight rate of 780, it differentiates between lodging types
// Meal reductions should be based on these custom rates
// Tax (skatteetaten) rules apply to counting nights and "påbegynt døgn"
// Nondomestic seems to not be applicable, use same rates for everything

// From V32:
// oneco_diet_hotel: 591 -> 607
// oneco_diet_nocooking: 591 -> 607
// oneco_diet_private_or_withcooking: 535 -> 549

// From V33:
// By customer request, made it so that the difference between oneco rate and taxfree rate is show as the taxable part of each day expense (and transfered away from the taxable part)
// This diff can also be reduced by meal reductions

// From V34:
// Removed merging/grouping of nightextra and nondomestic autoexpenses, ref TTN-174

// By request while testing V34:
// official_diet_daytrip_6_12: 307 -> 315
// official_diet_daytrip_12_plus: 570 -> 585

// Static values for this version

// Oneco specific overnight rates based on lodging type
const oneco_diet_hotel = 607;
const oneco_diet_nocooking = 607;
const oneco_diet_private_or_withcooking = 549;

// The rest are standard rates
const official_diet_taxfree_part_private_or_withcooking = 94;
const official_diet_taxfree_part_nocooking = 170;
const official_diet_taxfree_part_hotel = 609;

const official_diet_daytrip_6_12 = 315;
const official_diet_daytrip_12_plus = 585;

const official_diet_taxfree_part_daytrip_6_12 = 200;
const official_diet_taxfree_part_daytrip_12_plus = 400;

// Oneco specific night extra, seems to apply regardless of domestic/nondomestic as long as the box is checked
const oneco_overnight_extra = 121;

// N/A for oneco
//const int official_nondomestic_extra = 531;

// Collapsed these into a single set instead of differentiating between domestic and nondomestic
const meal_reduction_breakfast = 0.2;
const meal_reduction_lunch = 0.3;
const meal_reduction_dinner = 0.5;

// Take a report, return the ordered segments prepopulated with autoexpensegroups
const getSegmentsWithGroups = (report) => {
  // Clone segments to preserve original, and order by date
  let orderedSegments = report.reportSegments.filter((o) => !o.deleted).map((o) => Object.assign({}, o));
  orderedSegments.sort((a, b) => new Date(a.starts) - new Date(b.starts));

  // Create autoexpense groups for segments
  orderedSegments.forEach((orderedSegment) => {
    const diet_expensetype_label = "custom_diet";
    // Create an autoexpense group for diet
    const agDiet = autoExpenseGroupConstructor();
    agDiet.autoExpenseType = diet_expensetype_label;
    agDiet.fromDate = orderedSegment.starts;
    agDiet.toDate = orderedSegment.stops;

    orderedSegment.diet = agDiet;
    if (orderedSegment.countryId === 164) {
      // Domestic (Norway)
      orderedSegment.diet.location = i18n.t("calculation.norway");
      let dietdesc = i18n.t("calculation.ownRates");
      orderedSegment.diet.description = `${dietdesc} ${i18n.t("for")} ${i18n.t("calculation.norway")}`;

      // Create an autoexpensegroup for "Nattillegget" (Domestic only)
      const agNightExtra = autoExpenseGroupConstructor();
      agNightExtra.autoExpenseType = "state_overnight_extra";
      agNightExtra.fromDate = orderedSegment.starts;
      agNightExtra.toDate = orderedSegment.stops;
      agNightExtra.location = "Norge";
      orderedSegment.nightExtra = agNightExtra;
    } else {
      // Non-domestic
      const location = getRateBySegment(orderedSegment);
      orderedSegment.diet.location = location.officialPlaceName;
      let dietdesc = i18n.t("calculation.ownRates");
      orderedSegment.diet.description = `${dietdesc} ${i18n.t("for")} ${agDiet.location}`;
    }
  });
  return orderedSegments;
};

// Take a report, return the ordered segments prepopulated with autoexpensegroups
const getDaysInSegments = (orderedSegments) => {
  // Map out individual / continuous segment days
  const start = moment(orderedSegments[0].starts);
  const stop = moment(orderedSegments[orderedSegments.length - 1].stops);
  const lastDayHours = stop.diff(start, "hours", true) % 24;

  // Number of days across all segments, including any dates between segments
  let totalDays = Math.floor(stop.diff(start, "days", true));

  // Add a final day if it is more than 6 hours
  if (lastDayHours >= 6) totalDays++;

  // Has not been away longer than 6 hours, no diet
  if (!totalDays || totalDays <= 0) return [];

  // Sanity check, probably a typo from the user
  if (totalDays > 1000) return [];

  // Start of the current day
  let currStart = start;

  // All days across all segments
  let segmentDays = new Array(totalDays);

  // Index of the current day
  let currDay = 0;

  // Will be true if the last analyzed day was
  let segmentPause = false;
  let firstDayInTravel = true;

  // Loop over all dates across all segments, including any dates between segments
  while (currDay < totalDays) {
    let longestDay = null;
    let totalMinutesInDay = 0;
    let currStop = moment(currStart).add(24, "hours");

    // If the day goes beyond the last day of all segments, truncate the day
    if (currStop.isAfter(stop)) currStop = stop;

    let longestOverlap = 0;

    // Loop over all segments to find one that contains this day
    for (let i = 0; i < orderedSegments.length; i++) {
      // Remove segments that doesn't contain this day
      const segment = orderedSegments[i];
      const segmentStarts = moment(segment.starts);
      const segmentStops = moment(segment.stops);
      // If this segment ends before the current day starts, or starts after the current day stops, skip the rest of the block
      if (segmentStops.isBefore(currStart) || segmentStarts.isAfter(currStop)) continue;

      // This segment contains the current day, but we're in a pause between segments
      if (segmentPause) {
        // Ergo, this day starts when the segment starts
        currStart = segmentStarts;
        currStop = moment(currStart).add(24, "hours");
        // If the day goes beyond the last day of all segments, truncate the day
        if (currStop > stop) currStop = stop;
        // We're no longer paused, a new segment has started
        segmentPause = false;
      }

      const overlapStart = currStart.isAfter(segmentStarts) ? currStart : segmentStarts;
      const overlapStop = currStop.isBefore(segmentStops) ? currStop : segmentStops;
      const totalMinutes = moment(overlapStop).diff(moment(overlapStart), "minutes");

      totalMinutesInDay += totalMinutes;
      if (totalMinutes >= longestOverlap) {
        longestDay = segmentDayConstructor({
          from: moment(currStart.toISOString()),
          to: moment(currStop.toISOString()),
          segment,
          firstDay: firstDayInTravel
        });
        longestOverlap = totalMinutes;
      }
    }

    // It's no longer the first day of the segment
    firstDayInTravel = false;

    if (totalMinutesInDay >= 360) {
      // This day was 6 hours or more, populate the right array slot with it
      segmentDays[currDay] = longestDay;
    } else {
      // This day was under 6 hours, adjust the stop of the previous day and flag a pause between segments
      if (currDay >= 1) {
        const prevday = segmentDays[currDay - 1];
        if (prevday && moment(prevday.to).isAfter(prevday.segment.stops)) {
          prevday.to = prevday.segment.stops;
        }
      }
      // We've entered a pause between segments
      segmentPause = true;

      // The next day will be the first day of a new segment
      firstDayInTravel = true;
    }
    currDay++;
    currStart = moment(currStart).add(24, "hours");
  }

  return segmentDays;
};

// Checks if a trip is considered "overnight" if either of the following is true:
// - Lasts for at least MinTotalHours hours
// - If MinNightHours > 0, at least this many hours were spent between 22:00 and 06:00
const isOvernight = (starts, stops, minTotalHours, minNightHours) => {
  const start = moment(starts);
  const stop = moment(stops);
  let ret = stop.diff(start, "hours", true) >= minTotalHours;

  if (minNightHours > 0) {
    if (start.hours() >= 22 || stop.hours() < 6) {
      // Segment starts inside the 22-06 timespan. If we're still within the 22-06 timespan MinNightHours hours later, and that time is before the segment stops date, it's an overnight trip
      const end = start.add(minNightHours, "h");
      if (end <= stop && (end.hours() < 6 || (end.hours() === 6 && end.minutes === 0))) ret = true;
    } else {
      // Segment starts outside the 22-06 timespan. If the segment lasts longer than 03:00 the following day, it's an overnight trip
      const nextDay = start.add(1, "d");
      const earliestEnd = nextDay.hours(3).minutes(0).seconds(0);
      if (earliestEnd <= stops) ret = true;
    }
  }
  return ret;
};

// Analyze a single day in a segment, return metadata and the refundable/taxfree rate for the day
const analyzeDay = (segmentDay, report, userConfiguration) => {
  const ret = {
    rate: 0,
    taxfreePart: 0,
    domestic: true,
    overnight: true,
    location: null
  };

  // Look for a location and official diet rate, based on a matching geographic location and timestamp
  // If this returns null, it's a domestic segment and we should use the norwegian official rate
  ret.location = getRateBySegmentDay(segmentDay);

  // Is this a domestic or non-domestic day?
  ret.domestic = ret.location === null;

  // Did we stay the night? (Either a 24-hour day, or over 5 hours during night time)
  ret.overnight = isOvernight(segmentDay.from, segmentDay.to, 24, 5);

  // What *would* the rate be if we stayed overnight? (This is used for meal reduction calculation of partial following days, "påbegynt døgn", and is tax specific)
  ret.officialOvernightRate = oneco_diet_private_or_withcooking * 100; // Lodging types 2 ("pensjonat med kok") and 4 ("privat/ulegitimert")
  if (segmentDay.segment.lodgingType === 3) ret.officialOvernightRate = oneco_diet_nocooking * 100; // Lodging type 3 ("pensjonat uten kok")
  if (segmentDay.segment.lodgingType === 1) ret.officialOvernightRate = oneco_diet_hotel * 100; // Lodging type 1 ("hotel")

  // The duration of this day in hours and seconds
  const hours = moment(segmentDay.to).diff(moment(segmentDay.from), "hours", true);
  const seconds = Math.floor(moment(segmentDay.to).diff(moment(segmentDay.from), "seconds", true));

  // Precalculate all possible rates in hundredths for domestic/nondomestic, overnight, daytrip etc
  let daytrip_rate_6_12 = official_diet_daytrip_6_12 * 100;
  let daytrip_rate_12_plus = official_diet_daytrip_12_plus * 100;
  let overnight_rate = ret.officialOvernightRate;

  // Ensure everything is rounded to the nearest whole krone
  daytrip_rate_6_12 = Math.round(daytrip_rate_6_12 / 100) * 100;
  daytrip_rate_12_plus = Math.round(daytrip_rate_12_plus / 100) * 100;
  overnight_rate = Math.round(overnight_rate / 100) * 100;

  // Precalculate taxfree parts in hundredths
  let overnight_taxfree_part = official_diet_taxfree_part_private_or_withcooking * 100; // For lodging types 2 ("pensjonat med kok") and 4 ("privat/ulegitimert")
  if (segmentDay.segment.lodgingType === 3) overnight_taxfree_part = official_diet_taxfree_part_nocooking * 100; // For lodging type 3 ("pensjonat uten kok")
  if (segmentDay.segment.lodgingType === 1) overnight_taxfree_part = official_diet_taxfree_part_hotel * 100; // For lodging type 1 ("hotel") - new as of 2017-12-31
  let daytrip_taxfree_part_6_12 = official_diet_taxfree_part_daytrip_6_12 * 100;
  let daytrip_taxfree_part_12_plus = official_diet_taxfree_part_daytrip_12_plus * 100;

  // Pick the right refund based on length of day
  if (ret.overnight) {
    // OVERNIGHT DIET: Either a full 24-hour day, or an overnight stay during the first day
    ret.rate = overnight_rate;
    ret.taxfreePart = overnight_taxfree_part;
  } else if (hours >= 6) {
    if (segmentDay.firstDay) {
      // DAYTRIP DIET: The first day, but not overnight
      ret.rate = daytrip_rate_6_12;
      ret.taxfreePart = daytrip_taxfree_part_6_12;
      if (seconds > 43200) {
        // Only if *OVER* 12 hours (43200), not equal to (as of V22)
        ret.rate = daytrip_rate_12_plus;
        ret.taxfreePart = daytrip_taxfree_part_12_plus;
      }
    } else {
      // PARTIAL LAST DAY DIET: Not the first day, but also not overnight diet, while lasting 6 hours or more. Must be a partial last day in an overnight trip, aka "påbegynt døgn". Only refundable when it lasts 6 hours or more.
      ret.rate = overnight_rate;
      ret.taxfreePart = overnight_taxfree_part;
    }
  }

  // Taxfree part should be capped to the full rate
  if (ret.taxfreePart > ret.rate) ret.taxfreePart = ret.rate;

  return ret;
};

// Generate autoexpenses for all days in all segments on a report
export const getAutoExpensesV34_oneco = (report, userConfiguration) => {
  const ret = []; // Autoexpensegroups

  // Order reportsegments by date
  let orderedSegments = getSegmentsWithGroups(report);

  // Need at least one segment to work with
  if (orderedSegments.length === 0) return ret;

  // Get the individual days in the segments.
  // Call getDaysInSegments for each "trip" (seen from a dietary standpoint) on this report.
  // By default the whole report is one trip, but the user can force a segment to end the calculation and trigger a new trip.
  let segmentDays = [];
  let currentSegmentGroup = [];
  for (var i = 0; i < orderedSegments.length; i++) {
    currentSegmentGroup.push(orderedSegments[i]);
    if (orderedSegments[i].endsDietCalculation || i === orderedSegments.length - 1) {
      // This segments is the last one, or flagged as a trip-ending one
      var days = getDaysInSegments(currentSegmentGroup);
      if (days !== null) segmentDays.push(...days);
      currentSegmentGroup = [];
    }
  }

  if (!segmentDays || segmentDays.length === 0) return ret; // No days to work with

  // Calculate autoexpenses for each day
  segmentDays
    .filter((sd) => sd)
    .forEach((segmentDay) => {
      let dayInfo = analyzeDay(segmentDay, report, userConfiguration);
      const diet_expensetype_label = "custom_diet";
      const diet_location_label = dayInfo.domestic ? i18n.t("calculation.norway") : dayInfo.location.officialPlaceName;

      // Add autoexpense for this timespan if we have a rate over 0
      if (dayInfo.rate > 0) {
        // Build an expense for this timespan
        const expense = autoExpenseConstructor();

        expense.autoExpenseType = diet_expensetype_label;
        expense.fromDate = segmentDay.from;
        expense.toDate = segmentDay.to;

        expense.location = diet_location_label;
        expense.geoCityId = segmentDay.segment.cityId;
        expense.geoCountryId = segmentDay.segment.countryId;

        expense.sum = dayInfo.rate;
        expense.taxfreeSum = dayInfo.taxfreePart;
        expense.taxableSum = dayInfo.rate - dayInfo.taxfreePart;

        // Preemptive sanity check and recalculation for tax parts
        if (expense.taxfreeSum < 0) expense.taxfreeSum = 0;
        if (expense.taxableSum < 0) expense.taxableSum = 0;
        if (expense.taxfreeSum > expense.sum) expense.taxfreeSum = expense.sum;
        expense.taxableSum = expense.sum - expense.taxfreeSum;

        // Extended fields for export usage
        expense.hours = moment(segmentDay.to).diff(moment(segmentDay.from), "hours", true);
        expense.units = 1;
        expense.unitSum = expense.sum;
        expense.domestic = dayInfo.domestic ? 1 : 0;
        expense.overnight = dayInfo.overnight ? 1 : 0;
        expense.lodgingType = segmentDay.segment.lodgingType;
        expense.farFromHome = segmentDay.segment.farFromHome ? 1 : 0;
        expense.originalSum = dayInfo.rate; // The sum before meal reductions

        // Has the user set any meal-based overrides? This will reduce the total compensation
        // Filter on overrides that actually does something
        const aeo = report.autoExpenseOverrides
          .filter((o) => o.excludeDiet || o.excludedLodging || o.freeBreakfast || o.freeLunch || o.freeDinner)
          .find((o) => moment(o.expenseDate).isSame(moment(segmentDay.from), "day"));

        if (aeo) {
          // Calculate the reduction sum for each meal for the total sum, based on a fraction of the overnight rate for the location
          const meal_reduction_breakfast_sum = Math.round(dayInfo.officialOvernightRate * meal_reduction_breakfast);
          const meal_reduction_lunch_sum = Math.round(dayInfo.officialOvernightRate * meal_reduction_lunch);
          const meal_reduction_dinner_sum = Math.round(dayInfo.officialOvernightRate * meal_reduction_dinner);

          // Calculate the reduction sum for each meal for the taxfree part
          const meal_reduction_breakfast_taxfree_sum = Math.round(dayInfo.taxfreePart * meal_reduction_breakfast);
          const meal_reduction_lunch_taxfree_sum = Math.round(dayInfo.taxfreePart * meal_reduction_lunch);
          const meal_reduction_dinner_taxfree_sum = Math.round(dayInfo.taxfreePart * meal_reduction_dinner);

          // Reduce the taxfree and taxable sums for each free meal
          if (aeo.freeBreakfast) {
            expense.sum -= meal_reduction_breakfast_sum;
            expense.breakfastReductionSum = meal_reduction_breakfast_sum;
            expense.taxfreeSum -= meal_reduction_breakfast_taxfree_sum;
            expense.freeBreakfast = 1;
          }
          if (aeo.freeLunch) {
            expense.sum -= meal_reduction_lunch_sum;
            expense.lunchReductionSum = meal_reduction_lunch_sum;
            expense.taxfreeSum -= meal_reduction_lunch_taxfree_sum;
            expense.freeLunch = 1;
          }
          if (aeo.freeDinner) {
            expense.sum -= meal_reduction_dinner_sum;
            expense.dinnerReductionSum = meal_reduction_dinner_sum;
            expense.taxfreeSum -= meal_reduction_dinner_taxfree_sum;
            expense.freeDinner = 1;
          }

          // Cap taxfree part and recalculate taxable part
          if (expense.taxfreeSum > expense.sum) expense.taxfreeSum = expense.sum;
          expense.taxableSum = expense.sum - expense.taxfreeSum;

          // Initial conversion from backend v33 below, kept commented for comparison in case this comes back to us

          // // Calculate the reduction sum for each meal for the total sum, based on a fraction of the overnight rate for the location
          // const meal_reduction_breakfast_sum = Math.round(dayInfo.officialOvernightRate * meal_reduction_breakfast);
          // const meal_reduction_lunch_sum = Math.round(dayInfo.officialOvernightRate * meal_reduction_lunch);
          // const meal_reduction_dinner_sum = Math.round(dayInfo.officialOvernightRate * meal_reduction_dinner);

          // // Grab the difference between the taxfree rate and oneco's rate.
          // // This diff must be moved from TaxfreeSum to TaxableSum for the day, but apply meal reductions to it first
          // let taxable_diff_between_taxfree_and_oneco_rate = dayInfo.rate - dayInfo.taxfreePart;
          // // Calculate the reduction sum for each meal for the total sum, based on a fraction of the overnight rate for the location
          // const taxable_diff_reduction_breakfast_sum = Math.round(taxable_diff_between_taxfree_and_oneco_rate * meal_reduction_breakfast);
          // const taxable_diff_reduction_lunch_sum = Math.round(taxable_diff_between_taxfree_and_oneco_rate * meal_reduction_lunch);
          // const taxable_diff_reduction_dinner_sum = Math.round(taxable_diff_between_taxfree_and_oneco_rate * meal_reduction_dinner);

          // // Reduce the taxfree and taxable sums for each free meal
          // if (aeo.freeBreakfast) {
          //   expense.sum -= meal_reduction_breakfast_sum;
          //   expense.breakfastReductionSum = meal_reduction_breakfast_sum;
          //   expense.freeBreakfast = 1;
          //   taxable_diff_between_taxfree_and_oneco_rate -= taxable_diff_reduction_breakfast_sum;
          // }
          // if (aeo.freeLunch) {
          //   expense.sum -= meal_reduction_lunch_sum;
          //   expense.lunchReductionSum = meal_reduction_lunch_sum;
          //   expense.freeLunch = 1;
          //   taxable_diff_between_taxfree_and_oneco_rate -= taxable_diff_reduction_lunch_sum;
          // }
          // if (aeo.freeDinner) {
          //   expense.sum -= meal_reduction_dinner_sum;
          //   expense.dinnerReductionSum = meal_reduction_dinner_sum;
          //   expense.freeDinner = 1;
          //   taxable_diff_between_taxfree_and_oneco_rate -= taxable_diff_reduction_dinner_sum;
          // }

          // // Cap taxfree part and recalculate taxable part
          // if (expense.taxfreeSum > expense.sum) expense.taxfreeSum = expense.sum;
          // expense.taxableSum = expense.sum - expense.taxfreeSum;

          // // Move the taxable diff from taxfree part to taxable part
          // if (taxable_diff_between_taxfree_and_oneco_rate > 0)
          // {
          //     // We can only move up to the already taxfree part, so cap it
          //     if (taxable_diff_between_taxfree_and_oneco_rate > expense.taxfreeSum) taxable_diff_between_taxfree_and_oneco_rate = expense.taxfreeSum;
          //     expense.taxfreeSum -= taxable_diff_between_taxfree_and_oneco_rate;
          //     expense.taxableSum += taxable_diff_between_taxfree_and_oneco_rate;
          // }

          // Zero out everything if all meals are checked, in case of rounding issues. (This is only true as long as the three meal reduction factors adds up to 100%)
          if (aeo.freeBreakfast && aeo.freeLunch && aeo.freeDinner) {
            expense.sum = 0;
            expense.taxfreeSum = 0;
            expense.taxableSum = 0;
          }

          // Zero out everything if this day is excluded
          if (aeo.excludeDiet) {
            expense.sum = 0;
            expense.taxfreeSum = 0;
            expense.taxableSum = 0;
            expense.excluded = 1;
          }

          // Sanity check
          if (expense.sum < 0) expense.sum = 0;
          if (expense.taxfreeSum < 0) expense.taxfreeSum = 0;
          if (expense.taxableSum < 0) expense.taxableSum = 0;
        }

        segmentDay.segment.diet.autoExpenses.push(expense);
      }

      // Check for "Nattillegget"; extra compensation per overnight stay. Triggered and calculated as a single-expense autoexpensegroup under the following conditions:
      // * IncludeNightExtra is checked by the user
      // * One or more overnight stays
      if (segmentDay.segment.includeNightExtra) {
        // One night = 6 hours between 22 and 06
        if (isOvernight(segmentDay.from, segmentDay.to, 21, 6)) {
          const nightexpense = autoExpenseConstructor();
          nightexpense.autoExpenseType = "state_overnight_extra";
          nightexpense.fromDate = segmentDay.from;
          nightexpense.geoCityId = segmentDay.segment.cityId;
          nightexpense.geoCountryId = segmentDay.segment.countryId;
          nightexpense.location = diet_location_label;
          nightexpense.sum = oneco_overnight_extra * 100;
          nightexpense.toDate = segmentDay.to;

          nightexpense.hours = moment(segmentDay.to).diff(moment(segmentDay.from), "hours", true);
          nightexpense.units = 1;
          nightexpense.unitSum = oneco_overnight_extra * 100;
          nightexpense.domestic = 1;
          nightexpense.overnight = 1;
          nightexpense.lodgingType = segmentDay.segment.lodgingType;
          nightexpense.farFromHome = segmentDay.segment.farFromHome ? 1 : 0;
          nightexpense.originalSum = nightexpense.sum;

          segmentDay.segment.nightExtra.description = `${i18n.t(
            "calculation.nightExtra"
          )} kr ${oneco_overnight_extra} ${i18n.t("calculation.perNight")}`;
          segmentDay.segment.nightExtra.autoExpenses.push(nightexpense);
        }
      }
    });

  // Merge and cleanup
  orderedSegments.forEach((orderedSegment) => {
    // Merge all nightextras if there are more than 1
    // if (
    //   orderedSegment.nightExtra &&
    //   orderedSegment.nightExtra.autoExpenses.length > 1
    // ) {
    //   const numnights = orderedSegment.nightExtra.autoExpenses.length;
    //   orderedSegment.nightExtra.autoExpenses[0].units = numnights;
    //   orderedSegment.nightExtra.autoExpenses[0].sum *= numnights;
    //   orderedSegment.nightExtra.autoExpenses.length = 1;
    //   orderedSegment.nightExtra.description = `${i18n.t(
    //     "calculation.nightExtra"
    //   )}, ${numnights} ${i18n.t(
    //     "calculation.nights"
    //   )} x kr ${official_overnight_extra}`;
    // }
    // Return any groups that contains lines
    if (orderedSegment.diet && orderedSegment.diet.autoExpenses.length > 0) ret.push(orderedSegment.diet);
    if (orderedSegment.nightExtra && orderedSegment.nightExtra.autoExpenses.length > 0)
      ret.push(orderedSegment.nightExtra);
  });

  return ret;
};
