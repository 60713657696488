import { jsonFetch } from "./api";
/*
  This API lets an unauthenticated user create an account; a new person within a new company.

  A typical flow goes like this:
  1) Show signup form for fields defined in signupData object
  2) Verify availability of email and mobile number as we go, using isMobileAvailable() and isEmailAvailable(). 
  3) When the user confirms, hit sendSms() to send a one-time code to the user's mobile phone. (For development purposes, pass in the secret devkey to ignore the mobile number and suppress the actual SMS.)
  4) Prompt the user for the one time code received by SMS, add it to signupData, and hit save(). (Pass the secret devkey here too to suppress one time code verification.)
  5) If all is well, the user is now created and can login

  The endpoints wrapped in trycatch might return other HTTP responses than 200 if the payload wasn't properly validated in advance.
  This wrapper returns true for 200, and false for other responses.
*/

/**
 * @typedef {object} signupData
 * @property {string} firstname
 * @property {string} lastname
 * @property {string} email - User's login email address, must be valid and not in use by others
 * @property {string} mobile - User's mobile number, must be valid ([4|7]\d{7}) and not in use by others. Should be blank if correct devkey is provided to suppress SMS check
 * @property {string} company - Company name, optional
 * @property {string} orgno - Orgno, optional, but must match \d{9} if provided
 * @property {string} code - One-time code sent to user by SMS by SendSms(), must be provided by user. Will be ignored if correct devkey is provided to suppress SMS check
 * @property {string} campaign - Campaign code for logging purposes
 * @property {string} pass - User-selected password, must not be blank
 * @property {string} loginurl - URL to the web frontend's login page. This will be emailed to the user with instructions to go there and login
 * @property {string} devkey - If the correct key is provided, SendSms() will not send anything (and always succeed), and no code check is performed during signup. No mobile number will be added to the user.
 */

/**
 * @typedef {object} upgradeData
 * @property {string} person.firstname
 * @property {string} person.lastname
 * @property {string} person.email
 * @property {string} company.name - Company name for invoicing
 * @property {string} company.orgno - Orgno for invoicing,  must match \d{9}
 * @property {string} company.address - Company address for invoicing
 * @property {string} company.zip
 * @property {string} company.city
 * @property {number} licenses - The number of licenses the customer wants to start out with, must be be an integer between 1 and 10
 * @property {string} key - The user's upgrade key, found in profile details. Valid key required to identify the user.
 */

/**
 * @typedef {object} resellerDetails
 * @property {string} name - Reseller name
 */

/**
 * Check if a mobile number is available. This endpoint is ratelimited, expect a few seconds roundtrip.
 * If a mobile number is already taken, it can't be used to sign up a new user.
 * @param {string} mobile Mobile number (norwegian only!) without country code, should match [4|9]\d{7} (8 digits, starting with either 4 or 9).
 * @returns {boolean} Whether number is available
 */
export const isMobileAvailable = async (mobile: string): Promise<boolean> => {
  const fetchOptions = {
    method: "GET"
  };
  return jsonFetch(`signup/IsMobileAvailable?mobile=${mobile}`, fetchOptions);
};

/**
 * Check if an email address is available. This endpoint is ratelimited, expect a few seconds roundtrip.
 * If an email is already taken, it can't be used to sign up a new user.
 * @param {string} email Email address
 * @returns {boolean} Whether number is available
 */
export const isEmailAvailable = async (email: string): Promise<boolean> => {
  const fetchOptions = {
    method: "GET"
  };
  return jsonFetch(`signup/IsEmailAvailable?email=${email}`, fetchOptions);
};

/**
 * Pass in a reseller token (acquired from adminweb) and get the name of the reseller back, if valid.
 * The token is usually handed to end users by the reseller as part of a unique self-signup link for that reseller
 * @param {string} resellerToken Token from the reseller
 * @returns {resellerDetails} Reseller details
 */
export const getResellerDetails = async (resellerToken: string): Promise<{ name: string }> => {
  const fetchOptions = {
    method: "GET"
  };
  return jsonFetch(`signup/GetResellerDetails?resellerToken=${resellerToken}`, fetchOptions);
};

/**
 * Sends an SMS message to the provided (norwegian) mobile number in the payload. Must be a valid 8-digit number (\d8) and not already taken.
 * The payload should contain the complete signupData that you intend to pass to save(), with the one-time code set to blank
 * @param {signupData} signupData
 */
interface SignupData {
  firstname: string;
  lastname: string;
  email: string;
  mobile: string;
  company: string;
  orgno: string;
  code: string;
  campaign: string;
  pass: string;
  loginurl: string;
  devkey: string;
}
export const sendSms = async (signupData: SignupData): Promise<boolean> => {
  const fetchOptions = {
    method: "POST",
    body: JSON.stringify(signupData)
  };
  try {
    await jsonFetch(`signup/SendSms`, fetchOptions);
  } catch (err) {
    return false;
  }
  return true;
};

/**
 * Finalizes the signup process, creates the user, company and related entities, and emails the user a welcome message.
 * See object definition for payload details
 * @param {signupData} signupData
 */
export const save = async (signupData: SignupData): Promise<boolean> => {
  const fetchOptions = {
    method: "POST",
    body: JSON.stringify(signupData)
  };
  try {
    await jsonFetch(`signup/Save`, fetchOptions);
  } catch (err) {
    return false;
  }
  return true;
};

/**
 * Self-signed-up users will have an "upgrade key" in their profile data. Pass this key to get the user's existing user/company data to prepopulate the upgrade form.
 *
 * @param {string} key User's upgrade key
 * @returns {object} Most of the user/company data required for the upgrade form
 */
interface UpgradeDetails {
  person: {
    firstname: string;
    lastname: string;
    email: string;
  };
  company: {
    orgno: string;
    name: string;
    address: string;
    zip: string;
    city: string;
  };
}
export const upgradeDetails = async (key: string): Promise<UpgradeDetails | null> => {
  const fetchOptions = {
    method: "GET"
  };
  try {
    const ret = await jsonFetch(`signup/UpgradeDetails?key=${key}`, fetchOptions);
    return ret;
  } catch (err) {
    return null;
  }
};

/**
 * Finalizes the upgrade process from a free to paying customer
 * Some details can be changed, see object definition for payload details
 * @param {upgradeData} upgradeData
 * @returns {boolean} Success or failure
 */
interface UpgradeData extends UpgradeDetails {
  licenses: number;
  key: string;
}
export async function submitUpgrade(upgradeData: UpgradeData): Promise<boolean> {
  const fetchOptions = {
    method: "POST",
    body: JSON.stringify(upgradeData)
  };
  try {
    await jsonFetch(`signup/SubmitUpgrade`, fetchOptions);
  } catch (err) {
    return false;
  }
  return true;
}
