import { useState } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { capitalize, formatMoney } from "../../shared/utils/helpers";
import * as reportApprovalApi from "../../shared/api/reportApproval";
import { addStatusesToAdvancedApprovalAttempt, getAttemptComments, getDecisionPending } from "../../shared/utils/advancedApproval";
import { Button, FormControl } from "react-bootstrap";
import SelectGroup from "../common/SelectGroup";
import Spinner from "../common/Spinner";
import Icon from "../common/Icon";
import { AdvancedApprovalAttempt } from "../../shared/types";
import ButtonWithSpinner from "../common/ButtonWithSpinner";

interface AdvancedApprovalAttemptTimelineProps {
  attempt: AdvancedApprovalAttempt;
  onDecideAdvanced?: (approved: boolean, approvalAttemptUuid: string, comment: string) => void;
  onRedirectToFlow?: (attemptUuid: string, currentDecisionUuid: string, newFlowId: number) => void;
  onViewAdvanced?: (advancedApprovalAttemptDecisionUuid: string) => void;
  active?: boolean;
  disabled?: boolean;
  isDeciding?: boolean;
}
const AdvancedApprovalAttemptTimeline = ({
  attempt,
  onDecideAdvanced,
  onRedirectToFlow,
  onViewAdvanced,
  active,
  disabled,
  isDeciding
}: AdvancedApprovalAttemptTimelineProps) => {
  const [t] = useTranslation();
  const [approvalComment, setApprovalComment] = useState("");
  const [switchingFlows, setSwitchingFlows] = useState(false);
  const [flowOptions, setFlowOptions] = useState<{ label: string; value: number }[]>([]);
  const [flowOptionsLoaded, setFlowOptionsLoaded] = useState(false);
  const [redirectFlowId, setRedirectFlowId] = useState<number | null>(null);

  // Call when the approver wants to switch the approval flow
  // Shows the new flow UI and loads available flows
  const switchFlow = async (attempt: AdvancedApprovalAttempt) => {
    setSwitchingFlows(true);
    const pendingDecision = getDecisionPending(attempt);
    if (pendingDecision && flowOptions.length === 0) {
      reportApprovalApi.alternativeFlows(pendingDecision.uuid).then((flows) => {
        const flowOptions = flows
          .filter((flow) => flow.id !== attempt.flowId && flow.active)
          .map((flow) => ({ label: flow.description, value: flow.id }));
        const redirectFlowId = flowOptions.length > 0 ? flowOptions[0].value : null;
        setFlowOptions(flowOptions);
        setFlowOptionsLoaded(true);
        setRedirectFlowId(redirectFlowId);
      });
    }
  };

  const confirmSwitchFlow = (attempt: AdvancedApprovalAttempt, flowId: number) => {
    if (!onRedirectToFlow) return;
    const pendingDecision = getDecisionPending(attempt);
    if (pendingDecision) onRedirectToFlow(attempt.uuid, pendingDecision.uuid, flowId);
  };

  // Contents of the linkbutton: report description, id and sum, userinfo
  const descriptionLinkDescription = attempt.report?.description ? attempt.report.description : t("noDescription");
  const descriptionLinkIdAndValue = (
    <span className="gray">{`(ID ${attempt.report?.id} / kr ${attempt.report?.calculatedSum ? formatMoney(attempt.report.calculatedSum) : 0})`}</span>
  );
  const descriptionLinkFrom = `${t("from")} ${attempt.report?.personFirstName} ${attempt.report?.personLastName} / ${attempt.report?.personEmail}`;

  /* The onViewAdvanced lambda below is ugly but works. Use the first available decision uuid for purposes of fetching the external report */
  const descriptionLink = attempt.report ? (
    <button className="fakelink" onClick={() => onViewAdvanced && onViewAdvanced(attempt.decisions.find((d) => d.uuid)?.uuid || "")}>
      {descriptionLinkDescription}
      &nbsp;
      {descriptionLinkIdAndValue}
      &nbsp;
      {descriptionLinkFrom}
    </button>
  ) : null;

  // Synthesize a "status" property for each step and member to simplify adding classes during render
  const extendedAttempt = addStatusesToAdvancedApprovalAttempt(attempt);
  const comments = getAttemptComments(attempt);

  const renderDate = (d?: string) => (d ? moment(d) : moment()).fromNow();
  const iconRejected = <Icon icon="emoticonSad" title={capitalize(t("approvals.statuses.rejected"))}></Icon>;
  const iconApproved = <Icon icon="emoticonSmile" title={capitalize(t("approvals.statuses.approved"))}></Icon>;
  const iconCancelled = <Icon icon="stop" title={capitalize(t("approvals.statuses.cancelled"))}></Icon>;

  const ret = (
    <div className={active ? "aaa aaa-active" : "aaa"}>
      <div className="aaa-report">{descriptionLink}</div>
      <div className="aaa-description">
        <strong>{capitalize(t("approvals.sentTo"))}</strong>:&nbsp;
        {extendedAttempt.flow.description}, {renderDate(extendedAttempt.created)}
        {extendedAttempt.status === 2 && (
          <span className="aaa-status aaa-status-rejected">
            {" "}
            - <strong>{capitalize(t("approvals.statuses.rejected"))}</strong>
          </span>
        )}
        {extendedAttempt.status === 3 && (
          <span className="aaa-status aaa-status-approved">
            {" "}
            - <strong>{capitalize(t("approvals.statuses.approved"))}</strong>
          </span>
        )}
        {extendedAttempt.status === 4 && (
          <span className="aaa-status aaa-status-cancelled">
            {" "}
            - <strong>{capitalize(t("approvals.statuses.cancelled"))}</strong>
          </span>
        )}
      </div>

      <div className="aaa-comment">
        {extendedAttempt.comment && <em>❯ {extendedAttempt.comment}</em>}
        {comments.map((c, i) => (
          <div key={`c${i}`}>
            <Icon icon="message" title={t("approvals.approverComment")}></Icon>
            {c.name}: &rdquo;<em>{c.comment}</em>&rdquo;
          </div>
        ))}
      </div>

      <div className={extendedAttempt.status === 3 ? "aaa-steps aaa-steps-approved" : "aaa-steps"}>
        {extendedAttempt.flow.steps.map((step, stepIndex) => (
          <div className={`aaa-step aaa-step-${step.status}`} key={stepIndex}>
            <div className="aaa-step-description">
              <Icon
                icon={step.logicType === "any" ? "singleUser" : "multipleUsers"}
                title={capitalize(t(`approvals.logicTypes.${step.logicType}`))}
              ></Icon>

              {step.description}
              {step.status === "rejected" && <div className="aaa-step-status aaa-status-rejected">{iconRejected}</div>}
              {step.status === "approved" && <div className="aaa-step-status aaa-status-approved">{iconApproved}</div>}
              {step.status === "cancelled" && <div className="aaa-step-status aaa-status-cancelled">{iconCancelled}</div>}
            </div>
            <div className="aaa-step-members">
              {step.members.map((member) => (
                <div className={`aaa-step-member aaa-step-member-${member.status}`} key={member.id}>
                  {member.description}&nbsp;
                  {member.status === "approved" && <Icon icon="thumbUp" title={capitalize(t("approvals.statuses.approved"))} />}
                  {member.status === "rejected" && <Icon icon="thumbDown" title={capitalize(t("approvals.statuses.rejected"))} />}
                  {member.usedForReportEditing && <Icon icon="edit" title={capitalize(t("approvals.approverHasEdited"))} />}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      {active && !switchingFlows && (
        <div className="aaa-form">
          <FormControl
            type={"text"}
            componentClass={"textarea"}
            placeholder={t("yourComment")}
            onChange={(event: React.FormEvent<FormControl & HTMLInputElement>) => setApprovalComment(event.currentTarget.value)}
            disabled={disabled}
          />
          <ButtonWithSpinner
            bsStyle="danger"
            disabled={disabled || !onDecideAdvanced}
            showSpinner={isDeciding}
            onClick={() => onDecideAdvanced && onDecideAdvanced(false, attempt.uuid, approvalComment)}
            title={capitalize(t("disapprove"))}
          >
            {capitalize(t("disapprove"))}
          </ButtonWithSpinner>
          <ButtonWithSpinner
            bsStyle="success"
            disabled={disabled || !onDecideAdvanced}
            showSpinner={isDeciding}
            onClick={() => onDecideAdvanced && onDecideAdvanced(true, attempt.uuid, approvalComment)}
            title={capitalize(t("approve"))}
          >
            {capitalize(t("approve"))}
          </ButtonWithSpinner>
          <ButtonWithSpinner
            bsStyle="default"
            disabled={disabled}
            showSpinner={isDeciding || switchingFlows}
            onClick={() => switchFlow(attempt)}
            title={capitalize(t("approvals.redirectToFlow"))}
          >
            {capitalize(t("approvals.redirectToFlow"))}
          </ButtonWithSpinner>
        </div>
      )}

      {active && switchingFlows && (
        <div className="aaa-form aaa-form-redirect-flow">
          {flowOptionsLoaded ? (
            flowOptions.length > 0 ? (
              <SelectGroup
                field="redirectToFlow"
                label={t("approvals.selectNewFlow")}
                value={redirectFlowId}
                onChange={(value: number) => setRedirectFlowId(value)}
                options={flowOptions}
              />
            ) : (
              <div>
                <strong>{t("approvals.noOtherFlowsFound")}</strong>
              </div>
            )
          ) : (
            <div>
              <Spinner size="32px" />
            </div>
          )}
          <Button bsStyle="warning" disabled={disabled} onClick={() => setSwitchingFlows(false)} title={capitalize(t("cancel"))}>
            {capitalize(t("cancel"))}
          </Button>
          <ButtonWithSpinner
            bsStyle="success"
            disabled={disabled || !flowOptionsLoaded || flowOptions.length === 0 || !redirectFlowId}
            showSpinner={isDeciding}
            onClick={() => redirectFlowId && confirmSwitchFlow(attempt, redirectFlowId)}
            title={capitalize(t("confirm"))}
          >
            {capitalize(t("confirm"))}
          </ButtonWithSpinner>
        </div>
      )}
    </div>
  );

  return ret;
};

export default AdvancedApprovalAttemptTimeline;
