import { SupportRequest } from "../types";
import { jsonFetch } from "./api";

/**
 * @typedef {object} supportRequest
 * @property {string} email - Email the user can be reached at
 * @property {string} phone - Phone number the user can be reached at
 * @property {string} category - Category of the support issue
 * @property {string} url - URL of the current page, if applicable
 * @property {string} message - Message to support
 * @property {supportAttachment[]} attachments - Attachments for this support request
 */

/**
 * @typedef {object} supportAttachment
 * @property {string} originalFilename - Original filename from the user
 * @property {string} blobRef - Reference (file name) returned from blob API via saveSupportAttachment
 * @property {string} mimeType - Mime type of the attachment
 */

/**
 * Submit a support request
 * Attachments can be added, submit them via the saveSupportAttachment blob API and add the blob references here
 * @param {supportRequest} supportRequest The support request details
 * @returns {boolean} Success or failure
 */

export const sendSupportRequest = async (supportRequest: SupportRequest): Promise<boolean> => {
  const fetchOptions = {
    method: "POST",
    body: JSON.stringify(supportRequest)
  };
  try {
    await jsonFetch(`support/sendsupportrequest`, fetchOptions);
  } catch (err) {
    return false;
  }
  return true;
};
