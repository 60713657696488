import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import Icon from "../common/Icon";
import AttachmentThumbnail from "../attachment/AttachmentThumbnail";
import { formatMoney, formatDateRange, capitalize } from "../../shared/utils/helpers";

import "../../styles/slick-carousel.css";
import { dateRangeByReport, reportStatusByReport } from "../../shared/utils/reportUtils";
import { Attachment, Report } from "../../shared/types";

interface ReportListItemProps {
  report: Report;
  onDelete?: (report: Report) => void;
  onEdit?: (report: Report) => void;
  renderMode: "table" | "drawer" | "cards";
  alternate?: boolean;
  onShowAttachables?: (report: Report) => void;
  onDrawerSelection?: (report: Report) => void;
  attachments?: Attachment[];
}

const ReportListItem = ({
  report,
  onDelete,
  onEdit,
  renderMode,
  alternate,
  onShowAttachables,
  onDrawerSelection,
  attachments
}: ReportListItemProps) => {
  const [t] = useTranslation();
  const { deleted, calculation, description, reportTypeId } = report;
  const readOnly = deleted || report.status === 2;
  const startStop = dateRangeByReport(report);
  const dates = formatDateRange(startStop.starts, startStop.stops);
  const sum = formatMoney(calculation.sumTotal);

  let iconNameReportType = "travelReport";
  let reportTypeDescription = capitalize(t("travelReport"));

  if (reportTypeId === 2) {
    iconNameReportType = "expenseReport";
    reportTypeDescription = capitalize(t("expenseReport"));
  }
  if (reportTypeId === 3) {
    iconNameReportType = "drivingReport";
    reportTypeDescription = capitalize(t("drivingReport"));
  }

  const datesWithIcon = (
    <React.Fragment>
      <Icon icon="timespan" />
      {dates}
    </React.Fragment>
  );

  const descriptionLink = (
    <button className="fakelink" onClick={() => onEdit && onEdit(report)}>
      <span>{description || "(No description)"}</span>
    </button>
  );

  const status = reportStatusByReport(report);
  const reportStatus = (
    <div
      className={
        (status.type === 0 ? "report-status-open" : "") +
        (status.type === 1 ? "report-status-approving" : "") +
        (status.type === 2 ? "report-status-finished" : "") +
        (status.type === 3 ? "report-status-rejected" : "")
      }
    >
      {status.text}
    </div>
  );

  const maxAttachments = renderMode === "cards" ? 12 : 4;
  const attachmentThumbnails = attachments
    ? attachments
        .filter((attachment) => !attachment.deleted)
        .slice(0, maxAttachments)
        .map((a) => (
          <div key={a.uuid}>
            <AttachmentThumbnail attachment={a} />
          </div>
        ))
    : [];

  const attachButton = !readOnly && onShowAttachables && (
    <Button className="item-attach-button" title={capitalize(t("attachItems"))} onClick={() => onShowAttachables(report)}>
      <Icon icon="attachment" />
    </Button>
  );

  const deleteButton = !readOnly && onDelete && (
    <Button title={capitalize(t("delete"))} onClick={() => onDelete(report)}>
      <Icon icon="trash" />
    </Button>
  );

  const itemType = <Icon icon={iconNameReportType} title={reportTypeDescription} />;

  let extraClass = "";
  if (renderMode === "table" && alternate) extraClass += " alternate";

  /* Render as cards */
  if (renderMode === "cards") {
    return (
      <div className={`card-item ${extraClass}`}>
        <div className="item-description">{descriptionLink}</div>
        <div className="item-thumbnails">{attachmentThumbnails}</div>
        <div className="item-actions">
          {attachButton} {deleteButton}
        </div>
        <div className="item-type">
          {itemType} <span> {reportTypeDescription}</span>
        </div>
        <div className="item-sum">{`/ kr ${sum}`}</div>
        <div className="item-dates">{datesWithIcon}</div>
      </div>
    );
  }

  /* Render as drawer */
  if (renderMode === "drawer") {
    const drawerItemClass = readOnly ? "drawer-item drawer-item-readonly" : "drawer-item";
    const title = readOnly ? t("attachablesDrawer.cantDetachFromReportBecauseReadOnly") : "";
    return (
      <div className={drawerItemClass} role="button" tabIndex={0} onClick={() => onDrawerSelection && onDrawerSelection(report)} title={title}>
        <div className={`item-description ${extraClass}`}>{description}</div>
        <div className={`item-dates ${extraClass}`}>{dates}</div>
        <div className={`item-sum ${extraClass}`}>{sum}</div>
      </div>
    );
  }
  /* Render as table */
  return (
    <React.Fragment>
      <div className={`item-checkbox ${extraClass}`} />
      <div className={`item-dates ${extraClass}`}>{dates}</div>
      <div className={`item-description ${extraClass}`}>{descriptionLink}</div>
      <div className={`item-type ${extraClass}`}>{itemType}</div>
      <div className={`item-sum ${extraClass}`}>{sum}</div>
      <div className={`item-status ${extraClass}`}>{reportStatus}</div>
      <div className={`item-thumbnails ${extraClass}`}>{attachmentThumbnails}</div>
      <div className={`item-actions ${extraClass}`}>
        {attachButton}
        {deleteButton}
      </div>
    </React.Fragment>
  );
};

export default ReportListItem;
