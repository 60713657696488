import { ApiInboxListResponse, Inbox } from "../types";
import { jsonFetch, jsonFetchWithServerTime } from "./api";

/**
 * Gets all inboxes for the currently logged in user.
 * @param {string} changedSince - If this is included only inboxes changed after this date will be returned
 * @returns {response} - An object containing an array of inboxes and serverTime (string)
 */
export const list = async (changedSince?: string): Promise<ApiInboxListResponse> => {
  const fetchOptions = {
    method: "GET"
  };
  const qs = changedSince ? `?changedSince=${changedSince}` : "?skipDeleted=true";
  const { body, serverTime } = await jsonFetchWithServerTime(`e/inbox/list${qs}`, fetchOptions);
  return {
    inboxes: body,
    serverTime
  };
};

/**
 * Gets a single inbox by uuid
 * @param {number} uuid The uuid of the inbox
 * @returns {inbox} - A single inbox object containing
 * inboxAttachments
 */
export const details = async (uuid: string): Promise<Inbox> => {
  const fetchOptions = {
    method: "GET"
  };
  return jsonFetch(`e/inbox/${uuid}`, fetchOptions);
};

// Save a single inbox. inboxAttachments will be ignored, and must be individually saved through the inboxAttachment API
export const save = async (inbox: Inbox): Promise<Inbox> => {
  const fetchOptions = {
    method: "POST",
    body: JSON.stringify(inbox)
  };
  return jsonFetch(`e/inbox/save`, fetchOptions);
};

/**
 * Deletes a single inbox by uuid
 * @param {number} uuid The uuid of the inbox
 */
export const del = async (uuid: string): Promise<void> => {
  const fetchOptions = {
    method: "DELETE"
  };
  return jsonFetch(`e/inbox/${uuid}`, fetchOptions);
};
