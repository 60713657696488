import moment from "moment";
import { uuid } from "../utils/helpers";
import { capitalize } from "../utils/helpers";
import i18n from "../i18n/i18nConfig";
import { Mileage, MileageTrack, MileageTrackSegment } from "../types";

interface MileageConstructorOptions {
  description?: string;
  personId: number;
  addressFrom?: string;
  addressTo?: string;
  generator?: string;
  starts?: string;
  stops?: string;
  snapped?: string;
  totalMeters?: number;
  trackingAccuracy?: "" | "normal" | "power_saving";
  track?: MileageTrack;
}

export const mileageConstructor = (opts: MileageConstructorOptions): Mileage => {
  const {
    description = capitalize(i18n.t("newMileage")),
    personId,
    addressFrom = "",
    addressTo = "",
    generator = "webclient",
    starts = moment().toISOString(),
    stops = moment().toISOString(),
    snapped = "",
    totalMeters = 0,
    trackingAccuracy = "normal",
    track = {}
  } = opts;
  const created = moment().toISOString();
  const changed = moment().toISOString();
  const mileageUuid = uuid();
  return {
    uuid: mileageUuid,
    created,
    changed,
    personId,
    description,
    addressFrom,
    addressTo,
    generator,
    starts,
    stops,
    snapped,
    deleted: "",
    totalMeters,
    track,
    trackingAccuracy,
    dirty: created
  };
};

// Generate a hardcoded mileage with a 4km track for testing/debugging purposes
export const getDummyTrack = (personId: number): Mileage => {
  const description = "Dummy track " + Math.round(Math.random() * 100000);
  const segment = getDummyTrackSegment();
  const track = {
    trackSegments: [segment]
  };
  const ret = mileageConstructor({
    description,
    personId,
    addressFrom: "Gjesdalhallen, Gjesdal, Norway",
    addressTo: "Myrullveien 7, 4330 Ålgård, Norway",
    totalMeters: 4000,
    track
  });
  return ret;
};

// Generate a hardcoded mileage with a 4km track for testing/debugging purposes
export const getDummyTrackWithTollstations = (personId: number): Mileage => {
  const description = "Dummy track with tollstations " + Math.round(Math.random() * 100000);
  const segment = getDummyTrackSegmentWithTollstations();
  const track = {
    trackSegments: [segment]
  };
  const ret = mileageConstructor({
    description,
    personId,
    addressFrom: "Kyrkjebakken 27, 4330 Ålgård, Norway",
    addressTo: "Nygata 24, 4006 Stavanger, Norway",
    totalMeters: 30000,
    track
  });
  return ret;
};

const getDummyTrackSegment = (): MileageTrackSegment => {
  return {
    starts: "2020-11-19T08:57:07.865+01:00",
    stops: "2020-11-19T09:21:26.783+01:00",
    addressFrom: "Gjesdalhallen, Gjesdal, Norway",
    addressTo: "Myrullveien 7, 4330 Ålgård, Norway",
    coords: [
      { lat: "58.7728315634633", lon: "5.8654044517086" },
      { lat: "58.7728315634633", lon: "5.8654044517086" },
      { lat: "58.7728066", lon: "5.8653824" },
      { lat: "58.7727766676877", lon: "5.86535899421669" },
      { lat: "58.7726869", lon: "5.8652888" },
      { lat: "58.7725663", lon: "5.8652059" },
      { lat: "58.7724437", lon: "5.865134" },
      { lat: "58.7723963603568", lon: "5.86511108514353" },
      { lat: "58.7723963603568", lon: "5.86511108514353" },
      { lat: "58.7723183", lon: "5.8650733" },
      { lat: "58.7721926", lon: "5.8650213" },
      { lat: "58.7721857451993", lon: "5.86501925168087" },
      { lat: "58.7721857451993", lon: "5.86501925168087" },
      { lat: "58.7720801890245", lon: "5.86498770998721" },
      { lat: "58.7720661", lon: "5.8649835" },
      { lat: "58.7719385", lon: "5.8649547" },
      { lat: "58.7719154", lon: "5.8649469" },
      { lat: "58.7719154", lon: "5.8649469" },
      { lat: "58.7717687", lon: "5.86493" },
      { lat: "58.7714895", lon: "5.8649048" },
      { lat: "58.7713489", lon: "5.864909" },
      { lat: "58.7712497", lon: "5.864937" },
      { lat: "58.7711978", lon: "5.8649698" },
      { lat: "58.7711978", lon: "5.8649698" },
      { lat: "58.7710164", lon: "5.864997" },
      { lat: "58.7708825", lon: "5.8650203" },
      { lat: "58.770707127815", lon: "5.86505613808434" },
      { lat: "58.7706804", lon: "5.8650616" },
      { lat: "58.7704952", lon: "5.8651124" },
      { lat: "58.7703257", lon: "5.8651677" },
      { lat: "58.7699554", lon: "5.865297" },
      { lat: "58.7695339", lon: "5.8653651" },
      { lat: "58.7695339", lon: "5.8653651" },
      { lat: "58.7694363", lon: "5.8653266" },
      { lat: "58.7693552", lon: "5.8653046" },
      { lat: "58.7693552", lon: "5.8653046" },
      { lat: "58.769325780153", lon: "5.86530690411613" },
      { lat: "58.7692339", lon: "5.8653141" },
      { lat: "58.7690892", lon: "5.8653002" },
      { lat: "58.7689939", lon: "5.8653445" },
      { lat: "58.7689082", lon: "5.8654212" },
      { lat: "58.7688369", lon: "5.8655618" },
      { lat: "58.7687514", lon: "5.8657061" },
      { lat: "58.7686844", lon: "5.86583" },
      { lat: "58.7686463", lon: "5.8659166" },
      { lat: "58.7686463", lon: "5.8659166" },
      { lat: "58.7685324", lon: "5.865992" },
      { lat: "58.7684478", lon: "5.8660213" },
      { lat: "58.76829", lon: "5.8659883" },
      { lat: "58.7681435", lon: "5.8659349" },
      { lat: "58.7680364", lon: "5.865878" },
      { lat: "58.7679397316181", lon: "5.86581269786939" },
      { lat: "58.7679273", lon: "5.8658043" },
      { lat: "58.7678197", lon: "5.8656901" },
      { lat: "58.7678005", lon: "5.8655856" },
      { lat: "58.7678005", lon: "5.8655856" },
      { lat: "58.767703", lon: "5.8654723" },
      { lat: "58.767703", lon: "5.8654723" },
      { lat: "58.7676235", lon: "5.8651559" },
      { lat: "58.7675642", lon: "5.8650099" },
      { lat: "58.767473", lon: "5.8647192" },
      { lat: "58.767473", lon: "5.8647192" },
      { lat: "58.7674089", lon: "5.8644283" },
      { lat: "58.7674089", lon: "5.8644283" },
      { lat: "58.7673666", lon: "5.8641525" },
      { lat: "58.7673301", lon: "5.8639448" },
      { lat: "58.7672897", lon: "5.8636703" },
      { lat: "58.7672897", lon: "5.8636703" },
      { lat: "58.7672516882838", lon: "5.86346461769084" },
      { lat: "58.7672425", lon: "5.8634149" },
      { lat: "58.7671902", lon: "5.863074" },
      { lat: "58.7671902", lon: "5.863074" },
      { lat: "58.7671727", lon: "5.8629703" },
      { lat: "58.7671223", lon: "5.8628349" },
      { lat: "58.7671223", lon: "5.8628349" },
      { lat: "58.7670915", lon: "5.8627113" },
      { lat: "58.7670915", lon: "5.8627113" },
      { lat: "58.7670014", lon: "5.8625195" },
      { lat: "58.7669606", lon: "5.8624417" },
      { lat: "58.7669606", lon: "5.8624417" },
      { lat: "58.766964", lon: "5.8624006" },
      { lat: "58.7669622", lon: "5.8623616" },
      { lat: "58.7669622", lon: "5.8623616" },
      { lat: "58.766984", lon: "5.8622451" },
      { lat: "58.7669979", lon: "5.8621932" },
      { lat: "58.7669979", lon: "5.8621932" },
      { lat: "58.7670861", lon: "5.8619375" },
      { lat: "58.7671122", lon: "5.861863" },
      { lat: "58.7671887", lon: "5.8616852" },
      { lat: "58.7673252", lon: "5.8613315" },
      { lat: "58.767535330614", lon: "5.86079357410415" },
      { lat: "58.767647", lon: "5.8605077" },
      { lat: "58.7677145", lon: "5.8603443" },
      { lat: "58.7677145", lon: "5.8603443" },
      { lat: "58.7678249", lon: "5.8600405" },
      { lat: "58.7679588", lon: "5.8596852" },
      { lat: "58.7679588", lon: "5.8596852" },
      { lat: "58.7683601", lon: "5.8587031" },
      { lat: "58.7683601", lon: "5.8587031" },
      { lat: "58.76839", lon: "5.8586262" },
      { lat: "58.7684365", lon: "5.8585169" },
      { lat: "58.7685049", lon: "5.8583543" },
      { lat: "58.7685049", lon: "5.8583543" },
      { lat: "58.7686247", lon: "5.8580573" },
      { lat: "58.7686342477936", lon: "5.85803398939792" },
      { lat: "58.768805", lon: "5.8576171" },
      { lat: "58.7688781", lon: "5.8574426" },
      { lat: "58.768927", lon: "5.8573376" },
      { lat: "58.7689452", lon: "5.857309" },
      { lat: "58.7689801", lon: "5.8572404" },
      { lat: "58.7690178", lon: "5.8571591" },
      { lat: "58.7690178", lon: "5.8571591" },
      { lat: "58.7690413", lon: "5.8571653" },
      { lat: "58.7690647", lon: "5.857161" },
      { lat: "58.7690832", lon: "5.8571499" },
      { lat: "58.7690832", lon: "5.8571499" },
      { lat: "58.7692369", lon: "5.8573394" },
      { lat: "58.7692369", lon: "5.8573394" },
      { lat: "58.7692612", lon: "5.8573665" },
      { lat: "58.7692842", lon: "5.857399" },
      { lat: "58.7693755", lon: "5.8575024" },
      { lat: "58.7693755", lon: "5.8575024" },
      { lat: "58.7694235", lon: "5.8573721" },
      { lat: "58.7694235", lon: "5.8573721" },
      { lat: "58.7694475", lon: "5.8572653" },
      { lat: "58.7694492", lon: "5.8572358" },
      { lat: "58.769443", lon: "5.857203" },
      { lat: "58.7693571", lon: "5.857028" },
      { lat: "58.7693425", lon: "5.8569801" },
      { lat: "58.7693354", lon: "5.8569396" },
      { lat: "58.769339", lon: "5.8568372" },
      { lat: "58.7693625", lon: "5.8566968" },
      { lat: "58.7695324140255", lon: "5.85618608863074" },
      { lat: "58.7696073", lon: "5.855961" },
      { lat: "58.769640150437", lon: "5.85587743332827" },
      { lat: "58.7696306634519", lon: "5.85590156685092" },
      { lat: "58.7696073", lon: "5.855961" },
      { lat: "58.7694300494281", lon: "5.85649376769731" },
      { lat: "58.7693625", lon: "5.8566968" },
      { lat: "58.769339", lon: "5.8568372" },
      { lat: "58.7693354", lon: "5.8569396" },
      { lat: "58.7693425", lon: "5.8569801" },
      { lat: "58.7693571", lon: "5.857028" },
      { lat: "58.769443", lon: "5.857203" },
      { lat: "58.7694492", lon: "5.8572358" },
      { lat: "58.7694475", lon: "5.8572653" },
      { lat: "58.7694235", lon: "5.8573721" },
      { lat: "58.7694235", lon: "5.8573721" },
      { lat: "58.7693079", lon: "5.8572534" },
      { lat: "58.7692671", lon: "5.8572083" },
      { lat: "58.7692671", lon: "5.8572083" },
      { lat: "58.7691382", lon: "5.8570267" },
      { lat: "58.7691382", lon: "5.8570267" },
      { lat: "58.7691372", lon: "5.8569879" },
      { lat: "58.7691304", lon: "5.8569495" },
      { lat: "58.7691304", lon: "5.8569495" },
      { lat: "58.7691685", lon: "5.8567948" },
      { lat: "58.7692154", lon: "5.8566483" },
      { lat: "58.7693155", lon: "5.8563713" },
      { lat: "58.7694164", lon: "5.8560718" },
      { lat: "58.7694468", lon: "5.8559533" },
      { lat: "58.7694468", lon: "5.8559533" },
      { lat: "58.7695679", lon: "5.8555639" },
      { lat: "58.7696166", lon: "5.8554204" },
      { lat: "58.7696166", lon: "5.8554204" },
      { lat: "58.7697294", lon: "5.8550551" },
      { lat: "58.769793816221", lon: "5.8548311333764" },
      { lat: "58.7699112", lon: "5.854423" },
      { lat: "58.7700168", lon: "5.8540406" },
      { lat: "58.7700168", lon: "5.8540406" },
      { lat: "58.7701167", lon: "5.8536566" },
      { lat: "58.7701167", lon: "5.8536566" },
      { lat: "58.7702186", lon: "5.8532711" },
      { lat: "58.7702976", lon: "5.8529924" },
      { lat: "58.770440629114", lon: "5.85245442246543" },
      { lat: "58.7704698", lon: "5.8523447" },
      { lat: "58.7704698", lon: "5.8523447" },
      { lat: "58.7705951", lon: "5.8518728" },
      { lat: "58.7705951", lon: "5.8518728" },
      { lat: "58.7707703", lon: "5.8512055" },
      { lat: "58.7712599465467", lon: "5.84928936199082" },
      { lat: "58.7714132", lon: "5.8486896" },
      { lat: "58.7714132", lon: "5.8486896" },
      { lat: "58.7714373", lon: "5.8486061" },
      { lat: "58.771461", lon: "5.8485116" },
      { lat: "58.771461", lon: "5.8485116" },
      { lat: "58.7717144", lon: "5.8474688" },
      { lat: "58.7717144", lon: "5.8474688" },
      { lat: "58.771924", lon: "5.8469399" },
      { lat: "58.771924", lon: "5.8469399" },
      { lat: "58.771931", lon: "5.846935" },
      { lat: "58.7719522", lon: "5.8469137" },
      { lat: "58.7719708", lon: "5.8468845" },
      { lat: "58.7719858", lon: "5.8468487" },
      { lat: "58.7719967", lon: "5.8468076" },
      { lat: "58.7720038", lon: "5.8467517" },
      { lat: "58.772004", lon: "5.8467056" },
      { lat: "58.7719993", lon: "5.8466604" },
      { lat: "58.7719899", lon: "5.846618" },
      { lat: "58.7719762", lon: "5.8465802" },
      { lat: "58.7719762", lon: "5.8465802" },
      { lat: "58.7719595", lon: "5.8465488" },
      { lat: "58.7719401", lon: "5.8465249" },
      { lat: "58.7719182", lon: "5.8465097" },
      { lat: "58.7718954", lon: "5.8465036" },
      { lat: "58.7718836", lon: "5.8465041" },
      { lat: "58.7718836", lon: "5.8465041" },
      { lat: "58.7718773", lon: "5.8465034" },
      { lat: "58.7718533", lon: "5.8465084" },
      { lat: "58.7718314", lon: "5.8465253" },
      { lat: "58.7718314", lon: "5.8465253" },
      { lat: "58.7717309", lon: "5.8465407" },
      { lat: "58.7716377", lon: "5.8465588" },
      { lat: "58.7715866", lon: "5.8465769" },
      { lat: "58.7715146", lon: "5.8466138" },
      { lat: "58.7715146", lon: "5.8466138" },
      { lat: "58.7714213", lon: "5.8467742" },
      { lat: "58.7714213", lon: "5.8467742" },
      { lat: "58.7713294", lon: "5.8468069" },
      { lat: "58.7713294", lon: "5.8468069" },
      { lat: "58.7712245", lon: "5.84685" },
      { lat: "58.7712245", lon: "5.84685" },
      { lat: "58.7711919", lon: "5.8468263" },
      { lat: "58.7711263", lon: "5.8468319" },
      { lat: "58.7710303", lon: "5.8468161" },
      { lat: "58.7710303", lon: "5.8468161" },
      { lat: "58.7709761", lon: "5.8467678" },
      { lat: "58.7709439", lon: "5.8467194" },
      { lat: "58.7709439", lon: "5.8467194" },
      { lat: "58.7709319", lon: "5.8466777" },
      { lat: "58.7709167", lon: "5.8466475" },
      { lat: "58.7708979", lon: "5.846626" },
      { lat: "58.7708893", lon: "5.8466204" },
      { lat: "58.7708893", lon: "5.8466204" },
      { lat: "58.7708772", lon: "5.8464253" },
      { lat: "58.7708731", lon: "5.8463005" },
      { lat: "58.7708816", lon: "5.8461255" },
      { lat: "58.7709047572596", lon: "5.84593411119276" },
      { lat: "58.7709205", lon: "5.845804" },
      { lat: "58.7709254", lon: "5.8456721" },
      { lat: "58.7709258", lon: "5.8456006" },
      { lat: "58.7709258", lon: "5.8456006" },
      { lat: "58.7709244", lon: "5.8454889" },
      { lat: "58.7709082", lon: "5.845375" },
      { lat: "58.7709082", lon: "5.845375" },
      { lat: "58.7708647", lon: "5.8450716" },
      { lat: "58.7708003", lon: "5.8447513" },
      { lat: "58.7708003", lon: "5.8447513" },
      { lat: "58.7707012", lon: "5.8442767" },
      { lat: "58.770689", lon: "5.844188" },
      { lat: "58.77068", lon: "5.8441032" },
      { lat: "58.7706601", lon: "5.8439894" },
      { lat: "58.7706601", lon: "5.8439894" },
      { lat: "58.7706689", lon: "5.8439554" },
      { lat: "58.7706755", lon: "5.8439208" },
      { lat: "58.770677", lon: "5.8438793" },
      { lat: "58.7706737", lon: "5.8438434" },
      { lat: "58.7706737", lon: "5.8438434" },
      { lat: "58.770665", lon: "5.8438112" },
      { lat: "58.770652", lon: "5.8437782" },
      { lat: "58.7706337", lon: "5.8437502" },
      { lat: "58.7706337", lon: "5.8437502" },
      { lat: "58.7706156", lon: "5.843734" },
      { lat: "58.7705935", lon: "5.8437239" },
      { lat: "58.7705745", lon: "5.8437216" },
      { lat: "58.7705745", lon: "5.8437216" },
      { lat: "58.7705315", lon: "5.8435214" },
      { lat: "58.7705145", lon: "5.8434296" },
      { lat: "58.7705038875091", lon: "5.84338496793572" },
      { lat: "58.7704824", lon: "5.8432946" },
      { lat: "58.7704432", lon: "5.843197" },
      { lat: "58.7704432", lon: "5.843197" },
      { lat: "58.7702906", lon: "5.8424148" },
      { lat: "58.7702906", lon: "5.8424148" },
      { lat: "58.770171", lon: "5.8417542" },
      { lat: "58.770171", lon: "5.8417542" },
      { lat: "58.770115", lon: "5.841402" },
      { lat: "58.770115", lon: "5.841402" },
      { lat: "58.7700366", lon: "5.8408488" },
      { lat: "58.7700273354724", lon: "5.84074492041004" },
      { lat: "58.7699631", lon: "5.8400247" },
      { lat: "58.7699611", lon: "5.8392268" },
      { lat: "58.7699611", lon: "5.8392268" },
      { lat: "58.7699493", lon: "5.8383811" },
      { lat: "58.7698313", lon: "5.8375792" },
      { lat: "58.7697399561965", lon: "5.83710772513132" },
      { lat: "58.7696765", lon: "5.8367802" },
      { lat: "58.7696804", lon: "5.8361912" },
      { lat: "58.769758", lon: "5.8353705" },
      { lat: "58.7698064", lon: "5.8345836" },
      { lat: "58.7698064", lon: "5.8345836" },
      { lat: "58.7698102", lon: "5.8345356" },
      { lat: "58.7698125", lon: "5.8342775" },
      { lat: "58.7698125", lon: "5.8342775" },
      { lat: "58.7698173", lon: "5.8338779" },
      { lat: "58.7698173", lon: "5.8338779" },
      { lat: "58.7699108", lon: "5.8338844" },
      { lat: "58.7703236", lon: "5.8342113" },
      { lat: "58.7707582", lon: "5.834362" },
      { lat: "58.7709307419513", lon: "5.83438794109184" },
      { lat: "58.7710176", lon: "5.834401" },
      { lat: "58.7710176", lon: "5.834401" },
      { lat: "58.7711199", lon: "5.8344173" },
      { lat: "58.7715174", lon: "5.8341899" },
      { lat: "58.771849", lon: "5.8337609" },
      { lat: "58.7720702", lon: "5.8332551" },
      { lat: "58.7721123", lon: "5.8331482" },
      { lat: "58.7721123", lon: "5.8331482" },
      { lat: "58.7723323", lon: "5.8325748" },
      { lat: "58.7726412", lon: "5.8319935" },
      { lat: "58.7728375", lon: "5.831543" },
      { lat: "58.7728909", lon: "5.8311408" },
      { lat: "58.7728771", lon: "5.8309862" },
      { lat: "58.7728771", lon: "5.8309862" },
      { lat: "58.772853", lon: "5.8307264" },
      { lat: "58.772715", lon: "5.8303265" },
      { lat: "58.772715", lon: "5.8303265" },
      { lat: "58.7726113", lon: "5.8303036" },
      { lat: "58.7726113", lon: "5.8303036" },
      { lat: "58.772546", lon: "5.8302879" },
      { lat: "58.7722718", lon: "5.8304704" },
      { lat: "58.7721148", lon: "5.8306756" },
      { lat: "58.7720268", lon: "5.8307703" },
      { lat: "58.7720268", lon: "5.8307703" },
      { lat: "58.7719227", lon: "5.8308815" },
      { lat: "58.7716568", lon: "5.8311557" },
      { lat: "58.7716568", lon: "5.8311557" },
      { lat: "58.7715542", lon: "5.8312612" }
    ],
    totalMeters: 4000
  };
};

const getDummyTrackSegmentWithTollstations = (): MileageTrackSegment => {
  return {
    starts: "2020-11-19T08:57:07.865+01:00",
    stops: "2020-11-19T09:21:26.783+01:00",
    addressFrom: "Kyrkjebakken 27, 4330 Ålgård, Norway",
    addressTo: "Nygata 24, 4006 Stavanger, Norway",
    coords: [
      { lat: "58.7718662817286", lon: "5.83372138367572" },
      { lat: "58.7718662817286", lon: "5.83372138367572" },
      { lat: "58.7699611", lon: "5.8392268" },
      { lat: "58.7699611", lon: "5.8392268" },
      { lat: "58.7699611", lon: "5.8392268" },
      { lat: "58.7699631", lon: "5.8400247" },
      { lat: "58.7700366", lon: "5.8408488" },
      { lat: "58.770115", lon: "5.841402" },
      { lat: "58.770115", lon: "5.841402" },
      { lat: "58.770171", lon: "5.8417542" },
      { lat: "58.770171", lon: "5.8417542" },
      { lat: "58.7702906", lon: "5.8424148" },
      { lat: "58.7702906", lon: "5.8424148" },
      { lat: "58.7704432", lon: "5.843197" },
      { lat: "58.7704432", lon: "5.843197" },
      { lat: "58.7704501", lon: "5.8433187" },
      { lat: "58.7704782", lon: "5.8434713" },
      { lat: "58.7704901", lon: "5.8435481" },
      { lat: "58.7705114", lon: "5.8437321" },
      { lat: "58.7705172", lon: "5.8438009" },
      { lat: "58.7705172", lon: "5.8438009" },
      { lat: "58.7705055", lon: "5.8438559" },
      { lat: "58.7705022", lon: "5.8439022" },
      { lat: "58.7705031", lon: "5.8439349" },
      { lat: "58.7705031", lon: "5.8439349" },
      { lat: "58.77051", lon: "5.8439693" },
      { lat: "58.7705218", lon: "5.8440012" },
      { lat: "58.7705328", lon: "5.8440228" },
      { lat: "58.7705498", lon: "5.8440425" },
      { lat: "58.7705498", lon: "5.8440425" },
      { lat: "58.7705646", lon: "5.8440496" },
      { lat: "58.7705881", lon: "5.8440523" },
      { lat: "58.7706079", lon: "5.8440476" },
      { lat: "58.7706079", lon: "5.8440476" },
      { lat: "58.7706231", lon: "5.8441486" },
      { lat: "58.7706488", lon: "5.8442856" },
      { lat: "58.7706669", lon: "5.8443724" },
      { lat: "58.7707168", lon: "5.8445892" },
      { lat: "58.770755", lon: "5.8447877" },
      { lat: "58.770755", lon: "5.8447877" },
      { lat: "58.7708234", lon: "5.8451164" },
      { lat: "58.7708501", lon: "5.8452643" },
      { lat: "58.7708653", lon: "5.8453905" },
      { lat: "58.7708653", lon: "5.8453905" },
      { lat: "58.7708754", lon: "5.845514" },
      { lat: "58.7708782", lon: "5.8456125" },
      { lat: "58.7708782", lon: "5.8456125" },
      { lat: "58.7708758", lon: "5.8457985" },
      { lat: "58.7708265", lon: "5.8463026" },
      { lat: "58.7708133", lon: "5.846473" },
      { lat: "58.7708138", lon: "5.8466457" },
      { lat: "58.7708138", lon: "5.8466457" },
      { lat: "58.7708138", lon: "5.8466457" },
      { lat: "58.7707959", lon: "5.8466824" },
      { lat: "58.7707859", lon: "5.8467199" },
      { lat: "58.7707813", lon: "5.846761" },
      { lat: "58.7707818", lon: "5.8467975" },
      { lat: "58.7707818", lon: "5.8467975" },
      { lat: "58.770788", lon: "5.8468392" },
      { lat: "58.7707992", lon: "5.8468752" },
      { lat: "58.7708149", lon: "5.8469045" },
      { lat: "58.770834", lon: "5.8469249" },
      { lat: "58.7708552", lon: "5.8469352" },
      { lat: "58.7708592", lon: "5.8469358" },
      { lat: "58.7708592", lon: "5.8469358" },
      { lat: "58.7708831", lon: "5.8469323" },
      { lat: "58.7709036", lon: "5.8469179" },
      { lat: "58.7709215", lon: "5.8468937" },
      { lat: "58.7709271", lon: "5.8468826" },
      { lat: "58.7709271", lon: "5.8468826" },
      { lat: "58.7709521", lon: "5.8469019" },
      { lat: "58.7710187", lon: "5.84692" },
      { lat: "58.7710187", lon: "5.84692" },
      { lat: "58.7710848", lon: "5.846919" },
      { lat: "58.7711435", lon: "5.8469119" },
      { lat: "58.7712", lon: "5.8468871" },
      { lat: "58.7712245", lon: "5.84685" },
      { lat: "58.7712245", lon: "5.84685" },
      { lat: "58.7713294", lon: "5.8468069" },
      { lat: "58.7713294", lon: "5.8468069" },
      { lat: "58.7714213", lon: "5.8467742" },
      { lat: "58.7714213", lon: "5.8467742" },
      { lat: "58.7715146", lon: "5.8466138" },
      { lat: "58.7715146", lon: "5.8466138" },
      { lat: "58.7715859", lon: "5.8466791" },
      { lat: "58.7716422", lon: "5.8467044" },
      { lat: "58.771702", lon: "5.8467435" },
      { lat: "58.7717636", lon: "5.8467987" },
      { lat: "58.7717636", lon: "5.8467987" },
      { lat: "58.7717727", lon: "5.8468462" },
      { lat: "58.7717868", lon: "5.8468889" },
      { lat: "58.7717926", lon: "5.846902" },
      { lat: "58.7717926", lon: "5.846902" },
      { lat: "58.7718064", lon: "5.8469256" },
      { lat: "58.7718274", lon: "5.8469493" },
      { lat: "58.771851", lon: "5.8469634" },
      { lat: "58.7718756", lon: "5.8469664" },
      { lat: "58.7719004", lon: "5.8469584" },
      { lat: "58.771924", lon: "5.8469399" },
      { lat: "58.771924", lon: "5.8469399" },
      { lat: "58.771931", lon: "5.846935" },
      { lat: "58.7719522", lon: "5.8469137" },
      { lat: "58.7719708", lon: "5.8468845" },
      { lat: "58.7719858", lon: "5.8468487" },
      { lat: "58.7719967", lon: "5.8468076" },
      { lat: "58.7720038", lon: "5.8467517" },
      { lat: "58.772004", lon: "5.8467056" },
      { lat: "58.7719993", lon: "5.8466604" },
      { lat: "58.7719899", lon: "5.846618" },
      { lat: "58.7719762", lon: "5.8465802" },
      { lat: "58.7719762", lon: "5.8465802" },
      { lat: "58.7720796", lon: "5.8461341" },
      { lat: "58.7720961", lon: "5.8460021" },
      { lat: "58.7720961", lon: "5.8460021" },
      { lat: "58.7721426", lon: "5.8458441" },
      { lat: "58.7721426", lon: "5.8458441" },
      { lat: "58.7722055", lon: "5.845629" },
      { lat: "58.7723168", lon: "5.845292" },
      { lat: "58.772412", lon: "5.8449909" },
      { lat: "58.772412", lon: "5.8449909" },
      { lat: "58.7724939", lon: "5.844751" },
      { lat: "58.7724939", lon: "5.844751" },
      { lat: "58.7725473", lon: "5.8446048" },
      { lat: "58.7726040429406", lon: "5.84446638716981" },
      { lat: "58.7726857", lon: "5.8442672" },
      { lat: "58.7727962317822", lon: "5.84401214782031" },
      { lat: "58.772807", lon: "5.8439873" },
      { lat: "58.7729285", lon: "5.843723" },
      { lat: "58.7730325", lon: "5.843508" },
      { lat: "58.7732088", lon: "5.8431732" },
      { lat: "58.7733487", lon: "5.8429333" },
      { lat: "58.7734900450883", lon: "5.84270456057972" },
      { lat: "58.7734962", lon: "5.8426946" },
      { lat: "58.7736459", lon: "5.8424702" },
      { lat: "58.7736459", lon: "5.8424702" },
      { lat: "58.7737603", lon: "5.8423509" },
      { lat: "58.7739041", lon: "5.842156" },
      { lat: "58.7740598", lon: "5.8419653" },
      { lat: "58.7740598", lon: "5.8419653" },
      { lat: "58.774301", lon: "5.8416466" },
      { lat: "58.774301", lon: "5.8416466" },
      { lat: "58.7743846", lon: "5.8415542" },
      { lat: "58.7743846", lon: "5.8415542" },
      { lat: "58.7744551293725", lon: "5.84146780160677" },
      { lat: "58.7744846", lon: "5.8414317" },
      { lat: "58.7744846", lon: "5.8414317" },
      { lat: "58.774976", lon: "5.8408072" },
      { lat: "58.774976", lon: "5.8408072" },
      { lat: "58.7750781", lon: "5.8406763" },
      { lat: "58.7752107", lon: "5.8405164" },
      { lat: "58.7753274", lon: "5.8403808" },
      { lat: "58.7753861", lon: "5.8402809" },
      { lat: "58.7753861", lon: "5.8402809" },
      { lat: "58.7754731822543", lon: "5.84019347195556" },
      { lat: "58.7757379", lon: "5.8399277" },
      { lat: "58.7761123", lon: "5.8395584" },
      { lat: "58.7763150890635", lon: "5.83934906714942" },
      { lat: "58.7764934", lon: "5.839165" },
      { lat: "58.7768851", lon: "5.8387721" },
      { lat: "58.7768851", lon: "5.8387721" },
      { lat: "58.7774321224826", lon: "5.83822142151424" },
      { lat: "58.7778259", lon: "5.837825" },
      { lat: "58.7778259", lon: "5.837825" },
      { lat: "58.7780971", lon: "5.837574" },
      { lat: "58.7780971", lon: "5.837574" },
      { lat: "58.778211", lon: "5.837463" },
      { lat: "58.778211", lon: "5.837463" },
      { lat: "58.7782325721718", lon: "5.83744059278548" },
      { lat: "58.7784228", lon: "5.837243" },
      { lat: "58.7784228", lon: "5.837243" },
      { lat: "58.778581", lon: "5.837092" },
      { lat: "58.7788048", lon: "5.8368601" },
      { lat: "58.7789776", lon: "5.8366654" },
      { lat: "58.7790952680108", lon: "5.83652624431062" },
      { lat: "58.7791939", lon: "5.8364096" },
      { lat: "58.7792849", lon: "5.8362981" },
      { lat: "58.7792849", lon: "5.8362981" },
      { lat: "58.7794281", lon: "5.836088" },
      { lat: "58.7796297", lon: "5.8357833" },
      { lat: "58.7798301", lon: "5.8354592" },
      { lat: "58.7800056", lon: "5.8351612" },
      { lat: "58.7800140178717", lon: "5.83514511109165" },
      { lat: "58.780142", lon: "5.8349005" },
      { lat: "58.780142", lon: "5.8349005" },
      { lat: "58.7801993", lon: "5.8347835" },
      { lat: "58.7801993", lon: "5.8347835" },
      { lat: "58.7804103", lon: "5.8343237" },
      { lat: "58.7804103", lon: "5.8343237" },
      { lat: "58.7805896", lon: "5.833876" },
      { lat: "58.7805896", lon: "5.833876" },
      { lat: "58.7807174", lon: "5.8335501" },
      { lat: "58.7807806", lon: "5.8333568" },
      { lat: "58.7807806", lon: "5.8333568" },
      { lat: "58.7807945340966", lon: "5.83331859974354" },
      { lat: "58.7810197", lon: "5.8327013" },
      { lat: "58.7810197", lon: "5.8327013" },
      { lat: "58.7810817", lon: "5.832534" },
      { lat: "58.7811435", lon: "5.8323767" },
      { lat: "58.7811435", lon: "5.8323767" },
      { lat: "58.7812745", lon: "5.8320276" },
      { lat: "58.781416", lon: "5.8316658" },
      { lat: "58.781416", lon: "5.8316658" },
      { lat: "58.7815366875504", lon: "5.83134297456302" },
      { lat: "58.7816104", lon: "5.8311458" },
      { lat: "58.7816104", lon: "5.8311458" },
      { lat: "58.7817953", lon: "5.8306638" },
      { lat: "58.7817953", lon: "5.8306638" },
      { lat: "58.7819125", lon: "5.8303502" },
      { lat: "58.7819696", lon: "5.8302052" },
      { lat: "58.7820149", lon: "5.8300807" },
      { lat: "58.7820149", lon: "5.8300807" },
      { lat: "58.7820574", lon: "5.8299663" },
      { lat: "58.7820574", lon: "5.8299663" },
      { lat: "58.7820948", lon: "5.8298657" },
      { lat: "58.7820948", lon: "5.8298657" },
      { lat: "58.7821563", lon: "5.829691" },
      { lat: "58.7821944", lon: "5.8295778" },
      { lat: "58.7821944", lon: "5.8295778" },
      { lat: "58.7822710305618", lon: "5.82936652292053" },
      { lat: "58.7823072", lon: "5.8292668" },
      { lat: "58.7823964", lon: "5.8290115" },
      { lat: "58.7824844", lon: "5.828747" },
      { lat: "58.7824844", lon: "5.828747" },
      { lat: "58.7827778", lon: "5.8279227" },
      { lat: "58.7829589806563", lon: "5.82740684367788" },
      { lat: "58.7830337", lon: "5.8271941" },
      { lat: "58.7831015", lon: "5.8270065" },
      { lat: "58.7831653", lon: "5.8268229" },
      { lat: "58.7832305", lon: "5.8266454" },
      { lat: "58.7832998", lon: "5.8264414" },
      { lat: "58.7836168886226", lon: "5.82554894556718" },
      { lat: "58.7836384", lon: "5.8254884" },
      { lat: "58.7837624", lon: "5.825136" },
      { lat: "58.7838184", lon: "5.8249812" },
      { lat: "58.7838857", lon: "5.8247846" },
      { lat: "58.7838857", lon: "5.8247846" },
      { lat: "58.7839113", lon: "5.8247245" },
      { lat: "58.7839319", lon: "5.8246706" },
      { lat: "58.7839319", lon: "5.8246706" },
      { lat: "58.7841204", lon: "5.8241444" },
      { lat: "58.7841702", lon: "5.823999" },
      { lat: "58.7843079540583", lon: "5.82361545508241" },
      { lat: "58.7844785", lon: "5.8231406" },
      { lat: "58.7846654", lon: "5.8226111" },
      { lat: "58.7849886", lon: "5.8217096" },
      { lat: "58.7850356068757", lon: "5.8215760333986" },
      { lat: "58.7850668", lon: "5.8214874" },
      { lat: "58.7851378", lon: "5.8212944" },
      { lat: "58.7854182", lon: "5.8205102" },
      { lat: "58.7855458", lon: "5.8201585" },
      { lat: "58.7857277", lon: "5.8196492" },
      { lat: "58.7857532237397", lon: "5.81958310672844" },
      { lat: "58.7857981", lon: "5.8194669" },
      { lat: "58.7858646", lon: "5.819284" },
      { lat: "58.7859587", lon: "5.8190589" },
      { lat: "58.7860887", lon: "5.8187688" },
      { lat: "58.786162", lon: "5.8186141" },
      { lat: "58.7862396", lon: "5.8184594" },
      { lat: "58.7863167", lon: "5.8183134" },
      { lat: "58.7864673", lon: "5.8180426" },
      { lat: "58.7865445", lon: "5.8179074" },
      { lat: "58.786589605115", lon: "5.81783302898255" },
      { lat: "58.7867222", lon: "5.8176144" },
      { lat: "58.7868726", lon: "5.8173748" },
      { lat: "58.7869452", lon: "5.8172547" },
      { lat: "58.7870992", lon: "5.8170121" },
      { lat: "58.7872442", lon: "5.8167771" },
      { lat: "58.7873861", lon: "5.8165571" },
      { lat: "58.787508136057", lon: "5.81635727875333" },
      { lat: "58.787631", lon: "5.8161561" },
      { lat: "58.7877568", lon: "5.8159264" },
      { lat: "58.7878259", lon: "5.8157851" },
      { lat: "58.7878259", lon: "5.8157851" },
      { lat: "58.7878837", lon: "5.8156534" },
      { lat: "58.7879523", lon: "5.8154835" },
      { lat: "58.7879523", lon: "5.8154835" },
      { lat: "58.7880286", lon: "5.8152814" },
      { lat: "58.788073", lon: "5.8151553" },
      { lat: "58.7881609", lon: "5.8148896" },
      { lat: "58.7881896", lon: "5.8147878" },
      { lat: "58.7882483534916", lon: "5.81456196649285" },
      { lat: "58.78826", lon: "5.8145172" },
      { lat: "58.7882909", lon: "5.8143884" },
      { lat: "58.7883173", lon: "5.8142525" },
      { lat: "58.7883566", lon: "5.8140731" },
      { lat: "58.7884054", lon: "5.8138101" },
      { lat: "58.7884714", lon: "5.8133501" },
      { lat: "58.788581544223", lon: "5.81251687811274" },
      { lat: "58.7887363", lon: "5.8113461" },
      { lat: "58.7888409", lon: "5.8105767" },
      { lat: "58.78886638531", lon: "5.8103961079858" },
      { lat: "58.7889024", lon: "5.8101409" },
      { lat: "58.7889635", lon: "5.8097629" },
      { lat: "58.7890307", lon: "5.8094069" },
      { lat: "58.7891043", lon: "5.8090503" },
      { lat: "58.7891749", lon: "5.8087436" },
      { lat: "58.7892482", lon: "5.8084479" },
      { lat: "58.7893173623043", lon: "5.80819949629306" },
      { lat: "58.7893388", lon: "5.8081225" },
      { lat: "58.7894583", lon: "5.80772" },
      { lat: "58.7895825", lon: "5.8073584" },
      { lat: "58.7895825", lon: "5.8073584" },
      { lat: "58.78968", lon: "5.8071004" },
      { lat: "58.7899840820191", lon: "5.80625891115034" },
      { lat: "58.7902104", lon: "5.8056326" },
      { lat: "58.7902104", lon: "5.8056326" },
      { lat: "58.790736573865", lon: "5.80439771533015" },
      { lat: "58.7907601", lon: "5.8043425" },
      { lat: "58.7907601", lon: "5.8043425" },
      { lat: "58.7910066", lon: "5.8038004" },
      { lat: "58.7910066", lon: "5.8038004" },
      { lat: "58.7913641", lon: "5.8030814" },
      { lat: "58.7913641", lon: "5.8030814" },
      { lat: "58.7914689", lon: "5.8028775" },
      { lat: "58.7914689", lon: "5.8028775" },
      { lat: "58.7915612564947", lon: "5.80269722854918" },
      { lat: "58.7916748", lon: "5.8024756" },
      { lat: "58.7916748", lon: "5.8024756" },
      { lat: "58.7917784", lon: "5.8022905" },
      { lat: "58.7917784", lon: "5.8022905" },
      { lat: "58.7919465", lon: "5.8020032" },
      { lat: "58.7920734", lon: "5.8017713" },
      { lat: "58.7920734", lon: "5.8017713" },
      { lat: "58.7924704824781", lon: "5.80106354852036" },
      { lat: "58.7933059406749", lon: "5.79957436992419" },
      { lat: "58.7933195", lon: "5.7995502" },
      { lat: "58.7936278", lon: "5.798959" },
      { lat: "58.7940315", lon: "5.7982453" },
      { lat: "58.7940464", lon: "5.7982163" },
      { lat: "58.7940464", lon: "5.7982163" },
      { lat: "58.79412552372", lon: "5.79806380117219" },
      { lat: "58.7942047", lon: "5.7979112" },
      { lat: "58.7942047", lon: "5.7979112" },
      { lat: "58.7943489", lon: "5.7976377" },
      { lat: "58.7943489", lon: "5.7976377" },
      { lat: "58.7944345", lon: "5.7974761" },
      { lat: "58.7948757", lon: "5.7967075" },
      { lat: "58.7948757", lon: "5.7967075" },
      { lat: "58.7949639", lon: "5.7965918" },
      { lat: "58.7950137520009", lon: "5.79650239151015" },
      { lat: "58.7951605", lon: "5.7962392" },
      { lat: "58.7953398", lon: "5.7958888" },
      { lat: "58.7954879", lon: "5.795552" },
      { lat: "58.7956043", lon: "5.7952451" },
      { lat: "58.7957014", lon: "5.7949619" },
      { lat: "58.7957721687362", lon: "5.7947169248759" },
      { lat: "58.7957835", lon: "5.7946777" },
      { lat: "58.7958583", lon: "5.7943858" },
      { lat: "58.7958984", lon: "5.7942027" },
      { lat: "58.79593", lon: "5.7940479" },
      { lat: "58.7959696", lon: "5.7938261" },
      { lat: "58.7960194", lon: "5.7935048" },
      { lat: "58.7960649", lon: "5.7931228" },
      { lat: "58.79609", lon: "5.7928223" },
      { lat: "58.7961002673179", lon: "5.79264187091662" },
      { lat: "58.7961118", lon: "5.7924392" },
      { lat: "58.7961179", lon: "5.792188" },
      { lat: "58.7961179", lon: "5.792188" },
      { lat: "58.7961161", lon: "5.7917375" },
      { lat: "58.7960954", lon: "5.7910909" },
      { lat: "58.7960834", lon: "5.7909785" },
      { lat: "58.7960834", lon: "5.7909785" },
      { lat: "58.7960521", lon: "5.7905121" },
      { lat: "58.7960423822022", lon: "5.7903496435243" },
      { lat: "58.796027", lon: "5.7900925" },
      { lat: "58.7960112", lon: "5.7897587" },
      { lat: "58.7960112", lon: "5.7897587" },
      { lat: "58.7960031", lon: "5.7895665" },
      { lat: "58.7959989", lon: "5.7891413" },
      { lat: "58.7960014", lon: "5.7888978" },
      { lat: "58.7960076", lon: "5.7886938" },
      { lat: "58.796014029793", lon: "5.78856808908951" },
      { lat: "58.7960279", lon: "5.7882969" },
      { lat: "58.796043", lon: "5.7880973" },
      { lat: "58.7960624", lon: "5.7878965" },
      { lat: "58.7960624", lon: "5.7878965" },
      { lat: "58.7960854", lon: "5.7876652" },
      { lat: "58.7961113", lon: "5.7874559" },
      { lat: "58.7961366", lon: "5.787272" },
      { lat: "58.7961847", lon: "5.786976" },
      { lat: "58.7962164", lon: "5.786796" },
      { lat: "58.7962564", lon: "5.7865854" },
      { lat: "58.7962564", lon: "5.7865854" },
      { lat: "58.7963131841933", lon: "5.78631645517972" },
      { lat: "58.7963402", lon: "5.7861885" },
      { lat: "58.7964258", lon: "5.7858605" },
      { lat: "58.7964258", lon: "5.7858605" },
      { lat: "58.7965205", lon: "5.7855237" },
      { lat: "58.7966536", lon: "5.78512" },
      { lat: "58.7967145", lon: "5.7849613" },
      { lat: "58.7967754", lon: "5.7848095" },
      { lat: "58.7968469", lon: "5.7846398" },
      { lat: "58.7969088", lon: "5.7844987" },
      { lat: "58.7969561", lon: "5.7843982" },
      { lat: "58.7969561", lon: "5.7843982" },
      { lat: "58.7970499711774", lon: "5.78420634222401" },
      { lat: "58.7970519", lon: "5.7842024" },
      { lat: "58.7971224", lon: "5.7840699" },
      { lat: "58.7972685", lon: "5.783815" },
      { lat: "58.797352", lon: "5.783676" },
      { lat: "58.7974258", lon: "5.7835646" },
      { lat: "58.7974952", lon: "5.7834663" },
      { lat: "58.7976875", lon: "5.7832094" },
      { lat: "58.7977719", lon: "5.7831111" },
      { lat: "58.7978678", lon: "5.7830041" },
      { lat: "58.7979521283797", lon: "5.78291473852079" },
      { lat: "58.7979566", lon: "5.78291" },
      { lat: "58.798051", lon: "5.7828154" },
      { lat: "58.7981413", lon: "5.7827302" },
      { lat: "58.798326", lon: "5.7825759" },
      { lat: "58.7985019", lon: "5.7824504" },
      { lat: "58.7987361", lon: "5.7823083" },
      { lat: "58.7988892", lon: "5.7822237" },
      { lat: "58.7990056", lon: "5.7821704" },
      { lat: "58.7990422464437", lon: "5.78215528657991" },
      { lat: "58.7991055", lon: "5.7821292" },
      { lat: "58.7992093", lon: "5.7820931" },
      { lat: "58.799439", lon: "5.782018" },
      { lat: "58.799439", lon: "5.782018" },
      { lat: "58.8001742", lon: "5.7818213" },
      { lat: "58.8001827582816", lon: "5.78181886365454" },
      { lat: "58.8003811", lon: "5.7817624" },
      { lat: "58.8003811", lon: "5.7817624" },
      { lat: "58.8007412", lon: "5.781665" },
      { lat: "58.8007412", lon: "5.781665" },
      { lat: "58.8008447", lon: "5.7816635" },
      { lat: "58.8013747167958", lon: "5.7815576283674" },
      { lat: "58.8020717", lon: "5.7814184" },
      { lat: "58.8020717", lon: "5.7814184" },
      { lat: "58.8021558", lon: "5.781392" },
      { lat: "58.8021558", lon: "5.781392" },
      { lat: "58.8022814", lon: "5.7813621" },
      { lat: "58.8022814", lon: "5.7813621" },
      { lat: "58.802295176669", lon: "5.78135805650855" },
      { lat: "58.8026371", lon: "5.7812577" },
      { lat: "58.8026371", lon: "5.7812577" },
      { lat: "58.8030418", lon: "5.7811227" },
      { lat: "58.8034872389445", lon: "5.78096550263606" },
      { lat: "58.8037281", lon: "5.7808805" },
      { lat: "58.8038195", lon: "5.7808252" },
      { lat: "58.8038195", lon: "5.7808252" },
      { lat: "58.8044484", lon: "5.780657" },
      { lat: "58.8044755182388", lon: "5.78064935729216" },
      { lat: "58.8051222", lon: "5.7804671" },
      { lat: "58.8052305", lon: "5.7804287" },
      { lat: "58.8053294", lon: "5.7803894" },
      { lat: "58.8054185", lon: "5.7803467" },
      { lat: "58.8056203", lon: "5.780238" },
      { lat: "58.8056498097413", lon: "5.78021873829882" },
      { lat: "58.8058403", lon: "5.7800944" },
      { lat: "58.8059416", lon: "5.7800206" },
      { lat: "58.806058", lon: "5.7799308" },
      { lat: "58.8062625", lon: "5.7797478" },
      { lat: "58.8064679", lon: "5.7795386" },
      { lat: "58.8065832", lon: "5.7794088" },
      { lat: "58.806689", lon: "5.779285" },
      { lat: "58.8067799", lon: "5.7791673" },
      { lat: "58.8068903", lon: "5.7790196" },
      { lat: "58.8069313101302", lon: "5.7789570694671" },
      { lat: "58.8069953", lon: "5.7788595" },
      { lat: "58.8071573", lon: "5.7786008" },
      { lat: "58.8072607", lon: "5.7784186" },
      { lat: "58.8074306", lon: "5.7780935" },
      { lat: "58.8075081", lon: "5.7779286" },
      { lat: "58.8075749", lon: "5.777777" },
      { lat: "58.8076582", lon: "5.7775793" },
      { lat: "58.8077174445373", lon: "5.7774312231159" },
      { lat: "58.8077447", lon: "5.7773631" },
      { lat: "58.8079915", lon: "5.7767271" },
      { lat: "58.8084518519774", lon: "5.77552654919026" },
      { lat: "58.808834", lon: "5.7745299" },
      { lat: "58.8090278785326", lon: "5.77402620171779" },
      { lat: "58.8091482", lon: "5.7737136" },
      { lat: "58.8092979", lon: "5.7733346" },
      { lat: "58.8092979", lon: "5.7733346" },
      { lat: "58.809351", lon: "5.7731932" },
      { lat: "58.8095074", lon: "5.7728187" },
      { lat: "58.809618", lon: "5.7725731" },
      { lat: "58.8096295408383", lon: "5.7725492073034" },
      { lat: "58.8097745", lon: "5.7722491" },
      { lat: "58.8098495", lon: "5.7720984" },
      { lat: "58.8100761", lon: "5.7716702" },
      { lat: "58.8101716", lon: "5.7715035" },
      { lat: "58.8102619", lon: "5.7713522" },
      { lat: "58.8103029520666", lon: "5.77128781635641" },
      { lat: "58.8103731", lon: "5.7711778" },
      { lat: "58.8104953", lon: "5.7709963" },
      { lat: "58.8106319", lon: "5.7708072" },
      { lat: "58.8107531", lon: "5.770658" },
      { lat: "58.8108755", lon: "5.7705137" },
      { lat: "58.810995", lon: "5.770379" },
      { lat: "58.8111121", lon: "5.7702605" },
      { lat: "58.8113015191132", lon: "5.77008162257486" },
      { lat: "58.8113439", lon: "5.7700416" },
      { lat: "58.8114627", lon: "5.7699349" },
      { lat: "58.812396251919", lon: "5.76913409071145" },
      { lat: "58.8127118", lon: "5.7688634" },
      { lat: "58.8127118", lon: "5.7688634" },
      { lat: "58.8133360936307", lon: "5.76832583596503" },
      { lat: "58.8133402", lon: "5.7683223" },
      { lat: "58.8141251", lon: "5.7676502" },
      { lat: "58.8141722927164", lon: "5.76760965246841" },
      { lat: "58.8147757", lon: "5.7670912" },
      { lat: "58.8147757", lon: "5.7670912" },
      { lat: "58.814821", lon: "5.7670796" },
      { lat: "58.8150055219556", lon: "5.76692454715017" },
      { lat: "58.8151034", lon: "5.7668423" },
      { lat: "58.8151034", lon: "5.7668423" },
      { lat: "58.8158477", lon: "5.7661924" },
      { lat: "58.8159099038325", lon: "5.76613985289309" },
      { lat: "58.8166633", lon: "5.7655034" },
      { lat: "58.8166633", lon: "5.7655034" },
      { lat: "58.8168021647764", lon: "5.76538801591093" },
      { lat: "58.8173133", lon: "5.7649633" },
      { lat: "58.8173133", lon: "5.7649633" },
      { lat: "58.8173995", lon: "5.7648866" },
      { lat: "58.8173995", lon: "5.7648866" },
      { lat: "58.8177029849704", lon: "5.76462478579777" },
      { lat: "58.8177418", lon: "5.7645913" },
      { lat: "58.8181875", lon: "5.764215" },
      { lat: "58.8184589", lon: "5.7639812" },
      { lat: "58.8187319", lon: "5.7637225" },
      { lat: "58.8187808539311", lon: "5.76367280950252" },
      { lat: "58.8188648", lon: "5.7635876" },
      { lat: "58.8189367", lon: "5.7635097" },
      { lat: "58.8191585", lon: "5.7632396" },
      { lat: "58.8192906", lon: "5.7630723" },
      { lat: "58.819429", lon: "5.7628775" },
      { lat: "58.8195617", lon: "5.7626768" },
      { lat: "58.8196961", lon: "5.762465" },
      { lat: "58.8197862", lon: "5.7623157" },
      { lat: "58.8197862", lon: "5.7623157" },
      { lat: "58.819798495335", lon: "5.76229386197773" },
      { lat: "58.8199144", lon: "5.762088" },
      { lat: "58.8200467", lon: "5.7618423" },
      { lat: "58.820166", lon: "5.7616081" },
      { lat: "58.8202764", lon: "5.7613595" },
      { lat: "58.8203931", lon: "5.7610898" },
      { lat: "58.8204902", lon: "5.7608504" },
      { lat: "58.8206008", lon: "5.7605618" },
      { lat: "58.8206009815006", lon: "5.76056128069392" },
      { lat: "58.820724", lon: "5.7602093" },
      { lat: "58.820724", lon: "5.7602093" },
      { lat: "58.8208432", lon: "5.759843" },
      { lat: "58.8208843", lon: "5.7597089" },
      { lat: "58.8209471", lon: "5.7594896" },
      { lat: "58.8209956", lon: "5.7593104" },
      { lat: "58.8210555", lon: "5.7590785" },
      { lat: "58.8211314", lon: "5.7587667" },
      { lat: "58.8211314", lon: "5.7587667" },
      { lat: "58.8212001375292", lon: "5.75843613373568" },
      { lat: "58.8213059", lon: "5.7579275" },
      { lat: "58.8213059", lon: "5.7579275" },
      { lat: "58.8214227", lon: "5.757249" },
      { lat: "58.8215113", lon: "5.7566701" },
      { lat: "58.8215863", lon: "5.7561407" },
      { lat: "58.8215869336656", lon: "5.7561357574898" },
      { lat: "58.8216493", lon: "5.7556493" },
      { lat: "58.8216493", lon: "5.7556493" },
      { lat: "58.8217494", lon: "5.7548437" },
      { lat: "58.8217494", lon: "5.7548437" },
      { lat: "58.8218875", lon: "5.7537155" },
      { lat: "58.8218875", lon: "5.7537155" },
      { lat: "58.8218891789631", lon: "5.75370311593379" },
      { lat: "58.8221795414247", lon: "5.75156124687076" },
      { lat: "58.8222104", lon: "5.7513336" },
      { lat: "58.8222104", lon: "5.7513336" },
      { lat: "58.8223196", lon: "5.7506062" },
      { lat: "58.8223196", lon: "5.7506062" },
      { lat: "58.8225080011189", lon: "5.7493663977412" },
      { lat: "58.8225266", lon: "5.749244" },
      { lat: "58.8225266", lon: "5.749244" },
      { lat: "58.8226741", lon: "5.7482895" },
      { lat: "58.8226741", lon: "5.7482895" },
      { lat: "58.8228091", lon: "5.7474635" },
      { lat: "58.8228091", lon: "5.7474635" },
      { lat: "58.8228607", lon: "5.7471474" },
      { lat: "58.8228607", lon: "5.7471474" },
      { lat: "58.8228610128827", lon: "5.74714548794954" },
      { lat: "58.8228922", lon: "5.7469549" },
      { lat: "58.8229668", lon: "5.7465408" },
      { lat: "58.8230153", lon: "5.746296" },
      { lat: "58.8230836", lon: "5.7459817" },
      { lat: "58.8231592", lon: "5.7456472" },
      { lat: "58.8232343", lon: "5.7453308" },
      { lat: "58.8233047", lon: "5.7450669" },
      { lat: "58.8233081292497", lon: "5.74505444080421" },
      { lat: "58.8234184", lon: "5.7446538" },
      { lat: "58.8235274", lon: "5.7443072" },
      { lat: "58.8236234", lon: "5.7440301" },
      { lat: "58.8237616", lon: "5.7436553" },
      { lat: "58.823891", lon: "5.7433309" },
      { lat: "58.823958021384", lon: "5.74317593451121" },
      { lat: "58.8240313", lon: "5.7430065" },
      { lat: "58.8241575", lon: "5.7427315" },
      { lat: "58.8242654", lon: "5.7425048" },
      { lat: "58.8244668", lon: "5.742129" },
      { lat: "58.8245825", lon: "5.7419265" },
      { lat: "58.8247025", lon: "5.741727" },
      { lat: "58.8247823776982", lon: "5.74160214802464" },
      { lat: "58.8248746", lon: "5.741458" },
      { lat: "58.8251995", lon: "5.7410177" },
      { lat: "58.8253023", lon: "5.7408897" },
      { lat: "58.8254556", lon: "5.7407114" },
      { lat: "58.8256689", lon: "5.7404857" },
      { lat: "58.825752416212", lon: "5.74040585027498" },
      { lat: "58.8258238", lon: "5.7403376" },
      { lat: "58.8260402", lon: "5.7401412" },
      { lat: "58.8264762", lon: "5.7397613" },
      { lat: "58.8267511", lon: "5.7395487" },
      { lat: "58.8268030074614", lon: "5.73950762307355" },
      { lat: "58.8271839", lon: "5.7392062" },
      { lat: "58.8276214", lon: "5.7388868" },
      { lat: "58.827872", lon: "5.7387142" },
      { lat: "58.827872", lon: "5.7387142" },
      { lat: "58.8279945145114", lon: "5.73862940679483" },
      { lat: "58.8282676", lon: "5.7384404" },
      { lat: "58.8282676", lon: "5.7384404" },
      { lat: "58.8287391", lon: "5.7381345" },
      { lat: "58.8287391", lon: "5.7381345" },
      { lat: "58.8291936428714", lon: "5.73786356998539" },
      { lat: "58.8292201", lon: "5.7378478" },
      { lat: "58.8295935", lon: "5.7376412" },
      { lat: "58.8298797", lon: "5.7374861" },
      { lat: "58.8301891", lon: "5.7373313" },
      { lat: "58.8301891", lon: "5.7373313" },
      { lat: "58.8302860582693", lon: "5.73729743144222" },
      { lat: "58.830502", lon: "5.737222" },
      { lat: "58.830502", lon: "5.737222" },
      { lat: "58.8308217", lon: "5.7371151" },
      { lat: "58.8312571", lon: "5.7370104" },
      { lat: "58.8313726", lon: "5.736992" },
      { lat: "58.8315370215693", lon: "5.73697002836481" },
      { lat: "58.831642", lon: "5.736956" },
      { lat: "58.8323899", lon: "5.7369011" },
      { lat: "58.8323899", lon: "5.7369011" },
      { lat: "58.8324905", lon: "5.7368964" },
      { lat: "58.8324905", lon: "5.7368964" },
      { lat: "58.8326173", lon: "5.7368908" },
      { lat: "58.8329126", lon: "5.7368515" },
      { lat: "58.8330514956005", lon: "5.7368298265679" },
      { lat: "58.8330773", lon: "5.7368258" },
      { lat: "58.8330773", lon: "5.7368258" },
      { lat: "58.8333314", lon: "5.7367743" },
      { lat: "58.8334725", lon: "5.7367345" },
      { lat: "58.8334725", lon: "5.7367345" },
      { lat: "58.8336583", lon: "5.7366808" },
      { lat: "58.8338736", lon: "5.7366103" },
      { lat: "58.8341436", lon: "5.7365406" },
      { lat: "58.8343139", lon: "5.7364485" },
      { lat: "58.8344425", lon: "5.7363887" },
      { lat: "58.8344425", lon: "5.7363887" },
      { lat: "58.8344743631236", lon: "5.7363732933541" },
      { lat: "58.8346307", lon: "5.7362977" },
      { lat: "58.8348387", lon: "5.7361754" },
      { lat: "58.8351226", lon: "5.7359991" },
      { lat: "58.8351226", lon: "5.7359991" },
      { lat: "58.8356347", lon: "5.7356235" },
      { lat: "58.8358091684092", lon: "5.73547103351648" },
      { lat: "58.8360431", lon: "5.7352666" },
      { lat: "58.8365461", lon: "5.7347763" },
      { lat: "58.8369323", lon: "5.7343116" },
      { lat: "58.8370169833984", lon: "5.73419110497718" },
      { lat: "58.8371437", lon: "5.7340108" },
      { lat: "58.8371975", lon: "5.7339526" },
      { lat: "58.8371975", lon: "5.7339526" },
      { lat: "58.8373123", lon: "5.7337967" },
      { lat: "58.8373123", lon: "5.7337967" },
      { lat: "58.8373483", lon: "5.7337375" },
      { lat: "58.8374554", lon: "5.7335811" },
      { lat: "58.837598", lon: "5.7333361" },
      { lat: "58.837598", lon: "5.7333361" },
      { lat: "58.837854", lon: "5.7329066" },
      { lat: "58.837854", lon: "5.7329066" },
      { lat: "58.8379539263024", lon: "5.73272891513698" },
      { lat: "58.8381087", lon: "5.7324537" },
      { lat: "58.8381087", lon: "5.7324537" },
      { lat: "58.8382009", lon: "5.7322442" },
      { lat: "58.8382009", lon: "5.7322442" },
      { lat: "58.838297", lon: "5.7320455" },
      { lat: "58.8384514", lon: "5.7316933" },
      { lat: "58.8386661", lon: "5.7311719" },
      { lat: "58.8387516", lon: "5.730951" },
      { lat: "58.8388691804279", lon: "5.73061330040109" },
      { lat: "58.8388712", lon: "5.7306075" },
      { lat: "58.8388712", lon: "5.7306075" },
      { lat: "58.8389472", lon: "5.7304055" },
      { lat: "58.8390576", lon: "5.7301175" },
      { lat: "58.8390576", lon: "5.7301175" },
      { lat: "58.8392105", lon: "5.7296079" },
      { lat: "58.8392814", lon: "5.7293316" },
      { lat: "58.8392814", lon: "5.7293316" },
      { lat: "58.8394729", lon: "5.7286645" },
      { lat: "58.8394729", lon: "5.7286645" },
      { lat: "58.8396070672846", lon: "5.72819824891016" },
      { lat: "58.8396823", lon: "5.7279368" },
      { lat: "58.8396823", lon: "5.7279368" },
      { lat: "58.8399092", lon: "5.7271299" },
      { lat: "58.8402097", lon: "5.7260952" },
      { lat: "58.8403275644493", lon: "5.72571524095464" },
      { lat: "58.8403818", lon: "5.7255404" },
      { lat: "58.8405382", lon: "5.7251749" },
      { lat: "58.8405382", lon: "5.7251749" },
      { lat: "58.8407732", lon: "5.7245089" },
      { lat: "58.8407732", lon: "5.7245089" },
      { lat: "58.8410439", lon: "5.7237917" },
      { lat: "58.8410439", lon: "5.7237917" },
      { lat: "58.8411218", lon: "5.7235868" },
      { lat: "58.8411655328255", lon: "5.72346479472758" },
      { lat: "58.8412041", lon: "5.7233572" },
      { lat: "58.8413822", lon: "5.7229128" },
      { lat: "58.8414864", lon: "5.7226717" },
      { lat: "58.8416011", lon: "5.722438" },
      { lat: "58.8416993", lon: "5.7222228" },
      { lat: "58.8418156", lon: "5.7219886" },
      { lat: "58.8420496", lon: "5.7215506" },
      { lat: "58.8421685546709", lon: "5.72135283453651" },
      { lat: "58.8422674", lon: "5.7211885" },
      { lat: "58.8424612", lon: "5.7209155" },
      { lat: "58.8424612", lon: "5.7209155" },
      { lat: "58.8424688", lon: "5.720901" },
      { lat: "58.8427068", lon: "5.7205965" },
      { lat: "58.8427733", lon: "5.720521" },
      { lat: "58.8428133", lon: "5.7204801" },
      { lat: "58.842907", lon: "5.7203767" },
      { lat: "58.8430727", lon: "5.720188" },
      { lat: "58.8431852", lon: "5.7200674" },
      { lat: "58.8432878", lon: "5.7199662" },
      { lat: "58.8432878", lon: "5.7199662" },
      { lat: "58.8433639287696", lon: "5.71989385979733" },
      { lat: "58.8434767", lon: "5.7197867" },
      { lat: "58.8436345", lon: "5.7196503" },
      { lat: "58.8438142", lon: "5.719503" },
      { lat: "58.8439825", lon: "5.7193724" },
      { lat: "58.8441404", lon: "5.7192424" },
      { lat: "58.8443418", lon: "5.7190914" },
      { lat: "58.8445355", lon: "5.7189306" },
      { lat: "58.8446235", lon: "5.7188755" },
      { lat: "58.8446664240021", lon: "5.71884235429015" },
      { lat: "58.8447091", lon: "5.7188094" },
      { lat: "58.8450792", lon: "5.7185085" },
      { lat: "58.8450792", lon: "5.7185085" },
      { lat: "58.8455049", lon: "5.7181645" },
      { lat: "58.8457402", lon: "5.7179791" },
      { lat: "58.8460022411705", lon: "5.71776328707478" },
      { lat: "58.8464505", lon: "5.7173941" },
      { lat: "58.8468078", lon: "5.7171033" },
      { lat: "58.8468078", lon: "5.7171033" },
      { lat: "58.8472853112599", lon: "5.7167303347021" },
      { lat: "58.8473221", lon: "5.7167016" },
      { lat: "58.8475492", lon: "5.7165214" },
      { lat: "58.8475492", lon: "5.7165214" },
      { lat: "58.8482068", lon: "5.7159975" },
      { lat: "58.8485725296072", lon: "5.71570542717291" },
      { lat: "58.8486224", lon: "5.7156656" },
      { lat: "58.8490472", lon: "5.7153396" },
      { lat: "58.849389", lon: "5.715088" },
      { lat: "58.8498297", lon: "5.7148051" },
      { lat: "58.8499437737472", lon: "5.71473529336608" },
      { lat: "58.8513072545776", lon: "5.71400712690192" },
      { lat: "58.8517252", lon: "5.7138222" },
      { lat: "58.8521263", lon: "5.7136565" },
      { lat: "58.852706417956", lon: "5.71344984795604" },
      { lat: "58.8531105", lon: "5.7133059" },
      { lat: "58.8535008", lon: "5.7131739" },
      { lat: "58.8540186", lon: "5.7129898" },
      { lat: "58.8541090201253", lon: "5.71296016476243" },
      { lat: "58.8541858", lon: "5.712935" },
      { lat: "58.8541858", lon: "5.712935" },
      { lat: "58.8544385", lon: "5.7128449" },
      { lat: "58.8544385", lon: "5.7128449" },
      { lat: "58.8550121", lon: "5.7126465" },
      { lat: "58.855132", lon: "5.7126005" },
      { lat: "58.855132", lon: "5.7126005" },
      { lat: "58.8551994", lon: "5.7125792" },
      { lat: "58.8551994", lon: "5.7125792" },
      { lat: "58.8555227202295", lon: "5.71247675753425" },
      { lat: "58.8559395", lon: "5.7123447" },
      { lat: "58.8559395", lon: "5.7123447" },
      { lat: "58.8569517", lon: "5.712033" },
      { lat: "58.8569517", lon: "5.712033" },
      { lat: "58.8569738342462", lon: "5.7120270443311" },
      { lat: "58.8573423", lon: "5.7119279" },
      { lat: "58.8573423", lon: "5.7119279" },
      { lat: "58.8574468", lon: "5.7119226" },
      { lat: "58.8574468", lon: "5.7119226" },
      { lat: "58.8586649", lon: "5.7116538" },
      { lat: "58.8586649", lon: "5.7116538" },
      { lat: "58.8587126986061", lon: "5.71164458431304" },
      { lat: "58.8589595", lon: "5.711597" },
      { lat: "58.8589595", lon: "5.711597" },
      { lat: "58.8593581", lon: "5.7115461" },
      { lat: "58.859781", lon: "5.7114996" },
      { lat: "58.8604309", lon: "5.7114724" },
      { lat: "58.860432337847", lon: "5.71147239525689" },
      { lat: "58.8606431", lon: "5.7114717" },
      { lat: "58.8606431", lon: "5.7114717" },
      { lat: "58.8612558", lon: "5.71149" },
      { lat: "58.8612558", lon: "5.71149" },
      { lat: "58.8614049", lon: "5.7114931" },
      { lat: "58.861534", lon: "5.7115005" },
      { lat: "58.8616211", lon: "5.7115012" },
      { lat: "58.8616211", lon: "5.7115012" },
      { lat: "58.8616516", lon: "5.7114988" },
      { lat: "58.8617871", lon: "5.7115094" },
      { lat: "58.8618744773145", lon: "5.71152203511794" },
      { lat: "58.8619261", lon: "5.7115295" },
      { lat: "58.8619654", lon: "5.7115305" },
      { lat: "58.8619654", lon: "5.7115305" },
      { lat: "58.8620655", lon: "5.7115401" },
      { lat: "58.8620655", lon: "5.7115401" },
      { lat: "58.862406", lon: "5.7115632" },
      { lat: "58.8628896", lon: "5.7116017" },
      { lat: "58.8628896", lon: "5.7116017" },
      { lat: "58.8631188", lon: "5.7116218" },
      { lat: "58.8631188", lon: "5.7116218" },
      { lat: "58.8632894131004", lon: "5.711639610258" },
      { lat: "58.8637223", lon: "5.7116848" },
      { lat: "58.8644544", lon: "5.7117661" },
      { lat: "58.8647220531457", lon: "5.71180153081127" },
      { lat: "58.8651169", lon: "5.7118538" },
      { lat: "58.8656783", lon: "5.7119413" },
      { lat: "58.8656783", lon: "5.7119413" },
      { lat: "58.8660820225615", lon: "5.71202446943151" },
      { lat: "58.8661278", lon: "5.7120339" },
      { lat: "58.8663214", lon: "5.71209" },
      { lat: "58.8663214", lon: "5.71209" },
      { lat: "58.866488", lon: "5.7121591" },
      { lat: "58.866488", lon: "5.7121591" },
      { lat: "58.8668045", lon: "5.7122946" },
      { lat: "58.8669893", lon: "5.7123831" },
      { lat: "58.8671132", lon: "5.7124596" },
      { lat: "58.8671132", lon: "5.7124596" },
      { lat: "58.8672078", lon: "5.7125178" },
      { lat: "58.8673151", lon: "5.71259" },
      { lat: "58.8674191", lon: "5.7126648" },
      { lat: "58.8674465116005", lon: "5.71268585920572" },
      { lat: "58.8676258", lon: "5.7128236" },
      { lat: "58.8678303", lon: "5.7130021" },
      { lat: "58.8680314", lon: "5.7131955" },
      { lat: "58.8682519", lon: "5.7134203" },
      { lat: "58.8682519", lon: "5.7134203" },
      { lat: "58.8683851", lon: "5.7135853" },
      { lat: "58.8683851", lon: "5.7135853" },
      { lat: "58.8684953", lon: "5.7137108" },
      { lat: "58.8686778", lon: "5.7139434" },
      { lat: "58.8686961147621", lon: "5.71396765517923" },
      { lat: "58.8687669", lon: "5.7140614" },
      { lat: "58.8689431", lon: "5.714313" },
      { lat: "58.8692772", lon: "5.7148608" },
      { lat: "58.8697694726627", lon: "5.71575045307987" },
      { lat: "58.8698406", lon: "5.715879" },
      { lat: "58.8703222", lon: "5.7167394" },
      { lat: "58.8704891", lon: "5.7170218" },
      { lat: "58.8705751", lon: "5.7171595" },
      { lat: "58.8706595", lon: "5.717289" },
      { lat: "58.8707459", lon: "5.7174266" },
      { lat: "58.8709234", lon: "5.7176816" },
      { lat: "58.8709535525085", lon: "5.71772194846869" },
      { lat: "58.8711032", lon: "5.7179222" },
      { lat: "58.871292", lon: "5.7181476" },
      { lat: "58.8713843", lon: "5.7182542" },
      { lat: "58.8714832", lon: "5.718357" },
      { lat: "58.8715854", lon: "5.718452" },
      { lat: "58.8715854", lon: "5.718452" },
      { lat: "58.8716889", lon: "5.71856" },
      { lat: "58.8716889", lon: "5.71856" },
      { lat: "58.8719757", lon: "5.7188049" },
      { lat: "58.8719757", lon: "5.7188049" },
      { lat: "58.8720162", lon: "5.7188399" },
      { lat: "58.8721186", lon: "5.7189187" },
      { lat: "58.8721556006537", lon: "5.71894466259703" },
      { lat: "58.8722138", lon: "5.7189855" },
      { lat: "58.872451", lon: "5.7191412" },
      { lat: "58.8726442", lon: "5.7192496" },
      { lat: "58.8728449", lon: "5.7193507" },
      { lat: "58.8730599", lon: "5.7194497" },
      { lat: "58.8732757", lon: "5.7195345" },
      { lat: "58.8734895", lon: "5.7196041" },
      { lat: "58.8735099587747", lon: "5.71960972294261" },
      { lat: "58.8736656", lon: "5.7196525" },
      { lat: "58.8736656", lon: "5.7196525" },
      { lat: "58.8737608", lon: "5.7196704" },
      { lat: "58.8737608", lon: "5.7196704" },
      { lat: "58.8741773", lon: "5.7197283" },
      { lat: "58.8747149", lon: "5.7197586" },
      { lat: "58.8749435", lon: "5.7197452" },
      { lat: "58.8749435", lon: "5.7197452" },
      { lat: "58.8749553518329", lon: "5.7197424368359" },
      { lat: "58.875031", lon: "5.7197248" },
      { lat: "58.8754412", lon: "5.7196518" },
      { lat: "58.8754412", lon: "5.7196518" },
      { lat: "58.8756893", lon: "5.7195849" },
      { lat: "58.876157", lon: "5.7194415" },
      { lat: "58.876157", lon: "5.7194415" },
      { lat: "58.8763074", lon: "5.719392" },
      { lat: "58.8763074", lon: "5.719392" },
      { lat: "58.8764738751983", lon: "5.7192967132564" },
      { lat: "58.8765029", lon: "5.7192801" },
      { lat: "58.876757", lon: "5.7191502" },
      { lat: "58.8768458", lon: "5.7190892" },
      { lat: "58.8768458", lon: "5.7190892" },
      { lat: "58.8770497", lon: "5.7189774" },
      { lat: "58.8770497", lon: "5.7189774" },
      { lat: "58.87726", lon: "5.7188476" },
      { lat: "58.87726", lon: "5.7188476" },
      { lat: "58.8774083", lon: "5.7187665" },
      { lat: "58.8776312", lon: "5.7186382" },
      { lat: "58.8777980277152", lon: "5.7185463127042" },
      { lat: "58.8780023", lon: "5.7184338" },
      { lat: "58.878099", lon: "5.7183875" },
      { lat: "58.8782545", lon: "5.7183047" },
      { lat: "58.878554", lon: "5.7181716" },
      { lat: "58.8787611", lon: "5.7180902" },
      { lat: "58.8789753", lon: "5.7180147" },
      { lat: "58.8791976", lon: "5.717951" },
      { lat: "58.8793193555126", lon: "5.71792138290029" },
      { lat: "58.8795088", lon: "5.7178753" },
      { lat: "58.8796307", lon: "5.7178541" },
      { lat: "58.879716", lon: "5.7178455" },
      { lat: "58.8799424", lon: "5.7178067" },
      { lat: "58.8800724", lon: "5.7177989" },
      { lat: "58.8802566", lon: "5.7177918" },
      { lat: "58.8804903", lon: "5.7177895" },
      { lat: "58.8807026", lon: "5.7177981" },
      { lat: "58.880801036671", lon: "5.71780626867104" },
      { lat: "58.8808219", lon: "5.717808" },
      { lat: "58.8811422", lon: "5.7178371" },
      { lat: "58.8812175", lon: "5.7178465" },
      { lat: "58.8813045", lon: "5.7178624" },
      { lat: "58.8813739", lon: "5.7178688" },
      { lat: "58.8815762", lon: "5.7179006" },
      { lat: "58.8816913", lon: "5.7179159" },
      { lat: "58.881796", lon: "5.7179335" },
      { lat: "58.8821388", lon: "5.7179775" },
      { lat: "58.8823679104269", lon: "5.71801150496992" },
      { lat: "58.8825572", lon: "5.7180396" },
      { lat: "58.8827149", lon: "5.7180655" },
      { lat: "58.8827867", lon: "5.7180694" },
      { lat: "58.882873", lon: "5.7180814" },
      { lat: "58.8829554", lon: "5.7180889" },
      { lat: "58.8830987", lon: "5.7181084" },
      { lat: "58.8830987", lon: "5.7181084" },
      { lat: "58.8834277", lon: "5.718125" },
      { lat: "58.8834277", lon: "5.718125" },
      { lat: "58.8837156437206", lon: "5.71812955801721" },
      { lat: "58.8844953", lon: "5.7181419" },
      { lat: "58.8844953", lon: "5.7181419" },
      { lat: "58.8847754", lon: "5.7181369" },
      { lat: "58.8847754", lon: "5.7181369" },
      { lat: "58.8850780018346", lon: "5.7181039468552" },
      { lat: "58.8853282", lon: "5.7180767" },
      { lat: "58.8859067", lon: "5.7179771" },
      { lat: "58.8863465", lon: "5.7178765" },
      { lat: "58.8864539469216", lon: "5.71784296299479" },
      { lat: "58.8867678", lon: "5.717745" },
      { lat: "58.8867678", lon: "5.717745" },
      { lat: "58.8871005", lon: "5.7176338" },
      { lat: "58.8874648", lon: "5.7175309" },
      { lat: "58.8874648", lon: "5.7175309" },
      { lat: "58.8876982", lon: "5.7174285" },
      { lat: "58.8876982", lon: "5.7174285" },
      { lat: "58.8878396395116", lon: "5.71736092972064" },
      { lat: "58.8878464", lon: "5.7173577" },
      { lat: "58.8878464", lon: "5.7173577" },
      { lat: "58.8879231", lon: "5.7173464" },
      { lat: "58.8879231", lon: "5.7173464" },
      { lat: "58.8881339", lon: "5.7172283" },
      { lat: "58.8882294", lon: "5.7171643" },
      { lat: "58.8884609", lon: "5.7170336" },
      { lat: "58.8884609", lon: "5.7170336" },
      { lat: "58.8886403", lon: "5.7169269" },
      { lat: "58.8888006", lon: "5.7168364" },
      { lat: "58.8890476", lon: "5.7166933" },
      { lat: "58.8892235138123", lon: "5.71659376741776" },
      { lat: "58.8893728", lon: "5.7165093" },
      { lat: "58.889606", lon: "5.7163527" },
      { lat: "58.8901454", lon: "5.7159725" },
      { lat: "58.8905235", lon: "5.7156852" },
      { lat: "58.8905235", lon: "5.7156852" },
      { lat: "58.8906156", lon: "5.7155934" },
      { lat: "58.8906156", lon: "5.7155934" },
      { lat: "58.8906615109332", lon: "5.71555379651894" },
      { lat: "58.891546", lon: "5.7147908" },
      { lat: "58.8916413", lon: "5.7147038" },
      { lat: "58.8916413", lon: "5.7147038" },
      { lat: "58.8919562", lon: "5.7144166" },
      { lat: "58.8920767063704", lon: "5.71429906322283" },
      { lat: "58.8922322", lon: "5.7141474" },
      { lat: "58.8922322", lon: "5.7141474" },
      { lat: "58.8924793", lon: "5.713908" },
      { lat: "58.8932842559607", lon: "5.7131045529587" },
      { lat: "58.8936059", lon: "5.7127835" },
      { lat: "58.8936059", lon: "5.7127835" },
      { lat: "58.894115", lon: "5.7123032" },
      { lat: "58.894115", lon: "5.7123032" },
      { lat: "58.8946201", lon: "5.7118274" },
      { lat: "58.8946201", lon: "5.7118274" },
      { lat: "58.8946652", lon: "5.7117793" },
      { lat: "58.8946666554042", lon: "5.71177825265333" },
      { lat: "58.8946759", lon: "5.7117716" },
      { lat: "58.8946759", lon: "5.7117716" },
      { lat: "58.89478", lon: "5.7116814" },
      { lat: "58.89478", lon: "5.7116814" },
      { lat: "58.8948732", lon: "5.7116126" },
      { lat: "58.8948732", lon: "5.7116126" },
      { lat: "58.8952617", lon: "5.7113064" },
      { lat: "58.8955278", lon: "5.7111009" },
      { lat: "58.8956513", lon: "5.7110086" },
      { lat: "58.8959211", lon: "5.7108216" },
      { lat: "58.8959211", lon: "5.7108216" },
      { lat: "58.8959637382286", lon: "5.71079866728367" },
      { lat: "58.8962474", lon: "5.7106461" },
      { lat: "58.8964791", lon: "5.7105306" },
      { lat: "58.8966494", lon: "5.7104487" },
      { lat: "58.8967157", lon: "5.7104207" },
      { lat: "58.8967791", lon: "5.7103899" },
      { lat: "58.8972525", lon: "5.7101841" },
      { lat: "58.8973405", lon: "5.7101451" },
      { lat: "58.8973456362167", lon: "5.71014310056829" },
      { lat: "58.8974394", lon: "5.7101066" },
      { lat: "58.8979507", lon: "5.7098802" },
      { lat: "58.8980549", lon: "5.70983" },
      { lat: "58.8980732", lon: "5.7098178" },
      { lat: "58.8980732", lon: "5.7098178" },
      { lat: "58.8981532", lon: "5.7097829" },
      { lat: "58.8983449", lon: "5.7096771" },
      { lat: "58.8983449", lon: "5.7096771" },
      { lat: "58.8984728", lon: "5.7096144" },
      { lat: "58.8986129", lon: "5.7095371" },
      { lat: "58.8986751394789", lon: "5.70949919745474" },
      { lat: "58.8987955", lon: "5.7094259" },
      { lat: "58.8989887", lon: "5.7093013" },
      { lat: "58.8992909", lon: "5.7090921" },
      { lat: "58.8994761", lon: "5.7089557" },
      { lat: "58.8996522", lon: "5.7088165" },
      { lat: "58.8997175", lon: "5.7087625" },
      { lat: "58.8997746", lon: "5.7087195" },
      { lat: "58.8999496791839", lon: "5.70856941848253" },
      { lat: "58.8999574", lon: "5.7085628" },
      { lat: "58.900298", lon: "5.7082631" },
      { lat: "58.9004586", lon: "5.7081088" },
      { lat: "58.9005108", lon: "5.7080618" },
      { lat: "58.9005676", lon: "5.7080013" },
      { lat: "58.9006685", lon: "5.7079047" },
      { lat: "58.9007352", lon: "5.707833" },
      { lat: "58.9008878", lon: "5.7076808" },
      { lat: "58.9010093", lon: "5.7075475" },
      { lat: "58.9010637", lon: "5.7074912" },
      { lat: "58.9011184", lon: "5.7074277" },
      { lat: "58.901170705959", lon: "5.70737186975894" },
      { lat: "58.9012119", lon: "5.7073279" },
      { lat: "58.9012681", lon: "5.7072711" },
      { lat: "58.9013248", lon: "5.7072003" },
      { lat: "58.9014391", lon: "5.7070757" },
      { lat: "58.9015839", lon: "5.7069046" },
      { lat: "58.9016346", lon: "5.7068476" },
      { lat: "58.9016858", lon: "5.7067852" },
      { lat: "58.9017241", lon: "5.7067437" },
      { lat: "58.9018164", lon: "5.7066284" },
      { lat: "58.9018694", lon: "5.7065655" },
      { lat: "58.9019592", lon: "5.7064527" },
      { lat: "58.9020032", lon: "5.7064027" },
      { lat: "58.9020485", lon: "5.7063452" },
      { lat: "58.90209", lon: "5.7062976" },
      { lat: "58.9021367", lon: "5.7062363" },
      { lat: "58.9022242", lon: "5.7061278" },
      { lat: "58.9022624", lon: "5.7060738" },
      { lat: "58.9023136", lon: "5.7060097" },
      { lat: "58.9023543613549", lon: "5.7059628342886" },
      { lat: "58.902355", lon: "5.7059621" },
      { lat: "58.9024435", lon: "5.7058444" },
      { lat: "58.9026145", lon: "5.7056229" },
      { lat: "58.9026617", lon: "5.7055563" },
      { lat: "58.9027087", lon: "5.7054983" },
      { lat: "58.9028051", lon: "5.7053646" },
      { lat: "58.9031261", lon: "5.7049341" },
      { lat: "58.9034973802982", lon: "5.70441840598736" },
      { lat: "58.9035066", lon: "5.7044056" },
      { lat: "58.9036148", lon: "5.7042494" },
      { lat: "58.9038539", lon: "5.7039156" },
      { lat: "58.9040624", lon: "5.7036177" },
      { lat: "58.904207", lon: "5.7034084" },
      { lat: "58.9043839", lon: "5.7031573" },
      { lat: "58.9044895", lon: "5.7030019" },
      { lat: "58.9045776", lon: "5.7028791" },
      { lat: "58.9046277229661", lon: "5.70280649469414" },
      { lat: "58.9048371", lon: "5.7025032" },
      { lat: "58.9052798", lon: "5.7018675" },
      { lat: "58.9054726", lon: "5.7015912" },
      { lat: "58.9055554", lon: "5.7014802" },
      { lat: "58.9056626", lon: "5.7013227" },
      { lat: "58.905749839565", lon: "5.70119753261379" },
      { lat: "58.9058611", lon: "5.7010379" },
      { lat: "58.9059658", lon: "5.7008949" },
      { lat: "58.9060571", lon: "5.700766" },
      { lat: "58.9060571", lon: "5.700766" },
      { lat: "58.9061962", lon: "5.700559" },
      { lat: "58.9061962", lon: "5.700559" },
      { lat: "58.9065814", lon: "5.7000397" },
      { lat: "58.906671", lon: "5.6999256" },
      { lat: "58.9067569", lon: "5.6998081" },
      { lat: "58.9069539", lon: "5.6995541" },
      { lat: "58.9069539", lon: "5.6995541" },
      { lat: "58.9070355357478", lon: "5.69944585774448" },
      { lat: "58.9071248", lon: "5.6993275" },
      { lat: "58.9071248", lon: "5.6993275" },
      { lat: "58.9072698", lon: "5.6991404" },
      { lat: "58.9072698", lon: "5.6991404" },
      { lat: "58.9074405", lon: "5.6989375" },
      { lat: "58.9074405", lon: "5.6989375" },
      { lat: "58.9077431", lon: "5.6985634" },
      { lat: "58.9077431", lon: "5.6985634" },
      { lat: "58.908029", lon: "5.6982431" },
      { lat: "58.9082256168315", lon: "5.69803387351742" },
      { lat: "58.9085045", lon: "5.6977371" },
      { lat: "58.908673", lon: "5.6975613" },
      { lat: "58.908673", lon: "5.6975613" },
      { lat: "58.9092319", lon: "5.6970322" },
      { lat: "58.9092319", lon: "5.6970322" },
      { lat: "58.909393", lon: "5.6968913" },
      { lat: "58.9094737369379", lon: "5.69682330098851" },
      { lat: "58.9095597", lon: "5.6967509" },
      { lat: "58.9098367", lon: "5.6965377" },
      { lat: "58.9099306", lon: "5.6964683" },
      { lat: "58.9100222", lon: "5.6964057" },
      { lat: "58.9101114", lon: "5.6963402" },
      { lat: "58.9103179", lon: "5.696203" },
      { lat: "58.9105182", lon: "5.6960829" },
      { lat: "58.9106485", lon: "5.6960112" },
      { lat: "58.9106485", lon: "5.6960112" },
      { lat: "58.9108100531039", lon: "5.69592528369211" },
      { lat: "58.9109298", lon: "5.6958616" },
      { lat: "58.9109943", lon: "5.6958319" },
      { lat: "58.9109943", lon: "5.6958319" },
      { lat: "58.9111271", lon: "5.6957704" },
      { lat: "58.9112755", lon: "5.6957124" },
      { lat: "58.9112755", lon: "5.6957124" },
      { lat: "58.9113809", lon: "5.6956711" },
      { lat: "58.9116113", lon: "5.6955901" },
      { lat: "58.9117802", lon: "5.6955427" },
      { lat: "58.9119602", lon: "5.6954975" },
      { lat: "58.9119602", lon: "5.6954975" },
      { lat: "58.9120359", lon: "5.6954872" },
      { lat: "58.9120359", lon: "5.6954872" },
      { lat: "58.9121945229218", lon: "5.69545153870346" },
      { lat: "58.9122285", lon: "5.6954439" },
      { lat: "58.9122285", lon: "5.6954439" },
      { lat: "58.9123127", lon: "5.6954297" },
      { lat: "58.9124838", lon: "5.6954146" },
      { lat: "58.9126163", lon: "5.695405" },
      { lat: "58.9127333", lon: "5.6954028" },
      { lat: "58.9129523", lon: "5.6954115" },
      { lat: "58.9130012", lon: "5.6954176" },
      { lat: "58.9130631", lon: "5.6954196" },
      { lat: "58.9131578", lon: "5.6954345" },
      { lat: "58.9131684", lon: "5.6954333" },
      { lat: "58.9134899", lon: "5.6954857" },
      { lat: "58.9135436306564", lon: "5.69549784332381" },
      { lat: "58.9136691", lon: "5.6955262" },
      { lat: "58.9139191", lon: "5.6955964" },
      { lat: "58.9139989", lon: "5.6956252" },
      { lat: "58.9139989", lon: "5.6956252" },
      { lat: "58.9140788", lon: "5.6956544" },
      { lat: "58.9142312", lon: "5.6957127" },
      { lat: "58.914289", lon: "5.6957372" },
      { lat: "58.9143415", lon: "5.6957631" },
      { lat: "58.9144001", lon: "5.6957857" },
      { lat: "58.9144918", lon: "5.6958289" },
      { lat: "58.9147595", lon: "5.6959695" },
      { lat: "58.9149033", lon: "5.696058" },
      { lat: "58.9149099882886", lon: "5.69606174949063" },
      { lat: "58.9149495", lon: "5.6960839" },
      { lat: "58.9149961", lon: "5.6961167" },
      { lat: "58.9150434", lon: "5.6961458" },
      { lat: "58.9151926", lon: "5.6962468" },
      { lat: "58.9152366", lon: "5.6962803" },
      { lat: "58.9152927", lon: "5.6963176" },
      { lat: "58.915393", lon: "5.6963934" },
      { lat: "58.9155024", lon: "5.6964808" },
      { lat: "58.915752", lon: "5.6966944" },
      { lat: "58.9159694", lon: "5.696901" },
      { lat: "58.9160229", lon: "5.6969529" },
      { lat: "58.9160718", lon: "5.6970043" },
      { lat: "58.9161184", lon: "5.6970493" },
      { lat: "58.9161944773926", lon: "5.69713061436159" },
      { lat: "58.916284", lon: "5.6972263" },
      { lat: "58.9165029", lon: "5.6974779" },
      { lat: "58.9166407", lon: "5.6976467" },
      { lat: "58.9168186", lon: "5.6978815" },
      { lat: "58.9170362", lon: "5.6981894" },
      { lat: "58.9171646", lon: "5.6983782" },
      { lat: "58.9172888", lon: "5.69857" },
      { lat: "58.9173384", lon: "5.6986561" },
      { lat: "58.9173384", lon: "5.6986561" },
      { lat: "58.9173615", lon: "5.6986883" },
      { lat: "58.9173652209322", lon: "5.69869402092191" },
      { lat: "58.9174175", lon: "5.6987744" },
      { lat: "58.9174643", lon: "5.6988542" },
      { lat: "58.9174643", lon: "5.6988542" },
      { lat: "58.9175781", lon: "5.6990454" },
      { lat: "58.91765", lon: "5.6991639" },
      { lat: "58.9178047", lon: "5.69944" },
      { lat: "58.9178398", lon: "5.6995004" },
      { lat: "58.9178975", lon: "5.6996105" },
      { lat: "58.9179307", lon: "5.6996697" },
      { lat: "58.9179645", lon: "5.6997374" },
      { lat: "58.9180059", lon: "5.69981" },
      { lat: "58.9180365", lon: "5.6998699" },
      { lat: "58.9181086", lon: "5.7000041" },
      { lat: "58.918298", lon: "5.7003738" },
      { lat: "58.918329", lon: "5.7004388" },
      { lat: "58.9183591", lon: "5.7004936" },
      { lat: "58.9184085056549", lon: "5.70059665902703" },
      { lat: "58.9184347", lon: "5.7006513" },
      { lat: "58.9184659", lon: "5.7007075" },
      { lat: "58.9184899", lon: "5.7007639" },
      { lat: "58.9185418", lon: "5.7008686" },
      { lat: "58.9193490237581", lon: "5.70257318558673" },
      { lat: "58.9196484", lon: "5.7032054" },
      { lat: "58.9196484", lon: "5.7032054" },
      { lat: "58.9198392", lon: "5.7035916" },
      { lat: "58.9198392", lon: "5.7035916" },
      { lat: "58.9201642", lon: "5.7042127" },
      { lat: "58.9203002806408", lon: "5.70445141431934" },
      { lat: "58.9204071", lon: "5.7046388" },
      { lat: "58.9206001", lon: "5.7049335" },
      { lat: "58.9206001", lon: "5.7049335" },
      { lat: "58.9207506", lon: "5.7051765" },
      { lat: "58.9208325", lon: "5.7052913" },
      { lat: "58.9209486", lon: "5.705443" },
      { lat: "58.9212604", lon: "5.7058356" },
      { lat: "58.9212604", lon: "5.7058356" },
      { lat: "58.9212898", lon: "5.7058724" },
      { lat: "58.921377", lon: "5.7059676" },
      { lat: "58.921420357966", lon: "5.70601274959906" },
      { lat: "58.921752", lon: "5.7063581" },
      { lat: "58.9218115", lon: "5.7064088" },
      { lat: "58.9219967", lon: "5.7065866" },
      { lat: "58.92208", lon: "5.7066594" },
      { lat: "58.92208", lon: "5.7066594" },
      { lat: "58.9221558", lon: "5.7067188" },
      { lat: "58.9221558", lon: "5.7067188" },
      { lat: "58.922309", lon: "5.7068265" },
      { lat: "58.922309", lon: "5.7068265" },
      { lat: "58.9224637", lon: "5.7069431" },
      { lat: "58.9226992", lon: "5.7071058" },
      { lat: "58.9226992", lon: "5.7071058" },
      { lat: "58.9227858", lon: "5.7071625" },
      { lat: "58.9228256", lon: "5.7071961" },
      { lat: "58.9228619438324", lon: "5.70721835070674" },
      { lat: "58.9229597", lon: "5.7072782" },
      { lat: "58.9231225", lon: "5.7073634" },
      { lat: "58.9234605", lon: "5.7074842" },
      { lat: "58.9235595", lon: "5.7075232" },
      { lat: "58.9237419", lon: "5.7075639" },
      { lat: "58.9239895", lon: "5.7076072" },
      { lat: "58.9242190601347", lon: "5.70764471904633" },
      { lat: "58.9246405", lon: "5.7077136" },
      { lat: "58.9246405", lon: "5.7077136" },
      { lat: "58.9251006", lon: "5.7077969" },
      { lat: "58.9251006", lon: "5.7077969" },
      { lat: "58.9254662", lon: "5.7078631" },
      { lat: "58.9254662", lon: "5.7078631" },
      { lat: "58.9256194488529", lon: "5.70788819102406" },
      { lat: "58.9260336", lon: "5.707956" },
      { lat: "58.9260336", lon: "5.707956" },
      { lat: "58.9270066269958", lon: "5.70808740665969" },
      { lat: "58.9272102", lon: "5.7081149" },
      { lat: "58.9283681", lon: "5.7083103" },
      { lat: "58.9284157138635", lon: "5.70832223758934" },
      { lat: "58.9290956", lon: "5.7084927" },
      { lat: "58.9297222", lon: "5.7087298" },
      { lat: "58.9298105835731", lon: "5.70877902653558" },
      { lat: "58.9301619", lon: "5.7089747" },
      { lat: "58.9306554", lon: "5.7093004" },
      { lat: "58.9311179", lon: "5.709699" },
      { lat: "58.9311409063689", lon: "5.70971981330317" },
      { lat: "58.9314232", lon: "5.7099752" },
      { lat: "58.9316733", lon: "5.7102488" },
      { lat: "58.9320417", lon: "5.7107033" },
      { lat: "58.9320417", lon: "5.7107033" },
      { lat: "58.932071", lon: "5.7107397" },
      { lat: "58.9322766", lon: "5.7110168" },
      { lat: "58.9323069563508", lon: "5.71105587569217" },
      { lat: "58.932333", lon: "5.7110894" },
      { lat: "58.9324172", lon: "5.7112322" },
      { lat: "58.932462", lon: "5.7112996" },
      { lat: "58.9325112", lon: "5.7113846" },
      { lat: "58.9325643", lon: "5.7114652" },
      { lat: "58.9326146", lon: "5.7115514" },
      { lat: "58.9328296", lon: "5.7119123" },
      { lat: "58.9328769", lon: "5.7119966" },
      { lat: "58.9329801", lon: "5.7121708" },
      { lat: "58.933079", lon: "5.7123462" },
      { lat: "58.9331869", lon: "5.7125382" },
      { lat: "58.9332415", lon: "5.7126393" },
      { lat: "58.9333286630556", lon: "5.71279148276899" },
      { lat: "58.9333466", lon: "5.7128228" },
      { lat: "58.9333466", lon: "5.7128228" },
      { lat: "58.9333902", lon: "5.7129124" },
      { lat: "58.9334255", lon: "5.7129762" },
      { lat: "58.9356767", lon: "5.7173975" },
      { lat: "58.9356767", lon: "5.7173975" },
      { lat: "58.9357332", lon: "5.7175028" },
      { lat: "58.9361471", lon: "5.7183155" },
      { lat: "58.9361765642465", lon: "5.71837384641706" },
      { lat: "58.9365121", lon: "5.7190383" },
      { lat: "58.9365909", lon: "5.7191901" },
      { lat: "58.9366576", lon: "5.7193258" },
      { lat: "58.9367888", lon: "5.719577" },
      { lat: "58.9368471", lon: "5.7196959" },
      { lat: "58.9371511181169", lon: "5.72028602953723" },
      { lat: "58.9371561", lon: "5.7202957" },
      { lat: "58.9372754", lon: "5.7205327" },
      { lat: "58.9373919", lon: "5.7207564" },
      { lat: "58.9376312", lon: "5.7212283" },
      { lat: "58.9379769", lon: "5.7218847" },
      { lat: "58.9380422", lon: "5.7220016" },
      { lat: "58.9381216", lon: "5.7221497" },
      { lat: "58.9382207982046", lon: "5.72232678710086" },
      { lat: "58.9382296", lon: "5.7223425" },
      { lat: "58.9385047", lon: "5.7228101" },
      { lat: "58.9386929", lon: "5.7231018" },
      { lat: "58.9388714", lon: "5.7233699" },
      { lat: "58.9389977", lon: "5.723542" },
      { lat: "58.9390559", lon: "5.7236224" },
      { lat: "58.9391773", lon: "5.7237766" },
      { lat: "58.9393143", lon: "5.7239459" },
      { lat: "58.9393225635771", lon: "5.7239550393165" },
      { lat: "58.9393879", lon: "5.7240273" },
      { lat: "58.9394541", lon: "5.7241056" },
      { lat: "58.9395255", lon: "5.7241806" },
      { lat: "58.9395934", lon: "5.7242567" },
      { lat: "58.9396909", lon: "5.7243562" },
      { lat: "58.9396909", lon: "5.7243562" },
      { lat: "58.9397925", lon: "5.7244554" },
      { lat: "58.9398533", lon: "5.724518" },
      { lat: "58.9398937", lon: "5.7245553" },
      { lat: "58.9398937", lon: "5.7245553" },
      { lat: "58.9400468", lon: "5.7246927" },
      { lat: "58.9403333", lon: "5.7249187" },
      { lat: "58.9404017", lon: "5.7249709" },
      { lat: "58.9405414", lon: "5.7250681" },
      { lat: "58.9407669", lon: "5.725211" },
      { lat: "58.9409400317587", lon: "5.72530628999168" },
      { lat: "58.9409971", lon: "5.7253377" },
      { lat: "58.9411538", lon: "5.7254146" },
      { lat: "58.9413385", lon: "5.725496" },
      { lat: "58.9413385", lon: "5.725496" },
      { lat: "58.9415252", lon: "5.725573" },
      { lat: "58.9415252", lon: "5.725573" },
      { lat: "58.9415711", lon: "5.7255884" },
      { lat: "58.9416769", lon: "5.7256142" },
      { lat: "58.9416769", lon: "5.7256142" },
      { lat: "58.9419841", lon: "5.7256793" },
      { lat: "58.9421551", lon: "5.7257123" },
      { lat: "58.9421967", lon: "5.7257238" },
      { lat: "58.9421967", lon: "5.7257238" },
      { lat: "58.9422298", lon: "5.7257729" },
      { lat: "58.9422592", lon: "5.7258062" },
      { lat: "58.9422837", lon: "5.7258208" },
      { lat: "58.9423154", lon: "5.7258342" },
      { lat: "58.9424583268962", lon: "5.72588433753996" },
      { lat: "58.9424796", lon: "5.7258918" },
      { lat: "58.9432244", lon: "5.7261203" },
      { lat: "58.9432866", lon: "5.726142" },
      { lat: "58.943391", lon: "5.7261872" },
      { lat: "58.9435244", lon: "5.7262699" },
      { lat: "58.9435779", lon: "5.7263097" },
      { lat: "58.9438431466076", lon: "5.72652875291367" },
      { lat: "58.9439431", lon: "5.7266113" },
      { lat: "58.9440085", lon: "5.726653" },
      { lat: "58.9440469", lon: "5.7266723" },
      { lat: "58.9441171", lon: "5.7266936" },
      { lat: "58.9441672", lon: "5.7266992" },
      { lat: "58.9442167", lon: "5.7266963" },
      { lat: "58.9442614", lon: "5.7266895" },
      { lat: "58.9444965", lon: "5.7266031" },
      { lat: "58.9445399", lon: "5.7265914" },
      { lat: "58.9445399", lon: "5.7265914" },
      { lat: "58.9445589", lon: "5.7266789" },
      { lat: "58.9446158", lon: "5.7269118" },
      { lat: "58.9446158", lon: "5.7269118" },
      { lat: "58.9446544", lon: "5.7270884" },
      { lat: "58.9446985", lon: "5.7272545" },
      { lat: "58.9446985", lon: "5.7272545" },
      { lat: "58.944739", lon: "5.7274328" },
      { lat: "58.944793", lon: "5.7276539" },
      { lat: "58.944793", lon: "5.7276539" },
      { lat: "58.9448459", lon: "5.727862" },
      { lat: "58.9448958", lon: "5.7280391" },
      { lat: "58.9449598", lon: "5.7282875" },
      { lat: "58.9450044", lon: "5.7284743" },
      { lat: "58.9450044", lon: "5.7284743" },
      { lat: "58.9450167", lon: "5.728524" },
      { lat: "58.9450787", lon: "5.728745" },
      { lat: "58.9451137026498", lon: "5.72883917373599" },
      { lat: "58.9451249", lon: "5.7288693" },
      { lat: "58.9451576", lon: "5.7289803" },
      { lat: "58.9452002", lon: "5.7291381" },
      { lat: "58.9452033", lon: "5.7291664" },
      { lat: "58.9452195", lon: "5.729263" },
      { lat: "58.9452289", lon: "5.7293615" },
      { lat: "58.9452285", lon: "5.7294216" },
      { lat: "58.9452252", lon: "5.7294705" },
      { lat: "58.9452252", lon: "5.7294705" },
      { lat: "58.9452069", lon: "5.7294946" },
      { lat: "58.9451963", lon: "5.7295136" },
      { lat: "58.9451907", lon: "5.7295519" },
      { lat: "58.9451907", lon: "5.7295519" },
      { lat: "58.9451907", lon: "5.7295879" },
      { lat: "58.9451924", lon: "5.7296131" },
      { lat: "58.9451954", lon: "5.7296289" },
      { lat: "58.9452069", lon: "5.7296572" },
      { lat: "58.9452069", lon: "5.7296572" },
      { lat: "58.9452332", lon: "5.7296776" },
      { lat: "58.9452599", lon: "5.7296875" },
      { lat: "58.9452599", lon: "5.7296875" },
      { lat: "58.9453405", lon: "5.7299486" },
      { lat: "58.9453702", lon: "5.7300394" },
      { lat: "58.9453899", lon: "5.7300754" },
      { lat: "58.9453899", lon: "5.7300754" },
      { lat: "58.9453896", lon: "5.7300965" },
      { lat: "58.9453915", lon: "5.7301222" },
      { lat: "58.9454005", lon: "5.7301515" },
      { lat: "58.9454061", lon: "5.730162" },
      { lat: "58.9454169", lon: "5.7301731" },
      { lat: "58.9454169", lon: "5.7301731" },
      { lat: "58.94543", lon: "5.73018" },
      { lat: "58.9454409", lon: "5.7301805" },
      { lat: "58.9454538", lon: "5.7301718" },
      { lat: "58.945465", lon: "5.73016" },
      { lat: "58.9454725", lon: "5.7301472" },
      { lat: "58.9454777", lon: "5.7301316" },
      { lat: "58.9454777", lon: "5.7301316" },
      { lat: "58.9454787", lon: "5.7301069" },
      { lat: "58.9454777", lon: "5.7300848" },
      { lat: "58.9454739", lon: "5.7300582" },
      { lat: "58.9454739", lon: "5.7300582" },
      { lat: "58.9454615", lon: "5.7300282" },
      { lat: "58.9454486", lon: "5.730011" },
      { lat: "58.9454486", lon: "5.730011" },
      { lat: "58.9454338", lon: "5.7299545" },
      { lat: "58.9453867", lon: "5.729811" },
      { lat: "58.9453404", lon: "5.7296825" },
      { lat: "58.9453348", lon: "5.7296629" },
      { lat: "58.9453227", lon: "5.729636" },
      { lat: "58.9453079", lon: "5.72961" },
      { lat: "58.9453079", lon: "5.72961" },
      { lat: "58.9453111", lon: "5.7295766" },
      { lat: "58.9453064", lon: "5.729535" },
      { lat: "58.9452981", lon: "5.7295047" },
      { lat: "58.9452838", lon: "5.7294795" },
      { lat: "58.9452838", lon: "5.7294795" },
      { lat: "58.9452838", lon: "5.7294795" },
      { lat: "58.9452733", lon: "5.7294691" },
      { lat: "58.9452551", lon: "5.7294602" },
      { lat: "58.945241", lon: "5.7294609" },
      { lat: "58.9452252", lon: "5.7294705" },
      { lat: "58.9452252", lon: "5.7294705" },
      { lat: "58.9452069", lon: "5.7294946" },
      { lat: "58.9451963", lon: "5.7295136" },
      { lat: "58.9451907", lon: "5.7295519" },
      { lat: "58.9451907", lon: "5.7295519" },
      { lat: "58.9451907", lon: "5.7295879" },
      { lat: "58.9451924", lon: "5.7296131" },
      { lat: "58.9451954", lon: "5.7296289" },
      { lat: "58.9452069", lon: "5.7296572" },
      { lat: "58.9452069", lon: "5.7296572" },
      { lat: "58.9452332", lon: "5.7296776" },
      { lat: "58.9452599", lon: "5.7296875" },
      { lat: "58.9452599", lon: "5.7296875" },
      { lat: "58.9453405", lon: "5.7299486" },
      { lat: "58.9453702", lon: "5.7300394" },
      { lat: "58.9453899", lon: "5.7300754" },
      { lat: "58.9453899", lon: "5.7300754" },
      { lat: "58.9453896", lon: "5.7300965" },
      { lat: "58.9453915", lon: "5.7301222" },
      { lat: "58.9454005", lon: "5.7301515" },
      { lat: "58.9454061", lon: "5.730162" },
      { lat: "58.9454169", lon: "5.7301731" },
      { lat: "58.9454169", lon: "5.7301731" },
      { lat: "58.94543", lon: "5.73018" },
      { lat: "58.9454409", lon: "5.7301805" },
      { lat: "58.9454538", lon: "5.7301718" },
      { lat: "58.945465", lon: "5.73016" },
      { lat: "58.9454725", lon: "5.7301472" },
      { lat: "58.9454777", lon: "5.7301316" },
      { lat: "58.9454777", lon: "5.7301316" },
      { lat: "58.9454787", lon: "5.7301069" },
      { lat: "58.9454777", lon: "5.7300848" },
      { lat: "58.9454739", lon: "5.7300582" },
      { lat: "58.9454739", lon: "5.7300582" },
      { lat: "58.9454615", lon: "5.7300282" },
      { lat: "58.9454486", lon: "5.730011" },
      { lat: "58.9454486", lon: "5.730011" },
      { lat: "58.9454338", lon: "5.7299545" },
      { lat: "58.9453867", lon: "5.729811" },
      { lat: "58.9453404", lon: "5.7296825" },
      { lat: "58.9453348", lon: "5.7296629" },
      { lat: "58.9453227", lon: "5.729636" },
      { lat: "58.9453079", lon: "5.72961" },
      { lat: "58.9453079", lon: "5.72961" },
      { lat: "58.9453111", lon: "5.7295766" },
      { lat: "58.9453064", lon: "5.729535" },
      { lat: "58.9452981", lon: "5.7295047" },
      { lat: "58.9452838", lon: "5.7294795" },
      { lat: "58.9452838", lon: "5.7294795" },
      { lat: "58.9452879", lon: "5.7294387" },
      { lat: "58.9452885", lon: "5.7293989" },
      { lat: "58.9452835", lon: "5.7293104" },
      { lat: "58.9452783", lon: "5.7292669" },
      { lat: "58.94526", lon: "5.7291943" },
      { lat: "58.9452399", lon: "5.7291083" },
      { lat: "58.9452201", lon: "5.7290274" },
      { lat: "58.9451685", lon: "5.7288413" },
      { lat: "58.9451372", lon: "5.7287169" },
      { lat: "58.9451372", lon: "5.7287169" },
      { lat: "58.9451073", lon: "5.7285781" },
      { lat: "58.9450522", lon: "5.7283535" },
      { lat: "58.9450278", lon: "5.7282412" },
      { lat: "58.9449807", lon: "5.7280511" },
      { lat: "58.9449627", lon: "5.7279895" },
      { lat: "58.9449627", lon: "5.7279895" },
      { lat: "58.9449211", lon: "5.7278521" },
      { lat: "58.9449211", lon: "5.7278521" },
      { lat: "58.9448989", lon: "5.7277687" },
      { lat: "58.944848", lon: "5.7275574" },
      { lat: "58.944848", lon: "5.7275574" },
      { lat: "58.9448028", lon: "5.7273726" },
      { lat: "58.9447997", lon: "5.7273544" },
      { lat: "58.9447997", lon: "5.7273544" },
      { lat: "58.9447601", lon: "5.7271928" },
      { lat: "58.9447601", lon: "5.7271928" },
      { lat: "58.9447199", lon: "5.7270355" },
      { lat: "58.9446815", lon: "5.7268728" },
      { lat: "58.9446769", lon: "5.7268482" },
      { lat: "58.9446769", lon: "5.7268482" },
      { lat: "58.9446367291049", lon: "5.72668119080183" },
      { lat: "58.944628", lon: "5.7266449" },
      { lat: "58.9446042", lon: "5.7265409" },
      { lat: "58.9446042", lon: "5.7265409" },
      { lat: "58.944568", lon: "5.7263494" },
      { lat: "58.944568", lon: "5.7263494" },
      { lat: "58.9444994", lon: "5.7263992" },
      { lat: "58.9444994", lon: "5.7263992" },
      { lat: "58.9444216", lon: "5.7264102" },
      { lat: "58.9443876", lon: "5.7264268" },
      { lat: "58.944308", lon: "5.7264748" },
      { lat: "58.9442314", lon: "5.7265162" },
      { lat: "58.9441981", lon: "5.7265288" },
      { lat: "58.9441655", lon: "5.7265326" },
      { lat: "58.9441356", lon: "5.7265283" },
      { lat: "58.9440713", lon: "5.7264858" },
      { lat: "58.9440331", lon: "5.7264397" },
      { lat: "58.9439685", lon: "5.7263521" },
      { lat: "58.9439685", lon: "5.7263521" },
      { lat: "58.9439461", lon: "5.7262739" },
      { lat: "58.9439346", lon: "5.7262189" },
      { lat: "58.9439258", lon: "5.7260949" },
      { lat: "58.9439277", lon: "5.7260292" },
      { lat: "58.9439337", lon: "5.7259682" },
      { lat: "58.9439449", lon: "5.7259072" },
      { lat: "58.9439571", lon: "5.7258535" },
      { lat: "58.9439737", lon: "5.7258028" },
      { lat: "58.943994", lon: "5.7257519" },
      { lat: "58.9440166", lon: "5.7257019" },
      { lat: "58.9440697", lon: "5.7256221" },
      { lat: "58.9440697", lon: "5.7256221" },
      { lat: "58.9441325", lon: "5.7255607" },
      { lat: "58.9442169", lon: "5.7254992" },
      { lat: "58.9442879", lon: "5.7254535" },
      { lat: "58.9443926733436", lon: "5.72539323819896" },
      { lat: "58.9446165", lon: "5.7252645" },
      { lat: "58.9446165", lon: "5.7252645" },
      { lat: "58.9446762", lon: "5.7252302" },
      { lat: "58.9447967", lon: "5.7251497" },
      { lat: "58.9448396", lon: "5.7251132" },
      { lat: "58.9448931", lon: "5.7250595" },
      { lat: "58.9449371", lon: "5.7250037" },
      { lat: "58.9449759", lon: "5.7249163" },
      { lat: "58.9449759", lon: "5.7249163" },
      { lat: "58.9450734", lon: "5.7248532" },
      { lat: "58.9454481", lon: "5.7245841" },
      { lat: "58.9454997152438", lon: "5.7245439575553" },
      { lat: "58.9456753", lon: "5.7244074" },
      { lat: "58.9457557", lon: "5.7243489" },
      { lat: "58.9460725", lon: "5.7241044" },
      { lat: "58.9461738", lon: "5.7240294" },
      { lat: "58.9461936", lon: "5.7240173" },
      { lat: "58.9461936", lon: "5.7240173" },
      { lat: "58.946456", lon: "5.7238111" },
      { lat: "58.9465698", lon: "5.723726" },
      { lat: "58.9465761414299", lon: "5.72372106743884" },
      { lat: "58.946762", lon: "5.7235765" },
      { lat: "58.9468797", lon: "5.7234883" },
      { lat: "58.9469774", lon: "5.7234096" },
      { lat: "58.9472944", lon: "5.7231696" },
      { lat: "58.947322", lon: "5.7231506" },
      { lat: "58.947322", lon: "5.7231506" },
      { lat: "58.9473927", lon: "5.7230935" },
      { lat: "58.9475967", lon: "5.7229423" },
      { lat: "58.9476922", lon: "5.7228752" },
      { lat: "58.9477556248439", lon: "5.7228272900976" },
      { lat: "58.9477944", lon: "5.722798" },
      { lat: "58.9479586", lon: "5.7226836" },
      { lat: "58.9479586", lon: "5.7226836" },
      { lat: "58.9482972", lon: "5.7224605" },
      { lat: "58.9485682", lon: "5.7222928" },
      { lat: "58.9485774", lon: "5.7222844" },
      { lat: "58.9485774", lon: "5.7222844" },
      { lat: "58.9489771953762", lon: "5.72208388629532" },
      { lat: "58.949096", lon: "5.7220243" },
      { lat: "58.9496276", lon: "5.7218409" },
      { lat: "58.9500221", lon: "5.7217621" },
      { lat: "58.9501474462123", lon: "5.72173865278621" },
      { lat: "58.9503065", lon: "5.7217089" },
      { lat: "58.9503065", lon: "5.7217089" },
      { lat: "58.9506494", lon: "5.7216634" },
      { lat: "58.9506494", lon: "5.7216634" },
      { lat: "58.9508288", lon: "5.7216452" },
      { lat: "58.9512579", lon: "5.7216193" },
      { lat: "58.9513562101607", lon: "5.72161646371736" },
      { lat: "58.9516877", lon: "5.7216069" },
      { lat: "58.9523531", lon: "5.7216027" },
      { lat: "58.9524263", lon: "5.7216038" },
      { lat: "58.9524263", lon: "5.7216038" },
      { lat: "58.9524497", lon: "5.7216132" },
      { lat: "58.952483252021", lon: "5.72162028992064" },
      { lat: "58.952577", lon: "5.7216401" },
      { lat: "58.9526069", lon: "5.7216487" },
      { lat: "58.9526069", lon: "5.7216487" },
      { lat: "58.9528036", lon: "5.7217191" },
      { lat: "58.9528661", lon: "5.7217379" },
      { lat: "58.9528661", lon: "5.7217379" },
      { lat: "58.9529234", lon: "5.7217772" },
      { lat: "58.9530054", lon: "5.7218118" },
      { lat: "58.9531279", lon: "5.7218408" },
      { lat: "58.9532911", lon: "5.7218631" },
      { lat: "58.9535086", lon: "5.721944" },
      { lat: "58.9536217718867", lon: "5.7220133724305" },
      { lat: "58.9536688", lon: "5.7220422" },
      { lat: "58.9538126", lon: "5.7221058" },
      { lat: "58.9539139", lon: "5.7220957" },
      { lat: "58.9540376", lon: "5.7220538" },
      { lat: "58.9543461", lon: "5.7219017" },
      { lat: "58.9543461", lon: "5.7219017" },
      { lat: "58.9543706", lon: "5.7219118" },
      { lat: "58.9543942", lon: "5.7219129" },
      { lat: "58.9544181", lon: "5.721905" },
      { lat: "58.9544392", lon: "5.7218891" },
      { lat: "58.9544518", lon: "5.7218742" },
      { lat: "58.9544518", lon: "5.7218742" },
      { lat: "58.9545353", lon: "5.7218108" },
      { lat: "58.9546058747931", lon: "5.72178615614508" },
      { lat: "58.9546215", lon: "5.7217807" },
      { lat: "58.9547198", lon: "5.7217607" },
      { lat: "58.954778", lon: "5.721764" },
      { lat: "58.954778", lon: "5.721764" },
      { lat: "58.9548129", lon: "5.7217639" },
      { lat: "58.9549103", lon: "5.7217776" },
      { lat: "58.9549103", lon: "5.7217776" },
      { lat: "58.954946", lon: "5.7217808" },
      { lat: "58.955038", lon: "5.7217963" },
      { lat: "58.9551326", lon: "5.7218076" },
      { lat: "58.9553847", lon: "5.7218407" },
      { lat: "58.9555612", lon: "5.721871" },
      { lat: "58.9558242", lon: "5.7219093" },
      { lat: "58.95591", lon: "5.7219253" },
      { lat: "58.95591", lon: "5.7219253" },
      { lat: "58.9561234", lon: "5.7219581" },
      { lat: "58.9561661", lon: "5.721965" },
      { lat: "58.9561661", lon: "5.721965" },
      { lat: "58.9563963920951", lon: "5.72200444220365" },
      { lat: "58.9564738", lon: "5.7220177" },
      { lat: "58.9566078", lon: "5.7220372" },
      { lat: "58.9566078", lon: "5.7220372" },
      { lat: "58.9573937978706", lon: "5.72217844456041" },
      { lat: "58.9574581", lon: "5.72219" },
      { lat: "58.9577813", lon: "5.7222518" },
      { lat: "58.9577813", lon: "5.7222518" },
      { lat: "58.9579514", lon: "5.7222733" },
      { lat: "58.9580797", lon: "5.7222969" },
      { lat: "58.9582101", lon: "5.7223401" },
      { lat: "58.9583379", lon: "5.7224115" },
      { lat: "58.9583379", lon: "5.7224115" },
      { lat: "58.9583527", lon: "5.7224543" },
      { lat: "58.9583722", lon: "5.7224948" },
      { lat: "58.9583947", lon: "5.7225289" },
      { lat: "58.9584014", lon: "5.7225367" },
      { lat: "58.9584014", lon: "5.7225367" },
      { lat: "58.9584197", lon: "5.7225555" },
      { lat: "58.9584465", lon: "5.7225742" },
      { lat: "58.9584746", lon: "5.7225843" },
      { lat: "58.9585031", lon: "5.7225857" },
      { lat: "58.9585314", lon: "5.7225783" },
      { lat: "58.9585587", lon: "5.7225623" },
      { lat: "58.9585806", lon: "5.7225422" },
      { lat: "58.9585806", lon: "5.7225422" },
      { lat: "58.9586028", lon: "5.7225073" },
      { lat: "58.9586248", lon: "5.7224577" },
      { lat: "58.9586248", lon: "5.7224577" },
      { lat: "58.9587963", lon: "5.7224758" },
      { lat: "58.9587963", lon: "5.7224758" },
      { lat: "58.9589533", lon: "5.7225054" },
      { lat: "58.9589533", lon: "5.7225054" },
      { lat: "58.9590568", lon: "5.7225543" },
      { lat: "58.9590568", lon: "5.7225543" },
      { lat: "58.9592977", lon: "5.722601" },
      { lat: "58.9594776", lon: "5.7226421" },
      { lat: "58.9597913", lon: "5.7227227" },
      { lat: "58.9599574907322", lon: "5.7227790643873" },
      { lat: "58.9599688", lon: "5.7227829" },
      { lat: "58.9601152", lon: "5.7228576" },
      { lat: "58.9602279", lon: "5.7229051" },
      { lat: "58.9602279", lon: "5.7229051" },
      { lat: "58.9603235", lon: "5.7229363" },
      { lat: "58.9604944", lon: "5.7230267" },
      { lat: "58.9605511", lon: "5.7230791" },
      { lat: "58.9606065", lon: "5.7231511" },
      { lat: "58.9606504", lon: "5.723252" },
      { lat: "58.9606715", lon: "5.7232949" },
      { lat: "58.9606715", lon: "5.7232949" },
      { lat: "58.9607032", lon: "5.7233713" },
      { lat: "58.9607215", lon: "5.7234053" },
      { lat: "58.9607422", lon: "5.7234336" },
      { lat: "58.9607649", lon: "5.7234556" },
      { lat: "58.960789", lon: "5.7234708" },
      { lat: "58.960814", lon: "5.7234788" },
      { lat: "58.9608394", lon: "5.7234794" },
      { lat: "58.9608412722727", lon: "5.72347890023032" },
      { lat: "58.9608645", lon: "5.7234727" },
      { lat: "58.9608888", lon: "5.7234587" },
      { lat: "58.9609118", lon: "5.7234379" },
      { lat: "58.9609229", lon: "5.7234216" },
      { lat: "58.9609229", lon: "5.7234216" },
      { lat: "58.9609672", lon: "5.7234118" },
      { lat: "58.961024", lon: "5.7234249" },
      { lat: "58.9610956", lon: "5.7234852" },
      { lat: "58.9612395", lon: "5.72359" },
      { lat: "58.9614556", lon: "5.7237262" },
      { lat: "58.9615712", lon: "5.7237865" },
      { lat: "58.9616019776511", lon: "5.72379847354827" },
      { lat: "58.9625071295613", lon: "5.72408216764449" },
      { lat: "58.9625136", lon: "5.724087" },
      { lat: "58.9625387", lon: "5.7240946" },
      { lat: "58.962564", lon: "5.7240918" },
      { lat: "58.9625885", lon: "5.7240787" },
      { lat: "58.9625984", lon: "5.72407" },
      { lat: "58.9625984", lon: "5.72407" },
      { lat: "58.9627058", lon: "5.7242545" },
      { lat: "58.9627546", lon: "5.7243428" },
      { lat: "58.9627837", lon: "5.7244217" },
      { lat: "58.9627837", lon: "5.7244217" },
      { lat: "58.9628327", lon: "5.724586" },
      { lat: "58.9628327", lon: "5.724586" },
      { lat: "58.962980892582", lon: "5.72515996473762" },
      { lat: "58.9630045", lon: "5.7252514" },
      { lat: "58.9630045", lon: "5.7252514" },
      { lat: "58.9630318", lon: "5.7253636" },
      { lat: "58.9630667", lon: "5.7254904" },
      { lat: "58.9632835", lon: "5.7260907" },
      { lat: "58.9632835", lon: "5.7260907" },
      { lat: "58.9633603445084", lon: "5.72630304892524" },
      { lat: "58.9633621", lon: "5.7263079" },
      { lat: "58.9633621", lon: "5.7263079" },
      { lat: "58.9634056", lon: "5.7264237" },
      { lat: "58.9636106", lon: "5.7269794" },
      { lat: "58.9636106", lon: "5.7269794" },
      { lat: "58.9636808", lon: "5.7272121" },
      { lat: "58.9637734", lon: "5.7274661" },
      { lat: "58.9638207", lon: "5.7275885" },
      { lat: "58.963836", lon: "5.7276328" },
      { lat: "58.963836", lon: "5.7276328" },
      { lat: "58.9638754254979", lon: "5.72773409933879" },
      { lat: "58.963885", lon: "5.7277587" },
      { lat: "58.9639135", lon: "5.7278238" },
      { lat: "58.9639717", lon: "5.7279419" },
      { lat: "58.9640011", lon: "5.7279947" },
      { lat: "58.9640591", lon: "5.7280867" },
      { lat: "58.9641158", lon: "5.7281634" },
      { lat: "58.9641791", lon: "5.7282313" },
      { lat: "58.9641791", lon: "5.7282313" },
      { lat: "58.9644721", lon: "5.7284713" },
      { lat: "58.9644721", lon: "5.7284713" },
      { lat: "58.9647579", lon: "5.7287034" },
      { lat: "58.9648773", lon: "5.7288598" },
      { lat: "58.9649589", lon: "5.7290129" },
      { lat: "58.9650228", lon: "5.7291584" },
      { lat: "58.9650228", lon: "5.7291584" },
      { lat: "58.9650132", lon: "5.729216" },
      { lat: "58.9650109", lon: "5.7292611" },
      { lat: "58.965013", lon: "5.7292863" },
      { lat: "58.9650192", lon: "5.7293167" },
      { lat: "58.9650294", lon: "5.7293504" },
      { lat: "58.9650539", lon: "5.7293896" },
      { lat: "58.9650675", lon: "5.7294017" },
      { lat: "58.9650997", lon: "5.7294105" },
      { lat: "58.9650997", lon: "5.7294105" },
      { lat: "58.9651663", lon: "5.7298823" },
      { lat: "58.9652254829119", lon: "5.73016716862647" },
      { lat: "58.9652917", lon: "5.7304859" },
      { lat: "58.9653278", lon: "5.7306747" },
      { lat: "58.9653278", lon: "5.7306747" },
      { lat: "58.9654285", lon: "5.731128" },
      { lat: "58.9654957269611", lon: "5.73141300067454" },
      { lat: "58.9655346", lon: "5.7315778" },
      { lat: "58.9655346", lon: "5.7315778" },
      { lat: "58.9655274", lon: "5.7315953" },
      { lat: "58.9655208", lon: "5.7316191" },
      { lat: "58.9655156", lon: "5.7316641" },
      { lat: "58.9655138", lon: "5.7316902" },
      { lat: "58.9655138", lon: "5.7316902" },
      { lat: "58.9655164", lon: "5.7317385" },
      { lat: "58.9655249", lon: "5.7317816" },
      { lat: "58.9655393", lon: "5.731818" },
      { lat: "58.9655393", lon: "5.731818" },
      { lat: "58.9655647", lon: "5.7318566" },
      { lat: "58.9655895", lon: "5.7318744" },
      { lat: "58.9656006", lon: "5.7318784" },
      { lat: "58.9656006", lon: "5.7318784" },
      { lat: "58.9656079", lon: "5.7319289" },
      { lat: "58.9656453", lon: "5.732085" },
      { lat: "58.9656809", lon: "5.7322521" },
      { lat: "58.9656809", lon: "5.7322521" },
      { lat: "58.9657066", lon: "5.7323956" },
      { lat: "58.9657522", lon: "5.7326387" },
      { lat: "58.9657543194227", lon: "5.73265234634187" },
      { lat: "58.9657775", lon: "5.7328016" },
      { lat: "58.9657846", lon: "5.7328354" },
      { lat: "58.9657846", lon: "5.7328354" },
      { lat: "58.9658252", lon: "5.7330596" },
      { lat: "58.9658252", lon: "5.7330596" },
      { lat: "58.965811", lon: "5.7330964" },
      { lat: "58.9658052", lon: "5.733123" },
      { lat: "58.9658052", lon: "5.733123" },
      { lat: "58.9658018", lon: "5.733164" },
      { lat: "58.9658042", lon: "5.7332051" },
      { lat: "58.9658123", lon: "5.7332435" },
      { lat: "58.9658241", lon: "5.733273" },
      { lat: "58.9658241", lon: "5.733273" },
      { lat: "58.965841", lon: "5.7332985" },
      { lat: "58.9658608", lon: "5.7333139" },
      { lat: "58.9658804", lon: "5.733319" },
      { lat: "58.9658804", lon: "5.733319" },
      { lat: "58.9660008033144", lon: "5.73394366995799" },
      { lat: "58.9660223", lon: "5.7340552" },
      { lat: "58.9660496", lon: "5.7341541" },
      { lat: "58.9660799", lon: "5.7341967" },
      { lat: "58.9660799", lon: "5.7341967" },
      { lat: "58.9661048", lon: "5.7343216" },
      { lat: "58.9661048", lon: "5.7343216" },
      { lat: "58.966163", lon: "5.7346221" },
      { lat: "58.966163", lon: "5.7346221" },
      { lat: "58.9662342", lon: "5.7350794" },
      { lat: "58.9662342", lon: "5.7350794" },
      { lat: "58.9662824107802", lon: "5.73536078560754" },
      { lat: "58.9663058", lon: "5.7354973" },
      { lat: "58.9663358", lon: "5.7356627" },
      { lat: "58.9663651", lon: "5.7358383" },
      { lat: "58.9665153", lon: "5.7366729" },
      { lat: "58.9665748", lon: "5.7370081" },
      { lat: "58.9666336", lon: "5.7373375" },
      { lat: "58.9666644", lon: "5.7375165" },
      { lat: "58.9667119", lon: "5.7377766" },
      { lat: "58.9667882", lon: "5.7381863" },
      { lat: "58.9668556", lon: "5.7385152" },
      { lat: "58.9668921", lon: "5.7386799" },
      { lat: "58.9669695", lon: "5.7390016" },
      { lat: "58.9670112", lon: "5.7391609" },
      { lat: "58.9671006", lon: "5.739468" },
      { lat: "58.9671488", lon: "5.7396152" },
      { lat: "58.9672535", lon: "5.7398927" },
      { lat: "58.9673068", lon: "5.7400383" },
      { lat: "58.9674203", lon: "5.7403085" },
      { lat: "58.9675416", lon: "5.7405726" },
      { lat: "58.9676694", lon: "5.7408214" },
      { lat: "58.9677344", lon: "5.7409405" },
      { lat: "58.9678026", lon: "5.7410585" },
      { lat: "58.9679409", lon: "5.7412806" },
      { lat: "58.9680862", lon: "5.7414916" },
      { lat: "58.9681612", lon: "5.7415909" },
      { lat: "58.9683144", lon: "5.741779" },
      { lat: "58.9684701", lon: "5.7419571" },
      { lat: "58.9685521", lon: "5.7420368" },
      { lat: "58.9687158", lon: "5.7421865" },
      { lat: "58.9688795", lon: "5.7423209" },
      { lat: "58.9690535", lon: "5.7424372" },
      { lat: "58.9691393", lon: "5.742489" },
      { lat: "58.9693135", lon: "5.7425822" },
      { lat: "58.9694028", lon: "5.7426222" },
      { lat: "58.9701059", lon: "5.7429091" },
      { lat: "58.9703734", lon: "5.7430149" },
      { lat: "58.9704785", lon: "5.743059" },
      { lat: "58.9704785", lon: "5.743059" },
      { lat: "58.9705233", lon: "5.7430759" },
      { lat: "58.9705415", lon: "5.7430804" },
      { lat: "58.9706365", lon: "5.7431195" },
      { lat: "58.9706365", lon: "5.7431195" },
      { lat: "58.97069", lon: "5.7431876" },
      { lat: "58.9709111", lon: "5.7432748" },
      { lat: "58.9709111", lon: "5.7432748" },
      { lat: "58.9710182", lon: "5.7433317" },
      { lat: "58.9710182", lon: "5.7433317" },
      { lat: "58.9710204", lon: "5.7433552" },
      { lat: "58.9710275", lon: "5.7433917" },
      { lat: "58.9710432", lon: "5.743433" },
      { lat: "58.9710631", lon: "5.7434591" },
      { lat: "58.971083", lon: "5.7434712" },
      { lat: "58.9711003", lon: "5.7434735" },
      { lat: "58.9711003", lon: "5.7434735" },
      { lat: "58.971118", lon: "5.7434703" },
      { lat: "58.971142", lon: "5.74345" },
      { lat: "58.971155", lon: "5.7434307" },
      { lat: "58.9711634", lon: "5.7434092" },
      { lat: "58.9711634", lon: "5.7434092" },
      { lat: "58.971173", lon: "5.7433786" },
      { lat: "58.9711768", lon: "5.7433566" },
      { lat: "58.9711791", lon: "5.7433262" },
      { lat: "58.9711779", lon: "5.7432845" },
      { lat: "58.9711779", lon: "5.7432845" },
      { lat: "58.9712847", lon: "5.7428616" },
      { lat: "58.9713138", lon: "5.7427346" },
      { lat: "58.9713138", lon: "5.7427346" },
      { lat: "58.971336", lon: "5.7426332" },
      { lat: "58.9713549", lon: "5.7424973" },
      { lat: "58.9713549", lon: "5.7424973" },
      { lat: "58.9713920739579", lon: "5.74219202655754" },
      { lat: "58.9714096", lon: "5.7420481" },
      { lat: "58.971461", lon: "5.7416155" },
      { lat: "58.9715055", lon: "5.7412513" },
      { lat: "58.9715351", lon: "5.7410244" },
      { lat: "58.9715479228032", lon: "5.74090152112859" },
      { lat: "58.9715526", lon: "5.7408567" },
      { lat: "58.9715628", lon: "5.7407121" },
      { lat: "58.9715681", lon: "5.7405617" },
      { lat: "58.9715654", lon: "5.7403925" },
      { lat: "58.971555", lon: "5.7401992" },
      { lat: "58.9715489866804", lon: "5.73995007281049" },
      { lat: "58.9715466", lon: "5.7398512" },
      { lat: "58.9715466", lon: "5.7398512" },
      { lat: "58.9715678", lon: "5.7398294" },
      { lat: "58.9715759", lon: "5.7398133" },
      { lat: "58.9715868", lon: "5.7397824" },
      { lat: "58.9715916", lon: "5.7397559" },
      { lat: "58.9715934", lon: "5.7397208" },
      { lat: "58.9715921", lon: "5.7396739" },
      { lat: "58.9715875", lon: "5.7396451" },
      { lat: "58.9715833", lon: "5.7396289" },
      { lat: "58.9715833", lon: "5.7396289" },
      { lat: "58.971562", lon: "5.7395663" },
      { lat: "58.9715493", lon: "5.7395417" },
      { lat: "58.9715493", lon: "5.7395417" },
      { lat: "58.9715693", lon: "5.7390066" },
      { lat: "58.9715698340572", lon: "5.73898027934421" },
      { lat: "58.9715728", lon: "5.7388341" },
      { lat: "58.9715725", lon: "5.738671" },
      { lat: "58.971558", lon: "5.7383308" },
      { lat: "58.971558", lon: "5.7383308" },
      { lat: "58.9715535777025", lon: "5.7380414163201" },
      { lat: "58.9715518", lon: "5.7379251" },
      { lat: "58.9715555", lon: "5.7378558" },
      { lat: "58.9715675", lon: "5.7377226" },
      { lat: "58.9715751", lon: "5.7376585" },
      { lat: "58.9715861", lon: "5.7375911" },
      { lat: "58.9716516", lon: "5.7372767" },
      { lat: "58.9716794276886", lon: "5.73715880939948" },
      { lat: "58.9717218", lon: "5.7369793" },
      { lat: "58.9717689", lon: "5.7368047" },
      { lat: "58.9717846", lon: "5.7367286" },
      { lat: "58.9717846", lon: "5.7367286" },
      { lat: "58.9717984", lon: "5.7367208" },
      { lat: "58.9718125", lon: "5.7367076" },
      { lat: "58.9718244", lon: "5.736692" },
      { lat: "58.9718343", lon: "5.7366721" },
      { lat: "58.9718343", lon: "5.7366721" },
      { lat: "58.9718446", lon: "5.7366367" },
      { lat: "58.9718503", lon: "5.7365973" },
      { lat: "58.9718511", lon: "5.7365766" },
      { lat: "58.9718497", lon: "5.7365509" },
      { lat: "58.9718497", lon: "5.7365509" },
      { lat: "58.9718454", lon: "5.7365031" },
      { lat: "58.9718366", lon: "5.7364687" },
      { lat: "58.971829", lon: "5.7364515" },
      { lat: "58.971829", lon: "5.7364515" },
      { lat: "58.9718182", lon: "5.7364298" },
      { lat: "58.9718091", lon: "5.7364176" },
      { lat: "58.9717965", lon: "5.7364069" },
      { lat: "58.9717713", lon: "5.7364065" },
      { lat: "58.9717638", lon: "5.7364095" },
      { lat: "58.9717638", lon: "5.7364095" },
      { lat: "58.9717232", lon: "5.7364331" },
      { lat: "58.9717137", lon: "5.7364442" },
      { lat: "58.9717137", lon: "5.7364442" },
      { lat: "58.9715679", lon: "5.7363344" },
      { lat: "58.9715251", lon: "5.7363513" },
      { lat: "58.9715251", lon: "5.7363513" },
      { lat: "58.9714598", lon: "5.736288" },
      { lat: "58.971359", lon: "5.7361969" },
      { lat: "58.97133", lon: "5.7361654" },
      { lat: "58.97133", lon: "5.7361654" },
      { lat: "58.9712509", lon: "5.7360874" },
      { lat: "58.9711155", lon: "5.7359616" }
    ],
    totalMeters: 30000
  };
};
