import React, { Suspense } from "react";
import Spinner from "../common/Spinner";
import "../../styles/eula.css";

const PrivacyPageContent = React.lazy(() => import("./PrivacyPageContent"));

class PrivacyPage extends React.Component {
  render() {
    return (
      <div className="eula-outer">
        <div className="eula-inner">
          <Suspense
            fallback={
              <div>
                <Spinner size="4em" />
              </div>
            }
          >
            <PrivacyPageContent />
          </Suspense>
        </div>
      </div>
    );
  }
}

export default PrivacyPage;
