import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import moment from "moment";
import ReportSegmentList from "../reportSegment/ReportSegmentList";
import ReportSegmentEditor from "../reportSegment/ReportSegmentEditor";
import Icon from "../common/Icon";
import { capitalize } from "../../shared/utils/helpers";
import "../../styles/report.css";
import { ReportSegment } from "../../shared/types";

interface ReportSegmentsProps {
  segmentList: ReportSegment[];
  createSegment: (fromDate: string, toDate: string) => ReportSegment;
  onChange: (segments: ReportSegment[], forcePersist?: boolean) => void;
  readOnly?: boolean;
  reportTypeId: number;
}
const ReportSegments = ({ segmentList, createSegment, onChange, readOnly, reportTypeId }: ReportSegmentsProps) => {
  const [t] = useTranslation();

  const [editMode, setEditMode] = useState(false);
  const [currentSegmentToEdit, setCurrentSegmentToEdit] = useState<ReportSegment | null>(null);

  // Add a new segment to the list and switch to the editor
  const onAddSegment = () => {
    const segmentListNew = [...segmentList];
    // Default to today's date..
    let date = moment().toISOString();
    // .. override with the latest existing date in the segment list
    if (segmentListNew.length > 0)
      date = segmentListNew
        .map((o) => o.stops)
        .sort()
        .reverse()[0];

    // Parent will construct the segment for us, including the dirty flag
    const segment = createSegment(date, date);

    // But we'll insert it into the list ourselves
    segmentListNew.push(segment);
    onChange(segmentListNew);
    setCurrentSegmentToEdit(segment);
    setEditMode(true);
  };

  // Merge a single changed segment into the list and throw it up the chain
  const onChangeSegment = (partialSegment: Partial<ReportSegment>) => {
    if (!currentSegmentToEdit) return;
    const now = moment().toISOString();
    const segmentListNew = [...segmentList];
    const oldSegment = segmentListNew.find((o) => o.uuid === currentSegmentToEdit.uuid);
    if (!oldSegment) return;
    const index = segmentListNew.findIndex((o) => o.uuid === currentSegmentToEdit.uuid);

    const updatedSegment: ReportSegment = {
      ...oldSegment,
      ...partialSegment,
      reportUuid: currentSegmentToEdit.reportUuid,
      dirty: now
    };
    segmentListNew[index] = updatedSegment;
    segmentListNew.sort((a, b) => moment(a.starts).unix() - moment(b.starts).unix());

    onChange(segmentListNew);
  };

  // Load a segment into the editor and switch to the editor
  const onSwitchSegmentToEdit = (segment: ReportSegment) => {
    const currentSegmentToEdit = segmentList.find((o) => o.uuid === segment.uuid);
    if (currentSegmentToEdit) {
      setCurrentSegmentToEdit(currentSegmentToEdit);
      setEditMode(true);
    }
  };

  // Delete a segment from the list
  const onDeleteSegment = (segment: ReportSegment) => {
    const now = moment().toISOString();
    let segmentListNew = [...segmentList];
    const oldSegment = segmentListNew.find((o) => o.uuid === segment.uuid);
    if (!oldSegment) return;
    const index = segmentListNew.findIndex((o) => o.uuid === segment.uuid);
    segmentListNew[index] = { ...oldSegment, deleted: now, dirty: now };
    segmentListNew.sort((a, b) => moment(a.starts).unix() - moment(b.starts).unix());
    onChange(segmentListNew);
  };

  return (
    <div className="report-component" id="reportSegments">
      <div className="report-component-header">
        <div className="report-component-title">{t("reportSegment.routeAndTimespan")}</div>

        <div className="header-buttons">
          <Button onClick={() => onAddSegment()} disabled={readOnly} id="reportNewSegment">
            <Icon icon="add" />
            <span>{capitalize(t("new"))}</span>
          </Button>
          <Button
            bsStyle="link"
            onClick={() => {
              setEditMode(false);
              setCurrentSegmentToEdit(null);
            }}
            id="reportAllSegments"
          >
            {capitalize(t("showAll"))} ({segmentList.filter((o) => !o.deleted).length.toString()})
          </Button>
        </div>
      </div>

      {editMode && currentSegmentToEdit && (
        <ReportSegmentEditor segment={currentSegmentToEdit} readOnly={readOnly} reportTypeId={reportTypeId} onChange={onChangeSegment} />
      )}

      {!editMode && (
        <ReportSegmentList
          segments={segmentList.filter((o) => !o.deleted)}
          readOnly={!!readOnly}
          onSwitchSegmentToEdit={onSwitchSegmentToEdit}
          onDeleteSegment={onDeleteSegment}
        />
      )}
    </div>
  );
};

export default ReportSegments;
