import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, FormControl } from "react-bootstrap";
import ApprovalAttemptTimeline from "./ApprovalAttemptTimeline";
import { capitalize, formatMoney } from "../../shared/utils/helpers";
import { ClassicApprovalAttempt } from "../../shared/types";
import ButtonWithSpinner from "../common/ButtonWithSpinner";

interface ApprovalAttemptListItemProps {
  approvalAttempt: ClassicApprovalAttempt;
  previousAttempts: ClassicApprovalAttempt[];
  onDecide: (approved: boolean, reportUuid: string, approvalAttemptUuid: string, comment: string) => void;
  onView: (reportUuid: string, approvalAttemptUuid: string) => void;
  disabled?: boolean;
  active?: boolean;
  isDeciding?: boolean;
}
const ApprovalAttemptListItem = ({
  approvalAttempt,
  previousAttempts,
  onDecide,
  onView,
  disabled,
  active,
  isDeciding
}: ApprovalAttemptListItemProps) => {
  const [t] = useTranslation();
  const [approvalComment, setApprovalComment] = useState("");
  const [showAll, setShowAll] = useState(false);

  // Contents of the linkbutton: report description, id and sum, userinfo
  const descriptionLinkDescription = approvalAttempt.reportDescription ? approvalAttempt.reportDescription : t("noDescription");
  const descriptionLinkIdAndValue = (
    <span className="gray">{`(ID ${approvalAttempt.reportId} / kr ${
      approvalAttempt.reportCalculatedSum ? formatMoney(approvalAttempt.reportCalculatedSum) : 0
    })`}</span>
  );
  const descriptionLinkFrom = `${t("from")} ${approvalAttempt.owner.name} / ${approvalAttempt.owner.email}`;

  const descriptionLink = (
    <button className="fakelink" onClick={() => onView(approvalAttempt.reportUuid, approvalAttempt.approvalAttemptUuid)}>
      {descriptionLinkDescription}
      &nbsp;
      {descriptionLinkIdAndValue}
      &nbsp;
      {descriptionLinkFrom}
    </button>
  );

  return (
    <div className="reportapproval-list-item">
      <div className="reportapproval-description">
        {descriptionLink} {disabled && active && <div className="loading25">&nbsp;</div>}
      </div>

      <div className="reportapproval-list-item-timeline">
        <ApprovalAttemptTimeline approvalAttempt={approvalAttempt} />
      </div>

      {previousAttempts.length > 0 && (
        <div>
          {!showAll && (
            <div>
              <Button bsStyle="link" onClick={() => setShowAll(true)}>
                {t("showCountPreviousApprovalAttempts", {
                  count: previousAttempts.length
                })}
              </Button>
            </div>
          )}
          {showAll &&
            previousAttempts.map((o) => (
              <div className="reportapproval-list-item-timeline" key={o.approvalAttemptUuid}>
                <ApprovalAttemptTimeline approvalAttempt={o} />
              </div>
            ))}
        </div>
      )}

      {active && (
        <div className="caa-form">
          <FormControl
            type={"text"}
            componentClass={"textarea"}
            placeholder={t("yourComment")}
            onChange={(e: React.FormEvent<FormControl & HTMLInputElement>) => setApprovalComment(e.currentTarget.value)}
          />
          <ButtonWithSpinner
            bsStyle="danger"
            disabled={disabled}
            showSpinner={isDeciding}
            onClick={() => onDecide(false, approvalAttempt.reportUuid, approvalAttempt.approvalAttemptUuid, approvalComment)}
            title={capitalize(t("disapprove"))}
          >
            {capitalize(t("disapprove"))}
          </ButtonWithSpinner>
          <ButtonWithSpinner
            bsStyle="success"
            disabled={disabled}
            showSpinner={isDeciding}
            onClick={() => onDecide(true, approvalAttempt.reportUuid, approvalAttempt.approvalAttemptUuid, approvalComment)}
            title={capitalize(t("approve"))}
          >
            {capitalize(t("approve"))}
          </ButtonWithSpinner>
        </div>
      )}
    </div>
  );
};

export default ApprovalAttemptListItem;
