import { useTranslation } from "react-i18next";
import { NavItem, Nav, Badge } from "react-bootstrap";
import { LinkContainer, IndexLinkContainer } from "react-router-bootstrap";
import Icon from "./common/Icon";
import "../styles/sidebar.css";

import { usePendingApprovalCount, useUserConfiguration } from "../shared/queries/queries";

// Sidebar menu
const SideBar = () => {
  const [t] = useTranslation();

  const pendingApprovalCountQuery = usePendingApprovalCount();
  const todoCount = pendingApprovalCountQuery.data || 0;

  const userConfigurationQuery = useUserConfiguration();
  let hideMileage = false;
  if (userConfigurationQuery?.data?.configuration?.product?.uxPolicy?.general?.hideMileageView) hideMileage = true;

  return (
    <>
      <div id="sidebar-drawer">
        <Nav>
          <IndexLinkContainer to="/" title={t("navBar.overview")} id="navbar_Overview">
            <NavItem>
              <Icon icon="home" />
              <br />
              <div className="sidebar-link-text">{t("navBar.overview")}</div>
            </NavItem>
          </IndexLinkContainer>

          <LinkContainer to="/reports" title={t("navBar.reports")} id="navbar_Reports">
            <NavItem>
              <Icon icon="reports" />
              <div className="sidebar-link-text">{t("navBar.reports")}</div>
            </NavItem>
          </LinkContainer>

          <LinkContainer to="/expenses" title={t("navBar.expenses")} id="navbar_Expenses">
            <NavItem>
              <Icon icon="expenseReport" />
              <div className="sidebar-link-text">{t("navBar.expenses")}</div>
            </NavItem>
          </LinkContainer>

          {!hideMileage && (
            <LinkContainer to="/mileages" title={t("navBar.mileages")} id="navbar_Mileages">
              <NavItem>
                <Icon icon="drivingReport" />
                <div className="sidebar-link-text">{t("navBar.mileages")}</div>
              </NavItem>
            </LinkContainer>
          )}

          <LinkContainer to="/inbox" title={t("navBar.inbox")} id="navbar_Inbox">
            <NavItem>
              <Icon icon="email" />
              <div className="sidebar-link-text">{t("navBar.inbox")}</div>
            </NavItem>
          </LinkContainer>

          <LinkContainer to="/approval" title={t("navBar.approval")} id="navbar_Approval">
            <NavItem>
              <Icon icon="approvals" />
              <div className="sidebar-link-text">{t("navBar.approval")}</div>
              {todoCount > 0 && <Badge>{todoCount}</Badge>}
            </NavItem>
          </LinkContainer>

          <LinkContainer to="/about" title={t("navBar.about")} id="navbar_About">
            <NavItem>
              <Icon icon="information" />
              <div className="sidebar-link-text">{t("navBar.about")}</div>
            </NavItem>
          </LinkContainer>
        </Nav>
      </div>
    </>
  );
};

export default SideBar;
