import React, { Suspense } from "react";
import { withTranslation } from "react-i18next";
import Spinner from "../common/Spinner";
import "../../styles/eula.css";

const DataProcessorPageContent = React.lazy(() => import("./DataProcessorPageContent"));

const DataProcessorPage = () => {
  return (
    <Suspense
      fallback={
        <div>
          <Spinner size="4em" />
        </div>
      }
    >
      <DataProcessorPageContent />
    </Suspense>
  );
};

export default withTranslation()(DataProcessorPage);
