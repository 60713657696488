import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import CustomFieldItem from "./CustomFieldItem";
import { createExpenseCustomValue } from "../../shared/utils/expenseUtils";
import { createReportCustomValue } from "../../shared/utils/reportUtils";
import "../../styles/customfield.css";
import { ReportCustomField, ExpenseCustomField, ReportCustomValue, ExpenseCustomValue, ValidateCustomFieldError } from "../../shared/types";
import Icon from "../common/Icon";
import { Button } from "react-bootstrap";
import { cloneDeep } from "lodash";
import { useUserConfiguration } from "../../shared/queries/queries";

// A wrapper block for all customfields on either a report or an expense
interface CustomFieldsProps {
  reportUuid?: string;
  expenseUuid?: string;
  customFields: (ReportCustomField | ExpenseCustomField)[];
  customValues: (ReportCustomValue | ExpenseCustomValue)[];
  readOnly?: boolean;
  onChangeCustomValues: (customValues: (ReportCustomValue | ExpenseCustomValue)[], fromConstructor?: boolean) => void;
  validationErrors?: ValidateCustomFieldError[];
}
const CustomFields = ({
  reportUuid,
  expenseUuid,
  customFields,
  customValues,
  readOnly,
  onChangeCustomValues,
  validationErrors
}: CustomFieldsProps) => {
  const [t] = useTranslation();
  const userConfigurationQuery = useUserConfiguration();

  const [currentCustomValues, setCurrentCustomValues] = useState<(ReportCustomValue | ExpenseCustomValue)[]>([]);

  // Grab and parse UX policy for expense customfields
  // If we ever add global customfields for reports, we want to add that here too
  const uxPolicyCustomFields = userConfigurationQuery.data?.configuration?.product?.uxPolicy?.expense?.customFields;
  let hiddenSystemNames: string[] = [];
  let disabledSystemNames: string[] = [];
  let requiredSystemNames: string[] = [];
  let policyDefaultValues: any = {};
  if (uxPolicyCustomFields) {
    for (const [key, val] of Object.entries(uxPolicyCustomFields)) {
      if (val.hide) hiddenSystemNames.push(key);
      if (val.readOnly) disabledSystemNames.push(key);
      if (val.required) requiredSystemNames.push(key);
      if (val.useDefaultValue) policyDefaultValues[key] = val.defaultValue;
    }
  }

  // Tweak incoming customfields before doing anything else with them
  const preppedCustomFields = (): (ReportCustomField | ExpenseCustomField)[] => {
    let preppedFields = [...customFields];

    // Add a "Choose..." first option to choice-fields with a blank value
    // Override required if policy has set it to true, and inject defaultValues from policy
    preppedFields = preppedFields.map((field) => {
      const required = field.required || requiredSystemNames.includes(field.systemName);
      const defaultValue = policyDefaultValues[field.systemName] || field.defaultValue;
      if (field.fieldType === "choice") {
        const choices = `!!!${t("choose")}...\n${field.choices}`;
        return { ...field, choices, required, defaultValue };
      } else {
        return { ...field, required, defaultValue };
      }
    });

    // Reorder/group known global fields to render them more user friendly
    // These are known field systemnames and the order we want to sort them in before rendering
    const systemNamesSortOrder = [
      "taxi_from",
      "taxi_to",
      "airplane_from",
      "airplane_to",
      "train_from",
      "train_to",
      "othertransport_from",
      "othertransport_to",
      "driving_fromaddress",
      "driving_toaddress",
      "driving_passengers",
      "driving_passengernames",
      "driving_electriccar",
      "driving_commuter",
      "driving_hanger",
      "driving_forestroad",
      "driving_boatover50hk",
      "driving_othertransports",
      "driving_tromsoextra",
      "driving_otherexpenses",
      "driving_otherexpensesdesc",
      "driving_motorcycle",
      "hotel_name",
      "driving_taxfree_part",
      "driving_taxable_part"
    ];

    // Add known fields to a new array in the order specified
    let preppedFieldsSorted: (ReportCustomField | ExpenseCustomField)[] = [];
    for (const systemName of systemNamesSortOrder) {
      preppedFieldsSorted.push(...preppedFields.filter((o) => o.systemName === systemName));
      preppedFields = preppedFields.filter((o) => o.systemName !== systemName);
    }
    // Add the remaining/unspecified fields, typically user-defined ones
    preppedFieldsSorted.push(...preppedFields);
    // Return the sorted result
    return preppedFieldsSorted;
  };

  // "Constructor", runs whenever customfields changes to set default values
  useEffect(() => {
    // Loop over all enabled fields and construct default values for any enabled fields that are missing values in props
    // This means selecting the first value in multiple choice fields and default values if the fields has them
    const defaultValues: (ReportCustomValue | ExpenseCustomValue)[] = [];
    preppedCustomFields()
      .filter((customField) => customField.enabled)
      .forEach((customField) => {
        // Check for either a defined default value or any "inherent" default value (like the first option in a multiple choice field)
        let defaultValue = undefined;
        if (customField.defaultValue) {
          defaultValue = customField.defaultValue;
        } else if (customField.fieldType === "choice" && customField.choices.length > 0) {
          const choices = (customField.choices ? customField.choices.match(/[^\r\n]+/g) || [""] : [""]).map((o) => {
            if (o.indexOf("!!!") > -1) {
              const a = o.split("!!!");
              return { value: a[0], label: a[1] };
            } else if (o.startsWith("+++")) {
              return { label: o.substring(3), disabled: true };
            }
            return { value: o, label: o };
          });
          const firstEnabled = choices.find((o) => !o.disabled);
          if (firstEnabled) defaultValue = firstEnabled.value;
        }

        // If we managed to generate a default value, check if we need to inject it into state
        if (defaultValue !== undefined) {
          if (
            reportUuid &&
            "reportType" in customField &&
            customField.reportType !== undefined &&
            customValues.findIndex(
              (o: ReportCustomValue | ExpenseCustomValue) => "reportCustomFieldId" in o && o.reportCustomFieldId === customField.id
            ) === -1
          ) {
            // Report customfield with missing value found, inject default value
            const newValue = createReportCustomValue(reportUuid, customField.id, defaultValue);
            defaultValues.push(newValue);
          } else if (
            expenseUuid &&
            "expenseTypeId" in customField &&
            customField.expenseTypeId !== undefined &&
            customValues.findIndex(
              (o: ReportCustomValue | ExpenseCustomValue) => "expenseCustomFieldId" in o && o.expenseCustomFieldId === customField.id
            ) === -1
          ) {
            // Expense customfield with missing value found, inject default value
            const newValue = createExpenseCustomValue(expenseUuid, customField.id, defaultValue);
            defaultValues.push(newValue);
          }
        }
      });
    // Populate the local state with the customvalues we got through props, and the values we just constructed
    const customValuesNew = [...customValues, ...defaultValues];
    setCurrentCustomValues(customValuesNew);
    if (defaultValues.length > 0) {
      // If we just constructed any values internally, toss them upstream so the editor is aware of them
      // This prevents validation errors for required customfields that has a default value, even if the user does not change them
      // Ref TT-1223
      onChangeCustomValues(customValuesNew, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customFields]);

  // Handles changing a field value
  const onChangeCustomValue = (customField: ReportCustomField | ExpenseCustomField, value: string) => {
    const customValuesNew = cloneDeep(currentCustomValues);
    const preppedFields = preppedCustomFields();
    const now = moment().toISOString();
    if (reportUuid && "reportType" in customField && customField.reportType !== undefined) {
      // Look for existing values based on reportCustomFieldId, update/insert accordingly
      const index = customValuesNew.findIndex((o) => "reportCustomFieldId" in o && o.reportCustomFieldId === customField.id);
      if (index > -1) {
        // Update
        customValuesNew[index].value = value;
        customValuesNew[index].dirty = now;
      } else {
        // Insert
        const newValue = createReportCustomValue(reportUuid, customField.id, value);
        customValuesNew.push(newValue);
      }
    } else if (expenseUuid && "expenseTypeId" in customField && customField.expenseTypeId !== undefined) {
      // Look for existing values based on expenseCustomFieldId, update/insert accordingly
      const index = customValuesNew.findIndex((o) => "expenseCustomFieldId" in o && o.expenseCustomFieldId === customField.id);
      if (index > -1) {
        // Update
        customValuesNew[index].value = value;
        customValuesNew[index].dirty = now;
      } else {
        // Insert
        const newValue = createExpenseCustomValue(expenseUuid, customField.id, value);
        customValuesNew.push(newValue);
      }

      // MAGIC DRIVING BEHAVIOUR
      //
      // Some customfields for mileage expenses should affect each other.
      // We use field.systemName to identify these special fields.
      // This ruleset will change over time as politicians have bright ideas.
      // Future field interaction also goes here.
      //
      // * Checking any of the following 5 fields should uncheck the other 4: driving_electriccar, driving_commuter, driving_boatover50hk, driving_othertransports, driving_motorcycle
      // * Checking driving_boatover50hk should also uncheck: driving_hanger, driving_forestroad, driving_tromsoextra
      // (There are other driving customfields, but these are the only ones with internal interaction)

      const field_electriccar = preppedFields.find((o) => o.systemName === "driving_electriccar");
      const field_commuter = preppedFields.find((o) => o.systemName === "driving_commuter");
      const field_boatover50hk = preppedFields.find((o) => o.systemName === "driving_boatover50hk");
      const field_othertransports = preppedFields.find((o) => o.systemName === "driving_othertransports");
      const field_motorcycle = preppedFields.find((o) => o.systemName === "driving_motorcycle");
      const field_hanger = preppedFields.find((o) => o.systemName === "driving_hanger");
      const field_forestroad = preppedFields.find((o) => o.systemName === "driving_forestroad");
      const field_tromsoextra = preppedFields.find((o) => o.systemName === "driving_tromsoextra");

      if (
        field_electriccar &&
        field_commuter &&
        field_boatover50hk &&
        field_othertransports &&
        field_motorcycle &&
        field_hanger &&
        field_forestroad &&
        field_tromsoextra
      ) {
        const index_electriccar = customValuesNew.findIndex((o) => "expenseCustomFieldId" in o && o.expenseCustomFieldId === field_electriccar.id);
        const index_commuter = customValuesNew.findIndex((o) => "expenseCustomFieldId" in o && o.expenseCustomFieldId === field_commuter.id);
        const index_boatover50hk = customValuesNew.findIndex((o) => "expenseCustomFieldId" in o && o.expenseCustomFieldId === field_boatover50hk.id);
        const index_othertransports = customValuesNew.findIndex(
          (o) => "expenseCustomFieldId" in o && o.expenseCustomFieldId === field_othertransports.id
        );
        const index_motorcycle = customValuesNew.findIndex((o) => "expenseCustomFieldId" in o && o.expenseCustomFieldId === field_motorcycle.id);
        const index_hanger = customValuesNew.findIndex((o) => "expenseCustomFieldId" in o && o.expenseCustomFieldId === field_hanger.id);
        const index_forestroad = customValuesNew.findIndex((o) => "expenseCustomFieldId" in o && o.expenseCustomFieldId === field_forestroad.id);
        const index_tromsoextra = customValuesNew.findIndex((o) => "expenseCustomFieldId" in o && o.expenseCustomFieldId === field_tromsoextra.id);

        // Should we uncheck driving_electriccar?
        if (value && ["driving_commuter", "driving_boatover50hk", "driving_othertransports", "driving_motorcycle"].includes(customField.systemName)) {
          if (index_electriccar > -1) {
            customValuesNew[index_electriccar].value = "0";
            customValuesNew[index_electriccar].dirty = now;
          } else {
            customValuesNew.push(createExpenseCustomValue(expenseUuid, field_electriccar.id, "0"));
          }
        }

        // Should we uncheck driving_commuter?
        if (
          value &&
          ["driving_electriccar", "driving_boatover50hk", "driving_othertransports", "driving_motorcycle"].includes(customField.systemName)
        ) {
          if (index_commuter > -1) {
            customValuesNew[index_commuter].value = "0";
            customValuesNew[index_commuter].dirty = now;
          } else {
            customValuesNew.push(createExpenseCustomValue(expenseUuid, field_commuter.id, "0"));
          }
        }

        // Should we uncheck driving_boatover50hk?
        if (
          value &&
          [
            "driving_electriccar",
            "driving_commuter",
            "driving_othertransports",
            "driving_motorcycle",
            "driving_hanger",
            "driving_forestroad",
            "driving_tromsoextra"
          ].includes(customField.systemName)
        ) {
          if (index_boatover50hk > -1) {
            customValuesNew[index_boatover50hk].value = "0";
            customValuesNew[index_boatover50hk].dirty = now;
          } else {
            customValuesNew.push(createExpenseCustomValue(expenseUuid, field_boatover50hk.id, "0"));
          }
        }

        // Should we uncheck driving_othertransports?
        if (value && ["driving_electriccar", "driving_commuter", "driving_boatover50hk", "driving_motorcycle"].includes(customField.systemName)) {
          if (index_othertransports > -1) {
            customValuesNew[index_othertransports].value = "0";
            customValuesNew[index_othertransports].dirty = now;
          } else {
            customValuesNew.push(createExpenseCustomValue(expenseUuid, field_othertransports.id, "0"));
          }
        }

        // Should we uncheck driving_motorcycle?
        if (
          value &&
          ["driving_electriccar", "driving_commuter", "driving_boatover50hk", "driving_othertransports"].includes(customField.systemName)
        ) {
          if (index_motorcycle > -1) {
            customValuesNew[index_motorcycle].value = "0";
            customValuesNew[index_motorcycle].dirty = now;
          } else {
            customValuesNew.push(createExpenseCustomValue(expenseUuid, field_motorcycle.id, "0"));
          }
        }

        // Should we uncheck driving_hanger, driving_forestroad, driving_tromsoextra due to driving_boatover50hk being checked?
        if (value && customField.systemName === "driving_boatover50hk") {
          if (index_hanger > -1) {
            customValuesNew[index_hanger].value = "0";
            customValuesNew[index_hanger].dirty = now;
          } else {
            customValuesNew.push(createExpenseCustomValue(expenseUuid, field_hanger.id, "0"));
          }
          if (index_forestroad > -1) {
            customValuesNew[index_forestroad].value = "0";
            customValuesNew[index_forestroad].dirty = now;
          } else {
            customValuesNew.push(createExpenseCustomValue(expenseUuid, field_forestroad.id, "0"));
          }
          if (index_tromsoextra > -1) {
            customValuesNew[index_tromsoextra].value = "0";
            customValuesNew[index_tromsoextra].dirty = now;
          } else {
            customValuesNew.push(createExpenseCustomValue(expenseUuid, field_tromsoextra.id, "0"));
          }
        }
      }
    }
    setCurrentCustomValues(customValuesNew);
    onChangeCustomValues(customValuesNew);
  };

  // Check if we have driving fields for from- and to-address, and swap their values
  const swapAddresses = () => {
    const customValuesNew = [...currentCustomValues];
    const fields = preppedCustomFields();
    const fromField = fields.find((o) => o.systemName === "driving_fromaddress");
    const toField = fields.find((o) => o.systemName === "driving_toaddress");
    if (fromField && toField) {
      const fromIndex = customValuesNew.findIndex((o) => "expenseCustomFieldId" in o && o.expenseCustomFieldId === fromField.id);
      const toIndex = customValuesNew.findIndex((o) => "expenseCustomFieldId" in o && o.expenseCustomFieldId === toField.id);
      if (fromIndex && toIndex) {
        const fromAddress = customValuesNew[fromIndex];
        const toAddress = customValuesNew[toIndex];
        // Only swap if we already have values for both fields
        if (fromAddress && toAddress) {
          const fromAddressValue = customValuesNew[fromIndex].value.toString() || "";
          const toAddressValue = customValuesNew[toIndex].value.toString() || "";
          if (fromAddressValue && toAddressValue) {
            customValuesNew[fromIndex].value = toAddressValue;
            customValuesNew[toIndex].value = fromAddressValue;
            setCurrentCustomValues(customValuesNew);
            onChangeCustomValues(customValuesNew);
          }
        }
      }
    }
  };

  const preppedFields = preppedCustomFields();

  // We will render a CustomFieldItem for a field if it is enabled, or if it has a value.
  // All values has a field (disabled or not), but not all fields has a value.
  // If a field is both disabled and missing a value, don't render it.
  return (
    <div className="customfields">
      {preppedFields.map((customField) => {
        let customValue;
        if ("reportType" in customField && customField.reportType !== undefined) {
          customValue = currentCustomValues.find((o) => "reportCustomFieldId" in o && o.reportCustomFieldId === customField.id);
        } else if ("expenseTypeId" in customField && customField.expenseTypeId !== undefined) {
          customValue = currentCustomValues.find((o) => "expenseCustomFieldId" in o && o.expenseCustomFieldId === customField.id);
        }
        const validationError = validationErrors && validationErrors.find((error) => error.fieldId === customField.id);
        const addonSwapAddresses =
          customField.systemName === "driving_fromaddress" ? (
            <Button onClick={() => swapAddresses()} title={t("swapFromToAddress")}>
              <Icon icon="swapHorizontal" />
            </Button>
          ) : undefined;
        return (
          (customField.enabled || customValue !== undefined) && (
            <div className={`customfield-wrapper ${hiddenSystemNames.includes(customField.systemName) ? "hidden" : ""}`} key={customField.id}>
              <CustomFieldItem
                customField={customField}
                customValue={customValue}
                readOnly={readOnly || disabledSystemNames.includes(customField.systemName)}
                onChangeCustomValue={onChangeCustomValue}
                validationError={validationError}
                addonRight={addonSwapAddresses}
              />
            </div>
          )
        );
      })}
    </div>
  );
};

export default CustomFields;
