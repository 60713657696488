import { useEffect, useState } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import { Row, Col, Button, Grid } from "react-bootstrap";
import Steps from "rc-steps";
import "rc-steps/assets/index.css";
import { reportConstructor } from "../../shared/constructors/reportConstructor";
import { reportSegmentConstructor } from "../../shared/constructors/reportSegmentConstructor";
import { useStore } from "../../shared/store/store";

import PageHeader, { PageHeaderPage } from "../common/PageHeader";
import ReportHeader from "./ReportHeader";
import ReportSegments from "./ReportSegments";
import CustomFields from "../customField/CustomFields";
import AttachmentPreview from "../attachment/AttachmentPreview";
import "../../styles/report.css";
import "../../styles/rc-steps.css";

import ReportAutoExpenseOverrides from "./ReportAutoExpenseOverrides";
import { calculateReport } from "../../shared/utils/reportCalculation/calculator";
import { validateReport } from "../../shared/validation/report";
import { AutoExpenseOverride, ExpenseCustomValue, Report, ReportCustomValue, ReportSegment, ValidateReportErrors } from "../../shared/types";
import {
  saveReport,
  useCountries,
  useMajorCities,
  useOfficialRates,
  useSeedExchangeRatesNB,
  useUserConfiguration
} from "../../shared/queries/queries";
import Spinner from "../common/Spinner";
import { showToast } from "../../utils/toastWrapper";
import { rateTypes } from "../../shared/utils/constants";
import { nullOrUndefined } from "../../shared/utils/helpers";
import { applyPolicyToNewReportSegment } from "../../shared/utils/uxPolicyHelpers";

// Create a new report
const ReportPageNew = () => {
  const [t] = useTranslation();
  const history = useHistory();
  const location = useLocation<{ reportType: 1 | 2 | 3 }>();
  const language = useStore((state) => state.language);
  const currentUserId = useStore((state) => state.currentUserId);

  const userConfigurationQuery = useUserConfiguration();
  const officialRatesQuery = useOfficialRates();
  const countriesQuery = useCountries();
  const majorCitiesQuery = useMajorCities();
  const seedExchangeRatesNBQuery = useSeedExchangeRatesNB();

  const [page, setPage] = useState<number>(1);
  const [currentReport, setCurrentReport] = useState<Report | null>(null);
  const [userId] = useState<number | undefined>(currentUserId);
  const [previousLanguage, setPreviousLanguage] = useState(language);
  const [showDietary, setShowDietary] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<ValidateReportErrors>({
    header: [],
    reportCustomFields: [],
    expenses: [],
    segments: []
  });
  const [persistQueued, setPersistQueued] = useState(false);

  // Redirect to homepage if user is switched
  useEffect(() => {
    if (currentUserId !== userId) history.push("/#");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserId]);

  // If validationErrors has changed, check if any elements failed validation and scroll them into view
  useEffect(() => {
    // If any elements failed validation, scroll them into view
    const failedElement = document.querySelector(".has-error"); // Report header
    if (failedElement)
      failedElement.scrollIntoView({
        behavior: "smooth",
        block: "center"
      });
  }, [validationErrors]);

  // If a persist has been queued, persist report to backend and redirect to reportpage
  useEffect(() => {
    if (persistQueued) {
      if (currentReport && validate(currentReport)) {
        saveReport(currentReport)
          .then(() => {
            history.push(`/report/${currentReport.uuid}`);
          })
          .catch((err: any) => {
            setPersistQueued(false);
            showToast({ type: "error", title: t("notifications.reportSaveFailed"), text: err?.backendMessage || "" });
          });
      } else {
        // Only reset persistQueued if we didn't save changes. We should stay in "persisting"-mode until the redirect has been completed and this component unmounted to avoid reenabling the editor while transitioning.
        setPersistQueued(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [persistQueued]);

  // When the language changes, the report is recalculated with the new language
  // Set the dirty flag so the changes are saved, but only if it differs from the initial/previous language, or this will fire on the initial render
  useEffect(() => {
    if (language !== previousLanguage) {
      setPreviousLanguage(language);
      if (currentReport !== null) {
        const report = { ...currentReport, dirty: now() };
        report.calculation = calculateReport(report, userConfiguration, officialRates, countries, majorCities, exchangeRatesNBSeed);
        setCurrentReport(report);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  // Bail out here if we don't have a valid user configuration or all the static data yet
  if (!userConfigurationQuery.data || !officialRatesQuery.data || !countriesQuery.data || !majorCitiesQuery.data || !seedExchangeRatesNBQuery.data) {
    console.log("ReportPageNew: No userconfig or static data, bailing out");
    return <Spinner size="75px" margin="1em" />;
    // return (
    //   <div>
    //     <div>Loading...</div>
    //     <div>userConfigurationQuery.data: {(!!userConfigurationQuery.data).toString()}</div>
    //     <div>officialRatesQuery.data: {(!!officialRatesQuery.data).toString()}</div>
    //     <div>countriesQuery.data: {(!!countriesQuery.data).toString()}</div>
    //     <div>majorCitiesQuery.data: {(!!majorCitiesQuery.data).toString()}</div>
    //   </div>
    // );
  }

  // Shorthands
  const userConfiguration = userConfigurationQuery.data.configuration;
  const personConfig = userConfiguration.person;
  const productConfig = userConfiguration.product;
  const officialRates = officialRatesQuery.data;
  const countries = countriesQuery.data;
  const majorCities = majorCitiesQuery.data;
  const exchangeRatesNBSeed = seedExchangeRatesNBQuery.data;

  const personId = personConfig.id;
  const companyName = personConfig.parentName;
  const defaultLocation = productConfig.defaultLocation;
  const reportCustomFields = productConfig.reportCustomFields.filter((o) => o.reportType === 0 || o.reportType === reportTypeId);
  const expenseCustomFields = productConfig.expenseCustomFields;
  const defaultCustomDietRate =
    (productConfig.defaultCustomDietRate && !isNaN(Number(productConfig.defaultCustomDietRate)) ? Number(productConfig.defaultCustomDietRate) : 0) *
    100;

  const reportTypeId: 1 | 2 | 3 = location.state ? location.state.reportType : 1;
  if (!reportTypeId || ![1, 2, 3].includes(reportTypeId)) return <div>&nbsp;{t("backendErrorCodes.30128")}</div>;

  const now = () => moment().toISOString();

  // If no report exists yet, stub it now
  if (!currentReport) {
    const starts = now();
    const newReport = reportConstructor({
      reportType: reportTypeId,
      personId: personId,
      companyName: companyName,
      countryName: defaultLocation.countryName,
      countryId: defaultLocation.countryId,
      cityName: defaultLocation.cityName,
      cityId: defaultLocation.cityId,
      rateCustomDiet: defaultCustomDietRate,
      starts,
      stops: starts
    });

    // Check UX policy to adjust defaults
    if (productConfig.uxPolicy) {
      // Ensure a disabled rateType is not set as default
      if (productConfig.uxPolicy.diet?.hiddenRateTypeIds) {
        // Some ratetypes for diet has been disabled
        const hiddenIds = productConfig.uxPolicy.diet.hiddenRateTypeIds;
        if (hiddenIds.includes(newReport.rateType)) {
          // The default rateType is disabled, replace with the first enabled one if one exists
          const newRateType = rateTypes.find((o) => !hiddenIds.includes(o.id));
          if (newRateType) newReport.rateType = newRateType.id;
        }
      }
      // Check for a default rateType
      if (productConfig.uxPolicy.diet?.defaultRateTypeId) {
        // A default ratetype for new reports has been configured, use it regardless of available options, if it's valid
        if (rateTypes.map((o) => o.id).includes(productConfig.uxPolicy.diet.defaultRateTypeId)) {
          newReport.rateType = productConfig.uxPolicy.diet.defaultRateTypeId;
        }
      }

      // Set report header field defaults
      if (productConfig.uxPolicy.report?.fields) {
        // Check individual report header fields for default values
        const fields = productConfig.uxPolicy.report.fields;
        const { advance, comment, companyName, description, purpose, referenceNumber } = fields;
        if (advance?.useDefaultValue && !nullOrUndefined(advance.defaultValue)) {
          const advanceDefault = Number(advance.defaultValue);
          if (!isNaN(advanceDefault)) newReport.advance = advanceDefault * 100;
        }
        if (comment?.useDefaultValue && !nullOrUndefined(comment.defaultValue)) {
          newReport.comment = String(comment.defaultValue);
        }
        if (companyName?.useDefaultValue && !nullOrUndefined(companyName.defaultValue)) {
          newReport.companyName = String(companyName.defaultValue);
        }
        if (description?.useDefaultValue && !nullOrUndefined(description.defaultValue)) {
          newReport.description = String(description.defaultValue);
        }
        if (purpose?.useDefaultValue && !nullOrUndefined(purpose.defaultValue)) {
          newReport.purpose = String(purpose.defaultValue);
        }
        if (referenceNumber?.useDefaultValue && !nullOrUndefined(referenceNumber.defaultValue)) {
          newReport.referenceNumber = String(referenceNumber.defaultValue);
        }
      }

      // If we have a reportSegment (and we should!), check and apply the diet policy
      if (newReport.reportSegments.length > 0) {
        newReport.reportSegments[0] = applyPolicyToNewReportSegment(newReport.reportSegments[0], productConfig);
      }
    }

    setCurrentReport(newReport);
  }

  // Bail out here if we haven't stubbed a report yet
  if (!currentReport) {
    return <Spinner size="75px" margin="1em" />;
  }

  // Only show the segment creator if we're creating a travel report
  const showSegments = reportTypeId === 1;

  // Handle paging back and forth
  const onPage = async (next: boolean) => {
    // Page 1: Header
    // Page 2: Segments
    // Page 3: Dietary
    if (next) {
      if (page === 3 || (page === 2 && !showDietary) || (page === 1 && !showSegments && !showDietary)) {
        // Final step completed
        setPersistQueued(true);
      } else {
        // Next page
        if (!validate(currentReport)) return false;
        let nextPage = page + 1;
        if (nextPage === 2 && !showSegments) nextPage = 3;
        setPage(nextPage);
      }
    } else if (page > 1) {
      // Prev page
      if (!validate(currentReport)) return false;
      let nextPage = page - 1;
      if (nextPage === 2 && !showSegments) nextPage = 1;
      setPage(nextPage);
    } else {
      // No prev page available, bail out
      history.goBack();
    }
  };

  // Merge header changes into report
  const onChangeHeader = (partialHeader: Partial<Report>) => {
    const report = { ...currentReport, ...partialHeader, personId, dirty: now() };
    report.calculation = calculateReport(report, userConfiguration, officialRates, countries, majorCities, exchangeRatesNBSeed);
    // Update whether to show the dietary override step for travel reports based on a rate type, and if we have any applicable days to override
    const showDietary =
      report.reportTypeId === 1 && report.rateType !== 1 && report.calculation.calculationItems.filter((item) => item.itemType === 3).length > 0;
    setCurrentReport(report);
    setShowDietary(showDietary);
  };

  // Replace segment list
  const onChangeSegments = (segments: ReportSegment[]) => {
    // Merge changes into state
    const report = { ...currentReport };
    report.reportSegments = segments;
    report.calculation = calculateReport(report, userConfiguration, officialRates, countries, majorCities, exchangeRatesNBSeed);
    // Update whether to show the dietary override step for travel reports based on a rate type, and if we have any applicable days to override
    const showDietary =
      report.reportTypeId === 1 && report.rateType !== 1 && report.calculation.calculationItems.filter((item) => item.itemType === 3).length > 0;
    setCurrentReport(report);
    setShowDietary(showDietary);
  };

  // Replace diet override list
  const onChangeAutoExpenseOverrides = (overrides: AutoExpenseOverride[]) => {
    const report = { ...currentReport };
    report.autoExpenseOverrides = overrides;
    report.calculation = calculateReport(report, userConfiguration, officialRates, countries, majorCities, exchangeRatesNBSeed);
    setCurrentReport(report);
  };

  // Replace customValue list
  const onChangeCustomValues = (customValues: (ReportCustomValue | ExpenseCustomValue)[]) => {
    const report = { ...currentReport };
    report.reportCustomValues = customValues as ReportCustomValue[];
    report.calculation = calculateReport(report, userConfiguration, officialRates, countries, majorCities, exchangeRatesNBSeed);
    setCurrentReport(report);
  };

  // Segment constructor wrapper provided for use by children
  const createSegment = (starts: string, stops: string) => {
    const segment = reportSegmentConstructor({
      reportUuid: currentReport.uuid,
      starts,
      stops,
      countryName: defaultLocation.countryName,
      countryId: defaultLocation.countryId,
      cityName: defaultLocation.cityName,
      cityId: defaultLocation.cityId
    });
    return segment;
  };

  // Should be called before persisting, and if performance allows, whenever the report is changed
  // Only deals with customfields for now, expand on this later
  const validate = (report: Report) => {
    const reportClone = { ...report };
    const reportCustomFieldsEnabled = reportCustomFields.filter((field) => field.enabled);
    const expenseCustomFieldsEnabled = expenseCustomFields.filter((field) => field.enabled);
    const validationResult = validateReport(reportClone, reportCustomFieldsEnabled, expenseCustomFieldsEnabled);
    setValidationErrors(validationResult.errors);
    return validationResult.isValid;
  };

  let headerType: PageHeaderPage = "newTravelReport";
  if (currentReport.reportTypeId === 2) headerType = "newExpenseReport";
  if (currentReport.reportTypeId === 3) headerType = "newDrivingLog";

  const showNextButton = (page === 1 && showSegments) || (page === 2 && showDietary);

  return (
    <Grid fluid>
      <AttachmentPreview />
      <PageHeader page={headerType} />
      <div className="report-create-wrapper">
        <Row>
          {showSegments && (
            <Col sm={1} xsHidden>
              <div className="report-create-progress-vertical">
                <Steps labelPlacement="vertical" size="small" current={page - 1} direction="vertical">
                  <Steps.Step title="" />
                  <Steps.Step title="" />
                  {showDietary && <Steps.Step title="" />}
                </Steps>
                {/* Header, segments, dietary */}
              </div>
            </Col>
          )}

          <Col sm={11} xs={12}>
            <Row>
              <Col smHidden mdHidden lgHidden xs={12}>
                {showSegments && (
                  <div className="report-create-progress-horizontal">
                    <Steps size="small" current={page - 1}>
                      <Steps.Step title="" />
                      <Steps.Step title="" />
                      {showDietary && <Steps.Step title="" />}
                    </Steps>
                    {/* Header, segments, dietary */}
                  </div>
                )}
              </Col>
            </Row>

            {page === 1 && (
              <>
                <ReportHeader
                  report={currentReport}
                  userConfiguration={userConfiguration}
                  readOnly={persistQueued}
                  onChange={onChangeHeader}
                  showTour={false}
                  validationErrors={validationErrors.header}
                />
                <CustomFields
                  reportUuid={currentReport.uuid}
                  customFields={reportCustomFields}
                  customValues={currentReport.reportCustomValues}
                  onChangeCustomValues={onChangeCustomValues}
                  readOnly={persistQueued}
                  validationErrors={validationErrors.reportCustomFields}
                />
              </>
            )}

            {page === 2 && showSegments && (
              <ReportSegments
                segmentList={currentReport.reportSegments}
                readOnly={persistQueued}
                reportTypeId={currentReport.reportTypeId}
                onChange={onChangeSegments}
                createSegment={createSegment}
              />
            )}

            {page === 3 && showDietary && (
              <ReportAutoExpenseOverrides
                reportUuid={currentReport.uuid}
                reportCalculation={currentReport.calculation}
                autoExpenseOverrides={currentReport.autoExpenseOverrides}
                readOnly={persistQueued}
                onChange={onChangeAutoExpenseOverrides}
              />
            )}
          </Col>
        </Row>

        <Row>
          <Col xs={12} style={{ textAlign: "right" }}>
            {persistQueued ? (
              <Spinner size="50px" />
            ) : (
              <>
                <Button bsSize="large" bsStyle={page === 1 ? "warning" : "default"} onClick={async () => await onPage(false)}>
                  {page === 1 ? t("cancel") : t("back")}
                </Button>
                {showNextButton && (
                  <>
                    &nbsp;
                    <Button bsSize="large" bsStyle="default" onClick={async () => await onPage(true)}>
                      {t("next")}
                    </Button>
                  </>
                )}
                &nbsp;
                <Button disabled={persistQueued} bsSize="large" bsStyle="success" onClick={() => setPersistQueued(true)}>
                  {t("finish")}
                </Button>
              </>
            )}
          </Col>
        </Row>
      </div>
    </Grid>
  );
};

export default ReportPageNew;
