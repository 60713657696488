import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import TextFieldGroup from "../common/TextFieldGroup";
import NumberFieldGroup from "../common/NumberFieldGroup";
import CheckboxInput from "../common/CheckboxInput";
import SelectGroup from "../common/SelectGroup";
import Icon from "../common/Icon";
import "../../styles/customfield.css";
import { ExpenseCustomField, ExpenseCustomValue, ReportCustomField, ReportCustomValue, ValidateCustomFieldError } from "../../shared/types";

// Renders a single customfield on either a report or an expense
interface CustomFieldItemProps {
  customField: ReportCustomField | ExpenseCustomField;
  customValue?: ReportCustomValue | ExpenseCustomValue;
  readOnly?: boolean;
  onChangeCustomValue?: (field: ReportCustomField | ExpenseCustomField, value: string) => void;
  validationError?: ValidateCustomFieldError;
  addonLeft?: JSX.Element | HTMLElement | string; // For text fields, we can pass these through to the customfield
  addonRight?: JSX.Element | HTMLElement | string;
}

const CustomFieldItem = ({
  customField,
  customValue,
  readOnly,
  onChangeCustomValue,
  validationError,
  addonLeft,
  addonRight
}: CustomFieldItemProps) => {
  const [t] = useTranslation();

  const choices = useMemo(() => {
    // Generate selectbox options for multiple choice fields
    // Parse the available choices, each line is one choice in its simplest form
    // Choices can contain special characters to modify this
    return (customField.choices ? customField.choices.match(/[^\r\n]+/g) || [""] : [""]).map((o) => {
      if (o.indexOf("!!!") > -1) {
        const a = o.split("!!!");
        return { value: a[0], label: a[1] };
      } else if (o.startsWith("+++")) {
        return { label: o.substring(3), value: "", isDisabled: true };
      }
      return { value: o, label: o };
    });
  }, [customField.choices]);

  // const [selectedOption, setSelectedOption] = useState<string | undefined>(undefined);

  // Change handlers for field values

  const onChangeValueNumeric = (val: number | null) => {
    onChangeValueString(val === null ? "" : String(val));
  };

  const onChangeValueBoolean = (value: boolean) => {
    onChangeValueString(value ? "1" : "0");
  };

  const onChangeValueString = (val: string) => {
    onChangeCustomValue && onChangeCustomValue(customField, val);
  };

  const { description, fieldType, enabled, required, minValue, maxValue } = customField;

  // Figure out the currently selected value for the field
  // Use the customValue if supplied, or the field's default value, and convert to other types using the following logic
  // Both default values and already selected values start out as string types
  const valueString = customValue !== undefined ? customValue.value : customField.defaultValue;
  const valueNumeric = !valueString || !Number(valueString) || String(valueString).trim() === "" ? null : Number(valueString);
  const valueBool = valueString === "0" ? false : Boolean(valueString);

  const minValueNumeric = minValue || minValue === "0" ? (!isNaN(Number(minValue)) ? Number(minValue) : undefined) : undefined;
  const maxValueNumeric = maxValue || maxValue === "0" ? (!isNaN(Number(maxValue)) ? Number(maxValue) : undefined) : undefined;

  const fieldName = customField.systemName || `customField_${customField.id}`;

  // Pick up [info]url[/info] tags in the description and render them as information links
  const infoRegex = new RegExp(/\[info\](.*?)\[\/info\]/g);
  let infoLinks = null;
  const infoMatches = description.match(infoRegex);
  if (infoMatches) {
    infoLinks = infoMatches.map((o, i) => {
      const url = o.replace("[info]", "").replace("[/info]", "");
      return (
        <a target="_blank" rel="noopener noreferrer" key={`${fieldName}${i}`} href={url}>
          <Icon style={{ display: "inline-block" }} icon="help" />
        </a>
      );
    });
  }
  const cleanDescription = description.replace(infoRegex, "").trim();

  const localizedDescription = (
    <span>
      {t([`customFieldNames.${cleanDescription.replace(".", "")}`, cleanDescription])} {infoLinks}
    </span>
  );

  // Rendering time! These are the available custom field types:
  // "smalltext" (small text field)
  // "longtext" (large text field)
  // "int" (integer-friendly text field)
  // "float" (decimal-friendly text field)
  // "bool" (checkbox storing a 1 or 0)
  // "choice" (dropdown with preset text choices)
  return (
    <div className="custom-field">
      {(fieldType === "smalltext" || fieldType === "longtext") && (
        <TextFieldGroup
          field={fieldName}
          label={localizedDescription}
          value={valueString}
          onChange={(value: string) => onChangeValueString(value)}
          componentClass={customField.fieldType === "longtext" ? "textarea" : "input"}
          rows={4}
          required={required}
          readOnly={readOnly || !enabled}
          error={validationError && validationError.message}
          addonLeft={addonLeft}
          addonRight={addonRight}
        />
      )}

      {(fieldType === "int" || fieldType === "float") && (
        <NumberFieldGroup
          field={fieldName}
          label={localizedDescription}
          value={valueNumeric}
          onChange={(value: number | null) => onChangeValueNumeric(value)}
          allowDecimals={customField.fieldType === "float"}
          allowBlank={!required}
          required={required}
          minValue={minValueNumeric}
          maxValue={maxValueNumeric}
          readOnly={readOnly || !enabled}
          error={validationError && validationError.message}
        />
      )}

      {fieldType === "bool" && (
        <CheckboxInput
          field={fieldName}
          label={localizedDescription}
          value={valueBool}
          onChange={(value: boolean) => onChangeValueBoolean(value)}
          readOnly={readOnly || !enabled}
          error={validationError && validationError.message}
        />
      )}

      {fieldType === "choice" && (
        <SelectGroup
          field={fieldName}
          label={localizedDescription}
          value={valueString}
          onChange={(value: string) => onChangeValueString(value)}
          options={choices}
          readOnly={readOnly || !enabled}
          required={required}
          error={validationError && validationError.message}
        />
      )}
    </div>
  );
};

export default CustomFieldItem;
