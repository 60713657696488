import create, { GetState, SetState, UseStore } from "zustand";
import { persist, devtools, StateStorage } from "zustand/middleware";
import { getBackendUrlFromEnvironment } from "../api/api";
import { SupportedClientTypes, SupportedEnvironments, SupportedLanguages, User, CommandHistoryEntry, SupportedClientPlatforms } from "../types";
import { ApiResponseDetails } from "../api/api";

export interface Store {
  ready: boolean;
  language: SupportedLanguages;
  env: SupportedEnvironments;
  currentUserId?: number;
  users: User[];
  commandHistory: CommandHistoryEntry[];
  apiBaseUrl: string;
  clientType: SupportedClientTypes;
  clientPlatform: SupportedClientPlatforms;
  clientVersion?: number;
  apiCallCompletedHandler?: (responseDetails: ApiResponseDetails) => void; // Handle ALL call results so we can show and reset errors based on failed/succeeded calls
  updateCallback?: () => void;
  clientSpecific: any; // Any client-specific stuff that does not belong in shared. You should typedef this in your own client implementation.
  addCommand: (command: CommandHistoryEntry) => void;
  updateCommand: (command: CommandHistoryEntry) => void;
  clearCommands: () => void;
  addUser: (user: User) => void;
  getCurrentUser: () => User | null;
  switchUser: (userId: number) => void;
  removeUser: (userId: number) => void;
  switchEnvironment: (env: SupportedEnvironments) => void;
}

export const store = (set: any, get: any): Store => ({
  ready: false,
  language: "nb",
  env: "prod",
  currentUserId: undefined,
  users: [] /* Only { id, exp, iat, jwtToken }, everything else is handled by react-query */,
  commandHistory: [],
  apiBaseUrl: "",
  clientType: "web",
  clientPlatform: "edith",
  clientVersion: 1,
  apiCallCompletedHandler: undefined,
  updateCallback: undefined,
  clientSpecific: {},
  addCommand: (command: CommandHistoryEntry) => {
    // Adds a command/reply to the command history
    const history = get().commandHistory;
    set({ commandHistory: [...history, command] });
  },
  updateCommand: (command: CommandHistoryEntry) => {
    // Updates an existing command in command history based on uuid
    const history = get().commandHistory;
    set({ commandHistory: history.map((cmd: CommandHistoryEntry) => (cmd.uuid === command.uuid ? command : cmd)) });
  },
  clearCommands: () => {
    // Clear the command history
    set({ commandHistory: [] });
  },
  addUser: (user: User) => {
    // Adds or overwrites a user in users[]
    const otherUsers: User[] = get().users.filter((o: User) => o.id !== user.id);
    set({ users: [...otherUsers, user] });
  },
  getCurrentUser: () => {
    // Returns the current user, if any
    const currentUserId = get().currentUserId;
    if (!currentUserId) return null;
    const user: User = get().users.find((o: User) => o.id === currentUserId);
    return user;
  },
  switchUser: (userId: number) => {
    // Sets a new current user, must already exist in users[]
    const user = get().users.find((o: User) => o.id === userId);
    if (user) {
      set({ currentUserId: user.id });
    } else {
      throw new Error("Attempted to switchUser() to a user not currently in allUsers");
    }
  },
  removeUser: (userId: number) => {
    // Removes a user from users[], also clears currentUserId if it's the one we're removing
    const otherUsers = get().users.filter((o: User) => o.id !== userId);
    set({ users: otherUsers });
    if (get().currentUserId === userId) set({ currentUserId: undefined });
  },
  switchEnvironment: (env: SupportedEnvironments) => {
    // Switches to a new backend
    const newBaseUrl = getBackendUrlFromEnvironment(env);
    set({ env, apiBaseUrl: newBaseUrl });
  }
});

// Initialize and export a basic store
export let useStore: UseStore<Store> = create<Store>((set, get) => store(set, get));

// Validate defaults before initializing a store
export const validateDefaults = (defaults: Partial<Store>) => {
  // Client info and backend URL should always be provided since a new client can rehydrate an old store
  if (!defaults.clientType) throw new Error("initStore: invalid clientType");
  if (!defaults.clientVersion) throw new Error("initStore: invalid clientVersion");
  // The callbacks are not serialized and persisted, and must always be provided
  // if (!defaults.apiCallCompletedHandler) throw new Error("initStore: invalid apiCallCompletedHandler");
  if (!defaults.updateCallback) throw new Error("initStore: invalid updateCallback");
};

// Set up the initial store, rehydrate if we have a persisted store, and inject any default values we want to reset.
// If the store is async (react-native AsyncStorage), set config.persistIsAsync=true. This populates the hydrated store in a callback.
// If the store is sync (web LocalStorage), set config.persistIsAsync=false. This populates the hydrated store synchronously.
export interface StoreConfig {
  enableDevtools: boolean;
  persistOptions: {
    name: string;
    getStorage: () => StateStorage;
  };
  persistIsAsync: boolean;
  defaultValues: Partial<Store>;
}
export const initStore = async (config: StoreConfig) => {
  validateDefaults(config.defaultValues);
  let s: any = (set: SetState<Store>, get: GetState<Store>) => store(set, get);
  if (config.enableDevtools) s = devtools(s);
  if (config.persistOptions)
    s = persist(s, {
      ...config.persistOptions,
      onRehydrateStorage: config.persistIsAsync
        ? () => () => {
            // @ts-ignore
            useStore.setState({ ...config.defaultValues, ready: true });
          }
        : undefined
    });
  useStore = create<Store>(s);
  if (!config.persistIsAsync) {
    // @ts-ignore
    useStore.setState({ ...config.defaultValues, ready: true });
  }
  return;
};
