import { useState, useEffect } from "react";
import { Redirect } from "react-router";
import { logout } from "../../shared/actions/userActions";
import Spinner from "../common/Spinner";

// Logs out he current user
const LogoutPage = () => {
  const [loggedOut, setLoggedOut] = useState(false);
  useEffect(() => {
    (async () => {
      await logout();
      setLoggedOut(true);
    })();
  }, [loggedOut]);

  if (!loggedOut) {
    return <Spinner size="75px" margin="1em" />;
  } else {
    return <Redirect to={`/login`} />;
  }
};

export default LogoutPage;
