export const expenseCategories = [
  {
    description: "accomodation",
    id: 1
  },
  {
    description: "foodWithReceipt",
    id: 2
  },
  {
    description: "transport",
    id: 3
  },
  {
    description: "drivingOwnVehicle",
    id: 4
  },
  {
    description: "otherExpenses",
    id: 5
  }
];

export const rateTypes = [
  {
    description: "none",
    id: 1
  },
  {
    description: "custom24",
    id: 2
  },
  {
    description: "official",
    id: 3
  },
  {
    description: "taxFree",
    id: 4
  },
  {
    description: "taxable",
    id: 5
  },
  {
    description: "customTaxfree",
    id: 6
  }
];

export const lodgingTypes = [
  { id: 4, description: "none" },
  { id: 1, description: "hotel" },
  { id: 2, description: "dormWithKitchen" },
  { id: 3, description: "dormWithoutKitchen" }
];

/* 6% is the temporary corona crisis VAT rate */
export const vatOptions = [0, 6, 10, 12, 15, 25];

/* All supported language codes */
export const supportedLanguageCodes = ["nb", "en"] as const;

/* Language codes using comma (,) as decimal separator rather than dot (.) */
export const languageCodesWithCommaSeparator = ["nb"];

/* Environment codes */
export const supportedEnvironments = ["localhost", "dev", "staging", "prod"] as const;

/* Client type codes */
export const supportedClientTypes = ["web", "mobile"] as const;

/* Client platform codes */
export const supportedClientPlatforms = ["classic", "edith", "ios", "android"] as const;

// Companies with access to ratetype 1001, triggering the OneCo diet calculator
export const rateType_1001_companyIds = [931, 10000279, 10000636, 3592, 2206, 3203, 3204];
