import { useEffect, useState } from "react";
import { Grid, Row, Col, Button, FormGroup, InputGroup, FormControl, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import isEqual from "lodash/isEqual";
import omit from "lodash/omit";
import IntlTelInput, { CountryData } from "react-intl-tel-input";
import validator from "validator";
import moment from "moment";
import PageHeader from "../common/PageHeader";
import TextFieldGroup from "../common/TextFieldGroup";
import SelectGroup from "../common/SelectGroup";
import SelectGroupGeography from "../common/SelectGroupGeography";
import TemporalPicker from "../common/TemporalPicker";
import CheckboxInput from "../common/CheckboxInput";
import GenericUploader from "../common/GenericUploader";
import Icon from "../common/Icon";
import AttachmentPreview from "../attachment/AttachmentPreview";
import * as userApi from "../../shared/api/user";
import { signature } from "../../shared/api/blob";
import Spinner from "../common/Spinner";
import { capitalize, formatDateRange } from "../../shared/utils/helpers";
import { validateProfile } from "../../shared/validation/profile";
import "../../styles/profile.css";
import "../../styles/react-intl-tel-input.css";
import "react-intl-tel-input/dist/main.css";
import { showToast } from "../../utils/toastWrapper";
import {
  AdvancedApprovalDeputy,
  AdvancedApprovalDeputyCandidate,
  UserProfileFromBackend,
  UserProfileToBackend,
  ValidateProfileErrors
} from "../../shared/types";
import useConstructor from "../../shared/utils/useConstructor";
import ButtonWithSpinner from "../common/ButtonWithSpinner";
import { useStore } from "../../shared/store/store";

// UI for editing the user's profile
const ProfilePage = () => {
  const [t] = useTranslation();

  // Monitor this to trigger a rerender if we switch users
  const currentUserId = useStore((state) => state.currentUserId);

  const [loaded, setLoaded] = useState(false);
  const [signatureUrl, setSignatureUrl] = useState<string>("");
  const [serverProfile, setServerProfile] = useState<UserProfileFromBackend>();
  const [initialLocalProfile, setInitialLocalProfile] = useState<UserProfileToBackend>();
  const [localProfile, setLocalProfile] = useState<UserProfileToBackend>();
  const [errors, setErrors] = useState<ValidateProfileErrors>({});
  const [newWhitelistAddress, setNewWhitelistAddress] = useState("");
  const [deputies, setDeputies] = useState<AdvancedApprovalDeputy[]>([]);
  const [editingDeputy, setEditingDeputy] = useState<AdvancedApprovalDeputy>();
  const [deputyCandidates, setDeputyCandidates] = useState<AdvancedApprovalDeputyCandidate[]>([]);
  const [savingOrDeletingDeputy, setSavingOrDeletingDeputy] = useState(false);
  const [saving, setSaving] = useState(false);

  const loadDeputyCandidates = async () => {
    userApi.getAdvancedApprovalDeputyCandidates().then((response) => {
      setDeputyCandidates(response.map((o) => ({ id: o.id, name: o.name })));
    });
  };

  const loadDeputies = async () => {
    userApi.getAdvancedApprovalDeputies().then((response) => {
      setDeputies(response);
    });
  };

  const load = () => {
    userApi.getProfile().then((response) => {
      const localDefaults = {
        email2: response.email,
        password: "",
        password2: ""
      };
      setLoaded(true);
      setServerProfile(response);
      setInitialLocalProfile({ ...response, ...localDefaults });
      setLocalProfile({ ...response, ...localDefaults });
      if (response.signatureUuid) showSignature(response.signatureUuid);
    });
    loadDeputyCandidates();
    loadDeputies();
  };

  const showSignature = (uuid: string) => {
    signature(uuid).then((result) => setSignatureUrl((URL || webkitURL).createObjectURL(result)));
  };

  const saveSignature = (uuid: string) => {
    if (localProfile) setLocalProfile({ ...localProfile, signatureUuid: uuid });
    showSignature(uuid);
  };

  const removeSignature = () => {
    setSignatureUrl("");
    if (localProfile) setLocalProfile({ ...localProfile, signatureUuid: "" });
  };

  useConstructor(() => {
    load();
  });

  // Reload everything if we switch users
  useEffect(() => {
    setLoaded(false);
    removeSignature();
    setServerProfile(undefined);
    setInitialLocalProfile(undefined);
    setLocalProfile(undefined);
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserId]);

  const loadingView = (
    <Grid fluid>
      <AttachmentPreview />
      <PageHeader page={"profile"} />
      <div className="profile-wrapper">
        <Spinner size="75px" />
      </div>
    </Grid>
  );

  if (!serverProfile || !initialLocalProfile || !localProfile) return loadingView;

  const save = async () => {
    const validation = validateProfile(localProfile);
    setErrors(validation.errors);
    if (validation.isValid) {
      setSaving(true);

      userApi
        .saveProfile(localProfile)
        .then(() => {
          load();
          setSaving(false);
        })
        .catch((err: any) => {
          setSaving(false);
          showToast({ type: "error", title: t("error"), text: err?.backendMessage || "", autoClose: 12000 });
        });
    }
  };

  const changeMobile1 = (isValid: boolean, value: string, selectedCountryData: CountryData, fullNumber: string, extension: string) => {
    if (!isValid && value !== "") return; // Accept valid or blank numbers
    const num = isValid ? fullNumber : "";
    const mobiles = [...localProfile.mobiles];
    mobiles[0] = num.replace(/ /g, "");
    setLocalProfile({ ...localProfile, mobiles });
  };

  const changeMobile2 = (isValid: boolean, value: string, selectedCountryData: CountryData, fullNumber: string, extension: string) => {
    if (!isValid && value !== "") return;
    const num = isValid ? fullNumber : "";
    const mobiles = [...localProfile.mobiles];
    mobiles[1] = num.replace(/ /g, "");
    setLocalProfile({ ...localProfile, mobiles });
  };

  const changeMobile3 = (isValid: boolean, value: string, selectedCountryData: CountryData, fullNumber: string, extension: string) => {
    if (!isValid && value !== "") return;
    const num = isValid ? fullNumber : "";
    const mobiles = [...localProfile.mobiles];
    mobiles[2] = num.replace(/ /g, "");
    setLocalProfile({ ...localProfile, mobiles });
  };

  // A new location was picked
  const changeLocation = (selectedLocation: string) => {
    const locationParts = selectedLocation.split("|");
    const location = {
      cityId: parseInt(locationParts[0], 10),
      cityName: locationParts[1],
      countryId: parseInt(locationParts[2], 10),
      countryName: locationParts[3],
      location: locationParts[4]
    };
    setLocalProfile({ ...localProfile, ...location });
  };

  const generateKey = () => {
    userApi.generateApiKey().then(() =>
      userApi.getProfile().then((profileResponse) => {
        setServerProfile(profileResponse);
      })
    );
  };

  const deleteKey = (key: string) => {
    userApi.deleteApiKey(key).then(() =>
      userApi.getProfile().then((profileResponse) => {
        setServerProfile(profileResponse);
      })
    );
  };

  const deleteWhitelistAddress = (address: string) => {
    const newList = localProfile.emailSenderWhitelist.filter((o) => o !== address);
    setLocalProfile({ ...localProfile, emailSenderWhitelist: newList });
  };

  const addWhitelistAddress = () => {
    const list = [...localProfile.emailSenderWhitelist];
    const newAddress = newWhitelistAddress;
    if (list.indexOf(newAddress) >= 0 || validator.isEmpty(newAddress) || !validator.isEmail(newAddress)) return;
    list.push(newAddress.trim());
    setLocalProfile({ ...localProfile, emailSenderWhitelist: list });
    setNewWhitelistAddress("");
  };

  const deleteDeputy = (depid: number) => {
    setSavingOrDeletingDeputy(true);
    userApi
      .deleteAdvancedApprovalDeputy(depid)
      .then(() => {
        loadDeputies().then(() => {
          setSavingOrDeletingDeputy(false);
        });
      })
      .catch((err: any) => {
        setSavingOrDeletingDeputy(false);
        showToast({ type: "error", title: t("error"), text: err?.backendMessage || "", autoClose: 12000 });
      });
  };

  const editDeputy = (deputy: AdvancedApprovalDeputy) => {
    setEditingDeputy(deputy);
  };

  const addDeputy = () => {
    const validFrom = moment().format("YYYY-MM-DD");
    const validTo = moment().add(7, "days").format("YYYY-MM-DD");

    const deputyPersonId = deputyCandidates[0].id;
    setEditingDeputy({ id: 0, validFrom, validTo, deputyPersonId, deputyFirstName: "", deputyLastName: "" });
  };

  const cancelEditDeputy = () => {
    setEditingDeputy(undefined);
  };

  const saveDeputy = () => {
    if (!editingDeputy) return false;
    setSavingOrDeletingDeputy(true);
    userApi
      .saveAdvancedApprovalDeputy(editingDeputy)
      .then(() => {
        loadDeputies().then(() => {
          setEditingDeputy(undefined);
          setSavingOrDeletingDeputy(false);
        });
      })
      .catch((err) => {
        setSavingOrDeletingDeputy(false);
        showToast({ type: "error", title: t("error"), text: err?.backendMessage || "", autoClose: 12000 });
      });
  };

  const { firstName, lastName, personNumber, bankAccount, employeeNumber, emailCommandsEnabled, countryName, apiKeys } = serverProfile;

  const {
    address,
    zip,
    city,
    email,
    email2,
    phone,
    workRole,
    cityId,
    countryId,
    mobiles,
    emailSenderWhitelist,
    password,
    password2,
    analyzeReceipts,
    analyzeCardTransactions,
    sendEmailNotificationsApprovalPending,
    sendEmailNotificationsReportApproved,
    sendEmailNotificationsReportDisapproved
  } = localProfile;

  if (!loaded) return loadingView;

  const updateEditingDeputy = (changes: Partial<AdvancedApprovalDeputy>) => {
    setEditingDeputy((current) => {
      return !current ? undefined : { ...current, ...changes };
    });
  };

  // Construct a geography selectgroup friendly location value
  const currentlocationLabel = city ? `${city}, ${countryName}` : countryName;
  const currentLocationValue = `${cityId}|${city}|${countryId}|${countryName}|${currentlocationLabel}`;
  const editDeputyForm = editingDeputy ? (
    <div className="deputy-editor">
      <div>
        <SelectGroup
          field="deputy"
          label={`${capitalize(t("who"))}?`}
          value={editingDeputy.deputyPersonId}
          onChange={(value: number) => updateEditingDeputy({ deputyPersonId: value })}
          options={deputyCandidates.map((o) => ({ label: o.name, value: o.id }))}
          readOnly={savingOrDeletingDeputy}
        />
      </div>
      <div>
        <label className="control-label">{capitalize(t("tidsrom"))}</label>
        <TemporalPicker
          useRange
          useTime={false}
          startDate={editingDeputy.validFrom}
          endDate={editingDeputy.validTo}
          onChange={(value) => {
            updateEditingDeputy({ validFrom: value.start, validTo: value.end || value.start });
          }}
          readOnly={savingOrDeletingDeputy}
        />
      </div>
      <ButtonWithSpinner
        disabled={savingOrDeletingDeputy}
        showSpinner={savingOrDeletingDeputy}
        bsStyle="success"
        onClick={() => saveDeputy()}
        title={capitalize(t("save"))}
      >
        {capitalize(t("save"))}
      </ButtonWithSpinner>
      <Button disabled={savingOrDeletingDeputy} bsStyle="warning" onClick={() => cancelEditDeputy()} title={capitalize(t("cancel"))}>
        {capitalize(t("cancel"))}
      </Button>
    </div>
  ) : null;

  const isDirty = !isEqual(omit(localProfile, "apiKeys"), omit(initialLocalProfile, "apiKeys"));

  return (
    <Grid fluid>
      <AttachmentPreview />
      <PageHeader page={"profile"} />
      <Form>
        <div className="profile-wrapper">
          <div className="profile-block profile-block-main">
            <Row>
              <Col sm={6}>
                <TextFieldGroup field="firstName" label={t("profile.firstName")} value={firstName} readOnly onChange={() => {}} />
              </Col>
              <Col sm={6}>
                <TextFieldGroup field="lastName" label={t("profile.lastName")} value={lastName} readOnly onChange={() => {}} />
              </Col>
            </Row>

            <Row>
              <Col sm={12}>
                <TextFieldGroup
                  field="address"
                  label={t("profile.address")}
                  value={address}
                  onChange={(value) => setLocalProfile({ ...localProfile, address: value })}
                  placeholder="Veigaten 1"
                />
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <TextFieldGroup
                  field="zip"
                  label={t("profile.zip")}
                  value={zip}
                  onChange={(value) => setLocalProfile({ ...localProfile, zip: value })}
                  placeholder="0000"
                />
              </Col>
              <Col sm={9}>
                <TextFieldGroup
                  field="city"
                  label={t("profile.city")}
                  value={city}
                  onChange={(value) => setLocalProfile({ ...localProfile, city: value })}
                  placeholder="Oslo"
                />
              </Col>
            </Row>

            <Row>
              <Col sm={6}>
                <Row>
                  <Col sm={12}>
                    <TextFieldGroup
                      field="phone"
                      label={t("profile.phone")}
                      value={phone}
                      onChange={(value) => setLocalProfile({ ...localProfile, phone: value })}
                      placeholder="00000000"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col sm={12}>
                    <SelectGroupGeography
                      field="location"
                      label={t("profile.defaultLocation")}
                      value={currentLocationValue}
                      onChange={(value) => changeLocation(value)}
                    />
                  </Col>
                </Row>
              </Col>
              <Col sm={6}>
                <div>
                  <label>{t("profile.mobileNumbers")}</label>
                </div>
                <FormGroup>
                  <IntlTelInput
                    preferredCountries={["no", "se", "dk", "gb", "us"]}
                    fieldId="mobile1"
                    defaultValue={mobiles[0] ? mobiles[0] : ""}
                    separateDialCode
                    format
                    onPhoneNumberChange={changeMobile1}
                    inputClassName="form-control"
                  />
                </FormGroup>
                <FormGroup>
                  <IntlTelInput
                    preferredCountries={["no", "se", "dk", "gb", "us"]}
                    fieldId="mobile2"
                    defaultValue={mobiles[1] ? mobiles[1] : ""}
                    separateDialCode
                    format
                    onPhoneNumberChange={changeMobile2}
                    inputClassName="form-control"
                  />
                </FormGroup>
                <FormGroup>
                  <IntlTelInput
                    preferredCountries={["no", "se", "dk", "gb", "us"]}
                    fieldId="mobile3"
                    defaultValue={mobiles[2] ? mobiles[2] : ""}
                    separateDialCode
                    format
                    onPhoneNumberChange={changeMobile3}
                    inputClassName="form-control"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className="top-margin-2">
              <Col sm={6}>
                <TextFieldGroup
                  field="email"
                  label={t("profile.email")}
                  value={email}
                  onChange={(value) => setLocalProfile({ ...localProfile, email: value })}
                  placeholder="adresse@domene.no"
                  error={errors.email}
                  autoComplete="username"
                />
              </Col>
              <Col sm={6}>
                <TextFieldGroup
                  field="email2"
                  label={t("profile.emailRepeat")}
                  value={email2}
                  onChange={(value) => setLocalProfile({ ...localProfile, email2: value })}
                  placeholder="adresse@domene.no"
                  error={errors.email2}
                  autoComplete="username"
                />
              </Col>
            </Row>

            <Row>
              <Col sm={6}>
                <TextFieldGroup
                  field="password"
                  type="password"
                  label={t("profile.password")}
                  value={password}
                  onChange={(value) => setLocalProfile({ ...localProfile, password: value })}
                  placeholder=""
                  error={errors.password}
                  autoComplete="new-password"
                />
              </Col>
              <Col sm={6}>
                <TextFieldGroup
                  field="password2"
                  type="password"
                  label={t("profile.passwordRepeat")}
                  value={password2}
                  onChange={(value) => setLocalProfile({ ...localProfile, password2: value })}
                  placeholder=""
                  error={errors.password2}
                  autoComplete="new-password"
                />
              </Col>
            </Row>

            <Row className="top-margin-2">
              <Col sm={6}>
                <TextFieldGroup
                  field="workRole"
                  label={t("profile.workRole")}
                  value={workRole}
                  onChange={(value) => setLocalProfile({ ...localProfile, workRole: value })}
                />
              </Col>
              <Col sm={6}>
                <TextFieldGroup field="employeeNumber" label={t("profile.employeeNumber")} value={employeeNumber} readOnly onChange={() => {}} />
              </Col>
            </Row>

            <Row>
              <Col sm={6}>
                <TextFieldGroup field="personNumber" label={t("profile.personNumber")} value={personNumber} readOnly onChange={() => {}} />
              </Col>
              <Col sm={6}>
                <TextFieldGroup field="bankAccount" label={t("profile.bankAccount")} value={bankAccount} readOnly onChange={() => {}} />
              </Col>
            </Row>
          </div>
          <div className="profile-block profile-block-settings">
            <Row>
              <Col sm={12}>
                <div>
                  <strong>{t("profile.analyzeReceiptsAndCardTransactionsTitle")}</strong>
                </div>
                <CheckboxInput
                  field="analyzeReceipts"
                  value={analyzeReceipts === "1"}
                  label={t("profile.analyzeReceiptsSetting")}
                  onChange={(value) => setLocalProfile({ ...localProfile, analyzeReceipts: value ? "1" : "0" })}
                />
                <CheckboxInput
                  field="analyzeCardTransactions"
                  value={analyzeCardTransactions === "1"}
                  label={t("profile.analyzeCardTransactionsSetting")}
                  onChange={(value) => setLocalProfile({ ...localProfile, analyzeCardTransactions: value ? "1" : "0" })}
                />

                <div className="top-margin-2">
                  <strong>{t("profile.emailNotifications")}</strong>
                </div>
                <CheckboxInput
                  field="sendEmailNotificationsReportApproved"
                  value={sendEmailNotificationsReportApproved === "1"}
                  label={t("profile.sendEmailNotificationsReportApproved")}
                  onChange={(value) => setLocalProfile({ ...localProfile, sendEmailNotificationsReportApproved: value ? "1" : "0" })}
                />

                <CheckboxInput
                  field="sendEmailNotificationsReportDisapproved"
                  value={sendEmailNotificationsReportDisapproved === "1"}
                  label={t("profile.sendEmailNotificationsReportDisapproved")}
                  onChange={(value) => setLocalProfile({ ...localProfile, sendEmailNotificationsReportDisapproved: value ? "1" : "0" })}
                />

                <CheckboxInput
                  field="sendEmailNotificationsApprovalPending"
                  value={sendEmailNotificationsApprovalPending === "1"}
                  label={t("profile.sendEmailNotificationsApprovalPending")}
                  onChange={(value) => setLocalProfile({ ...localProfile, sendEmailNotificationsApprovalPending: value ? "1" : "0" })}
                />

                <div className="top-margin-2">
                  <strong>{t("profile.apiKeys")}</strong>
                </div>

                <div className="apikeys-wrapper">
                  {apiKeys.map((o) => (
                    <div key={o} className="apikey-item">
                      <Button bsSize="xs" bsStyle="danger" title={capitalize(t("delete"))} onClick={() => deleteKey(o)}>
                        <Icon icon="trash" />
                      </Button>
                      {o}
                    </div>
                  ))}
                  {apiKeys.length === 0 && (
                    <div>
                      <em>{capitalize(t("none"))}</em>
                    </div>
                  )}
                </div>
                <div>
                  <br />
                  <Button bsSize="sm" onClick={() => generateKey()}>
                    <Icon icon="add" />
                    {capitalize(t("createApiKey"))}
                  </Button>
                </div>

                {emailCommandsEnabled && (
                  <div className="top-margin-2 whitelist-wrapper">
                    <div>
                      <strong>{t("profile.whitelistedEmails")}</strong>
                    </div>

                    <div>
                      {emailSenderWhitelist.map((o) => (
                        <div key={o} className="whitelist-item">
                          <Button bsSize="xs" bsStyle="danger" title={capitalize(t("delete"))} onClick={() => deleteWhitelistAddress(o)}>
                            <Icon icon="trash" />
                          </Button>
                          {o}
                        </div>
                      ))}
                    </div>
                    <div className="top-margin-1">
                      <InputGroup>
                        <FormControl
                          type="text"
                          placeholder="adresse@domene.no"
                          onChange={(e: React.FormEvent<FormControl & HTMLInputElement>) => setNewWhitelistAddress(e.currentTarget.value)}
                        />
                        <InputGroup.Button>
                          <Button onClick={() => addWhitelistAddress()}>{capitalize(t("profile.addWhitelistedEmail"))}</Button>
                        </InputGroup.Button>
                      </InputGroup>
                    </div>
                  </div>
                )}
              </Col>
            </Row>

            <Row>
              <Col md={9} sm={12}>
                <div className="top-margin-2">
                  <strong>{t("profile.approvalDeputiesTitle")}</strong>
                  <p>{t("profile.approvalDeputiesDescription")}:</p>
                </div>

                <div className="approval-deputies-wrapper">
                  <div>
                    {deputies.map((o) => (
                      <div key={o.id} className="approval-deputy-item">
                        <Button
                          bsSize="xs"
                          bsStyle="danger"
                          title={capitalize(t("delete"))}
                          onClick={() => deleteDeputy(o.id)}
                          disabled={savingOrDeletingDeputy}
                        >
                          <Icon icon="trash" />
                        </Button>
                        {formatDateRange(o.validFrom, o.validTo)}: {o.deputyFirstName} {o.deputyLastName}
                      </div>
                    ))}
                    {deputies.length === 0 && !editDeputy && (
                      <div>
                        <em>{capitalize(t("none"))}</em>
                      </div>
                    )}
                  </div>

                  {editDeputyForm}

                  {!editingDeputy && deputyCandidates.length > 0 && (
                    <div>
                      <br />
                      <Button bsSize="sm" disabled={savingOrDeletingDeputy} onClick={() => addDeputy()}>
                        <Icon icon="add" />
                        {capitalize(t("add"))}
                      </Button>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </div>
          <div className="profile-block profile-block-signature">
            <Row>
              <Col sm={12}>
                <div>
                  <strong>{t("profile.signature")}</strong>
                </div>

                {signatureUrl && (
                  <div className="signature-wrapper">
                    <button title={capitalize(t("delete"))} className="signature-delete" onClick={() => removeSignature()}>
                      &times;
                    </button>
                    <img className="" src={signatureUrl} alt="" />
                  </div>
                )}
                <div>
                  <GenericUploader
                    mode="signature"
                    onCreatedSignature={saveSignature}
                    onError={() => {
                      showToast({ title: t("error"), text: t("profile.signatureUploadFailed"), type: "error", autoClose: 12000 });
                    }}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="profile-bottom-buttons">
          <ButtonWithSpinner showSpinner={saving} bsStyle="success" bsSize="large" disabled={!isDirty || saving} onClick={() => save()}>
            {isDirty ? t("profile.saveChanges") : t("profile.saved")}
          </ButtonWithSpinner>
        </div>
      </Form>
    </Grid>
  );
};

export default ProfilePage;
