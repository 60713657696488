import { toast, ToastOptions, ToastId } from "react-toastify";

// Get the toast body based on text and optional title
const getToastBody = (title: string | undefined, text: string) => {
  return (
    <div>
      {title && <h4>{title}</h4>}
      {text}
    </div>
  );
};

// Shortcut options for a toast that can't be closed by the user
const permanentToastOptions: ToastOptions = {
  autoClose: false,
  closeOnClick: false,
  draggable: false,
  closeButton: false,
  className: "permanent-toast"
};

export type ToastStatus = "info" | "success" | "warning" | "error" | "default";

// Actually show a toast and return its ID
export const showToast = (opts: {
  title?: string;
  text: string;
  type: ToastStatus;
  // options: ToastOptions;
  autoClose?: false | number;
  permanent?: boolean;
  unclickable?: boolean;
}) => {
  const toastBody = getToastBody(opts.title, opts.text);
  let toastOptions: ToastOptions = { ...opts };
  if (opts.permanent) toastOptions = { ...toastOptions, ...permanentToastOptions };
  if (opts.unclickable) toastOptions = { ...toastOptions, className: "permanent-toast unclickable-toast" };
  return toast(toastBody, toastOptions);
};

export const updateToast = (id: ToastId, title: string | undefined, text: string) => {
  toast.update(id, {
    render: getToastBody(title, text)
  });
};

export const dismissToast = (id: ToastId) => {
  toast.dismiss(id);
};
