import React, { useEffect, useMemo, useState } from "react";
import { Panel, DropdownButton, MenuItem, Button } from "react-bootstrap";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { showToast } from "../../utils/toastWrapper";
import Icon from "../common/Icon";
import DeleteModal from "../common/DeleteModal";
import { capitalize, uuid } from "../../shared/utils/helpers";
import MileageListItem from "./MileageListItem";
import MileageMap from "./MileageMap";
import Empty from "../common/Empty";
import CheckboxInput from "../common/CheckboxInput";
import TextFieldGroup from "../common/TextFieldGroup";
import AutoListLoader from "../common/AutoListLoader";
import Spinner from "../common/Spinner";
import { saveExpense, saveMileage, useExpenses, useMileage, useReports, useUserConfiguration } from "../../shared/queries/queries";
import "../../styles/lists.css";
import "../../styles/map.css";
import { Expense, Mileage, Tollstation } from "../../shared/types";
import {
  createExpenseFromTollStationPassing,
  getBestDrivingExpenseType,
  getBestTollstationExpenseType,
  getExpenseFromMileage
} from "../../shared/utils/expenseUtils";
import ButtonWithSpinner from "../common/ButtonWithSpinner";

type MileageOrderBy = "created" | "changed" | "starts" | "description" | "addressFrom" | "totalMeters";
interface MileageListProps {
  mileages: Mileage[];
  orderBy?: MileageOrderBy;
  orderDesc?: boolean;
  searchString?: string;
  itemsPerPage?: number;
  title?: string;
  description?: string;
  showHeader?: boolean;
  showRefreshButton?: boolean;
  refreshMileages?: () => void;
  isLoading?: boolean;
  isFetching?: boolean;
  errorMessage?: string;
  readOnly?: boolean;
}

const MileageList = ({
  mileages,
  orderBy: orderByDefault = "changed",
  orderDesc: orderDescDefault = true,
  searchString: searchStringDefault = "",
  itemsPerPage = 20,
  title = "",
  description = "",
  showHeader,
  showRefreshButton,
  refreshMileages,
  isLoading,
  isFetching,
  errorMessage,
  readOnly
}: MileageListProps) => {
  const [t] = useTranslation();

  const [orderBy, setOrderBy] = useState<MileageOrderBy>(orderByDefault);
  const [orderDesc, setOrderDesc] = useState(orderDescDefault);
  const [showItems, setShowItems] = useState(itemsPerPage);
  const [searchString, setSearchString] = useState(searchStringDefault);
  const [searching, setSearching] = useState(false);
  const [deletingMileage, setDeletingMileage] = useState<Mileage | null>(null);
  const [mileageToPreview, setMileageToPreview] = useState<Mileage | null>(null);
  const [mileagePreviewIsForExpenseCreation, setMileagePreviewIsForExpenseCreation] = useState(false);
  const [mileageExpenseDescription, setMileageExpenseDescription] = useState("");
  const [tollStationsForExpenseCreation, setTollStationsForExpenseCreation] = useState<Tollstation[]>([]);
  const [tollStationsForExpenseCreationSelected, setTollStationsForExpenseCreationSelected] = useState<(Tollstation | null)[]>([]);
  const [createExpenseFromMileageChecked, setCreateExpenseFromMileageChecked] = useState(true);
  const [creatingExpenseFromMileage, setCreatingExpenseFromMileage] = useState(false);
  const [hideUsedTracks, setHideUsedTracks] = useState(false);

  const userConfigurationQuery = useUserConfiguration();
  const reportsQuery = useReports();
  const expensesQuery = useExpenses();
  const mileageQuery = useMileage(mileageToPreview ? mileageToPreview.uuid : "");

  // The list has a local search function, but also accepts a search string from parent
  // Update local search string with anything passed down by parent that is not null or undefined, blank strings are OK
  useEffect(() => {
    if (searchStringDefault !== null && searchStringDefault !== undefined && searchStringDefault !== searchString) {
      setSearchString(searchStringDefault);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchStringDefault]);

  // Get all non-deleted expenses from reports and standalone expenses and grab all the mileageTrackUuuids
  // We use this to indicate already-linked mileage tracks
  interface MileageUuidLinkCount {
    [mileageTrackUuid: string]: number;
  }
  const connectedMileageUuidCounts: MileageUuidLinkCount = useMemo(() => {
    const mileageTrackUuidsFound: string[] = [];
    if (reportsQuery.data) {
      // All tracks linked to expenses on reports
      const exps: Expense[] = reportsQuery.data.reports
        .filter((o) => !o.deleted)
        .reduce((expenses: Expense[], report) => [...expenses, ...report.expenses.filter((o) => !o.deleted && o.mileageTrackUuid)], []);
      mileageTrackUuidsFound.push(...exps.map((o) => o.mileageTrackUuid));
    }
    if (expensesQuery.data) {
      // All tracks linked to standalone expenses
      mileageTrackUuidsFound.push(...expensesQuery.data.expenses.filter((o) => !o.deleted && o.mileageTrackUuid).map((o) => o.mileageTrackUuid));
    }

    const ret = mileageTrackUuidsFound.reduce((acc: MileageUuidLinkCount, u) => {
      acc[u] = acc[u] ? acc[u] + 1 : 1;
      return acc;
    }, {});

    return ret;
    // return ret.filter((v, i, a) => a.indexOf(v) === i); // distinct
  }, [reportsQuery.data, expensesQuery.data]);

  const numberOfConnectedMileageUuids = Object.keys(connectedMileageUuidCounts).filter((v, i, a) => a.indexOf(v) === i).length; // distinct

  // Order change handler
  const setOrder = (orderKey: MileageOrderBy) => {
    // Sort descending by default for changed, created, starts
    let orderDescNew = true;
    // Sort ascending by default for the rest
    if (["description", "addressFrom", "totalMeters"].includes(orderKey)) orderDescNew = false;
    // If we're reordering by the same key as last time, just invert the order
    if (orderKey === orderBy) orderDescNew = !orderDesc;
    setOrderBy(orderKey);
    setOrderDesc(orderDescNew);
  };

  // Search string change handler
  const setFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchStringNew = event.target.value;
    setSearchString(searchStringNew);
  };

  // (Re)build an array of mileage UUIDs and their searchable strings concatenated ("description, addressFrom - addressTo (nn km)"), for later use as a search index
  const searchIndex = useMemo(() => {
    const index = mileages.map((mileage) => ({
      uuid: mileage.uuid,
      searchable: `${mileage.description} ${mileage.addressFrom} ${mileage.addressTo} ${mileage.totalMeters / 1000} km`
    }));
    return index;
  }, [mileages]);

  // Bail out here if we don't have a valid user configuration yet
  if (!userConfigurationQuery.data) {
    console.log("MileagesList: No userconfig or static data, bailing out");
    return <Spinner size="75px" margin="1em" />;
  }
  // Get the default driving expense type from config
  const userConfiguration = userConfigurationQuery.data.configuration;
  const defaultDrivingExpenseType = getBestDrivingExpenseType(userConfiguration.product.expenseTypes);
  const defaultTollStationExpenseType = getBestTollstationExpenseType(userConfiguration.product.expenseTypes);

  // Build a list of mileages based on the current filter and order
  const filteredOrderedMileages = () => {
    let orderedMileages = [...mileages];

    // If "hide used tracks" is checked, filter items that have already generated mileage expenses
    if (hideUsedTracks) {
      orderedMileages = orderedMileages.filter((o) => !connectedMileageUuidCounts[o.uuid] || connectedMileageUuidCounts[o.uuid] === 0);
    }
    if (orderBy === "changed") {
      orderedMileages.sort((a, b) => {
        return moment(a.changed || a.created).unix() - moment(b.changed || b.created).unix();
      });
    } else if (orderBy === "created") {
      orderedMileages.sort((a, b) => {
        return moment(a.created || a.changed).unix() - moment(b.created || b.changed).unix();
      });
    } else if (orderBy === "starts") {
      orderedMileages.sort((a, b) => {
        return moment(a.starts).unix() - moment(b.starts).unix();
      });
    } else {
      orderedMileages.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));
    }

    if (orderDesc) orderedMileages.reverse();
    if (searchString !== "") {
      // Use searchString to find matching expense UUIDs in the search index
      const matchingUuids = searchIndex.filter((o) => o.searchable.toLowerCase().includes(searchString.toLowerCase())).map((o) => o.uuid);
      // Filter the list against the matching UUIDs
      orderedMileages = orderedMileages.filter((mileage) => matchingUuids.includes(mileage.uuid));
    }
    return orderedMileages;
  };

  const orderedMileages = filteredOrderedMileages();

  const onDeleteMileage = (mileage: Mileage) => {
    setDeletingMileage(mileage);
  };

  const confirmDeleteMileage = async () => {
    if (!deletingMileage) return; // Prevent second dispatch if user double clicks
    const now = moment().toISOString();
    const deletedMileage = { ...deletingMileage, deleted: now, dirty: now };
    saveMileage(deletedMileage, true)
      .then(() => {
        setDeletingMileage(null);
      })
      .catch((err: any) => {
        setDeletingMileage(null);
        showToast({ type: "error", title: t("notifications.mileageDeleteFailed"), text: err?.backendMessage || "" });
      });
  };

  const cancelDeleteMileage = () => {
    setDeletingMileage(null);
  };

  const showMore = () => {
    setShowItems((prev) => prev + itemsPerPage);
  };

  // User attempts to to generate an expense from a mileage list item
  const createExpenseFromMileage = (mileage: Mileage) => {
    // Check for a valid driving expensetype
    if (!defaultDrivingExpenseType) {
      showToast({ title: t("error"), text: t("noDrivingTypeFound"), type: "warning" });
      return;
    }
    const km = parseInt((mileage.totalMeters / 1000).toFixed(0), 10);

    // Reset the flag for creating an expense from the mileage itself (default true)
    setCreateExpenseFromMileageChecked(true);
    // Reset the toll stations listings
    setTollStationsForExpenseCreation([]);
    setTollStationsForExpenseCreationSelected([]);
    // Show the preview map and render any toll stations on it, and have it callback when the user confirms what to do
    setMileageToPreview(mileage);
    setMileagePreviewIsForExpenseCreation(true);
    setMileageExpenseDescription(`${mileage.description} (${km} km)`);
  };

  // Actually create an expense from a mileage, after the user confirms, or we're offline
  const createExpenseFromMileageConfirmed = async (mileage: Mileage) => {
    // Check for a valid driving expensetype
    if (!defaultDrivingExpenseType) {
      showToast({ title: t("error"), text: t("noDrivingTypeFound"), type: "warning" });
      return;
    }

    setCreatingExpenseFromMileage(true);

    const expenseGroupUuid = uuid();
    // Create and save the driving expense, if box is checked
    if (createExpenseFromMileageChecked) {
      try {
        const expense = getExpenseFromMileage(userConfiguration, defaultDrivingExpenseType, mileage, mileageExpenseDescription);
        expense.groupUuid = expenseGroupUuid;
        await saveExpense(expense);
        // Notify user
        showToast({ type: "success", text: t("mileageLogs.expenseCreated") });
      } catch (err: any) {
        setCreatingExpenseFromMileage(false);
        showToast({ type: "error", title: t("notifications.expenseSaveFailed"), text: err?.backendMessage || "" });
      }
    }

    // Do we have any toll station passings?
    if (tollStationsForExpenseCreationSelected) {
      if (defaultTollStationExpenseType) {
        // Create expenses from them as well
        const tollExpenses = tollStationsForExpenseCreationSelected
          .filter((station): station is Tollstation => station !== null)
          .map((station) => createExpenseFromTollStationPassing(station, mileage, defaultTollStationExpenseType, userConfiguration));
        Promise.all(tollExpenses.map((tollExpense) => saveExpense({ ...tollExpense, groupUuid: expenseGroupUuid })))
          .then(() => {
            if (tollExpenses.length > 0) showToast({ type: "success", text: t("mileageLogs.tollExpenseCreated", { count: tollExpenses.length }) });
          })
          .catch((err: any) => {
            showToast({ type: "error", title: t("notifications.expenseSaveFailed"), text: err?.backendMessage || "" });
          });
      } else {
        // We couldn't find a suitable toll station expense type. Notify the user, but continue.
        showToast({ type: "warning", title: "", text: t("tollStations.noTollStationExpenseTypeFound") });
      }
    }

    // Reset state
    setMileageToPreview(null);
    setMileagePreviewIsForExpenseCreation(false);
    setTollStationsForExpenseCreation([]);
    setTollStationsForExpenseCreationSelected([]);
    setCreatingExpenseFromMileage(false);
  };

  // User attempts to show a mileage list item on a map
  const showMap = (mileage: Mileage) => {
    setMileageToPreview(mileage);
    setMileagePreviewIsForExpenseCreation(false);
  };

  const toggleHideUsedTracks = () => {
    setHideUsedTracks((prev) => !prev);
  };

  const orderTitle = `${t("ordering.by")} ${t(`ordering.${orderBy}`)} ${orderDesc ? t("ordering.descending") : ""}`;

  const header = (
    <Panel.Heading>
      <div className="widgets">
        {searching ? (
          <Button
            className="active-widget"
            onClick={() => {
              setSearching(false);
              setSearchString("");
            }}
          >
            <Icon icon="close" title={t("clearSearch")} />
          </Button>
        ) : (
          <Button onClick={() => setSearching(true)}>
            <Icon icon="search" title={capitalize(t("searchMileages"))} />
          </Button>
        )}
        <DropdownButton
          title={<Icon icon="sort" title={`${capitalize(t("ordering.ordering"))}: ${orderTitle}`} />}
          id="bg-nested-dropdown"
          noCaret
          pullRight
          onSelect={(eventKey) => setOrder(eventKey)}
        >
          <MenuItem
            eventKey="created"
            className={orderBy === "created" ? "active-widget list-ordering-button " + (orderDesc ? "order-desc" : "order-asc") : ""}
          >
            {t("ordering.created")}
          </MenuItem>
          <MenuItem
            eventKey="changed"
            className={orderBy === "changed" ? "active-widget list-ordering-button " + (orderDesc ? "order-desc" : "order-asc") : ""}
          >
            {t("ordering.changed")}
          </MenuItem>
          <MenuItem
            eventKey="starts"
            className={orderBy === "starts" ? "active-widget list-ordering-button " + (orderDesc ? "order-desc" : "order-asc") : ""}
          >
            {t("ordering.starts")}
          </MenuItem>
          <MenuItem
            eventKey="description"
            className={orderBy === "description" ? "active-widget list-ordering-button " + (orderDesc ? "order-desc" : "order-asc") : ""}
          >
            {t("ordering.description")}
          </MenuItem>
          <MenuItem
            eventKey="addressFrom"
            className={orderBy === "addressFrom" ? "active-widget list-ordering-button " + (orderDesc ? "order-desc" : "order-asc") : ""}
          >
            {t("ordering.address")}
          </MenuItem>
          <MenuItem
            eventKey="totalMeters"
            className={orderBy === "totalMeters" ? "active-widget list-ordering-button " + (orderDesc ? "order-desc" : "order-asc") : ""}
          >
            {t("ordering.distance")}
          </MenuItem>
        </DropdownButton>
        <div className="inbox-list-header-checkbox-wrapper">
          <CheckboxInput
            field="hideUsedTracks"
            value={hideUsedTracks}
            onChange={() => toggleHideUsedTracks()}
            label={t("hideUsedMileageTracks")}
            title={t("hideUsedMileageTracksDescription")}
          ></CheckboxInput>
        </div>
      </div>

      {searching ? (
        <div className="search-wrapper">
          <Icon icon="search" />
          <input autoFocus type="text" className="search-box" placeholder={capitalize(t("searchMileages"))} onChange={setFilter} />
        </div>
      ) : (
        <div className="info">
          <div className="title">
            {title}
            <span className="item-count">{` (${(mileages.length - numberOfConnectedMileageUuids).toString()} / ${mileages.length.toString()})`}</span>
            {showRefreshButton && (
              <Button
                bsSize="xs"
                onClick={() => refreshMileages && refreshMileages()}
                className={`refresh ${isFetching ? "spin spin-fast" : ""}`}
                disabled={isFetching}
              >
                <Icon icon="refresh" />
              </Button>
            )}
          </div>
          <div className="description">{description}</div>
        </div>
      )}

      <div className="clearfix" />
    </Panel.Heading>
  );

  const columnNames = (
    <React.Fragment>
      <div className="table-header item-checkbox">&nbsp;</div>
      <div className="table-header item-dates">
        <div
          className={`table-header-clickable ${orderBy === "starts" ? (orderDesc ? "order-desc" : "order-asc") : ""}`}
          onClick={() => setOrder("starts")}
        >
          {capitalize(t("tableHeaders.time"))}
        </div>
      </div>
      <div className="table-header item-description">
        <div
          className={`table-header-clickable ${orderBy === "description" ? (orderDesc ? "order-desc" : "order-asc") : ""}`}
          onClick={() => setOrder("description")}
        >
          {capitalize(t("tableHeaders.description"))}
        </div>
      </div>
      <div className="table-header item-addresses">
        <div
          className={`table-header-clickable ${orderBy === "addressFrom" ? (orderDesc ? "order-desc" : "order-asc") : ""}`}
          onClick={() => setOrder("addressFrom")}
        >
          {capitalize(t("tableHeaders.address"))}
        </div>
      </div>
      <div className="table-header item-icons">&nbsp;</div>
      <div className="table-header item-distance">
        <div
          className={`table-header-clickable ${orderBy === "totalMeters" ? (orderDesc ? "order-desc" : "order-asc") : ""}`}
          onClick={() => setOrder("totalMeters")}
        >
          {capitalize(t("tableHeaders.distance"))}
        </div>
      </div>
      <div className="table-header item-actions">&nbsp;</div>
    </React.Fragment>
  );

  const visibleMileages = orderedMileages.slice(0, showItems);

  const listClass = "list table";

  if (isLoading) {
    return (
      <div className="mileage-list">
        <Panel>
          {showHeader && header}
          <div className={listClass}>
            <Spinner size="75px" margin="1em" />
          </div>
        </Panel>
      </div>
    );
  }

  return (
    <div className="mileage-list">
      <Panel>
        {showHeader && header}
        {errorMessage && <div className="list-load-error">{errorMessage}</div>}
        <div className={listClass}>
          {visibleMileages.length > 0 && columnNames}
          {visibleMileages.map((mileage, index) => (
            <React.Fragment key={mileage.uuid}>
              <MileageListItem
                key={mileage.uuid}
                mileage={mileage}
                readOnly={readOnly}
                alternate={index % 2 === 0}
                timesLinked={connectedMileageUuidCounts[mileage.uuid]}
                onDelete={onDeleteMileage}
                onCreateExpenseFromMileage={!mileageToPreview || mileageToPreview.uuid !== mileage.uuid ? createExpenseFromMileage : undefined}
                disableCreateExpenseFromMileage={mileageToPreview && mileageToPreview.uuid === mileage.uuid ? true : false}
                onShowMap={showMap}
                showMapPreviewButton={true}
              />

              {mileageToPreview && mileageToPreview.uuid === mileage.uuid && (
                <div className="mileage-between-items">
                  <Button
                    className="close-button"
                    onClick={() => {
                      setMileageToPreview(null);
                      setMileagePreviewIsForExpenseCreation(false);
                      setTollStationsForExpenseCreation([]);
                      setTollStationsForExpenseCreationSelected([]);
                    }}
                  >
                    <Icon icon="close" />
                  </Button>

                  <MileageMap
                    halfSize={mileagePreviewIsForExpenseCreation}
                    mileage={mileageQuery.data && !mileageQuery.isFetching ? mileageQuery.data : undefined}
                    tollStationsLoaded={(stations: Tollstation[]) => {
                      setTollStationsForExpenseCreation(stations);
                      setTollStationsForExpenseCreationSelected(stations);
                    }}
                  />

                  {mileagePreviewIsForExpenseCreation && (
                    <div className="mileage-expense-creation">
                      <TextFieldGroup
                        field="expense-description"
                        value={mileageExpenseDescription}
                        onChange={(value: string) => setMileageExpenseDescription(value)}
                        placeholder={t("description")}
                        label={capitalize(t("create"))}
                      />
                      <CheckboxInput
                        key="mileageExpenseMain"
                        field="mileageExpenseMain"
                        value={true}
                        label={`${t("mileageLogs.kmDetails")}: ${mileage.totalMeters ? (mileage.totalMeters / 1000).toFixed(1) : "0"} km`}
                        onChange={(checked: boolean) => {
                          setCreateExpenseFromMileageChecked(checked);
                        }}
                      />
                      {tollStationsForExpenseCreation.map((station, stationIndex) => {
                        const stationIsChecked = tollStationsForExpenseCreationSelected[stationIndex] !== null;
                        return (
                          <CheckboxInput
                            field={`mileageExpenseStation${stationIndex}`}
                            key={`mileageExpenseStation${stationIndex}`}
                            value={stationIsChecked}
                            label={`${capitalize(t("mileageLogs.tollStationPassing"))}: kr ${station.chargeSmallCar} (${station.name})`}
                            onChange={(checked: boolean) => {
                              if (checked) {
                                const newSelected = tollStationsForExpenseCreationSelected.map((st, idx) => (idx === stationIndex ? station : st));
                                setTollStationsForExpenseCreationSelected(newSelected);
                                // Add to tollStationsForExpenseCreationSelected
                                // if (!tollStationsForExpenseCreationSelected.find((o) => o.id === station.id && o.passedWithMetering === station.passedWithMetering))
                                //   setTollStationsForExpenseCreationSelected((current) => [...current, station]);
                              } else {
                                const newSelected = tollStationsForExpenseCreationSelected.map((st, idx) => (idx === stationIndex ? null : st));
                                setTollStationsForExpenseCreationSelected(newSelected);
                                // Remove from tollStationsForExpenseCreationSelected
                                // if (tollStationsForExpenseCreationSelected.find((o) => o.id === station.id && o.passedWithMetering === station.passedWithMetering))
                                //   setTollStationsForExpenseCreationSelected((current) => current.filter((o) => o.id !== station.id && (o.id === station.id && o.passedWithMetering !== station.passedWithMetering)));
                              }
                            }}
                          />
                        );
                      })}
                      <ButtonWithSpinner
                        bsStyle="success"
                        showSpinner={creatingExpenseFromMileage}
                        disabled={
                          creatingExpenseFromMileage ||
                          (!createExpenseFromMileageChecked && !tollStationsForExpenseCreationSelected.find((o) => o !== null))
                        }
                        onClick={() => createExpenseFromMileageConfirmed(mileage)}
                      >
                        {t("confirm")}
                      </ButtonWithSpinner>
                    </div>
                  )}
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
        {orderedMileages.length === 0 && <Empty text={t("noMileagesFound")} />}
      </Panel>
      <AutoListLoader active={showItems < orderedMileages.length} loadMoreFunction={showMore} />
      <DeleteModal show={!!deletingMileage} cancel={cancelDeleteMileage} confirm={confirmDeleteMileage} />
    </div>
  );
};

export default MileageList;
