import { jsonFetch, jsonFetchWithServerTime } from "./api";
import { ApiExpenseListResponse, Expense } from "../types";

/**
 * Gets all non-deleted expenses for the currently logged in user.
 * @param changedSince - If this is included only expenses changed after this date will be returned
 * @param onlyStandalone - If true, only standalone expenses (not placed on a report) will be returned
 * @returns {response} - An object containing an array of expenses and serverTime (string)
 */
export const list = async (changedSince?: string, onlyStandalone?: boolean): Promise<ApiExpenseListResponse> => {
  const fetchOptions = {
    method: "GET"
  };
  const qs = changedSince ? `?changedSince=${changedSince}` : "?skipDeleted=true";
  const endPoint = onlyStandalone ? "e/expense/liststandalone" : "e/expense/list";
  const { body: expenses, serverTime } = await jsonFetchWithServerTime(`${endPoint}${qs}`, fetchOptions);
  return {
    expenses,
    serverTime
  };
};

/**
 * Gets a single expense by uuid
 * @param {string} uuid The uuid of the expense
 * @returns {expense} - A single expense object containing
 * attachments
 * expenseCustomValues
 */
export async function details(uuid: string): Promise<Expense> {
  const fetchOptions = {
    method: "GET"
  };
  return jsonFetch(`e/expense/${uuid}`, fetchOptions);
}

// Save a single expense
export async function save(expense: Expense, fetchOpts?: any): Promise<Expense> {
  const fetchOptions = {
    method: "POST",
    body: JSON.stringify(expense),
    ...fetchOpts
  };
  return jsonFetch(`e/expense/save`, fetchOptions);
}

/**
 * Deletes a single expense by uuid
 * @param {number} uuid The uuid of the expense
 */
export async function del(uuid: string): Promise<void> {
  const fetchOptions = {
    method: "DELETE"
  };
  return jsonFetch(`e/expense/${uuid}`, fetchOptions);
}
