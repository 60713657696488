import moment from "moment";
import i18n from "../../i18n/i18nConfig";
import * as exchangeRatesApi from "../../api/exchangeRates";
import { autoExpenseGroupConstructor } from "../../constructors/reportCalculationConstructors";
import { getAutoExpensesV1 } from "./autoExpensesV1";
import { getAutoExpensesV2 } from "./autoExpensesV2";
import { getAutoExpensesV3 } from "./autoExpensesV3";
import { getAutoExpensesV4 } from "./autoExpensesV4";
import { getAutoExpensesV5 } from "./autoExpensesV5";
import { getAutoExpensesV6 } from "./autoExpensesV6";
import { getAutoExpensesV7 } from "./autoExpensesV7";
import { getAutoExpensesV8 } from "./autoExpensesV8";
import { getAutoExpensesV9 } from "./autoExpensesV9";
import { getAutoExpensesV10 } from "./autoExpensesV10";
import { getAutoExpensesV11 } from "./autoExpensesV11";
import { getAutoExpensesV12 } from "./autoExpensesV12";
import { getAutoExpensesV13 } from "./autoExpensesV13";
import { getAutoExpensesV14 } from "./autoExpensesV14";
import { getAutoExpensesV15 } from "./autoExpensesV15";
import { getAutoExpensesV16 } from "./autoExpensesV16";
import { getAutoExpensesV22 } from "./autoExpensesV22";
import { getAutoExpensesV30 } from "./autoExpensesV30";
import { getAutoExpensesV31 } from "./autoExpensesV31";
import { getAutoExpensesV32 } from "./autoExpensesV32";
import { getAutoExpensesV33 } from "./autoExpensesV33";
import { getAutoExpensesV34 } from "./autoExpensesV34";
// import { getAutoExpensesV35 } from "./autoExpensesV35";
import { getAutoExpensesV36 } from "./autoExpensesV36";
import { getAutoExpensesV37 } from "./autoExpensesV37";
import { getAutoExpensesV38 } from "./autoExpensesV38";
import { getAutoExpensesV39 } from "./autoExpensesV39";
import { getAutoExpensesV40 } from "./autoExpensesV40";

import { getAutoExpensesV34_oneco } from "./custom/autoExpensesV34_oneco";
// import { getAutoExpensesV35_oneco } from "./custom/autoExpensesV35_oneco";
import { getAutoExpensesV36_oneco } from "./custom/autoExpensesV36_oneco";
import { getAutoExpensesV38_oneco } from "./custom/autoExpensesV38_oneco";
import { getAutoExpensesV39_oneco } from "./custom/autoExpensesV39_oneco";

let geography = { countries: [], majorCities: [] };

// Local cache for official rates
let rates = [];

// Local cache for exchange rates from Norges Bank, used to convert foreign currency in diet
let exchangeRatesNBCache = {};
// Set this as soon as we've populated the cache with the initial seed data from the client, so we don't overwrite it on subsequent calls
let exchangeRatesNBCachePopulated = false;
// Keep track of the individual dates requested from the backend, so we don't send multiple requests for the same date
let exchangeRatesNBDatesRequested = [];

// Used by the calculator to get out the correct official rate for a given location on a given day
// If no match is found return null, which tells the calculator to use whatever the default norwegian value is at the time
export function getRateBySegmentDay(segmentDay) {
  let ret = null;

  if (segmentDay.segment.cityId || segmentDay.segment.countryId) {
    // We have a location. Grab a rateset that fits the date
    const set = [...rates] // Spread to prevent state mutation, since sort is in-place
      .sort((a, b) => new Date(b.validFrom) - new Date(a.validFrom))
      .find((o) => moment(o.validFrom).isSameOrBefore(moment(segmentDay.from)));

    if (set) {
      // We have an applicable rateset, now figure out which rate to use based on geography
      if (segmentDay.segment.cityId) {
        // Try finding a rate by cityid
        const cityrate = set.officialRates.find((o) => o.geoCityId === segmentDay.segment.cityId);
        if (cityrate) {
          ret = cityrate;
        } else {
          const city = geography.majorCities.find((o) => o.cityId === segmentDay.segment.cityId);
          //if (city === null) city = await ttContext.GeoCities.Where(o => o.Id == segmentDay.Segment.CityId).FirstOrDefaultAsync();
          // TODO: Maybe hit API if we can't find the city in local store?
          if (city) {
            const countryrate = set.officialRates.find((o) => o.geoCountryId === city.countryId);
            if (countryrate) ret = countryrate;
          }
        }
      }

      if (segmentDay.segment.countryId && !ret) {
        // Try finding a rate by countryid
        const countryrate = set.officialRates.find((o) => o.geoCountryId === segmentDay.segment.countryId);
        if (countryrate) {
          ret = countryrate;
        } else {
          //Try finding a rate by continent
          const country = geography.countries.find((gc) => gc.id === segmentDay.segment.countryId);
          if (country) {
            const continentrate = set.officialRates.find((o) => o.geoContinentId === country.continentId);
            if (continentrate) ret = continentrate;
          }
        }
      }
    }
  }

  // console.log("-----------");
  // console.log("getRateBySegmentDay running, ret is", ret);

  if (ret && ret.foreignCurrency !== "" && ret.foreignCurrencyDiet !== 0) {
    // The rate we found is in a foreign currency, and diet is 0 at this point
    // We need to calculate the diet in NOK based on the foreign currency
    // Use the start date of the segment for this so we get the same sum for all days in the segment (and reduce load / backend calls)
    // We might want to change this to individual day calculations, depending on how accountants interpret the rules
    var dateForExchangeRates = moment(segmentDay.segment.starts).format("YYYY-MM-DD");

    // Do we have the rates for this date cached already?
    let exchangeRatesForDay = exchangeRatesNBCache[dateForExchangeRates];
    //console.log("Foreign currency diet found. Datekey is " + dateForExchangeRates + ", cache hit:", exchangeRatesForDay);
    if (!exchangeRatesForDay) {
      // We didn't find an exact match for the date
      // Fetch it from the backend, but since this is a synchronous function, *don't wait* for the result
      // Just dump it into the cache when it arrives, and it will be used the next time reportcalculation runs
      //console.log("Date not found in cache, checking if we should fetch from backend");
      if (exchangeRatesNBDatesRequested.indexOf(dateForExchangeRates) === -1) {
        //console.log("Request not sent, sending it now");
        exchangeRatesNBDatesRequested.push(dateForExchangeRates);
        exchangeRatesApi.exchangeRatesNB(dateForExchangeRates).then((newRates) => {
          //console.log("Rates fetched from backend, updating cache");
          exchangeRatesNBCache[dateForExchangeRates] = newRates;
        });
      } else {
        //console.log("Backend request for ratedate already sent, skipping new request");
      }

      // We also need to find the closest rates we actually *have* cached
      const allDates = Object.keys(exchangeRatesNBCache).map((o) => Number(o.replaceAll("-", "")));
      const target = Number(dateForExchangeRates.replaceAll("-", ""));
      const closest = allDates.reduce((prev, curr) => (Math.abs(curr - target) < Math.abs(prev - target) ? curr : prev));
      const closestKey = closest.toString().substring(0, 4) + "-" + closest.toString().substring(4, 6) + "-" + closest.toString().substring(6, 8);
      //console.log("Closest cache key found:", closestKey);
      exchangeRatesForDay = exchangeRatesNBCache[closestKey];
    }

    //console.log("Using rates:", exchangeRatesForDay);
    let factor = 0;
    if (exchangeRatesForDay) {
      const dayRate = exchangeRatesForDay.find((o) => o.code === ret.foreignCurrency);
      if (dayRate) {
        factor = dayRate.rate;
      } else {
        //console.log("ERROR - SHOULD NOT HAPPEN: Currency", ret.foreignCurrency, "not found in rates!");
      }
    }
    //console.log("Using conversion factor:", factor, "for currency", ret.foreignCurrency);

    // Don't set ret.diet, this will pollute the cache. Return a new OfficialRate instead, with diet set to the NOK equivalent of the foreign currency rate on the given date
    ret = { ...ret, diet: ret.foreignCurrencyDiet * factor };
  }
  //console.log("Returning rate by segmentday:", ret);
  return ret;
}

// Used by the calculator to get out the correct official rate for a given segment based on startdate. If no match is found return null, which tells the calculator to use whatever the default norwegian value is at the time
// In recent versions of the calculator, the information here is only used for geographic segment grouping, so we don't need to handle exchange rates here for rates with diet=0 that uses foreign currency
export function getRateBySegment(segment) {
  let ret = null;

  if (segment.cityId || segment.countryId) {
    // We have a location
    // We have a location. Grab a rateset that fits the dat
    const set = [...rates] // Spread to prevent state mutation, since sort is in-place
      .sort((a, b) => new Date(b.validFrom) - new Date(a.validFrom))
      .find((o) => moment(o.validFrom).isSameOrBefore(moment(segment.starts)));

    if (set) {
      // We have an applicable rateset, now figure out which rate to use based on geography
      if (segment.cityId) {
        // Try finding a rate by cityid

        const cityrate = set.officialRates.find((o) => o.geoCityId === segment.cityId);

        if (cityrate) {
          ret = cityrate;
        } else {
          const city = geography.majorCities.find((o) => o.cityId === segment.cityId);
          // if (city == null) city = await ttContext.GeoCities.Where(o => o.Id == segment.CityId).FirstOrDefaultAsync();
          // TODO: Maybe hit API if we can't find the city in local store?

          if (city) {
            const countryrate = set.officialRates.find((o) => o.geoCountryId === city.countryId);
            if (countryrate) ret = countryrate;
          }
        }
      }

      if (segment.countryId && !ret) {
        // Try finding a rate by countryid
        const countryrate = set.officialRates.find((o) => o.geoCountryId === segment.countryId);
        if (countryrate) {
          ret = countryrate;
        } else {
          //Try finding a rate by continent
          const country = geography.countries.find((gc) => gc.id === segment.countryId);
          if (country) {
            const continentrate = set.officialRates.find((o) => o.geoContinentId === country.continentId);
            if (continentrate) ret = continentrate;
          }
        }
      }
    }
  }

  return ret;
}

// If the report belongs to Biltema, the calculator can use this function to calculate the max refundable sum the report should allow
export function biltemaCap(segments) {
  // // Naive approach: sum total hours, disregarding day boundaries, pauses, etc
  // int totalhours = Convert.ToInt32(Math.Floor(segments.Select(o => (o.Stops - o.Starts).TotalHours).Sum()));
  // int days = Convert.ToInt32(Math.Floor(Convert.ToDouble(totalhours) / 24));
  // int hours = totalhours % 24;

  // int sum = days * 300;
  // if (hours >= 9)
  // {
  //     sum += 300;
  // }
  // else if (hours >= 5)
  // {
  //     sum += 200;
  // }

  // return sum * 100; // Always in hundredths
  return 0;
}

// Picks the correct ruleset for autoexpense (rates) calculation. Expand as neccessary when rules change
export function getAutoExpenses(report, configuration, officialRates, countries, majorCities, exchangeRatesNBSeed) {
  rates = officialRates;
  geography = { countries, majorCities };
  if (!exchangeRatesNBCachePopulated && exchangeRatesNBSeed && exchangeRatesNBSeed.length > 0) {
    for (const xrdate of exchangeRatesNBSeed) {
      exchangeRatesNBCache[xrdate.validFrom] = xrdate.rates;
    }
    exchangeRatesNBCachePopulated = true;
  }

  // console.log(
  //   "getAutoExpenses running, exchangeRatesNBCachePopulated is ",
  //   exchangeRatesNBCachePopulated,
  //   "exchangeRatesNBCache keys length is",
  //   Object.keys(exchangeRatesNBCache).length
  // );
  const ret = []; // Array of autoExpenseGroup

  // First check if we're using custom ratetypes (report.RateType > 1000)
  // These are custom rulesets for groups of individual companies, and this option should only be exposed to those companies in the frontend
  if (report.rateType === 1001) {
    // OneCo, ref TT-1107 in Jira
    // const rateDate = moment(report.rateDate);
    // Add dated versions here
    if (report.reportSegments.filter((o) => !o.deleted).length === 0) return []; // No segments exists
    const rateDate = moment(report.rateDate);
    if (rateDate.isBefore("2022-01-01")) return getAutoExpensesV34_oneco(report, configuration);
    // if (rateDate.isBefore("2022-01-01")) return getAutoExpensesV35_oneco(report, configuration);
    if (rateDate.isBefore("2023-01-01")) return getAutoExpensesV36_oneco(report, configuration);
    if (rateDate.isBefore("2023-09-01")) return getAutoExpensesV38_oneco(report, configuration);
    return getAutoExpensesV39_oneco(report, configuration);
  }

  // Pre 2014-12-12, when segments were calculated individually
  const created = moment(report.created);
  if (created.isBefore("2014-12-12")) {
    // Calculate rates for each segment
    report.reportSegments
      .filter((o) => !o.deleted)
      .forEach((segment) => {
        let segmentExpenses = [];

        if (created.isBefore("2011-06-17")) segmentExpenses = getAutoExpensesV1(report, segment);
        if (created.isSameOrAfter("2011-06-17") && created.isBefore("2012-01-01")) segmentExpenses = getAutoExpensesV2(report, segment);
        if (created.isSameOrAfter("2012-01-01") && created.isBefore("2012-01-10")) segmentExpenses = getAutoExpensesV3(report, segment);
        if (created.isSameOrAfter("2012-01-10") && created.isBefore("2013-01-23")) segmentExpenses = getAutoExpensesV4(report, segment);
        if (created.isSameOrAfter("2013-01-23") && created.isBefore("2014-01-21")) segmentExpenses = getAutoExpensesV5(report, segment);
        if (created.isSameOrAfter("2014-01-21") && created.isBefore("2014-01-31")) segmentExpenses = getAutoExpensesV6(report, segment);
        if (created.isSameOrAfter("2014-01-31")) segmentExpenses = getAutoExpensesV7(report, segment);

        // Do we have any rate items?
        if (segmentExpenses.length > 0) {
          // Populate the descriptive fields based on the first rate item, and add the list of rate items itself
          const firstExpense = segmentExpenses[0];
          const loc = firstExpense.location;
          let desc = `${i18n.t("calculation.officialDietFor")} ${loc}`; // state_diet
          if (firstExpense.autoExpenseType === "state_lodging") desc = `${i18n.t("calculation.officialLodgingFor")} ${loc}`;
          if (firstExpense.autoExpenseType === "custom_diet") desc = `${i18n.t("calculation.customDietFor")} ${loc}`;
          if (firstExpense.autoExpenseType === "custom_lodging") desc = `${i18n.t("calculation.customLodgingFor")} ${loc}`;

          const group = autoExpenseGroupConstructor();
          group.autoExpenses = segmentExpenses;
          group.autoExpenseType = firstExpense.autoExpenseType;
          group.description = desc;
          group.fromDate = segment.starts;
          group.location = loc;
          group.toDate = segment.stops;
          ret.push(group);
        }
      });
    return ret;
  } else {
    // Post 2014-12-12, when it got more complicated
    // As of 2017-01-17 we're looking at report.RateDate instead of report.Created to decide on a calculator version
    // The migration has populated RateDate from Created, so no archived reports will be affected. Hence, we can do this for all of the calcs below and continue versioning as usual
    if (report.reportSegments.filter((o) => !o.deleted).length === 0) return []; // No segments exists
    const rateDate = moment(report.rateDate);
    if (rateDate.isBefore("2015-01-01")) return getAutoExpensesV8(report);
    if (rateDate.isBefore("2015-02-20")) return getAutoExpensesV9(report);
    if (rateDate.isBefore("2016-01-01")) return getAutoExpensesV10(report);
    if (rateDate.isBefore("2016-10-01")) return getAutoExpensesV11(report);
    if (rateDate.isBefore("2017-01-01")) return getAutoExpensesV12(report);
    if (rateDate.isBefore("2017-01-05")) return getAutoExpensesV13(report);
    if (rateDate.isBefore("2017-04-22")) return getAutoExpensesV14(report);
    if (rateDate.isBefore("2017-05-05")) return getAutoExpensesV15(report);
    if (rateDate.isBefore("2018-11-09")) return getAutoExpensesV16(report);
    if (rateDate.isBefore("2019-01-01")) return getAutoExpensesV22(report);
    if (rateDate.isBefore("2020-01-01")) return getAutoExpensesV30(report, configuration);
    if (rateDate.isBefore("2020-06-01")) return getAutoExpensesV31(report, configuration);
    if (rateDate.isBefore("2021-01-01")) return getAutoExpensesV32(report, configuration);
    if (rateDate.isBefore("2021-04-05")) return getAutoExpensesV33(report, configuration);
    if (rateDate.isBefore("2022-01-01")) return getAutoExpensesV34(report, configuration);
    //if (rateDate.isBefore("2022-01-01")) return getAutoExpensesV35(report, configuration); // Skip this and go directly to v36, it was announced retroactively
    if (rateDate.isBefore("2022-12-01")) return getAutoExpensesV36(report, configuration);
    if (rateDate.isBefore("2023-01-01")) return getAutoExpensesV37(report, configuration);
    if (rateDate.isBefore("2023-09-01")) return getAutoExpensesV38(report, configuration);
    if (rateDate.isBefore("2024-01-01")) return getAutoExpensesV39(report, configuration);
    return getAutoExpensesV40(report, configuration);
  }
}
