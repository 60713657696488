import moment from "moment";
import { uuid } from "../utils/helpers";
import i18n from "../i18n/i18nConfig";

import { capitalize } from "../utils/helpers";
import { reportSegmentConstructor } from "./reportSegmentConstructor";
import { calculationConstructor } from "./reportCalculationConstructors";
import { Report } from "../types";

const createReportPurpose = (created: string): string => {
  const purpose = `${i18n.t("my")} ${i18n.t("report")} ${moment(created).format("L")}`;
  return capitalize(purpose);
};

const createReportDescription = (created: string, reportType: 1 | 2 | 3): string => {
  let description = "";
  switch (reportType) {
    case 1: {
      description += i18n.t("travelReport");
      break;
    }
    case 2: {
      description += i18n.t("expenseReport");
      break;
    }
    default: {
      //3
      description += i18n.t("drivingReport");
      break;
    }
  }
  description += ` ${i18n.t("of")} ${moment(created).format("L")}`;
  return capitalize(description);
};

interface ReportConstructorOptions {
  reportType: 1 | 2 | 3;
  personId: number;
  companyName: string;
  countryName: string;
  countryId: number;
  cityName: string;
  cityId: number;
  starts: string;
  stops: string;
  rateCustomDiet?: number;
}
export const reportConstructor = (opts: ReportConstructorOptions): Report => {
  const { reportType, personId, companyName, countryName, countryId, cityName, cityId, starts, stops, rateCustomDiet = 0 } = opts;
  const created = moment().toISOString();
  const reportUuid = uuid();
  return {
    uuid: reportUuid,
    created,
    changed: "",
    personId,
    status: 1,
    reportTypeId: reportType,
    description: createReportDescription(created, reportType),
    purpose: createReportPurpose(created),
    companyName,
    referenceNumber: "",
    comment: "",
    advance: 0,
    overrideSum: 0,
    overrideSumEnabled: false,
    calculatedSum: 0,
    requiresApproval: true,
    approvalChainId: 0,
    approved: false,
    rateType: 1,
    rateDate: created,
    rateCustomDiet,
    rateCustomMaxLodging: 0,
    archivedDate: "",
    calculation: calculationConstructor(),
    deleted: "",
    expenses: [],
    reportSegments: [
      reportSegmentConstructor({
        reportUuid,
        starts,
        stops,
        countryName,
        countryId,
        cityName,
        cityId
      })
    ],
    reportCustomValues: [],
    attachments: [],
    reportApprovals: [],
    advancedApprovalAttempts: [],
    autoExpenseOverrides: [],
    dirty: created
  };
};
