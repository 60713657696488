import { useEffect, useState } from "react";

// Simple hook for debouncing a value. Will return the updated value after the provided number of milliseconds without changes
const useDebounce = (value: any, ms: number) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, ms);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, ms] // Only re-call effect if value or delay changes
  );
  return debouncedValue;
};

export default useDebounce;
