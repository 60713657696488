import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { capitalize } from "../../shared/utils/helpers";
import { GoogleMap, useJsApiLoader, Polyline, Marker, DirectionsService, DirectionsRenderer } from "@react-google-maps/api";
import { Alert } from "react-bootstrap";
import { mapIcon } from "../common/Icon";
import { useCountries, useMajorCities } from "../../shared/queries/queries";
import { location as getLocation } from "../../shared/api/geography";
import { MileageTrackSegment, ReportSegment, Tollstation } from "../../shared/types";
import Spinner from "../common/Spinner";

// Google maps API key
const mapsApiKey: string = "AIzaSyCxx3k4W2dF2tVZYRj4HTUIp25dbOUZ6_E";

// Default size and positioning unless overridden by props
const defaultHeight = "300px";
const defaultWidth = "100%";

const mapOptions: google.maps.MapOptions = { disableDefaultUI: true };

// Default styling options for polylines (tracks)
const polylineOptions = {
  strokeColor: "#00a1e1",
  strokeOpacity: 0.8,
  strokeWeight: 4,
  fillColor: "#00a1e1",
  fillOpacity: 0.35,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  radius: 30000,
  zIndex: 1
};

// Google map wrapper, capable of showing reportsegments and GPS tracks
interface MapProps {
  trackSegments?: MileageTrackSegment[];
  tollStations?: Tollstation[];
  routeQuery?: { from: string; to: string };
  segments?: ReportSegment[];
  estimatorCallback?: (totalKilometers: number, route?: any) => void;
  cssWidth?: string;
  cssHeight?: string;
}
const Map = ({ trackSegments, tollStations, routeQuery, segments, estimatorCallback, cssWidth, cssHeight }: MapProps) => {
  const [t] = useTranslation();
  const [tracks, setTracks] = useState<JSX.Element[] | null>(null);
  const [trackMarkers, setTrackMarkers] = useState<JSX.Element[] | null>(null);
  const [segmentLines, setSegmentLines] = useState<JSX.Element | null>(null);
  const [segmentMarkers, setSegmentMarkers] = useState<JSX.Element[] | null>(null);
  const [tollMarkers, setTollMarkers] = useState<JSX.Element[] | null>(null);
  const [currentBounds, setCurrentBounds] = useState<google.maps.LatLngBounds | null>(null);
  const [directionsService, setDirectionsService] = useState<JSX.Element | null>(null);
  const [directionsRenderer, setDirectionsRenderer] = useState<JSX.Element | null>(null);
  const [directionsFailed, setDirectionsFailed] = useState(false);

  const actualWidth = cssWidth || defaultWidth;
  const actualHeight = cssHeight || defaultHeight;

  const useCountriesQuery = useCountries();
  const useMajorCitiesQuery = useMajorCities();

  // References to the actual map and infowindow
  const mapRef = useRef<google.maps.Map | null>(null);
  const infowindowRef = useRef<google.maps.InfoWindow | null>(null);

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: mapsApiKey
    //version: "3.48.6"
    //version: "3.47.6"
    // 3.47.6 is the last version that doesn't break when unmounting and mounting a new map.
    // With 3.48 or up, closing a mileage track and opening another will result in an empty grey box.
    // Ref: https://github.com/JustFly1984/react-google-maps-api/issues/2963
    // Remove version flag when this is resolved
    // UPDATE 26.01.2023: version flag removed, bug seems to have been fixed.
  });

  // Effects to rerender various children if they change
  // The initial render of anything passed in is done through onLoad, so we only need to call rerendering if the map has been loaded before
  useEffect(() => {
    if (mapRef.current) renderRouteQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeQuery]);

  useEffect(() => {
    if (mapRef.current) renderTracks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackSegments]);

  useEffect(() => {
    if (mapRef.current) renderTollstations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tollStations]);

  // The map was loaded, run initial child rendering
  const onLoad = useCallback((mapInstance: google.maps.Map) => {
    mapRef.current = mapInstance;
    renderTracks();
    renderTollstations();
    renderSegments();
    renderRouteQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Main map render function
  const renderMap = () => {
    // const bounds = currentBounds || new google.maps.LatLngBounds(new google.maps.LatLng({ lat: 50, lng: -15 }), new google.maps.LatLng({ lat: 70, lng: 30 }));
    return (
      <>
        <GoogleMap
          id="mainmap"
          mapContainerStyle={{
            height: actualHeight,
            width: actualWidth
          }}
          center={currentBounds?.getCenter() || undefined}
          options={mapOptions}
          onLoad={onLoad}
        >
          {tracks}
          {trackMarkers}
          {tollMarkers}
          {segmentMarkers}
          {segmentLines}
          {directionsService}
          {directionsRenderer}
        </GoogleMap>
        {directionsFailed && <Alert bsStyle="warning">{t("unableToEstimateRoute")}</Alert>}
      </>
    );
  };

  // If trackSegments were passed in, we want to render existing mileage tracks as polylines with start and end infowindows
  const renderTracks = () => {
    const coordSets: { lat: number; lng: number }[][] = [];
    const pois: { type: string; description: string; lat: number; lng: number }[] = [];
    // Iterate over each segment
    if (trackSegments && trackSegments.length > 0) {
      trackSegments.forEach((segment) => {
        // Add a coordset from the coords in this segment
        const parsedCoords = segment.coords.map((coord) => ({
          lat: parseFloat(coord.lat),
          lng: parseFloat(coord.lon)
        }));
        coordSets.push(parsedCoords);

        // Add markers for the start and end addresses
        if (segment.addressFrom && segment.coords && segment.coords.length > 0)
          pois.push({
            type: "start",
            description: `<div><strong>${capitalize(t("start"))}</strong>: ${segment.addressFrom}</div>`,
            lat: parsedCoords[0].lat,
            lng: parsedCoords[0].lng
          });

        if (segment.addressTo && segment.coords)
          pois.push({
            type: "end",
            description: `<div><strong>${capitalize(t("stop"))}</strong>: ${segment.addressTo}</div>`,
            lat: parsedCoords[parsedCoords.length - 1].lat,
            lng: parsedCoords[parsedCoords.length - 1].lng
          });
      });
    }

    // Adjust map bounds to track coords
    const allCoords = coordSets.length ? coordSets.reduce((a, b) => [...a, ...b]) : [];
    const bounds = currentBounds || new google.maps.LatLngBounds();
    allCoords.forEach((coord) => bounds.extend(new google.maps.LatLng(coord.lat, coord.lng)));
    if (mapRef.current) mapRef.current.fitBounds(bounds);

    const symFrom = mapIcon("symbolMileageTrackStart");
    const iconAddressFrom = {
      anchor: new google.maps.Point(symFrom.anchor[0], symFrom.anchor[1]),
      url: symFrom.url,
      scaledSize: new google.maps.Size(symFrom.scaledSize[0], symFrom.scaledSize[1])
    };

    const symTo = mapIcon("symbolMileageTrackStop");
    const iconAddressTo = {
      anchor: new google.maps.Point(symTo.anchor[0], symTo.anchor[1]),
      url: symTo.url,
      scaledSize: new google.maps.Size(symTo.scaledSize[0], symTo.scaledSize[1])
    };

    // Generate map markers for each poi
    const markers = pois.map((poi, i) => {
      let symbol = iconAddressFrom;
      if (poi.type === "end") symbol = iconAddressTo;
      // if (poi.type === "station") symbol = iconStation;
      return (
        <Marker
          clickable
          key={`mrk${i}`}
          icon={symbol}
          position={{ lat: poi.lat, lng: poi.lng }}
          onClick={() => {
            if (infowindowRef.current) infowindowRef.current.close();
            infowindowRef.current = new google.maps.InfoWindow({
              content: poi.description,
              position: { lat: poi.lat, lng: poi.lng }
            });
            infowindowRef.current.open(mapRef.current);
          }}
        />
      );
    });

    // Generate polylines for each track
    const tracks = coordSets.map((coordSet, i) => <Polyline key={`polyline${i}`} path={coordSet} options={polylineOptions} />);

    setTracks(tracks);
    setTrackMarkers(markers);
    setCurrentBounds(bounds);
    return;
  };

  // If any tollstation passings were passed in, render them as infowindows
  const renderTollstations = () => {
    const pois: { type: string; description: string; lat: number; lng: number }[] = [];
    // Add markers for each tollstation
    if (tollStations && tollStations.length > 0)
      tollStations.forEach((station) => {
        pois.push({
          type: "station",
          description: `
            <div>
              <strong>${station.name}</strong>: kr ${station.chargeSmallCar}<br />
              (${station.type})
            </div>
          `,
          lat: station.latitude,
          lng: station.longitude
        });
      });

    const symToll = mapIcon("symbolMileageTrackTollStation");
    const iconStation = {
      anchor: new google.maps.Point(symToll.anchor[0], symToll.anchor[1]),
      url: symToll.url,
      scaledSize: new google.maps.Size(symToll.scaledSize[0], symToll.scaledSize[1])
    };

    // Generate map markers for each poi
    const markers = pois.map((poi, i) => {
      let symbol = iconStation;
      return (
        <Marker
          clickable
          key={`mrk${i}`}
          icon={symbol}
          position={{ lat: poi.lat, lng: poi.lng }}
          onClick={() => {
            if (infowindowRef.current) infowindowRef.current.close();
            infowindowRef.current = new google.maps.InfoWindow({
              content: poi.description,
              position: { lat: poi.lat, lng: poi.lng }
            });
            infowindowRef.current.open(mapRef.current);
          }}
        />
      );
    });

    setTollMarkers(markers);
    // Don't adjust bounds! We will always be rendering a route/track, and stations will never be outside of those bounds anyway
    return;
  };

  const getCityFromGeography = (cityId: number) => {
    return useMajorCitiesQuery.data && useMajorCitiesQuery.data.find((o) => o.cityId === cityId);
  };
  const getCountryFromGeography = (countryId: number) => {
    return useCountriesQuery.data && useCountriesQuery.data.find((o) => o.id === countryId);
  };

  // Used for estimating route and distance, typically from a driving expense
  // Renders a track and calls back with the distance in kilometers
  const renderRouteQuery = async () => {
    if (!routeQuery || !routeQuery.from || !routeQuery.to) return;
    const service = (
      <DirectionsService
        options={{
          destination: routeQuery.to,
          origin: routeQuery.from,
          travelMode: google.maps.TravelMode.DRIVING
        }}
        callback={(result, status) => {
          if (!result || status !== "OK") {
            setDirectionsRenderer(null);
            setDirectionsFailed(true);
            return;
          } else {
            renderRouteResponse(result);
          }
        }}
      />
    );
    setDirectionsService(service);
  };

  // Actually renders the route from renderRouteQuery
  const renderRouteResponse = (directionsResponse: google.maps.DirectionsResult) => {
    const renderer = (
      <DirectionsRenderer
        options={{
          directions: directionsResponse
        }}
      />
    );

    const route = directionsResponse.routes[0]; // Use for route distance estimation and toll station estimator coords
    setCurrentBounds(route.bounds);
    setDirectionsRenderer(renderer);
    setDirectionsFailed(false);

    if (directionsResponse.routes && directionsResponse.routes.length > 0) {
      const totalMeters = directionsResponse.routes[0].legs.reduce(
        (total: number, leg: google.maps.DirectionsLeg) => (total += leg?.distance?.value || 0),
        0
      );
      const totalKilometers = Math.round(totalMeters / 1000);
      if (estimatorCallback) estimatorCallback(totalKilometers, route);
    }
  };

  // Renders markers and travel lines between them based on the segments on a report
  interface LatLngLiteralWithName extends google.maps.LatLngLiteral {
    name: string;
  }
  const renderSegments = async () => {
    if (!mapRef.current || !segments || segments.length === 0) return;

    const markers: LatLngLiteralWithName[] = [];

    // Iterate over each segment
    for (var segment of segments) {
      const marker: LatLngLiteralWithName = { name: segment.location, lat: 0, lng: 0 };
      if (segment.cityId) {
        // If the segment has a cityId, look it up in majorCities
        const city = getCityFromGeography(segment.cityId);
        if (city) {
          // We found a city, check for latlon presence
          if (city.lat && city.lon) {
            // The city has a valid latlon
            marker.lat = parseFloat(city.lat);
            marker.lng = parseFloat(city.lon);
          } else {
            const loc = await getLocation(segment.cityId, 0);
            if (loc && loc.lat && loc.lon) {
              marker.lat = parseFloat(loc.lat);
              marker.lng = parseFloat(loc.lon);
            }
          }
        } else {
          const loc = await getLocation(segment.cityId, 0);
          if (loc && loc.lat && loc.lon) {
            marker.lat = parseFloat(loc.lat);
            marker.lng = parseFloat(loc.lon);
          }
        }
      }

      if (!marker.lat && !marker.lng && segment.countryId) {
        // We're still missing latlon, but we do have a countryId, so check that against countries
        // If the segment has a countryId, look it up in majorCountries
        const country = getCountryFromGeography(segment.countryId);
        if (country) {
          // We found a country, check for latlon presence;
          if (country.lat && country.lon) {
            // The country has a valid latlon
            marker.lat = parseFloat(country.lat);
            marker.lng = parseFloat(country.lon);
          } else {
            const loc = await getLocation(0, segment.countryId);
            if (loc && loc.lat && loc.lon) {
              marker.lat = parseFloat(loc.lat);
              marker.lng = parseFloat(loc.lon);
            }
          }
        } else {
          const loc = await getLocation(0, segment.countryId);
          if (loc && loc.lat && loc.lon) {
            marker.lat = parseFloat(loc.lat);
            marker.lng = parseFloat(loc.lon);
          }
        }
      }

      // If we have a location, add the marker
      if (marker.lat || marker.lng) markers.push(marker);
    }

    // Generate markers for each segment
    const segmentMarkersNew = markers.map((marker, i) => <Marker key={`segmentMarker${i}`} position={{ lat: marker.lat, lng: marker.lng }} />);

    // Generate a polyline between the segments
    const segmentLinesNew =
      markers.length > 1 ? (
        <Polyline
          key={"segmentLine"}
          path={markers.map((marker, i) => ({
            lat: marker.lat,
            lng: marker.lng
          }))}
          options={polylineOptions}
        />
      ) : null;

    // Adjust map bounds to track coords
    if (markers.length > 0) {
      const bounds: google.maps.LatLngBounds = new google.maps.LatLngBounds();
      markers.forEach((marker) => bounds.extend(new google.maps.LatLng(marker.lat, marker.lng)));
      google.maps.event.addListenerOnce(mapRef.current, "bounds_changed", () => {
        // If the current bounds took us further in than zoom level 10 (typically if there's only one city), reset the zoom to 10 after the bounds have updated to give a better overview
        const curZoom = mapRef.current && mapRef.current.getZoom();
        if (mapRef.current && curZoom && curZoom > 10) mapRef.current.setZoom(10);
      });
      mapRef.current.fitBounds(bounds);
      setCurrentBounds(bounds);
    }

    if (segmentMarkersNew) setSegmentMarkers(segmentMarkersNew);
    if (segmentLinesNew) setSegmentLines(segmentLinesNew);

    return;
  };

  if (loadError) {
    return <div>Currently unable to load map.</div>;
  }

  return isLoaded ? renderMap() : <Spinner />;
};

export default Map;
