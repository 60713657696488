import { MouseEvent, useEffect, useState } from "react";
import Tour from "reactour";
import { useBus } from "../../utils/useBus";
import { getTour, TourStep, TourName } from "../../utils/tours";

/* A guided tour through the contents of the current page */
interface BetterTourProps {
  tourName: TourName;
  onRequestClose: (event: MouseEvent) => void;
}
const BetterTour = ({ tourName, onRequestClose }: BetterTourProps) => {
  const [tour, setTour] = useState<TourStep[]>([]);
  const [tourOpen, setTourOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);

  const bus = useBus(() => {});

  useEffect(() => {
    const tour = tourName ? getTour(tourName) : [];
    const open = tourName ? true : false;
    setTour(tour);
    setTourOpen(open);
  }, [tourName]);

  const stepChanged = (stepIndex: number) => {
    if (stepIndex !== currentStep) {
      const step = tour[stepIndex];
      if (step && step.globalEvent) {
        bus(step.globalEvent);
      }
      setCurrentStep(stepIndex);
    }
  };

  return (
    <>
      <Tour
        steps={tour}
        isOpen={tourOpen}
        onRequestClose={onRequestClose}
        rounded={4}
        showNumber={false}
        getCurrentStep={(i: number) => stepChanged(i)}
      />
    </>
  );
};

export default BetterTour;
