import moment from "moment";
import { uuid } from "../utils/helpers";
import { calculateVat, capitalize } from "../utils/helpers";
import i18n from "../i18n/i18nConfig";
import { Expense, ExpenseCustomValue } from "../types";

export interface ExpenseConstructorOptions {
  expenseTypeId: number;
  personId: number;
  reportUuid?: string;
  command?: string;
  starts?: string;
  ends?: string;
  source?: string;
  units?: number;
  unitName?: string;
  unitPrice?: number;
  currency?: string;
  unitPriceNok?: number;
  refund?: boolean;
  receipt?: boolean;
  description?: string;
  vatPercent?: number;
  groupUuid?: string;
  mileageTrackUuid?: string;
  expenseCustomValues?: ExpenseCustomValue[];
}
export const expenseConstructor = (opts: ExpenseConstructorOptions): Expense => {
  const {
    expenseTypeId,
    personId,
    reportUuid = "",
    command = "",
    starts = moment().toISOString(),
    ends = moment().toISOString(),
    source = "web",
    units = 1,
    unitName = "Antall",
    unitPrice = 0,
    currency = "NOK",
    unitPriceNok = 0,
    refund = true,
    receipt = true,
    description = capitalize(i18n.t("newExpenseDescription")),
    vatPercent = 25,
    groupUuid = "",
    mileageTrackUuid = "",
    expenseCustomValues = []
  } = opts;

  const created = moment().toISOString();
  const changed = moment().toISOString();
  const expenseUuid = uuid();
  const totalValueNok = units * unitPriceNok;
  return {
    uuid: expenseUuid,
    created,
    changed,
    expenseTypeId,
    source,
    command,
    reportUuid,
    personId,
    starts: starts || created,
    ends: ends || created,
    units,
    unitName,
    unitPrice,
    currency,
    unitPriceNok,
    totalValueNok,
    refund,
    receipt,
    description,
    vatPercent,
    totalVatNok: calculateVat(vatPercent, totalValueNok),
    groupUuid,
    mileageTrackUuid,
    expenseCustomValues,
    attachments: [],
    deleted: "",
    dirty: created
  };
};
