import React, { useEffect, useState } from "react";
import { uuid } from "../../shared/utils/helpers";
import "../../styles/checkbox.css";

interface CheckboxInputProps {
  id?: string;
  field: string;
  value?: boolean; // If this is undefined, just use internal state. The parent is not keeping track.
  label?: string | HTMLElement | JSX.Element;
  title?: string;
  error?: string;
  onChange?: (value: boolean) => void;
  readOnly?: boolean;
}
const CheckboxInput = ({ id, field, value, label, title, error, onChange, readOnly }: CheckboxInputProps) => {
  const [stateValue, setStateValue] = useState(value);
  const [stateId] = useState(id || uuid());

  // A checkbox value could be overridden by new props if part of a group. Examples:
  // Customfields for driving: some driving addons resets others addons
  // Autoexpenseoverrides: using "select all" for a mealtype or exclusion
  // Update stateValue if the new value prop differs from the previous one, AND from the current value state
  useEffect(() => {
    if (value !== undefined && value !== stateValue) {
      setStateValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.checked;
    setStateValue(value);
    onChange && onChange(value);
  };

  return (
    <div className="checkbox">
      <input type="checkbox" name={field} checked={stateValue} onChange={handleChange} disabled={readOnly} id={stateId} />
      <label title={title} htmlFor={stateId}>
        {label}
      </label>
      {error && <span>{error}</span>}
    </div>
  );
};

export default CheckboxInput;
