import { InboxAttachment } from "../types";
import { jsonFetch } from "./api";

// Save a single inboxattachment
export const save = async (inboxAttachment: InboxAttachment): Promise<InboxAttachment> => {
  const fetchOptions = {
    method: "POST",
    body: JSON.stringify(inboxAttachment)
  };
  return jsonFetch(`e/inboxAttachment/save`, fetchOptions);
};
