// Whenever UX policy logic is used from more than one place, dump it here
import { Expense, ProductConfiguration, ReportSegment } from "../types";
import { lodgingTypes, vatOptions } from "./constants";
import { currentExpenseTypesByProductConfig } from "./currentExpenseTypes";
import { nullOrUndefined } from "./helpers";

// Takes a ReportSegment and a ProductConfiguration, applies default values from the UX policy if one exists
// Only applies default values, and should only be used during construction of new segments
// This exists as helper code since segments are created in more than one place. (In webclient: ReportPage and ReportPageNew)
export const applyPolicyToNewReportSegment = (segment: ReportSegment, productConfig: ProductConfiguration): ReportSegment => {
  // Check if there is a policy at all, with diet configuration
  if (productConfig.uxPolicy?.diet) {
    // Ensure a disabled lodgingType is not set as default
    if (productConfig.uxPolicy.diet?.hiddenLodgingTypeIds) {
      // Some lodgingTypes for diet has been disabled
      const hiddenIds = productConfig.uxPolicy.diet.hiddenLodgingTypeIds;
      if (hiddenIds.includes(segment.lodgingType)) {
        // The default lodgingType is disabled, replace with the first enabled one if one exists
        const newLodgingType = lodgingTypes.find((o) => !hiddenIds.includes(o.id));
        if (newLodgingType) segment.lodgingType = newLodgingType.id;
      }
    }
    // Check for a default lodgingType
    if (productConfig.uxPolicy.diet?.defaultLodgingTypeId) {
      // A default ratetype for new reports has been configured, use it regardless of available options, if it's valid
      if (lodgingTypes.map((o) => o.id).includes(productConfig.uxPolicy.diet.defaultLodgingTypeId)) {
        segment.lodgingType = productConfig.uxPolicy.diet.defaultLodgingTypeId;
      }
    }

    // Set diet field defaults
    if (productConfig.uxPolicy.diet?.fields) {
      // Set correct defaults for the checkboxes on the initial segment
      const fields = productConfig.uxPolicy.diet.fields;
      const { farFromHome, includeNightExtra, includeOvernightAbroadExtra } = fields;
      if (farFromHome?.useDefaultValue) segment.farFromHome = farFromHome.defaultValue ? true : false;
      if (includeNightExtra?.useDefaultValue) segment.includeNightExtra = includeNightExtra.defaultValue ? true : false;
      if (includeOvernightAbroadExtra?.useDefaultValue) segment.includeOvernightAbroadExtra = includeOvernightAbroadExtra.defaultValue ? true : false;
    }
  }
  return segment;
};

// Same as previous function, but for new expenses (both standalone and on a report)
export const applyPolicyToNewExpense = (expense: Expense, productConfig: ProductConfiguration): Expense => {
  // Check if there is an expense policy
  if (productConfig.uxPolicy?.expense) {
    // We don't need to check disabled currencies here. New expenses are hardcoded with NOK, and NOK can't be disabled.

    // Check for disabled VAT percentages
    if (productConfig.uxPolicy.expense?.hiddenVatPercentages) {
      // Some VATs has been disabled
      const hiddenVATs = productConfig.uxPolicy.expense.hiddenVatPercentages;
      if (hiddenVATs.includes(expense.vatPercent)) {
        // The default VAT percentage is disabled, replace with the first enabled one if one exists
        const newVAT = vatOptions.find((o) => !hiddenVATs.includes(o));
        if (newVAT) expense.vatPercent = newVAT;
      }
    }
    // Check for a default expenseType
    if (productConfig.uxPolicy.expense?.defaultExpenseTypeId) {
      // A default expenseType for new expenses has been configured, use it if it's valid
      const expenseTypes = currentExpenseTypesByProductConfig(productConfig);
      if (expenseTypes.map((o) => o.id).includes(productConfig.uxPolicy.expense.defaultExpenseTypeId)) {
        expense.expenseTypeId = productConfig.uxPolicy.expense.defaultExpenseTypeId;
      }
    }

    // Set expense field defaults
    // Ignore default values for totalVatNok, does not make sense to enforce and is hidden in admin UI
    if (productConfig.uxPolicy.expense?.fields) {
      const fields = productConfig.uxPolicy.expense.fields;
      const { description, refund, receipt } = fields;
      if (description?.useDefaultValue && !nullOrUndefined(description.defaultValue)) {
        expense.description = String(description.defaultValue);
      }
      if (refund?.useDefaultValue) expense.refund = refund.defaultValue ? true : false;
      if (receipt?.useDefaultValue) expense.receipt = receipt.defaultValue ? true : false;
    }
  }
  return expense;
};
