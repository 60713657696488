import "../../styles/spinner.css";

interface SpinnerProps {
  size?: string; // CSS unit
  margin?: string; // CSS unit
}
const Spinner = ({ size, margin }: SpinnerProps) => {
  let style: React.CSSProperties = {};
  if (size) style = { ...style, width: size, height: size };
  if (margin) style = { ...style, margin };
  return <div className="loading-spinner" style={style} />;
};

export default Spinner;
