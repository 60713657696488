import posed from "react-pose";

// TODO: Reinstante  these transitions when pages are performant enough. (Opacity 0-1, duration 2-400ms?)
const HomePagePoseWrapper = posed.div({
  enter: {
    opacity: 1,
    transition: {
      duration: 0
    }
  },
  exit: {
    opacity: 1,
    transition: {
      duration: 0
    }
  }
});

export default HomePagePoseWrapper;
