import { ReportSegment } from "../types";
import { jsonFetch } from "./api";

// Save a single reportsegment
export const save = async (reportSegment: ReportSegment, fetchOpts?: any): Promise<ReportSegment> => {
  const fetchOptions = {
    method: "POST",
    body: JSON.stringify(reportSegment),
    ...fetchOpts
  };
  return jsonFetch(`e/reportsegment/save`, fetchOptions);
};
