import { ExpenseCustomValue } from "../types";
import { jsonFetch } from "./api";

// Save a single expensecustomvalue
export const save = async (expenseCustomValue: ExpenseCustomValue, fetchOpts?: any): Promise<ExpenseCustomValue> => {
  const fetchOptions = {
    method: "POST",
    body: JSON.stringify(expenseCustomValue),
    ...fetchOpts
  };
  return jsonFetch(`e/expensecustomvalue/save`, fetchOptions);
};
