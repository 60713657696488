import AttachmentThumbnail from "../attachment/AttachmentThumbnail";
import { Inbox, InboxAttachment } from "../../shared/types";

interface InboxAttachmentListItemProps {
  inboxElement: Inbox;
  inboxAttachmentElement: InboxAttachment;
  renderMode: "table" | "drawer" | "cards";
}
function InboxAttachmentListItem({ inboxElement, inboxAttachmentElement, renderMode }: InboxAttachmentListItemProps) {
  const thumbnail = (
    <AttachmentThumbnail
      key={`tn${inboxAttachmentElement.uuid}`}
      attachment={inboxAttachmentElement}
      showOriginalFilename={false}
      renderMode={renderMode}
    />
  );
  return thumbnail;
}

export default InboxAttachmentListItem;
