import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Badge } from "react-bootstrap";
import Icon from "../common/Icon";
import { capitalize, formatDateRange } from "../../shared/utils/helpers";
import InboxAttachmentListItem from "./InboxAttachmentListItem";
import InboxThumbnail from "./InboxThumbnail";
import i18n from "../../shared/i18n/i18nConfig";
import { Inbox, InboxAttachment } from "../../shared/types";
import moment from "moment";
import TextFieldGroup from "../common/TextFieldGroup";
import { saveInbox } from "../../shared/queries/queries";
import Spinner from "../common/Spinner";

// Renders a single inbox item with inboxAttachments
interface InboxListItemProps {
  inboxElement: Inbox;
  renderMode: "table" | "drawer" | "cards";
  alternate?: boolean;
  onDeleteInbox?: (inbox: Inbox) => void;
  onShowAttachables?: (inbox: Inbox, inboxAttachment: InboxAttachment | null) => void;
  onDrawerSelectionInbox?: (inbox: Inbox) => void;
  onDrawerSelectionInboxAttachment?: (inbox: Inbox, inboxAttachment: InboxAttachment) => void;
  onAnalyzeInboxAttachment?: (inboxAttachment: InboxAttachment) => void;
  hideTargetUuidsInDrawerMode?: string[];
  attachmentsByEntityUuid: { [uuid: string]: string[] };
}
const InboxListItem = ({
  inboxElement,
  renderMode,
  alternate,
  onDeleteInbox,
  onShowAttachables,
  onDrawerSelectionInbox,
  onDrawerSelectionInboxAttachment,
  onAnalyzeInboxAttachment,
  hideTargetUuidsInDrawerMode = [],
  attachmentsByEntityUuid = {}
}: InboxListItemProps) => {
  const [t] = useTranslation();

  const { uuid, subject, source, changed } = inboxElement;

  const date = formatDateRange(changed || moment().toISOString());

  const descriptionText = subject.trim() ? subject : capitalize(t("noDescription"));

  const [editingDescription, setEditingDescription] = useState(false);
  const [newDescription, setNewDescription] = useState(subject);
  const [saving, setSaving] = useState(false);

  const saveDescription = () => {
    setEditingDescription(false);
    if (inboxElement.subject !== newDescription) {
      const updatedInbox = { ...inboxElement, subject: newDescription, dirty: moment().toISOString() };
      setSaving(true);
      saveInbox(updatedInbox).then(() => {
        setSaving(false);
      });
    }
  };

  const description = editingDescription ? (
    <TextFieldGroup
      hideLabel
      autoFocus
      field={`inboxDescription${uuid}`}
      value={descriptionText}
      placeholder={capitalize(t("description"))}
      onChange={(s) => setNewDescription(s)}
      onBlur={() => saveDescription()}
      onEscape={() => setEditingDescription(false)}
      // onBlur={() => setEditingDescription(false)}
      // addonRight={
      //   <>
      //     <Button onClick={() => saveDescription()} title={t("confirm")}>
      //       <Icon icon="checkMark" />
      //     </Button>
      //     <Button onClick={() => setEditingDescription(false)} title={t("cancel")}>
      //       <Icon icon="cancel" />
      //     </Button>
      //   </>
      // }
    />
  ) : (
    <button className="fakelink" onClick={() => setEditingDescription(true)}>
      <span>
        {descriptionText} {saving ? <Spinner size="16px" /> : ""}
      </span>
    </button>
  );

  const sourceHumanFriendly = i18n.exists(`attachmentMeta.${source}`) ? capitalize(t(`attachmentMeta.${source}`)) : source;

  const sourceDescription = inboxElement.fromAddress && (
    <div>
      <span>{sourceHumanFriendly}</span>
      <span>{inboxElement.fromAddress && ` (${inboxElement.fromAddress})`}</span>
    </div>
  );

  const attachmentCount = attachmentsByEntityUuid[uuid]?.length || 0;
  const attachmentCountBadge = attachmentCount > 0 && (
    <Badge
      className="attached-count"
      title={t("attachedBefore", {
        count: attachmentCount
      })}
    >
      {attachmentCount}
    </Badge>
  );

  // The inbox element itself (if the body is noteworthy; over 100 characters)
  let inboxThumbnail = null;
  if (inboxElement.body && inboxElement.body.length > 100) {
    inboxThumbnail = (
      <div>
        {renderMode !== "drawer" && (
          <Button
            className="inbox-attach-button"
            bsSize="xs"
            title={capitalize(t("attachToReportOrExpense"))}
            onClick={() => onShowAttachables && onShowAttachables(inboxElement, null)}
          >
            <Icon icon="attachment" />
            {attachmentCountBadge}
          </Button>
        )}

        <InboxThumbnail inboxElement={inboxElement} renderMode={renderMode} />
      </div>
    );
  }

  // The block of inbox attachments
  const attachmentThumbnails = inboxElement.inboxAttachments.filter((inboxAttachment) => !hideTargetUuidsInDrawerMode.includes(inboxAttachment.uuid));

  const deleteButton = (
    <Button title={capitalize(t("delete"))} onClick={() => onDeleteInbox && onDeleteInbox(inboxElement)}>
      <Icon icon="trash" />
    </Button>
  );

  let extraClass = "";
  if (renderMode === "table" && alternate) extraClass += " alternate";

  /* Render as cards */
  if (renderMode === "cards") {
    return (
      <div className={`card-item ${extraClass}`}>
        <div className="item-description">{description}</div>
        <div className="item-thumbnails">
          {inboxThumbnail}
          {attachmentThumbnails.map((inboxAttachment) => {
            return (
              <div key={inboxAttachment.uuid}>
                {(inboxAttachment.mimeType.startsWith("image/") || inboxAttachment.mimeType.startsWith("application/pdf")) && (
                  <Button
                    title={capitalize(t("attemptCreateExpenseFromReceipt"))}
                    onClick={() => onAnalyzeInboxAttachment && onAnalyzeInboxAttachment(inboxAttachment)}
                  >
                    <Icon icon={"ocr"} />
                  </Button>
                )}
                <Button
                  className="inbox-attach-button"
                  bsSize="sm"
                  title={capitalize(t("attachToReportOrExpense"))}
                  onClick={() => onShowAttachables && onShowAttachables(inboxElement, inboxAttachment)}
                >
                  {attachmentCount > 0 && (
                    <Badge
                      className="attached-count"
                      title={t("attachedBefore", {
                        count: attachmentCount
                      })}
                    >
                      {attachmentCount}
                    </Badge>
                  )}
                  <Icon icon="attachment" />
                </Button>

                <InboxAttachmentListItem
                  key={inboxAttachment.uuid}
                  inboxElement={inboxElement}
                  inboxAttachmentElement={inboxAttachment}
                  renderMode={renderMode}
                />
              </div>
            );
          })}
        </div>
        <div className="item-actions">{deleteButton}</div>
        <div className="item-source">{sourceDescription}</div>
        <div className="item-dates">{date}</div>
      </div>
    );
  }

  /* Render as drawer */
  if (renderMode === "drawer") {
    const inboxAndAttachmentThumbnails = attachmentThumbnails.map((inboxAttachment) => {
      const attachmentCount = attachmentsByEntityUuid[inboxAttachment.uuid]?.length || 0;
      return (
        <div
          key={inboxAttachment.uuid}
          className="drawer-item"
          role="button"
          tabIndex={0}
          onClick={() => onDrawerSelectionInboxAttachment && onDrawerSelectionInboxAttachment(inboxElement, inboxAttachment)}
        >
          <InboxAttachmentListItem
            key={inboxAttachment.uuid}
            inboxElement={inboxElement}
            inboxAttachmentElement={inboxAttachment}
            renderMode={renderMode}
          />
          {attachmentCount > 0 && (
            <Badge
              className="attached-count"
              title={t("attachedBefore", {
                count: attachmentCount
              })}
            >
              {attachmentCount}
            </Badge>
          )}
        </div>
      );
    });

    if (inboxThumbnail !== null && !hideTargetUuidsInDrawerMode.includes(inboxElement.uuid))
      inboxAndAttachmentThumbnails.splice(
        0,
        0,
        <div
          key={uuid}
          className="drawer-item"
          role="button"
          tabIndex={0}
          onClick={() => onDrawerSelectionInbox && onDrawerSelectionInbox(inboxElement)}
        >
          {inboxThumbnail}
          {attachmentCountBadge}
        </div>
      );
    return <>{inboxAndAttachmentThumbnails}</>;
  }

  /* Render as table */
  return (
    <>
      <div className={`item-checkbox ${extraClass}`} />
      <div className={`item-dates ${extraClass}`}>{date}</div>
      <div className={`item-description ${extraClass}`}>{description}</div>

      <div className={`item-thumbnails ${extraClass}`}>
        {inboxThumbnail}
        {attachmentThumbnails.map((inboxAttachment) => {
          const attachmentCount = attachmentsByEntityUuid[inboxAttachment.uuid]?.length || 0;
          return (
            <div key={inboxAttachment.uuid}>
              {(inboxAttachment.mimeType.startsWith("image/") || inboxAttachment.mimeType.startsWith("application/pdf")) && (
                <Button
                  title={capitalize(t("attemptCreateExpenseFromReceipt"))}
                  onClick={() => onAnalyzeInboxAttachment && onAnalyzeInboxAttachment(inboxAttachment)}
                >
                  <Icon icon={"ocr"} />
                </Button>
              )}
              <Button
                className="inbox-attach-button"
                bsSize="sm"
                title={capitalize(t("attachToReportOrExpense"))}
                onClick={() => onShowAttachables && onShowAttachables(inboxElement, inboxAttachment)}
              >
                {attachmentCount > 0 && (
                  <Badge
                    className="attached-count"
                    title={t("attachedBefore", {
                      count: attachmentCount
                    })}
                  >
                    {attachmentCount}
                  </Badge>
                )}
                <Icon icon="attachment" />
              </Button>
              <InboxAttachmentListItem
                key={inboxAttachment.uuid}
                inboxElement={inboxElement}
                inboxAttachmentElement={inboxAttachment}
                renderMode={renderMode}
              />
            </div>
          );
        })}
      </div>
      <div className={`item-source ${extraClass}`}>{sourceDescription}</div>
      <div className={`item-actions ${extraClass}`}>{deleteButton}</div>
    </>
  );
};

export default InboxListItem;
