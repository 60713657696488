import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Grid, Button, FormGroup, Radio } from "react-bootstrap";
import PageHeader from "../common/PageHeader";
import AttachmentPreview from "../attachment/AttachmentPreview";
import { useStore } from "../../shared/store/store";
import config from "../../shared/config";
import { capitalize } from "../../shared/utils/helpers";
import Icon from "../common/Icon";
import { getApiUrl } from "../../shared/api/api";
import { SupportedEnvironments } from "../../shared/types";
import { refreshAll } from "../../shared/queries/queries";

const AboutPage = () => {
  const [t] = useTranslation();
  const [techClicked, setTechClicked] = useState(0);

  const store = useStore();
  const currentEnv = store.env;

  // Set another backend environment and invalidate all userdata queries to trigger refetch from new backend
  const toggleEnv = (env: SupportedEnvironments) => {
    store.switchEnvironment(env);
    refreshAll();
  };

  return (
    <Grid fluid>
      <AttachmentPreview />
      <PageHeader page={"about"} />
      <table className="table table-striped">
        <tbody>
          <tr>
            <td>{t("Orgno")}</td>
            <td>{t("aboutView.ttOrgno")}</td>
          </tr>

          <tr>
            <td>{capitalize(t("aboutView.address"))}</td>
            <td>{capitalize(t("aboutView.ttAddress"))}</td>
          </tr>

          <tr>
            <td>{capitalize(t("aboutView.telephone"))}</td>
            <td>{capitalize(t("aboutView.ttTelephone"))}</td>
          </tr>

          <tr>
            <td>{capitalize(t("aboutView.email"))}</td>
            <td>{t("aboutView.ttEmail")}</td>
          </tr>

          <tr>
            <td>{capitalize(t("aboutView.visitWebsite"))}</td>
            <td>
              <a href={t("contactUrl")}>{t("contactUrl")}</a>
            </td>
          </tr>

          <tr>
            <td id="about-legalese-header">{capitalize(t("aboutView.legaleseViews"))}</td>
            <td>
              <div id="about-legalese-eula">
                <Link to="/eula">{capitalize(t("aboutView.termsAndConditions"))}</Link>
              </div>
              <div id="about-legalese-privacy">
                <Link to="/eula/privacy">{capitalize(t("aboutView.privacyPolicy"))}</Link>
              </div>
              <div id="about-legalese-dpa">
                <Link to="/eula/dpa">{capitalize(t("aboutView.dpAgreement"))}</Link>
              </div>
            </td>
          </tr>

          {techClicked > 0 && (
            <>
              <tr>
                <td>{t("aboutView.ossAttributions")}</td>
                <td>
                  <Link to="/oss-attributions/attribution.txt" target="_blank">
                    {t("aboutView.showAttributions")}
                  </Link>
                </td>
              </tr>
              <tr>
                <td>{t("Node mode")}</td>
                <td>{process.env.NODE_ENV || "-"}</td>
              </tr>
              <tr>
                <td>{t("Version")}</td>
                <td>{process.env.REACT_APP_COMMIT_SHA || "-"}</td>
              </tr>
              <tr>
                <td>{t("API URLs from environment config")}</td>
                <td>
                  REACT_APP_SERVER_LOCALHOST: {config.REACT_APP_SERVER_LOCALHOST || "-"}
                  <br />
                  REACT_APP_SERVER_DEV: {config.REACT_APP_SERVER_DEV || "-"}
                  <br />
                  REACT_APP_SERVER_STAGING: {config.REACT_APP_SERVER_STAGING || "-"}
                  <br />
                  REACT_APP_SERVER_PROD: {config.REACT_APP_SERVER_PROD || "-"}
                </td>
              </tr>
              <tr>
                <td>{t("Backend environment currently configured")}</td>
                <td>{currentEnv || "-"}</td>
              </tr>
              <tr>
                <td>{t("API URL currently configured")}</td>
                <td>{getApiUrl() || "-"}</td>
              </tr>
            </>
          )}
          {techClicked >= 5 && (
            <>
              <tr>
                <td>Backend:</td>
                <td>
                  <FormGroup>
                    <Radio checked={currentEnv === "localhost"} name="radioGroup" inline onChange={() => toggleEnv("localhost")}>
                      localhost
                    </Radio>{" "}
                    <Radio checked={currentEnv === "dev"} name="radioGroup" inline onChange={() => toggleEnv("dev")}>
                      dev
                    </Radio>{" "}
                    <Radio checked={currentEnv === "staging"} name="radioGroup" inline onChange={() => toggleEnv("staging")}>
                      staging
                    </Radio>{" "}
                    <Radio checked={currentEnv === "prod"} name="radioGroup" inline onChange={() => toggleEnv("prod")}>
                      prod
                    </Radio>
                  </FormGroup>
                </td>
              </tr>
            </>
          )}
        </tbody>
      </table>

      {techClicked < 5 && (
        <Button title="Technical details" className="very-discreet" bsSize="xs" onClick={() => setTechClicked(techClicked + 1)}>
          <Icon icon="engine" />
        </Button>
      )}
    </Grid>
  );
};

export default AboutPage;