/*eslint camelcase: 0*/
import moment from "moment";
import i18n from "../../i18n/i18nConfig";
import { autoExpenseGroupConstructor, autoExpenseConstructor, segmentDayConstructor } from "../../constructors/reportCalculationConstructors";
import { getRateBySegment, getRateBySegmentDay } from "./autoExpenses";

// V38

// Valid until 2023-09-01

// official_diet_norway 825 -> 872
// official_diet_daytrip_6_12 324 -> 342
// official_diet_daytrip_12_plus 603 -> 637

// official_diet_taxfree_part_hotel 617 -> 634
// official_diet_taxfree_part_nocooking 172 -> 177
// official_diet_taxfree_part_private_or_withcooking 95 -> 98

// official_nondomestic_extra 563 -> 584

// New official rateset for nondomestic rates

// Static values for this version
const official_diet_norway = 872;
const official_diet_norway_after_day_28 = 217;
const official_diet_reduction_factor_after_day_28 = 0.25;

const official_diet_taxfree_part_private_or_withcooking = 98;
const official_diet_taxfree_part_nocooking = 177;
const official_diet_taxfree_part_hotel = 634;

const official_diet_daytrip_6_12 = 342;
const official_diet_daytrip_12_plus = 637;

const official_diet_taxfree_part_daytrip_6_12 = 200;
const official_diet_taxfree_part_daytrip_12_plus = 400;

const official_overnight_extra = 435;
const official_nondomestic_extra = 584;

const meal_reduction_domestic_breakfast = 0.2;
const meal_reduction_domestic_lunch = 0.3;
const meal_reduction_domestic_dinner = 0.5;

const meal_reduction_nondomestic_breakfast = 0.2;
const meal_reduction_nondomestic_lunch = 0.3;
const meal_reduction_nondomestic_dinner = 0.5;

// Take a report, return the ordered segments prepopulated with autoexpensegroups
const getSegmentsWithGroups = (report) => {
  // Clone segments to preserve original, and order by date
  let orderedSegments = report.reportSegments.filter((o) => !o.deleted).map((o) => Object.assign({}, o));
  orderedSegments.sort((a, b) => new Date(a.starts) - new Date(b.starts));

  // Create autoexpense groups for segments
  orderedSegments.forEach((orderedSegment) => {
    const diet_expensetype_label = report.rateType === 3 || report.rateType === 4 || report.rateType === 5 ? "state_diet" : "custom_diet";

    // Create an autoexpense group for diet
    const agDiet = autoExpenseGroupConstructor();
    agDiet.autoExpenseType = diet_expensetype_label;

    // "state_diet" is the wrapper term exposed in API and elsewhere for both the classic rates and the new tax free rates
    // This might change in the future if people need to differentiate them
    agDiet.fromDate = orderedSegment.starts;
    agDiet.toDate = orderedSegment.stops;

    orderedSegment.diet = agDiet;
    if (orderedSegment.countryId === 164) {
      // Domestic (Norway)
      orderedSegment.diet.location = i18n.t("calculation.norway");
      let dietdesc = i18n.t("calculation.dietCalculation");
      if (report.rateType === 2) dietdesc = i18n.t("calculation.ownRates");
      if (report.rateType === 4) dietdesc = i18n.t("calculation.taxfreeRates");
      if (report.rateType === 5) dietdesc = i18n.t("calculation.taxableRates");
      if (report.rateType === 6) dietdesc = i18n.t("calculation.ownTaxfreeRates");
      orderedSegment.diet.description = `${dietdesc} ${i18n.t("for")} ${i18n.t("calculation.norway")}`;

      // Create an autoexpensegroup for "Nattillegget" (Domestic only)
      const agNightExtra = autoExpenseGroupConstructor();
      agNightExtra.autoExpenseType = "state_overnight_extra";
      agNightExtra.fromDate = orderedSegment.starts;
      agNightExtra.toDate = orderedSegment.stops;
      agNightExtra.location = "Norge";
      orderedSegment.nightExtra = agNightExtra;
    } else {
      // Non-domestic
      const location = getRateBySegment(orderedSegment);
      orderedSegment.diet.location = location.officialPlaceName;
      let dietdesc = i18n.t("calculation.dietCalculation");
      if (report.rateType === 2) dietdesc = i18n.t("calculation.ownRates");
      if (report.rateType === 4) dietdesc = i18n.t("calculation.taxfreeRates");
      if (report.rateType === 5) dietdesc = i18n.t("calculation.taxableRates");
      if (report.rateType === 6) dietdesc = i18n.t("calculation.ownTaxfreeRates");
      orderedSegment.diet.description = `${dietdesc} ${i18n.t("for")} ${agDiet.location}`;

      // Create an autoexpensegroup for "Kompensasjons-/Utenlandstillegget" (Non-domestic only)
      const agNondomesticExtra = autoExpenseGroupConstructor();
      agNondomesticExtra.autoExpenseType = "state_nondomestic_extra";
      agNondomesticExtra.fromDate = orderedSegment.starts;
      agNondomesticExtra.toDate = orderedSegment.stops;
      orderedSegment.nonDomesticExtra = agNondomesticExtra;

      orderedSegment.nonDomesticExtra.location = location.officialPlaceName;
    }
  });
  return orderedSegments;
};

// Take a report, return the ordered segments prepopulated with autoexpensegroups
const getDaysInSegments = (orderedSegments) => {
  // Map out individual / continuous segment days
  const start = moment(orderedSegments[0].starts);
  const stop = moment(orderedSegments[orderedSegments.length - 1].stops);
  const lastDayHours = stop.diff(start, "hours", true) % 24;

  // Number of days across all segments, including any dates between segments
  let totalDays = Math.floor(stop.diff(start, "days", true));

  // Add a final day if it is more than 6 hours
  if (lastDayHours >= 6) totalDays++;

  // Has not been away longer than 6 hours, no diet
  if (!totalDays || totalDays <= 0) return [];

  // Sanity check, probably a typo from the user
  if (totalDays > 1000) return [];

  // Start of the current day
  let currStart = start;

  // All days across all segments
  let segmentDays = new Array(totalDays);

  // Index of the current day
  let currDay = 0;

  // Will be true if the last analyzed day was
  let segmentPause = false;
  let firstDayInTravel = true;

  // Loop over all dates across all segments, including any dates between segments
  while (currDay < totalDays) {
    let longestDay = null;
    let totalMinutesInDay = 0;
    let currStop = moment(currStart).add(24, "hours");

    // If the day goes beyond the last day of all segments, truncate the day
    if (currStop.isAfter(stop)) currStop = stop;

    let longestOverlap = 0;

    // Loop over all segments to find one that contains this day
    for (let i = 0; i < orderedSegments.length; i++) {
      // Remove segments that doesn't contain this day
      const segment = orderedSegments[i];
      const segmentStarts = moment(segment.starts);
      const segmentStops = moment(segment.stops);
      // If this segment ends before the current day starts, or starts after the current day stops, skip the rest of the block
      if (segmentStops.isBefore(currStart) || segmentStarts.isAfter(currStop)) continue;

      // This segment contains the current day, but we're in a pause between segments
      if (segmentPause) {
        // Ergo, this day starts when the segment starts
        currStart = segmentStarts;
        currStop = moment(currStart).add(24, "hours");
        // If the day goes beyond the last day of all segments, truncate the day
        if (currStop > stop) currStop = stop;
        // We're no longer paused, a new segment has started
        segmentPause = false;
      }

      const overlapStart = currStart.isAfter(segmentStarts) ? currStart : segmentStarts;
      const overlapStop = currStop.isBefore(segmentStops) ? currStop : segmentStops;
      const totalMinutes = moment(overlapStop).diff(moment(overlapStart), "minutes");

      totalMinutesInDay += totalMinutes;
      if (totalMinutes >= longestOverlap) {
        longestDay = segmentDayConstructor({
          from: moment(currStart.toISOString()),
          to: moment(currStop.toISOString()),
          segment,
          firstDay: firstDayInTravel
        });
        longestOverlap = totalMinutes;
      }
    }

    // It's no longer the first day of the segment
    firstDayInTravel = false;

    if (totalMinutesInDay >= 360) {
      // This day was 6 hours or more, populate the right array slot with it
      segmentDays[currDay] = longestDay;
    } else {
      // This day was under 6 hours, adjust the stop of the previous day and flag a pause between segments
      if (currDay >= 1) {
        const prevday = segmentDays[currDay - 1];
        if (prevday && moment(prevday.to).isAfter(prevday.segment.stops)) {
          prevday.to = prevday.segment.stops;
        }
      }
      // We've entered a pause between segments
      segmentPause = true;

      // The next day will be the first day of a new segment
      firstDayInTravel = true;
    }
    currDay++;
    currStart = moment(currStart).add(24, "hours");
  }

  return segmentDays;
};

// Checks if a trip is considered "overnight" if either of the following is true:
// - Lasts for at least MinTotalHours hours
// - If MinNightHours > 0, at least this many hours were spent between 22:00 and 06:00
const isOvernight = (starts, stops, minTotalHours, minNightHours) => {
  const start = moment(starts);
  const stop = moment(stops);
  let ret = stop.diff(start, "hours", true) >= minTotalHours;

  if (minNightHours > 0) {
    if (start.hours() >= 22 || stop.hours() < 6) {
      // Segment starts inside the 22-06 timespan. If we're still within the 22-06 timespan MinNightHours hours later, and that time is before the segment stops date, it's an overnight trip
      const end = start.add(minNightHours, "h");
      if (end <= stop && (end.hours() < 6 || (end.hours() === 6 && end.minutes === 0))) ret = true;
    } else {
      // Segment starts outside the 22-06 timespan. If the segment lasts longer than 03:00 the following day, it's an overnight trip
      const nextDay = start.add(1, "d");
      const earliestEnd = nextDay.hours(3).minutes(0).seconds(0);
      if (earliestEnd <= stops) ret = true;
    }
  }
  return ret;
};

// Analyze a single day in a segment, return metadata and the refundable/taxfree rate for the day
const analyzeDay = (segmentDay, report, userConfiguration, dayNumberInSegment) => {
  const ret = {
    rate: 0,
    taxfreePart: 0,
    domestic: true,
    overnight: true,
    location: null
  };

  // Look for a location and official diet rate, based on a matching geographic location and timestamp
  // If this returns null, it's a domestic segment and we should use the norwegian official rate
  ret.location = getRateBySegmentDay(segmentDay);

  // Is this a domestic or non-domestic day?
  ret.domestic = ret.location === null;

  // Did we stay the night? (Either a 24-hour day, or over 5 hours during night time)
  ret.overnight = isOvernight(segmentDay.from, segmentDay.to, 24, 5);

  // What *would* the *official* rate be if we stayed overnight? (This is used for meal reduction calculation of partial following days, "påbegynt døgn", and is tax specific)
  ret.officialOvernightRate = ret.domestic ? official_diet_norway * 100 : ret.location.diet * 100;
  // Apply overrides to this if we're beyond day 28 in the current segment
  if (dayNumberInSegment > 28) {
    if (ret.domestic) {
      ret.officialOvernightRate = official_diet_norway_after_day_28 * 100;
    }
    else {
      ret.officialOvernightRate = Math.round(ret.location.diet - (ret.location.diet * official_diet_reduction_factor_after_day_28)) * 100;
    }
  }
  

  // The duration of this day in hours and seconds
  const hours = moment(segmentDay.to).diff(moment(segmentDay.from), "hours", true);
  const seconds = Math.floor(moment(segmentDay.to).diff(moment(segmentDay.from), "seconds", true));

  // Precalculate all possible rates in hundredths for domestic/nondomestic, overnight, daytrip etc
  let daytrip_rate_6_12 = official_diet_daytrip_6_12 * 100;
  let daytrip_rate_12_plus = official_diet_daytrip_12_plus * 100;
  let overnight_rate = ret.officialOvernightRate;

  // Override those rates if we're using a custom diet
  if (report.rateType === 2 || report.rateType === 6) {
    if (!userConfiguration.product.companyCustomDietOnlyAffectsOvernight) {
      // If companyCustomDietOnlyAffectsOvernight is true, the normal dayrates should remain untouched.
      // If false, we calculate the dayrates based on the same fraction as between the official dayrate and overnight rate
      let fraction_6_12 = daytrip_rate_6_12 / overnight_rate;
      let fraction_12_plus = daytrip_rate_12_plus / overnight_rate;
      daytrip_rate_6_12 = Math.floor(report.rateCustomDiet * fraction_6_12); // Daytrip-adjusted fraction of custom rate for 6-12 hours
      daytrip_rate_12_plus = Math.floor(report.rateCustomDiet * fraction_12_plus); // Daytrip-adjusted fraction of custom rate for 12+ hours
    }
    overnight_rate = report.rateCustomDiet;
  }

  // Also override daytrip rates if this is a domestic trip beyond day 29, and the special rate is capped lower than the daytrip rates
  if (ret.domestic && dayNumberInSegment > 28) {
    if (official_diet_norway_after_day_28 * 100 < daytrip_rate_6_12) daytrip_rate_6_12 = official_diet_norway_after_day_28 * 100;
    if (official_diet_norway_after_day_28 * 100 < daytrip_rate_12_plus) daytrip_rate_12_plus = official_diet_norway_after_day_28 * 100;
  }

  // As above, but for the nondomestic 50% dayrate. Base it on the custom rate unless the company has specificed that custom rates should only affect overnighters, in which case we base it on the original overnight rate instead
  let overnight_rate_50percent = Math.floor(overnight_rate * 0.5);
  if (report.rateType === 2 && userConfiguration.product.companyCustomDietOnlyAffectsOvernight) {
    overnight_rate_50percent = Math.floor(ret.officialOvernightRate * 0.5);
  }

  // Ensure everything is rounded to the nearest whole krone
  daytrip_rate_6_12 = Math.round(daytrip_rate_6_12 / 100) * 100;
  daytrip_rate_12_plus = Math.round(daytrip_rate_12_plus / 100) * 100;
  overnight_rate = Math.round(overnight_rate / 100) * 100;
  overnight_rate_50percent = Math.round(overnight_rate_50percent / 100) * 100;

  // Precalculate taxfree parts in hundredths
  let overnight_taxfree_part = official_diet_taxfree_part_private_or_withcooking * 100; // For lodging types 2 ("pensjonat med kok") and 4 ("privat/ulegitimert")
  if (segmentDay.segment.lodgingType === 3) overnight_taxfree_part = official_diet_taxfree_part_nocooking * 100; // For lodging type 3 ("pensjonat uten kok")
  if (segmentDay.segment.lodgingType === 1) overnight_taxfree_part = official_diet_taxfree_part_hotel * 100; // For lodging type 1 ("hotel") - new as of 2017-12-31
  let daytrip_taxfree_part_6_12 = official_diet_taxfree_part_daytrip_6_12 * 100;
  let daytrip_taxfree_part_12_plus = official_diet_taxfree_part_daytrip_12_plus * 100;

  // If we're using custom taxfree rates, we've already set the rates to the custom ones, and should use the same values for taxfree parts too
  if (report.rateType === 6) {
    overnight_taxfree_part = overnight_rate;
  }

  // Pick the right refund based on domestic or not, and length of day
  if (ret.domestic) {
    if (ret.overnight) {
      // DOMESTIC OVERNIGHT DIET: Either a full 24-hour day, or an overnight stay during the first day
      ret.rate = overnight_rate;
      ret.taxfreePart = overnight_taxfree_part;
    } else if (hours >= 6) {
      if (segmentDay.firstDay && segmentDay.segment.farFromHome) {
        // DOMESTIC DAYTRIP DIET: The first day, but not overnight. Only refundable if FarFromHome (more than 15km from home/office) is checked
        ret.rate = daytrip_rate_6_12;
        ret.taxfreePart = daytrip_taxfree_part_6_12;
        if (seconds > 43200) {
          // Only if *OVER* 12 hours (43200), not equal to (as of V22)
          ret.rate = daytrip_rate_12_plus;
          ret.taxfreePart = daytrip_taxfree_part_12_plus;
        }
      } else if (!segmentDay.firstDay) {
        // PARTIAL LAST DAY DIET: Not the first day, but also not overnight diet, while lasting 6 hours or more. Must be a partial last day in an overnight trip, aka "påbegynt døgn". Only refundable when it lasts 6 hours or more.
        ret.rate = daytrip_rate_6_12;
        if (seconds > 43200) ret.rate = daytrip_rate_12_plus; // Over 12 hours

        // The taxfree part for a partial last day is capped at the same sum as the previous night based on lodging type, as of 2019
        ret.taxfreePart = overnight_taxfree_part;
      }
    }
  } // Non-domestic
  else {
    if (hours >= 12) {
      // NON-DOMESTIC OVERNIGHT DIET
      // Triggered at 12 hours or more
      ret.rate = overnight_rate;
      ret.taxfreePart = overnight_taxfree_part;
    } else if (hours >= 6) {
      // NON-DOMESTIC DAYTRIP
      // Triggered at 6 hours or more, valued at 50% of overnight sum
      ret.rate = overnight_rate_50percent;
      ret.taxfreePart = overnight_taxfree_part;
    }
  }

  // Taxfree part is now a cap, not a set sum (closing an unintended loophole from 2018)
  // As of V26, after internal discussion, we're NOT capping this if this report is explicitly using taxfree rates (type 4 or 6)
  if (ret.taxfreePart > ret.rate && report.rateType !== 4 && report.rateType !== 6) ret.taxfreePart = ret.rate;

  return ret;
};

// Generate autoexpenses for all days in all segments on a report
export const getAutoExpensesV38 = (report, userConfiguration) => {
  const ret = []; // Autoexpensegroups

  // No rates at all? Return an empty list
  if (report.rateType !== 2 && report.rateType !== 3 && report.rateType !== 4 && report.rateType !== 5 && report.rateType !== 6) return ret;

  // Custom rates = 0, no rates will be returned. This should not happen
  if ((report.rateType === 2 || report.rateType === 6) && report.rateCustomDiet === 0) return ret;

  // Order reportsegments by date
  let orderedSegments = getSegmentsWithGroups(report);

  // Need at least one segment to work with
  if (orderedSegments.length === 0) return ret;

  // Get the individual days in the segments.
  // Call getDaysInSegments for each "trip" (seen from a dietary standpoint) on this report.
  // By default the whole report is one trip, but the user can force a segment to end the calculation and trigger a new trip.
  let segmentDays = [];
  let currentSegmentGroup = [];
  for (var i = 0; i < orderedSegments.length; i++) {
    currentSegmentGroup.push(orderedSegments[i]);
    if (orderedSegments[i].endsDietCalculation || i === orderedSegments.length - 1) {
      // This segments is the last one, or flagged as a trip-ending one
      var days = getDaysInSegments(currentSegmentGroup);
      if (days !== null) segmentDays.push(...days);
      currentSegmentGroup = [];
    }
  }

  if (!segmentDays || segmentDays.length === 0) return ret; // No days to work with

  // Calculate autoexpenses for each day
  let dayNumberInSegment = 1;
  segmentDays
    .filter((sd) => sd)
    .forEach((segmentDay) => {

      // Keep track of which day number in this segment (or possibly multiple continous segments) we are on, so we can deal with the rate change after day 28
      if (segmentDay.firstDay) { 
        dayNumberInSegment = 1;
      }
      else {
        dayNumberInSegment++;
      }

      let dayInfo = analyzeDay(segmentDay, report, userConfiguration, dayNumberInSegment);
      const diet_expensetype_label = report.rateType === 3 || report.rateType === 4 || report.rateType === 5 ? "state_diet" : "custom_diet";
      const diet_location_label = dayInfo.domestic ? i18n.t("calculation.norway") : dayInfo.location.officialPlaceName;

      // Add autoexpense for this timespan if we have a rate over 0
      if (dayInfo.rate > 0) {
        // Build an expense for this timespan
        const expense = autoExpenseConstructor();

        expense.autoExpenseType = diet_expensetype_label;
        expense.fromDate = segmentDay.from;
        expense.toDate = segmentDay.to;

        expense.location = diet_location_label;
        expense.geoCityId = segmentDay.segment.cityId;
        expense.geoCountryId = segmentDay.segment.countryId;

        expense.sum = dayInfo.rate;
        expense.taxfreeSum = dayInfo.taxfreePart;
        expense.taxableSum = dayInfo.rate - dayInfo.taxfreePart;

        // Preemptive sanity check to compensate for scenarios where the taxfree part starts out bigger than the sum, leading to negative values
        // This can happen when we are using tax free rates, but will mess up the math for the taxable part
        if (expense.taxfreeSum < 0) expense.taxfreeSum = 0;
        if (expense.taxableSum < 0) expense.taxableSum = 0;

        // Extended fields for export usage
        expense.hours = moment(segmentDay.to).diff(moment(segmentDay.from), "hours", true);
        expense.units = 1;
        expense.unitSum = expense.sum;
        expense.domestic = dayInfo.domestic ? 1 : 0;
        expense.overnight = dayInfo.overnight ? 1 : 0;
        expense.lodgingType = segmentDay.segment.lodgingType;
        expense.farFromHome = segmentDay.segment.farFromHome ? 1 : 0;
        expense.originalSum = dayInfo.rate; // The sum before meal reductions

        // Has the user set any meal-based overrides? This will reduce the total compensation
        // Filter on overrides that actually does something
        const aeo = report.autoExpenseOverrides
          .filter((o) => o.excludeDiet || o.excludedLodging || o.freeBreakfast || o.freeLunch || o.freeDinner)
          // Only use day part to ignore timezone differences
          // .find((o) => moment(o.expenseDate).isSame(moment(segmentDay.from), "day"));
          .find((o) => moment(o.expenseDate).format("YYYY-MM-DD") === segmentDay.from.format("YYYY-MM-DD"));

        if (aeo) {
          // Meal reductions
          const meal_reduction_breakfast_factor = dayInfo.domestic ? meal_reduction_domestic_breakfast : meal_reduction_nondomestic_breakfast;
          const meal_reduction_lunch_factor = dayInfo.domestic ? meal_reduction_domestic_lunch : meal_reduction_nondomestic_lunch;
          const meal_reduction_dinner_factor = dayInfo.domestic ? meal_reduction_domestic_dinner : meal_reduction_nondomestic_dinner;

          // Updated meal reduction logic in V24, subtract percentage from both taxfree and taxable parts individually. See revision notes on top

          // Calculate the reduction sum for each meal for the taxfree part, based on a fraction of the taxfree limit for the day
          const meal_reduction_breakfast_sum_taxfree = Math.round(expense.taxfreeSum * meal_reduction_breakfast_factor);
          const meal_reduction_lunch_sum_taxfree = Math.round(expense.taxfreeSum * meal_reduction_lunch_factor);
          const meal_reduction_dinner_sum_taxfree = Math.round(expense.taxfreeSum * meal_reduction_dinner_factor);

          // Calculate the reduction sum for each meal for the total sum, based on a fraction of the current payout overnight rate for the location (v35 used the full overnight rate, dayInfo.officialOvernightRate, as the base)
          const base_for_meal_reduction_sum = dayInfo.rate;
          const meal_reduction_breakfast_sum = Math.round(base_for_meal_reduction_sum * meal_reduction_breakfast_factor);
          const meal_reduction_lunch_sum = Math.round(base_for_meal_reduction_sum * meal_reduction_lunch_factor);
          const meal_reduction_dinner_sum = Math.round(base_for_meal_reduction_sum * meal_reduction_dinner_factor);

          // For reports with normal ratetypes, we put the normal meal reduction sums into the *reduction fields.
          // For reports with taxfree ratetypes (4 and 6), we use the taxfree reduction sums instead
          const use_taxfree_reduction_sums = report.rateType === 4 || report.rateType === 6;

          // Reduce the taxfree and taxable sums for each free meal
          if (aeo.freeBreakfast) {
            expense.taxfreeSum -= meal_reduction_breakfast_sum_taxfree;
            expense.sum -= meal_reduction_breakfast_sum;
            expense.breakfastReductionSum = use_taxfree_reduction_sums ? meal_reduction_breakfast_sum_taxfree : meal_reduction_breakfast_sum;
            expense.freeBreakfast = 1;
          }
          if (aeo.freeLunch) {
            expense.taxfreeSum -= meal_reduction_lunch_sum_taxfree;
            expense.sum -= meal_reduction_lunch_sum;
            expense.lunchReductionSum = use_taxfree_reduction_sums ? meal_reduction_lunch_sum_taxfree : meal_reduction_lunch_sum;
            expense.freeLunch = 1;
          }
          if (aeo.freeDinner) {
            expense.taxfreeSum -= meal_reduction_dinner_sum_taxfree;
            expense.sum -= meal_reduction_dinner_sum;
            expense.dinnerReductionSum = use_taxfree_reduction_sums ? meal_reduction_dinner_sum_taxfree : meal_reduction_dinner_sum;
            expense.freeDinner = 1;
          }

          // Recalculate taxable part
          expense.taxableSum = expense.sum - expense.taxfreeSum;

          // Zero out everything if all meals are checked, in case of rounding issues. (This is only true as long as the three meal reduction factors adds up to 100%)
          if (aeo.freeBreakfast && aeo.freeLunch && aeo.freeDinner) {
            expense.sum = 0;
            expense.taxfreeSum = 0;
            expense.taxableSum = 0;
          }

          // Zero out everything if this day is excluded
          if (aeo.excludeDiet) {
            expense.sum = 0;
            expense.taxfreeSum = 0;
            expense.taxableSum = 0;
            expense.excluded = 1;
          }

          // Sanity check
          if (expense.sum < 0) expense.sum = 0;
          if (expense.taxfreeSum < 0) expense.taxfreeSum = 0;
          if (expense.taxableSum < 0) expense.taxableSum = 0;
        }

        // If the report uses Ratetype 2 (custom rates) or Ratetype 3 (official rates), cap both the taxable or taxfree part to the actual sum
        if (report.rateType === 2 || report.rateType === 3) {
          if (expense.taxfreeSum > expense.sum) expense.taxfreeSum = expense.sum;
          if (expense.taxableSum > expense.sum) expense.taxableSum = expense.sum;
        }

        // If the report uses Ratetype 5 (taxable rates), move the whole sum from taxfree to the taxable part (make the whole sum taxable)
        if (report.rateType === 5) {
          expense.taxfreeSum = 0;
          expense.taxableSum = expense.sum;
        }

        // If the report uses Ratetype 4 or 6 (taxfree rates), override the main sum to reflect just the taxfree part. This is now the sum that will be used for payouts and everything else.
        if (report.rateType === 4 || report.rateType === 6) {
          expense.sum = expense.taxfreeSum;
          expense.taxableSum = 0;
          expense.unitSum = expense.taxfreeSum;
          expense.originalSum = dayInfo.taxfreePart;
          // Both UnitSum and OriginalSum are only used by webexport, and must also be updated when using taxfree rates
          // The *ReductionSum fields are used by webexport as well as a cosmetic field on the PDF export
          // They're currently based on the taxfree part regardless of the report's ratetype, so we don't need to modify them
        }

        segmentDay.segment.diet.autoExpenses.push(expense);
      }

      // Check for "Nattillegget"; extra compensation per overnight stay. Triggered and calculated as a single-expense autoexpensegroup under the following conditions:
      // * Domestic segment
      // * Official rates
      // * IncludeNightExtra is checked by the user
      // * One or more overnight stays
      if (dayInfo.domestic && (report.rateType === 3 || report.rateType === 4 || report.rateType === 5) && segmentDay.segment.includeNightExtra) {
        // One night = 6 hours between 22 and 06
        if (isOvernight(segmentDay.from, segmentDay.to, 21, 6)) {
          const nightexpense = autoExpenseConstructor();
          nightexpense.autoExpenseType = "state_overnight_extra";
          nightexpense.fromDate = segmentDay.from;
          nightexpense.geoCityId = segmentDay.segment.cityId;
          nightexpense.geoCountryId = segmentDay.segment.countryId;
          nightexpense.location = "Norge";
          nightexpense.sum = official_overnight_extra * 100;
          nightexpense.toDate = segmentDay.to;

          nightexpense.hours = moment(segmentDay.to).diff(moment(segmentDay.from), "hours", true);
          nightexpense.units = 1;
          nightexpense.unitSum = official_overnight_extra * 100;
          nightexpense.domestic = 1;
          nightexpense.overnight = 1;
          nightexpense.lodgingType = segmentDay.segment.lodgingType;
          nightexpense.farFromHome = segmentDay.segment.farFromHome ? 1 : 0;
          nightexpense.originalSum = nightexpense.sum;

          segmentDay.segment.nightExtra.description = `${i18n.t("calculation.nightExtra")} kr ${official_overnight_extra} ${i18n.t(
            "calculation.perNight"
          )}`;
          segmentDay.segment.nightExtra.autoExpenses.push(nightexpense);
        }
      }

      // Check for "Kompensasjons/Utenlandstillegget"; extra compensation for non-domestic trips. Triggered and calculated as a single-expense autoexpensegroup under the following conditions:
      // * Non-domestic segment
      // * Official rates
      // * IncludeOvernightAbroadExtra is checked by the user
      // * The total segment length is 12 hour or more
      if (
        !dayInfo.domestic &&
        (report.rateType === 3 || report.rateType === 4 || report.rateType === 5) &&
        segmentDay.segment.includeOvernightAbroadExtra &&
        moment(segmentDay.to).diff(moment(segmentDay.from), "hours", true) >= 12
      ) {
        // Add a official_nondomestic_extra per night
        const nondomesticexpense = autoExpenseConstructor();
        nondomesticexpense.autoExpenseType = "state_nondomestic_extra";
        nondomesticexpense.fromDate = segmentDay.from;
        nondomesticexpense.geoCityId = dayInfo.location.geoCityId;
        nondomesticexpense.geoCountryId = dayInfo.location.geoCountryId;
        nondomesticexpense.location = dayInfo.location.officialPlaceName;
        nondomesticexpense.sum = official_nondomestic_extra * 100;
        nondomesticexpense.toDate = segmentDay.to;

        nondomesticexpense.hours = moment(segmentDay.to).diff(moment(segmentDay.from), "hours", true);
        nondomesticexpense.units = 1;
        nondomesticexpense.unitSum = official_nondomestic_extra * 100;
        nondomesticexpense.domestic = 0;
        nondomesticexpense.overnight = 1;
        nondomesticexpense.lodgingType = segmentDay.segment.lodgingType;
        nondomesticexpense.farFromHome = segmentDay.segment.farFromHome ? 1 : 0;
        nondomesticexpense.originalSum = nondomesticexpense.sum;
        nondomesticexpense.breakfastReductionSum = 0;
        nondomesticexpense.lunchReductionSum = 0;
        nondomesticexpense.dinnerReductionSum = 0;

        segmentDay.segment.nonDomesticExtra.description = `${i18n.t("calculation.nonDomesticExtra")} ${i18n.t("for")} ${
          dayInfo.location.officialPlaceName
        }, ${official_nondomestic_extra} ${i18n.t("calculation.perDayTaxable")}`;
        segmentDay.segment.nonDomesticExtra.autoExpenses.push(nondomesticexpense);
      }
    });

  // Merge and cleanup
  orderedSegments.forEach((orderedSegment) => {
    // Merge all nightextras if there are more than 1
    // if (
    //   orderedSegment.nightExtra &&
    //   orderedSegment.nightExtra.autoExpenses.length > 1
    // ) {
    //   const numnights = orderedSegment.nightExtra.autoExpenses.length;
    //   orderedSegment.nightExtra.autoExpenses[0].units = numnights;
    //   orderedSegment.nightExtra.autoExpenses[0].sum *= numnights;
    //   orderedSegment.nightExtra.autoExpenses.length = 1;
    //   orderedSegment.nightExtra.description = `${i18n.t(
    //     "calculation.nightExtra"
    //   )}, ${numnights} ${i18n.t(
    //     "calculation.nights"
    //   )} x kr ${official_overnight_extra}`;
    // }
    // // Merge all nondomestic extras if there are more than 1
    // if (
    //   orderedSegment.nonDomesticExtra &&
    //   orderedSegment.nonDomesticExtra.autoExpenses.length > 1
    // ) {
    //   var numDays = orderedSegment.nonDomesticExtra.autoExpenses.length;
    //   orderedSegment.nonDomesticExtra.autoExpenses[0].units = numDays;
    //   orderedSegment.nonDomesticExtra.autoExpenses[0].sum *= numDays;
    //   orderedSegment.nonDomesticExtra.autoExpenses.length = 1;
    //   orderedSegment.nonDomesticExtra.description = `${i18n.t(
    //     "calculation.nonDomesticExtra"
    //   )} ${i18n.t("for")} ${
    //     orderedSegment.nonDomesticExtra.location
    //   }, ${numDays} ${i18n.t(
    //     "calculation.days"
    //   )} x kr ${official_nondomestic_extra} (${i18n.t("calculation.taxable")})`;
    // }
    // Return any groups that contains lines
    if (orderedSegment.diet && orderedSegment.diet.autoExpenses.length > 0) ret.push(orderedSegment.diet);
    if (orderedSegment.nightExtra && orderedSegment.nightExtra.autoExpenses.length > 0) ret.push(orderedSegment.nightExtra);
    if (orderedSegment.nonDomesticExtra && orderedSegment.nonDomesticExtra.autoExpenses.length > 0) ret.push(orderedSegment.nonDomesticExtra);
  });

  return ret;
};
