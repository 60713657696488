import validator from "validator";
import isEmpty from "lodash/isEmpty";
import i18n from "../i18n/i18nConfig";
import { ValidateSignupResult, ValidateUpgradeResult } from "../types";

// Validates self-signup initial signup details
// Pass afterSms=true to also validate the fields that are required after the SMS code has been sent to the user

export const validateSignup = (
  data: { firstname: string; lastname: string; email: string; mobile: string; code: string; pass: string },
  afterSms?: boolean
): ValidateSignupResult => {
  const errors: {
    firstname?: string;
    lastname?: string;
    email?: string;
    mobile?: string;
    code?: string;
    pass?: string;
  } = {};

  if (validator.isEmpty(data.firstname)) {
    errors.firstname = i18n.t("requiredField");
  }
  if (validator.isEmpty(data.lastname)) {
    errors.lastname = i18n.t("requiredField");
  }

  if (validator.isEmpty(data.email)) {
    errors.email = i18n.t("requiredField");
  } else if (!validator.isEmail(data.email)) {
    errors.email = i18n.t("invalidEmail");
  }

  if (validator.isEmpty(data.mobile)) {
    errors.mobile = i18n.t("requiredField");
  } else if (!validator.matches(data.mobile, /[4|9]\d{7}$/i)) {
    errors.mobile = i18n.t("invalidMobile");
  }

  if (afterSms) {
    if (validator.isEmpty(data.code)) {
      errors.code = i18n.t("requiredField");
    }

    if (validator.isEmpty(data.pass)) {
      errors.pass = i18n.t("requiredField");
    } else if (!validator.isLength(data.pass, { min: 4 })) {
      errors.pass = i18n.t("tooShortPassword");
    }
  }

  return {
    errors,
    isValid: isEmpty(errors)
  };
};

// Validates self-signup account upgrade details

export const validateUpgrade = (data: {
  person: { firstname: string; lastname: string };
  company: { orgno: string; name: string; zip: string; city: string };
}): ValidateUpgradeResult => {
  const errors: { firstname?: string; lastname?: string; orgno?: string; name?: string; zip?: string; city?: string } = {};

  if (validator.isEmpty(data.person.firstname)) {
    errors.firstname = i18n.t("requiredField");
  }
  if (validator.isEmpty(data.person.lastname)) {
    errors.lastname = i18n.t("requiredField");
  }
  if (validator.isEmpty(data.company.orgno)) {
    errors.orgno = i18n.t("requiredField");
  }
  if (validator.isEmpty(data.company.name)) {
    errors.name = i18n.t("requiredField");
  }
  if (validator.isEmpty(data.company.zip)) {
    errors.zip = i18n.t("requiredField");
  }
  if (validator.isEmpty(data.company.city)) {
    errors.city = i18n.t("requiredField");
  }

  return {
    errors,
    isValid: isEmpty(errors)
  };
};
