import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useUserConfiguration } from "../../shared/queries/queries";
import Icon from "../common/Icon";
// import { createExpense } from "../../shared/actions/localEntities/expenseActions";
import "../../styles/createbuttons.css";
import { currentExpenseTypes } from "../../shared/utils/currentExpenseTypes";

interface CreateButtonsProps {
  showTravelReport?: boolean;
  showExpenseReport?: boolean;
  showMileageReport?: boolean;
  showExpenseReportFromExpenses?: boolean;
  showMileageReportFromDriving?: boolean;
  showExpense?: boolean;
  showDriving?: boolean;
  onCreateReport?: (reportType: ReportType) => void;
  onCreateExpense?: (expenseTypeId: number) => void;
  onCreateReportFromExpenses?: () => void; // Must be provided if showExpenseReportFromExpenses or showMileageReportFromDriving is true
  disabled?: boolean;
}
type ReportType = 1 | 2 | 3;

const CreateButtons = ({
  showTravelReport,
  showExpenseReport,
  showMileageReport,
  showExpenseReportFromExpenses,
  showMileageReportFromDriving,
  showExpense,
  showDriving,
  onCreateReport,
  onCreateExpense,
  onCreateReportFromExpenses,
  disabled
}: CreateButtonsProps) => {
  const [t] = useTranslation();
  const history = useHistory();

  // Allow parent to override these functions

  /**
   * Creates a new report of the given type and redirects to that report
   * @param {number} reportType - 1:Travel report, 2:Expense report, 3: Driving report
   */

  const createReport =
    onCreateReport ||
    ((reportType: ReportType) => {
      history.push("/createreport", { reportType });
    });

  /**
   * Creates a new standalone expense of the default expense type or default driving type and redirects to that expense
   */
  const createExpense =
    onCreateExpense ||
    ((expenseTypeId: number) => {
      history.push(`/expense/new?expenseType=${expenseTypeId}&from=expenses`);
    });

  /**
   * Create a new expense report based on a selection of standalone expenses. This function must be provided by parent.
   */
  const createReportFromExpenses =
    onCreateReportFromExpenses ||
    (() => {
      throw new Error("onCreateReportFromExpenses not provided to Createbuttons");
    });

  const userConfigurationQuery = useUserConfiguration();

  // Bail out here if we don't have a valid user configuration
  if (!userConfigurationQuery.data) {
    console.log("CreateButtons: No userconfig, bailing out");
    return null;
  }

  // Must be provided by parent
  if ((showExpenseReportFromExpenses || showMileageReportFromDriving) && !onCreateReportFromExpenses) {
    console.error("createReportFromExpenses not provided to CreateButtons!");
    return <div className="global-error">ERROR: createReportFromExpenses not provided to CreateButtons!</div>;
  }

  const userConfiguration = userConfigurationQuery.data.configuration;

  const expenseTypes = currentExpenseTypes(userConfiguration);
  const defaultExpenseType = expenseTypes.find((et) => et.is_Default) || expenseTypes[0];
  const defaultExpenseTypeId = defaultExpenseType.id;

  const defaultDrivingExpenseType = expenseTypes.find((et) => et.is_Driving);
  const defaultDrivingExpenseTypeId = defaultDrivingExpenseType ? defaultDrivingExpenseType.id : null;

  let hideTravelReport_policy = false;
  let hideExpenseReport_policy = false;
  let hideMileageReport_policy = false;
  if (userConfiguration.product.uxPolicy?.general) {
    if (userConfiguration.product.uxPolicy?.general?.hideCreateTravelReport) hideTravelReport_policy = true;
    if (userConfiguration.product.uxPolicy?.general?.hideCreateExpenseReport) hideExpenseReport_policy = true;
    if (userConfiguration.product.uxPolicy?.general?.hideCreateMileageReport) hideMileageReport_policy = true;
  }

  let buttons = 0;
  if (showTravelReport && !hideTravelReport_policy) buttons++;
  if (showExpenseReport && !hideExpenseReport_policy) buttons++;
  if (showMileageReport && !hideMileageReport_policy) buttons++;
  if (showExpenseReportFromExpenses) buttons++;
  if (showMileageReportFromDriving) buttons++;
  if (showExpense) buttons++;
  if (showDriving) buttons++;

  return (
    <div className={`create-buttons-panel buttons-${buttons}`}>
      {showTravelReport && !hideTravelReport_policy && (
        <div className="create-button">
          <Button disabled={disabled} onClick={() => createReport(1)} id="createButtons_TravelReport">
            <div className="button-icon hidden-xs">
              <Icon icon="travelReport" />
            </div>
            <div className="button-description">
              <div className="plus-icon hidden-xs">
                <Icon icon="add" />
              </div>
              <strong>{t("createButtons.startTravelReport")}</strong>
              <div className="button-description-small hidden-xs">{t("createButtons.travelReportExplanation")}</div>
            </div>
          </Button>
        </div>
      )}
      {showExpenseReport && !hideExpenseReport_policy && (
        <div className="create-button">
          <Button disabled={disabled} onClick={() => createReport(2)} id="createButtons_ExpenseReport">
            <div className="button-icon hidden-xs">
              <Icon icon="expenseReport" />
            </div>
            <div className="button-description">
              <div className="plus-icon hidden-xs">
                <Icon icon="add" />
              </div>
              <strong>{t("createButtons.startExpenseReport")}</strong>
              <div className="button-description-small hidden-xs">{t("createButtons.expenseReportExplanation")}</div>
            </div>
          </Button>
        </div>
      )}
      {showMileageReport && !hideMileageReport_policy && defaultDrivingExpenseTypeId !== null && (
        <div className="create-button">
          <Button disabled={disabled} onClick={() => createReport(3)} id="createButtons_MileageReport">
            <div className="button-icon hidden-xs">
              <Icon icon="drivingReport" />
            </div>
            <div className="button-description">
              <div className="plus-icon hidden-xs">
                <Icon icon="add" />
              </div>
              <strong>{t("createButtons.startMileageReport")}</strong>
              <div className="button-description-small hidden-xs">{t("createButtons.mileageReportExplanation")}</div>
            </div>
          </Button>
        </div>
      )}
      {showExpense && (
        <div className="create-button">
          <Button disabled={disabled} onClick={() => createExpense(defaultExpenseTypeId)} id="createButtons_Expense">
            <div className="button-icon hidden-xs">
              <Icon icon="expense" />
            </div>
            <div className="button-description">
              <div className="plus-icon hidden-xs">
                <Icon icon="add" />
              </div>
              <strong>{t("createButtons.newExpense")}</strong>
              <div className="button-description-small hidden-xs">{t("createButtons.expenseExplanation")}</div>
            </div>
          </Button>
        </div>
      )}
      {showDriving && defaultDrivingExpenseTypeId !== null && (
        <div className="create-button">
          <Button disabled={disabled} onClick={() => createExpense(defaultDrivingExpenseTypeId)} id="createButtons_Mileage">
            <div className="button-icon hidden-xs">
              <Icon icon="mileage" />
            </div>
            <div className="button-description">
              <div className="plus-icon hidden-xs">
                <Icon icon="add" />
              </div>
              <strong>{t("createButtons.newMileage")}</strong>
              <div className="button-description-small hidden-xs">{t("createButtons.mileageExplanation")}</div>
            </div>
          </Button>
        </div>
      )}
      {showExpenseReportFromExpenses && (
        <div className="create-button">
          <Button disabled={disabled} onClick={() => createReportFromExpenses()} id="createButtons_ExpenseReportFromExpenses">
            <div className="button-icon hidden-xs">
              <Icon icon="expenseReport" />
            </div>
            <div className="button-description">
              <div className="plus-icon hidden-xs">
                <Icon icon="add" />
              </div>
              <strong>{t("createButtons.expenseReportFromExpenses")}</strong>
              <div className="button-description-small hidden-xs">{t("createButtons.expenseReportFromExpensesExplanation")}</div>
            </div>
          </Button>
        </div>
      )}
      {showMileageReportFromDriving && (
        <div className="create-button">
          <Button disabled={disabled} onClick={() => createReportFromExpenses()} id="createButtons_MileageReportFromExpenses">
            <div className="button-icon hidden-xs">
              <Icon icon="drivingReport" />
            </div>
            <div className="button-description">
              <div className="plus-icon hidden-xs">
                <Icon icon="add" />
              </div>
              <strong>{t("createButtons.mileageReportFromDriving")}</strong>
              <div className="button-description-small hidden-xs">{t("createButtons.mileageReportFromDrivingExplanation")}</div>
            </div>
          </Button>
        </div>
      )}
    </div>
  );
};

export default CreateButtons;
