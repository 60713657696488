import { useState } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useBus, BusEvent } from "../../utils/useBus";
import BetterTour from "../common/BetterTour";
import { Row, Col, FormGroup, ControlLabel, Button } from "react-bootstrap";
import TextFieldGroup from "../common/TextFieldGroup";
import SelectGroup from "../common/SelectGroup";
import NumberFieldGroup from "../common/NumberFieldGroup";
import TemporalPicker from "../common/TemporalPicker";
import Icon from "../common/Icon";
import { rateTypes, rateType_1001_companyIds } from "../../shared/utils/constants";
import "../../styles/report.css";
import { Report, UserConfiguration, ValidateReportHeaderError } from "../../shared/types";
import { TourName } from "../../utils/tours";

interface ReportHeaderProps {
  report: Report;
  userConfiguration: UserConfiguration;
  readOnly?: boolean;
  onChange: (report: Report, fromConstructor?: boolean) => void;
  showTour: boolean;
  validationErrors: ValidateReportHeaderError[];
}
const ReportHeader = ({ report, userConfiguration, readOnly, onChange, showTour, validationErrors }: ReportHeaderProps) => {
  const [t] = useTranslation();

  const [tourOpen, setTourOpen] = useState(false);
  const [berserkMode, setBerserkMode] = useState(false);

  const busHandler = (event: BusEvent) => {
    if (event === "BERSERK_MODE") setBerserkMode(true);
  };
  useBus(busHandler);

  // Handle changes in fields
  const onChangeField = (partialReport: Partial<Report>, fromConstructor?: boolean) => {
    // Merge changes into passed-down report
    const reportNew = { ...report, ...partialReport };
    // Throw it up the chain
    onChange(reportNew, fromConstructor);
  };

  const closeTour = () => {
    setTourOpen(false);
  };

  const { reportTypeId, description, companyName, referenceNumber, purpose, comment, rateType, rateCustomDiet, rateDate, advance } = report;
  const uxPolicyReport = userConfiguration.product?.uxPolicy?.report;
  const uxPolicyDiet = userConfiguration.product?.uxPolicy?.diet;

  let tourName: TourName | null = null;
  if (reportTypeId === 1) tourName = "travelReportPage";
  if (reportTypeId === 2) tourName = "expenseReportPage";
  if (reportTypeId === 3) tourName = "mileageReportPage";

  let rateTypeOptions = rateTypes.map((rt) => ({
    label: t(`rateTypes.${rt.description}`),
    value: rt.id
  }));

  // Expose additional ratetypes based on person's parent company ID
  if (userConfiguration.person && userConfiguration.person.parentType === "company") {
    const personCompanyId = userConfiguration.person.parentId;
    if (personCompanyId && rateType_1001_companyIds.includes(personCompanyId)) {
      rateTypeOptions.push({
        label: "OneCo",
        value: 1001
      });
    }
  }

  // In berserk mode, add an invalid ratetype. This allows us to create an invalid reportheader for testing
  if (berserkMode)
    rateTypeOptions.push({
      label: "INVALID OPTION - WILL BREAK",
      value: 100
    });

  // Remove available ratetypes based on UX policy, except any that has already been selected
  if (uxPolicyDiet && uxPolicyDiet.hiddenRateTypeIds && uxPolicyDiet?.hiddenRateTypeIds.length > 0) {
    var hiddenIds = uxPolicyDiet.hiddenRateTypeIds.filter((o) => o !== rateType);
    rateTypeOptions = rateTypeOptions.filter((o) => !hiddenIds.includes(o.value));
  }

  // Check UX policy for individual fields
  const policyAdvance = uxPolicyReport?.fields?.advance;
  const policyComment = uxPolicyReport?.fields?.comment;
  const policyCompanyName = uxPolicyReport?.fields?.companyName;
  const policyDescription = uxPolicyReport?.fields?.description;
  const policyPurpose = uxPolicyReport?.fields?.purpose;
  const policyReferenceNumber = uxPolicyReport?.fields?.referenceNumber;

  return (
    <>
      {showTour && tourOpen && tourName && (
        <BetterTour tourName={tourName} onRequestClose={closeTour} />
        // <Tour steps={getTour(tourName)} isOpen={tourOpen} onRequestClose={closeTour} rounded={4} showNumber={false} />
      )}
      <div className="report-component">
        <div className="report-component-header">
          <Row>
            <Col xs={8}>
              <div className="report-component-title">{description || `[${t("noDescription")}]`}</div>
            </Col>
            <Col xs={4}>
              {showTour && (
                <div className="report-header-tourstart">
                  <Button
                    title={t("help")}
                    onClick={() => {
                      setTourOpen(true);
                    }}
                  >
                    <Icon icon="help" />
                  </Button>
                </div>
              )}
            </Col>
          </Row>
        </div>

        <div id="reportHeaderMundaneFields">
          <Row>
            <Col sm={6} hidden={policyDescription && policyDescription.hide}>
              <TextFieldGroup
                field="description"
                label={t("reportDetailsView.detailsTab.description")}
                value={description}
                onChange={(value: string) => onChangeField({ description: value })}
                placeholder={t("reportDetailsView.detailsTab.placeholders.description")}
                readOnly={readOnly || (policyDescription && policyDescription.readOnly)}
                required={policyDescription && policyDescription.required}
                error={validationErrors.find((o) => o.fieldName === "description")?.message}
              />
            </Col>

            <Col sm={3} hidden={policyCompanyName && policyCompanyName.hide}>
              <TextFieldGroup
                field="companyName"
                label={t("reportDetailsView.detailsTab.company")}
                value={companyName}
                onChange={(value: string) => onChangeField({ companyName: value })}
                placeholder={t("reportDetailsView.detailsTab.placeholders.company")}
                readOnly={readOnly || (policyCompanyName && policyCompanyName.readOnly)}
                required={policyCompanyName && policyCompanyName.required}
                error={validationErrors.find((o) => o.fieldName === "companyName")?.message}
              />
            </Col>
            <Col sm={3} hidden={policyReferenceNumber && policyReferenceNumber.hide}>
              <TextFieldGroup
                field="referenceNumber"
                label={t("reportDetailsView.detailsTab.reference")}
                value={referenceNumber}
                onChange={(value: string) => onChangeField({ referenceNumber: value })}
                placeholder={t("reportDetailsView.detailsTab.placeholders.reference")}
                readOnly={readOnly || (policyReferenceNumber && policyReferenceNumber.readOnly)}
                required={policyReferenceNumber && policyReferenceNumber.required}
                error={validationErrors.find((o) => o.fieldName === "referenceNumber")?.message}
              />
            </Col>
          </Row>

          <Row>
            <Col sm={6} hidden={policyPurpose && policyPurpose.hide}>
              <TextFieldGroup
                field="purpose"
                label={t("reportDetailsView.detailsTab.purpose")}
                value={purpose}
                onChange={(value: string) => onChangeField({ purpose: value })}
                placeholder={t("reportDetailsView.detailsTab.placeholders.purpose")}
                componentClass="textarea"
                rows={4}
                readOnly={readOnly || (policyPurpose && policyPurpose.readOnly)}
                required={policyPurpose && policyPurpose.required}
                error={validationErrors.find((o) => o.fieldName === "purpose")?.message}
              />
            </Col>

            <Col sm={6} hidden={policyComment && policyComment.hide}>
              <TextFieldGroup
                field="comment"
                label={t("reportDetailsView.detailsTab.comment")}
                value={comment}
                onChange={(value: string) => onChangeField({ comment: value })}
                placeholder={t("reportDetailsView.detailsTab.placeholders.comment")}
                componentClass="textarea"
                rows={4}
                readOnly={readOnly || (policyComment && policyComment.readOnly)}
                required={policyComment && policyComment.required}
                error={validationErrors.find((o) => o.fieldName === "comment")?.message}
              />
            </Col>
          </Row>
        </div>
        <Row>
          {reportTypeId === 1 && (
            <Col sm={3} id="reportHeaderRateType">
              <SelectGroup
                field="rateType"
                label={t("reportDetailsView.detailsTab.rateType")}
                value={rateType}
                onChange={(value: number) => onChangeField({ rateType: Number(value) })}
                readOnly={readOnly}
                options={rateTypeOptions}
              />
              {(rateType === 2 || rateType === 6) && (
                <NumberFieldGroup
                  field="rateCustomDiet"
                  label={rateType === 2 ? t("rateTypes.custom24") : t("rateTypes.customTaxfree")}
                  value={rateCustomDiet}
                  useHundredths
                  allowDecimals
                  decimals={2}
                  onChange={(valueNumber: number | null, valueString: string) => onChangeField({ rateCustomDiet: valueNumber || 0 })}
                  readOnly={readOnly}
                />
              )}
            </Col>
          )}

          {reportTypeId === 1 && (
            <Col sm={3}>
              {(rateType === 2 || rateType === 3 || rateType === 4 || rateType === 5 || rateType === 6 || rateType === 1001) && (
                <FormGroup>
                  <ControlLabel>{t("reportDetailsView.detailsTab.rateDate")}</ControlLabel>
                  <div>
                    <TemporalPicker
                      startDate={rateDate}
                      onChange={(o: { start: string; end?: string }, fromConstructor?: boolean) => {
                        onChangeField(
                          {
                            rateDate: moment(o.start).format("YYYY-MM-DD")
                          },
                          fromConstructor
                        );
                      }}
                      readOnly={readOnly}
                    />
                  </div>
                </FormGroup>
              )}
            </Col>
          )}

          <Col sm={3} id="reportHeaderAdvance" hidden={policyAdvance && policyAdvance.hide}>
            <NumberFieldGroup
              field="advance"
              label={t("reportDetailsView.detailsTab.advance")}
              value={advance}
              useHundredths
              allowDecimals
              decimals={2}
              onChange={(valueNumber: number | null, valueString: string) => onChangeField({ advance: valueNumber || 0 })}
              readOnly={readOnly || (policyAdvance && policyAdvance.readOnly)}
              required={policyAdvance && policyAdvance.required}
              error={validationErrors.find((o) => o.fieldName === "advance")?.message}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ReportHeader;
