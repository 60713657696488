import { useUserConfiguration } from "../../shared/queries/queries";
import { User } from "../../shared/types";

interface UserDetailProps {
  user?: User;
  detail: "firstname" | "lastname" | "fullname" | "email";
  whileLoading?: string | JSX.Element;
}
const UserDetail = ({ user, detail, whileLoading }: UserDetailProps) => {
  const userId = user ? user.id : undefined;
  const jwtToken = user ? user.jwtToken : undefined;
  const userConfig = useUserConfiguration(userId, jwtToken).data;
  if (userConfig && userConfig.configuration && userConfig.configuration.person) {
    const personConfig = userConfig.configuration.person;
    let ret = `${personConfig.firstName} ${personConfig.lastName}`;
    if (detail === "firstname") ret = personConfig.firstName;
    if (detail === "lastname") ret = personConfig.lastName;
    if (detail === "email") ret = personConfig.email;
    return <>{ret}</>;
  }
  return <>{whileLoading || "..."}</>;
};

export default UserDetail;
