import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FormGroup, InputGroup, Dropdown, MenuItem, Modal } from "react-bootstrap";
import { changeLanguage } from "../shared/actions/languageActions";
import CommandBox from "./CommandBox";
import RoleLink from "./RoleLink";
import ContactUs from "./ContactUs";
import Flag from "../components/common/Flag";
import UserDetail from "../components/common/UserDetail";
import { getApiBaseUrl } from "../shared/api/api";
import { switchUser } from "../shared/actions/userActions";
import { useStore } from "../shared/store/store";
import { useBus, BusEvent } from "../utils/useBus";
import { useUserConfiguration } from "../shared/queries/queries";

import "../styles/topbar.css";
import Icon from "./common/Icon";

import playstoreBadgeEN from "./../images/playstore_badge_en.png";
import playstoreBadgeNO from "./../images/playstore_badge_no.png";

import appstoreBadgeEN from "./../images/appstore_badge_en.svg";
import appstoreBadgeNO from "./../images/appstore_badge_no.svg";
import { UserConfiguration } from "../shared/types";

const TopBar = () => {
  const [t] = useTranslation();

  // const [showingLogoutConfirmation, setShowingLogoutConfirmation] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  const [showAppsModal, setShowAppsModal] = useState(false);
  const [berserkMode, setBerserkMode] = useState(false);

  const currentUser = useStore((state) => state.getCurrentUser());
  const allUsers = useStore((state) => state.users);
  const language = useStore((state) => state.language);
  const history = useHistory();

  const busHandler = (event: BusEvent) => {
    if (event === "BERSERK_MODE") setBerserkMode(true);
  };
  useBus(busHandler);

  const userConfigurationQuery = useUserConfiguration();
  const userConfiguration: UserConfiguration | undefined = userConfigurationQuery.data?.configuration;

  const username = userConfiguration?.person?.name || "";
  const useremail = userConfiguration?.person.email || "";
  const roles = userConfiguration?.person.roles || [];
  const otherUsers = currentUser ? allUsers.filter((o) => o.id !== currentUser.id) : [];
  const token = currentUser ? currentUser.jwtToken : "";

  const playstoreBadge = ["nb", "no"].includes(language) ? playstoreBadgeNO : playstoreBadgeEN;
  const appstoreBadge = ["nb", "no"].includes(language) ? appstoreBadgeNO : appstoreBadgeEN;

  const playstoreUrl = t("appUrls.android");
  const appstoreUrl = t("appUrls.iOS");

  // Consider a global state for "should not log out" and reapply this blocker modal
  // For example, if queries.save* is still executing?
  // If anything is queued for saving, logging out means permanently losing that data. Warn the user.
  const logout = () => {
    // if (!showingLogoutConfirmation) {
    //   setShowingLogoutConfirmation(true);
    // } else {
    //   confirmLogout();
    // }
    confirmLogout();
  };

  const loginOther = () => {
    history.push("/login");
  }

  // const abortLogout = () => {
  //   setShowingLogoutConfirmation(false);
  // };

  const confirmLogout = () => {
    window.location.replace(`${getApiBaseUrl()}/login/logout?redirectUrl=${window.location.origin}/logout`);
  };

  return (
    <div id="topbar-wrapper">
      {userConfiguration && (
        <ContactUs show={showContactModal} userConfiguration={userConfiguration.person} onHide={() => setShowContactModal(false)} />
      )}

      <Modal show={showAppsModal} onHide={() => setShowAppsModal(false)}>
        <Modal.Header closeButton={true}>
          <Modal.Title>{t("contactUs.downloadApps")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="download-apps-wrapper">
            <div>
              <a href={playstoreUrl} target="_blank" rel="noreferrer noopener">
                <img alt="Android" src={playstoreBadge} />
              </a>
            </div>
            <div>
              <a href={appstoreUrl} target="_blank" rel="noreferrer noopener">
                <img alt="iOS" src={appstoreBadge} />
              </a>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* <Modal show={showingLogoutConfirmation} onHide={abortLogout}>
        <Modal.Header>
          <Modal.Title>{t("syncErrorHandling.logoutWarningTitle")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t("syncErrorHandling.logoutWarningText")}</Modal.Body>
        <Modal.Footer>
          <Button onClick={abortLogout}>{t("no")}</Button>
          <Button bsStyle="danger" onClick={confirmLogout}>
            {t("yes")}
          </Button>
        </Modal.Footer>
      </Modal> */}

      <div className="topbar-left-wrapper">
        <FormGroup>
          <InputGroup>
            <InputGroup.Addon>
              <Link to="/">
                <div id="topbar-logo" />
              </Link>
            </InputGroup.Addon>
            <CommandBox disabled={!userConfiguration} placeholder={!userConfiguration ? "" : `${t("commandBox.placeholder")}`} />
          </InputGroup>
        </FormGroup>
      </div>

      <div className="topbar-right-wrapper">
        {userConfiguration && (
          <Dropdown id="userMenu">
            <Dropdown.Toggle>
              {berserkMode ? <Icon icon="doomGuy" /> : <Icon icon="user" />}
              <span style={{ fontSize: "1.2em" }}> {username}</span>
            </Dropdown.Toggle>

            <Dropdown.Menu id="topbar-menu">
              <div id="topbar-menu-header">
                <div id="topbar-menu-user-icon">
                  <Icon icon="user" />
                </div>
                <div id="topbar-menu-name">{username}</div>
                <div id="topbar-menu-email">{useremail}</div>
                <div id="topbar-menu-profile">
                  <Link to="/profile">{t("navBar.myProfile")}</Link>
                </div>
              </div>

              {roles.length > 0 && (
                <>
                  <MenuItem divider />
                  <MenuItem className="topbar-menu-subheader" disabled>
                    {t("navBar.administration")}
                  </MenuItem>
                  {roles.map((role) => (
                    <RoleLink key={`${role.roleType}|${role.roleForId}`} role={role} token={token} language={language} />
                  ))}
                </>
              )}

              {otherUsers.length > 0 && (
                <>
                  <MenuItem divider />
                  <MenuItem className="topbar-menu-subheader" disabled>
                    {t("navBar.switchUser")}
                  </MenuItem>
                  {otherUsers.map((user) => (
                    <MenuItem key={user.id} onSelect={async () => await switchUser(user.id)}>
                      <UserDetail user={user} detail="fullname" />
                    </MenuItem>
                  ))}
                </>
              )}

              <MenuItem divider />
              <MenuItem className="topbar-menu-subheader" disabled>
                {t("navBar.traveltext")}
              </MenuItem>
              <MenuItem onSelect={() => setShowContactModal(true)}>{t("contactUs.getSupport")}</MenuItem>
              <MenuItem onSelect={() => setShowAppsModal(true)}>{t("contactUs.downloadApps")}</MenuItem>

              <MenuItem divider />
              <MenuItem className="topbar-menu-subheader" disabled>
                {t("navBar.language")}
              </MenuItem>
              <MenuItem onSelect={async () => await changeLanguage("nb")}>
                <Flag country="no" />
                {t("navBar.norwegian")}
                <span className="language-checkmark">{language === "nb" ? ` ✔` : ""}</span>
              </MenuItem>
              <MenuItem onSelect={async () => await changeLanguage("en")}>
                <Flag country="gb" />
                {t("navBar.english")}
                <span className="language-checkmark">{language === "en" ? ` ✔` : ""}</span>
              </MenuItem>

              <MenuItem divider />
              <MenuItem id="topbar-menu-login-other" onSelect={() => loginOther()}>
                <Icon icon="accountStar"></Icon>
                {t("navBar.loginOther")}
              </MenuItem>
              <MenuItem id="topbar-menu-logout" onSelect={() => logout()}>
                <Icon icon="exit"></Icon>
                {`${t("navBar.logOut")} ${username}`}
              </MenuItem>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </div>
    </div>
  );
};

export default TopBar;
