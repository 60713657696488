import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface DeleteModalProps {
  show?: boolean;
  cancel: () => void;
  confirm: () => void;
}
const DeleteModal = ({ show, cancel, confirm }: DeleteModalProps) => {
  const [t] = useTranslation();
  return (
    <Modal
      show={show}
      onHide={cancel}
      onKeyPress={(event) => {
        if (event && event.key && event.key === "Enter") confirm();
      }}
    >
      <Modal.Header>
        <Modal.Title>{t("confirmDeletionTitle")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{t("confirmDeletionText")}</Modal.Body>
      <Modal.Footer>
        <Button onClick={() => cancel()}>{t("no")}</Button>
        <Button bsStyle="danger" onClick={() => confirm()}>
          {t("yes")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteModal;
