import { useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { FormGroup, ControlLabel, FormControl, Button, ButtonGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { validateLogin } from "../../shared/validation/login";
import { login, logout, loginWithToken, switchUser, getConfiguration } from "../../shared/actions/userActions";
import { changeLanguage } from "../../shared/actions/languageActions";
import { useStore } from "../../shared/store/store";
import Flag from "../common/Flag";
import Icon from "../common/Icon";
import UserDetail from "../common/UserDetail";
import { capitalize } from "../../shared/utils/helpers";
import "../../styles/login.css";
import { trackEvent } from "../../utils/eventTracker";
import { ValidateLoginErrors } from "../../shared/types";
import Spinner from "../common/Spinner";
import useConstructor from "../../shared/utils/useConstructor";
import { setAuthenticationExpired } from "../../utils/webclientStore";

const LoginPage = () => {
  const [t] = useTranslation();
  const history = useHistory();
  const location = useLocation<{
    redirectUrl?: string;
  }>();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState<ValidateLoginErrors>({});
  const [isLoading, setIsLoading] = useState(false);
  const [showLoginForm, setShowLoginForm] = useState(true);

  const language = useStore((state) => state.language);
  const existingUsers = useStore((state) => state.users);
  const authenticationExpired = useStore((state) => state.clientSpecific.authenticationExpired);

  // Make sure to reset the authentication expired flag, we're probably here to login
  if (authenticationExpired) setAuthenticationExpired(false);

  useConstructor(() => {
    // code to run on component mount
    // Check for a one-time-login token

    const checkOTL = async (token: string, personId: number) => {
      setIsLoading(true);
      try {
        trackEvent("Login", "Authenticating user from token", personId);
        await loginWithToken("", token, personId);
        history.push((location.state && location.state.redirectUrl) || "/");
      } catch (error: any) {
        let errormessage = error.message;
        if (errormessage === "401") {
          errormessage = t("login.invalidcredentials");
        }
        setErrors({ form: errormessage });
        setIsLoading(false);
      }
    };

    if (location.pathname.indexOf("/otl") > -1) {
      const q = new URLSearchParams(location.search);
      const token = q.get("token");
      const personId = Number(q.get("pid"));
      if (token) checkOTL(token, personId);
    }
    if (existingUsers && existingUsers.length > 0) setShowLoginForm(false);
  });

  const loginUser = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isValid()) {
      setIsLoading(true);
      try {
        trackEvent("Login", "Authenticating user from credentials", username);
        await login(username, password);

        // Prepopulate the userconfig query
        await getConfiguration();
        const url = (location.state && location.state.redirectUrl) || "/";
        history.push(url);
      } catch (error: any) {
        // let errormessage = error.message;
        // if (errormessage === "401") {
        //   errormessage = t("login.invalidcredentials");
        // }
        setErrors({
          form: t("login.invalidcredentials")
        });
        setIsLoading(false);
      }
    }
  };

  // Log in as a user we authenticated before
  const loginExistingUser = async (id: number) => {
    await switchUser(id);
    // setIsAuthenticated(true);
    history.push((location.state && location.state.redirectUrl) || "/");
  };

  // Logout/remove a user we authenticated before
  const removeExistingUser = async (id: number) => {
    const removingLastUser = existingUsers.length === 1;
    await switchUser(id);
    await logout();
    if (removingLastUser) setShowLoginForm(true);
  };

  const isValid = () => {
    const { errors, isValid } = validateLogin(username, password);
    setErrors(errors);
    return isValid;
  };

  return (
    <div className="login-page">
      <div className="login-grid">
        <div className="login-top" />
        <div className="login-middle">
          <div className="login-middle-mobile-top" />
          <div className="login-form">
            <div id="login-form-header">
              <h2>{t("login.login")}</h2>
              <hr />
            </div>

            {!isLoading && showLoginForm && (
              <>
                <form onSubmit={loginUser}>
                  <FormGroup>
                    <ControlLabel>{t("login.email")}</ControlLabel>
                    <FormControl
                      onChange={(e: React.FormEvent<FormControl & HTMLInputElement>) => setUsername(e.currentTarget.value)}
                      value={username}
                      type="email"
                      name="username"
                      placeholder={t("login.email")}
                      disabled={isLoading}
                      autoComplete="username"
                    />
                    {errors.username && <span className="error">{errors.username}</span>}
                  </FormGroup>

                  <FormGroup>
                    <ControlLabel>{t("login.password")}</ControlLabel>
                    <FormControl
                      onChange={(e: React.FormEvent<FormControl & HTMLInputElement>) => setPassword(e.currentTarget.value)}
                      value={password}
                      type="password"
                      name="password"
                      placeholder={t("login.password")}
                      disabled={isLoading}
                      autoComplete="current-password"
                    />
                    {errors.password && <span className="error">{errors.password}</span>}
                  </FormGroup>

                  <FormGroup>
                    <button type="submit" className="main-button" disabled={isLoading}>
                      {t("login.login")}
                    </button>
                  </FormGroup>
                  {existingUsers.length > 0 && (
                    <FormGroup>
                      <Button className="login-use-another-account" onClick={() => setShowLoginForm(false)}>
                        <div className="login-use-another-account-title">{t("login.useLoggedInAccount")}</div>
                        <div className="login-use-another-account-icon">
                          <Icon icon="arrowRightNaked"></Icon>
                        </div>
                      </Button>
                    </FormGroup>
                  )}
                </form>
                <div id="login-forgot-password">
                  <Link className="forgot-password" to="/login/resetpassword" tabIndex={-1}>
                    {t("login.forgotpass")}
                  </Link>
                </div>
              </>
            )}

            {!isLoading && !showLoginForm && (
              <div id="login-as">
                {existingUsers
                  .filter((o) => o)
                  .map((o) => (
                    <ButtonGroup key={o.id}>
                      <Button className="login-as-button" disabled={isLoading} onClick={() => loginExistingUser(o.id)}>
                        <div className="login-user-icon">
                          <Icon icon="user" />
                        </div>
                        <div className="login-user-name">
                          {<UserDetail user={o} detail="fullname" whileLoading={<Spinner size="35px" />}></UserDetail>}
                        </div>
                        <div className="login-user-email">{<UserDetail user={o} detail="email" whileLoading=" "></UserDetail>}</div>
                      </Button>
                      <Button
                        className="login-remove-user"
                        disabled={isLoading}
                        title={capitalize(t("delete"))}
                        onClick={() => removeExistingUser(o.id)}
                      >
                        <Icon icon="close" />
                      </Button>
                    </ButtonGroup>
                  ))}
                <Button className="login-use-another-account" onClick={() => setShowLoginForm(true)}>
                  <div className="login-use-another-account-title">{t("login.useAnotherAccount")}</div>
                  <div className="login-use-another-account-icon">
                    <Icon icon="arrowRightNaked"></Icon>
                  </div>
                </Button>
              </div>
            )}

            {isLoading && (
              <h2>
                <Spinner size="100px" />
              </h2>
            )}

            {errors.form && <div className="error">{errors.form}</div>}

            {!isLoading && (
              <div>
                <div id="login-info">
                  <hr />
                  {t("login.needaccount")}
                  <br />
                  <a href={t("contactUrl")}>{t("login.contactus")}</a> {t("login.or")} <Link to="/login/signup">{t("login.signupnow")}</Link>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="login-bottom">
          <div className="language-switcher">
            {language !== "nb" && (
              <Button bsSize="xs" onClick={async () => await changeLanguage("nb")}>
                <Flag country="no" /> Bytt til Norsk
              </Button>
            )}

            {language !== "en" && (
              <Button bsSize="xs" onClick={async () => await changeLanguage("en")}>
                <Flag country="gb" /> Switch to english
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
