import { useUserConfiguration } from "../shared/queries/queries";

// Look for external CSS in state from the current/last active user, and inject it
const ExternalCssInjector = () => {
  const userConfigurationQuery = useUserConfiguration();

  // Bail out here if we don't have a valid user configuration
  if (!userConfigurationQuery.data) return null;
  const css = userConfigurationQuery.data.configuration.person.customCss;
  if (!css) return null;
  return <style type="text/css">{css}</style>;
};

export default ExternalCssInjector;
