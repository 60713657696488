import { GeoCountry, GeoLocation } from "../types";
import { jsonFetch, jsonFetchWithServerTime } from "./api";

/**
 * @typedef {object} topLocationsResponse
 * @property {object} topLocations - Array of the user's most used locations
 * @property {string} serverTime - UTC servertime
 */

/**
 * A method for retrieving the current user's most used locations
 * @returns {topLocationsResponse} The top locations and server time
 */
export const topLocations = async (): Promise<{ serverTime: string; topLocations: GeoLocation[] }> => {
  const fetchOptions = {
    method: "GET"
  };
  const { body: topLocs, serverTime } = await jsonFetchWithServerTime("e/geography/toplocations", fetchOptions);
  return {
    topLocations: topLocs,
    serverTime
  };
};

// Fetch the top cities in the world
export const majorCities = async (): Promise<GeoLocation[]> => {
  const fetchOptions = {
    method: "GET"
  };
  return jsonFetch("e/geography/majorcities", fetchOptions);
};

// Fetch all countries in the world
export const countries = async (): Promise<GeoCountry[]> => {
  const fetchOptions = {
    method: "GET"
  };
  return jsonFetch("e/geography/countries", fetchOptions);
};

// Attempt to autocomplete a search query to a list of locations
export const autocomplete = async (query: string): Promise<GeoLocation[]> => {
  const fetchOptions = {
    method: "GET"
  };
  return jsonFetch(`e/geography/autocomplete?query=${query}`, fetchOptions);
};

// Fetch a single location (country or city)
export const location = async (cityId: number, countryId: number): Promise<GeoLocation> => {
  const fetchOptions = {
    method: "GET"
  };
  return jsonFetch(`e/geography/location?cityId=${cityId}&countryId=${countryId}`, fetchOptions);
};
