import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { Nav, NavItem } from "react-bootstrap";
// import { showToast } from "../../utils/toastWrapper";
// import currentUserData from "../../shared/utils/currentUserData";
import ReportList from "../report/ReportList";
import ExpenseList from "../expense/ExpenseList";
import InboxList from "../inbox/InboxList";

import { attachmentFromInbox, attachmentFromInboxAttachment } from "../../shared/constructors/attachmentConstructor";
// import {
//   createAttachmentFromInbox,
//   createAttachmentFromInboxAttachment,
//   deleteAttachment
// } from "../../shared/actions/localEntities/attachmentsActions";
// import { moveExpense } from "../../shared/actions/localEntities/expenseActions";
import "../../styles/attachablesdrawer.css";
import Icon from "./Icon";
import { capitalize } from "../../shared/utils/helpers";
import {
  useReports,
  useExpenses,
  useInboxes,
  saveReport,
  saveExpense,
  refreshInboxes,
  refreshReports,
  refreshExpenses,
  useUserConfiguration,
  useOfficialRates,
  useCountries,
  useMajorCities,
  useSeedExchangeRatesNB
} from "../../shared/queries/queries";
import { Report, Expense, Inbox, InboxAttachment } from "../../shared/types";
import { showToast } from "../../utils/toastWrapper";
import moment from "moment";
import Spinner from "./Spinner";
import { calculateReport } from "../../shared/utils/reportCalculation/calculator";

interface AttachablesDrawerProps {
  parent: Report | Expense | Inbox | InboxAttachment;
  parentType: "report" | "expense" | "inbox" | "inboxAttachment";
  parentInboxIfParentIsInboxAttachment?: Inbox;
  showTabs: ("report" | "expense" | "inbox")[];
  onClose: () => void;
  // Parents need to be notified when an expense has been moved away from it, so make sure to call this when an expense is moved between reports
  onExpenseMovedToOtherReport?: (expense: Expense) => void;
  // When the parent is a report or expense, populate this with all inbox and inboxAttachement uuids that have already been used as attachment sources for the parent.
  // This will prevent them from showing up as selectable options.
  // When the parent is an inbox or inboxAttachment, populate it with all report and expense uuids they are attached to
  // This will render them as "already attached" options for viewing and detaching
  hideTargetUuidsInDrawerMode?: string[];
  // Populate this when the parent is an expense contained within a report, and you don't want that report itself to show up as an option in the drawer.
  renderedInsideReportUuid?: string;
}
const AttachablesDrawer = ({
  parent,
  parentType,
  parentInboxIfParentIsInboxAttachment,
  showTabs,
  onClose,
  onExpenseMovedToOtherReport,
  hideTargetUuidsInDrawerMode,
  renderedInsideReportUuid
}: AttachablesDrawerProps) => {
  const [t] = useTranslation();

  const reportsQuery = useReports();
  const expensesQuery = useExpenses();
  const inboxesQuery = useInboxes();

  const userConfigurationQuery = useUserConfiguration();
  const officialRatesQuery = useOfficialRates();
  const countriesQuery = useCountries();
  const majorCitiesQuery = useMajorCities();
  const seedExchangeRatesNBQuery = useSeedExchangeRatesNB();

  // Build the list of available tabs
  const buildNavItems = () => {
    const navItems = [];

    if (parentType === "report" && showTabs.includes("expense"))
      navItems.push({
        eventKey: 1,
        text: t("attachablesDrawer.attachExpenses")
      });

    if (parentType === "report" && showTabs.includes("inbox"))
      navItems.push({
        eventKey: 2,
        text: t("attachablesDrawer.attachInbox")
      });

    if (parentType === "expense" && showTabs.includes("report"))
      navItems.push({
        eventKey: 0,
        text: t("attachablesDrawer.moveToReport")
      });

    if (parentType === "expense" && showTabs.includes("inbox"))
      navItems.push({
        eventKey: 2,
        text: t("attachablesDrawer.attachInbox")
      });
    if ((parentType === "inbox" || parentType === "inboxAttachment") && showTabs.includes("report"))
      navItems.push({
        eventKey: 0,
        text: t("attachablesDrawer.attachToReport")
      });
    if ((parentType === "inbox" || parentType === "inboxAttachment") && showTabs.includes("expense"))
      navItems.push({
        eventKey: 1,
        text: t("attachablesDrawer.attachToExpense")
      });

    return navItems;
  };

  const navItems = buildNavItems();

  const [currentTab, setCurrentTab] = useState(navItems[0].eventKey);
  const [searchString, setSearchString] = useState("");
  const [alreadyAttachedReportsAndExpenses, setAlreadyAttachedReportsAndExpenses] = useState<{ reports: Report[]; expenses: Expense[] }>({
    reports: [],
    expenses: []
  });
  
  // We no longer allow attaching inbox/inboxAttachments directly to report
  // If a report is selected for attaching, we load the expenses from that report into this array
  // We then switch to the expenses tab and render those expenses in a separate block on top of the standalone expenses
  // This allows the user to send inbox/inboxAttachments to expenses *within* a report
  const [expensesOnSelectedReport, setExpensesOnSelectedReport] = useState<Expense[]>([]);

  // When reportsquery and expensesquery has been populated (or updated), and parent is an inbox or inboxAttachment, generate a list of all reports and expenses the parent is attached to
  // This will be used to enable detaching from within the inbox
  useEffect(() => {
    if (reportsQuery.data && expensesQuery.data && (parentType === "inbox" || parentType === "inboxAttachment")) {
      let alreadyAttachedReports: Report[] = [];
      let alreadyAttachedExpenses: Expense[] = [];
      if (parentType === "inbox") {
        for (const report of reportsQuery.data.reports.filter((o) => !o.deleted)) {
          if (report.attachments.find((a) => !a.deleted && a.inboxUuid === parent.uuid)) alreadyAttachedReports.push(report);
          for (const expense of report.expenses.filter((o) => !o.deleted)) {
            if (expense.attachments.find((a) => !a.deleted && a.inboxUuid === parent.uuid)) alreadyAttachedExpenses.push(expense);
          }
        }
        for (const expense of expensesQuery.data.expenses.filter((o) => !o.deleted)) {
          if (expense.attachments.find((a) => !a.deleted && a.inboxUuid === parent.uuid)) alreadyAttachedExpenses.push(expense);
        }
      }
      if (parentType === "inboxAttachment") {
        for (const report of reportsQuery.data.reports.filter((o) => !o.deleted)) {
          if (report.attachments.find((a) => !a.deleted && a.inboxAttachmentUuid === parent.uuid)) alreadyAttachedReports.push(report);
          for (const expense of report.expenses.filter((o) => !o.deleted)) {
            if (expense.attachments.find((a) => !a.deleted && a.inboxAttachmentUuid === parent.uuid)) alreadyAttachedExpenses.push(expense);
          }
        }
        for (const expense of expensesQuery.data.expenses.filter((o) => !o.deleted)) {
          if (expense.attachments.find((a) => !a.deleted && a.inboxAttachmentUuid === parent.uuid)) alreadyAttachedExpenses.push(expense);
        }
      }
      setAlreadyAttachedReportsAndExpenses({ reports: alreadyAttachedReports, expenses: alreadyAttachedExpenses });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportsQuery.data, expensesQuery.data]);

  // Bail out here if we don't have a valid user configuration or all the static data yet
  if (!userConfigurationQuery.data || !officialRatesQuery.data || !countriesQuery.data || !majorCitiesQuery.data || !seedExchangeRatesNBQuery.data) {
    console.log("AttachablesDrawer: No userconfig or static data, bailing out");
    return <Spinner size="75px" margin="1em" />;
  }

  const userConfiguration = userConfigurationQuery.data.configuration;
  const officialRates = officialRatesQuery.data;
  const countries = countriesQuery.data;
  const majorCities = majorCitiesQuery.data;
  const exchangeRatesNBSeed = seedExchangeRatesNBQuery.data;

  // Switch between tabs
  const switchTab = (tabNum: number) => {
    // If we're moving away from the expenses tab, reset setExpensesOnSelectedReport
    if (tabNum !== 1) setExpensesOnSelectedReport([]);
    setCurrentTab(tabNum);
  };

  const moveExpenseToReport = async (expense: Expense, report: Report) => {
    // Update the expense, add it to the report, recalculate and save
    const now = moment().toISOString();
    // If we're moving the expense from a report, the user is probably inside that report right now
    // We should notify the parent so the expense can be moved away from the grandparent report
    if (expense.reportUuid && onExpenseMovedToOtherReport) onExpenseMovedToOtherReport(expense);
    const updatedExpense = { ...expense, reportUuid: report.uuid, dirty: now };
    const updatedReport = { ...report, expenses: [...report.expenses, updatedExpense], dirty: now };
    updatedReport.calculation = calculateReport(updatedReport, userConfiguration, officialRates, countries, majorCities, exchangeRatesNBSeed);

    saveReport(updatedReport)
      .then(() => {
        // Refresh both related queries
        refreshReports();
        refreshExpenses();
      })
      .catch((err) => {
        showToast({ type: "error", title: t("notifications.reportSaveFailed"), text: err?.backendMessage || "" });
      });
  };

  const createAttachmentFromInbox = async (targetType: "report" | "expense", target: Report | Expense, inbox: Inbox) => {
    const attachment = attachmentFromInbox({
      inbox,
      report: targetType === "report" ? (target as Report) : undefined,
      expense: targetType === "expense" ? (target as Expense) : undefined
    });
    target.attachments.push(attachment);

    if (targetType === "report") {
      saveReport(target as Report)
        .then(() => {
          refreshInboxes();
        })
        .catch((err) => {
          showToast({ type: "error", title: t("notifications.reportSaveFailed"), text: err?.backendMessage || "" });
        });
    }
    if (targetType === "expense") {
      saveExpense(target as Expense)
        .then(() => {
          refreshInboxes();
        })
        .catch((err) => {
          showToast({ type: "error", title: t("notifications.expenseSaveFailed"), text: err?.backendMessage || "" });
        });
    }
  };

  const createAttachmentFromInboxAttachment = async (targetType: string, target: Report | Expense, inboxAttachment: InboxAttachment) => {
    const attachment = attachmentFromInboxAttachment({
      inboxAttachment,
      report: targetType === "report" ? (target as Report) : undefined,
      expense: targetType === "expense" ? (target as Expense) : undefined
    });
    target.attachments.push(attachment);

    if (targetType === "report") {
      saveReport(target as Report)
        .then(() => {
          refreshInboxes();
        })
        .catch((err) => {
          showToast({ type: "error", title: t("notifications.reportSaveFailed"), text: err?.backendMessage || "" });
        });
    }
    if (targetType === "expense") {
      saveExpense(target as Expense)
        .then(() => {
          refreshInboxes();
        })
        .catch((err) => {
          showToast({ type: "error", title: t("notifications.expenseSaveFailed"), text: err?.backendMessage || "" });
        });
    }
  };

  // Load interactable reports, expenses and inboxes (depending on our parent)
  const reports =
    parentType !== "report" && reportsQuery.data
      ? reportsQuery.data.reports.filter((report) => !report.deleted && report.status === 1 && report.uuid !== renderedInsideReportUuid) // No deleted or archived reports, and if we're being rendered beneath an expense already on a report, don't show that one either
      : [];

  const expenses =
    parentType !== "expense" && expensesQuery.data
      ? expensesQuery.data.expenses.filter((expense) => !expense.deleted && !expense.reportUuid) // No deleted expenses or expenses assigned to reports
      : [];

  const inboxes = parentType !== "inbox" && inboxesQuery.data ? inboxesQuery.data.inboxes.filter((inbox) => !inbox.deleted) : [];

  // Assume success on all attach-calls and show success notifications
  // Change this when we implement proper error handling.

  const onReportSelected = (report: Report) => {
    if (report.status !== 1) {
      showToast({ text: t("attachActions.The selected report can not be modified"), type: "success" });
      return;
    }
    if (parentType === "expense") {
      // Move parent expense to selected report
      moveExpenseToReport(parent as Expense, report);
      showToast({ title: t("attachActions.Expense moved"), text: t("attachActions.The expense was placed on a report"), type: "success" });
    }
    if (parentType === "inbox") {
      // Attach parent inbox to selected report
      createAttachmentFromInbox("report", report, parent as Inbox);
      showToast({ title: t("attachActions.Attachment moved"), text: t("attachActions.The attachment was placed on a report"), type: "success" });
    }
    if (parentType === "inboxAttachment") {
      // Attach parent inboxattachment to selected report
      createAttachmentFromInboxAttachment("report", report, parent as InboxAttachment);
      showToast({ title: t("attachActions.Attachment moved"), text: t("attachActions.The attachment was placed on a report"), type: "success" });
    }
  };

  const onExpenseSelected = (expense: Expense) => {
    if (parentType === "report") {
      // Move selected expense to parent report
      moveExpenseToReport(expense, parent as Report);
      showToast({ title: t("attachActions.Expense moved"), text: t("attachActions.The expense was placed on a report"), type: "success" });
    }
    if (parentType === "inbox") {
      // Attach parent inbox to selected expense
      createAttachmentFromInbox("expense", expense, parent as Inbox);
      showToast({ title: t("attachActions.Attachment moved"), text: t("attachActions.The attachment was placed on an expense"), type: "success" });
    }
    if (parentType === "inboxAttachment") {
      // Attach parent inboxattachment to selected expense
      createAttachmentFromInboxAttachment("expense", expense, parent as InboxAttachment);
      showToast({ title: t("attachActions.Attachment moved"), text: t("attachActions.The attachment was placed on an expense"), type: "success" });
    }
  };

  const onInboxSelected = (inbox: Inbox) => {
    if (parentType === "report") {
      // Attach selected inbox to parent report
      createAttachmentFromInbox("report", parent as Report, inbox);
      showToast({ title: t("attachActions.Attachment moved"), text: t("attachActions.The attachment was placed on a report"), type: "success" });
    }
    if (parentType === "expense") {
      // Attach selected inbox to parent expense
      createAttachmentFromInbox("expense", parent as Expense, inbox);
      showToast({ title: t("attachActions.Attachment moved"), text: t("attachActions.The attachment was placed on an expense"), type: "success" });
    }
  };

  const onInboxAttachmentSelected = (inbox: Inbox, inboxAttachment: InboxAttachment) => {
    if (parentType === "report") {
      // Attach selected inboxattachment to parent report
      createAttachmentFromInboxAttachment("report", parent as Report, inboxAttachment);
      showToast({ title: t("attachActions.Attachment moved"), text: t("attachActions.The attachment was placed on a report"), type: "success" });
    }
    if (parentType === "expense") {
      // Attach selected inboxattachment to parent expense
      createAttachmentFromInboxAttachment("expense", parent as Expense, inboxAttachment);
      showToast({ title: t("attachActions.Attachment moved"), text: t("attachActions.The attachment was placed on an expense"), type: "success" });
    }
  };

  // Used for removing an existing attachment between parent (inbox/inboxattachment) and report
  const onReportSelectedDetach = async (report: Report) => {
    const now = moment().toISOString();
    if (parentType === "inbox") {
      // Detach parent inbox from selected report. Handle multiple hits in case we have multiple attachments between the same two entities.
      const updatedReport = {
        ...report,
        dirty: now,
        attachments: report.attachments.map((a) => (a.inboxUuid === parent.uuid ? { ...a, deleted: now, dirty: now } : a))
      };
      saveReport(updatedReport)
        .then(() => {
          refreshReports();
          refreshInboxes();
          showToast({
            title: t("attachActions.Attachment removed"),
            text: t("attachActions.The attachment was removed from the report"),
            type: "success"
          });
        })
        .catch((err) => {
          showToast({ type: "error", title: t("notifications.reportSaveFailed"), text: err?.backendMessage || "" });
        });
    }
    if (parentType === "inboxAttachment") {
      // Detach parent inboxAttachment from selected report. Handle multiple hits in case we have multiple attachments between the same two entities.
      const updatedReport = {
        ...report,
        dirty: now,
        attachments: report.attachments.map((a) => (a.inboxAttachmentUuid === parent.uuid ? { ...a, deleted: now, dirty: now } : a))
      };

      saveReport(updatedReport)
        .then(() => {
          refreshReports();
          refreshInboxes();
          showToast({
            title: t("attachActions.Attachment removed"),
            text: t("attachActions.The attachment was removed from the report"),
            type: "success"
          });
        })
        .catch((err) => {
          showToast({ type: "error", title: t("notifications.reportSaveFailed"), text: err?.backendMessage || "" });
        });
    }
  };

  // Used for removing an existing attachment between parent (inbox/inboxattachment) and expense
  const onExpenseSelectedDetach = async (expense: Expense) => {
    const now = moment().toISOString();
    if (parentType === "inbox") {
      // Detach parent inbox from selected expense. Handle multiple hits in case we have multiple attachments between the same two entities.
      const updatedExpense = {
        ...expense,
        dirty: now,
        attachments: expense.attachments.map((a) => (a.inboxUuid === parent.uuid ? { ...a, deleted: now, dirty: now } : a))
      };

      saveExpense(updatedExpense)
        .then(() => {
          if (expense.reportUuid) {
            refreshReports();
          } else {
            refreshExpenses();
          }
          refreshInboxes();
          showToast({
            title: t("attachActions.Attachment removed"),
            text: t("attachActions.The attachment was removed from the expense"),
            type: "success"
          });
        })
        .catch((err) => {
          showToast({ type: "error", title: t("notifications.expenseSaveFailed"), text: err?.backendMessage || "" });
        });
    }
    if (parentType === "inboxAttachment") {
      // Detach parent inboxAttachment from selected expense. Handle multiple hits in case we have multiple attachments between the same two entities.
      const updatedExpense = {
        ...expense,
        dirty: now,
        attachments: expense.attachments.map((a) => (a.inboxAttachmentUuid === parent.uuid ? { ...a, deleted: now, dirty: now } : a))
      };

      saveExpense(updatedExpense)
        .then(() => {
          if (expense.reportUuid) {
            refreshReports();
          } else {
            refreshExpenses();
          }
          refreshInboxes();
          showToast({
            title: t("attachActions.Attachment removed"),
            text: t("attachActions.The attachment was removed from the expense"),
            type: "success"
          });
        })
        .catch((err) => {
          showToast({ type: "error", title: t("notifications.expenseSaveFailed"), text: err?.backendMessage || "" });
        });
    }
  };

  // Special case, see comment above the expensesOnSelectedReport useState hook
  // Load the expenses from the selected report and switch to the expenses tab
  const onShowExpensesFromReport = (report: Report) => {
    const expensesOnReport = report.expenses.filter((o) => !o.deleted);
    setExpensesOnSelectedReport(expensesOnReport);
    switchTab(1);
  };

  const parentIsInboxOrInboxAttachment = parentType === "inbox" || parentType === "inboxAttachment";

  return (
    <div className="attachables-drawer">
      <div className="pull-right attachables-drawer-close-widget">
        <button title={t("attachablesDrawer.closeDrawer")} className="attachables-drawer-close" onClick={() => onClose()}>
          <Icon icon="close" />
        </button>
      </div>
      {(alreadyAttachedReportsAndExpenses.reports.length > 0 || alreadyAttachedReportsAndExpenses.expenses.length > 0) && (
        // Render a list of existing attachments if we have any, allowing the user to detach them
        <div className="attachables-drawer-existing-attachments">
          <div className="attachables-drawer-title">{t("attachablesDrawer.alreadyAttachedTo")}:</div>
          <div className="attachables-drawer-subtitle">({t("attachablesDrawer.clickToDetach")})</div>
          {alreadyAttachedReportsAndExpenses.reports.length > 0 && (
            <ReportList
              reports={alreadyAttachedReportsAndExpenses.reports}
              orderBy="changed"
              orderDesc
              renderMode="drawer"
              onDrawerSelection={onReportSelectedDetach}
            />
          )}
          {alreadyAttachedReportsAndExpenses.expenses.length > 0 && (
            <ExpenseList
              expenses={alreadyAttachedReportsAndExpenses.expenses}
              orderBy="changed"
              orderDesc
              renderMode="drawer"
              onDrawerSelection={onExpenseSelectedDetach}
            />
          )}
        </div>
      )}
      <div className="attachables-drawer-header">
        <Nav className="pull-left" bsStyle="tabs" activeKey={currentTab} onSelect={(eventKey: any) => switchTab(eventKey)}>
          {navItems.map((item) => (
            <NavItem eventKey={item.eventKey} key={item.eventKey}>
              {item.text}
            </NavItem>
          ))}
        </Nav>

        <div className="pull-left search">
          <Icon icon="search" />
          <input type="text" className="search-box" placeholder={capitalize(t("search"))} onChange={(event) => setSearchString(event.target.value)} />
        </div>
        <div className="clearfix" />
      </div>
      {currentTab === 0 && (
        <ReportList
          reports={
            parentIsInboxOrInboxAttachment
              ? reports.filter((report) => report.status === 1 && report.expenses.filter((p) => !p.deleted).length > 0)
              : reports.filter((report) => report.status === 1)
          }
          orderBy="changed"
          orderDesc
          renderMode="drawer"
          onDrawerSelection={parentIsInboxOrInboxAttachment ? onShowExpensesFromReport : onReportSelected}
          hideTargetUuidsInDrawerMode={hideTargetUuidsInDrawerMode}
          showHeader={false}
          searchString={searchString}
          errorMessage={reportsQuery.isError || reportsQuery.data?.incrementalRefreshStatus === "error" ? t("queryErrors.loadReportsError") : ""}
        />
      )}
      {/* 
          If we have an inbox/inboxAttachment parent and the user selects a report, expensesOnSelectedReport is populated 
          Show these expenses above the standalone expenses
        */}
      {currentTab === 1 && expensesOnSelectedReport.length > 0 && (
        <div className="attachables-drawer-expenses-from-report">
          <div className="attachables-drawer-title">{t("attachablesDrawer.expensesOnReport")}:</div>

          {expensesOnSelectedReport.map((o) => o.uuid).filter((o) => !hideTargetUuidsInDrawerMode?.includes(o)).length === 0 && (
            <div className="attachables-drawer-subtitle">{t("attachablesDrawer.noRemainingExpensesOnReport")}:</div>
          )}
          <ExpenseList
            expenses={expensesOnSelectedReport}
            orderBy="changed"
            orderDesc
            readOnly={false}
            renderMode="drawer"
            onDrawerSelection={onExpenseSelected}
            hideTargetUuidsInDrawerMode={hideTargetUuidsInDrawerMode}
            showHeader={false}
            searchString={searchString}
            errorMessage={expensesQuery.isError || expensesQuery.data?.incrementalRefreshStatus === "error" ? t("queryErrors.loadExpensesError") : ""}
          />
        </div>
      )}
      {/* Standalone expenses */}
      {currentTab === 1 && (
        <>
          {expensesOnSelectedReport.length > 0 && <div className="attachables-drawer-title">{t("attachablesDrawer.standaloneExpenses")}:</div>}

          <ExpenseList
            expenses={expenses}
            orderBy="changed"
            orderDesc
            readOnly={false}
            renderMode="drawer"
            onDrawerSelection={onExpenseSelected}
            hideTargetUuidsInDrawerMode={hideTargetUuidsInDrawerMode}
            showHeader={false}
            searchString={searchString}
            errorMessage={expensesQuery.isError || expensesQuery.data?.incrementalRefreshStatus === "error" ? t("queryErrors.loadExpensesError") : ""}
          />
        </>
      )}

      {currentTab === 2 && (
        <InboxList
          inboxes={inboxes}
          orderBy="changed"
          orderDesc
          renderMode="drawer"
          onDrawerSelectionInbox={onInboxSelected}
          onDrawerSelectionInboxAttachment={onInboxAttachmentSelected}
          hideTargetUuidsInDrawerMode={hideTargetUuidsInDrawerMode}
          showHeader={false}
          searchString={searchString}
          errorMessage={inboxesQuery.isError || inboxesQuery.data?.incrementalRefreshStatus === "error" ? t("queryErrors.loadInboxesError") : ""}
        />
      )}
    </div>
  );
};

export default AttachablesDrawer;
