import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import Map from "../google/Map";
import { useTollstations } from "../../shared/queries/queries";
import { Mileage, Tollstation } from "../../shared/types";
import Spinner from "../common/Spinner";

// A map wrapper for showing GPS tracks and toll stations
interface MileageMapProps {
  mileage?: Mileage;
  tollStationsLoaded?: (tollstations: Tollstation[]) => void; // A callback that should be passed the list of toll stations when loaded
  halfSize?: boolean;
}
const MileageMap = ({ mileage, tollStationsLoaded, halfSize }: MileageMapProps) => {
  const [t] = useTranslation();
  const tollstationsQuery = useTollstations(mileage);

  useEffect(() => {
    // Poke the callback when tollstations have been loaded
    if (tollstationsQuery.data && tollStationsLoaded) tollStationsLoaded(tollstationsQuery.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tollstationsQuery.data]);

  // Show a spinner while loading tollstations for track
  if (!tollstationsQuery.data)
    return (
      <div className={`mileage-map ${halfSize ? "mileage-map-halfsize" : ""}`}>
        <Spinner size="100px" margin="100px" />
      </div>
    );

  // If the track has no segments, show a message instead of the map
  if (mileage && mileage.track.trackSegments?.length === 0)
    return (
      <div className={`mileage-map ${halfSize ? "mileage-map-halfsize" : ""} empty-track`}>
        <div>{t("trackIsEmpty")}</div>
      </div>
    );

  // We have all needed data, show the map
  return (
    <div className={`mileage-map ${halfSize ? "mileage-map-halfsize" : ""}`}>
      {mileage ? <Map trackSegments={mileage.track.trackSegments} tollStations={tollstationsQuery.data || []} /> : <Spinner size="75px" />}
    </div>
  );
};

export default MileageMap;
